import { Directive, HostListener } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
  selector: "[appNoSpaces]",
})
export class NoSpacesDirective {
  constructor(private ngControl: NgControl) {}

  @HostListener("input", ["$event"]) onInputChange(event: any) {
    let inputValue = this.ngControl.control?.value;

    // Remove spaces at the beginning or multiple consecutive spaces
    if (inputValue) {
      // Remove spaces at the beginning
      inputValue = inputValue.replace(/^\s+/g, "");

      // Replace multiple consecutive spaces with a single space
      inputValue = inputValue.replace(/\s\s+/g, " ");

      this.ngControl.control?.setValue(inputValue);
    }

    // Stop event propagation if input value was altered
    if (inputValue !== this.ngControl.control?.value) {
      event.stopPropagation();
    }
  }
}
