import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import { CompanyServices } from '../../company.service';
import swal from 'sweetalert2';
import { ExcelService } from '../../../excel-service.service';

@Component({
  selector: 'app-summary-reports',
  templateUrl: './summary-reports.component.html',
  styleUrls: ['./summary-reports.component.scss'],
})
export class SummaryReportsComponent implements OnInit {
  summaryReportType = '';
  summaryFilter = '';
  summaryCellValue = '';
  loading: boolean = false;
  summaryReportdata: any = [];
  allPossibleFilters: any = [];
  dropdownList: any = [];
  finalDropdownList: any = [];
  selectedItems: any = [];
  dropdownSettings = {};
  dropdownSettings2 = {};
  finalDisplayFilters: any = [];
  summaryResult: Array<Filter> = [
    {
      name: 'Avg Score',
      value: 'avgValue',
    },
    {
      name: 'Min Score',
      value: 'minValue',
    },
    {
      name: 'Max Score',
      value: 'maxValue',
    },
    {
      name: 'No of Managers',
      value: 'noOfManagers',
    },
    {
      name: '% of Managers',
      value: '%ofManagers',
    },
  ];
  constructor(
    private companyService: CompanyServices,
    private excelService: ExcelService
  ) {}

  ngOnInit() {
    this.getPossibleFilters();
    this.dropdownSettings = {
      singleSelection: false,
      text: 'Select Filters',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'fieldFilterDropdown',
      maxHeight: 160,
    };
    this.dropdownSettings2 = {
      singleSelection: false,
      text: 'Select Filters',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'fieldFilterDropdown',
      maxHeight: 160,
    };
  }

  getPossibleFilters() {
    // this.loading = true;
    this.companyService.getAllPossibleFilters().subscribe((res: any) => {
      if (res.status) {
        // this.loading = false;
        let data: any = res.data;
        data.forEach((filter: any) => {
          if (filter.options.length > 0) {
            this.dropdownList.push({
              id: filter.dbName,
              itemName: filter.filterName,
            });
            this.allPossibleFilters.push(filter);
          }
        });
        this.finalDropdownList = this.dropdownList;
      }
    });
  }

  //   {
  //     "_id" : ObjectId("61121f4ed4f07d1de83b384a"),
  //     "managerId" : ObjectId("60dae15057f05665db747594")
  // },

  getSummaryReport(excelDownload = false): Boolean {
    this.loading = true;
    if (
      this.summaryReportType == '' ||
      this.summaryFilter == '' ||
      this.summaryCellValue == ''
    ) {
      swal.fire({
        title: 'Incomplete Selection!',
        text: 'Please select all 3 options/filters for generating the report.',
        icon: 'error',
        showConfirmButton: false,
        timer: 2500,
      });
      this.loading = false;
      return false;
    }
    let params = {
      type: this.summaryReportType,
      filter: this.summaryFilter,
      value: this.summaryCellValue,
      filters: this.finalDisplayFilters,
    };
    this.companyService.getSummaryReport(params).subscribe((res: any) => {
      this.loading = false;
      if (res.status) {
        let sorted = res.data.sort((a: any, b: any) => {
          return a.id < b.id ? 1 : -1;
        });
        this.summaryReportdata = sorted;

        if (excelDownload) {
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.data);
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
          this.excelService.exportAsExcelFile(wb, 'Summary Report.xlsx');
        }
      }
    });
    return true;
  }

  onItemSelect(item: any) {
    let newObject: any = {};
    this.allPossibleFilters.forEach((filter: any) => {
      if (filter.dbName == item.id) {
        newObject = filter;
        newObject['selectedItems'] = [];
        newObject['newOptions'] = [];
        filter.options.map((option: any) => {
          newObject['newOptions'].push({
            id: option,
            itemName: option,
          });
        });
        this.finalDisplayFilters.push(newObject);
      }
    });
  }

  OnItemDeSelect(item: any) {
    this.finalDisplayFilters = this.finalDisplayFilters.filter(
      (filter: any) => {
        return filter.dbName != item.id;
      }
    );
  }

  onSelectAll(items: any) {
    this.selectedItems = items;
    let alreadyIncluded: any = [];
    let newObject: any = {};
    this.finalDisplayFilters.map((already: any) => {
      alreadyIncluded.push(already.dbName);
    });
    this.selectedItems.forEach((item: any) => {
      this.allPossibleFilters.forEach((filter: any) => {
        if (filter.dbName == item.id && !alreadyIncluded.includes(item.id)) {
          newObject = filter;
          newObject['selectedItems'] = [];
          newObject['newOptions'] = [];
          filter.options.map((option: any) => {
            newObject['newOptions'].push({
              id: option,
              itemName: option,
            });
          });
          this.finalDisplayFilters.push(newObject);
        }
      });
    });
  }

  onDeSelectAll(items: any) {
    this.selectedItems = items;
    this.finalDisplayFilters = [];
  }

  onFilterItemSelect(item: any) {
    console.log('item', this.finalDisplayFilters);
  }

  onFilterItemDeSelect(item: any) {
    console.log('item', this.finalDisplayFilters);
  }

  onFilterSelectAll(items: any, itemId: any) {
    this.finalDisplayFilters.map((filter: any, index: any) => {
      if (filter.dbName == itemId) {
        this.finalDisplayFilters[index]['selectedItems'] = items;
      }
    });
    console.log('item', this.finalDisplayFilters);
  }

  onFilterDeSelectAll(items: any, itemId: any) {
    this.finalDisplayFilters.map((filter: any, index: any) => {
      if (filter.dbName == itemId) {
        this.finalDisplayFilters[index]['selectedItems'] = items;
      }
    });
    console.log('item', this.finalDisplayFilters);
  }

  changeSummaryFilters(evt: any, filter: any) {
    let value = evt.target.value;
    // console.log('value', value);
    switch (filter) {
      case 'type':
        this.summaryReportType = value;
        if (value == 'actionPlan' || value == 'surveySummary') {
          this.summaryResult = [
            {
              name: 'No of Managers',
              value: 'noOfManagers',
            },
          ];
        } else {
          this.summaryResult = [
            {
              name: 'Avg Score',
              value: 'avgValue',
            },
            {
              name: 'Min Score',
              value: 'minValue',
            },
            {
              name: 'Max Score',
              value: 'maxValue',
            },
            {
              name: 'No of Managers',
              value: 'noOfManagers',
            },
            {
              name: '% of Managers',
              value: '%ofManagers',
            },
          ];
        }
        break;
      case 'filter':
        this.summaryFilter = value;
        console.log('test', value);
        this.selectedItems = [];
        this.finalDisplayFilters = [];
        this.finalDropdownList = this.dropdownList.filter((filter: any) => {
          return filter.id !== value;
        });
        console.log('test2', this.finalDropdownList);
        break;
      case 'value':
        this.summaryCellValue = value;
        break;
    }
  }

  returnZero() {
    return 0;
  }
}

interface Filter {
  name: string;
  value: string;
}
