import { camelCase } from "lodash";
import { Component, OnInit } from "@angular/core";
import { DataService } from "../data.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { CompanyServices } from "../company.service";
import swal from "sweetalert2";
// import * as jwt_decode from 'jwt-decode';
import { jwtDecode } from "jwt-decode";
@Component({
  selector: "app-xl-data-mapping",
  templateUrl: "./xl-data-mapping.component.html",
  styleUrls: ["./xl-data-mapping.component.css"],
})
export class XlDataMappingComponent implements OnInit {
  xlData: any[][] = [];
  managerXlData: any;
  participantXlData: any;
  mappingForm!: FormGroup;
  name!: String;
  headersAsObject: any = {};
  dataInObject = {};
  pisHeaderData = {};
  headers: Array<string> = [];
  finalDataInArray: any = [];
  valid: number = 0;
  invalid: number = 0;
  existingMgrList: any;
  loading: Boolean = false;
  compulsoryList = [
    "participantName",
    "participantEmailId",
    "feedbackGiverName",
    "feedbackGiverEmailId",
    "respondentType",
  ];
  standardDemographicList = [
    {
      label: "Participant Name",
      controlName: "participantName",
      type: "participant",
    },
    {
      label: "Participant Email ID",
      controlName: "participantEmailId",
      type: "participant",
    },

    {
      label: "Feedback giver's Employee  Id",
      controlName: "employeeId",
      type: "participant",
    },
    {
      label: "Name of the Feedback giver",
      controlName: "feedbackGiverName",
      type: "participant",
    },
    {
      label: "Feedback Giver Email Id",
      controlName: "feedbackGiverEmailId",
      type: "participant",
    },
    {
      label: "Feedback Giver Phone Number",
      controlName: "feedbackGiverPhoneNumber",
      type: "participant",
    },
    {
      label: "Survey Administration Language",
      controlName: "surveyLanguage",
      type: "participant",
    },
    {
      label: "Respondent Type",
      controlName: "respondentType",
      type: "participant",
    },

    { label: "Sl. No", controlName: "srNo", type: "manager" },
    {
      label: "Participant Employee ID",
      controlName: "employeeId",
      type: "manager",
    },

    {
      label: "Participant Name",
      controlName: "participantName",
      type: "manager",
    },
    {
      label: "Participant Email ID",
      controlName: "participantEmailId",
      type: "manager",
    },
    {
      label: "Participant Phone Number",
      controlName: "participantPhoneNumber",
      type: "manager",
    },
    { label: "Batch Number / Name	", controlName: "batchName", type: "manager" },
    {
      label: "Batch Anchor Name",
      controlName: "batchAnchorName",
      type: "manager",
    },
    {
      label: "Batch Anchor Email Id",
      controlName: "batchAnchorEmailId",
      type: "manager",
    },
    {
      label: "Batch Anchor Phone Number",
      controlName: "batchAnchorPhoneNumber",
      type: "manager",
    },
    {
      label: "Survey Administration Language",
      controlName: "participantSurveyLanguage",
      type: "manager",
    },
    {
      label: "Reporting Manager Employee ID",
      controlName: "reportingManagerEmployeeId",
      type: "manager",
    },
    {
      label: "Reporting Manager Name	",
      controlName: "reportingManagerName",
      type: "manager",
    },
    {
      label: "Reporting Manager Email ID",
      controlName: "reportingManagerEmail",
      type: "manager",
    },
    {
      label: "Age",
      controlName: "age",
      type: "both",
    },
    {
      label: "Gender",
      controlName: "gender",
      type: "both",
    },
    {
      label: "Tenure With Organisation",
      controlName: "tenureWithOrganisation",
      type: "both",
    },
    {
      label: "Tenure as Manager",
      controlName: "tenureAsManager",
      type: "manager",
    },
    {
      label: "Layer Of Reportee",
      controlName: "layerOfReportee",
      type: "manager",
    },
    {
      label: "Department/Function",
      controlName: "departmentFunction",
      type: "both",
    },
    {
      label: "Level of Seniority",
      controlName: "levelOfSeniority",
      type: "both",
    },
    {
      label: "Work Experience",
      controlName: "workExperience",
      type: "both",
    },
    {
      label: "Team Composition",
      controlName: "teamComposition",
      type: "manager",
    },
    {
      label: "Team Size",
      controlName: "teamSize",
      type: "manager",
    },
    {
      label: "Tenure With Manager",
      controlName: "tenureWithManager",
      type: "participant",
    },
    {
      label: "Country",
      controlName: "country",
      type: "both",
    },
    {
      label: "City",
      controlName: "city",
      type: "both",
    },
    {
      label: "State",
      controlName: "state",
      type: "both",
    },
    {
      label: "Nudge Topics",
      controlName: "nudgeTopics",
      type: "manager",
    },
  ];
  managerDynamicDemographicsList: demographicModel[] = [];
  participantDynamicDemographicsList: demographicModel[] = [];
  managersDemographicsList: string[] = [];
  participantsDemographicsList: string[] = [];
  demographic: string = "";
  isManagerUpload: boolean = false;
  isParticipantUpload: boolean = false;
  errorMessage: string = "";
  uploadType!: string;
  editDemographicList: Boolean = false;
  editDemographicId!: number;
  alreadyAddedDemographics: any;

  constructor(
    private data: DataService,
    private fb: FormBuilder,
    private companyService: CompanyServices,
    private router: Router
  ) {}

  ngOnInit() {
    this.data.uploadType$.subscribe((type) => {
      this.uploadType = type;
    });
    this.data.pisHeaderData.subscribe((type) => {
      this.pisHeaderData = type;
    });
    localStorage.setItem("type", this.uploadType);
    this.uploadType == "manager"
      ? (this.isManagerUpload = true)
      : (this.isParticipantUpload = true);
    this.mappingForm = this.fb.group({});
    this.standardDemographicList = this.standardDemographicList.filter(
      (item) => item.type === this.uploadType || item.type === "both"
    );

    this.standardDemographicList.forEach((input) => {
      if (this.uploadType == input.type || input.type == "both") {
        this.mappingForm.addControl(
          input.controlName,
          this.fb.control("", Validators.required)
        );
      }
    });
    this.data.managerDemographics.subscribe(
      (message) => (this.managerXlData = message)
    );
    this.data.participantDemographic.subscribe(
      (message) => (this.participantXlData = message)
    );
    this.uploadType == "manager"
      ? (this.xlData = this.managerXlData)
      : (this.xlData = this.participantXlData);
    this.dataInObject = this.xlData;
    // TODO handle the header condition here
    this.headersAsObject = this.xlData[0];
    for (var key in this.headersAsObject) {
      this.headers.push(this.headersAsObject[key]);
    }
    // this.managersDemographicsList = this.headers.filter(
    //   (item) => !item.includes("articipant")
    // );
    // this.saveDemographics("manager");
    this.getCompanyDemographics();
  }

  /*
  Description: This fucntion will collect all the form mapping of excels
  Date Of Creation: 2-1-2019
  Author: Shaikh Faizan
  */
  onSubmit(formData: any) {
    // Creating data as per mapping

    for (var excelRowIndex in this.xlData) {
      // looping through each excel row set
      let tempObj: any = {};
      for (var singleMappingHeaderIndex in formData) {
        // looping through each mapped value

        if (formData[singleMappingHeaderIndex] != "") {
          this.finalDataInArray[excelRowIndex] = [];

          for (var singleExcelheaderIndex in this.headersAsObject) {
            // looping through header found in excel
            if (
              formData[singleMappingHeaderIndex] ==
              this.headersAsObject[singleExcelheaderIndex]
            ) {
              // Creating object of each excel row
              let keyName = camelCase(singleMappingHeaderIndex);
              tempObj[keyName] = "";
              tempObj[keyName] =
                this.xlData[excelRowIndex][singleExcelheaderIndex] != undefined
                  ? this.xlData[excelRowIndex][singleExcelheaderIndex]
                  : "";
            }
          }
          this.finalDataInArray[excelRowIndex] = tempObj;
        }
      }
    }
    this.finalDataInArray.shift();
    const data = {
      type: this.uploadType,
      data: this.finalDataInArray,
      pisHeaderData: this.pisHeaderData,
    };
    this.companyService.checkDataValidation(data).subscribe((res: any) => {
      this.loading = false;
      if (res.status) {
        let data = {
          allData: res.allRows,
          validRows: res.validRows,
          notValidRows: res.notValidRows,
          pisHeaderData: this.pisHeaderData,
          standardDemographic: this.standardDemographicList.map(
            (item) => item.controlName
          ),
          dynamicDemographic:
            this.uploadType == "manager"
              ? this.managerDynamicDemographicsList.map(
                  (item) => item.controlName
                )
              : this.participantDynamicDemographicsList.map(
                  (item) => item.controlName
                ),
        };
        this.uploadType == "manager"
          ? this.data.setManagerDemographics(data)
          : this.data.setParticipantDemographics(data);
        this.router.navigate(["/company/company-dashboard/xl-dataUpdateNSave"]);
      } else {
        swal.fire({
          title: "Data Validation",
          text: "Something went wrong!",
          icon: "error",
          showConfirmButton: true,
        });
      }
    });
  }

  getCompanyDemographics() {
    let data = {
      type: this.uploadType == "manager" ? 1 : 2,
    };
    this.companyService.getCompanyDemographics(data).subscribe((res: any) => {
      if (res.status) {
        this.alreadyAddedDemographics = res.data;
      } else {
        swal.fire({
          title: "",
          text: "Something went wrong!",
          icon: "error",
          showConfirmButton: true,
        });
      }
    });
  }

  manageDemographicsInList(type: string, action: string, index: number) {
    if (this.demographic.trim() === "" && action == "add") {
      return; // If demographic is empty, do nothing
    }

    let tempList: string[] =
      type == "manager"
        ? this.managersDemographicsList
        : this.participantsDemographicsList;
    if (this.editDemographicList) {
      tempList.splice(this.editDemographicId, 1, this.demographic);
      this.editDemographicList = false;
      this.demographic = "";
      return;
    }
    switch (action) {
      case "add":
        if (tempList.includes(this.demographic)) {
          this.errorMessage = "Demographics already exists";
        } else {
          tempList.push(this.demographic);
          this.demographic = "";
        }
        break;
      case "edit":
        this.demographic = tempList[index];
        this.editDemographicList = true;
        this.editDemographicId = index;
        // tempList.splice(index, 1, this.demographic);
        break;
      case "remove":
        let removed = tempList.splice(index, 1);
        for (const controlName in this.mappingForm.controls) {
          if (this.mappingForm.controls.hasOwnProperty(controlName)) {
            if (controlName == removed[0].toLowerCase()) {
              this.mappingForm.removeControl(controlName);
            }
          }
        }
        break;
    }
  }
  deleteControl(controlName) {
    event.preventDefault();
    if (!this.compulsoryList.includes(controlName)) {
      this.mappingForm.removeControl(controlName);
      this.standardDemographicList = this.standardDemographicList.filter(
        (item) => item.controlName !== controlName
      );
      // if (this.uploadType == "manager") {
      //   this.managerDynamicDemographicsList =
      //     this.managerDynamicDemographicsList.filter(
      //       (item) => item.controlName !== controlName
      //     );
      // }
    } else {
      // swal({
      //   title: "",
      //   text: "Cannot delete this demographic!",
      //   type: "error",
      //   showConfirmButton: true,
      // });
    }
  }

  // DONE check if demographic exist or not in form before adding
  saveDemographics(type: any) {
    switch (type) {
      case "manager":
        this.managersDemographicsList.forEach((element) => {
          let obj = {
            label: element,
            controlName: camelCase(element),
          };
          !this.managerDynamicDemographicsList.find(
            (item) => item.label == element
          ) && this.managerDynamicDemographicsList.push(obj);
        });
        this.managerDynamicDemographicsList =
          this.managerDynamicDemographicsList.filter((obj) =>
            this.managersDemographicsList.includes(obj.label)
          );
        if (this.managerDynamicDemographicsList.length > 0) {
          this.managerDynamicDemographicsList.forEach((element) => {
            this.mappingForm.addControl(
              element.controlName,
              this.fb.control("", Validators.required)
            );
          });
        }
        this.isManagerUpload = !this.isManagerUpload;
        break;
      case "participant":
        this.participantsDemographicsList.forEach((element) => {
          let obj = {
            label: element,
            controlName: camelCase(element),
          };
          if (this.participantDynamicDemographicsList.length > 0) {
            !this.participantDynamicDemographicsList.find(
              (item) => item.label == element
            ) && this.participantDynamicDemographicsList.push(obj);
          } else {
            this.participantDynamicDemographicsList.push(obj);
          }
        });
        this.participantDynamicDemographicsList =
          this.participantDynamicDemographicsList.filter((obj) =>
            this.participantsDemographicsList.includes(obj.label)
          );
        if (this.participantDynamicDemographicsList.length > 0) {
          this.participantDynamicDemographicsList.forEach((element) => {
            this.mappingForm.addControl(
              element.controlName,
              this.fb.control("", Validators.required)
            );
          });
        }
        this.isParticipantUpload = !this.isParticipantUpload;
        break;
    }
  }
  openDemographicInput() {
    this.uploadType == "manager"
      ? (this.isManagerUpload = !this.isManagerUpload)
      : (this.isParticipantUpload = !this.isParticipantUpload);
  }
  getDecodedAccessToken(token: string): any {
    try {
      return jwtDecode(token);
    } catch (Error) {
      return null;
    }
  }
  changeValidators(e: any) {
    const empEmailControl = this.mappingForm.get("empEmail");
    const empDobControl = this.mappingForm.get("empDob");
    if (
      e == "Feedback giver's Employee  Id" &&
      empEmailControl &&
      empDobControl
    ) {
      empEmailControl.clearValidators();
      empEmailControl.setValidators(null);
      empEmailControl.updateValueAndValidity();

      empDobControl.setValidators([Validators.required]);
      empDobControl.updateValueAndValidity();
    }
    // ) {
    // this.mappingForm.get('empEmail').clearValidators();
    // this.mappingForm.get('empEmail').setValidators(null);
    // this.mappingForm.get('empEmail').updateValueAndValidity();
    // this.mappingForm.get('empDob').setValidators([Validators.required]);
    // this.mappingForm.get('empDob').updateValueAndValidity();
  }
}

export interface demographicModel {
  label: string;
  controlName: string;
}
