import { LoginServices } from '../../../login/login.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormControlName,
  Validators,
} from '@angular/forms';
// import { Response } from "@angular/http";
import Swal from 'sweetalert2';

import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  data: any;
  modal: any;
  el: HTMLElement;
  loading: boolean;

  @ViewChild('myDiv') myDiv: ElementRef;

  constructor(private ls: LoginServices, private router: Router) {}

  ngOnInit() {
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      role: new FormControl('super_admin'),
    });
  }

  onResetPassword() {}

  onforgotpassword() {
    this.loading = true;
    this.ls
      .forgotPassword(this.forgotPasswordForm.value)
      .subscribe((response: Response) => {
        this.data = response;
        if (this.data.status) {
          this.loading = false;
          this.el = this.myDiv.nativeElement as HTMLElement;
          this.el.click();
        } else {
          Swal.fire({
            title: 'Forgot Password',
            text: this.data.message,
            icon: 'error',
            showConfirmButton: true,
            timer: 3500,
          });
          this.loading = false;
        }
      });
  }

  checkemail() {
    if (this.forgotPasswordForm.value.email) {
      return false;
    } else {
      return true;
    }
  }

  onSet() {
    this.router.navigate(['/admin']);
  }

  onClickX() {
    this.router.navigate(['/admin']);
  }
}
