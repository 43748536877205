import { Injectable } from "@angular/core";
import { config } from "../config";

@Injectable({
  providedIn: "root",
})
export class TokenService {
  private bearerToken: string = config.lfiToken;

  setBearerToken(token: string): void {
    this.bearerToken = token;
  }

  getBearerToken(): string {
    return this.bearerToken;
  }
}
