import { Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormControlName,
  Validators,
  FormsModule,
} from '@angular/forms';
// import { Response } from "@angular/http";
import Swal from 'sweetalert2';
import { LoginServices } from '../../login/login.service';
import * as jwt_decode from 'jwt-decode';
import { GoogleRecaptchaComponent } from '../../google-recaptcha/google-recaptcha.component';
import { jwtDecode } from 'jwt-decode';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  data: any;
  userId: any;
  roles = [];
  isVerifiedCaptcha: boolean = false;
  captchaResponse: String = '';
  @ViewChild(GoogleRecaptchaComponent) googleCaptcha: GoogleRecaptchaComponent;

  constructor(private loginService: LoginServices, private router: Router) {}

  ngOnInit() {
    this.loginForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, Validators.required),
      selectedRole: new FormControl('admin', Validators.required),
    });
    this.checkAndRedirectToUrl();
  }

  checkAndRedirectToUrl() {
    let role = localStorage.getItem('role');
    console.log(role);
    if (role && role.toLowerCase() === 'admin') {
      this.router.navigate(['/admin/admin-dashboard/companies']);
    } else {
      localStorage.clear();
    }
  }

  loginSubmit() {
    this.loginForm.value.selectedRole = 'admin';
    this.loginForm.value.captchaResponse = this.captchaResponse;
    this.loginService
      .login(this.loginForm.value)
      .subscribe((response: Response) => {
        this.data = response;
        if (this.data.status) {
          localStorage.setItem('token', this.data.token);
          let decodeToken: any = jwtDecode(this.data.token);
          console.log(decodeToken, 'k;kl');
          localStorage.setItem('admin_name', decodeToken.name);
          this.loginService
            .getUniversalLogin({ email: this.loginForm.value.email })
            .subscribe((res) => {
              if (res.status) {
                // this.roles = res.role.roles;
                if (res.universalLogin.roles.length > 1) {
                  this.roles = res.universalLogin.roles;
                  localStorage.setItem('roles', JSON.stringify(this.roles));
                }
              }
            });
          localStorage.setItem('role', decodeToken.role);
          this.router.navigate(['/admin/admin-dashboard/companies']);
        } else {
          Swal.fire({
            title: 'login',
            text: this.data.message,
            icon: 'error',
            showConfirmButton: true,
          });
        }
        if (this.googleCaptcha != null && this.googleCaptcha != undefined) {
          this.googleCaptcha.resetCapcha();
        }
        this.isVerifiedCaptcha = false;
      });
  }

  onCaptchaSuccess(captchaResponse) {
    if (captchaResponse == null) {
      this.isVerifiedCaptcha = false;
    } else {
      this.isVerifiedCaptcha = true;
    }
    this.captchaResponse = captchaResponse;
  }

  onCaptchaError(captchaError) {
    this.isVerifiedCaptcha = false;
    this.captchaResponse = '';
  }
}
