import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminServices } from '../admin.service';
// import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import swal from 'sweetalert2';

@Component({
  selector: 'app-assign-mentor',
  templateUrl: './assign-mentor.component.html',
  styleUrls: ['./assign-mentor.component.css'],
})
export class AssignMentorComponent implements OnInit {
  managerId: any;
  loading: any;
  displayedColumns = ['select', 'name', 'email'];
  dataSource = new MatTableDataSource();
  managerLists = [];
  count = 0;
  selectedManagers = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  checkall: boolean;
  displayNoRecords: boolean;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private adminService: AdminServices
  ) {}

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit() {
    this.managerId = { id: this.route.snapshot.params['managerId'] };
    this.getAllManagerLists(this.managerId);
  }

  getAllManagerLists(id) {
    this.loading = true;
    this.adminService.getallMentors({}).subscribe((res) => {
      this.loading = false;

      if (res.status) {
        // res.data.forEach(mentor =>{
        // 	if(mentor.managers.length > 0){
        //     mentor.managers.forEach(manager => {
        //       console.log(manager.managerId);
        //       console.log(this.managerId.id);
        //       if(manager.managerId == this.managerId.id){
        //         console.log("matched");
        //         mentor.type = 'in'
        //       }else{
        //         mentor.type = 'out'
        //       }
        //     })
        //   }else{
        //     mentor.type = 'out'

        //   }
        // })
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].managers.length > 0) {
            for (let j = 0; j < res.data[i].managers.length; j++) {
              if (res.data[i].managers[j].managerId == this.managerId.id) {
                res.data[i].type = 'in';
                break;
              } else {
                res.data[i].type = 'out';
              }
            }
          } else {
            res.data[i].type = 'out';
          }
        }
        this.dataSource.data = res.data;
        this.managerLists = res.data;
      }
    });
  }

  checkUncheckAll() {
    if (this.checkall) {
      this.checkall = true;
    } else {
      this.checkall = false;
    }
    this.managerLists.forEach((selectedManager) => {
      if (selectedManager.type !== 'in') {
        selectedManager.checked = this.checkall;
        this.onChange(selectedManager, selectedManager.checked);
      }
    });
  }

  updateCheckall(participant: any, isChecked: boolean) {
    ////console.log(isChecked);
    ////console.log(participant)
    var userTotal = this.managerLists.length;
    var count = 0;
    if (isChecked) {
      this.count++;
      if (!this.selectedManagers.includes(participant)) {
        this.selectedManagers.push(participant);
      }
    } else {
      this.count--;
      let index = this.selectedManagers.indexOf(participant);
      this.selectedManagers.splice(index, 1);
    }
    if (userTotal == this.count) {
      this.checkall = true;
    } else {
      this.checkall = false;
    }
  }

  onChange(selectedManger: any, isChecked: boolean) {
    if (isChecked) {
      this.count++;
      if (!this.selectedManagers.includes(selectedManger)) {
        this.selectedManagers.push(selectedManger);
      }
    } else {
      this.count--;
      let index = this.selectedManagers.indexOf(selectedManger);
      this.selectedManagers.splice(index, 1);
    }
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
    if (this.dataSource.filteredData.length == 0) {
      this.displayNoRecords = true;
    } else {
      this.displayNoRecords = false;
    }
  }
  addSelectedManagers() {
    let data = {
      managerId: this.managerId.id,
      selectedManagers: this.selectedManagers,
    };
    this.loading = true;
    this.adminService.assignMentor(data).subscribe((res) => {
      this.loading = false;
      if (res.status) {
        // this.getAllManagerLists(this.managerId);
        this.router.navigate['/admin/admin-dashboard/mentor'];
        swal.fire({
          title: 'Mentor assigned',
          text: 'Mentor assigned successfully',
          icon: 'success',
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        swal.fire({
          title: 'Oops',
          text: 'Somethning went wrong',
          icon: 'error',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    });
    this.selectedManagers = [];
  }
  removeMentorFromManager(id) {
    let data = {
      mentorId: id,
      managerId: this.managerId.id,
    };
    this.loading = true;
    this.adminService.removeMentorFromManager(data).subscribe((res) => {
      this.loading = false;
      if (res.status) {
        this.getAllManagerLists(this.managerId);
        this.router.navigate['/admin/admin-dashboard/mentor'];
        swal.fire({
          title: 'Manager removed',
          text: res.message,
          icon: 'success',
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        swal.fire({
          title: 'Manager removed',
          text: res.message,
          icon: 'error',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    });
    this.selectedManagers = [];
  }
}
