import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
@Injectable({
  providedIn: "root",
})
// @Injectable()
export class DataService {
  private _companyData = {};
  private managerDemographicsSource = new BehaviorSubject({});
  managerDemographics = this.managerDemographicsSource.asObservable();
  setManagerDemographics(message: any) {
    this.managerDemographicsSource.next(message);
  }
  private participantDemographicsSource = new BehaviorSubject({});
  participantDemographic = this.participantDemographicsSource.asObservable();
  setParticipantDemographics(message: any) {
    this.participantDemographicsSource.next(message);
  }

  private uploadType = new BehaviorSubject("manager");
  uploadType$ = this.uploadType.asObservable();
  setUploadType(message: string) {
    this.uploadType.next(message);
  }

  private pisHeaderDataSource = new BehaviorSubject({});
  pisHeaderData = this.pisHeaderDataSource.asObservable();
  setPisHeaderData(message: any) {
    this.pisHeaderDataSource.next(message);
  }

  mappedData(message: any) {
    this.managerDemographicsSource.next(message);
  }
  decodeToken(token: string) {
    try {
      return jwt_decode(token);
    } catch (error) {
      return null;
    }
  }

  getToken() {
    return this._companyData;
  }
}
function jwt_decode(token: string) {
  throw new Error("Function not implemented.");
}
