<a (click)="openApFormsModal(apForms)" class="action-plan-heading"
  >Assign Action Plan form
</a>

<ng-template #apForms>
  <div class="modal-header">
    <h4 style="font-size: 22px" class="modal-title pull-left">
      Assign Action plans Forms to your selected managers ({{
        selectedManagers.length
      }})
    </h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div
    *ngIf="selectedManagers.length > 0; else showAddManagerMessage"
    class="modal-body"
  >
    <mat-tab-group>
      <!-- tab1 -->
      <mat-tab label="Action Plan Forms">
        <div class="row">
          <div class="col-md-6">
            <h3>Assign Ap form</h3>
            <form>
              <div class="form-group">
                <label for="formName">Form Name</label>
                <ngx-select-dropdown
                  (change)="selectForms($event, 'apForms')"
                  [multiple]="true"
                  [config]="configDropdownApForms"
                  [options]="apFormName"
                  class="mb-2"
                ></ngx-select-dropdown>
              </div>
              <div class="form-group">
                <button
                  class="btn btn-primary"
                  (click)="submitForms($event, 'actionPlan')"
                  [disabled]="!selectedApFormName"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
          <div class="col-md-6">
            <h3>
              Sample
              {{ selectedApFormName ? selectedApFormName : "form name" }}
              question
            </h3>
            <div class="error" *ngIf="selectedApFormName === undefined">
              <h4 class="question">Please select form name</h4>
            </div>

            <div
              *ngFor="let question of selectedApFormQuestion; let i = index"
              class="question"
            >
              <h4>Statement ({{ i + 1 }}) {{ question.text }}</h4>
              <p>{{ question.type }}</p>
            </div>
          </div>
        </div>
      </mat-tab>
      <!-- tab2  -->
      <mat-tab label="Implementation Forms">
        <div class="row">
          <div class="col-md-6">
            <h3>Assign Implementation form</h3>
            <form>
              <div class="form-group">
                <label for="formName">Form Name</label>
                <ngx-select-dropdown
                  (change)="selectForms($event, 'impleForms')"
                  [multiple]="true"
                  [config]="configDropdownImpleForms"
                  [options]="impleFormName"
                  class="mb-2"
                ></ngx-select-dropdown>
              </div>
              <div class="form-group">
                <button
                  class="btn btn-primary"
                  (click)="submitForms($event, 'implementation')"
                  [disabled]="!selectedImpleFormName"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
          <div class="col-md-6">
            <h3>
              Sample
              {{ selectedImpleFormName ? selectedImpleFormName : "form name" }}
              question
            </h3>
            <div class="error" *ngIf="selectedImpleFormName === undefined">
              <h4 class="question">Please select form name</h4>
            </div>
            <ng-container *ngIf="selectedImpleFormName !== undefined">
              <div
                *ngFor="
                  let question of selectedImpleFormQuestion;
                  let i = index
                "
                class="question"
              >
                <h4>Statement ({{ i + 1 }}) {{ question.text }}</h4>
                <p>{{ this.selectedImpleFormName }}</p>
                <p>{{ question.type }}</p>
                <p>{{ question.formName }}</p>
              </div>
            </ng-container>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</ng-template>
<ng-template #showAddManagerMessage>
  <p class="title">Select at least 1 manager to assign form</p>
</ng-template>
