import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormControlName,
  Validators,
} from '@angular/forms';
import { HttpResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginServices } from '../../../login/login.service';
import { CompanyServices } from '../../company.service';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent implements OnInit {
  newPasswordForm!: FormGroup;
  data: any;
  id: any;
  eyeIcon = 'fa-eye-slash';
  eyeIcon1 = 'fa-eye-slash';
  loading: boolean | undefined;
  passwordError = true;
  confirmPasswordError = true;
  constructor(
    private ls: LoginServices,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private companyService: CompanyServices
  ) {}

  ngOnInit() {
    this.newPasswordForm = new FormGroup({
      newPassword: new FormControl(null, Validators.required),
      confirmPassword: new FormControl(null, Validators.required),
      role: new FormControl('company'),
    });
  }
  togglePasswordView() {
    if (this.eyeIcon == 'fa-eye') {
      this.eyeIcon = 'fa-eye-slash';
      document.getElementById('passwordView')?.setAttribute('type', 'password');
    } else {
      this.eyeIcon = 'fa-eye';
      document.getElementById('passwordView')?.setAttribute('type', 'text');
    }
  }
  togglePasswordView1() {
    if (this.eyeIcon1 == 'fa-eye') {
      this.eyeIcon1 = 'fa-eye-slash';
      document
        .getElementById('passwordView1')
        ?.setAttribute('type', 'password');
    } else {
      this.eyeIcon1 = 'fa-eye';
      document.getElementById('passwordView1')?.setAttribute('type', 'text');
    }
  }
  passwordCheck() {
    if (this.newPasswordForm?.value.newPassword == '') {
      this.passwordError = true;
    } else {
      if (this.validatePassword(this.newPasswordForm?.value.newPassword)) {
        this.passwordError = false;
      } else {
        Swal.fire({
          title: 'Reset password',
          text: 'Invalid password pattern',
          icon: 'error',
          timer: 2000,
        });
      }
    }
  }

  validatePassword(password: any) {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(password);
  }
  confirmPasswordCheck() {
    if (this.newPasswordForm?.value.confirmPassword == '') {
      this.confirmPasswordError = true;
    } else {
      this.confirmPasswordError = false;
    }
  }

  newPassword() {
    this.loading = true;
    if (
      this.newPasswordForm?.value.newPassword ==
      this.newPasswordForm?.value.confirmPassword
    ) {
      this.activatedRoute.params.subscribe((params) => {
        this.id = params['id'];
      });
      var data = {
        token: this.id,
        password: this.newPasswordForm?.value.newPassword,
        role: 'company',
      };
      this.ls.resetPassword(data).subscribe((response: Response) => {
        this.data = response;
        if (this.data.status) {
          Swal.fire({
            title: 'Reset password',
            text: this.data.message,
            icon: 'success',
            timer: 2000,
          }).then((value: any) => {
            this.router.navigate(['/company']);
          });
          this.loading = false;
        } else {
          Swal.fire({
            title: 'Reset password',
            text: this.data.message,
            icon: 'error',
            timer: 2000,
          });
          this.loading = false;
        }
      });
    } else {
      Swal.fire({
        title: 'Reset password',
        text: 'Confirm password and password should be same',
        icon: 'error',
        timer: 2000,
      });
    }
    this.loading = false;
  }
}
