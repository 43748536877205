<div class="summaryReport">
  <div class="row">
    <div class="col-sm-12">
      <div class="form-group">
        <label for="reportTypeSelect">Description:</label>
        <p>
          This section provides dynamic summary reports through different
          combinations of Report-types & Demographics.
        </p>
      </div>
    </div>
  </div>
  <div class="filterSection">
    <div class="row">
      <div class="col-sm-3">
        <div class="form-group">
          <label for="summaryReportType">Report Type:</label>
          <select
            class="form-control"
            id="summaryReportType"
            (change)="changeSummaryFilters($event, 'type')"
            [(ngModel)]="summaryReportType"
          >
            <option value="">Select</option>
            <option value="moduleCompletion">Module Completion</option>
            <!-- <option value="tierWise">Tier-wise Completion</option> -->
            <option value="moduleWise">Module-wise Completion</option>
            <option value="dimensionWise">Dimension-wise Completion</option>
            <option value="actionPlan">Action plan</option>
            <option value="surveySummary">Survey</option>
          </select>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="form-group">
          <label for="demographicSummary">Demographic:</label>
          <select
            class="form-control"
            id="demographicSummary"
            (change)="changeSummaryFilters($event, 'filter')"
            [(ngModel)]="summaryFilter"
          >
            <option value="">Select</option>
            <option *ngFor="let list of dropdownList" [value]="list.id">
              {{ list.itemName }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="form-group">
          <label for="summaryResult">Value:</label>
          <select
            class="form-control"
            id="summaryResult"
            (change)="changeSummaryFilters($event, 'value')"
            [(ngModel)]="summaryCellValue"
          >
            <option value="" disabled selected>Select</option>
            <option *ngFor="let list of summaryResult" [value]="list.value">
              {{ list.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-sm-3"></div>
    </div>
  </div>
  <div class="filters">
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label for="reportTypeSelect">Filters:</label>
          <angular2-multiselect
            [data]="finalDropdownList"
            [(ngModel)]="selectedItems"
            [settings]="dropdownSettings"
            (onSelect)="onItemSelect($event)"
            (onDeSelect)="OnItemDeSelect($event)"
            (onSelectAll)="onSelectAll($event)"
            (onDeSelectAll)="onDeSelectAll($event)"
          >
          </angular2-multiselect>
        </div>
      </div>
      <div class="col-sm-4" style="display: flex; margin-top: 20px;">
        <button
          class="btn gmi-primary-btn refreshData"
          (click)="getSummaryReport()"
          [disabled]="
            summaryReportType == '' ||
            summaryFilter == '' ||
            summaryCellValue == ''
          "
        >
          Get Result
        </button>
        <!-- <button class="btn btn-primary refreshData" (click)="onReloadReport()">Apply Filters</button> -->
        <button
          class="btn gmi-primary-btn downloadExcel"
          (click)="getSummaryReport(true)"
        >
          Download Excel
        </button>
      </div>
      <div class="col-sm-4"></div>
    </div>
    <div class="row">
      <div
        class="col-sm-3"
        *ngFor="let filters of finalDisplayFilters; let i = index"
      >
        <div class="form-group">
          <label for="reportTypeSelect">{{ filters.filterName }}:</label>
          <angular2-multiselect
            [data]="filters.newOptions"
            [(ngModel)]="filters.selectedItems"
            [settings]="dropdownSettings2"
            (onSelect)="onFilterItemSelect($event)"
            (onDeSelect)="onFilterItemDeSelect($event)"
            (onSelectAll)="onFilterSelectAll($event, filters.dbName)"
            (onDeSelectAll)="onFilterDeSelectAll($event, filters.dbName)"
          >
          </angular2-multiselect>
        </div>
      </div>
    </div>
  </div>
  <div class="summaryTableSection">
    <app-bounce-loader *ngIf="loading"></app-bounce-loader>
    <div class="row">
      <div class="col-sm-12">
        <div
          class="table-responsive"
          *ngIf="
            summaryReportdata != undefined &&
            summaryReportdata.length > 0 &&
            !loading
          "
        >
          <table class="table table-hover">
            <thead>
              <tr>
                <th
                  *ngFor="
                    let item of summaryReportdata[0] | keyvalue: returnZero
                  "
                  [ngClass]="{ 'text-center': item.key != '_id' }"
                >
                  {{ item.key != "_id" ? item.key : "" }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of summaryReportdata; let i = index">
                <td
                  *ngFor="let item of data | keyvalue: returnZero; index as i2"
                  [ngClass]="{ 'text-center': i2 > 0 }"
                >
                  {{ item.value }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
