import { Component, OnInit, TemplateRef } from "@angular/core";
import { CompanyServices } from "../company.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import swal from "sweetalert2";
import { Router } from "@angular/router";
import { NgxDropdownConfig } from "ngx-select-dropdown";
@Component({
  selector: "app-action-plan-forms",
  templateUrl: "./action-plan-forms.component.html",
  styleUrls: ["./action-plan-forms.component.css"],
})
export class ActionPlanFormsComponent implements OnInit {
  selectedManagers = [];
  modalRef!: BsModalRef;
  apFormName: any;
  selectedApFormName!: string;
  selectedApFormQuestion: any = [];
  apForms: any = [];
  impleFormName: any;
  impleForms: any = [];
  selectedImpleFormName: any;
  impleFormQuestion: any;
  selectedImpleFormQuestion: any = [];
  allSelectedApForms: any;
  allSelectedImpleForms: any;
  configDropdownApForms: NgxDropdownConfig = {
    displayKey: "name",
    search: true,
    height: "auto",
    placeholder: "Select form",
    // customComparator: () => {},
    customComparator: (optionA: any, optionB: any) => {
      if (optionA.name < optionB.name) {
        return -1;
      } else if (optionA.name > optionB.name) {
        return 1;
      } else {
        return 0;
      }
    },
    limitTo: 10,
    moreText: "more",
    noResultsFound: "No results found!",
    searchPlaceholder: "Search",
    searchOnKey: "name",
    clearOnSelection: false,
    inputDirection: "",
  };
  configDropdownImpleForms: NgxDropdownConfig = {
    displayKey: "name",
    search: true,
    height: "auto",
    placeholder: "Select form",
    // customComparator: () => {},
    customComparator: (optionA: any, optionB: any) => {
      if (optionA.name < optionB.name) {
        return -1;
      } else if (optionA.name > optionB.name) {
        return 1;
      } else {
        return 0;
      }
    },
    limitTo: 10,
    moreText: "more",
    noResultsFound: "No results found!",
    searchPlaceholder: "Search",
    searchOnKey: "name",
    clearOnSelection: false,
    inputDirection: "",
  };
  constructor(
    private companyService: CompanyServices,
    private modalService: BsModalService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getSelectedManagers();
  }

  getSelectedManagers() {
    this.companyService.selectedmanagersForPackages.subscribe((response) => {
      this.selectedManagers = response;
    });
  }
  openApFormsModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: "my-modal" })
    );
    this.companyService.addModal(this.modalRef);
    this.getApForms();
  }
  getApForms() {
    this.companyService.getApForms().subscribe((response) => {
      this.apForms = response.apForms;
      this.impleForms = response.implementationForms;
      this.apFormName = this.apForms.map((apForm: any) => apForm?.formName);
      this.impleFormName = this.impleForms.map(
        (impleForm: any) => impleForm?.formName
      );
    });
  }

  selectForms(e: any, type: any) {
    if (e.value !== undefined && Object.keys(e.value).length !== 0) {
      switch (type) {
        case "apForms":
          this.selectedApFormName = [...e.value].pop();
          this.allSelectedApForms = e.value;
          this.selectedApFormQuestion = this.apForms?.find(
            (apForm: any) => apForm.formName === this.selectedApFormName
          ).question;
          break;
        case "impleForms":
          this.selectedImpleFormName = [...e.value].pop();
          this.allSelectedImpleForms = e.value;
          this.selectedImpleFormQuestion = this.impleForms?.find(
            (impleForm: any) =>
              impleForm.formName === this.selectedImpleFormName
          ).question;
      }
    } else {
      this.selectedApFormName =
        Object.keys(e.value).length === 0 ? undefined : e.value;
      this.selectedApFormQuestion = [];
      this.selectedImpleFormName =
        Object.keys(e.value).length === 0 ? undefined : e.value;
      this.selectedImpleFormQuestion = [];
    }
  }

  submitForms(e: any, type: any) {
    let data;
    switch (type) {
      case "actionPlan":
        data = {
          managers: this.selectedManagers,
          forms: this.allSelectedApForms,
          type,
        };

        break;
      case "implementation":
        data = {
          managers: this.selectedManagers,
          forms: this.allSelectedImpleForms,
          type,
        };

        break;
    }
    this.companyService.assignApForms(data).subscribe((response) => {
      if (response.status) {
        swal.fire({
          title: "Form Assigned",
          text: response.message,
          icon: "success",
          showConfirmButton: false,
          timer: 2500,
        });
        this.modalRef.hide();
        // this.modalRef = null;
        this.router.navigate(["/company"]);
      } else {
        swal.fire({
          title: "Form Assigned",
          text: response.message,
          icon: "error",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    });
  }
}
