import { Component, OnInit } from "@angular/core";
// import * as jwt_decode from 'jwt-decode';
import { jwtDecode } from "jwt-decode";
import { CompanyServices } from "../company.service";

@Component({
  selector: "app-reports",
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.scss"],
})
export class ReportsComponent implements OnInit {
  selectedFeatures: any = ["ap", "sas", "l&d"];
  totalRegisteredManagers = 0;
  access: any = {
    lDReportsAccess: false,
    summariesAccess: false,
    apReportsAccess: false,
    surveyReportsAccess: false,
  };
  constructor(private companyService: CompanyServices) {}

  ngOnInit() {
    this.decodeToken();
  }

  decodeToken() {
    if (localStorage.getItem("token") !== null) {
      let token: any = localStorage.getItem("token");
      let tokenData: any = jwtDecode(token);
      // console.warn('tokenData', tokenData);
      let selectedFeatures: any =
        typeof tokenData.selectedFeatures !== "undefined"
          ? tokenData.selectedFeatures
          : [];
      this.checkAccess(tokenData);
      // console.log('selectedFeatures', selectedFeatures);
      if (selectedFeatures.length > 0) {
        let tempSelected: any = [];
        selectedFeatures.forEach((feature: any) => {
          tempSelected.push(feature.type);
        });
        this.selectedFeatures = tempSelected;
      }
      // if (typeof tokenData.companyId !== "undefined") {
      //   this.getCompanyProfile(tokenData.companyId); //Get Company Profile
      // }
    }
  }
  getDecodedAccessToken(token: string): any {
    try {
      return jwtDecode(token);
    } catch (Error) {
      return null;
    }
  }
  checkAccess(decodeToken: any) {
    this.access.lDReportsAccess =
      decodeToken.access.filter((acc: any) => acc.value == "l_d_report")
        .length > 0;
    this.access.summariesAccess =
      decodeToken.access.filter((acc: any) => acc.value == "summaries_report")
        .length > 0;
    this.access.apReportsAccess =
      decodeToken.access.filter((acc: any) => acc.value == "ap_report").length >
      0;
    this.access.surveyReportsAccess =
      decodeToken.access.filter((acc: any) => acc.value == "survey_report")
        .length > 0;
  }
  // getCompanyProfile(companyId) {
  //   this.companyService
  //     .getCompanyProfile({ companyId: companyId })
  //     .subscribe((response) => {
  //       if (response.status) {
  //         if (
  //           typeof response.totalRegisteredManagers !== "undefined" &&
  //           response.totalRegisteredManagers !== null
  //         ) {
  //           this.totalRegisteredManagers = response.totalRegisteredManagers;
  //         }
  //       }
  //     });
  // }
}
