import { AdminServices } from '../admin.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataSource } from '@angular/cdk/table';
import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  TemplateRef,
  ElementRef,
} from '@angular/core';
// import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
// import { Observable } from 'rxjs/Observable';
// import 'rxjs/add/observable/of';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-create-coupon',
  templateUrl: './create-coupon.component.html',
  styleUrls: ['./create-coupon.component.css'],
})
export class CreateCouponComponent implements OnInit {
  couponForm: FormGroup;
  discountPercentage: any;
  isdiscountPercentageValid: boolean;
  discountPercentageStatus: any;
  loading: boolean;
  inputdiscountPercentageBgColor: any;
  inputdiscountPercentageTextColor: any;
  displayedColumns = [
    'code',
    'company',
    'feature',
    'discount',
    'userCount',
    'couponStatus',
    'action',
  ];
  data: any;
  pageEvent: PageEvent;
  offset = 0;
  limit = 20;
  dataSource = new MatTableDataSource();

  constructor(
    private router: Router,
    private adminService: AdminServices,
    private modalService: BsModalService,
    public snackBar: MatSnackBar,
    private fb: FormBuilder
  ) {}
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  ngOnInit() {
    this.couponForm = this.fb.group({
      discountPercentage: [
        null,
        [Validators.required, Validators.pattern('^[1-9][0-9]?$|^100$')],
      ],
      package: [null, [Validators.required]],
    });

    this.getData();
  }
  ngAfterViewInit() {
    // this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    // console.log("sort",this.sort.sortChange);
    // console.log("pagination",this.paginator.page);
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
  changeGlobalCouponStatus(objectId, status) {
    //console.log(objectId);
    //console.log(status);
    this.adminService
      .changeGlobalCouponStatus(objectId, !status)
      .subscribe((response: any) => {
        if (response.status) {
          this.ngOnInit();
        } else {
        }
      });
  }
  onAddPackage(formData) {
    //console.log("submitted");
    //console.log(formData.value);
    this.adminService
      .addCoupondiscountPercentage(
        formData.value.discountPercentage,
        formData.value.package
      )
      .subscribe((response: any) => {
        //console.log(response);
        if (response.status) {
        } else {
          Swal.fire({
            title: 'Create coupon',
            text: response.message,
            icon: 'error',
            showConfirmButton: false,
            timer: 2500,
          });
        }
        this.ngOnInit();
      });
  }

  getData() {
    let params = {
      offset: this.offset,
      limit: this.limit,
    };
    this.adminService.getAllGlobalCoupons(params).subscribe((response: any) => {
      if (response.status) {
        this.dataSource.data = response.data;
        this.data = response.data;
        this.dataSource.paginator = this.paginator;
      }
    });
  }

  pageChange(event) {
    let pageIndex = event.pageIndex;
    let pageSize = event.pageSize;
    let previousIndex = event.previousPageIndex;
    if (pageIndex > previousIndex) {
      this.offset = this.offset + pageSize;
      this.limit = this.limit + pageSize;
    } else {
      this.offset = this.offset - pageSize;
      this.limit = this.limit - pageSize;
    }
    this.getData();
  }
}
