import { AdminServices } from "../admin.service";
import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  TemplateRef,
} from "@angular/core";
// import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

// import 'rxjs/add/observable/of';
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-certification-moderation",
  templateUrl: "./certification-moderation.component.html",
  styleUrls: ["./certification-moderation.component.css"],
})
export class CertificationModerationComponent implements OnInit {
  data: any;
  managerData: any;
  displayedColumns = [
    "name",
    "email",
    "surveys",
    "actionplanning",
    "participants",
    "information",
    "actions",
    "status",
  ];
  acceptcontent =
    "Welcome to Great Manager Institute® - a global digital institute for personalized education and certification of People Managers.Congrats your have been certified";
  rejectcontent =
    "Welcome to Great Manager Institute® - a global digital institute for personalized education and certification of People Managers.Sorry your have been rejected";
  showSubject: any;
  showacceptSubject: any;
  showrejectSubject: any;
  acceptsubject: any = "Survey accept";
  rejectsubject: any = "Survey reject";
  showDesc: any;
  acceptButtonColor: any = "color: rgba(0,0,0,.38);";
  rejectButtonColor: any = "color: rgba(0,0,0,.38);";
  modalRef: BsModalRef;
  currentSelected: any;
  error = {};
  touch = {};
  emailData: any;
  emailRejectData: any;
  loading: boolean;
  participantData: any;
  emailAcceptData: any;
  valid: boolean = true;
  dataSource = new MatTableDataSource();
  emailDefaultData: any;
  managerSurvey: any;
  managerActionPlanning: any;
  meritDataSource: MatTableDataSource<CertificateData>;
  gpmDataSource = new MatTableDataSource();
  meritColumns = ["Name", "Email", "Company", "Action"];
  data1: any;
  filteredValue: any;
  index: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private adminService: AdminServices,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.loading = true;
    // this.error["email"] = true;
    // this.touch["email"] = false;
    // this.adminService.getCertifiedManagers().subscribe((response: any) => {
    //   this.data = response.data;
    //   if (this.data.length > 0) {
    //     this.data.forEach((data, index) => {
    //       data.managerModerationStatus =
    //         data.managerInfo.managerModerationStatus;
    //       data.name = data.personalInfo.name;
    //       data.email = data.personalInfo.email;
    //       data.participantsLength = data.participants.length;
    //       data.surveys = response.surveys[index];
    //       data.actionPlanning = response.actionPlanning[index];
    //     });
    //     this.dataSource.data = this.data;
    //   }
    //   this.loading = false;
    // });
    // this.adminService.getAcceptTemplate().subscribe((response: any) => {
    //   this.emailAcceptData = response.acceptData;
    //   this.emailRejectData = response.rejectData;
    // });

    this.onLoad();
  }

  // to switch between pages in table
  ngAfterViewInit() {
    //this.dataSource.paginator = this.paginator;
    //this.dataSource.sort = this.sort;
  }

  onLoad() {
    this.adminService.certificateOfMerit().subscribe((res) => {
      if (res.status === true) {
        this.data1 = res.data;
        this.data1.forEach((data1, index) => {
          (data1.company = data1.personalInfo.company),
            (data1.name = data1.personalInfo.name),
            (data1.email = data1.personalInfo.email),
            (data1.isCertificateOfMerit =
              data1.personalInfo.isCertificateOfMerit);
        });

        this.meritDataSource = new MatTableDataSource(this.data1);
        this.meritDataSource.paginator = this.paginator;
      }
      //console.log("certificateOfMerit", this.meritDataSource);
    });
  }

  identifyTab(tab) {
    this.index = tab.index;
    switch (tab.index) {
      case 0:
        this.adminService.certificateOfMerit().subscribe((res) => {
          //console.log("certificateOfMerit",res);
          if (res.status === true) {
            this.data1 = res.data;
            this.data1.forEach((data1, index) => {
              (data1.company = data1.personalInfo.company),
                (data1.name = data1.personalInfo.name),
                (data1.email = data1.personalInfo.email),
                (data1.isCertificateOfMerit =
                  data1.personalInfo.isCertificateOfMerit);
            });
          }
          this.meritDataSource = new MatTableDataSource(this.data1);
          this.meritDataSource.paginator = this.paginator;
        });
        // applyFiltergetFilterValue(this.certiOfMeritDataSource);
        break;
      case 1:
        this.error["email"] = true;
        this.touch["email"] = false;

        this.adminService.getCertifiedManagers().subscribe((response: any) => {
          this.loading = true;
          this.data = response.data;
          if (this.data.length > 0) {
            this.data.forEach((data, index) => {
              data.managerModerationStatus =
                data.managerInfo.managerModerationStatus;
              data.name = data.personalInfo.name;
              data.email = data.personalInfo.email;
              data.participantsLength = data.participants.length;
              data.surveys = response.surveys[index];
              data.actionPlanning = response.actionPlanning[index];
            });
            this.dataSource.data = this.data;
            this.dataSource.paginator = this.paginator;
          }
          this.loading = false;
        });
        this.adminService.getAcceptTemplate().subscribe((response: any) => {
          this.emailAcceptData = response.acceptData;
          this.emailRejectData = response.rejectData;
        });
        console.log("heeyyyyy", tab.index);
        break;
      case 2:
        console.log("heeyyyyy", tab.index);
        break;
      case 3:
        console.log("heeyyyyy", tab.index);
        break;
      default:
        break;
    }
  }

  // to search in data table
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  // show manager information like email, name, user_name etc
  openManagerModal(template: TemplateRef<any>, managerId) {
    this.adminService
      .getManagerProfile(managerId)
      .subscribe((response: any) => {
        this.managerData = response.data;
      });
    this.modalRef = this.modalService.show(template);
    this.adminService.addModal(this.modalRef);
  }

  // to take action on manager certification
  openActionModal(template: TemplateRef<any>, managerId) {
    const config = { class: "modal-lg" };
    this.currentSelected = "";
    this.rejectButtonColor = "white";
    this.acceptButtonColor = "white";
    this.valid = true;
    this.modalRef = this.modalService.show(template, config);
    this.adminService.addModal(this.modalRef);
  }

  // to show participants details of a manager
  openParticipantsModal(template: TemplateRef<any>, participants) {
    this.participantData = participants;
    this.modalRef = this.modalService.show(template);
    this.adminService.addModal(this.modalRef);
  }

  // to show surcey details
  openSurveyModal(template: TemplateRef<any>, managerId, participants) {
    this.adminService.getManagerSurvey(managerId).subscribe((response: any) => {
      this.managerSurvey = response.data;
      if (this.managerSurvey.length > 0) {
        this.managerSurvey.forEach((data, index) => {
          let date1 = new Date(this.managerSurvey[index].startDate);
          let date2 = new Date(this.managerSurvey[index].endDate);
          let year = date1.getFullYear();
          let month = date1.getMonth();
          let day = date1.getDate();
          let year1 = date2.getFullYear();
          let month1 = date2.getMonth();
          let day1 = date2.getDate();
          let dateFormat = "";
          let dateFormat2 = "";
          // let months = ['Jan' , 'Feb' , 'Mar' , 'Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
          // let monthNames = ["January", "February", "March", "April", "May", "June",
          // "July", "August", "September", "October", "November", "December"];
          dateFormat = `${day}/${month + 1}/${year}`;
          dateFormat2 = `${day1}/${month1 + 1}/${year1}`;
          data.startDate = dateFormat;
          data.endDate = dateFormat2;
        });
      }
    });
    this.participantData = participants;
    this.modalRef = this.modalService.show(template);
    this.adminService.addModal(this.modalRef);
  }

  //  open manager action planning
  openActionPlanningModal(template: TemplateRef<any>, managerId) {
    this.adminService
      .getManagerActionPlanning(managerId)
      .subscribe((response: any) => {
        this.managerActionPlanning = response.data;
      });
    this.modalRef = this.modalService.show(template);
    this.adminService.addModal(this.modalRef);
  }

  // on click accept or reject any manager's certification
  action(val) {
    this.valid = false;
    if (val == "accept") {
      this.acceptButtonColor = "green";
      this.currentSelected = "accept";
      this.rejectButtonColor = "white";
    } else if (val == "reject") {
      this.rejectButtonColor = "red";
      this.currentSelected = "reject";
      this.acceptButtonColor = "white";
    }
  }

  // to change button color
  changeAcceptColor() {
    return { "background-color": this.acceptButtonColor };
  }

  // to change button color
  changeRejectColor() {
    return { "background-color": this.rejectButtonColor };
  }

  // to revoke certificate of a manager
  revoke(id) {
    Swal.fire({
      title: "Are you sure you want to revoke?",
      text: "Once revoked, he will be no more certified",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, revoke it!",
    }).then((revoke) => {
      if (revoke.value) {
        this.adminService.revokeCertificate(id).subscribe((response: any) => {
          if (response.status) {
            this.loading = true;
            this.adminService
              .getCertifiedManagers()
              .subscribe((response: any) => {
                this.data = response.data;
                if (this.data.length > 0) {
                  this.data.forEach((data, index) => {
                    data.managerModerationStatus =
                      data.managerInfo.managerModerationStatus;
                    data.name = data.personalInfo.name;
                    data.email = data.personalInfo.email;
                    data.participantsLength = data.participants.length;
                    data.surveys = response.surveys[index];
                    data.actionPlanning = response.actionPlanning[index];
                  });
                  this.dataSource.data = this.data;
                }
                this.loading = false;
              });
            Swal.fire({
              title: "Revoke certificate",
              text: "Revoke certificate",
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
          } else {
            Swal.fire({
              title: "Revoke certificate",
              text: "Something went wrong, please try again",
              icon: "error",
              showConfirmButton: false,
              timer: 2500,
            });
          }
        });
      }
    });
  }

  // on submit company details
  onSubmit(email, id) {
    this.loading = true;
    if (this.currentSelected == "reject") {
      this.adminService
        .managerCertification(email, this.currentSelected, this.rejectcontent)
        .subscribe((response: any) => {
          if (response.status) {
            this.loading = true;
            this.adminService
              .getCertifiedManagers()
              .subscribe((response: any) => {
                this.data = response.data;
                if (this.data.length > 0) {
                  this.data.forEach((data, index) => {
                    data.managerModerationStatus =
                      data.managerInfo.managerModerationStatus;
                    data.name = data.personalInfo.name;
                    data.email = data.personalInfo.email;
                    data.participantsLength = data.participants.length;
                    data.surveys = response.surveys[index];
                    data.actionPlanning = response.actionPlanning[index];
                  });
                  this.dataSource.data = this.data;
                }
                this.loading = false;
              });
            this.loading = false;
            Swal.fire({
              title: "Manager not certified",
              text: response.message,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
          } else {
            this.loading = false;
            Swal.fire({
              title: "Manager not certified",
              text: response.message,
              icon: "error",
              showConfirmButton: false,
              timer: 2500,
            });
          }
        });
      this.adminService
        .changeModerationStatus(id, "rejected")
        .subscribe((response: any) => {});
    } else {
      this.adminService.issueCertificate(id).subscribe((response: any) => {
        this.loading = true;
        if (response.status === true) {
          this.adminService
            .changeModerationStatus(id, "certified")
            .subscribe((response: any) => {});
          let data = {
            index: this.index,
          };
          this.identifyTab(data);
          this.loading = true;
          Swal.fire({
            text: "Certificate Assigned",
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.loading = false;
        }
      });
    }
  }
  descData(event) {
    if (event.target.value !== "") {
      this.acceptcontent = event.target.value;
    } else {
      this.acceptcontent =
        "Welcome to Great Manager Institute™ - a global digital institute for personalized education and certification of People Managers.Congrats your have been certified";
    }
  }
  descData1(event) {
    if (event.target.value !== "") {
      this.rejectcontent = event.target.value;
    } else {
      this.rejectcontent =
        "Welcome to Great Manager Institute™ - a global digital institute for personalized education and certification of People Managers.Sorry your have been rejected";
    }
  }
  editSubject() {
    this.showacceptSubject = !this.showacceptSubject;
  }
  editSubject1() {
    this.showrejectSubject = !this.showrejectSubject;
  }
  editDesc(event?) {
    this.showDesc = !this.showDesc;
  }
  editDesc1() {
    this.showDesc = !this.showDesc;
  }
  subjectData(event) {
    if (event.target.value !== "") {
      this.acceptsubject = event.target.value;
    } else {
      this.acceptsubject = "Survey accept";
    }
  }
  subjectData1(event) {
    if (event.target.value !== "") {
      this.rejectsubject = event.target.value;
    } else {
      this.rejectsubject = "Survey reject";
    }
  }

  applyFilterMerit(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.meritDataSource.filter = filterValue;
    console.log("filterValue", this.meritDataSource.filter);
  }

  assignCertiOfMerit(id, name) {
    Swal.fire({
      title: "Certificate of Merit",
      text: "Click yes to assign Certificate to " + name,
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then((result) => {
      console.log("result", result);
      if (result.value === true) {
        this.adminService
          .assignCertificateOfMerit({ _id: id })
          .subscribe((res) => {
            console.log("response", res);
            //Can check for res.status- If true then only show the popup
            if (res.status === true) {
              // this.data.forEach(item=>{
              //   if(item._id === id){
              //     item.isCertificateOfMerit == true;
              //     console.log("item",item);
              //   }

              // });
              this.onLoad();
              Swal.fire({
                title: "Certificate Assigned",
                icon: "success",
                showConfirmButton: false,
                timer: 2500,
              });
            }
          });
      }
    });
    //console.log("data",name);
  }
}
export interface CertificateData {
  company: any;
  email: any;
  name: any;
  isCertificateOfMerit: any;
}
