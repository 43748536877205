<main>
  <mat-tab-group (selectedTabChange)="identifyTab($event)">
    <!-- Certificate Of Merit -->
    <mat-tab label="Certificate Of Merit">
      <input
        type="text"
        (keyup)="applyFilterMerit($any($event.target).value)"
        class="form-control search-icon"
        placeholder="Enter your search..."
      />
      <mat-table [dataSource]="meritDataSource" matSort>
        <!-- Manager Name Column-->
        <ng-container matColumnDef="Name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            Name
          </mat-header-cell>
          <mat-cell *matCellDef="let item">
            {{ item.name }}
          </mat-cell>
        </ng-container>
        <!-- Manager Email Column -->
        <ng-container matColumnDef="Email">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            Email
          </mat-header-cell>
          <mat-cell class="certi__email" *matCellDef="let item">
            {{ item.email }}
          </mat-cell>
        </ng-container>

        <!-- Manager Company Column -->
        <ng-container matColumnDef="Company">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            Company
          </mat-header-cell>
          <mat-cell *matCellDef="let item">
            {{ item.company }}
          </mat-cell>
        </ng-container>

        <!-- Manager Action Column -->
        <ng-container matColumnDef="Action">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            Action
          </mat-header-cell>
          <mat-cell *matCellDef="let item">
            <button
              mat-raised-button
              class="assignBtn"
              (click)="assignCertiOfMerit(item._id, item.name)"
              *ngIf="item.isCertificateOfMerit == false"
            >
              Assign Certificate
            </button>
            <button
              mat-raised-button
              disabled
              *ngIf="item.isCertificateOfMerit == true"
            >
              Assigned
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="meritColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: meritColumns"></mat-row>
      </mat-table>
      <mat-paginator
        [length]="8"
        [pageSize]="8"
        [pageSizeOptions]="[8, 16, 24, 32]"
      >
      </mat-paginator>
    </mat-tab>

    <!-- Great People Manager Certificate -->
    <mat-tab label="Certified Great People Manager">
      <!-- <mat-table [dataSource]="gpmDataSource" matSort>
 
        <ng-container matColumnDef="Name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            Name
          </mat-header-cell>
          <mat-cell *matCellDef="let item">
            {{ item.name }}
          </mat-cell>
        </ng-container>
     
        <ng-container matColumnDef="Email">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            Email
          </mat-header-cell>
          <mat-cell *matCellDef="let item">
            {{ item.email }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Company">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            Company
          </mat-header-cell>
          <mat-cell *matCellDef="let item">
            {{ item.company }}
          </mat-cell>
        </ng-container>

     
        <ng-container matColumnDef="Action">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            Action
          </mat-header-cell>
          <mat-cell *matCellDef="let item">
            <button
              mat-raised-button
              color="primary"
              (click)="assignGPMCertificate(item._id, item.name)"
              *ngIf="item.isGpm == false"
            >
              Assign Certificate
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumns"
        ></mat-row> </mat-table
    > -->

      <div class="col-md-12 subadmin-title" style="padding: 0px !important">
        <div class="col-md-7" style="padding-top: 2%; padding-bottom: 2%">
          <input
            type="text"
            (keyup)="applyFilter($any($event.target).value)"
            class="form-control search-icon"
            placeholder="Enter your search..."
            (keyup)="applyFilter($any($event.target).value)"
          />
          <div class="clearfix"></div>
        </div>
      </div>
      <div class="users-table">
        <div class="subadmin-table">
          <div class="example-container container mar-tp tab-size">
            <mat-table [dataSource]="dataSource" matSort>
              <!-- Name Column -->
              <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >Name</mat-header-cell
                >
                <mat-cell *matCellDef="let element"
                  ><span>{{ element.name }}</span></mat-cell
                >
              </ng-container>

              <!-- Email Column -->
              <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >Email</mat-header-cell
                >
                <mat-cell *matCellDef="let element"
                  ><span>{{ element.email }}</span></mat-cell
                >
              </ng-container>

              <!-- Surveys Column -->
              <ng-container matColumnDef="surveys">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >Surveys</mat-header-cell
                >
                <mat-cell *matCellDef="let element"
                  ><span
                    style="cursor: pointer; padding-left: 13%"
                    (click)="
                      openSurveyModal(
                        SurveyInfo,
                        element._id,
                        element.participants
                      )
                    "
                  >
                    {{ element.surveys }}</span
                  >
                  <ng-template #SurveyInfo>
                    <div class="modal-header">
                      <h4 style="font-size: 22px" class="modal-title pull-left">
                        Survey Information
                      </h4>
                      <button
                        type="button "
                        class="close pull-right"
                        aria-label="Close "
                        (click)="modalRef.hide()"
                      >
                        <span aria-hidden="true ">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-md-12" style="padding: 0">
                          <h3
                            style="text-align: center"
                            *ngIf="element.surveys == 0"
                          >
                            No surveys created
                          </h3>
                          <ul
                            class="list-group"
                            *ngFor="let data of managerSurvey; index as i"
                          >
                            <li class="list-group-item">
                              <span style="text-align: center">
                                <strong class=""
                                  >Survey {{ i + 1 }}:
                                </strong> </span
                              ><br /><br />
                              <span style="text-align: center">
                                <strong>Participants </strong> </span
                              ><br />
                              <ul
                                *ngFor="
                                  let participatedData of participantData;
                                  index as j
                                "
                              >
                                <li style="display: inline-block">
                                  <span style="text-align: center">
                                    <strong>{{ j + 1 }}. Name: </strong> </span
                                  >{{ participatedData.name }}<br />
                                  <span
                                    style="text-align: center; padding-left: 8%"
                                  >
                                    <strong>Email: </strong> </span
                                  >{{ participatedData.emailId }}<br />
                                </li>
                              </ul>
                              <br />
                              <span class="pull-left" style="padding-right: 2%">
                                <strong class=""
                                  >Submission status:
                                </strong> </span
                              >{{ data.surveySubmissionStatus }}<br />
                              <span class="pull-left" style="padding-right: 2%">
                                <strong class="">Start Date: </strong> </span
                              >{{ data.startDate }}<br />
                              <span class="pull-left" style="padding-right: 2%">
                                <strong class="">End Date: </strong> </span
                              >{{ data.endDate }}<br />
                              <span class="pull-left" style="padding-right: 2%">
                                <strong class="">HR: </strong> </span
                              >{{ data.hrName }}<br />
                              <span class="pull-left" style="padding-right: 2%">
                                <strong class="">HR Email id: </strong> </span
                              >{{ data.hrEmailId }}<br />
                              <span
                                class="pull-left"
                                style="padding-right: 2%"
                                *ngIf="data.surveyScore"
                              >
                                <strong class="">Overall Score: </strong
                                >{{ data.surveyScore.overall }}<br />
                              </span>
                              <span
                                class="pull-left"
                                style="padding-right: 2%"
                                *ngIf="!data.surveyScore"
                              >
                                <strong class="">Overall Score: </strong> Scores
                                will be calculated after {{ data.endDate }}
                              </span>
                              <br />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </mat-cell>
              </ng-container>
              <!-- Action planning Column -->
              <ng-container matColumnDef="actionplanning">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >Action Planning</mat-header-cell
                >
                <mat-cell *matCellDef="let element">
                  <span
                    style="cursor: pointer; padding-left: 25%"
                    (click)="
                      openActionPlanningModal(actionPlanningInfo, element._id)
                    "
                  >
                    {{ element.actionPlanning }}</span
                  >
                  <ng-template #actionPlanningInfo>
                    <div class="modal-header">
                      <h4 style="font-size: 22px" class="modal-title pull-left">
                        Action Planning Information
                      </h4>
                      <button
                        type="button "
                        class="close pull-right"
                        aria-label="Close "
                        (click)="modalRef.hide()"
                      >
                        <span aria-hidden="true ">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-md-12" style="padding: 0">
                          <h3
                            style="text-align: center"
                            *ngIf="element.actionPlanning == 0"
                          >
                            No action planning created
                          </h3>
                          <ul
                            class="list-group"
                            *ngFor="
                              let data of managerActionPlanning;
                              index as i
                            "
                          >
                            <!-- <li class="list-group-item text-muted" contenteditable="false">Profile</li> -->

                            <li class="list-group-item">
                              <span style="text-align: center">
                                <strong class=""
                                  >Action Planning {{ i + 1 }}:
                                </strong> </span
                              ><br /><br />
                              <span style="text-align: center">
                                <strong>Notes </strong> </span
                              ><br />
                              <ul *ngFor="let notes of data.notes; index as j">
                                <li style="display: inline-block">
                                  <span style="text-align: center">
                                    <strong>Name: </strong> </span
                                  >{{ notes.title }}<br />
                                  <span style="text-align: center">
                                    <strong>Date: </strong> </span
                                  >{{ notes.date }}<br />
                                </li>
                              </ul>
                              <br />
                              <span class="pull-left" style="padding-right: 2%">
                                <strong class=""
                                  >Action planning name:
                                </strong> </span
                              >{{ data.actionName }}<br />
                              <span class="pull-left" style="padding-right: 2%">
                                <strong class="">Theme: </strong> </span
                              >{{ data.theme }}<br />
                              <span class="pull-left" style="padding-right: 2%">
                                <strong class="">Duration: </strong> </span
                              >{{ data.duration }}<br />
                              <span class="pull-left" style="padding-right: 2%">
                                <strong class="">Start date: </strong> </span
                              >{{ data.startDate }}<br />
                              <span class="pull-left" style="padding-right: 2%">
                                <strong class="">End date: </strong> </span
                              >{{ data.endDate }}<br />
                              <span class="pull-left" style="padding-right: 2%">
                                <strong class="">Description: </strong> </span
                              >{{ data.description }}<br />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </mat-cell>
              </ng-container>
              <!-- Participants Column -->
              <ng-container matColumnDef="participants">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >Participants</mat-header-cell
                >
                <mat-cell *matCellDef="let element"
                  ><span
                    style="cursor: pointer; padding-left: 17%"
                    (click)="
                      openParticipantsModal(
                        participantInfo,
                        element.participants
                      )
                    "
                    >{{ element.participantsLength }}</span
                  >
                  <ng-template #participantInfo>
                    <div class="modal-header">
                      <h4 style="font-size: 22px" class="modal-title pull-left">
                        Participant Information
                      </h4>
                      <button
                        type="button "
                        class="close pull-right"
                        aria-label="Close "
                        (click)="modalRef.hide()"
                      >
                        <span aria-hidden="true ">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-md-12" style="padding: 0">
                          <h3
                            style="text-align: center"
                            *ngIf="element.participantsLength == 0"
                          >
                            No participants added
                          </h3>
                          <ul
                            class="list-group"
                            *ngFor="let data of participantData; index as i"
                          >
                            <li class="list-group-item text-right">
                              <span class="pull-left">
                                <strong class=""
                                  >Participant {{ i + 1 }}
                                </strong></span
                              ><br />
                              <span class="pull-left">
                                <strong class="">Name: </strong></span
                              >{{ data.name }}<br />
                              <span class="pull-left">
                                <strong class="">Email: </strong></span
                              >{{ data.emailId }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="information">
                <mat-header-cell *matHeaderCellDef>Information</mat-header-cell>
                <mat-cell *matCellDef="let element"
                  ><i
                    class="fa fa-info"
                    style="padding-left: 20%"
                    (click)="openManagerModal(managerInfo, element._id)"
                  ></i>
                  <ng-template #managerInfo>
                    <div class="modal-header">
                      <h4 style="font-size: 22px" class="modal-title pull-left">
                        Manager Information
                      </h4>
                      <button
                        type="button "
                        class="close pull-right"
                        aria-label="Close "
                        (click)="modalRef.hide()"
                      >
                        <span aria-hidden="true ">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-md-12" style="padding: 0">
                          <ul class="list-group">
                            <li class="list-group-item text-right">
                              <span class="pull-left">
                                <strong class="">Name: </strong></span
                              >{{ managerData.personalInfo.name }}
                            </li>
                            <li class="list-group-item text-right">
                              <span class="pull-left">
                                <strong class="">User name: </strong></span
                              >{{ managerData.personalInfo.userName }}
                            </li>
                            <li class="list-group-item text-right">
                              <span class="pull-left">
                                <strong class="">Email: </strong></span
                              >{{ managerData.personalInfo.email }}
                            </li>
                            <li class="list-group-item text-right">
                              <span class="pull-left">
                                <strong class="">Mobile number: </strong></span
                              >{{ managerData.personalInfo.mobile }}
                            </li>
                            <li class="list-group-item text-right">
                              <span class="pull-left">
                                <strong class="">Gender: </strong></span
                              >{{ managerData.personalInfo.gender }}
                            </li>
                            <li class="list-group-item text-right">
                              <span class="pull-left">
                                <strong class="">Company: </strong></span
                              >{{ managerData.personalInfo.company }}
                            </li>
                            <li class="list-group-item text-right">
                              <span class="pull-left">
                                <strong class="">Role: </strong></span
                              >{{ managerData.personalInfo.role }}
                            </li>
                            <li class="list-group-item text-right">
                              <span class="pull-left">
                                <strong class="">Team size: </strong></span
                              >{{ managerData.personalInfo.teamSize }}
                            </li>
                            <li class="list-group-item text-right">
                              <span class="pull-left">
                                <strong class=""
                                  >Package subscribed:
                                </strong></span
                              >{{ managerData.package_info.packageName }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </mat-cell>
              </ng-container>
              <!-- Actions Column -->
              <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <i
                    class="fa fa-exclamation-triangle"
                    (click)="revoke(element._id)"
                    style="font-size: 21px; color: red; padding-left: 8%"
                    *ngIf="element.managerInfo.badge.length > 0"
                  ></i>
                  <i
                    class="fa fa-cog"
                    style="padding-left: 11%"
                    *ngIf="element.managerInfo.badge.length == 0"
                    (click)="openActionModal(managerActions, element._id)"
                  ></i>
                  <ng-template #managerActions>
                    <div class="modal-header">
                      <h4 style="font-size: 22px" class="modal-title pull-left">
                        Manager Actions
                      </h4>
                      <button
                        type="button "
                        class="close pull-right"
                        aria-label="Close "
                        (click)="modalRef.hide()"
                      >
                        <span aria-hidden="true ">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-md-12" style="padding: 0">
                          <div class="col-md-12" style="text-align: center">
                            <span style="padding-right: 2%">
                              <mat-button-toggle
                                (click)="action('accept')"
                                [ngStyle]="changeAcceptColor()"
                                >Accept</mat-button-toggle
                              >
                            </span>
                            <span>
                              <mat-button-toggle
                                (click)="action('reject')"
                                [ngStyle]="changeRejectColor()"
                                >Reject</mat-button-toggle
                              >
                            </span>
                          </div>
                          <!-- <div class="col-md-12" style="padding-top: 2%;text-align: center;">
                                                        <button mat-raised-button color="primary" (click)="modalRef.hide()"
                                                            (click)="onSubmit(element.personalInfo.email, element._id)"
                                                            [disabled]="valid" type="button">
                                                            Submit
                                                        </button>

                                                    </div> -->
                          <div class="col-md-12" style="padding-top: 2%">
                            <!-- <div [froalaEditor] [(froalaModel)]="emailAcceptData" *ngIf="currentSelected == 'accept'"
                                                            style="height: 100%;"></div>
                                                        <div [froalaEditor] [(froalaModel)]="emailRejectData" *ngIf="currentSelected == 'reject'"></div>
                                                        <div [froalaEditor] *ngIf="currentSelected == ''"></div> -->

                            <div class="email-preview-container">
                              <i
                                class="fa fa-file-image-o"
                                aria-hidden="true"
                              ></i>
                              <h3>Email Preview</h3>
                              <div class="form-body">
                                <!-- <ul class="input-style">
                                                                   <span>Cc</span>
                                                                   <li><input [ngStyle]="{'border':error['email']&&touch['email'] ? '1.5px solid red' : '1.5px solid whitesmoke' }" type="email" name="email" id="email" (change)="checkEmail($event)" placeholder="Enter Email Address"> <span *ngIf="touch['email'] && error['email']"><i style="font-size:18px; color:red" class="fa fa-times" aria-hidden="true"></i></span></li>
                                                                 </ul> -->
                              </div>
                              <div
                                class="subject-body"
                                *ngIf="currentSelected == 'accept'"
                              >
                                <p *ngIf="!showacceptSubject" class="subject">
                                  Subject
                                  <span (click)="editSubject()"
                                    >{{ acceptsubject }}
                                    <i
                                      class="fa fa-pencil"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </p>
                                <span *ngIf="showacceptSubject"
                                  >Subject
                                  <input
                                    type="text"
                                    (change)="subjectData($event)"
                                    name="subject"
                                    id="subject"
                                    placeholder="Enter Your Subject"
                                  />
                                  <i
                                    style="font-size: 18px"
                                    class="fa fa-pencil"
                                    (click)="editSubject()"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </div>
                              <div
                                class="subject-body"
                                *ngIf="currentSelected == 'reject'"
                              >
                                <p *ngIf="!showrejectSubject" class="subject">
                                  Subject
                                  <span (click)="editSubject1()"
                                    >{{ rejectsubject }}
                                    <i
                                      class="fa fa-pencil"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </p>
                                <span *ngIf="showrejectSubject"
                                  >Subject
                                  <input
                                    type="text"
                                    (change)="subjectData1($event)"
                                    name="subject"
                                    id="subject"
                                    placeholder="Enter Your Subject"
                                  />
                                  <i
                                    style="font-size: 18px"
                                    class="fa fa-pencil"
                                    (click)="editSubject1()"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </div>
                              <div
                                class="desc-body"
                                *ngIf="currentSelected == 'accept'"
                              >
                                <!-- <p>Hi %%NAME%</p>  -->
                                <p
                                  class="edit-desc"
                                  (click)="editDesc()"
                                  *ngIf="!showDesc"
                                >
                                  {{ acceptcontent
                                  }}<i
                                    class="fa fa-pencil"
                                    aria-hidden="true"
                                  ></i>
                                </p>
                                <textarea
                                  *ngIf="showDesc"
                                  (change)="descData($event)"
                                  name="desc"
                                  id=""
                                  cols="30"
                                  rows="10"
                                  >{{ acceptcontent }}</textarea
                                >
                                <i
                                  style="font-size: 18px"
                                  (click)="editDesc($event)"
                                  *ngIf="showDesc"
                                  class="fa fa-pencil"
                                  aria-hidden="true"
                                ></i>
                                <!-- <p> Please <a>click here</a>  for login </p>  -->
                              </div>
                              <div
                                class="desc-body"
                                *ngIf="currentSelected == 'reject'"
                              >
                                <!-- <p>Hi %%NAME%</p>  -->
                                <p
                                  class="edit-desc"
                                  (click)="editDesc()"
                                  *ngIf="!showDesc"
                                >
                                  {{ rejectcontent
                                  }}<i
                                    class="fa fa-pencil"
                                    aria-hidden="true"
                                  ></i>
                                </p>
                                <textarea
                                  *ngIf="showDesc"
                                  (change)="descData1($event)"
                                  name="desc"
                                  id=""
                                  cols="30"
                                  rows="10"
                                  >{{ rejectcontent }}</textarea
                                >
                                <i
                                  style="font-size: 18px"
                                  (click)="editDesc($event)"
                                  *ngIf="showDesc"
                                  class="fa fa-pencil"
                                  aria-hidden="true"
                                ></i>
                                <!-- <p> Please <a>click here</a>  for login </p>  -->
                              </div>
                              <div class="bottom-bar">
                                <!-- <button class="btn" [disabled]="error['email']" (click)="onSubmit()" style="margin: 10px;padding: 6px 20px;font-size: 16px;">Send <i class="fa fa-paper-plane" style="font-size:16px"aria-hidden="true"></i></button> -->
                                <button
                                  class="btn"
                                  (click)="modalRef.hide()"
                                  (click)="
                                    onSubmit(
                                      element.personalInfo.email,
                                      element._id
                                    )
                                  "
                                  [disabled]="valid"
                                  type="button"
                                >
                                  Send
                                  <i
                                    class="fa fa-paper-plane"
                                    style="font-size: 16px"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{ element.managerModerationStatus }}
                </mat-cell>
              </ng-container>
              <mat-header-row
                *matHeaderRowDef="displayedColumns"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedColumns"
              ></mat-row>
            </mat-table>

            <mat-paginator
              [length]="8"
              [pageSize]="8"
              [pageSizeOptions]="[8, 16, 24, 32]"
            >
            </mat-paginator>
          </div>
          <!-- <script>
                        $(function () {
                            $('div#froala-editor').froalaEditor({
                                height: 300
                            })
                        });
                    </script> -->
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Top 100 Great People Manager"
      >Top 100 Great People Manager</mat-tab
    >
    <mat-tab label="Top 100 Great People Manager - Special Category"
      >Top 100 Great People Manager - Special Category</mat-tab
    >
  </mat-tab-group>
</main>
