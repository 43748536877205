<!-- //<app-loader *ngIf="loading"></app-loader> -->
<div class="launch-survey-container">
  <div class="row">
    <div class="desc-container shadow">
      <a href="javascript:void(0)" [routerLink]="['/company/company-dashboard/survey-list']" class="create-new clr-grey"
        style="padding: 10px 0">
        <em>
          <i class="fa fa-arrow-left" aria-hidden="true" style="margin-right: 5px"></i> </em>Back</a>
      <p class="desc launchText red-header-text" *ngIf="!isEditSurvey">
        Launch the feedback survey for managers.
      </p>
      <p class="desc" *ngIf="isEditSurvey">Edit the survey.</p>
      <div class="totalManagerGroup">
        <i matTooltip="Total No. of Managers registered" matTooltipPosition="above" class="fa fa-users"
          aria-hidden="true"></i><span class="noOfManagers">{{ managers.length }}</span>
      </div>
    </div>
  </div>
  <div class="survey-details-container">
    <div class="row shadow">
      <div class="col-md-6">
        <div class="add-details effect">
          <div class="row">
            <div class="col-md-12 survey-container">
              <p class="title" *ngIf="!isEditSurvey">Launch the survey</p>
              <p class="title" *ngIf="isEditSurvey">Edit the survey</p>
              <p class="error" *ngIf="isEditSurvey">
                Mail will not go after any changes on survey.
                <strong>Please use email management to send the email</strong>
              </p>
              <div class="survey-type-container" *ngIf="!isEditSurvey">
                <div>
                  <input id="ap-checkbox" type="checkbox" [(ngModel)]="apCheckBox" />
                  <label for="ap-checkbox" style="display: inline-block"></label>
                  <span style="margin-left: 10px">Click here to add action plan feedback survey.
                  </span>
                  <span><i class="fa fa-info-circle info" aria-hidden="true"
                      matTooltip="This checkbox launches the feedback survey which has 2 parts (Part A: CDI Instrument, Part B: Action Plan feedback survey)"
                      matTooltipClass="custom-tooltip" matTooltipPosition="above"></i></span>
                </div>
                <div>
                  <input id="apOnlySurvey" type="checkbox" [(ngModel)]="apOnlySurvey" />
                  <label for="apOnlySurvey" style="display: inline-block"></label>
                  <span style="margin-left: 10px">Check here to launch only interim action plan feedback
                    survey.</span>
                  <span><i class="fa fa-info-circle info" aria-hidden="true"
                      matTooltip="This checkbox will launch only the Action Plan feedback survey. It will not include the CDI instrument"
                      matTooltipClass="custom-tooltip" matTooltipPosition="above"></i></span>
                </div>
                <div>
                  <input id="supervisor" type="checkbox" [(ngModel)]="supervisor" />
                  <label for="supervisor" style="display: inline-block"></label>
                  <span style="margin-left: 10px">Check here to launch the supervisor survey.</span><span><i
                      class="fa fa-info-circle info" aria-hidden="true"
                      matTooltip="This checkbox launches the supervisor survey" matTooltipClass="custom-tooltip"
                      matTooltipPosition="above"></i></span>
                </div>
                <div>
                  <input id="mobileDisabled" type="checkbox" [(ngModel)]="isMobileDisabled" />
                  <label for="mobileDisabled" style="display: inline-block"></label>
                  <span style="margin-left: 10px">Check here to disable mobile number while
                    registration.</span><span><i class="fa fa-info-circle info" aria-hidden="true"
                      matTooltip="This checkbox disables mobile numbers of manager while registration"
                      matTooltipClass="custom-tooltip" matTooltipPosition="above"></i></span>
                </div>
                <div>
                  <input id="autoPswrd" type="checkbox" [(ngModel)]="isAutoGeneratePswrd" />
                  <label for="autoPswrd" style="display: inline-block"></label>
                  <span style="margin-left: 10px">Check here to Auto generate password</span><span><i
                      class="fa fa-info-circle info" aria-hidden="true"
                      matTooltip="This checkbox auto generate password for the participants"
                      matTooltipClass="custom-tooltip" matTooltipPosition="above"></i></span>
                </div>
                <div>
                  <input id="magicLink" type="checkbox" [(ngModel)]="magicLinkStatus" />
                  <label for="magicLink" style="display: inline-block"></label>
                  <span style="margin-left: 10px">Check here to enable Magic link.</span><span><i
                      class="fa fa-info-circle info" aria-hidden="true"
                      matTooltip="This checkbox enable magic link for participants so that participants can directly access survey dashboard without login"
                      matTooltipClass="custom-tooltip" matTooltipPosition="above"></i></span>
                </div>
              </div>
              <div class="date-container" *ngIf="!apOnlySurvey && isEditSurvey">
                <p class="sub-title">
                  Please choose launch date of the feedback survey
                </p>
                <div class="date-sub-container">
                  <div>
                    <mat-form-field class="example-full-width">
                      <input disabled matInput [matDatepicker]="picker1" placeholder="Start Date" [min]="today"
                        [max]="startDateMin" [(ngModel)]="startDate" (ngModelChange)="checkStartDate()" />
                      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                      <mat-datepicker [disabled]="startDateDisabled" touchUi="true" #picker1></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div *ngIf="!isRtfSurveyType">
                    <mat-form-field class="example-full-width">
                      <input matInput disabled [matDatepicker]="picker" placeholder="End Date" [min]="endtoday"
                        [max]="maxDate" [(ngModel)]="endDate" (ngModelChange)="checkEndDate()" />
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker touchUi="true" disabled="false" #picker></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div>
                <p class="sub-title">Enter Survey Name</p>
                <input (change)="changeSurveyName()" class="surveyNameInput" type="text"
                  placeholder="Please enter survey name" [(ngModel)]="surveyName" maxlength="30" />
                <p class="note" *ngIf="isRealTimeFeedback && isRtfSelected">
                  PLEASE ENTER UNIQUE NAME TO LAUNCH REAL TIME FEEDBACK
                </p>
              </div>
              <div class="form-group typeSelect">
                <label class="title" for="surveyLanguage">Select Survey Language:<span>(By default English is
                    selected)</span></label>
                <angular2-multiselect [data]="surveyLanguages" [(ngModel)]="selectedItems"
                  [settings]="dropdownSettings">
                </angular2-multiselect>
              </div>
              <br />
              <div *ngIf="
                  isEditSurvey &&
                  isRealTimeFeedback &&
                  isRtfSurveyType == 'RealTimeFeedback'
                ">
                <div class="custom-recurrence">
                  <h4 class="header">Custom recurrence</h4>

                  <div class="repeat-container">
                    Repeat every
                    <div class="input-group">
                      <input type="number" name="repeatValue" id="repeatValue" [value]="repeatAfter"
                        class="form-control" style="width: 25%" [max]="MAX_QUESTIONS" [(ngModel)]="repeatAfter"
                        (blur)="checkMaxRepetition()" />
                      <div class="arrow-container">
                        <div (click)="increment()" style="height: 16px">^</div>

                        <div style="transform: rotate(180deg); height: 16px" (click)="decrement()">
                          ^
                        </div>
                      </div>
                    </div>
                    <select class="form-control" style="
                        width: fit-content;
                        margin-left: -130px;
                        z-index: 10;
                      " [value]="repeatType" (change)="repeatSelection($event)">
                      <option value="day">day</option>
                      <option value="week">week</option>
                      <option *ngIf="startDate" value="month">month</option>
                    </select>
                  </div>

                  <!-- need to add condition -->
                  <div class="repeat-container" *ngIf="weeksSelected">
                    <div class="days" *ngFor="let days of weekDays; let i = index" [ngStyle]="{
                        'background-color': days.backgroundColor,
                        color: days.color
                      }" (click)="changeBackground(i)" (mouseenter)="showDayOnHover(i)"
                      (mouseleave)="hideDayOnHover()">
                      {{ days.day[0] }}
                      <div *ngIf="hoveredIndex === i" class="tooltip">
                        {{ days.day }}
                      </div>
                    </div>
                  </div>
                  <button *ngIf="monthSelected" class="btn" style="width: fit-content">
                    Monthly on day {{ datePipe.transform(startDate, "dd") }}
                  </button>
                  <div class="form-group col-md-12 pb-10" style="align-items: center; gap: 10px; margin-left: -15px">
                    <label for="title">Enter number of respondents(MAX 4)</label>
                    <input id="numberOfRespondents" type="number" class="form-control" min="1" style="width: 10%"
                      [(ngModel)]="numberOfRespondents" (blur)="numberOfRespondentChange()" />
                  </div>
                  <div class="end-container">
                    Ends On

                    <mat-form-field class="example-full-width">
                      <input matInput disabled [matDatepicker]="picker" [min]="endtoday" [(ngModel)]="endDate"
                        (ngModelChange)="checkEndDate()" />
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker touchUi="true" disabled="false" #picker></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="schedule-container">
                    <button class="btn gmi-primary-btn" (click)="openEmailTemplate(emailTemp)">
                      Email Setup
                    </button>
                  </div>
                  <div class="form-group col-md-12 pb-10" style="align-items: center; gap: 10px; margin-left: -15px">
                    <label for="title">Enter number of question each day</label>
                    <input id="numberOfQuestionsEachDay" type="number" class="form-control" min="1" style="width: 10%"
                      [(ngModel)]="numberOfQuestionsEachDay" (blur)="numberOfQuestionChange()" />
                  </div>
                </div>

                <!-- <p class="rtf-head" *ngIf="!isRealTimePause">
                  PAUSE REAL TIME FEEDBACK
                </p>
                <p class="rtf-head" *ngIf="isRealTimePause">
                  RESUME REAL TIME FEEDBACK
                </p>
                <button
                  class="rtf-btn"
                  *ngIf="!isRealTimePause"
                  (click)="pauseRtf()"
                >
                  PAUSE RTF
                </button>
                <button
                  class="rtf-btn"
                  *ngIf="isRealTimePause"
                  (click)="resumeRtf()"
                >
                  RESUME RTF
                </button> -->
              </div>

              <div class="" style="width: 100%">
                <h5 class="" style="margin-bottom: 15px; font-weight: bold">
                  Select Demographics for Response Rate
                </h5>
                <small>* You Can select only 4 Demographics.</small>
                <div style="height: 250px; overflow: scroll">
                  <div *ngFor="let option of pisDemographics; let i = index">
                    <div style="
                        display: flex;
                        justify-content: flex-start;
                        gap: 10px;
                        align-items: center;
                      " [ngClass]="
                        !selectedDemographicOption.includes(option) &&
                        selectedDemographicOption.length == 4
                          ? 'opacityClass'
                          : ''
                      ">
                      <div>
                        <input id="checkbox{{ i }}option" type="checkbox" (change)="
                            changeDemographic(option, $event.target['checked'])
                          " [checked]="selectedDemographicOption.includes(option)" [disabled]="
                            !selectedDemographicOption.includes(option) &&
                            selectedDemographicOption.length == 4
                          " />
                        <label for="checkbox{{ i }}option"></label>
                      </div>
                      <div style="margin-top: 10px">
                        <p class="text-center" style="font-size: 13px">
                          {{ option }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <p *ngIf="pisDemographics?.length == 0" class="text-center">
                    Not found any Demographics.
                  </p>
                </div>
              </div>

              <div *ngIf="!isEditSurvey">
                <div class="form-group typeSelect">
                  <label for="surveyType">Select Survey type </label>
                  <select class="form-control" id="surveyType" [(ngModel)]="surveyType" (ngModelChange)="dataChanged()">
                    <option value="Pre" selected>Pre</option>
                    <option value="Interim">Interim</option>
                    <option value="Post">Post</option>
                    <option value="Pilot">Pilot</option>
                    <option value="Recertification">Recertification</option>
                    <option value="RealTimeFeedback" *ngIf="isRealTimeFeedback">
                      Real time feedback
                    </option>
                  </select>
                </div>
                <div class="date-container" *ngIf="!apOnlySurvey">
                  <p class="sub-title">
                    Please choose launch date of the feedback survey
                  </p>
                  <div class="date-sub-container">
                    <div>
                      <mat-form-field class="example-full-width">
                        <input disabled matInput [matDatepicker]="picker1" placeholder="Start Date" [min]="today"
                          [max]="startDateMin" [(ngModel)]="startDate" (ngModelChange)="checkStartDate()" />
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker [disabled]="startDateDisabled" touchUi="true" #picker1></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div *ngIf="!isRtfSelected">
                      <mat-form-field class="example-full-width">
                        <input matInput disabled [matDatepicker]="picker" placeholder="End Date" [min]="endtoday"
                          [max]="maxDate" [(ngModel)]="endDate" (ngModelChange)="checkEndDate()" />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker touchUi="true" disabled="false" #picker></mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div *ngIf="isRealTimeFeedback && isRtfSelected">
                  <div class="custom-recurrence">
                    <h4 class="header">Custom recurrence</h4>

                    <div class="repeat-container">
                      Repeat every
                      <div class="input-group">
                        <input type="number" name="repeatValue" id="repeatValue" [value]="repeatAfter"
                          class="form-control" style="width: 25%" [max]="MAX_QUESTIONS" [(ngModel)]="repeatAfter"
                          (blur)="checkMaxRepetition()" />
                        <div class="arrow-container">
                          <div (click)="increment()" style="height: 16px">
                            ^
                          </div>

                          <div style="transform: rotate(180deg); height: 16px" (click)="decrement()">
                            ^
                          </div>
                        </div>
                      </div>
                      <select class="form-control" style="
                          width: fit-content;
                          margin-left: -130px;
                          z-index: 10;
                        " (change)="repeatSelection($event)">
                        <option value="day">day</option>
                        <option value="week">week</option>
                        <option *ngIf="startDate" value="month">month</option>
                      </select>
                    </div>

                    <!-- need to add condition -->
                    <div class="repeat-container" *ngIf="weeksSelected">
                      <div class="days" *ngFor="let days of weekDays; let i = index" [ngStyle]="{
                          'background-color': days.backgroundColor,
                          color: days.color
                        }" (click)="changeBackground(i)" (mouseenter)="showDayOnHover(i)"
                        (mouseleave)="hideDayOnHover()">
                        {{ days.day[0] }}
                        <div *ngIf="hoveredIndex === i" class="tooltip">
                          {{ days.day }}
                        </div>
                      </div>
                    </div>
                    <button *ngIf="monthSelected" class="btn" style="width: fit-content">
                      Monthly on day {{ datePipe.transform(startDate, "dd") }}
                    </button>
                    <div class="form-group col-md-12 pb-10" style="align-items: center; gap: 10px; margin-left: -15px">
                      <label for="title">Enter number of respondents(MAX 4)</label>
                      <input id="numberOfRespondents" type="number" class="form-control" min="1" max="4"
                        style="width: 10%" [(ngModel)]="numberOfRespondents" (blur)="numberOfRespondentChange()" />
                    </div>
                    <div class="end-container">
                      Ends On

                      <mat-form-field class="example-full-width">
                        <input matInput disabled [matDatepicker]="picker" [min]="endtoday" [(ngModel)]="endDate"
                          (ngModelChange)="checkEndDate()" />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker touchUi="true" disabled="false" #picker></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div class="schedule-container">
                      <button class="btn gmi-primary-btn" (click)="openEmailTemplate(emailTemp)">
                        Email Setup
                      </button>
                    </div>
                    <div class="form-group col-md-12 pb-10" style="align-items: center; gap: 10px; margin-left: -15px">
                      <label for="title">Enter number of question each day</label>
                      <input id="numberOfQuestionsEachDay" type="number" class="form-control" min="1" style="width: 10%"
                        [(ngModel)]="numberOfQuestionsEachDay" (blur)="numberOfQuestionChange()" />
                    </div>
                  </div>
                </div>
                <div class="excel-input-container">
                  <p class="text">Upload Excel Sheet as a filter</p>
                  <button class="btn gmi-upload-button" (click)="fileInput.click()">
                    Upload File
                  </button>
                  <!-- <a
                    href="https://s3.ap-south-1.amazonaws.com/s3.gmi/sample+filter.xlsx"
                  >
                    Download Sample
                  </a> -->
                  <a href="https://media.greatmanagerinstitute.com/sample+filter.xlsx">
                    Download Sample
                  </a>
                  <div class="sheet-container" *ngIf="fileInput.files[0]">
                    <p>
                      {{ fileInput.files[0].name }}
                    </p>
                    <p class="delete-button" (click)="deleteExcelSheet()">
                      <i class="fa fa-trash-o" aria-hidden="true"></i>
                    </p>
                  </div>

                  <input style="width: 0; height: 0" id="fileSelect" type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    name="fileUpload" ngModel required #fileUpload="ngModel" #fileInput
                    (change)="onFileChange($event)" />
                  <p *ngIf="!excelType && touched" style="margin-top: 10px; color: red; font-size: 12px">
                    Invalid file type
                  </p>
                  <p *ngIf="excelHeader" style="margin-top: 10px; color: red; font-size: 12px">
                    Please add following header 1. Manager Name , 2. Manager
                    Email or add some email and name
                  </p>
                  <div *ngIf="errorsInData.length > 0" class="error-container">
                    <p class="text">Following Errors found in excel sheet</p>
                    <ul>
                      <li *ngFor="let error of errorsInData">
                        {{ error.name }} - {{ error.email }}
                      </li>
                    </ul>
                    <span>Please edit in excel sheet and again add it.</span>
                  </div>
                </div>
                <div>
                  <p class="sub-title" *ngIf="!startDateDisabled">
                    <span *ngIf="!apOnlySurvey">Select the managers to launch survey</span>
                    <span *ngIf="apOnlySurvey">Please select the managers to launch the interim action
                      plan feedback</span>
                  </p>
                  <span *ngIf="!startDateDisabled">
                    <a href="javascript:void()" style="cursor: pointer">
                      <label style="cursor: pointer" for="checkbox">{{
                        text
                        }}</label></a>
                    <input id="checkbox" type="checkbox" [(ngModel)]="checkall" (change)="checkUncheckAll()" />
                  </span>
                  <div *ngIf="!supervisor" class="manager-list-container">
                    <div *ngFor="let manager of managers; let i = index" class="col-md-6 cont">
                      <p class="name">{{ manager.name }}</p>
                      <div *ngIf="surveyTypeArray.includes(surveyType)">
                        <span *ngIf="
                            !manager.surveyOngoing &&
                            !manager.isRTFSurveyFound &&
                            manager.participants &&
                            manager.seManagerId
                          " style="float: right">
                          <input id="checkbox{{ i }}" type="checkbox" [(ngModel)]="manager.checked" (change)="
                              updateCheckall(manager, $event.target['checked'])
                            " />
                          <label for="checkbox{{ i }}"></label>
                        </span>
                      </div>

                      <div *ngIf="surveyTypeActionPlan.includes(surveyType)">
                        <span *ngIf="
                            manager.participants &&
                            manager.actionPlans &&
                            !manager.isRTFSurveyFound &&
                            !manager.surveyOngoing
                          " style="float: right">
                          <input id="checkbox{{ i }}" type="checkbox" [(ngModel)]="manager.checked" (change)="
                              updateCheckall(manager, $event.target['checked'])
                            " />
                          <label for="checkbox{{ i }}"></label>
                        </span>
                      </div>

                      <p *ngIf="manager.surveyOngoing && !apOnlySurvey" style="
                          color: green;
                          font-size: 12px;
                          margin: 0px !important;
                        ">
                        Survey is going on
                      </p>
                      <p *ngIf="
                          !manager.surveyOngoing &&
                          manager.isRTFSurveyFound &&
                          this.surveyType == 'RealTimeFeedback'
                        " style="
                          color: salmon;
                          font-size: 12px;
                          margin: 0px !important;
                        ">
                        RTF survey already present
                      </p>
                      <p *ngIf="!manager.participants" style="
                          color: salmon;
                          font-size: 12px;
                          margin: 0px !important;
                        ">
                        Please Add participant here
                      </p>
                      <p style="
                          color: salmon;
                          font-size: 12px;
                          margin: 0px !important;
                        " *ngIf="!manager.seManagerId">
                        Manager is not exist in the Survey Enging DB.
                      </p>
                      <div *ngIf="
                          this.surveyType == 'Post' ||
                          this.surveyType == 'Recertification'
                        ">
                        <p style="
                            color: salmon;
                            font-size: 12px;
                            margin: 0px !important;
                          " *ngIf="!manager.actionPlans && !manager.surveyOngoing">
                          Action Plan is not exist.
                        </p>
                      </div>

                      <p class="email">{{ manager.email }}</p>
                    </div>
                  </div>
                  <div *ngIf="supervisor" class="manager-list-container">
                    <div *ngFor="let manager of managers; let i = index" class="col-md-6 cont">
                      <p class="name">{{ manager.name }}</p>
                      <span *ngIf="
                          !manager?.superVisorSurvey && manager?.superVisorAdded
                        " style="float: right">
                        <input id="checkbox{{ i }}" type="checkbox" [(ngModel)]="manager.checked" (change)="
                            updateCheckall(manager, $event.target['checked'])
                          " />
                        <label for="checkbox{{ i }}"></label>
                      </span>
                      <p *ngIf="manager.superVisorSurvey" style="
                          color: green;
                          font-size: 12px;
                          margin: 0px !important;
                        ">
                        Supervisor Survey is going on
                      </p>
                      <p *ngIf="!manager.superVisorAdded" style="
                          color: salmon;
                          font-size: 12px;
                          margin: 0px !important;
                        ">
                        Please Add supervisor here
                      </p>
                      <!-- <p *ngIf="!manager.isSelectedForSurvey" style="
                      color: yellow;
                      font-size: 12px;
                      margin: 0px !important;
                    ">
                    Manager is not selected for launch survey
                  </p> -->
                      <p class="email">{{ manager.email }}</p>
                    </div>
                  </div>

                  <!-- <p class="sub-title">Or</p> -->
                  <div class="btn-container">
                    <button *ngIf="!apOnlySurvey" class="btn gmi-primary-btn" [disabled]="
                        endError ||
                        startError ||
                        respondent.length == 0 ||
                        surveyType === '' ||
                        (surveyType === 'RealTimeFeedback' && !isValidData)
                      " (click)="openModal(template)">
                      Review and Launch
                    </button>
                    <button *ngIf="apOnlySurvey" class="btn gmi-primary-btn" [disabled]="respondent.length == 0"
                      (click)="openModal(template)">
                      Review and Launch
                    </button>
                    <button (click)="loadMoreManager()" class="btn gmi-primary-btn">
                      Load More
                    </button>
                  </div>
                  <div class="button-container">
                    <div>
                      <input id="researchSurveyGroup" type="checkbox" [(ngModel)]="researchSurveyGroup" />
                      <label for="researchSurveyGroup" style="display: inline-block"></label>
                      <span style="margin-left: 10px">Click here to Research on this Survey Group.</span>
                      <br />
                      <p class="note">Under testing.</p>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="isEditSurvey">
                <div class="manager-list-container">
                  <div *ngFor="let manager of managers; let i = index" class="col-md-6 cont">
                    <p class="name">{{ manager.managerName }}</p>
                    <span class="edit-action" (click)="
                        openParticipantModel(
                          participantsModal,
                          manager.surveyId
                        )
                      ">
                      <i class="fa fa-pencil"></i>
                    </span>

                    <!-- <p *ngIf="!manager.isSelectedForSurvey" style="
                        color: yellow;
                        font-size: 12px;
                        margin: 0px !important;
                      ">
                      Manager is not selected for launch survey
                    </p> -->
                    <p class="email">{{ manager.managerEmail }}</p>
                  </div>
                </div>
                <h5 style="margin-bottom: 15px; font-weight: bold" *ngIf="allNewManagers.length > 0">
                  {{ allNewManagers.length }} Newly added
                  {{
                  allNewManagers.length == 1 ? "manager" : "managers"
                  }}(Click on update to reflect changes in database)
                </h5>
                <div class="manager-list-container" *ngIf="allNewManagers.length > 0">
                  <div *ngFor="let manager of allNewManagers; let i = index" class="col-md-6 cont">
                    <p class="name">{{ manager.name }}</p>

                    <p class="email">{{ manager.email }}</p>
                  </div>
                </div>
                <div class="btn-container">
                  <button class="btn btn-primary" style="margin-right: 10px" [disabled]="endError"
                    (click)="openAddManagerModal(addNewManager)">
                    Add Managers or Update Respondents
                  </button>
                  <button class="btn btn-primary" [disabled]="
                      endError ||
                      (surveyType === 'RealTimeFeedback' && !isValidData)
                    " (click)="updateSurvey(loadingSurvey)">
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p class="error" *ngIf="isEditSurvey && !endError">
          <strong>Add Manager and Respondents by PIS upload functionality before
            adding here</strong>
        </p>
      </div>

      <div class="col-md-6 form-sample effect">
        <div class="translation-status">
          <h2>Questions with Missing Translations : (Missing translation language are disabled)</h2>

          <!-- Language Selection -->
          <div class="language-tabs">
            <button *ngFor="let lang of languages" [class.active]="selectedLanguage === lang.id"
              (click)="selectLanguage(lang.id)">
              {{ lang.itemName }}
            </button>
          </div>

          <!-- Questions for Selected Language -->
          <div *ngIf="filteredQuestions.length > 0; else noQuestions" class="question-list scrollable">
            <div *ngFor="let question of filteredQuestions; let i = index" class="question-card">
              <h4>Q{{ i + 1 }}. {{ question.text }}</h4>
            </div>
          </div>

          <!-- No Questions Template -->
          <ng-template #noQuestions>
            <p class="no-data">No questions are missing translations for this language.</p>
          </ng-template>
        </div>


        <p class="title">Survey Instrument</p>
        <div class="questionsContainer">
          <div class="form-group" *ngFor="let question of radioQuestions; let i = index">
            <p class="statement">Statement {{ i + 1 }} ( Rating Question )</p>
            <p class="question">
              {{ question.question_text }}
            </p>
            <!-- <ul *ngFor="let questionId of question.question_description; let j = index">
                          <span *ngIf="questionId.answer_id !== 0" style="padding-right: 0px !important;">
                              <li>
                                  <input class="radio-inline" type="radio" name="optradio" disabled *ngIf="questionId.answer_id !== 0" />{{ questionId.answer_id }}
                              </li>
                          </span>
                      </ul> -->
          </div>
          <div class="form-group" *ngFor="let question of dropDownQuestions; let i = index">
            <p class="statement">Statement {{ i + radioQuestions.length + 1 }}</p>
            <p class="question">
              {{ question.question_text }}
            </p>
            <div class="container">
              <ul *ngFor="let questionId1 of question.options; let i = index">
                <li>Option {{ i + 1 }} : {{ questionId1.answer_text }}</li>
              </ul>
            </div>
          </div>
          <div class="form-group" *ngFor="let question of textAreaQuestions; let i = index">
            <p class="statement">
              Statement
              {{ i + radioQuestions.length + dropDownQuestions.length + 1 }}
            </p>
            <p class="question">
              {{ question.question_text }}
            </p>
            <textarea disabled name="statement3" id placeholder="Your Answer"></textarea>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<ng-template #template>
  <div class="survey-preview-modal">
    <div class="preview-modal-header">
      <div class="model-head-text">Preview and Submit</div>
      <button class="close" type="button " aria-label="Close " (click)="modalRef.hide()">
        <span aria-hidden="true ">&times;</span>
      </button>
    </div>
    <hr class="line" />
    <div class="survey-date">
      <p>Start Date: {{ correctedStartDate }}</p>
      <p>End Date: {{ correctedEndDate }}</p>
    </div>
    <hr class="line" />
    <div class="survey-preview-container">
      <div *ngFor="let list of surveyLaunchCheckList">
        <div class="checklist-item">
          <input type="checkbox" [(ngModel)]="list.isChecked" class="checklist-checkbox" />
          <p class="checklist-item-name" [ngClass]="{ 'checklist-item-name-red': !list.isChecked }">
            {{ list.name }}
          </p>
          <p class="checklist-item-separator">-</p>
          <p class="checklist-item-answer">{{ list.ans ?? "N/A" }}</p>
        </div>
      </div>
    </div>
    <hr class="line" />
    <div class="table-container" *ngIf="respondent && respondent.length > 0">
      <table>
        <thead>
          <tr>
            <th>Manager Name</th>
            <th>Manager Email</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let selectedResp of respondent">
            <td>{{ selectedResp.name }}</td>
            <td>{{ selectedResp.email }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="modal-bottom">
      <button class="btn gmi-primary-btn edit-btn" (click)="modalRef.hide()">
        Go back & edit
      </button>
      <button class="btn gmi-primary-btn" (click)="respondentData(launchSurvey)">
        Confirm & proceed
      </button>
    </div>
  </div>
</ng-template>

<ng-template #showLoadingMsg>
  <p class="text">Please wait. Manager list is loading..</p>
</ng-template>

<ng-template #participantsModal>
  <div class="modal-header">
    <h4 style="font-size: 22px" class="modal-title pull-left">
      Participants of {{ singleSurvey.managerName }}
    </h4>
    <button type="button " class="close pull-right" aria-label="Close " (click)="modalRef.hide()">
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <p class="title">
          Enter email address of new employee to participate in the survey
        </p>
        <form (ngSubmit)="onSubmit1(f)" #f="ngForm">
          <div class="row">
            <div class="form-group col-md-6">
              <input type="text" class="form-control" placeholder="Enter the participant name" name="name" ngModel
                required #name="ngModel" />
              <p style="font-size: 10px; color: red" *ngIf="!name.valid && name.touched">
                Please Enter Your Name
              </p>
            </div>

            <div class="form-group col-md-6">
              <input type="email" class="form-control" placeholder="Enter the participant email-id" name="emailId"
                ngModel email #email="ngModel" />
              <p style="font-size: 10px; color: red" *ngIf="!email.valid && email.touched">
                Please Enter Valid Email-Id
              </p>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-6">
              <input type="text" class="form-control" placeholder="Enter participant Code/Id" name="empCompanyId"
                ngModel #empCompanyId="ngModel" />
            </div>

            <div class="form-group col-md-6">
              <input type="text" class="form-control" placeholder="Enter participant DOB(DD/MM/YYYY)" name="empDob"
                ngModel #empDob="ngModel" />
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-6">
              <select class="form-control custom-form-control" name="respondentType" ngModel required
                #respondentType="ngModel">
                <option value disabled selected>Select respondent type</option>
                <option value="Peer">Peer</option>
                <option value="Reporting Manager">Reporting Manager</option>
                <option value="Senior">Senior</option>
                <option value="Team Member">Team Member</option>
              </select>
              <p style="font-size: 10px; color: red" *ngIf="!respondentType.valid && respondentType.touched">
                Please select Respondent type
              </p>
            </div>

            <div class="form-group col-md-6">
              <div class="form-group" style="height: 50px">
                <input [disabled]="!f.valid" type="submit" class="btn gmi-primary-btn form-control"
                  value="Add participant" />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <span *ngIf="sameEmail" style="padding: 0px 16px; color: red">Email already exists !</span>
      <span *ngIf="emailManager" style="padding: 0px 16px; color: red">Manager cannot add himself as survey
        participant</span>
      <div class="col-md-12">
        <span *ngIf="singleSurvey.participants.length <= 2" style="padding: 0px 16px; color: red">Please add more
          respondent to remove the participant</span>
        <div>
          <div class="col-md-6 cont" *ngFor="let respondent of singleSurvey.participants; let i = index">
            <div class="add-user">
              <p class="respondent">
                {{ respondent.name }}
                <span *ngIf="respondent.surveySubmissionStatus">*</span>
              </p>
              <p class="email">
                {{ respondent.emailId }}
                <a *ngIf="
                    !respondent.surveySubmissionStatus &&
                    singleSurvey.participants.length > 2
                  " class="delete" (click)="deleteParticipant(respondent.emailId)"><i class="fa fa-trash-o"
                    aria-hidden="true"></i></a>
              </p>
              <p class="respondent">
                {{ respondent.respondentType }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-area">
        <div class="btn-container">
          <button type="button" class="btn gmi-primary-btn" [disabled]="sameEmail || emailManager"
            (click)="modalRef.hide()">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #launchSurvey>
  <div class="modal-header">
    <h4 style="font-size: 22px" class="modal-title pull-left">Launch Status</h4>
    <!-- <button
      type="button "
      class="close pull-right"
      aria-label="Close "
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true ">&times;</span>
    </button> -->
  </div>
  <div class="modal-body">
    <div class="status-container" *ngIf="totalSurveysCount > 0">
      <p class="title">
        Total no of surveys {{ editSurveyType }}: {{ totalSurveysCount }}
      </p>
      <progress class="custom-progress" value="{{ completedCount }}" max="100">
        {{ completedCount }}
      </progress>
      <p class="status-count" *ngIf="!updateStatus">
        Updated <strong>{{ surveyCount }}</strong> of
        <strong>{{ totalSurveysCount }}</strong> surveys.
      </p>
      <div class="action-container" *ngIf="updateStatus">
        <p class="success-text">Successfully launched all the surveys.</p>
        <button class="btn gmi-primary-btn" (click)="redirectToSurveyPage()">
          Go to the survey page
        </button>
      </div>
    </div>
    <div class="message-container" *ngIf="totalSurveysCount == 0">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>

      <p class="loading-text">Please wait we are fetching the Data</p>
    </div>
  </div>
</ng-template>

<ng-template #loadingSurvey>
  <div class="modal-header">
    <h4 style="font-size: 22px" class="modal-title pull-left">Update Status</h4>
    <!-- <button
      type="button "
      class="close pull-right"
      aria-label="Close "
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true ">&times;</span>
    </button> -->
  </div>
  <div class="modal-body">
    <div class="status-container" *ngIf="totalSurveysCount > 0">
      <p class="title">
        Total no of surveys {{ editSurveyType }}: {{ totalSurveysCount }}
      </p>
      <progress class="custom-progress" value="{{ completedCount }}" max="100">
        {{ completedCount }}
      </progress>
      <p class="status-count" *ngIf="!updateStatus">
        Updated <strong>{{ surveyCount }}</strong> of
        <strong>{{ totalSurveysCount }}</strong> surveys.
      </p>
      <div class="action-container" *ngIf="updateStatus">
        <p class="success-text">Successfully updated all the surveys</p>
        <button class="btn gmi-primary-btn" (click)="redirectToSurveyPage()">
          Go to the survey page
        </button>
      </div>
    </div>
    <div class="message-container" *ngIf="totalSurveysCount == 0">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>

      <p class="loading-text">Please wait we are fetching the Data</p>
    </div>
  </div>
</ng-template>

<ng-template #emailTemp>
  <div class="modal-header">
    <h4 style="font-size: 22px; text-transform: capitalize" class="modal-title pull-left">
      Add email
    </h4>
    <button type="button " class="close pull-right" aria-label="Close " (click)="modalRef.hide()">
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <h4 class="sub-title">Email operation</h4>

        <small class="text-danger"></small>
        <div class="form-container">
          <div *ngIf="placeholder" class="placeholder-container">
            <p class="placeholder">{{ placeholder }}</p>
          </div>
          <div class="input-group">
            <label for="emailType">Email Type <span class="error">*</span></label>
            <select name="emailType" id="emailType" (change)="handleForm($event)">
              <option value="" selected disabled>
                Please select email type
              </option>
              <option value="rtf_respondent_mail">
                Real time feedback mail to Respondents
              </option>
            </select>
          </div>
          <div class="input-group">
            <label for="name">Name <span class="error">*</span></label>
            <input type="text" name="name" id="name" (change)="handleForm($event)" [(ngModel)]="formData.name" />
          </div>
          <div class="input-group">
            <!-- <span class="error">*</span> -->
            <label for="cc">CC </label>
            <input type="email" name="cc" id="cc" (change)="handleForm($event)" [(ngModel)]="formData.cc" />
          </div>
          <!-- <div class="input-group">
            <label for="bcc">BCC <span class="error">*</span></label>
            <input
              type="email"
              name="bcc"
              id="bcc"
              (change)="handleForm($event)"
              [(ngModel)]="formData.bcc"
            />
          </div> -->
          <div class="input-group">
            <label for="subject">Mail Subject <span class="error">*</span></label>
            <input type="text" name="subject" id="subject" (change)="handleForm($event)"
              [(ngModel)]="formData.subject" />
          </div>
          <div class="input-group">
            <label for="to">To <span class="error">*</span></label>

            <select name="to" id="to" (change)="handleForm($event)">
              <option value="" selected disabled>
                Please pick the recipients
              </option>
              <option *ngFor="let i of toTypes" value="{{ i }}">
                {{ i }}
              </option>
            </select>
          </div>

          <div class="input-group">
            <label for="subject">ScheduleAt <span class="error">*</span></label>

            <div class="extra-input">
              <!-- <input
                [owlDateTimeTrigger]="dt10"
                [owlDateTime]="dt10"
                [selectMode]="'range'"
                [min]="startAt"
                [(ngModel)]="scheduleAt"
              />
              <owl-date-time [pickerMode]="'dialog'" #dt10></owl-date-time> -->
              <input [owlDateTimeTrigger]="dt2" [owlDateTime]="dt2" (dateTimeChange)="handleFormTimeChange($event)" />
              <owl-date-time [pickerType]="'timer'" #dt2></owl-date-time>
            </div>
          </div>
          <label for="content">Content <span class="error">*</span></label>
          <!-- <div class="input-group"> -->
          <!-- <angular-editor
              [config]="editorConfig"
              [placeholder]="'Enter text here...'"
              [(ngModel)]="formData.content"
              (ngModelChange)="handleEditorChange($event)"
            ></angular-editor> -->
          <!-- <div class="editor">
              <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
              </ngx-editor-menu>
              <ngx-editor
                [ngModelOptions]="{ standalone: true }"
                [editor]="editor"
                [placeholder]="'Enter text here...'"
                [(ngModel)]="formData.content"
              >
              </ngx-editor>
            </div> -->
          <app-email-editor [(content)]="formData.content"></app-email-editor>
          <!-- (contentChange)="onContentChange($event)" -->
          <!-- </div> -->
          <div class="button-container">
            <button class="btn gmi-primary-btn" (click)="submitData()">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #addNewManager>
  <div class="modal-header">
    <h4 style="font-size: 22px" class="modal-title pull-left">
      Add Managers or Update Respondents
    </h4>
    <button type="button " class="close pull-right" aria-label="Close " (click)="modalRef.hide()">
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <select class="form-control" (change)="updateUploadType($event)" [value]="uploadTypeOnEdit">
        <option value="manager">Manager</option>
        <option value="respondent">Respondent</option>
      </select>
    </div>
    <div class="row">
      <div class="col-md-12" style="text-align: center; padding: 20px 0">
        <label for="fileSelect" class="btn gmi-primary-btn" style="width: 200px" (click)="resetFileInput()">
          <input type="file" id="fileSelect" style="display: none"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            (change)="onFileChange($event, uploadTypeOnEdit)" />
          Upload Excel
        </label>

        <a href="https://media.greatmanagerinstitute.com/sample+filter.xlsx">
          Download Sample
        </a>
        <div class="sheet-container-edit" *ngIf="uploadedFileName">
          <p>
            {{ uploadedFileName }}
          </p>
        </div>
        <p class="error" *ngIf="
            uploadedFileName &&
            respondentDataOnEdit?.length == 0 &&
            allNewManagers?.length == 0
          ">
          <strong>Add Manager and Respondents by PIS upload functionality before
            adding here</strong>
        </p>
      </div>
    </div>
    <p *ngIf="!excelType && touched" style="margin-top: 10px; color: red; font-size: 12px">
      Invalid file type
    </p>
    <p *ngIf="excelHeader" style="margin-top: 10px; color: red; font-size: 12px">
      {{ excelHeaderError }}
    </p>
    <div *ngIf="errorsInData.length > 0" class="error-container">
      <p class="text">Following Errors found in excel sheet</p>
      <ul>
        <li *ngFor="let error of errorsInData">
          {{ error.name }} - {{ error.email }}
        </li>
      </ul>
      <span>Please edit in excel sheet and again add it.</span>
    </div>
    <div *ngIf="allNewManagers?.length > 0" class="manager-list-container">
      <div *ngFor="let manager of allNewManagers; let i = index" class="col-md-6 cont">
        <p class="name">{{ manager.name }}</p>

        <p *ngIf="manager.surveyOngoing && !apOnlySurvey" style="color: green; font-size: 12px; margin: 0px !important">
          Survey is going on
        </p>
        <p *ngIf="
            !manager.surveyOngoing &&
            manager.isRTFSurveyFound &&
            this.surveyType == 'RealTimeFeedback'
          " style="color: salmon; font-size: 12px; margin: 0px !important">
          RTF survey already present
        </p>
        <p *ngIf="!manager.participants" style="color: salmon; font-size: 12px; margin: 0px !important">
          Please Add participant here
        </p>
        <p style="color: salmon; font-size: 12px; margin: 0px !important" *ngIf="!manager.seManagerId">
          Manager is not exist in the Survey Enging DB.
        </p>
        <div *ngIf="
            this.surveyType == 'Post' || this.surveyType == 'Recertification'
          ">
          <p style="color: salmon; font-size: 12px; margin: 0px !important"
            *ngIf="!manager.actionPlans && !manager.surveyOngoing">
            Action Plan is not exist.
          </p>
        </div>

        <p class="email">{{ manager.email }}</p>
      </div>
    </div>

    <div *ngIf="respondentDataOnEdit?.length > 0" class="">
      <h3>Total valid respondents: {{ respondentDataOnEdit.length }}</h3>
      <div class="mat-table-container">
        <mat-table [dataSource]="respondentDataOnEdit" matSort>
          <ng-container matColumnDef="managerName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Manager Name
            </mat-header-cell>
            <mat-cell *matCellDef="let respondent">
              {{ respondent.managerName }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="respondentName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Respondent Name
            </mat-header-cell>
            <mat-cell *matCellDef="let respondent">
              {{ respondent.respondentName }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="respondentEmail">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="email-cell">
              Respondent Email
            </mat-header-cell>
            <mat-cell class="email-cell" *matCellDef="let respondent">
              {{ respondent.respondentEmail }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="respondentType">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Respondent Type
            </mat-header-cell>
            <mat-cell *matCellDef="let respondent">
              {{ respondent.respondentType }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Status
            </mat-header-cell>
            <mat-cell *matCellDef="let respondent">
              {{ respondent.type }}
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="[
              'managerName',
              'respondentName',
              'respondentEmail',
              'respondentType',
              'status'
            ]"></mat-header-row>
          <mat-row *matRowDef="
              let respondent;
              columns: [
                'managerName',
                'respondentName',
                'respondentEmail',
                'respondentType',
                'status'
              ]
            "></mat-row>
        </mat-table>
      </div>

      <!-- <div class="col-md-12" style="text-align: center; padding: 20px 0">
        <button
          class="btn gmi-primary-btn"
          style="width: 200px"
          (click)="prepareLaunchData()"
        >
          Submit
        </button>
      </div> -->
    </div>
    <div class="row" style="text-align: center; padding: 20px 0"
      *ngIf="respondentDataOnEdit?.length > 0 || allNewManagers?.length > 0">
      <button class="btn gmi-primary-btn" style="width: 200px" (click)="prepareLaunchData()">
        Submit
      </button>
    </div>
  </div>
</ng-template>