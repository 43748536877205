<div class="container mt-4">
  <div class="button-custom">
    <button mat-raised-button color="primary" (click)="openModal()">
      Add Custom Achievement
    </button>
  </div>
  <table
    mat-table
    [dataSource]="allAchievementDetails"
    class="mat-elevation-z8 table table-bordered table-hover"
  >
    <!-- Achievement Title Column -->
    <ng-container matColumnDef="achievementTitle">
      <th mat-header-cell *matHeaderCellDef>Achievement Title</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="!element.isEditing">{{ element.achievementTitle }}</span>
        <input
          *ngIf="element.isEditing"
          [(ngModel)]="element.achievementTitle"
          class="form-control"
        />
      </td>
    </ng-container>

    <!-- Achievement Description Column -->
    <ng-container matColumnDef="achievementDescription">
      <th mat-header-cell *matHeaderCellDef>Achievement Description</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="!element.isEditing">{{
          element.achievementDescription
        }}</span>
        <textarea
          *ngIf="element.isEditing"
          [(ngModel)]="element.achievementDescription"
          class="form-control"
        ></textarea>
      </td>
    </ng-container>

    <ng-container matColumnDef="message">
      <th mat-header-cell *matHeaderCellDef>Message</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="!element.isEditing">{{ element.message }}</span>
        <textarea
          *ngIf="element.isEditing"
          [(ngModel)]="element.message"
          class="form-control"
        ></textarea>
      </td>
    </ng-container>

    <!-- Achievement Base Image Column -->
    <ng-container matColumnDef="baseImage">
      <th mat-header-cell *matHeaderCellDef>Base Image</th>
      <td mat-cell *matCellDef="let element">
        <!-- Render image in view mode -->
        <img
          *ngIf="!element.isEditing"
          [src]="element.baseImage"
          alt="Achievement Image"
          class="img-thumbnail img-width"
        />
        <!-- Render text input in edit mode -->
        <input
          *ngIf="element.isEditing"
          [(ngModel)]="element.baseImage"
          class="form-control"
          style="width: 300px"
        />
      </td>
    </ng-container>

    <!-- Is Active Column -->
    <ng-container matColumnDef="isActive">
      <th mat-header-cell *matHeaderCellDef>Is Active</th>
      <td mat-cell *matCellDef="let element">
        <mat-slide-toggle
          [checked]="!!element.isActive"
          color="primary"
          [disabled]="!element.isEditing"
          (change)="onToggleActive(element)"
        >
        </mat-slide-toggle>
      </td>
    </ng-container>

    <ng-container matColumnDef="achievementType">
      <th mat-header-cell *matHeaderCellDef>Achievement Type</th>
      <td mat-cell *matCellDef="let element">
        <h3>{{ element.achievementType }}</h3>
      </td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let element">
        <!-- Edit Button -->
        <button
          *ngIf="!element.isEditing"
          mat-raised-button
          color="accent"
          (click)="editRow(element)"
        >
          Edit
        </button>

        <!-- Save Button -->
        <button
          *ngIf="element.isEditing"
          mat-raised-button
          color="primary"
          (click)="saveRow(element)"
        >
          Save
        </button>

        <!-- Cancel Button -->
        <button
          *ngIf="element.isEditing"
          mat-raised-button
          class="gray-button"
          (click)="cancelEdit(element)"
        >
          Cancel
        </button>

        <!-- View Button (conditionally displayed when achievementTypeId === 3) -->
        <button
          *ngIf="element.achievementTypeId === CUSTOM_ACHIEVEMENT_ID"
          mat-raised-button
          class="gray-button"
          (click)="viewAchievement(element)"
        >
          View
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <ng-template #modalTemplate>
    <h2 mat-dialog-title>Add Achievement</h2>
    <mat-dialog-content>
      <form [formGroup]="modalForm" (ngSubmit)="onModalSubmit()">
        <!-- Achievement Title -->
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Achievement Title</mat-label>
          <input matInput formControlName="achievementTitle" />
        </mat-form-field>

        <!-- Achievement Description -->
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Achievement Description</mat-label>
          <textarea
            matInput
            formControlName="achievementDescription"
          ></textarea>
        </mat-form-field>

        <!-- Base Image URL -->
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Base Image URL</mat-label>
          <input
            matInput
            formControlName="baseImage"
            placeholder="https://example.com/image.png"
            type="url"
          />
          <mat-error *ngIf="modalForm.get('baseImage')?.hasError('pattern')">
            Please enter a valid URL.
          </mat-error>
        </mat-form-field>

        <!-- Message -->
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Message</mat-label>
          <textarea matInput formControlName="message"></textarea>
        </mat-form-field>

        <!-- File Upload -->
        <div class="file-upload">
          <label for="file">Upload File (.xlsx, .xls, .csv)</label>
          <input
            id="file"
            type="file"
            accept=".xlsx,.xls,.csv"
            class="file-input"
            (change)="onFileChange($event)"
          />
          <div class="error" *ngIf="fileError">{{ fileError }}</div>
        </div>

        <div mat-dialog-actions align="end">
          <button mat-raised-button color="warn" mat-dialog-close>
            Cancel
          </button>
          <button
            mat-raised-button
            color="primary"
            [disabled]="modalForm.invalid || fileError"
          >
            Submit
          </button>
        </div>
      </form>
    </mat-dialog-content>
  </ng-template>

  <ng-template #customModal let-data="dataSourceCustom">
    <mat-dialog-content>
      <h2 mat-dialog-title class="modal-title">Achievements</h2>
      <div class="table-container">
        <mat-table
          [dataSource]="dataSourceCustom"
          class="mat-elevation-z8 table table-bordered table-hover"
          *ngIf="dataSourceCustom.data.length > 0"
        >
          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
          </ng-container>

          <!-- Email Column -->
          <ng-container matColumnDef="email_id">
            <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
            <mat-cell *matCellDef="let element">{{
              element.email_id
            }}</mat-cell>
          </ng-container>

          <!-- Achievement Title Column -->
          <ng-container matColumnDef="achievement_title">
            <mat-header-cell *matHeaderCellDef>Title</mat-header-cell>
            <mat-cell *matCellDef="let element">{{
              element.achievement_title
            }}</mat-cell>
          </ng-container>

          <!-- Achievement Date Column -->
          <ng-container matColumnDef="achievement_date">
            <mat-header-cell *matHeaderCellDef>Date</mat-header-cell>
            <mat-cell *matCellDef="let element">{{
              element.achievement_date | date
            }}</mat-cell>
          </ng-container>

          <!-- Image Column -->
          <ng-container matColumnDef="achievement_image_url">
            <mat-header-cell *matHeaderCellDef>Image</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <img
                [src]="element.achievement_image_url"
                alt="Achievement Image"
                width="50"
                height="50"
              />
            </mat-cell>
          </ng-container>

          <!-- Trackable URL Column -->
          <ng-container matColumnDef="trackable_url">
            <mat-header-cell *matHeaderCellDef>Trackable URL</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <a [href]="element.trackable_url" target="_blank">Open Link</a>
            </mat-cell>
          </ng-container>

          <!-- Table Header and Row -->
          <mat-header-row
            *matHeaderRowDef="displayedColumnsCustom"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedColumnsCustom"
          ></mat-row>
        </mat-table>
        <p *ngIf="dataSourceCustom.data.length === 0">No data available.</p>
      </div>
    </mat-dialog-content>
  </ng-template>
</div>
