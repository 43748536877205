<div class="container cont">
  <div class="logo">
    <img
      src="../../../assets/images/GMI Logo (Registered).png"
      alt="GMI Logo"
    />
  </div>
  <div class="heading">
    <p>
      ” A leader is the one who can outline the broad vision and the direction,
      and say here’s where we are going to go, here’s why we need to go there,
      and here’s how we are going to get there. A manager is the one who
      actually gets up under the hood and tunes the carburetor.” –
      <strong>Mike Huckabee</strong>
    </p>
  </div>
  <div class="button">
    <button class="btn btn-primary" (click)="agreeToMerge()">
      I agree to merge
    </button>
  </div>
</div>
