import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from './auth.service';
// import * as decode from "jwt-decode";

@Injectable()
export class CompanyRoleGuardService implements CanActivate {
  wantedUrl: any;

  constructor(public auth: AuthService, public router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    // this will be passed from the route config
    // on the data property
    const expectedRole = route.data['expectedRole'];

    const token = localStorage.getItem('token');
    //  console.log(token)
    let url1: string = state.url;
    this.wantedUrl = url1.split('/')[1];
    //  console.log(this.wantedUrl)
    // this ogic needs to be chnaged
    // When user dont have valid token
    if (!token) {
      let url: string = state.url;
      this.wantedUrl = url.split('/')[1];
      if (this.wantedUrl == 'company') {
        this.router.navigate(['/company']);
        return false;
      } else {
        this.router.navigate(['/', this.wantedUrl]);
        return false;
      }
    }

    // decode the token to get its payload
    // Check Authenticated User or Not.
    let url: string = state.url;
    if (!this.auth.isAuthenticated()) {
      // this.router.navigate(['login']);
      this.wantedUrl = url.split('/')[1];
      if (this.wantedUrl == 'company') {
        this.router.navigate(['/company']);
        return false;
      } else {
        this.router.navigate(['/', this.wantedUrl]);
        return false;
      }
    }

    // Acess Control Logic

    // return true;
    return false;
  }
}
