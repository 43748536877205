<div class="main-box p-5">
  <div class="p-3" #nameFormSection>
    <h2>Nudge Varying Names Management</h2>
  </div>

  <form [formGroup]="nameForm">
    <div class="row align-items-center">
      <div class="col-md-6">
        <div class="form-group pb-10">
          <label for="name">Name<span class="asterick">*</span></label>
          <input
            class="form-control custom-form-control"
            required
            id="name"
            name="name"
            type="text"
            formControlName="name"
            appNoSpaces
            appNoNumbers
            placeholder="Enter name..."
          />
          <p
            class="error"
            *ngIf="
              nameForm.get('name').hasError('required') &&
              nameForm.get('name').touched
            "
          >
            Name is required.
          </p>
          <p
            class="error"
            *ngIf="
              nameForm.get('name').hasError('minlength') &&
              nameForm.get('name').touched
            "
          >
            Name must be at least 5 characters long.
          </p>
          <p
            class="error"
            *ngIf="
              nameForm.get('name').hasError('maxlength') &&
              nameForm.get('name').touched
            "
          >
            Name cannot be more than 20 characters long.
          </p>
        </div>
        <div class="pb-10 mt-5">
          <label>Status<span class="asterick">*</span></label>
          <br />
          <mat-slide-toggle
            formControlName="is_active"
            [checked]="nameForm.value.is_active == 1 ? true : false"
            >{{
              nameForm.value.is_active == 1 ? "Active" : "Inactive"
            }}</mat-slide-toggle
          >
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group pb-10">
          <label for="file"
            >Upload Excel File to Bulk Create
            <span class="asterick">*</span></label
          >
          <input
            class="form-control-file"
            id="file"
            type="file"
            (change)="onFileChange($event)"
            accept=".xlsx, .xls"
          />
          <div>
            <p class="error" *ngIf="fileError">Error: {{ fileError }}</p>
            <span>
              <p class="error" *ngIf="errorNames?.length > 0">
                Above names are not valid:
              </p>
              {{ errorNames?.join(", ") }}
            </span>
          </div>
        </div>
        <div>
          <a href="{{ sampleExcelFile }}"> Download Sample </a>
        </div>

        <p class="disclaimer">
          Disclaimer: You can only upload up to 200 names at a time. Name must
          be at least 5 characters long. Name cannot be more than 20 characters
          long.
        </p>
      </div>
    </div>

    <div
      class="actions"
      style="display: flex; justify-content: center; gap: 20px"
    >
      <button
        type="button"
        (click)="createNameConfig(null)"
        style="margin-top: 25px; color: white"
        class="btn btn-secondary btn-newuser-clr"
        [disabled]="nameForm.invalid"
      >
        {{ isUpdate ? "Update" : "Create" }}
      </button>

      <button
        *ngIf="isUpdate"
        type="button"
        style="margin-top: 25px; color: white"
        class="btn btn-secondary btn-newuser-clr"
        (click)="nameForm.reset(); isUpdate = false"
      >
        Add New
      </button>
    </div>
  </form>

  <div class="main-box-table mat-elevation-z8">
    <div class="form-group pb-10 w-60">
      <input
        class="form-control custom-form-control"
        required
        type="text"
        placeholder="Search name"
        (keyup)="search($event.target['value'])"
      />
    </div>
    <mat-table [dataSource]="dataSource" class="w-100">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
      </ng-container>

      <!-- Created Date Column -->
      <ng-container matColumnDef="created_at">
        <mat-header-cell *matHeaderCellDef> Created Date </mat-header-cell>
        <mat-cell *matCellDef="let element">{{
          element.created_at | date : "mediumDate"
        }}</mat-cell>
        <div></div>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="is_active">
        <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="p-3">
            <mat-slide-toggle
              (change)="statusChange($event, element)"
              [(ngModel)]="element.is_active"
              [checked]="element.is_active == 1 ? true : false"
            >
            </mat-slide-toggle>
          </div>

          <div
            class="chip"
            [ngStyle]="{
              'background-color':
                element.is_active == 1 ? '#D3FCD2' : '#FFAC82',
              color: element.is_active == 1 ? '#118D57' : '#B71D18'
            }"
          >
            {{ element.is_active == 1 ? "Active" : "Inactive" }}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell class="text-center" *matCellDef="let element">
          <i
            (click)="patchValues(element)"
            class="fa fa-pencil-square-o"
            aria-hidden="true"
          ></i>
          <!-- <i
            (click)="deleteUserGuide(element)"
            class="fa fa-trash m-left"
            aria-hidden="true"
          ></i> -->
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>

    <mat-paginator [pageSizeOptions]="[10, 15, 20]"></mat-paginator>
  </div>
</div>
