// import { Component, OnInit } from '@angular/core';
import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  ChangeDetectorRef,
} from "@angular/core";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { CompanyServices } from "../../company.service";
// import * as jwt_decode from "jwt-decode";
import { jwtDecode } from "jwt-decode";
import swal from "sweetalert2";
import { formatDate } from "@angular/common";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  NgForm,
} from "@angular/forms";
import {
  USER_TYPE,
  PAGE_DETAILS,
  PAGE_TYPE,
  constants,
} from "../../../../app/constants";
import * as XLSX from "xlsx";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { findLastKey } from "lodash";
import { Editor, Toolbar } from "ngx-editor";
import { toCamelCase } from "src/config/utils";
const COLUMN_NAME = "scores";
@Component({
  selector: "app-leadership-dashboard",
  templateUrl: "./leadership-dashboard.component.html",
  styleUrls: ["./leadership-dashboard.component.css"],
})
export class LeadershipDashboardComponent implements OnInit {
  editor: Editor;
  toolbar: Toolbar = [
    ["bold", "italic"],
    ["underline", "strike"],
    ["code", "blockquote"],
    ["ordered_list", "bullet_list"],
    [{ heading: ["h1", "h2", "h3", "h4", "h5", "h6"] }],
    ["link", "image"],
    ["text_color", "background_color"],
    ["align_left", "align_center", "align_right", "align_justify"],
  ];
  userType = USER_TYPE;
  pageDetails = PAGE_DETAILS;
  pageType = PAGE_TYPE;
  userId;
  @ViewChild("closebutton") closebutton;
  showHeading = true;
  modalRef: BsModalRef;
  isDisabled = false;
  filter;
  filtersArray = [];
  Userdata = [];
  loading: boolean = false;
  allPossibleFilters = [];
  finalDisplayFilters: any = [];
  companyId: any;
  editCompanyName: any;
  editEmail: any;
  id: any;
  disableAddRole: boolean = false;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  companyName: any;
  addCompanyName: any;
  filtersSelectedItems = [];
  dropdownSettings2: {
    singleSelection: boolean;
    text: string;
    selectAllText: string;
    unSelectAllText: string;
    enableSearchFilter: boolean;
    classes: string;
  };
  selectedEditItems: any;
  keyinsight = 1;
  organalytic = 2; //page_type:2
  componentData: any;
  showLoading: boolean = false;
  addMapping: boolean;
  disableSubmitCheck: boolean = true;
  batchNames = [];
  scoreType = 1;
  sampleExcelLink =
    constants.IMAGE_URL + "assets/sample/sample%2Bmanager%2Bdemo.xlsx";
  selectedUsers: any[] = [];
  selectAll = false;
  selectedSurveyGroupIdForEmployeePerformance: FormControl = new FormControl(
    ""
  );
  performanceOptions: any = [];
  managerList: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  currentPage = 1;
  itemsPerPage = 5;
  displayedData: any[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ["manager_name", "manager_email"];
  addpassword: any;
  addEmail: any;
  emailStatus: any;
  toTypes: Array<any> = [
    {
      type: "High",
      value: 4.5,
    },
    {
      type: "Medium",
      value: 3,
    },
    {
      type: "Low",
      value: 1.5,
    },
    {
      type: "NA",
      value: 0,
    },
  ];
  levels: any = {};
  isEdit: boolean = false;
  // levels: { [key: string]: string[] } = {};
  levelsOptions: Array<any> = [];
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: false,
    height: "auto",
    minHeight: "500px",
    maxHeight: "auto",
    width: "auto",
    minWidth: "0",
    translate: "true",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "Enter text here...",
    defaultParagraphSeparator: "",
    defaultFontName: "Times New Roman",
    defaultFontSize: "",
    fonts: [
      { class: "arial", name: "Arial" },
      { class: "times-new-roman", name: "Times New Roman" },
      { class: "calibri", name: "Calibri" },
      { class: "comic-sans-ms", name: "Comic Sans MS" },
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
    uploadUrl: "v1/image",
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: "top",
    toolbarHiddenButtons: [["insertImage", "insertVideo", "fontName"]],
  };
  emailType: any;
  selectedEmailType: any;
  roleForm: FormGroup;
  surveyGroups = [];
  demoNames = {};
  demoValues = {};
  surveyGroups2;
  emailData = [
    {
      subject: "",
      content: "",
    },
  ];
  groupedComponents: {
    pageType: string;
    components: any[];
    userType: string;
  }[] = [];
  employeeAnalyticsTableHeader = [
    "Visualization Name",
    "Status",
    "Title",
    "SubTitle",
  ];
  surveyData: any = [];
  selectedSurveyIds: number[] = [];
  selectedUserData: any;
  userSurveyData: any = [];
  headerChanged: boolean = true;
  desciptionChanged: boolean = true;
  selectedSurvey: any;
  benchmarkList: any = [];
  allBenchmarks: any = [];
  currentBenchmarkUserId: number = 0;
  employeeCustomScores: any | undefined;

  constructor(
    public modalService: BsModalService,
    private companyService: CompanyServices,
    private formBuilder: FormBuilder,

    private cdref: ChangeDetectorRef,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.roleForm = this.fb.group({
      email: new FormControl("", [Validators.required, Validators.email]),
      name: new FormControl("", Validators.required),
      mappings: this.fb.array([]),
    });
    // this.addMappingNew();
    this.editor = new Editor();
    this.decodeToken();
    this.getLADUserList();
    // this.getSubThemeandDemographics();
    this.getAllSurveyGroups();
    this.dropdownSettings = {
      singleSelection: false,
      text: "Select Filters",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableSearchFilter: true,
      classes: "myclass custom-class",
    };
    this.dropdownSettings2 = {
      singleSelection: false,
      text: "Select Filters",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableSearchFilter: true,
      classes: "myclass custom-class",
    };
  }

  resetFormArray() {
    this.dropdownSettings = {
      singleSelection: false,
      text: "Select Filters",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableSearchFilter: true,
      classes: "myclass custom-class",
    };
    // Clear the FormArray 'mappings'
    this.roleForm.setControl("mappings", this.fb.array([]));

    // Reset the rest of the form
    this.roleForm.reset({
      email: "",
      name: "",
    });
  }

  selectAllChanged() {
    if (this.selectAll) {
      // If "Select All" is checked, add all users to the selectedUsers array
      this.selectedUsers = [...this.Userdata];
    } else {
      // If "Select All" is unchecked, clear the selectedUsers array
      this.selectedUsers = [];
    }

    // Update the 'checked' property in each user object
    this.Userdata.forEach((user) => (user.checked = this.selectAll));
  }

  onCheckboxChange(event: any, user: any) {
    if (event.target.checked) {
      // If checkbox is checked, add the user to the selectedUsers array
      this.selectedUsers.push(user);
    } else {
      // If checkbox is unchecked, remove the user from the selectedUsers array
      const index = this.selectedUsers.findIndex(
        (selectedUser) => selectedUser === user
      );
      if (index !== -1) {
        this.selectedUsers.splice(index, 1);
      }
    }
    if (this.selectedUsers.length == this.Userdata.length) {
      this.selectAll = true;
    } else {
      this.selectAll = false;
    }
  }

  openEmailTemplate(template: TemplateRef<any>) {
    this.emailData[0].content = "";
    this.emailData[0].subject = "";
    this.companyService.getLadEmailType().subscribe((res: any) => {
      if (res.status) {
        this.emailType = res.data.emailFormat;

        this.modalRef = this.modalService.show(
          template,
          Object.assign({}, { class: "medium-modal" })
        );
        this.companyService.addModal(this.modalRef);
      } else {
        swal.fire(
          "error",
          res.message ? res.message : "Cannot find email type"
        );
      }
    });
  }

  onEmailTypeChanged(event) {
    this.emailData = this.emailType.filter((e) => e.id == event.target.value);
  }

  sendBulkMails() {
    const sendData = {
      data: [],
    };
    this.selectedUsers.map((user) => {
      const userDetails = {
        name: user.name,
        email: user.email,
      };
      sendData.data.push(userDetails);
    });
    this.companyService.sendBulkEmailLad(sendData).subscribe((res: any) => {
      if (res.status) {
        swal.fire(
          "success",
          res.message ? res.message : "Successfully sent email"
        );
      } else {
        swal.fire("error", res.message ? res.message : "Cannot send email");
      }
    });
  }

  sendBulkMailsToUsers(value) {
    const sendData = {
      userId: [],
      id: +value,
      subject: this.emailData[0]?.subject ? this.emailData[0].subject : "",
      content: this.emailData[0]?.content ? this.emailData[0].content : "",
    };

    this.selectedUsers.map((user) => {
      sendData.userId.push(user.id);
    });

    this.companyService.sendBulkEmailLadUser(sendData).subscribe((res: any) => {
      if (res.status) {
        swal.fire(
          "success",
          res.message ? res.message : "Successfully sent email"
        );
        this.emailData[0].subject = "";
        this.emailData[0].content = "";
        this.modalRef.hide();
      } else {
        swal.fire("error", res.message ? res.message : "Cannot send email");
        this.modalRef.hide();
      }
    });
  }
  openAddModal(identifier: string, template: TemplateRef<any>) {
    let config: ModalOptions = {
      backdrop: "static", // This prevents clicking outside the modal from closing it
      ignoreBackdropClick: true, // Set this to true as well
    };
    switch (identifier) {
      case "addTemplate":
        this.addCompanyName = this.companyName;
        // this.getUserList();
        this.selectedItems = [];
        this.finalDisplayFilters = [];
        config["class"] = "modal-lg";
        this.getAllSurveyGroups("Add User");
        break;
      case "managerDemographicsUpload":
        config["class"] = "modal-lg";
        break;
      case "employeeDemographicsUpload":
        config["class"] = "modal-lg";
        break;
      case "addConfig":
        config["class"] = "modal-lg";
        this.getCompanyConfigData();
        break;
    }
    this.modalRef = this.modalService.show(template, config);
    this.companyService.addModal(this.modalRef);
  }
  getCompanyConfigData() {
    let data = {
      companyId: this.companyId,
    };
    this.companyService.getCompanyConfigData(data).subscribe((res: any) => {
      if (res.status) {
        this.componentData = res.data;
        this.componentData.componentDetails =
          this.componentData.componentDetails.map(
            ({ name: component_name, ...rest }) => ({
              component_name,
              ...rest,
            })
          );
        this.componentData.pageDetails = this.componentData.pageDetails.map(
          ({ name: component_name, ...rest }) => ({
            component_name,
            ...rest,
          })
        );
        this.componentData.employeeAnalyticsDetails =
          this.componentData.employeeAnalyticsDetails.map(
            ({ name: component_name, ...rest }) => ({
              component_name,
              ...rest,
            })
          );

        this.componentData.employeeAnalyticsDetails.sort((a, b) => {
          // First, sort by page_type
          if (a.page_type !== b.page_type) {
            return a.page_type - b.page_type;
          } else {
            // If page_type is the same, sort by component_name
            return a.component_name.localeCompare(b.component_name);
          }
        });
        this.groupedComponents = this.getGroupedComponents(
          this.componentData.employeeAnalyticsDetails
        );
      } else {
        this.showLoading = false;
        swal.fire({
          title: "Company Configuration",
          text: res.message || "Something went wrong!",
          icon: "error",
          showConfirmButton: true,
        });
      }
    });
  }
  openMapViewModal(template: TemplateRef<any>) {
    const config = { class: "modal-lg" };
    this.modalRef = this.modalService.show(template, {
      class: "modal-lg",
      backdrop: "static",
      keyboard: false,
    });
    // this.modalRef = this.modalService.show(template, config);
    this.companyService.addModal(this.modalRef);
    this.addCompanyName = this.companyName;
  }
  getLADUserList() {
    this.showLoading = true;
    this.Userdata = [];
    this.companyService
      .getLADUserList(this.companyId, "company")
      .subscribe((res: any) => {
        if (res.status) {
          this.showLoading = false;
          this.Userdata = res.data;
          this.companyService.updateLADUserList(this.Userdata);
        } else {
          this.showLoading = false;
          swal.fire({
            title: "User List",
            text: res.message || "Something went wrong!",
            icon: "error",
            showConfirmButton: true,
          });
        }
      });
  }
  getSubThemeandDemographics(surveyId, type?) {
    this.loading = true;
    this.companyService
      .getSubThemeandDemographics(this.companyId, surveyId)
      .subscribe((res) => {
        if (res.status) {
          if (type !== "edit") {
            this.allPossibleFilters = [];
            this.dropdownList = [];
            this.finalDisplayFilters = [];
            this.selectedItems = [];
          }
          this.loading = false;
          setTimeout(() => {
            res.data.demographics.forEach((filter, index) => {
              if (filter.values.length > 0) {
                this.dropdownList.push({
                  id: index,
                  itemName: filter.name,
                });
                this.addUniqueObject(this.allPossibleFilters, filter);
                // this.allPossibleFilters.push(filter);
              }
            });
          }, 0);
          this.cdref.detectChanges();
        }
      });
  }

  openEditModal(template: TemplateRef<any>, data) {
    this.selectedEditItems = [];
    this.finalDisplayFilters = [];
    this.companyService.getUserSurveyGroup(data.id).subscribe((res) => {
      if (res.status) {
        this.userSurveyData = res.data[0];

        if (this.userSurveyData.survey_group_ids.length === 0) {
          // this.surveyData.forEach((survey) => {
          //   survey.checked = true;
          // });
        } else {
          this.surveyData.forEach((survey) => {
            survey.checked = this.userSurveyData.survey_group_ids.includes(
              survey.surveyGroupId
            );

            if (survey.checked) {
              this.getSubThemeandDemographics(survey.surveyGroupId, "edit");
            }
          });
        }
      }
    });
    // this.modalRef.hide();
    const config = { class: "modal-lg" };
    this.id = data.id;
    this.editCompanyName = data.name;
    this.editEmail = data.email;
    let filtered = data.filter || data.filters;

    const filterMap = new Map();

    // Group filter options by filter category
    filtered.forEach((item) => {
      const { filterCategory, filterOption } = item;
      if (!filterMap.has(filterCategory)) {
        filterMap.set(filterCategory, []);
      }
      filterMap.get(filterCategory).push(filterOption);
    });

    const modifiedFilter = Array.from(filterMap.entries()).map(
      ([filterCategory, filterOption]) => ({
        filterCategory,
        filterOption,
      })
    );

    modifiedFilter.map((val, index) => {
      let obj = {};
      obj["id"] = this.allPossibleFilters.findIndex(
        (filter) => filter.name == val.filterCategory
      );
      obj["itemName"] = val.filterCategory;
      this.selectedEditItems.push(obj);

      let subObj = this.allPossibleFilters.find(
        (val1) => val1.name == val.filterCategory
      );

      if (!subObj) {
        subObj = {
          name: val.filterCategory,
          values: [],
          newOption: [],
          selectedItems: [],
        };
        this.allPossibleFilters.push(subObj);
      }
      subObj["newOption"] = [];
      subObj["selectedItems"] = [];
      subObj.values.map((val, index) => {
        let values = {};
        values["id"] = val;
        values["itemName"] = val;
        subObj["newOption"].push(values);
      });

      if (val.filterOption.length > 0) {
        val.filterOption.map((item) => {
          let multiVal = {
            id: subObj.newOption.findIndex((filter) => filter.id == item),
            itemName: item,
          };

          subObj["selectedItems"].push(multiVal);
        });
      } else {
        subObj["selectedItems"] = [
          {
            id: subObj.newOption.findIndex(
              (filter) => filter.id == val.filterOption
            ),
            itemName: val.filterOption,
          },
        ];
      }
      setTimeout(() => {
        this.finalDisplayFilters.push(subObj);
        this.cdref.detectChanges();
      }, 0);
    });

    // this.modalRef = this.modalService.show(template, config);
    this.modalRef = this.modalService.show(template, {
      class: "modal-lg",
      backdrop: "static",
      keyboard: false,
    });
    this.companyService.addModal(this.modalRef);
  }

  addUniqueObject(arr, newObj) {
    const exists = arr.some((item) => item.name === newObj.name);

    if (!exists) {
      arr.push(newObj);
    } else {
      console.log(`Object with name "${newObj.name}" already exists.`);
    }
  }

  deleteUser(data) {
    swal
      .fire({
        title: "Are you sure you want to delete?",
        text: "You will not be able to recover this user!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
      .then((isConfirm) => {
        if (isConfirm.value) {
          this.confirmDeleteUser(data);
        }
      });
  }

  confirmDeleteUser(data) {
    this.companyService.deleteUser(data.id).subscribe((res: any) => {
      if (res.status) {
        swal.fire("success", res.message);
        // this.getUserList();
        this.getLADUserList();
        // this.Userdata = this.Userdata.filter((item) => item.id != data.id);
        this.companyService.updateLADUserList(this.Userdata);
      } else {
        swal.fire("error", res.message);
      }
    });
  }
  onFormSubmit(form: NgForm, type) {
    this.filtersArray = [];

    // new
    this.finalDisplayFilters.map((value) => {
      if (value.selectedItems.length > 0) {
        value.selectedItems.map((item) => {
          let filter = {};
          filter["filterCategory"] = value.name;
          filter["filterOption"] = item.itemName;
          this.filtersArray.push(filter);
        });
      }
    });
    let selectedId = this.surveyData
      .filter((survey) => survey.checked)
      .map((survey) => survey.surveyGroupId);
    let finalObj = {};

    if (type == "add") {
      finalObj = {
        name: this.companyName,
        email: form.value.email,
        // password: form.value.password,
        companyId: this.companyId,
        filters: this.filtersArray,
        surveyGroupId: selectedId,
      };
      this.companyService.addUser(finalObj).subscribe((res: any) => {
        if (res.status) {
          swal.fire("success", res.message);
          form.reset();
          // this.Userdata.push(res.data);
          // this.getUserList();
          finalObj["id"] = res.data.userId;
          finalObj["component"] = [];

          this.Userdata.push(finalObj);

          this.companyService.updateLADUserList(this.Userdata); // Update the shared data
        } else {
          form.reset();
          this.addEmail = "";
          swal.fire("error", res.message);
        }
      });
    } else {
      const editedData = this.Userdata.find((u) => u.id === this.id);
      finalObj = { ...editedData };
      finalObj["filters"] = this.filtersArray;
      finalObj["userId"] = this.id;
      finalObj["component"] = "";
      finalObj["surveyGroupId"] = selectedId;
      delete finalObj["id"];
      delete finalObj["password"];
      delete finalObj["company_id"];
      delete finalObj["filter"];
      delete finalObj["component"];
      delete finalObj["max_timeout"];
      delete finalObj["concurrent_session"];
      delete finalObj["default_components"];
      delete finalObj["company_components"];
      this.companyService.editUser(finalObj).subscribe((res: any) => {
        if (res.status) {
          swal.fire("success", res.message);
          // this.getUserList();
          const index = this.Userdata.findIndex((u) => u.id == this.id);

          editedData["filter"] = this.filtersArray;
          if (index != -1) this.Userdata[index] = editedData;
          this.getLADUserList();

          // this.companyService.updateLADUserList(this.Userdata);

          form.reset();
        } else {
          swal.fire("error", res.message);
          form.reset();
        }
      });
    }
  }
  onItemSelect(item: any) {
    this.allPossibleFilters.forEach((filter) => {
      let newObject = {};
      if (filter.name == item.itemName) {
        newObject = filter;
        newObject["selectedItems"] = [];
        newObject["newOption"] = [];
        filter.values.map((option) => {
          newObject["newOption"].push({
            id: option,
            itemName: option,
          });
        });
        this.addUniqueObject(this.finalDisplayFilters, newObject);
        // this.finalDisplayFilters.push(newObject);
      }
    });
  }

  OnItemDeSelect(item: any) {
    this.finalDisplayFilters = this.finalDisplayFilters.filter((filter) => {
      return filter.name != item.itemName;
    });
  }

  onSelectAll(items: any) {
    this.selectedItems = items;
    let alreadyIncluded = [];
    let newObject = {};
    this.finalDisplayFilters.map((already) => {
      alreadyIncluded.push(already.itemName);
    });
    this.selectedItems.forEach((item) => {
      this.allPossibleFilters.forEach((filter) => {
        if (
          filter.name == item.itemName &&
          !alreadyIncluded.includes(item.itemName)
        ) {
          newObject = filter;
          newObject["selectedItems"] = [];
          newObject["newOption"] = [];
          filter.values.map((option) => {
            newObject["newOption"].push({
              id: option,
              itemName: option,
            });
          });
          this.finalDisplayFilters.push(newObject);
        }
      });
    });
  }

  onDeSelectAll(items: any) {
    this.selectedItems = items;
    this.finalDisplayFilters = [];
  }

  onFilterSelectAll(items: any, itemId) {
    this.finalDisplayFilters.map((filter, index) => {
      if (filter.name == itemId) {
        this.finalDisplayFilters[index]["selectedItems"] = items;
      }
    });
  }

  onFilterDeSelectAll(items: any, itemId) {
    this.finalDisplayFilters.map((filter, index) => {
      if (filter.dbName == itemId) {
        this.finalDisplayFilters[index]["selectedItems"] = items;
      }
    });
  }

  findMissingDataAndPush(component, defaultComponet) {
    const missingData = defaultComponet.filter((element) => {
      return !component.some(
        (item) => item.component_name === element.component_name
      );
    });

    return component.concat(missingData);
  }

  openUserMapViewModal(template: TemplateRef<any>, data) {
    this.id = data.id;
    this.addMapping = data.component.length == 0 ? true : false;
    this.componentData = [];

    if (data.component.length > 0) {
      if (data?.company_components?.length > 0) {
        data.component = this.findMissingDataAndPush(
          data.component,
          data.company_components
        );
      } else {
        data.component = this.findMissingDataAndPush(
          data.component,
          data.default_components
        );
      }
      //remove from component and push in pagedetails to show in accordian header
      let filteredItems = data.component.filter(
        (item) =>
          item.component_name === "key-insights" ||
          item.component_name === "org-profile"
      );
      if (filteredItems.length) {
        this.componentData.pageDetails = filteredItems;
      } else this.componentData.pageDetails = this.pageDetails;

      //remove from componeneDetails not to shown in accordian
      this.componentData["componentDetails"] = data.component;
      this.componentData["componentDetails"] = this.componentData[
        "componentDetails"
      ].filter(
        (item) =>
          item.component_name != "key-insights" &&
          item.component_name != "org-profile" &&
          item.page_type != this.pageType.MENU_OPTIONS &&
          item.page_type != this.pageType.THEME_COLOR &&
          item.page_type != this.pageType.VISUALIZATION
      );

      //remove from component and add in Employee Analytics
      let emplFilteredItems = data.component.filter(
        (item) =>
          item.page_type == this.pageType.MENU_OPTIONS ||
          item.page_type == this.pageType.THEME_COLOR ||
          item.page_type == this.pageType.VISUALIZATION
      );
      if (emplFilteredItems.length) {
        emplFilteredItems.sort((a, b) => {
          // First, sort by page_type
          if (a.page_type !== b.page_type) {
            return a.page_type.localeCompare(b.page_type);
          } else {
            // If page_type is the same, sort by component_name
            return a.component_name.localeCompare(b.component_name);
          }
        });
        this.componentData.employeeAnalyticsDetails = emplFilteredItems;

        this.groupedComponents = this.getGroupedComponents(
          this.componentData.employeeAnalyticsDetails
        );
        this.cdref.detectChanges();
      }
    } else {
      this.getComponentData();
    }
    const config = { class: "modal-lg" };
    // this.modalRef = this.modalService.show(template, config);
    this.modalRef = this.modalService.show(template, {
      class: "modal-lg",
      backdrop: "static",
      keyboard: false,
    });
    this.companyService.addModal(this.modalRef);
  }
  getGroupedComponents(data) {
    return data.reduce((acc, component) => {
      if (component.page_type == this.pageType.VISUALIZATION) {
        const existingGroup = acc.find(
          (group) =>
            group.pageType === component.page_type &&
            group.userType === component.user_type
        );
        if (existingGroup) {
          existingGroup.components.push(component);
        } else {
          acc.push({
            pageType: component.page_type,
            userType: component.user_type,
            components: [component],
          });
        }
      } else if (
        component.page_type == this.pageType.MENU_OPTIONS ||
        component.page_type == this.pageType.THEME_COLOR
      ) {
        const existingGroup = acc.find(
          (group) =>
            group.pageType === component.page_type &&
            group.componentType === component.component_type
        );
        if (existingGroup) {
          existingGroup.components.push(component);
        } else {
          acc.push({
            pageType: component.page_type,
            componentType: component.component_type,
            components: [component],
          });
        }
      }
      return acc;
    }, []);
  }

  getComponentData(id?) {
    this.companyService.getComponentData().subscribe((res: any) => {
      if (res.status) {
        this.componentData = res.data;
        this.componentData.componentDetails =
          this.componentData.componentDetails.map(
            ({ name: component_name, ...rest }) => ({
              component_name,
              ...rest,
            })
          );
        this.componentData.pageDetails = this.componentData.pageDetails.map(
          ({ name: component_name, ...rest }) => ({
            component_name,
            ...rest,
          })
        );
        this.componentData.employeeAnalyticsDetails =
          this.componentData.employeeAnalyticsDetails.map(
            ({ name: component_name, ...rest }) => ({
              component_name,
              ...rest,
            })
          );

        this.componentData.componentDetails.map((data) => {
          data["is_active"] = false;
        });
        this.componentData.pageDetails.map((data) => {
          data["is_active"] = false;
        });
        this.componentData.employeeAnalyticsDetails.map((data) => {
          data["is_active"] = false;
        });
        // this.componentData.employeeAnalyticsDetails.sort((a, b) => {
        //   return a.component_name.localeCompare(b.component_name);
        // });
        this.componentData.employeeAnalyticsDetails.sort((a, b) => {
          // First, sort by page_type
          if (a.page_type !== b.page_type) {
            return a.page_type - b.page_type;
          } else {
            // If page_type is the same, sort by component_name
            return a.component_name.localeCompare(b.component_name);
          }
        });
        this.groupedComponents = this.getGroupedComponents(
          this.componentData.employeeAnalyticsDetails
        );
      } else {
        swal.fire("error", res.message);
      }
    });
  }
  convertToNumber(value: any): number {
    return Number(value);
  }
  saveMapping() {
    this.componentData.pageDetails = this.componentData.pageDetails.map(
      ({ component_name: name, ...rest }) => ({
        name,
        ...rest,
      })
    );

    this.componentData.componentDetails =
      this.componentData.componentDetails.map(
        ({ component_name: name, ...rest }) => ({
          name,
          ...rest,
        })
      );

    this.componentData.employeeAnalyticsDetails =
      this.componentData.employeeAnalyticsDetails.map(
        ({ component_name: name, ...rest }) => ({
          name,
          ...rest,
        })
      );

    try {
      this.checkEmployeeAnalyticsDetails(
        this.componentData.employeeAnalyticsDetails
      );
    } catch (error) {
      console.error(error.message);
      swal.fire(
        "error",
        "Error: Some objects have blank header or description"
      );

      return;
    }

    let obj = {};
    let foundItem = this.componentData["pageDetails"].filter(
      (item) => item.name == "key-insights" || item.name == "org-profile"
    );
    obj["pageDetails"] = this.componentData["pageDetails"];
    obj["componentDetails"] = this.componentData["componentDetails"];
    obj["employeeAnalyticsDetails"] =
      this.componentData["employeeAnalyticsDetails"];
    obj["userId"] = this.id;
    if (obj["userId"]) {
      this.companyService.editMapping(obj).subscribe((res) => {
        if (res.status) {
          swal.fire("success", res.message);
          this.modalRef.hide();
          this.getLADUserList();
        } else {
          swal.fire("error", res.message);
        }
      });
      // }
    }
  }
  saveConfig() {
    this.componentData.pageDetails = this.componentData.pageDetails.map(
      ({ component_name: name, ...rest }) => ({
        name,
        ...rest,
      })
    );

    this.componentData.componentDetails =
      this.componentData.componentDetails.map(
        ({ component_name: name, ...rest }) => ({
          name,
          ...rest,
        })
      );

    this.componentData.employeeAnalyticsDetails =
      this.componentData.employeeAnalyticsDetails.map(
        ({ component_name: name, ...rest }) => ({
          name,
          ...rest,
        })
      );

    try {
      this.checkEmployeeAnalyticsDetails(
        this.componentData.employeeAnalyticsDetails
      );
    } catch (error) {
      console.error(error.message);
      swal.fire(
        "error",
        "Error: Some objects have blank header or description"
      );

      return;
    }

    let obj = {};
    let foundItem = this.componentData["pageDetails"].filter(
      (item) => item.name == "key-insights" || item.name == "org-profile"
    );
    obj["pageDetails"] = this.componentData["pageDetails"];
    obj["componentDetails"] = this.componentData["componentDetails"];
    obj["employeeAnalyticsDetails"] =
      this.componentData["employeeAnalyticsDetails"];
    obj["companyId"] = this.companyId;
    if (obj["companyId"]) {
      this.companyService.editCompanyConfigData(obj).subscribe((res) => {
        if (res.status) {
          swal.fire("success", res.message);
          this.modalRef.hide();
          // this.getLADUserList();
        } else {
          swal.fire("error", res.message);
        }
      });
      // }
    }
  }

  checkEmployeeAnalyticsDetails(details) {
    for (const detail of details) {
      if (
        detail.page_type === "5" &&
        (!detail.header.trim() || !detail.description.trim())
      ) {
        throw new Error(
          "Header or description is blank for a page_type 5 component."
        );
      }
    }
  }
  changeStatus(status: string, data) {}

  resetFileInput() {
    // Reset the value of the file input
    const fileInput = document.getElementById(
      "myFileInput"
    ) as HTMLInputElement;
    fileInput.value = "";
  }

  onFileChange(event: any) {
    const file: File = event.target.files[0];
    const reader: FileReader = new FileReader();
    this.resetValues();

    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: "binary" });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      let data: any[] = XLSX.utils.sheet_to_json(ws, { raw: true, header: 1 });

      // Trim headers
      const headers: string[] = data
        .shift()
        .map((header: string) => header.toString().trim());

      // Convert all headers to lowercase for case-insensitive comparison
      const lowercaseHeaders = headers.map((header) => header.toLowerCase());

      const uniqueHeaders = new Set(lowercaseHeaders);
      if (uniqueHeaders.size != lowercaseHeaders.length) {
        swal.fire({
          title: "Manager Demographics",
          text: "Unique batches required",
          icon: "error",
          showConfirmButton: true,
        });
        event.target.value = null;
        this.resetValues();
        return;
      }
      // Trim data cells
      data = data.map((row: any[]) => {
        const trimmedRow: any = {};
        row.forEach((cell: any, i: number) => {
          const header = headers[i];
          const trimmedCell = cell.toString().trim(); // Trim cell value
          trimmedRow[header] = trimmedCell;
        });
        return trimmedRow;
      });

      // Filter out empty rows
      data = data.filter((row) =>
        Object.values(row).some((value) => value !== "")
      );

      if (data.length == 0) {
        swal.fire({
          title: "Manager Demographics",
          text: "Minimum one manager required",
          icon: "error",
          showConfirmButton: true,
        });
        event.target.value = null;
        this.resetValues();
        return;
      }

      if (headers.length <= 2) {
        swal.fire({
          title: "Manager Demographics",
          text: "Minimum one batch required",
          icon: "error",
          showConfirmButton: true,
        });
        event.target.value = null;
        this.resetValues();
        return;
      }
      this.batchNames = headers.slice(2);
      this.displayedColumns.push(
        ...this.batchNames.map((name) => this.toSnakeCase(name))
      );

      const allowedValues = this.toTypes.map((val) => val.type);

      for (let batch of this.batchNames) {
        const performanceColumnIndex = headers.indexOf(batch);
        const performanceData = data.map((row) => row[batch]);

        const uniqueAnswers = Array.from(new Set(performanceData));

        // Check if uniqueAnswers contains values other than "High", "Low", or "Medium"
        const invalidValues = uniqueAnswers.filter(
          (value) => !allowedValues.includes(value)
        );
        if (invalidValues.length > 0) {
          swal.fire({
            title: "Manager Demographics",
            text: "Performance column should contain only 'High', 'Low', 'Medium' or 'NA' values.",
            icon: "error",
            showConfirmButton: true,
          });
          this.resetValues();
          event.target.value = null;
          return;
        }
      }
      let errorFound = false;

      const output: any[] = data.map((row: any) => {
        if (Object.keys(row).length != this.displayedColumns.length) {
          swal.fire({
            title: "Manager Demographics",
            text: "Empty Name or Email Id found please check data",
            icon: "error",
            showConfirmButton: true,
          });
          this.resetValues();
          event.target.value = null;
          errorFound = true;
          return;
        }
        const obj: any = {};
        Object.entries(row).forEach(([key, value]) => {
          const snakeCaseHeader = this.toSnakeCase(key);
          obj[snakeCaseHeader] = value;
        });
        return obj;
      });
      if (errorFound) {
        return;
      }
      let allEmails = output.map((val) => val.manager_email);
      let uniqueEmails = [...new Set(allEmails)];
      if (allEmails.length != uniqueEmails.length) {
        swal.fire({
          title: "Manager Demographics",
          text: "Duplicate email IDs found, please check the data.",
          icon: "error",
          showConfirmButton: true,
        });
        this.resetValues();
        event.target.value = null;
        return;
      }

      this.managerList.data = output;

      this.paginateData();
    };

    reader.readAsBinaryString(file);
    // this.resetValues()
  }
  onFileChangeEmployee(event: any): void {
    const file: File | null = event.target?.files?.[0]; // Check if file exists
    if (!file) {
      console.error("No file selected");
      return;
    }

    const reader: FileReader = new FileReader();

    reader.onload = (e: ProgressEvent<FileReader>) => {
      const bstr: string = e.target?.result as string;
      if (!bstr) {
        console.error("Failed to read file content");
        return;
      }

      try {
        const toTypes: Array<any> = [
          { type: "High", value: 4.5 },
          { type: "Medium", value: 3 },
          { type: "Low", value: 1.5 },
          { type: "NA", value: 0 },
        ];

        // Parse the workbook
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: "binary" });

        // Get the first sheet
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        if (!ws) {
          console.error("Sheet not found");
          return;
        }

        // Convert sheet to JSON with raw data and headers
        const data: any[] = XLSX.utils.sheet_to_json(ws, {
          raw: true,
          header: 1,
        });
        if (data.length === 0) {
          console.error("No data found in the sheet");
          return;
        }

        // Extract and convert headers to snake case
        const headers: string[] = data
          .shift()
          .map(
            (header: any) =>
              header?.toString().trim().replace(/\s+/g, "_").toLowerCase() || ""
          );

        const jsonData = data.map((row) => {
          const feedbackGiverEmail = row[0]; // First column as feedback_giver_email
          const result = [];

          headers.slice(1).forEach((batchName, index) => {
            const labelName = row[index + 1]?.toString() || "NA"; // Use "NA" if value is missing
            const typeInfo = toTypes.find(
              (type) => type.type === labelName
            ) || {
              type: "NA",
              value: 0,
            };
            result.push({
              feedback_giver_email: feedbackGiverEmail,
              batch_name: batchName,
              label_name: typeInfo.type,
              score: typeInfo.value,
              type: 1,
            });
          });

          return result;
        });

        const flattenedData = jsonData.flat(); // Flatten nested arrays
        const finalData = {
          companyId: this.companyId,
          surveyGroupId: this.selectedSurveyGroupIdForEmployeePerformance.value,
          data: flattenedData,
        };
        this.employeeCustomScores = finalData;
      } catch (error) {
        console.error("Error processing the file", error);
      }
    };

    // Read the file as binary
    reader.readAsBinaryString(file);
  }

  changeDemographicsType(event) {
    this.scoreType = parseInt(event.target.value);
    this.resetValues();
  }

  onPageChange(event) {
    this.currentPage = event.pageIndex + 1; // Page index starts from 0
    this.itemsPerPage = event.pageSize;
    this.paginateData();
  }

  paginateData() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.displayedData = this.managerList.data.slice(startIndex, endIndex);
  }

  toSnakeCase(str: string) {
    return str.toLowerCase().replace(/\s+/g, "_");
  }
  insertLevels(scoreLabel: string, value: any) {
    this.levels[scoreLabel] = value.value;
    // this.levelsOptions.push(value.type);
    this.levels[scoreLabel] = value;
    // this.levelsOptions.push(value);
  }

  resetValues(): void {
    this.managerList = new MatTableDataSource<any>([]);
    this.displayedColumns = ["manager_name", "manager_email"];
  }
  submitPerformanceData() {
    let apiData = {
      data: [],
      companyId: this.companyId,
    };

    // Determine the score based on the value of res[this.toSnakeCase(batch)]

    this.managerList.data.map((res) => {
      for (let batch of this.batchNames) {
        // switch (res[this.toSnakeCase(batch)]) {
        //   case "High":
        //     score = 4.5;
        //     break;
        //   case "Medium":
        //     score = 3;
        //     break;
        //   case "Low":
        //     score = 1.5;
        //     break;
        // }
        let score = 0;
        let labelName = res[this.toSnakeCase(batch)];
        let typeObj = this.toTypes.find((type) => type.type === labelName);

        if (typeObj) {
          score = typeObj.value;
        }
        let data = {
          manager_email: res.manager_email,
          type: this.scoreType,
          score,
          label_name: res[this.toSnakeCase(batch)],
          batch_name: batch,
        };
        apiData.data.push(data);
      }
    });

    this.companyService.postPerformanceScores(apiData).subscribe((res) => {
      if (res.status) {
        swal.fire("success", res.message);
        this.resetValues();
        this.modalRef.hide();
      } else {
        swal.fire("error", res.message);
      }
    });
  }
  submitEmployeePerformanceData() {
    this.companyService
      .postEmployeePerformanceScores(this.employeeCustomScores)
      .subscribe((res) => {
        if (res.status) {
          swal.fire("success", res.message);
          this.employeeCustomScores = undefined;
          this.modalRef.hide();
        } else {
          swal.fire("error", res.message);
        }
      });
  }

  modify(component, type, action) {
    if (type === "header") {
      this.headerChanged = false;
      if (action === "edit") {
        component.editing = true;
        component.previousHeader = component.header;
      } else if (action === "cancel") {
        component.editing = false;
        component.header = component.previousHeader;
        this.headerChanged = true;
      } else if (action === "save") {
        component.editing = false;
        component.previousHeader = component.header;
        this.headerChanged = true;
      }
    } else {
      this.desciptionChanged = false;
      if (action === "edit") {
        component.editingDescription = true;
        component.previousDescription = component.description;
      } else if (action === "cancel") {
        component.editingDescription = false;
        component.description = component.previousDescription;
        this.desciptionChanged = true;
      } else if (action === "save") {
        component.editingDescription = false;
        component.previousDescription = component.description;
        this.desciptionChanged = true;
      }
    }
  }
  openSurveyModal(template: TemplateRef<any>, data) {
    if (Object.keys(this.surveyData).length == 0) {
      swal.fire("error", "No Survey data found");
      return;
    }
    this.selectedUserData = data;

    this.companyService
      .getUserSurveyGroup(this.selectedUserData.id)
      .subscribe((res) => {
        if (res.status) {
          this.userSurveyData = res.data[0];

          if (this.userSurveyData.survey_group_ids.length === 0) {
            // this.surveyData.forEach((survey) => {
            //   survey.checked = true;
            // });
          } else {
            this.surveyData.forEach((survey) => {
              survey.checked = this.userSurveyData.survey_group_ids.includes(
                survey.surveyGroupId
              );
            });
          }
          // const config = {
          //   class: "modal-lg",
          //   initialState: {
          //     context: { isVisible: true },
          //   },
          // };
          // this.modalRef = this.modalService.show(template, config);
          const initialState = {
            isVisible: true,
          };
          this.modalRef = this.modalService.show(template, {
            class: "modal-lg",
            initialState,
          });

          this.companyService.addModal(this.modalRef);
        } else {
          swal.fire("error", res.message);
        }
      });
  }
  sendSelectedSurvey() {
    let selectedId = this.surveyData
      .filter((survey) => survey.checked)
      .map((survey) => survey.surveyGroupId);

    let finalObj = {
      name: this.selectedUserData.name,
      email: this.selectedUserData.email,
      // companyId: this.selectedUserData.company_id,
      filters: this.selectedUserData.filter,
      userId: this.selectedUserData.id,
      // surveyGroupId: this.selectedSurveyIds,
      surveyGroupId: selectedId,
    };
    // this.companyService.addUser(finalObj).subscribe((res: any) => {
    this.companyService.editUser(finalObj).subscribe((res: any) => {
      if (res.status) {
        swal.fire("success", res.message);
        this.selectedUserData = null;
        this.modalRef.hide();
      } else {
        swal.fire("error", res.message);
      }
    });
  }
  toggleSurveySelection(surveyId: number) {
    const index = this.selectedSurveyIds.indexOf(surveyId);
    if (index === -1) {
      this.selectedSurveyIds.push(parseInt(surveyId.toString()));
    } else {
      this.selectedSurveyIds.splice(index, 1);
    }
  }

  toggleSurveySelectionNew(survey) {
    this.allPossibleFilters = [];
    this.selectedEditItems = [];
    this.selectedSurvey = survey;
    // survey.checked = !survey.checked;
    this.surveyData.forEach((allsurvey) => {
      allsurvey.checked = allsurvey.surveyGroupId === survey.surveyGroupId;
    });

    if (survey.checked) {
      this.getSubThemeandDemographics(survey.surveyGroupId);
      this.cdref.detectChanges();
    }
  }
  closeModal() {
    this.groupedComponents.forEach((group) => {
      group.components.forEach((component) => {
        component.editing = false;
        component.editingDescription = false;
      });
    });
    this.modalRef.hide();
  }
  onHeaderInputChange() {
    this.headerChanged = this.groupedComponents.some((page) => {
      return page.components.some((component) => {
        return (
          component.editing && component.header !== component.previousHeader
        );
      });
    });
  }
  onDescriptionInputChange() {
    this.desciptionChanged = this.groupedComponents.some((page) => {
      return page.components.some((component) => {
        return component.editingDescription;
      });
    });
  }
  isAnyComponentEditing(): boolean {
    return this.groupedComponents.some((page) => {
      return page.components.some(
        (component) => component.editing || component.editingDescription
      );
    });
  }

  get mappings() {
    return this.roleForm.get("mappings") as FormArray;
  }

  getAllSurveyGroups(data?: any) {
    this.companyService.getAllSurveyGroup(this.companyId).subscribe((res) => {
      if (res.status) {
        this.surveyGroups2 = res.data;
        this.surveyGroups = this.surveyGroups2.map((value: any) => ({
          id: value.surveyGroupId,
          itemName:
            "Start Date - " +
            formatDate(value.surveyStartDate, "dd-MM-YYYY", "en_US") +
            " | " +
            " End Date - " +
            formatDate(value.surveyEndDate, "dd-MM-YYYY", "en_US") +
            ` (${value.surveyGroupId})`,
        }));
      }
    });
  }

  decodeToken() {
    if (localStorage.getItem("token") !== null) {
      let token: any = localStorage.getItem("token");
      let tokenData: any = jwtDecode(token);
      // let tokenData = jwt_decode(localStorage.getItem("token"));
      this.companyId = tokenData.companyId;
      this.companyName = tokenData.company;
      this.roleForm.patchValue({
        name: this.companyName,
      });
    }
  }

  addMappingNew(existingData?: any) {
    const mappingGroup = this.fb.group({
      surveyGroupId: new FormControl(
        existingData ? existingData.surveyGroupId : ""
      ),
      demoName: new FormControl(
        existingData && existingData.demoName ? existingData.demoName : []
      ),
      demoValues: this.fb.array(
        existingData && existingData.demoValues.length > 0
          ? existingData.demoValues.map((value: any) => new FormControl(value))
          : []
      ),
    });

    mappingGroup
      .get("surveyGroupId")
      ?.valueChanges.subscribe((surveyGroupId) => {
        const demoNameControl = mappingGroup.get("demoName") as FormControl;
        demoNameControl.setValue([]); // Ensure it is cleared when surveyGroupId changes
        // Call the method to handle the event
        if (
          surveyGroupId !== null ||
          surveyGroupId !== "" ||
          surveyGroupId !== undefined
        ) {
          this.onSurveyGroupIdChange(surveyGroupId);
        }
      });

    mappingGroup.get("demoName")?.valueChanges.subscribe((demoNames) => {
      const demoValuesArray = mappingGroup.get("demoValues") as FormArray;

      // Create a map of demoId to its corresponding values
      const demoValuesMap = new Map<number, any[]>();

      demoValuesArray.controls.forEach((control) => {
        const values = control.value; // values is an array
        if (Array.isArray(values)) {
          values.forEach((value) => {
            const demoId = value?.demoId;
            if (demoId !== undefined) {
              if (!demoValuesMap.has(demoId)) {
                demoValuesMap.set(demoId, []);
              }
              demoValuesMap.get(demoId)?.push(value);
            }
          });
        }
      });

      // Clear and update demoValues based on selected demoNames
      demoValuesArray.clear();
      demoNames.forEach((demoName) => {
        const demoId = demoName.id; // Get the demoId from the demoName
        const existingValues = demoValuesMap.get(demoId) || []; // Get values by demoId
        demoValuesArray.push(new FormControl(existingValues));
      });
    });

    this.mappings.push(mappingGroup);
  }

  removeMapping(index: number) {
    this.mappings.removeAt(index);
  }

  onSubmit(edit: boolean) {
    const formattedDataFilter = this.formatFormData(this.roleForm.value);

    const selectedSurveyGroupIds = this.roleForm.value.mappings
      .map((mapping: any) => mapping.surveyGroupId)
      .filter((id: any) => id !== null && id !== undefined && id !== "");

    const formData = {
      name: this.roleForm.get("name")?.value,
      email: this.roleForm.get("email")?.value,
      companyId: this.companyId,
      filters:
        formattedDataFilter?.filters.length > 0
          ? formattedDataFilter.filters
          : [],
      surveyGroupId:
        selectedSurveyGroupIds.length > 0 ? selectedSurveyGroupIds : [],
    };

    if (edit) {
      this.editUser(formData);
    } else {
      this.addUser(formData);
    }
  }

  addUser(formData) {
    this.companyService.addUser(formData).subscribe((res: any) => {
      if (res.status) {
        swal.fire("success", res.message);
        this.roleForm.reset();
        this.getLADUserList();
        this.companyService.updateLADUserList(this.Userdata); // Update the shared data
        this.closeOpenModal();
      } else {
        swal.fire("error", res.message ? res.message : "Something went wrong");
        this.roleForm.reset();
        this.closeOpenModal();
      }
    });
  }

  addUserPopup(identifier: any, template: any) {
    this.isEdit = false;
    this.resetFormArray();
    this.openAddModal(identifier, template);
    // this.modalService.hide();
  }

  closeOpenModal() {
    this.modalService.hide();
  }

  editUser(formData) {
    let finalData = formData;
    finalData["userId"] = this.userId;
    delete finalData["companyId"];
    this.companyService.editUser(formData).subscribe((res: any) => {
      if (res.status) {
        swal.fire("success", res.message);
        this.resetFormArray();
        this.getLADUserList();
        this.companyService.updateLADUserList(this.Userdata); // Update the shared data
        this.closeOpenModal();
      } else {
        swal.fire("error", res.message ? res.message : "Something went wrong");
        this.resetFormArray();
        this.closeOpenModal();
      }
    });
  }

  getDemoValueName(demoValueId: number) {
    for (let key in this.demoNames) {
      const demoValue = this.demoNames[key].find(
        (value) => value.id === demoValueId
      );
      if (demoValue) {
        return demoValue.itemName;
      }
    }
    return null;
  }

  isSurveyGroupIdSelected(surveyGroupId: number): boolean {
    return this.mappings.controls.some(
      (mapping) => mapping.get("surveyGroupId")?.value === surveyGroupId
    );
  }

  formatFormData(formValue: any) {
    const filters = [];
    formValue.mappings.forEach((mapping: any) => {
      const surveyGroupId = mapping.surveyGroupId;
      mapping.demoName.forEach((demo: any, demoIndex: number) => {
        const demoValuesArray = mapping.demoValues[demoIndex];
        demoValuesArray.forEach((demoValue: any) => {
          filters.push({
            filterCategory: demo.itemName,
            filterOption: demoValue.itemName,
            surveyGroupId: surveyGroupId,
            demoId: demoValue.demoId,
          });
        });
      });
    });
    return { filters };
  }

  openRoleEditModal(template: TemplateRef<any>, data) {
    this.isEdit = true;
    this.resetFormArray();
    this.userId = data.id;
    this.dropdownSettings = {
      singleSelection: false,
      text: "Select Filters",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      disabled: true,
    };
    const roleData = this.transformDataEdit(data.filter);
    // Set surveyForm values
    this.roleForm.patchValue({
      email: data.email,
      name: data.name,
    });

    this.modalService.show(template, {
      class: "modal-lg",
      backdrop: "static",
      keyboard: false,
    });

    this.populateMappings(roleData);
  }

  resetMapping(index: number) {
    this.dropdownSettings = {
      singleSelection: false,
      text: "Select Filters",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      disabled: false,
    };
    const mappingGroup = this.mappings.at(index) as FormGroup;
    const surveyGroupId = mappingGroup.get("surveyGroupId").value;
    this.onSurveyGroupIdChange(surveyGroupId);
  }

  populateMappings(savedMappings: any) {
    savedMappings.forEach((map) => {
      this.addMappingNew(map);
    });
  }

  transformDataEdit(data: any[]): any[] {
    const transformed = {};

    data.forEach((item) => {
      const { surveyGroupId, filterCategory, filterOption, demoId } = item;

      // Initialize surveyGroupId if not already present in transformed
      if (!transformed[surveyGroupId]) {
        transformed[surveyGroupId] = {
          surveyGroupId,
          demoName: [],
          demoValues: [],
        };
      }

      // Check if filterOption is null
      if (filterOption === null) {
        transformed[surveyGroupId].demoName = [];
        transformed[surveyGroupId].demoValues = [];
        return; // Skip to the next iteration
      }

      const demoNameIndex = transformed[surveyGroupId].demoName.findIndex(
        (demo) => demo.id === demoId
      );

      if (demoNameIndex === -1) {
        transformed[surveyGroupId].demoName.push({
          id: demoId,
          itemName: filterCategory,
        });
        transformed[surveyGroupId].demoValues.push([
          {
            id: filterOption
              ? toCamelCase(filterOption) + `_${this.companyId}`
              : null,
            itemName: filterOption ? filterOption : null,
            demoId,
          },
        ]);
      } else {
        transformed[surveyGroupId].demoValues[demoNameIndex].push({
          id: filterOption
            ? toCamelCase(filterOption) + `_${this.companyId}`
            : null,
          itemName: filterOption ? filterOption : null,
          demoId,
        });
      }
    });

    return Object.values(transformed);
  }

  getDemoValues(demoNameId: string) {
    return this.demoValues[demoNameId] || [];
  }

  onSurveyGroupIdChange(surveyGroupId: any) {
    this.companyService
      .getRoleAccessData(this.companyId, surveyGroupId)
      .subscribe((res: any) => {
        if (res.status) {
          this.demoNames = res.data.demoNames;
          this.demoValues = res.data.demoValues;
        }
      });
    // Perform any additional logic here
  }

  getDemoValuesArray(index: number) {
    return this.mappings.at(index).get("demoValues") as FormArray;
  }
  ngOnDestroy(): void {
    this.editor.destroy();
  }

  getUserBenchmarkMapping(data) {
    this.companyService.getUserBenchmarkMapping(data).subscribe((res: any) => {
      if (res.status) {
        this.benchmarkList = res.data.benchmarkMappingData;
        this.allBenchmarks = res.data.allBenchmarks;
      }
    });
  }
  manageBenchmarkAccessModal(template: TemplateRef<any>, userId: any) {
    this.modalRef = this.modalService.show(template, {
      class: "modal-lg",
      backdrop: "static",
      keyboard: false,
    });

    if (!userId) {
      swal.fire("error", "User is invalid.");
      return;
    }
    this.currentBenchmarkUserId = userId;
    this.getUserBenchmarkMapping({ userId });

    this.companyService.addModal(this.modalRef);
  }

  saveBenchmarkUserMapping() {
    this.companyService
      .saveUserBenchmarkMapping({ benchmarkList: this.benchmarkList })
      .subscribe((res: any) => {
        if (res.status) {
          this.benchmarkList = [];
          this.modalRef.hide();
          this.currentBenchmarkUserId = 0;
        }
        swal.fire(res.status ? "success" : "error", res.message);
      });
  }

  addOtherBenchmark(benchmark: any, value: any) {
    const isChecked = value.checked;
    const index = this.benchmarkList.findIndex(
      (item) => item.benchmark_list_id === benchmark.benchmark_list_id
    );

    if (isChecked && index == -1) {
      this.benchmarkList.push({
        benchmark_list_id: benchmark.benchmark_list_id,
        benchmark_name: benchmark.benchmark_name,
        status: true,
        user_id: this.currentBenchmarkUserId,
      });
    }
    if (!isChecked && index > -1) {
      this.benchmarkList.splice(index, 1);
    }
  }
}
