import { Injectable, OnDestroy } from "@angular/core";
import { io, Socket } from "socket.io-client";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class SocketService implements OnDestroy {
  private socket: Socket;
  private serverUrl: string = environment.apiURl;

  constructor() {
    this.socket = io(this.serverUrl, {
      transports: ["websocket"],
    });

    this.socket.on("connect", () => {
      console.log("Connected to server with socket ID:", this.socket.id);
    });

    this.socket.on("connect_error", (error) => {
      console.error("Connection error:", error);
      this.socket.disconnect();
    });
  }

  on(eventName: string, callback: (data: any) => void): void {
    this.socket.on(eventName, callback);
  }

  emit(eventName: string, data: any): void {
    this.socket.emit(eventName, data);
  }

  disconnect(): void {
    this.socket.disconnect();
    console.log("SocketService destroyed");
  }

  // Lifecycle hook for cleanup
  ngOnDestroy(): void {
    this.disconnect(); // Ensure the socket is disconnected when the service is destroyed
  }
}
