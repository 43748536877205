import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";
// import { ErrorDialogService } from '../app/error-dialog/errordialog.service';
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { AuthService } from "./auth.service";

// import { TokenService } from './admin/lfi-token.service';
import { environment } from "../../src/environments/environment";
import swal from "sweetalert2";
// import Swal from 'sweetalert2';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  LFI_URL = environment.lfiUrl;
  constructor(
    // public errorDialogService: ErrorDialogService,
    public auth: AuthService // private tokenService: TokenService
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let token = `${this.auth.getToken()}`;

    if (request.url.includes(this.LFI_URL)) {
      // const lfiApiToken = this.tokenService.getBearerToken();
      // request = request.clone({
      //   setHeaders: {
      //     Authorization: `Bearer ${lfiApiToken}`,
      //   },
      // });
    } else if (token) {
      request = request.clone({
        headers: request.headers.set("Authorization", "Bearer " + token),
      });
    }

    // if (!request.headers.has('Content-Type')) {
    //   request = request.clone({
    //     headers: request.headers.set('Content-Type', 'application/json'),
    //   });
    // }

    request = request.clone({
      headers: request.headers.set("Accept", "application/json"),
    });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // console.log("event--->>>", event);
        }
        return event;
      }),
      catchError((error: any, caught: Observable<HttpEvent<any>>) => {
        let data = {};
        if (
          error.status == 401 ||
          error.status == 404 ||
          error.status == 400 ||
          error.status == 500 ||
          error.status == 0
        ) {
          // console.log(error,"1");
          data = {
            reason: error && error.error ? error.error : "",
            status: error.status,
          };
          // this.errorDialogService.openDialog(data);
          return throwError(error);
        } else if (error.status == 429) {
          // swal({
          //   title: 'Too many request',
          //   text: error.error,
          //   type: 'error',
          //   showConfirmButton: false,
          //   timer: 2500,
          // });

          // swal({
          //   title: 'Too many request',
          //   text: error.error,
          //   type: 'error',
          //   showConfirmButton: false,
          //   timer: 2500,
          // });

          return throwError(error);
        } else {
          // console.log(error,error.status,"Error");
          return throwError(error);
        }
      })
    );
  }
}
