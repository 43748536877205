<!-- //<app-loader *ngIf="loading"></app-loader> -->
<div class="row">
  <div class="col-md-8">
    <mat-form-field
      style="width: 100%"
      class="search-form-field"
      floatLabel="never"
    >
      <input
        style="border: none; color: #000; font-size: 14px; font-weight: 300"
        matInput
        [(ngModel)]="searchKey"
        placeholder="Search"
        autocomplete="off"
        (keyup)="applyFilter()"
      />
      <button
        mat-button
        matSuffix
        mat-icon-button
        aria-label="Clear"
        *ngIf="searchKey"
        (click)="onSearchClear()"
      >
        <!-- <mat-icon>close</mat-icon> -->
        <i class="fa fa-times"></i>
      </button>
    </mat-form-field>
  </div>
  <div class="col-md-4">
    <div class="uploadBox">
      <label class="custom-file-upload" for="myFileInput">
        <input type="file" id="myFileInput" (change)="onFileChange($event)" />
        <h4><i class="fa fa-upload"></i>Upload Excel +</h4>
        <h5>To Select Managers</h5>
      </label>
      <p class="text-center">
        <a
          href="https://media.greatmanagerinstitute.com/downloads/select-manager.xlsx"
        >
          <small> Download Sample</small>
        </a>
      </p>
    </div>
  </div>
</div>

<mat-table #table [dataSource]="dataSource" matSort>
  <!-- Select all -->
  <ng-container matColumnDef="select">
    <mat-header-cell *matHeaderCellDef>
      <input
        id="checkbox"
        type="checkbox"
        [(ngModel)]="checkall"
        (change)="checkUncheckAll()"
      />
      <label for="checkbox"></label>
    </mat-header-cell>
    <mat-cell *matCellDef="let row">
      <input
        id="checkbox{{ row.id }}"
        type="checkbox"
        value="{{ row.id }}"
        [(ngModel)]="row.checked"
        (change)="updateCheckall(row, $any($event.target).checked)"
      />
      <label for="checkbox{{ row.id }}"></label>
    </mat-cell>
  </ng-container>
  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      Name (<span
        ><i
          class="fa fa-info-circle info"
          aria-hidden="true"
          matTooltip="The number in bracket indicates the number of team members mapped to the manager"
          matTooltipClass="custom-tooltip"
        ></i></span
      >)
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element.name }}
      <span *ngIf="element.participants">
        ({{ element?.participants.length }})</span
      >
    </mat-cell>
  </ng-container>

  <!-- Email Column -->
  <ng-container matColumnDef="email">
    <mat-header-cell *matHeaderCellDef mat-sort-header
      >Email Id</mat-header-cell
    >
    <mat-cell *matCellDef="let element">{{ element.email }}</mat-cell>
  </ng-container>

  <!-- Module Column -->
  <ng-container matColumnDef="moduleCompleted">
    <mat-header-cell *matHeaderCellDef mat-sort-header
      >Module Completed</mat-header-cell
    >
    <mat-cell *matCellDef="let element">{{
      element.modulesCompleted
    }}</mat-cell>
  </ng-container>

  <!-- Batch Link Column -->
  <ng-container matColumnDef="batch">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Batch</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.batchName }} </mat-cell>
  </ng-container>

  <!-- Status column -->
  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
    <mat-cell *matCellDef="let element" style="overflow: unset">
      <ul class="gmi-stepper">
        <li
          [ngClass]="{ active: isRegister[element.id] }"
          class="active ml-5"
          matTooltip=" Registration Status"
        >
          RG
        </li>
        <div
          [ngClass]="{ activeDivider: isRegister[element.id] }"
          class="divider"
        ></div>
        <li
          [ngClass]="{ active: isSas[element.id] }"
          matTooltip="Self Assesment"
          (click)="openSaSModal(sasInfo, element.id)"
        >
          SAS
        </li>
        <div
          [ngClass]="{ activeDivider: isSas[element.id] }"
          class="divider"
        ></div>
        <li
          [ngClass]="{ active: isPackage[element.id] }"
          matTooltip="Package: {{ element.packageName }}"
        >
          PK
        </li>
        <div
          [ngClass]="{ activeDivider: isPackage[element.id] }"
          class="divider"
        ></div>
        <li
          [ngClass]="{ active: isTeamFeedBack[element.id] }"
          matTooltip="Team Feedback Survey"
          (click)="openSurveyModal(SurveyInfo, element.id)"
        >
          TFS
        </li>
        <div
          [ngClass]="{ activeDivider: isTeamFeedBack[element.id] }"
          class="divider"
        ></div>
        <li
          [ngClass]="{ active: isActionPlanning[element.id] == 'green' }"
          [ngStyle]="{
            'background-color':
              isActionPlanning[element.id] == 'yellow' ? 'yellow' : 'whitesmoke'
          }"
          (click)="getActionPlanningForMgr(element.id, actionPlanInfo)"
          matTooltip="Action Planning"
        >
          AP
        </li>
        <div
          [ngClass]="{ activeDivider: isActionPlanning[element.id] }"
          class="divider"
        ></div>
        <li
          [ngClass]="{ active: isCertificate[element.id] }"
          matTooltip="Certificate"
        >
          CF
        </li>
      </ul>
    </mat-cell>
  </ng-container>
  <!-- Package Column -->
  <!-- <ng-container matColumnDef="package">
        <mat-header-cell *matHeaderCellDef mat-sort-header>package</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <p style="margin-bottom:0" *ngIf="element.package == 'Active'">
            {{ element.package }}
          </p>
          <p style="margin-bottom:0" *ngIf="element.package == 'Expire'">
            {{ element.package }}
            <a style="color:red" (click)="openModal1(buyTemplate, element.id)">(Renew)</a>
          </p>
        </mat-cell>
      </ng-container> -->
  <!-- Action Column -->
  <ng-container matColumnDef="action">
    <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <i
        matTooltip="Edit Profile"
        matTooltipPosition="above"
        class="fa fa-edit"
        style="cursor: pointer; margin-left: 15px"
        (click)="editProfile(editProfileTemp, element.id)"
      ></i>
      <i
        matTooltip="Add Participant"
        matTooltipPosition="above"
        class="fa fa-user-plus"
        style="cursor: pointer; margin-left: 10px"
        (click)="addParticipantToManager(participantForm, element.id)"
      ></i>

      <img
        src="../../../../assets/images/Impersonate.png"
        alt="Impersonate"
        matTooltip="Impersonate"
        matTooltipPosition="above"
        class="impersonate-icon"
        (click)="impersonateUser(element.id)"
      />

      <span
        matTooltip="Add Action Plans"
        matTooltipPosition="above"
        style="cursor: pointer; margin-left: 10px"
        (click)="goTocompanyActionPlan(element.id)"
        >Add Action Plan</span
      >
      <input
        class="tgl tgl-ios"
        id="revoke{{ element.id }}"
        name="isRevoked{{ element.id }}"
        (click)="managerRevoke(element.id)"
        type="checkbox"
        [checked]="!revoke[element.id]"
      />
      <label
        style="cursor: pointer; margin-left: 10px"
        matTooltip="Revoke Manager Access"
        matTooltipPosition="above"
        class="tgl-btn"
        for="revoke{{ element.id }}"
      ></label>
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>
<mat-card *ngIf="displayNoRecords" style="padding: 100px">
  <h5 style="text-align: center">
    We couldn't find data for
    <span style="color: red">"{{ dataSource.filter }}"</span>
  </h5>
</mat-card>
<mat-paginator
  [length]="5"
  [pageSize]="5"
  [pageSizeOptions]="[1, 10, 25]"
  showFirstLastButtons
></mat-paginator>
<!--Renew subscriptions Template -->
<ng-template #buyTemplate>
  <div class="modal-header">
    <h4 style="font-size: 22px" class="modal-title pull-left">
      Renew Subscription
    </h4>
    <button
      type="button "
      class="close pull-right"
      aria-label="Close "
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row" style="width: 100%; margin: 0">
      <div class="col-md-12 col-xs-12 col-sm-12" style="padding: 0">
        <div
          class="col-md-3 form-card col-xs-12 col-sm-6"
          *ngFor="let package of packagesFeature"
        >
          <div class="selected" *ngIf="[currentSelected] == package.name">
            <span>Selected</span>
          </div>
          <div
            class="form-holder1 col-md-12"
            style="border: 8px solid white; height: 306px"
          >
            <div class="form-group col-md-12">
              <h4 style="font-weight: 600">{{ package.name }}</h4>
            </div>
            <div class="form-group col-md-12">
              <h3 style="font-weight: 600; margin-top: 8px !important">
                &#8377; {{ package.price }} / Year
              </h3>
              <div
                class="color"
                style="
                  background-color: #245a71;
                  width: 55px;
                  height: 5px;
                  border-radius: 50px;
                "
                *ngIf="package.name == 'Free'"
              ></div>
              <div
                class="color"
                style="
                  background-color: #50e3c2;
                  width: 55px;
                  height: 5px;
                  border-radius: 50px;
                "
                *ngIf="package.name == 'Basic'"
              ></div>
              <div
                class="color"
                style="
                  background-color: #b8e986;
                  width: 55px;
                  height: 5px;
                  border-radius: 50px;
                "
                *ngIf="package.name == 'Premium'"
              ></div>
              <div
                class="color"
                style="
                  background-color: #2ea8de;
                  width: 55px;
                  height: 5px;
                  border-radius: 50px;
                "
                *ngIf="package.name == 'Special'"
              ></div>
            </div>
            <div class="form-group col-md-12">
              <h5 style="font-weight: 200" *ngIf="package.name == 'Free'">
                Features
              </h5>
              <h5 style="font-weight: 200" *ngIf="package.name == 'Basic'">
                Features (Including Free)
              </h5>
              <h5 style="font-weight: 200" *ngIf="package.name == 'Premium'">
                Features (Including Basic)
              </h5>
              <h5 style="font-weight: 200" *ngIf="package.name == 'Special'">
                Features (Including Premium)
              </h5>
            </div>
            <div class="form-group col-md-12" style="padding-left: 8%">
              <ul style="padding-left: 8px">
                <li
                  *ngFor="let feature of package.features; let i = index"
                  style="padding-bottom: 3%"
                >
                  {{ feature.value }}
                </li>
              </ul>
            </div>
            <button
              type="submit"
              class="btn btn-primary"
              (click)="subscribe(package.name, package.price)"
              style="
                margin-left: -8px;
                bottom: 25px;
                position: absolute;
                left: 65px;
              "
            >
              Subscribe
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- Edit Profile -->
<ng-template #editProfileTemp>
  <div class="modal-header" *ngIf="!smallLoading">
    <h4
      style="font-size: 22px; text-transform: capitalize"
      class="modal-title pull-left"
    >
      Edit {{ managerProfile.personalInfo.name }} Profile
    </h4>
    <button
      type="button "
      class="close pull-right"
      aria-label="Close "
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <form (ngSubmit)="onSubmit2(f, managerProfile._id)" #f="ngForm">
          <div class="form-card">
            <div class="form-holder col-md-12">
              <div class="form-group col-md-6 mb-10">
                <label for="name">Name <span class="asterick">*</span> </label>
                <input
                  type="text"
                  class="form-control custom-form-control"
                  placeholder="Enter your name"
                  name="name"
                  [(ngModel)]="managerProfile.personalInfo.name"
                  required
                  #name="ngModel"
                />
                <p class="help-block" *ngIf="!name.valid && name.touched">
                  Please Enter Your Name
                </p>
              </div>
              <div class="form-group col-md-6 mb-10">
                <label for="name"
                  >Email Id <span class="asterick">*</span>
                </label>
                <input
                  type="email"
                  class="form-control custom-form-control"
                  placeholder="Enter your email-id"
                  name="emailId"
                  [(ngModel)]="managerProfile.personalInfo.email"
                  email
                  required
                  #email="ngModel"
                  (change)="checkEmail()"
                />
                <p
                  class="help-block"
                  *ngIf="!email.valid && email.touched && !emailExists"
                >
                  Please Enter Valid Email-Id
                </p>
                <p *ngIf="emailExists">Email Address already present</p>
              </div>
              <!-- <input type="hidden" 
                        class="form-control custom-form-control"
                        placeholder="Enter your name"
                        name="checkmail"
                        ngModel
                        required
                        #checkmail="ngModel"> -->
              <div
                *ngIf="managerProfile.personalInfo.age"
                class="form-group col-md-6 mb-10"
              >
                <label for="name">Age <span class="asterick">*</span> </label>
                <select
                  [ngClass]="{ defaultFontSize: age1 !== '' }"
                  class="form-control custom-form-control"
                  name="age"
                  [(ngModel)]="managerProfile.personalInfo.age"
                  required
                  #age="ngModel"
                >
                  <option value="" disabled selected hidden>
                    Select your Age
                  </option>
                  <option value="25 years or younger">
                    25 years or younger
                  </option>
                  <option value="26 years to 34 years">
                    26 years to 34 years
                  </option>
                  <option value="35 years  to 44 years">
                    35 years to 44 years
                  </option>
                  <option value="45 years to 54 years">
                    45 years to 54 years
                  </option>
                  <option value="55 years or older">55 years or older</option>
                </select>
                <p class="help-block" *ngIf="!age.valid && age.touched">
                  Please Select Your Age
                </p>
              </div>
              <div
                *ngIf="managerProfile.personalInfo.gender"
                class="form-group col-md-6 mb-10"
              >
                <label for="name"
                  >Gender <span class="asterick">*</span>
                </label>
                <select
                  [ngClass]="{ defaultFontSize: gender1 !== '' }"
                  class="form-control custom-form-control"
                  name="gender"
                  [(ngModel)]="managerProfile.personalInfo.gender"
                  required
                  #gender="ngModel"
                >
                  <option value="" disabled selected hidden>
                    Select your gender
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="others">Others</option>
                  <option value="Prefer not to disclose">
                    Prefer not to disclose
                  </option>
                </select>
                <p class="help-block" *ngIf="!gender.valid && gender.touched">
                  Please Select Your gender
                </p>
              </div>
              <div
                *ngIf="managerProfile.personalInfo.teamSize"
                class="form-group col-md-6 mb-10"
              >
                <label for="name"
                  >Team Size <span class="asterick">*</span>
                </label>
                <input
                  type="number"
                  class="form-control custom-form-control"
                  name="teamSize"
                  placeholder="Enter your team size in numbers"
                  min="0"
                  [(ngModel)]="managerProfile.personalInfo.teamSize"
                  required
                  #teamSize="ngModel"
                />
                <p
                  class="help-block"
                  *ngIf="!teamSize.valid && teamSize.touched"
                >
                  Please Enter Your Team Size
                </p>
              </div>
              <div class="form-group col-md-6 mb-10">
                <label for="name"
                  >Mobile Number <span class="asterick">*</span>
                </label>
                <input
                  type="number"
                  class="form-control custom-form-control"
                  name="mobile"
                  placeholder="Enter your mobile number"
                  min="0"
                  [(ngModel)]="managerProfile.personalInfo.mobile"
                  required
                  #mobile="ngModel"
                />
                <p class="help-block" *ngIf="!mobile.valid && mobile.touched">
                  Please Enter Mobile Number
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-12 ptb-15">
            <input
              type="submit"
              class="form-control custom-form-control"
              style="
                background-color: #00526f;
                color: white;
                border-radius: 5px;
              "
              [disabled]="!f.valid || emailExists"
              value="Update Profile"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
<!-- Add/Edit Participant form -->
<ng-template #participantForm>
  <div class="modal-header" *ngIf="!smallLoading">
    <h4
      style="font-size: 22px; text-transform: capitalize"
      class="modal-title pull-left"
    >
      View/Edit Team Members
    </h4>
    <button
      type="button "
      class="close pull-right"
      aria-label="Close "
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row" style="margin-left: 2px; width: 100%">
      <div class="col-md-12">
        <p class="title">
          Enter email address of new employee to participate in the survey
        </p>
        <form class="" (ngSubmit)="onSubmit1(f)" #f="ngForm">
          <div class="row">
            <div class="form-group col-md-4">
              <label for="">Name</label>
              <br />
              <input
                type="text"
                class="form-control custom-form-control"
                placeholder="Enter your name"
                name="name"
                [(ngModel)]="participant.name"
                required
              />
              <p
                style="font-size: 10px; color: red"
                *ngIf="f.submitted && f.controls['name'].invalid"
              >
                Please Enter Your Name
              </p>
            </div>

            <div class="form-group col-md-4">
              <label for="">Email Id</label>
              <br />
              <input
                type="email"
                class="form-control custom-form-control"
                placeholder="Enter your email-id"
                name="emailId"
                [(ngModel)]="participant.emailId"
                required
              />
              <p
                style="font-size: 10px; color: red"
                *ngIf="f.submitted && f.controls['emailId'].invalid"
              >
                Please Enter Valid Email-Id
              </p>
            </div>

            <div class="form-group col-md-4">
              <label for="respondentType">Respondent type</label>
              <select
                class="form-control custom-form-control"
                name="respondentType"
                [(ngModel)]="participant.respondentType"
                required
              >
                <option value="" disabled selected>
                  Select respondent type
                </option>
                <option value="Peer">Peer</option>
                <option value="Reporting Manager">Reporting Manager</option>
                <option value="Senior">Senior</option>
                <option value="Team Member">Team Member</option>
              </select>
            </div>
          </div>

          <div class="col-md-12">
            <div #formSection>
              <h2 style="margin: 0">Add Demographics</h2>
              <!-- <p style="color: red">
                Disclaimer: Demographics can only be added when adding Team
                Members, not during editing.
              </p> -->
            </div>
            <div class="row">
              <div class="col-md-6">
                <!-- Step 1: Dropdown for selecting existing demographics -->
                <div class="form-group col-md-12">
                  <label for="demographicNameSelect">Select Demographic</label>
                  <select
                    class="form-control custom-form-control"
                    id="demographicNameSelect"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="selectedDemographic"
                    (change)="onDemographicSelect()"
                  >
                    <option value="" selected>Select a Demographic</option>
                    <option
                      *ngFor="let demographic of defaultDemographics"
                      [ngValue]="demographic.company_demographics_name"
                    >
                      {{
                        demographic.company_demographics_name
                          | textDemographicNameFormat
                      }}
                    </option>
                  </select>
                </div>

                <!-- Step 2: Show options for the selected demographic -->
                <div
                  class="form-group col-md-12"
                  *ngIf="
                    selectedDemographic &&
                    (currentDemographicOptions.length > 0 || isDemoUpdate)
                  "
                >
                  <label for="demographicOptionSelect">Select Option</label>
                  <select
                    *ngIf="currentDemographicOptions.length > 0"
                    class="form-control custom-form-control"
                    id="demographicOptionSelect"
                    [(ngModel)]="selectedOption"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option value="" selected>Select an Option</option>
                    <option *ngFor="let option of currentDemographicOptions">
                      {{ option }}
                    </option>
                  </select>

                  <input
                    *ngIf="currentDemographicOptions.length == 0"
                    type="text"
                    class="form-control custom-form-control"
                    placeholder="Enter Demographic Option Name"
                    [(ngModel)]="selectedOption"
                  />
                </div>

                <!-- Step 3: Button to add or update selected demographic and options -->
                <div class="form-group col-md-12 text-center mb-10">
                  <button
                    type="button"
                    class="gmi-primary-btn example-header"
                    (click)="
                      !isDemoUpdate ? addDemographic() : updateDemographic()
                    "
                  >
                    {{ !isDemoUpdate ? "Add" : "Update" }}
                  </button>
                </div>

                <!-- Step 4: Button to add new custom demographic and options -->
                <div class="form-group col-md-12 text-center mb-10">
                  <button
                    type="button"
                    class="gmi-secondary-btn"
                    (click)="
                      showCustomDemographicForm = !showCustomDemographicForm
                    "
                  >
                    Add New Demographic
                  </button>
                </div>

                <!-- Form for adding a new custom demographic and its options -->
                <div *ngIf="showCustomDemographicForm || isCustomDemoEdit">
                  <div class="form-group mb-10">
                    <!-- <input
                      type="text"
                      class="form-control custom-form-control"
                      placeholder="Enter Demographic Name"
                      [(ngModel)]="newDemographicName"
                      [ngModelOptions]="{ standalone: true }"
                    /> -->
                    <input
                      type="text"
                      class="form-control custom-form-control"
                      placeholder="Enter Demographic Name"
                      [(ngModel)]="newDemographicName"
                      appNoSpaces
                      [ngModelOptions]="{ standalone: true }"
                      #demographicNameInput="ngModel"
                      maxlength="50"
                      required
                      [matAutocomplete]="auto"
                      (input)="filterDemographics()"
                    />
                    <mat-autocomplete
                      #auto="matAutocomplete"
                      [displayWith]="displayFn"
                    >
                      <mat-option
                        *ngFor="let option of filteredDemographics"
                        [value]="option"
                      >
                        {{ option }}
                      </mat-option>
                    </mat-autocomplete>
                  </div>
                  <div class="form-group mb-10">
                    <input
                      type="text"
                      class="form-control custom-form-control"
                      placeholder="Enter Option Name"
                      appNoSpaces
                      [(ngModel)]="newDemographicOption"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </div>
                  <div class="form-group col-md-12 text-center mb-10">
                    <button
                      type="button"
                      class="btn btn-success"
                      (click)="
                        !isCustomDemoEdit
                          ? addCustomDemographic()
                          : updateCustomDemographic()
                      "
                    >
                      {{
                        !isCustomDemoEdit
                          ? "Save Demographic"
                          : "Update Demographic"
                      }}
                    </button>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div *ngIf="alreadyAddedDemographics.length > 0">
                  <h3 style="margin: 0">Already uploaded demographics:</h3>
                  <div
                    class=""
                    style="
                      display: flex;
                      justify-content: flex-start;
                      gap: 5px;
                      align-items: center;
                      flex-wrap: wrap;
                      border: 1px solid black;
                      padding: 10px;
                      border-radius: 5px;
                      margin-bottom: 10px;
                    "
                  >
                    <small
                      style="
                        margin: 0;
                        border: 1px solid #f82937cc;
                        border-radius: 5px;
                        padding: 2px 10px 2px 10px;
                        font-weight: bolder;
                        background: #faeaebcc;
                      "
                      *ngFor="let demographic of alreadyAddedDemographics"
                    >
                      {{ demographic }}
                    </small>
                  </div>
                </div>
              </div>
            </div>

            <!-- List of selected demographics -->
            <div
              class="col-md-12 mb-10"
              *ngIf="currentParticipantDemographics.length > 0"
            >
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th class="bold-text-v2">Demographics Name</th>
                    <th class="bold-text-v2">Option Name</th>
                    <th class="bold-text-v2">Edit</th>
                    <th class="bold-text-v2">Remove</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let demographic of currentParticipantDemographics;
                      let i = index
                    "
                  >
                    <td>
                      {{
                        demographic.company_demographics_name
                          | textDemographicNameFormat
                      }}
                    </td>
                    <td>{{ demographic.value }}</td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-warning"
                        (click)="patchValues(demographic, i)"
                      >
                        <i class="fa fa-pencil" aria-hidden="true"></i>
                      </button>
                    </td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-danger"
                        (click)="removeDemographic(i)"
                      >
                        <i class="fa fa-trash" aria-hidden="true"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div style="margin: 1%; text-align: center">
            <button
              style="width: 30%"
              type="submit"
              class="btn btn-gmi-primary form-control"
              [disabled]="!f.valid"
            >
              {{ !isUpdate ? "Add participant" : "Update participant" }}
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <span *ngIf="sameEmail" style="padding: 0px 16px; color: red"
        >Email already exists !</span
      >
      <span *ngIf="emailManager" style="padding: 0px 16px; color: red"
        >Manager cannot add himself as survey participant</span
      >
      <div class="col-md-12">
        <div class="contact">
          <div
            class="col-md-6"
            *ngFor="let respondent of participants; let i = index"
          >
            <div class="add-user">
              <p class="respondent boldText">
                Name: {{ respondent.name }}
                <a
                  class="update"
                  (click)="patchParticipantValues(respondent, i)"
                  ><i class="fa fa-pencil" aria-hidden="true"></i
                ></a>
              </p>
              <p class="email boldText">
                Email: {{ respondent.emailId }}
                <a
                  class="delete"
                  (click)="deleteParticipantToManager(respondent)"
                  ><i class="fa fa-trash-o" aria-hidden="true"></i
                ></a>
              </p>
              <small class="email boldText">
                Respondent Type: {{ respondent.respondentType }}
              </small>
              <br />
              <br />
              <div
                class="col-md-12 mb-10"
                *ngIf="respondent?.demographics?.length > 0"
              >
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th class="bold-text-v2">Demographics Name</th>
                      <th class="bold-text-v2">Option Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let demographic of respondent.demographics;
                        let i = index
                      "
                    >
                      <td>
                        {{
                          demographic.company_demographics_name
                            | textDemographicNameFormat
                        }}
                      </td>
                      <td>
                        {{ demographic.value }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- <div *ngIf="editShow[respondent.emailId]" class="edit-user">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter your name"
                  name="name"
                  value="{{ name1 }}"
                  required
                  (change)="changeName($event)"
                />
                <p style="color: red" *ngIf="nameError">
                  Name field is required
                </p>
              </div>
              <div class="form-group">
                <input
                  type="email"
                  class="form-control"
                  placeholder="Enter your email-id"
                  name="emailId"
                  value="{{ email1 }}"
                  (change)="changeEmail($event)"
                />
                <p style="color: red" *ngIf="emailError">
                  Email field is required
                </p>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="modal-area">
        <span *ngIf="threeRespondentSelect">
          <p style="padding: 0px 16px; color: red">
            Add at least 3 people to launch your survey
          </p>
        </span>

        <button
          style="margin-top: 1%"
          type="button"
          class="gmi-primary-btn"
          [disabled]="
            threeRespondentSelect ||
            emailError ||
            nameError ||
            sameEmail ||
            emailManager
          "
          (click)="addParticipantsToManager()"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #actionPlanInfo>
  <div class="modal-header">
    <h4 style="font-size: 22px" class="modal-title pull-left">
      Action Plan Information
    </h4>
    <button
      type="button "
      class="close pull-right"
      aria-label="Close "
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="actionPlanningData.length != 0; else noActionData">
      <table style="margin-top: 0" class="table table-striped">
        <thead>
          <tr>
            <th>Sl. No</th>
            <th>Action Planning Name</th>
            <!-- <th>Start Date</th>
            <th>End Date</th> -->
            <th>Theme</th>
            <th>Duration</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody *ngFor="let action of actionPlanningData; index as i">
          <tr>
            <td>{{ i + 1 }}</td>
            <td>{{ action.actionName }}</td>
            <!-- <td>{{ action.startDate }}</td>
            <td>{{ action.endDate }}</td> -->
            <td>{{ action.theme }}</td>
            <td>{{ action.duration }}</td>
            <td>{{ action.description }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>

<ng-template #noActionData>
  <p>No Action Planning Data!</p>
</ng-template>

<ng-template #SurveyInfo>
  <div class="modal-header">
    <h4 style="font-size: 22px" class="modal-title pull-left">
      Survey Information
    </h4>
    <button
      type="button "
      class="close pull-right"
      aria-label="Close "
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="surveysData.length != 0; else noSurveyData">
      <table style="margin-top: 0" class="table table-striped">
        <thead>
          <tr>
            <th>Sl. No</th>
            <th>Survey Name</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Overall Score</th>
            <th>Response Rate</th>
            <th>Participants</th>
          </tr>
        </thead>
        <tbody *ngFor="let survey of surveysData; index as i">
          <tr>
            <td>{{ i + 1 }}</td>
            <td>{{ survey.name }}</td>
            <td>{{ survey.startDate }}</td>
            <td>{{ survey.endDate }}</td>
            <td>
              {{
                survey.surveyScore.overall
                  ? survey.surveyScore.overall + "%"
                  : "not calculated"
              }}
            </td>
            <td>{{ surveyResponseRate[survey._id] }}%</td>
            <td>
              <ul>
                <li
                  style="list-style: none"
                  *ngFor="let participant of survey.participants"
                >
                  {{ participant.emailId }}
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>
<ng-template #noSurveyData>
  <p>No Survey Data!</p>
</ng-template>

<ng-template #sasInfo>
  <div class="modal-header">
    <h4 style="font-size: 22px" class="modal-title pull-left">
      SelfAssesment Information
    </h4>
    <button
      type="button "
      class="close pull-right"
      aria-label="Close "
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="sasData.length != 0; else noSurveyData">
      <table style="margin-top: 0" class="table table-striped">
        <thead>
          <tr>
            <th>Sl. No</th>
            <th>Survey Name</th>
            <th>Overall Score</th>
            <th>Connect Score</th>
            <th>Develop Score</th>
            <th>Inspire Score</th>
          </tr>
        </thead>
        <tbody *ngFor="let survey of sasData; index as i">
          <tr>
            <td>{{ i + 1 }}</td>
            <td>{{ survey.details.surveyName }}</td>
            <td>{{ survey.details.surveyScore }}</td>
            <td>{{ survey.details.cScore }}</td>
            <td>{{ survey.details.dScore }}</td>
            <td>{{ survey.details.iScore }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>
