<div class="main">
  <h3 class="text-center">Upload Action Plans</h3>
  <div class="sub-main">
    <div class="row">
      <div class="col-md-2">
        <div class="file-upload">
          <h5>Upload Excel File</h5>
          <label class="custom-file-upload" for="myFileInput">
            <input
              type="file"
              id="myFileInput"
              (change)="onFileChange($event)"
            />
            Choose File +
          </label>
          <br />

          <a href="{{ samplePdfUpload }}"> Download Sample </a>
        </div>
      </div>
      <div class="col-md-10">
        <div class="mat-elevation-z4 matTable">
          <div class="form-group pb-10 searchManager">
            <input
              class="form-control custom-form-control"
              required
              id="subtheme"
              name="subtheme"
              type="text"
              placeholder="Search manager name..."
              (keyup)="searchManager($any($event.target).value)"
            />
          </div>
          <mat-table [dataSource]="managerList" class="matTableMain w-100">
            <!-- Manager Name Column -->
            <ng-container matColumnDef="managerName">
              <mat-header-cell *matHeaderCellDef>
                Manager Name
              </mat-header-cell>
              <mat-cell class="text-center" *matCellDef="let element">
                {{ element.managerName }}
              </mat-cell>
            </ng-container>

            <!-- Manager Email ID Column -->
            <ng-container matColumnDef="managerEmailId">
              <mat-header-cell *matHeaderCellDef>
                Manager Email ID
              </mat-header-cell>
              <mat-cell class="text-center" *matCellDef="let element">
                {{ element.managerEmailId }}
              </mat-cell>
            </ng-container>

            <!-- Action Plan Name Column -->
            <ng-container matColumnDef="actionPlanName">
              <mat-header-cell *matHeaderCellDef>
                Action Plan Name
              </mat-header-cell>
              <mat-cell class="text-center" *matCellDef="let element">
                {{ element.actionName }}
              </mat-cell>
            </ng-container>

            <!-- Theme Column -->
            <ng-container matColumnDef="theme">
              <mat-header-cell *matHeaderCellDef> Practice </mat-header-cell>
              <mat-cell class="text-center" *matCellDef="let element">
                {{ element.theme }}
              </mat-cell>
            </ng-container>

            <!-- subPracticeArea Column -->
            <ng-container matColumnDef="subPracticeArea">
              <mat-header-cell *matHeaderCellDef>
                Sub-Practice Area
              </mat-header-cell>
              <mat-cell class="text-center" *matCellDef="let element">
                {{ element.subtheme }}
              </mat-cell>
            </ng-container>

            <!-- goal Column -->
            <ng-container matColumnDef="goal">
              <mat-header-cell *matHeaderCellDef> Goal </mat-header-cell>
              <mat-cell class="text-center" *matCellDef="let element">
                {{ element.goal }}
              </mat-cell>
            </ng-container>

            <!-- description Column -->
            <ng-container matColumnDef="description">
              <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
              <mat-cell class="text-center" *matCellDef="let element">
                {{ element.description }}
              </mat-cell>
            </ng-container>

            <!-- duration -->
            <ng-container matColumnDef="duration">
              <mat-header-cell *matHeaderCellDef> Duration </mat-header-cell>
              <mat-cell class="text-center" *matCellDef="let element">
                {{ element.duration }}
              </mat-cell>
            </ng-container>

            <!-- implementation -->
            <ng-container matColumnDef="implementation">
              <mat-header-cell *matHeaderCellDef>
                Implementation
              </mat-header-cell>
              <mat-cell class="text-center" *matCellDef="let element">
                {{ element.implementation }}
              </mat-cell>
            </ng-container>

            <!-- type -->
            <ng-container matColumnDef="type">
              <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
              <mat-cell class="text-center" *matCellDef="let element">
                {{ element.type }}
              </mat-cell>
            </ng-container>

            <!-- memorable -->
            <ng-container matColumnDef="memorable">
              <mat-header-cell *matHeaderCellDef> Memorable </mat-header-cell>
              <mat-cell class="text-center" *matCellDef="let element">
                {{ element.memorable }}
              </mat-cell>
            </ng-container>

            <!-- implTeamBehaviour -->
            <ng-container matColumnDef="implTeamBehaviour">
              <mat-header-cell *matHeaderCellDef>
                Impl Team Behaviour
              </mat-header-cell>
              <mat-cell class="text-center" *matCellDef="let element">
                {{ element.implTeamBehaviour }}
              </mat-cell>
            </ng-container>

            <!-- implBusinessPerformance -->
            <ng-container matColumnDef="implBusinessPerformance">
              <mat-header-cell *matHeaderCellDef>
                Impl Business Performance
              </mat-header-cell>
              <mat-cell class="text-center" *matCellDef="let element">
                {{ element.implBusinessPerformance }}
              </mat-cell>
            </ng-container>

            <!-- isPresentForCommunity -->
            <ng-container matColumnDef="isPresentForCommunity">
              <mat-header-cell *matHeaderCellDef>
                isPresentForCommunity
              </mat-header-cell>
              <mat-cell class="text-center" *matCellDef="let element">
                {{ element.isPresentForCommunity }}
              </mat-cell>
            </ng-container>

            <!-- status -->
            <ng-container matColumnDef="status">
              <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
              <mat-cell class="text-center" *matCellDef="let element">
                {{ element.status }}
              </mat-cell>
            </ng-container>

            <!-- addedBy -->
            <ng-container matColumnDef="addedBy">
              <mat-header-cell *matHeaderCellDef> Added By </mat-header-cell>
              <mat-cell class="text-center" *matCellDef="let element">
                {{ element.addedBy }}
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          </mat-table>
          <mat-paginator [pageSizeOptions]="[10, 20, 30]"></mat-paginator>
        </div>
      </div>
    </div>
    <div class="text-center mt-10">
      <button
        type="button"
        style="margin-top: 25px; color: white"
        class="btn btn-secondary btn-newuser-clr"
        [disabled]="managerList.data.length == 0"
        (click)="submitActionPlans()"
      >
        Submit
      </button>
    </div>
  </div>
</div>
