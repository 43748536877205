import { environment } from "../../environments/environment";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";

// import 'rxjs/add/operator/map';
import { map } from "rxjs";

@Injectable()
export class SurveyEngineService {
  public serverUrl = environment.apiURl;

  public managerApiUrl = "/api/v2/surveyEngineRequest";

  constructor(
    private http: HttpClient,
    private router: Router // private httpp: Http
  ) {}

  roleGuard() {
    if (localStorage.getItem("role") != "Manager") {
      return false;
    } else {
      return true;
    }
  }

  getAllCompanies() {
    return this.http
      .get(this.serverUrl + this.managerApiUrl + "/allCompanies/")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getSingleCompany(id) {
    return this.http
      .post(this.serverUrl + this.managerApiUrl + "/singleCompany/", {
        companyId: id,
      })
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getSubthemes(data) {
    return this.http
      .post(this.serverUrl + this.managerApiUrl + "/getSubthemes", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
}
