<!-- //<app-loader *ngIf="loading"></app-loader> -->

<div class="example-header">
  <mat-form-field
    style="width: 50%"
    class="search-form-field"
    floatLabel="never"
  >
    <input
      style="border: none; color: #000; font-size: 14px; font-weight: 300"
      matInput
      [(ngModel)]="searchKey"
      placeholder="Search"
      autocomplete="off"
      (keyup)="applyFilter()"
    />
    <button
      mat-button
      matSuffix
      mat-icon-button
      aria-label="Clear"
      *ngIf="searchKey"
      (click)="onSearchClear()"
    >
      <!-- <mat-icon>close</mat-icon> -->
      <i class="fa fa-times"></i>
    </button>
  </mat-form-field>
</div>
<mat-table [dataSource]="dataSource" matSort>
  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element.name }}
    </mat-cell>
  </ng-container>

  <!-- Email Column -->
  <ng-container matColumnDef="email">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      Email Id</mat-header-cell
    >
    <mat-cell *matCellDef="let element">
      {{ element.email }}
    </mat-cell>
  </ng-container>

  <!-- Profile Link Column -->
  <ng-container matColumnDef="profileLink">
    <mat-header-cell *matHeaderCellDef mat-sort-header
      >Profile Link</mat-header-cell
    >
    <mat-cell *matCellDef="let element">
      <span class="ml-5"></span>
      <i
        class="fa fa-clone"
        (click)="copyToClipboard(element.id)"
        aria-hidden="true"
        style="cursor: pointer"
      ></i>

      <input
        class="hideInputCopy"
        type="text"
        value="{{ serverUrl }}/manager/digital-profile/{{
          element.profileLink
        }}"
        id="copy{{ element.id }}"
      />
    </mat-cell>
  </ng-container>

  <!-- Package Column -->
  <!-- <ng-container matColumnDef="package">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >package</mat-header-cell
        >
        <mat-cell *matCellDef="let element">
          <p style="margin-bottom:0" *ngIf="element.package == 'Active'">
            {{ element.package }}
          </p>
          <p style="margin-bottom:0" *ngIf="element.package == 'Expire'">
            {{ element.package }}
            <a
              style="color:red"
              (click)="openModal1(buyTemplate, element.id)"
              >(Renew)</a
            >
          </p>
        </mat-cell>
      </ng-container> -->
  <!-- Status column -->
  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
    <mat-cell *matCellDef="let element" style="overflow: unset">
      <ul class="gmi-stepper">
        <li
          [ngClass]="{ active: isRegister[element.id] }"
          class="active"
          matTooltip="Registeration Status"
        >
          RG
        </li>
        <div
          [ngClass]="{ activeDivider: isRegister[element.id] }"
          class="divider activeDivider"
        ></div>
        <li
          [ngClass]="{ active: isSas[element.id] }"
          matTooltip="Self Assesment"
        >
          SAS
        </li>
        <div
          [ngClass]="{ activeDivider: isSas[element.id] }"
          class="divider activeDivider"
        ></div>
        <li [ngClass]="{ active: isPackage[element.id] }" matTooltip="Package">
          PK
        </li>
        <div
          [ngClass]="{ activeDivider: isPackage[element.id] }"
          class="divider"
        ></div>
        <li
          [ngClass]="{ active: isTeamFeedBack[element.id] }"
          matTooltip="Team Feedback Survey"
        >
          TFS
        </li>
        <div
          [ngClass]="{ activeDivider: isTeamFeedBack[element.id] }"
          class="divider"
        ></div>
        <li
          [ngClass]="{ active: isActionPlanning[element.id] }"
          matTooltip="Action Planning"
        >
          AP
        </li>
        <div
          [ngClass]="{ activeDivider: isActionPlanning[element.id] }"
          class="divider"
        ></div>
        <li
          [ngClass]="{ active: isCertificate[element.id] }"
          matTooltip="Certificate"
        >
          CF
        </li>
      </ul>
    </mat-cell>
  </ng-container>
  <!-- Action Column -->
  <ng-container matColumnDef="action">
    <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <button
        class="btn btn-primary"
        [disabled]="text[element.id] == 'Pending'"
        (click)="askForMergingData(element.id)"
      >
        {{ text[element.id] }}
      </button>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>
<mat-card *ngIf="displayNoRecords" style="padding: 100px">
  <h5 style="text-align: center">
    We couldn't find data for
    <span style="color: red">"{{ dataSource.filter }}"</span>
  </h5>
</mat-card>
<mat-paginator
  showFirstLastButtons
  [length]="5"
  [pageSize]="5"
  [pageSizeOptions]="[1, 10, 25]"
>
</mat-paginator>
<ng-template #buyTemplate>
  <div class="modal-header">
    <h4 style="font-size: 22px" class="modal-title pull-left">
      Renew Subscription
    </h4>
    <button
      type="button "
      class="close pull-right"
      aria-label="Close "
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row" style="width: 100%; margin: 0">
      <div class="col-md-12 col-xs-12 col-sm-12" style="padding: 0">
        <div
          class="col-md-3 form-card col-xs-12 col-sm-6"
          *ngFor="let package of packagesFeature"
        >
          <div class="selected" *ngIf="[currentSelected] == package.name">
            <span>Selected</span>
          </div>
          <div
            class="form-holder1 col-md-12"
            style="border: 8px solid white; height: 306px"
          >
            <div class="form-group col-md-12">
              <h4 style="font-weight: 600">{{ package.name }}</h4>
            </div>
            <div class="form-group col-md-12">
              <h3 style="font-weight: 600; margin-top: 8px !important">
                &#8377; {{ package.price }} / Year
              </h3>
              <div
                class="color"
                style="
                  background-color: #245a71;
                  width: 55px;
                  height: 5px;
                  border-radius: 50px;
                "
                *ngIf="package.name == 'Free'"
              ></div>
              <div
                class="color"
                style="
                  background-color: #50e3c2;
                  width: 55px;
                  height: 5px;
                  border-radius: 50px;
                "
                *ngIf="package.name == 'Basic'"
              ></div>
              <div
                class="color"
                style="
                  background-color: #b8e986;
                  width: 55px;
                  height: 5px;
                  border-radius: 50px;
                "
                *ngIf="package.name == 'Premium'"
              ></div>
              <div
                class="color"
                style="
                  background-color: #2ea8de;
                  width: 55px;
                  height: 5px;
                  border-radius: 50px;
                "
                *ngIf="package.name == 'Special'"
              ></div>
            </div>
            <div class="form-group col-md-12">
              <h5 style="font-weight: 200" *ngIf="package.name == 'Free'">
                Features
              </h5>
              <h5 style="font-weight: 200" *ngIf="package.name == 'Basic'">
                Features (Including Free)
              </h5>
              <h5 style="font-weight: 200" *ngIf="package.name == 'Premium'">
                Features (Including Basic)
              </h5>
              <h5 style="font-weight: 200" *ngIf="package.name == 'Special'">
                Features (Including Premium)
              </h5>
            </div>
            <div class="form-group col-md-12" style="padding-left: 8%">
              <ul style="padding-left: 8px">
                <li
                  *ngFor="let feature of package.features; let i = index"
                  style="padding-bottom: 3%"
                >
                  {{ feature.value }}
                </li>
              </ul>
            </div>
            <button
              type="submit"
              class="btn btn-primary"
              (click)="subscribe(package.name, package.price)"
              style="
                margin-left: -8px;
                bottom: 25px;
                position: absolute;
                left: 65px;
              "
            >
              Subscribe
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
