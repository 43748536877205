<div>
  <div
    class="col-md-12 form-card bg-white"
    style="
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    "
  >
    <div class="flex justify-between">
      <h3 class="p-4">Research Question</h3>
      <div class="p-4">
        <button class="btn btn-newuser-clr" (click)="addQuestion(dateTemplate)">
          Add Question
        </button>
      </div>
    </div>

    <div class="p-4 bg-white">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <ng-container matColumnDef="Question Text">
          <th mat-header-cell *matHeaderCellDef>Question Text</th>
          <td mat-cell *matCellDef="let element" class="w-300">
            {{ element.questionText | titlecase }}
          </td>
        </ng-container>

        <ng-container matColumnDef="Launch Type">
          <th mat-header-cell *matHeaderCellDef>Launch Type</th>
          <td mat-cell *matCellDef="let element">
            {{ element.launchType | uppercase }}
          </td>
        </ng-container>

        <ng-container matColumnDef="Question Type">
          <th mat-header-cell *matHeaderCellDef>Question Type</th>
          <td mat-cell *matCellDef="let element">
            {{ element.questionType | titlecase }}
          </td>
        </ng-container>

        <ng-container matColumnDef="Start Date">
          <th mat-header-cell *matHeaderCellDef>Start Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.startDate | date : "dd/MM/yyyy" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="End Date">
          <th mat-header-cell *matHeaderCellDef>End Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.endDate | date : "dd/MM/yyyy" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="isActive">
          <th mat-header-cell *matHeaderCellDef>isActive</th>
          <td mat-cell *matCellDef="let element">
            <mat-slide-toggle
              [checked]="element.isActive"
              (change)="changeActiveQuestion(element._id, $event, editDate)"
              >{{ element.isActive ? "Active" : "Inactive" }}</mat-slide-toggle
            >
          </td>
        </ng-container>

        <ng-container matColumnDef="Edit">
          <th mat-header-cell *matHeaderCellDef>Edit</th>
          <td mat-cell *matCellDef="let element">
            <button
              mat-icon-button
              matTooltip="Click to Edit"
              class="iconbutton"
              color="primary"
              (click)="getResearchQuestionById(element._id, dateTemplate)"
            >
              <!-- <mat-icon>edit</mat-icon> -->
              <i class="fa fa-edit"></i>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="Delete">
          <th mat-header-cell *matHeaderCellDef>Delete</th>
          <td mat-cell *matCellDef="let element">
            <button
              mat-icon-button
              matTooltip="Click to Delete"
              class="iconbutton"
              (click)="deleteResearchQuestion(element._id)"
              color="warn"
            >
              <!-- <mat-icon>delete</mat-icon> -->
              <i class="fa fa-trash"></i>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="Download">
          <th mat-header-cell *matHeaderCellDef>Download</th>
          <td mat-cell *matCellDef="let element">
            <button
              type="button"
              class="btn btn-newuser-clr"
              (click)="downloadReport(element._id)"
            >
              Download
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator
        [pageSizeOptions]="pageSizeOption"
        showFirstLastButtons
        aria-label="Select page of researchquestion"
      >
      </mat-paginator>
    </div>
  </div>

  <!-- Modal start -->
  <ng-template #dateTemplate>
    <div class="modal-header">
      <h4 class="modal-title pull-left">
        {{ isEditQuestion ? "Edit" : "Add" }} Questions
      </h4>
      <button
        type="button"
        class="btn-close close pull-right"
        aria-label="Close"
        (click)="modalRef?.hide()"
      >
        <span aria-hidden="true" class="visually-hidden">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="questionForm">
        <div class="flex flex-col justify-center">
          <mat-form-field appearance="fill" class="px-4 pb-2 w-100">
            <mat-label>Question Text</mat-label>
            <input formControlName="questionText" matInput />
            <mat-error
              *ngIf="questionForm.controls['questionText']?.errors?.['required']"
              >Question Text is required</mat-error
            >
            <mat-error
              *ngIf="questionForm.controls['questionText']?.errors?.['minlength']"
              >Question Text should be minimum 3 characters</mat-error
            >
            <mat-error
              *ngIf="questionForm.controls['questionText']?.errors?.['maxlength']"
              >Question Text should not be more than 150 characters</mat-error
            >
          </mat-form-field>

          <mat-form-field appearance="fill" class="px-4 pb-2 w-100">
            <mat-label>Launch Type</mat-label>
            <mat-select formControlName="launchType">
              <mat-option
                *ngFor="let type of launchType"
                value="{{ type.value }}"
                >{{ type.label }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="questionForm.controls['launchType']?.errors?.['required']"
              >Launch Type is required</mat-error
            >
          </mat-form-field>

          <div class="date">
            <mat-form-field appearance="fill">
              <mat-label>Choose a Start Date</mat-label>
              <input
                formControlName="startDate"
                matInput
                [min]="minDate"
                [matDatepicker]="picker1"
              />
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle
                matIconSuffix
                [for]="picker1"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Choose a End Date</mat-label>
              <input
                formControlName="endDate"
                matInput
                [min]="minDate"
                [matDatepicker]="picker2"
              />
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle
                matIconSuffix
                [for]="picker2"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </div>

          <mat-form-field appearance="fill" class="px-4 pb-2 w-100">
            <mat-label>Question Type</mat-label>
            <mat-select
              formControlName="questionType"
              (selectionChange)="checkquestionType($event)"
            >
              <mat-option
                *ngFor="let type of researchQuestionType"
                value="{{ type.value }}"
                >{{ type.label }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="questionForm.controls['questionType']?.errors?.['required']"
              >Question Type is required</mat-error
            >
          </mat-form-field>
        </div>
        <div *ngIf="isRating && !isEditSelect" class="m-4">
          <section class="example-section">
            <mat-checkbox
              #c
              class="example-margin"
              (click)="changeDefaultRating(c.checked)"
              >Default Team feedback answers</mat-checkbox
            >
          </section>
        </div>

        <!-- answer section  -->
        <div
          class="px-4 pb-2"
          *ngIf="
            isSelect ||
            isEditSelect ||
            (isRating && !isDefaultRatingResponse) ||
            isEditRating
          "
        >
          <div
            formArrayName="selectAnswer"
            *ngFor="let answer of selectAnswerArray().controls; let i = index"
          >
            <div class="flex">
              <ng-container [formGroupName]="i">
                <mat-form-field appearance="fill" class="pb-2 w-100">
                  <input formControlName="choice" matInput />
                  <mat-error
                    *ngIf="
                      questionForm.controls['selectAnswer']?.['controls'][i]?.get(
                        'choice'
                      ).errors?.required
                    "
                    >Answer Text is required</mat-error
                  >
                  <mat-error
                    *ngIf="
                      questionForm.controls['selectAnswer']?.['controls'][i]?.get(
                        'choice'
                      ).errors?.minlength
                    "
                    >Answer Text should be atleast 2 characters</mat-error
                  >
                  <mat-error
                    *ngIf="
                      questionForm.controls['selectAnswer']?.['controls'][i]?.get(
                        'choice'
                      ).errors?.maxlength
                    "
                    >Answer Text should not be mpre than 20
                    characters</mat-error
                  >
                </mat-form-field>
              </ng-container>
              <button class="btn-delete" (click)="deleteSelection(i)">
                Delete
              </button>
            </div>
          </div>
          <button
            class="btn btn-newuser-clr"
            (click)="addToSelect()"
            *ngIf="!isRating && !isEditRating"
          >
            Add Choices
          </button>
        </div>

        <!--  Rating section -->
        <!-- <div class="px-4 pb-2" *ngIf="isRating || isEditRating">
          <div
            formArrayName="ratingAnswer"
            *ngFor="let answer of ratingAnswerArray().controls; let i = index"
          >
            <p>Select Rating Start</p>
            <div class="flex" [formGroupName]="i">
              <div class="flex">
                <ng-container>
                  <mat-form-field appearance="fill" class="pb-2 px-4 w-100">
                    <mat-label>Add Rating Start</mat-label>
                    <input
                      formControlName="ratingStart"
                      matInput
                      type="number"
                    />
                  </mat-form-field>
                </ng-container>
              </div>
              <div class="flex">
                <ng-container>
                  <mat-form-field appearance="fill" class="pb-2 px-4 w-100">
                    <mat-label>Add Rating End</mat-label>
                    <input formControlName="ratingEnd" matInput type="number" />
                  </mat-form-field>
                </ng-container>
              </div>
            </div>
          </div>
        </div> -->
      </form>

      <!-- If already active question exists -->
      <div *ngIf="activeQuestion.length > 0 && !isEditQuestion">
        <div>Question already active</div>
        <div class="alert alert-danger flex justify-between" role="alert">
          {{ activeQuestion[0].questionText }}
          <mat-slide-toggle
            [checked]="activeQuestion[0].isActive"
            (change)="onActiveChange($event)"
            >{{ isActiveChecked ? "Active" : "Inactive" }}</mat-slide-toggle
          >
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        class="btn btn-newuser-clr"
        (click)="submitForm()"
        [disabled]="!questionForm.valid && !isEditQuestion"
      >
        {{ isEditQuestion ? "Edit" : "Submit" }}
      </button>
    </div>
  </ng-template>

  <ng-template #editDate let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Update Question</h4>
    </div>
    <div class="modal-body">
      <form [formGroup]="endDateForm">
        <div class="date">
          <mat-form-field appearance="fill">
            <mat-label>Choose a Start Date</mat-label>
            <input
              formControlName="startDate"
              matInput
              [min]="minDate"
              [matDatepicker]="picker1"
            />
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker1"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Choose a End Date</mat-label>
            <input
              formControlName="endDate"
              matInput
              [min]="minDate"
              [matDatepicker]="picker2"
            />
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker2"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>
      </form>
      <!-- If already active question exists -->
      <div *ngIf="activeQuestion.length > 0 && !isEditQuestion">
        <div>Question already active</div>
        <div class="alert alert-danger flex justify-between" role="alert">
          {{ activeQuestion[0].questionText }}
          <mat-slide-toggle
            [checked]="activeQuestion[0].isActive"
            (change)="onActiveChange($event)"
            >{{ isActiveChecked ? "Active" : "Inactive" }}</mat-slide-toggle
          >
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-dark"
        [disabled]="!endDateForm.valid"
        (click)="updateQuestionEndDate()"
      >
        Update
      </button>
    </div>
  </ng-template>
</div>
