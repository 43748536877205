import { Component, OnInit, ViewChild } from '@angular/core';
import { CompanyServices } from '../company.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import swal from 'sweetalert2';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
// import { Editor } from '@ckeditor/ckeditor5-angular';

// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-supervisor',
  templateUrl: './supervisor.component.html',
  styleUrls: ['./supervisor.component.css'],
})
export class SupervisorComponent implements OnInit {
  public Editor = ClassicEditor;
  // public Editor = Editor;
  message: String = '';
  subject: String = '';
  messageError: String = '';
  subjectError: String = '';
  describe: any;
  checkall: any;
  count = 0;
  text: String = 'Select All';
  selectedManagers: any = [];
  @ViewChild('editor') editorComponent: typeof ClassicEditor;
  superVisorLists: Array<any> = [];

  constructor(private companyService: CompanyServices) {}

  ngOnInit() {
    this.getSupervisorLists();
  }
  getSupervisorLists() {
    this.companyService.getSupervisorlists().subscribe((res: any) => {
      if (res.status) {
        this.superVisorLists = res.data;
        console.log(this.superVisorLists, 'supervisor lists');
      }
    });
  }

  isValid() {
    let isValid = true;
    if (this.subject == '') {
      this.subjectError = 'Subject is required!';
    }
    if (this.message == '') {
      this.messageError = 'Message field is required!';
    }

    if (this.subjectError != '' || this.messageError != '') {
      isValid = false;
    }

    return isValid;
  }

  handleSubjectChange(event: any) {
    console.log(event.target.value);
    if (event.target.value.trim() !== '') {
      this.subject = event.target.value;
      this.subjectError = '';
    }
  }

  handleEditorChange({ editor }: any) {
    const data = editor.getData();
    this.message = data;
    this.messageError = '';
  }

  onSubmitClick() {
    console.log(this.isValid(), 'dkk');
    if (!this.isValid() || this.selectedManagers.length <= 0) {
      return false;
    } else {
      return this.sendMail();
    }
  }

  checkUncheckAll() {
    if (this.checkall) {
      this.checkall = true;
      this.text = 'Unselect All';
    } else {
      this.checkall = false;
      this.text = 'Select All';
    }
    this.superVisorLists.forEach((participant) => {
      participant.checked = this.checkall;
      this.onChange(participant, participant.checked);
    });
  }

  updateCheckall(participant: any, isChecked: boolean) {
    var userTotal = this.superVisorLists.length;
    var count = 0;
    if (isChecked) {
      this.count++;
      if (!this.selectedManagers.includes(participant)) {
        this.selectedManagers.push(participant);
      }
    } else {
      this.count--;
      let index = this.selectedManagers.indexOf(participant);
      this.selectedManagers.splice(index, 1);
    }
    if (userTotal == this.count) {
      this.checkall = true;
      this.text = 'Unselect All';
    } else {
      this.checkall = false;
      this.text = 'Select All';
    }
  }

  onChange(participant: any, isChecked: boolean) {
    if (isChecked) {
      this.count++;
      if (!this.selectedManagers.includes(participant)) {
        this.selectedManagers.push(participant);
      }
    } else {
      this.count--;
      let index = this.selectedManagers.indexOf(participant);
      this.selectedManagers.splice(index, 1);
    }
  }

  sendMail() {
    let superVisorLists: any = [];
    console.log(this.selectedManagers, 'dkp');
    this.selectedManagers.forEach((man: any) => {
      man.managers.forEach((manager: any) => {
        superVisorLists.push({
          managerEmail: manager.email,
          email: man._id,
          name: man.name,
        });
      });
    });
    let data = {
      superVisorLists: superVisorLists,
      message: this.message,
      subject: this.subject,
    };
    console.log(data, 'dr');
    this.companyService.sendMailsToSuperVisor(data).subscribe((res: any) => {
      if (res.status) {
        swal.fire('Emails', res.message, 'success');
      }
    });
  }
}
