<div class="container-fluid" style="padding-top: 3%">
  <!-- //<app-loader *ngIf="loading"></app-loader> -->
  <div
    class="col-md-12 form-card"
    style="
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    "
  >
    <div class="row">
      <div class="col-md-12">
        <div class="row" style="padding: 2%">
          <form [formGroup]="couponForm" (ngSubmit)="onAddPackage(couponForm)">
            <div class="col-md-4">
              <mat-form-field class="example-full-width">
                <mat-label>Discount(%)</mat-label>
                <input
                  matInput
                  formControlName="discountPercentage"
                  name="discount"
                />
              </mat-form-field>

              <!-- <p class="warning" *ngIf = "discount != ''">{{discountPercentageStatus}}</p>  -->
            </div>
            <div class="col-md-4">
              <mat-form-field>
                <mat-label>Select Package</mat-label>
                <mat-select formControlName="package" name="package">
                  <mat-option value="Profiling" selected>Profiling</mat-option>
                  <mat-option value="Basic">E-Learning </mat-option>
                  <mat-option value="Premium">Certification</mat-option>
                  <mat-option value="Special">Special</mat-option>
                  <mat-option value="Global">For All Package</mat-option>
                  <mat-option value="Free">Only Community</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4" style="padding-top: 1%">
              <button
                type="submit"
                class="btn btn-primary"
                [disabled]="couponForm.invalid"
              >
                Create coupon
              </button>
            </div>
          </form>
        </div>
        <div
          class="col-md-12 col-xs-6 col-md-6 subadmin-title"
          style="padding: 0px !important"
        >
          <div class="col-md-7" style="padding-top: 2%; padding-bottom: 2%">
            <input
              type="text"
              (keyup)="applyFilter($event.target['value'])"
              class="form-control search-icon"
              placeholder="Enter your search..."
              (keyup)="applyFilter($event.target['value'])"
            />
            <div class="clearfix"></div>
          </div>
        </div>
        <div class="users-table">
          <div class="subadmin-table">
            <div class="example-container container mar-tp tab-size">
              <mat-table [dataSource]="dataSource" matSort>
                <!-- code Column -->
                <ng-container matColumnDef="code">
                  <mat-header-cell *matHeaderCellDef mat-sort-header
                    >Coupon Code</mat-header-cell
                  >
                  <mat-cell *matCellDef="let element">{{
                    element.code
                  }}</mat-cell>
                </ng-container>
                <!-- company Column -->
                <ng-container matColumnDef="company">
                  <mat-header-cell *matHeaderCellDef mat-sort-header
                    >Company</mat-header-cell
                  >
                  <mat-cell *matCellDef="let element">{{
                    element.company != "" ? element.company : "No Company"
                  }}</mat-cell>
                </ng-container>
                <!-- feature Column -->
                <ng-container matColumnDef="feature">
                  <mat-header-cell *matHeaderCellDef mat-sort-header
                    >Assigned Feature</mat-header-cell
                  >
                  <mat-cell *matCellDef="let element">
                    {{ element.feature }}</mat-cell
                  >
                </ng-container>
                <!-- feature Column -->
                <ng-container matColumnDef="discount">
                  <mat-header-cell *matHeaderCellDef mat-sort-header
                    >Discount</mat-header-cell
                  >
                  <mat-cell *matCellDef="let element">
                    {{ element.discount }}%</mat-cell
                  >
                </ng-container>
                <ng-container matColumnDef="userCount">
                  <mat-header-cell *matHeaderCellDef mat-sort-header
                    >Used count</mat-header-cell
                  >
                  <mat-cell *matCellDef="let element">
                    Used by {{ element.userCount }} managers</mat-cell
                  >
                </ng-container>
                <ng-container matColumnDef="couponStatus">
                  <mat-header-cell *matHeaderCellDef mat-sort-header
                    >Coupon status</mat-header-cell
                  >
                  <mat-cell *matCellDef="let element">
                    {{
                      element.couponStatus == true ? "Active" : "Inactive"
                    }}</mat-cell
                  >
                </ng-container>

                <!-- feature Column -->
                <ng-container matColumnDef="action">
                  <mat-header-cell *matHeaderCellDef mat-sort-header
                    >Action</mat-header-cell
                  >
                  <mat-cell *matCellDef="let element">
                    <input
                      *ngIf="element.multipleTimeUse == true"
                      class="tgl tgl-ios"
                      id="{{ element.code }}"
                      (click)="
                        changeGlobalCouponStatus(
                          element.code,
                          element.couponStatus
                        )
                      "
                      name="status"
                      type="checkbox"
                      [checked]="element.couponStatus"
                    />
                    <label
                      *ngIf="element.multipleTimeUse == true"
                      class="tgl-btn"
                      for="{{ element.code }}"
                    ></label>
                    <span *ngIf="element.multipleTimeUse == false"
                      >"Not allowed"</span
                    >
                  </mat-cell>
                </ng-container>
                <mat-header-row
                  *matHeaderRowDef="displayedColumns"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></mat-row>
              </mat-table>
              <mat-paginator
                [length]="10"
                [pageSize]="10"
                (page)="pageChange($event)"
              >
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-12 col-xs-6 col-md-6 subadmin-title" style="padding: 0px !important;">
              <div class="col-md-7" style="padding-top: 2%;padding-bottom: 2%;">
                  <input type="text" (keyup)="applyFilter($event.target['value'])" class="form-control search-icon"
                      placeholder="Enter your search..." (keyup)="applyFilter($event.target['value'])">
                  <div class="clearfix"></div>
              </div>
          </div> -->
    </div>
  </div>
</div>
