import { Component, OnInit, TemplateRef } from "@angular/core";
import { CompanyServices } from "../company.service";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { BsModalService } from "ngx-bootstrap/modal";
import swal from "sweetalert2";
import io from "socket.io-client";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-reset-ap-ld",
  templateUrl: "./reset-ap-ld.component.html",
  styleUrls: ["./reset-ap-ld.component.css"],
})
export class ResetApLdComponent implements OnInit {
  selectedManagers: any[] = [];
  modalRef: BsModalRef;
  private serverUrl = environment.apiURl;
  private socket: any = io(this.serverUrl, { reconnection: true });
  totalCount: number = 0;
  count: number = 0;
  completedCount: number = 0;
  updateStatus: boolean = false;
  ap: boolean = false;
  ld: boolean = false;
  loading: boolean = false;
  statusText: string = "";

  constructor(
    private companyService: CompanyServices,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.getSelectedManagers();
    // this.getAssignedStatus();
  }

  getSelectedManagers() {
    this.companyService.selectedmanagersForPackages.subscribe((response) => {
      this.selectedManagers = response;
    });
  }

  openSendMailModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      class: "my-modal",
      backdrop: "static",
      keyboard: false,
    });
  }

  resetAPLD(template) {
    const data = {
      emails: this.selectedManagers.map((manager) => manager.email),
      ap: this.ap,
      ld: this.ld,
    };
    if (!this.ap && !this.ld) {
      swal.fire({
        title: "Check at least one checkbox for reset",
        text: "Check at least one checkbox for reset",
        icon: "error",
        showConfirmButton: false,
        timer: 2500,
      });
      return;
    }
    this.companyService.resetAPLD(data).subscribe((response) => {
      swal.fire({
        title: "Reset AP/L&D Status",
        text: response.message,
        icon: response.status ? "success" : "error",
        showConfirmButton: false,
        timer: 2500,
      });
      response.status && this.modalService._hideModal(1);
    });
    this.loading = false;
    // this.modalService.show(template, {
    //   backdrop: "static",
    //   keyboard: false,
    // });
  }
}
