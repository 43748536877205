import { Component, OnInit } from "@angular/core";
import { CompanyServices } from "../company.service";
import swal from "sweetalert2";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { dateFormat, isEmpty } from "../../../config/utils";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
@Component({
  selector: "app-batches-dashboard",
  templateUrl: "./batches-dashboard.component.html",
  styleUrls: ["./batches-dashboard.component.css"],
})
export class BatchesDashboardComponent implements OnInit {
  batchListData: any = [];
  displayedColumns: string[] = ["name", "email", "status"];
  batchData = [];
  loading = false;
  activeTabId = "";
  modalRef!: BsModalRef;
  actionPlanningData: any = [];
  dataSource = new MatTableDataSource();
  uploadedManagerList: any = [];
  isSas: any = {};
  isPackage: any = {};
  isRegister: any = {};
  isCertificate: any = {};
  isTeamFeedBack: any = {};
  isActionPlanning: any = {};
  surveyResponseRate: any = {};
  sasData: any = [];
  surveysData: any = [];
  searchKey!: string;
  displayNoRecords = false;
  constructor(
    private companyService: CompanyServices,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.getBuList();
  }

  getBuList() {
    this.loading = true;
    this.companyService.getBatchDetails().subscribe((res) => {
      this.loading = false;
      if (res.status) {
        this.batchListData = res.data;
        this.loadBatchData(this.batchListData[0]?.id);
        this.activeTabId = this.batchListData[0]?.id;
      } else {
        swal.fire({
          title: "Batch Dashboard",
          text: res.message,
          icon: "error",
          showConfirmButton: false,
          timer: 25000,
        });
      }
    });
  }
  openSurveyModal(modal: any, id: any) {
    this.modalRef = this.modalService.show(
      modal,
      Object.assign({}, { class: "my-modal" })
    );
    this.companyService.addModal(this.modalRef);
    this.companyService.getManagerSurvey(id).subscribe((res) => {
      if (res.status) {
        res.data.forEach((data: any) => {
          data.startDate = dateFormat(data.startDate, "dd-mm-yyyy");
          data.endDate = dateFormat(data.endDate, "dd-mm-yyyy");
          let surveySubmittedRes = data.participants.filter(
            (part: any) => part.surveySubmissionStatus == true
          );
          this.surveyResponseRate[data._id] =
            (surveySubmittedRes.length / data.participants.length) * 100;
          if (isEmpty(data.surveyScore)) {
            data.surveyScore = "Not Calculated";
          }
        });
        this.surveysData = res.data;
      }
    });
  }
  loadBatchData(id: any) {
    this.uploadedManagerList = [];
    this.activeTabId = id;
    this.loading = true;
    this.companyService.loadBatchData({ id: id }).subscribe((response) => {
      this.loading = false;
      if (response.status) {
        response.data.forEach((manager: any) => {
          if (manager.personalInfo.isVerified) {
            this.isRegister[manager._id] = true;
          } else {
            this.isRegister[manager._id] = false;
          }
          if (manager.package_info.features.length > 0) {
            this.isPackage[manager._id] = true;
          } else {
            this.isPackage[manager._id] = false;
          }
          if (manager.personalInfo.view !== "sas") {
            this.isSas[manager._id] = true;
          } else {
            this.isSas[manager._id] = false;
          }
          if (manager.managerInfo.badge.length > 0) {
            this.isCertificate[manager._id] = true;
          } else {
            this.isCertificate[manager._id] = false;
          }
          if (manager.surveys.length > 0) {
            this.isTeamFeedBack[manager._id] = true;
          } else {
            this.isTeamFeedBack[manager._id] = false;
          }
          if (manager.actionPlannings.length > 0) {
            this.isActionPlanning[manager._id] = true;
          } else {
            this.isActionPlanning[manager._id] = false;
          }
          if (manager.package_info.features.length > 0) {
            if (new Date(manager.package_info.endDate) > new Date(Date.now())) {
              manager.packageStatus = "Active";
            } else {
              manager.packageStatus = "Expire";
            }
          }
          if (manager.personalInfo.isRegisteredByCompany) {
            let managerInfo: any = {
              name: manager.personalInfo.name,
              email: manager.personalInfo.email,
              profileLink: manager.personalInfo.userName,
              package: manager.packageStatus,
              packageName: manager.package_info.packageName,
              id: manager._id,
              participants: manager.participants,
            };
            this.uploadedManagerList.push(managerInfo);
          }
        });
        this.dataSource.data = [...this.uploadedManagerList];
      }
    });
  }
  getActionPlanningForMgr(id: any, template: any) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: "my-modal" })
    );
    this.companyService.addModal(this.modalRef);
    this.companyService.getManagerActionPlanning(id).subscribe((res) => {
      if (res.status) {
        res.data.forEach((data: any) => {
          data.startDate = dateFormat(data.startDate, "dd-mm-yyyy");
          data.endDate = dateFormat(data.endDate, "dd-mm-yyyy");
        });
        this.actionPlanningData = res.data;
      }
    });
  }
  openSaSModal(modal: any, id: any) {
    this.modalRef = this.modalService.show(
      modal,
      Object.assign({}, { class: "my-modal" })
    );
    this.companyService.addModal(this.modalRef);
    this.companyService.getSasInfo(id).subscribe((res) => {
      if (res.status) {
        res.data.forEach((data1: any) => {
          data1.details.surveyScore = data1.details.surveyScore
            ? `${parseInt(data1.details.surveyScore).toFixed(0)}%`
            : "Not submitted";
          data1.details.cScore = data1.details.cScore
            ? `${parseInt(data1.details.cScore).toFixed(0)}%`
            : "Not submitted";
          data1.details.iScore = data1.details.iScore
            ? `${parseInt(data1.details.iScore).toFixed(0)}%`
            : "Not submitted";
          data1.details.dScore = data1.details.dScore
            ? `${parseInt(data1.details.dScore).toFixed(0)}%`
            : "Not submitted";
        });
        this.sasData = res.data;
      }
    });
  }

  applyFilter() {
    this.dataSource.filter = this.searchKey.trim().toLowerCase();
    if (this.dataSource.filteredData.length == 0) {
      this.displayNoRecords = true;
    } else {
      this.displayNoRecords = false;
    }
  }

  onSearchClear() {
    this.searchKey = "";
    this.applyFilter();
  }
}
