import { Component, OnInit, ViewChild } from "@angular/core";
import { CompanyServices } from "../company.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
// import * as jwt_decode from 'jwt-decode';
import { jwtDecode } from "jwt-decode";
import { ActivatedRoute, Router } from "@angular/router";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Validators, Editor, Toolbar } from "ngx-editor";
import swal from "sweetalert2";
import { leaderboardMailTypes } from "src/app/constants";
const commentsObject: any = {
  commentOne:
    " seems to need your support in their learning journey. Schedule a 1-1 connect with them to give them your feedback and inputs.",
  commentTwo:
    " is doing a good job in their learning journey. Acknowledge their effort and continue supporting them.",
  commentThree:
    " is doing really well in their learning journey. Appreciate their efforts and encourage them to continue the same.",
};

const LEADERBOARD_REPORT_TABLE_HEADERS = [
  "Participant Name",
  "Participant Email Id",
  "No of Action Plans",
  // "No of red plans",
  "L&D Modules",
  "Implementation Updates",
  "No of Community Post",
  "Comment",
];

@Component({
  selector: "app-leaderboard-report",
  templateUrl: "./leaderboard-report.component.html",
  styleUrls: ["./leaderboard-report.component.css"],
})
export class LeaderboardReportComponent implements OnInit {
  editor: Editor;
  sendGmiSpoc: boolean = true;
  toolbar: Toolbar = [
    ["bold", "italic"],
    ["underline", "strike"],
    ["code", "blockquote"],
    ["ordered_list", "bullet_list"],
    [{ heading: ["h1", "h2", "h3", "h4", "h5", "h6"] }],
    ["link", "image"],
    ["text_color", "background_color"],
    ["align_left", "align_center", "align_right", "align_justify"],
  ];
  modalRef: BsModalRef;
  selectedManagers: any;
  closeModal() {
    throw new Error("Method not implemented.");
  }
  batchOptions: any = [];
  configAccess = {
    displayKey: "name",
    search: true,
    height: "auto",
    placeholder: "Select batch",
    // customComparator: () => {},
    customComparator: function (a: any, b: any): number {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    },
    limitTo: this.batchOptions.length,
    moreText: "more",
    noResultsFound: "No batch found!",
    searchPlaceholder: "Search",
    searchOnKey: "name",
  };
  commentsObject = commentsObject;
  selectedBatchOptions: any = [];
  scheduledData: any = [];
  scheduleFor: string = "";
  seCompanyId!: number;
  companySurveyId!: number;
  displayedColumns: string[] = [
    "Schedule For",
    "Comments",
    "Selected Batches",
    "Selected Report Table Headers",
    "Is GMI Spoc included in CC",
    "Actions",
  ];
  companyScheduleData: any = [];
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",
    placeholder: "Enter text here...",
    translate: "no",
    defaultParagraphSeparator: "p",
    defaultFontName: "Arial",
    toolbarHiddenButtons: [["bold"]],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
  };
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: false,
    height: "auto",
    minHeight: "400px",
    maxHeight: "auto",
    width: "auto",
    translate: "true",
    enableToolbar: true,
    showToolbar: true,
    defaultParagraphSeparator: "",
    defaultFontName: "",
    defaultFontSize: "",
    fonts: [
      { class: "arial", name: "Arial" },
      { class: "times-new-roman", name: "Times New Roman" },
      { class: "calibri", name: "Calibri" },
      { class: "comic-sans-ms", name: "Comic Sans MS" },
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
    uploadUrl: "v1/image",
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: "top",
    // toolbarHiddenButtons: [["insertImage", "insertVideo"]],
  };

  //new try

  editorConfig1: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "auto",
    minHeight: "0",
    maxHeight: "auto",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "Enter text here...",
    defaultParagraphSeparator: "",
    defaultFontName: "",
    defaultFontSize: "",
    fonts: [
      { class: "arial", name: "Arial" },
      { class: "times-new-roman", name: "Times New Roman" },
      { class: "calibri", name: "Calibri" },
      { class: "comic-sans-ms", name: "Comic Sans MS" },
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
    uploadUrl: "v1/image",
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: "top",
    toolbarHiddenButtons: [
      ["bold", "italic"],
      ["fontSize"],
      ["insertImage", "insertVideo"],
    ],
  };
  emailData: any = {
    subject: "",
    content: "",
  };
  reportTableHeaders = LEADERBOARD_REPORT_TABLE_HEADERS;
  selectedReportTableHeaders: any = [];
  scheduledEmails = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  tableColumns: string[] = [
    "serialNo",
    "name",
    "createdAt",
    "scheduleFor",
    "sentStatus",
    "cc",
    "actions",
  ];
  scheduledForDataObject = {
    id: "",
    scheduleFor: "",
    active: true,
  };
  index!: number;

  constructor(
    private companyService: CompanyServices,
    private router: Router,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private modalService: BsModalService
  ) {
    if (!this.route.snapshot.params["id"]) {
      const navigation = this.router.getCurrentNavigation();
      this.selectedManagers = navigation?.extras.state["managers"];
    }
  }

  ngOnInit() {
    this.editor = new Editor();
    this.companySurveyId = this.route.snapshot.params["id"];
    this.companyService.batchOptions.subscribe((batch) => {
      this.batchOptions = batch;
    });

    if (localStorage.getItem("token") !== null) {
      let token: any = localStorage.getItem("token");
      let tokenData: any = jwtDecode(token);
      this.seCompanyId = tokenData.companyId;
    }

    this.getEmailContent();
    this.getCompanyScheduleEmails();
  }

  getEmailContent() {
    this.companyService
      .getEmailTemplates({
        emailType: "leaderboard_report_to_reporting_manager",
      })
      .subscribe((res: any) => {
        if (res.status && res.data) {
          this.emailData.content = res.data.content;
          this.emailData.subject = res.data.subject;
          this.addDefaultHeaders();
        }
      });
  }

  selectBatchName(event: any, index: any) {
    const batchValue = event.target.value;
    const duplicateIndex = this.selectedBatchOptions.findIndex(
      (item: any) => item === batchValue
    );
    if (event.target.checked && duplicateIndex == -1) {
      this.selectedBatchOptions.push(batchValue);
    } else {
      this.selectedBatchOptions.splice(index, 1);
    }
  }

  selectHeaders(event: any, index: any) {
    const header = event.target.value;
    const duplicateIndex = this.selectedReportTableHeaders.findIndex(
      (item: any) => item === header
    );
    if (event.target.checked && duplicateIndex == -1) {
      this.selectedReportTableHeaders.push(header);
    } else {
      this.selectedReportTableHeaders.splice(index, 1);
    }
  }

  addScheduleData() {
    const object = {
      scheduleFor: this.scheduleFor,
      comments: this.commentsObject,
      batches: this.selectedBatchOptions,
      tableHeaders: this.selectedReportTableHeaders,
      emailData: this.emailData,
      includeGmiSpoc: this.sendGmiSpoc,
    };
    this.scheduledData.push(object);
    this.selectedBatchOptions = [];
    this.selectedReportTableHeaders = [];
    this.scheduleFor = "";
  }

  deleteScheduleData(index: number) {
    this.scheduledData.splice(index, 1);
  }

  submitScheduledData() {
    this.companyService
      .addCompanyScheduleEmail({
        companySurveyId: this.companySurveyId ? this.companySurveyId : "",
        seCompanyId: this.seCompanyId,
        scheduledData: this.scheduledData,
        selectedManagers: this.companySurveyId ? [] : this.selectedManagers,
      })
      .subscribe((response: any) => {
        this.openSnackBar(response.message, response.status);
        if (response.status) {
          this.scheduledData = [];
          this.getEmailContent();
          this.getCompanyScheduleEmails();
        }
      });
  }

  addDefaultHeaders() {
    for (let header of LEADERBOARD_REPORT_TABLE_HEADERS) {
      this.selectedReportTableHeaders.push(header);
    }
  }

  openSnackBar(message: any, status: any) {
    this._snackBar.open(message, "", {
      duration: 2000,
      panelClass: status ? ["green-snackbar"] : ["red-snackbar"],
    });
  }

  getCompanyScheduleEmails() {
    this.companyService
      .getCompanyScheduleEmails({
        seCompanyId: this.seCompanyId,
        emailType: this.companySurveyId
          ? leaderboardMailTypes.with_survey_id
          : leaderboardMailTypes.without_survey_id,
        companySurveyId: this.companySurveyId ? this.companySurveyId : "",
      })
      .subscribe((response: any) => {
        if (response.status) {
          this.scheduledEmails.data = response.data;
          this.scheduledEmails.paginator = this.paginator;
        }
      });
  }

  editScheduleFor(element: any, template: any) {
    this.scheduledForDataObject.id = element._id;
    this.scheduledForDataObject.scheduleFor = element.scheduleFor;
    const config = {
      initialState: {},
      animated: true,
      class: "modal-md",
    };
    this.modalRef = this.modalService.show(template, config);
    this.companyService.addModal(this.modalRef);
  }

  deleteEmail(element: any) {
    const data = {
      id: element._id,
      updateObject: {
        active: false,
      },
    };
    swal
      .fire({
        title: "Are You Sure You Want To delete this schedule email?",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
      })
      .then((result: any) => {
        if (result.value) {
          this.onSubmit(data);
        }
      });
  }

  onSubmit(data: any) {
    const updateData =
      Object.keys(data).length > 0
        ? data
        : {
            id: this.scheduledForDataObject.id,
            updateObject: {
              scheduleFor: this.scheduledForDataObject.scheduleFor,
            },
          };
    this.companyService
      .updateCompanyScheduleEmail(updateData)
      .subscribe((response: any) => {
        this.openSnackBar(response.message, response.status);
        response.status && this.getCompanyScheduleEmails();
        this.modalService._hideModal(1);
      });
  }
  onEditorValueChange(value) {
    // Handle the value change here
    this.emailData.content = value;
  }
  ngOnDestroy(): void {
    // this.editor.destroy();
  }
  onContentChange(content: string) {
    // console.log("Content changed:", content);
  }
}
