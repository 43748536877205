import { Component, OnInit, ViewChild } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { dateFormat } from "src/config/utils";
import { MatPaginator } from "@angular/material/paginator";
import Swal from "sweetalert2";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AdminServices } from "../../admin.service";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { BsModalService } from "ngx-bootstrap/modal";
@Component({
  selector: "app-r2-extension",
  templateUrl: "./r2-extension.component.html",
  styleUrls: ["./r2-extension.component.css"],
})
export class R2ExtensionComponent implements OnInit {
  years = [2019, 2020, 2021, 2022, 2023, 2024];
  selectedYears: number[] = [];
  selectedStatuses: string = "";
  surveyStatus = ["Ongoing", "Ended"];
  managerFinalList: MatTableDataSource<any> = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  r2EmailId: any;
  managerData: any;
  managerList: any;
  currentDate = new Date();
  b2cColumn: string[] = [
    "mgrName",
    "mgrEmail",
    "year",
    "startDate",
    "endDate",
    "action",
  ];

  loading: boolean;
  modalRef: BsModalRef;
  pageSize = 7;
  pageSizeOptions: number[] = [7, 14, 28];

  updateEndDate: Date;
  minDate: Date;

  managerSurvey: any;
  searchTerm: any;
  filteredManagerList: any;
  constructor(
    private adminServices: AdminServices,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.getB2CR2List();
  }

  getB2CR2List() {
    this.managerData = this.managerList = [];
    this.adminServices
      .getR2B2CManagerList(this.selectedYears)
      .subscribe((res) => {
        if (res.status) {
          this.managerData = res.data;
          this.managerList = this.managerData.data;
          if (this.selectedStatuses.length != 0) {
            this.managerList = this.managerList.filter((item) => {
              if (
                this.selectedStatuses.includes("Ended") &&
                item.endDate < this.currentDate.toISOString()
              ) {
                return item;
              }
              if (
                this.selectedStatuses.includes("Ongoing") &&
                item.endDate >= this.currentDate.toISOString()
              ) {
                return item;
              }
            });
          }

          this.managerFinalList.paginator = this.paginator;
          this.managerFinalList.data = this.managerList;
        }
      });
  }

  updateSurvey(managerEmail,year) {
    this.r2EmailId = managerEmail;
    let data = {
      endDate: this.updateEndDate,
      r2EmailId: this.r2EmailId,
      year:year
    };

    this.loading = true;
    this.adminServices.updateB2cR2Survey(data).subscribe((res) => {
      this.loading = false;
      if (res.status) {
        this.modalRef.hide();
        Swal.fire({
          title: "Survey Extended",
          text: res.message,
          icon: "success",
          showConfirmButton: false,
          timer: 2500,
        });
        this.getB2CR2List()
      }
    });
  }
  openExtendSurvey(template, managerSurvey) {
    this.managerSurvey = managerSurvey;

    this.minDate =
      managerSurvey.endDate > this.currentDate.toISOString()
        ? managerSurvey.endDate
        : this.currentDate.toISOString();
    this.modalRef = this.modalService.show(template);
    this.adminServices.addModal(this.modalRef);
    return false;
  }
  applyFilter() {
    const filterValue = this.searchTerm.trim().toLowerCase();

    // Filter the manager list based on manager name or manager email
    this.managerFinalList.data = this.managerList.filter(
      (manager) =>
        (manager.managerName &&
          manager.managerName.toLowerCase().includes(filterValue)) ||
        (manager.managerEmail &&
          manager.managerEmail.toLowerCase().includes(filterValue))
    );
  }
}
