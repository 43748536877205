export const getCustomDate = (action: any) => {
  let d = new Date();
  let year = d.getFullYear();
  let month = d.getMonth();
  let day = d.getDate();
  let c = new Date(year + 1, month, day);

  if (action === "year") {
    return year;
  }
  if (action === "month") {
    return month;
  }
  if (action === "date") {
    return d;
  }
  if (action === "oneYearDate") {
    return c;
  }
  return null;
};
export const dayAdd = (date: any, interval: any, units: any) => {
  var ret = new Date(date); //don't change original date
  var checkRollover = function () {
    if (ret.getDate() != date.getDate()) ret.setDate(0);
  };
  switch (interval.toLowerCase()) {
    case "year":
      ret.setFullYear(ret.getFullYear() + units);
      checkRollover();
      break;
    case "quarter":
      ret.setMonth(ret.getMonth() + 3 * units);
      checkRollover();
      break;
    case "month":
      ret.setMonth(ret.getMonth() + units);
      // checkRollover();
      break;
    case "week":
      ret.setDate(ret.getDate() + 7 * units);
      break;
    case "day":
      ret.setDate(ret.getDate() + units);
      break;
    case "hour":
      ret.setTime(ret.getTime() + units * 3600000);
      break;
    case "minute":
      ret.setTime(ret.getTime() + units * 60000);
      break;
    case "second":
      ret.setTime(ret.getTime() + units * 1000);
      break;
    default:
      // ret = undefined;
      break;
  }
  return ret;
};

export const dateFormat = (date: any, format: any) => {
  let d = new Date(date);
  let year = d.getFullYear();
  let month = d.getMonth();
  let day = d.getDate();
  //  let oneDayAfter : Date ;
  let dateFormat = "";
  let months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  if (format == "dd/mm/yyyy") {
    dateFormat = `${day}/${month + 1}/${year}`;
    return dateFormat;
  }
  // if(format == 'oneDayAfter'){
  //   return oneDayAfter = new Date(day + 1, month+1, year)
  // }
  if (format == "dd-mm-yyyy") {
    dateFormat = `${day}-${month + 1}-${year}`;
    return dateFormat;
  }
  if (format == "ddmmmyyyy") {
    dateFormat = `${day} ${months[month]} ${year}`;
    return dateFormat;
  }
  if (format == "ddmmmmyyyy") {
    dateFormat = `${day} ${monthNames[month]} ${year}`;
    return dateFormat;
  }
  return null;
};

export const getMessage = (manager: any, surveys: any) => {
  let message = "";
  if (manager) {
    if (manager.managerInfo.managerModerationStatus == "certified") {
      return (message = "sas_tfs_audited_certified");
    }
    if (manager.managerInfo.managerModerationStatus == "rejected") {
      return (message = "sas_tfs_audited_not_certified");
    }
    if (manager.managerInfo.managerModerationStatus == "audit_in_progress") {
      return (message = "sas_tfs_audit_in_progress");
    }
    if (
      surveys.length > 0 &&
      manager.managerInfo.managerModerationStatus == null
    ) {
      if (
        new Date(surveys[surveys.length - 1].startDate) >= new Date(Date.now())
      ) {
        return (message = "sas_tfs_launched");
      }
      if (
        new Date(surveys[surveys.length - 1].startDate) <=
          new Date(Date.now()) &&
        new Date(surveys[surveys.length - 1].endDate) >= new Date(Date.now())
      ) {
        return (message = "sas_tfs_ongoing");
      }
      let surveysNotCalculated = 0;
      surveys.forEach((data: any) => {
        if (
          new Date(data.endDate) < new Date(Date.now()) &&
          (typeof data.surveyScore == "undefined" ||
            (typeof data.surveyScore !== "undefined" &&
              typeof data.surveyScore.overall == "undefined"))
        ) {
          surveysNotCalculated += 1;
        }
      });
      if (surveysNotCalculated == surveys.length) {
        return (message = "sas_surveys_audit");
      }
      if (
        new Date(surveys[surveys.length - 1].endDate) <= new Date(Date.now())
      ) {
        return (message = "sas_tfs_closed");
      }
      // if(manager.scores){
      //   if(manager.scores.overall < 90){
      //     return message = 'sas_tfs_not_eligible';
      //    }
      // }
    } else {
      return (message = "sas");
    }
  }
  return null;
};

export const isEmpty = (value: any) =>
  value === undefined ||
  value === null ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);

export const getCloudData = (type: any) => {
  let data = [];
  if (type == "CH+DL") {
    return (data = [
      { text: "Autocratic", weight: 2 },
      { text: "Persuasive", weight: 4 },
      { text: "Democratic", weight: 6 },
      { text: "Laissez-faire", weight: 3 },
      { text: "Collaborative", weight: 7 },
      { text: "Affiliative", weight: 8 },
      { text: "Charismatic", weight: 6 },
      { text: "Inspirational", weight: 5 },
      { text: "Disciplinarian", weight: 5 },
      { text: "Motivator", weight: 5 },
      { text: "Coach", weight: 4 },
      { text: "Consultative", weight: 6 },
    ]);
  }
  if (type == "CH+IL") {
    return (data = [
      { text: "Persuasive", weight: 4 },
      { text: "Democratic", weight: 6 },
      { text: "Laissez-faire", weight: 5 },
      { text: "Collaborative", weight: 7 },
      { text: "Affiliative", weight: 8 },
      { text: "Charismatic", weight: 3 },
      { text: "Inspirational", weight: 1 },
      { text: "Disciplinarian", weight: 5 },
      { text: "Motivator", weight: 3 },
      { text: "Coach", weight: 5 },
      { text: "Consultative", weight: 6 },
    ]);
  }
  if (type == "CH+DL+IL") {
    return (data = [
      { text: "Autocratic", weight: 1 },
      { text: "Persuasive", weight: 3 },
      { text: "Democratic", weight: 6 },
      { text: "Laissez-faire", weight: 3 },
      { text: "Chaotic", weight: 5 },
      { text: "Collaborative", weight: 7 },
      { text: "Affiliative", weight: 8 },
      { text: "Charismatic", weight: 3 },
      { text: "Inspirational", weight: 1 },
      { text: "Disciplinarian", weight: 5 },
      { text: "Motivator", weight: 3 },
      { text: "Coach", weight: 4 },
      { text: "Consultative", weight: 6 },
    ]);
  }
  if (type == "IH+CL") {
    return (data = [
      { text: "Autocratic", weight: 6 },
      { text: "Persuasive", weight: 8 },
      { text: "Democratic", weight: 3 },
      { text: "Laissez-faire", weight: 4 },
      { text: "Collaborative", weight: 3 },
      { text: "Affiliative", weight: 1 },
      { text: "Charismatic", weight: 6 },
      { text: "Inspirational", weight: 8 },
      { text: "Disciplinarian", weight: 4 },
      { text: "Motivator", weight: 6 },
      { text: "Coach", weight: 4 },
      { text: "Consultative", weight: 6 },
    ]);
  }
  if (type == "IH+DL") {
    return (data = [
      { text: "Persuasive", weight: 5 },
      { text: "Democratic", weight: 4 },
      { text: "Laissez-faire", weight: 2 },
      { text: "Chaotic", weight: 8 },
      { text: "Collaborative", weight: 2 },
      { text: "Affiliative", weight: 5 },
      { text: "Charismatic", weight: 5 },
      { text: "Inspirational", weight: 8 },
      { text: "Disciplinarian", weight: 3 },
      { text: "Motivator", weight: 5 },
      { text: "Coach", weight: 1 },
      { text: "Consultative", weight: 4 },
    ]);
  }
  if (type == "IH+CL+DL") {
    return (data = [
      { text: "Autocratic", weight: 7 },
      { text: "Persuasive", weight: 5 },
      { text: "Democratic", weight: 4 },
      { text: "Laissez-faire", weight: 2 },
      { text: "Chaotic", weight: 9 },
      { text: "Collaborative", weight: 2 },
      { text: "Strategic", weight: 3 },
      { text: "Affiliative", weight: 1 },
      { text: "Charismatic", weight: 4 },
      { text: "Inspirational", weight: 9 },
      { text: "Motivator", weight: 3 },
      { text: "Coach", weight: 3 },
      { text: "Consultative", weight: 3 },
    ]);
  }
  if (type == "CL+DH+IH") {
    return (data = [
      { text: "Autocratic", weight: 5 },
      { text: "Persuasive", weight: 6 },
      { text: "Democratic", weight: 1 },
      { text: "Laissez-faire", weight: 9 },
      { text: "Chaotic", weight: 3 },
      { text: "Collaborative", weight: 7 },
      { text: "Strategic", weight: 6 },
      { text: "Affiliative", weight: 4 },
      { text: "Charismatic", weight: 6 },
      { text: "Inspirational", weight: 8 },
      { text: "Motivator", weight: 7 },
      { text: "Coach", weight: 6 },
    ]);
  }
  if (type == "DL+CH+IH") {
    return (data = [
      { text: "Autocratic", weight: 3 },
      { text: "Persuasive", weight: 3 },
      { text: "Democratic", weight: 7 },
      { text: "Laissez-faire", weight: 1 },
      { text: "Chaotic", weight: 6 },
      { text: "Collaborative", weight: 4 },
      { text: "Strategic", weight: 4 },
      { text: "Affiliative", weight: 8 },
      { text: "Charismatic", weight: 8 },
      { text: "Inspirational", weight: 8 },
      { text: "Disciplinarian", weight: 7 },
      { text: "Coach", weight: 3 },
      { text: "Data Driven", weight: 3 },
    ]);
  }
  if (type == "IL+CH+DH") {
    return (data = [
      { text: "Autocratic", weight: 1 },
      { text: "Persuasive", weight: 1 },
      { text: "Democratic", weight: 8 },
      { text: "Laissez-faire", weight: 7 },
      { text: "Chaotic", weight: 2 },
      { text: "Collaborative", weight: 6 },
      { text: "Strategic", weight: 7 },
      { text: "Affiliative", weight: 7 },
      { text: "Charismatic", weight: 3 },
      { text: "Inspirational", weight: 1 },
      { text: "Motivator", weight: 4 },
      { text: "Coach", weight: 7 },
      { text: "Data Driven", weight: 8 },
    ]);
  }
  if (type == "M") {
    return (data = [
      { text: "Autocratic", weight: 1 },
      { text: "Persuasive", weight: 3 },
      { text: "Democratic", weight: 5 },
      { text: "Laissez-faire", weight: 5 },
      { text: "Chaotic", weight: 1 },
      { text: "Collaborative", weight: 5 },
      { text: "Strategic", weight: 8 },
      { text: "Affiliative", weight: 5 },
      { text: "Charismatic", weight: 4 },
      { text: "Inspirational", weight: 4 },
      { text: "Motivator", weight: 5 },
      { text: "Data Driven", weight: 7 },
    ]);
  }
  if (type == "L") {
    return (data = [
      { text: "Autocratic", weight: 10 },
      { text: "Democratic", weight: 6 },
      { text: "Laissez-faire", weight: 5 },
      { text: "Chaotic", weight: 9 },
      { text: "Collaborative", weight: 4 },
      { text: "Charismatic", weight: 1 },
      { text: "Inspirational", weight: 3 },
      { text: "Disciplinarian", weight: 8 },
      { text: "Motivator", weight: 2 },
      { text: "Coach", weight: 3 },
      { text: "Consultative", weight: 7 },
    ]);
  }
  if (type == "H") {
    return (data = [
      { text: "Autocratic", weight: 1 },
      { text: "Persuasive", weight: 4 },
      { text: "Democratic", weight: 7 },
      { text: "Laissez-faire", weight: 7 },
      { text: "Chaotic", weight: 2 },
      { text: "Collaborative", weight: 6 },
      { text: "Strategic", weight: 6 },
      { text: "Affiliative", weight: 1 },
      { text: "Inspirational", weight: 10 },
      { text: "Disciplinarian", weight: 3 },
      { text: "Motivator", weight: 8 },
    ]);
  }
  if (type == "DH+CL") {
    return (data = [
      { text: "Persuasive", weight: 4 },
      { text: "Democratic", weight: 2 },
      { text: "Laissez-faire", weight: 9 },
      { text: "Collaborative", weight: 3 },
      { text: "Charismatic", weight: 5 },
      { text: "Affiliative", weight: 5 },
      { text: "Inspirational", weight: 5 },
      { text: "Disciplinarian", weight: 3 },
      { text: "Motivator", weight: 5 },
      { text: "Disciplinarian", weight: 5 },
      { text: "Coach", weight: 7 },
      { text: "Data Driven", weight: 7 },
      { text: "Consultative", weight: 3 },
    ]);
  }
  if (type == "DH+IL") {
    return (data = [
      { text: "Autocratic", weight: 3 },
      { text: "Persuasive", weight: 3 },
      { text: "Democratic", weight: 5 },
      { text: "Laissez-faire", weight: 9 },
      { text: "Collaborative", weight: 7 },
      { text: "Charismatic", weight: 3 },
      { text: "Affiliative", weight: 5 },
      { text: "Inspirational", weight: 1 },
      { text: "Disciplinarian", weight: 3 },
      { text: "Motivator", weight: 5 },
      { text: "Disciplinarian", weight: 5 },
      { text: "Coach", weight: 7 },
      { text: "Data Driven", weight: 7 },
      { text: "Strategic", weight: 5 },
    ]);
  }
  if (type == "DH+CL+IL") {
    return (data = [
      { text: "Autocratic", weight: 5 },
      { text: "Persuasive", weight: 6 },
      { text: "Democratic", weight: 2 },
      { text: "Laissez-faire", weight: 8 },
      { text: "Collaborative", weight: 6 },
      { text: "Charismatic", weight: 4 },

      { text: "Inspirational", weight: 2 },
      { text: "Disciplinarian", weight: 3 },
      { text: "Motivator", weight: 3 },
      { text: "Coach", weight: 4 },
      { text: "Data Driven", weight: 8 },

      { text: "Chaotic", weight: 6 },
    ]);
  }
  return null;
};

export const timeFormatForVideoPlayer = (seconds: any) => {
  let m: any;
  m =
    Math.floor(seconds / 60) < 10
      ? "0" + Math.floor(seconds / 60)
      : Math.floor(seconds / 60);
  let s =
    Math.floor(seconds - m * 60) < 10
      ? "0" + Math.floor(seconds - m * 60)
      : Math.floor(seconds - m * 60);
  return m + ":" + s;
};

export const capitalizeName = (name: any) => {
  return (name ? name.toLowerCase() : name).replace(
    /(?:^|\s|['`‘’.-])[^\x00-\x60^\x7B-\xDF](?!(\s|$))/g,
    function (a: any) {
      return a.toUpperCase();
    }
  );
};

export const removeDuplicates = (myArr: any, prop: any) => {
  return myArr.filter((obj: any, pos: any, arr: any) => {
    return arr.map((mapObj: any) => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
};

export function getImageUrls(postDescription: any) {
  var data,
    urls = [],
    str = postDescription,
    rex = /<img[^>]+src="(https:\/\/[^">]+)/g;

  while ((data = rex.exec(str))) {
    urls.push(data[1]);
  }
  let result = postDescription.replace(
    /<figure class="image">(.*?)figure>/g,
    ""
  );
  return { modifiedPostDescription: result, imgUrls: urls };
}

export const badWords = [
  "dumbness",
  "Idiot",
  "idiot",
  "Bewakoof",
  "bewakoof",
  "kamina",
  "Kamina",
  "Dumbhead",
  "dumbhead",
  "Janwar",
  "janwar",
  "Fool",
  "fool",
  "Stupid",
  "stupid",
  "Douche",
  "douche",
  "4r5e",
  "5h1t",
  "5hit",
  "a55",
  "anal",
  "anus",
  "ar5e",
  "arrse",
  "arse",
  "ass",
  "ass-fucker",
  "asses",
  "assfucker",
  "assfukka",
  "asshole",
  "assholes",
  "asswhole",
  "a_s_s",
  "b!tch",
  "b00bs",
  "b17ch",
  "b1tch",
  "ballbag",
  "balls",
  "ballsack",
  "bastard",
  "beastial",
  "beastiality",
  "bellend",
  "bestial",
  "bestiality",
  "bi+ch",
  "biatch",
  "bitch",
  "bitcher",
  "bitchers",
  "bitches",
  "bitchin",
  "bitching",
  "bloody",
  "blow job",
  "blowjob",
  "blowjobs",
  "boiolas",
  "bollock",
  "bollok",
  "boner",
  "boob",
  "boobs",
  "booobs",
  "boooobs",
  "booooobs",
  "booooooobs",
  "breasts",
  "buceta",
  "bugger",
  "bum",
  "bunny fucker",
  "butt",
  "butthole",
  "buttmuch",
  "buttplug",
  "c0ck",
  "c0cksucker",
  "carpet muncher",
  "cawk",
  "chink",
  "cipa",
  "cl1t",
  "clit",
  "clitoris",
  "clits",
  "cnut",
  "cock",
  "cock-sucker",
  "cockface",
  "cockhead",
  "cockmunch",
  "cockmuncher",
  "cocks",
  "cocksuck",
  "cocksucked",
  "cocksucker",
  "cocksucking",
  "cocksucks",
  "cocksuka",
  "cocksukka",
  "cok",
  "cokmuncher",
  "coksucka",
  "coon",
  "cox",
  "crap",
  "cum",
  "cummer",
  "cumming",
  "cums",
  "cumshot",
  "cunilingus",
  "cunillingus",
  "cunnilingus",
  "cunt",
  "cuntlick",
  "cuntlicker",
  "cuntlicking",
  "cunts",
  "cyalis",
  "cyberfuc",
  "cyberfuck",
  "cyberfucked",
  "cyberfucker",
  "cyberfuckers",
  "cyberfucking",
  "d1ck",
  "damn",
  "dick",
  "dickhead",
  "dildo",
  "dildos",
  "dink",
  "dinks",
  "dirsa",
  "dlck",
  "dog-fucker",
  "doggin",
  "dogging",
  "donkeyribber",
  "doosh",
  "duche",
  "dyke",
  "ejaculate",
  "ejaculated",
  "ejaculates",
  "ejaculating",
  "ejaculatings",
  "ejaculation",
  "ejakulate",
  "f u c k",
  "f u c k e r",
  "f4nny",
  "fag",
  "fagging",
  "faggitt",
  "faggot",
  "faggs",
  "fagot",
  "fagots",
  "fags",
  "fanny",
  "fannyflaps",
  "fannyfucker",
  "fanyy",
  "fatass",
  "fcuk",
  "fcuker",
  "fcuking",
  "feck",
  "fecker",
  "felching",
  "fellate",
  "fellatio",
  "fingerfuck",
  "fingerfucked",
  "fingerfucker",
  "fingerfuckers",
  "fingerfucking",
  "fingerfucks",
  "fistfuck",
  "fistfucked",
  "fistfucker",
  "fistfuckers",
  "fistfucking",
  "fistfuckings",
  "fistfucks",
  "flange",
  "fook",
  "fooker",
  "fuck",
  "fucka",
  "fucked",
  "fucker",
  "fuckers",
  "fuckhead",
  "fuckheads",
  "fuckin",
  "fucking",
  "fuckings",
  "fuckingshitmotherfucker",
  "fuckme",
  "fucks",
  "fuckwhit",
  "fuckwit",
  "fudge packer",
  "fudgepacker",
  "fuk",
  "fuker",
  "fukker",
  "fukkin",
  "fuks",
  "fukwhit",
  "fukwit",
  "fux",
  "fux0r",
  "f_u_c_k",
  "gangbang",
  "gangbanged",
  "gangbangs",
  "gaylord",
  "gaysex",
  "goatse",
  "God",
  "god-dam",
  "god-damned",
  "goddamn",
  "goddamned",
  "hardcoresex",
  "hell",
  "heshe",
  "hoar",
  "hoare",
  "hoer",
  "homo",
  "hore",
  "horniest",
  "horny",
  "hotsex",
  "jack-off",
  "jackoff",
  "jap",
  "jerk-off",
  "jism",
  "jiz",
  "jizm",
  "jizz",
  "kawk",
  "knob",
  "knobead",
  "knobed",
  "knobend",
  "knobhead",
  "knobjocky",
  "knobjokey",
  "kock",
  "kondum",
  "kondums",
  "kum",
  "kummer",
  "kumming",
  "kums",
  "kunilingus",
  "l3i+ch",
  "l3itch",
  "labia",
  "lust",
  "lusting",
  "m0f0",
  "m0fo",
  "m45terbate",
  "ma5terb8",
  "ma5terbate",
  "masochist",
  "master-bate",
  "masterb8",
  "masterbat*",
  "masterbat3",
  "masterbate",
  "masterbation",
  "masterbations",
  "masturbate",
  "mo-fo",
  "mof0",
  "mofo",
  "mothafuck",
  "mothafucka",
  "mothafuckas",
  "mothafuckaz",
  "mothafucked",
  "mothafucker",
  "mothafuckers",
  "mothafuckin",
  "mothafucking",
  "mothafuckings",
  "mothafucks",
  "mother fucker",
  "motherfuck",
  "motherfucked",
  "motherfucker",
  "motherfuckers",
  "motherfuckin",
  "motherfucking",
  "motherfuckings",
  "motherfuckka",
  "motherfucks",
  "muff",
  "mutha",
  "muthafecker",
  "muthafuckker",
  "muther",
  "mutherfucker",
  "n1gga",
  "n1gger",
  "nazi",
  "nigg3r",
  "nigg4h",
  "nigga",
  "niggah",
  "niggas",
  "niggaz",
  "nigger",
  "niggers",
  "nob",
  "nob jokey",
  "nobhead",
  "nobjocky",
  "nobjokey",
  "numbnuts",
  "nutsack",
  "orgasim",
  "orgasims",
  "orgasm",
  "orgasms",
  "p0rn",
  "pawn",
  "pecker",
  "penis",
  "penisfucker",
  "phonesex",
  "phuck",
  "phuk",
  "phuked",
  "phuking",
  "phukked",
  "phukking",
  "phuks",
  "phuq",
  "pigfucker",
  "pimpis",
  "piss",
  "pissed",
  "pisser",
  "pissers",
  "pisses",
  "pissflaps",
  "pissin",
  "pissing",
  "pissoff",
  "poop",
  "porn",
  "porno",
  "pornography",
  "pornos",
  "prick",
  "pricks",
  "pron",
  "pube",
  "pusse",
  "pussi",
  "pussies",
  "pussy",
  "pussys",
  "rectum",
  "retard",
  "rimjaw",
  "rimming",
  "s hit",
  "s.o.b.",
  "sadist",
  "schlong",
  "screwing",
  "scroat",
  "scrote",
  "scrotum",
  "semen",
  "sex",
  "sh!+",
  "sh!t",
  "sh1t",
  "shag",
  "shagger",
  "shaggin",
  "shagging",
  "shemale",
  "shi+",
  "shit",
  "shitdick",
  "shite",
  "shited",
  "shitey",
  "shitfuck",
  "shitfull",
  "shithead",
  "shiting",
  "shitings",
  "shits",
  "shitted",
  "shitter",
  "shitters",
  "shitting",
  "shittings",
  "shitty",
  "skank",
  "slut",
  "sluts",
  "smegma",
  "smut",
  "snatch",
  "son-of-a-bitch",
  "spac",
  "spunk",
  "s_h_i_t",
  "t1tt1e5",
  "t1tties",
  "teets",
  "teez",
  "testical",
  "testicle",
  "tit",
  "titfuck",
  "tits",
  "titt",
  "tittie5",
  "tittiefucker",
  "titties",
  "tittyfuck",
  "tittywank",
  "titwank",
  "tosser",
  "turd",
  "tw4t",
  "twat",
  "twathead",
  "twatty",
  "twunt",
  "twunter",
  "v14gra",
  "v1gra",
  "vagina",
  "viagra",
  "vulva",
  "w00se",
  "wang",
  "wank",
  "wanker",
  "wanky",
  "whoar",
  "whore",
  "willies",
  "willy",
  "xrated",
  "xxx",
];

// export const convertDate = (date: any, format: any) => {
//   if (!date) return [];
//   let newData = new Date(date);
//   switch (format) {
//     case 'ISO':
//       return newData.toISOString();
//   }
//   return;
// };
export const convertDate = (date: any, format: any): any => {
  if (!date) return null; // Return a default value, adjust as needed
  let newData = new Date(date);
  switch (format) {
    case "ISO":
      return newData.toISOString();
    default:
      return null; // Return a default value for unknown formats, adjust as needed
  }
};

/*
  Takes input as an array of array of object 
  e.g = input = [[{key:'value}],[name],[{key:value},{key:value}],[]]
  output = [{key: value},{key:value}]
  */
export const arrOfArrtoArrOfObj = (inputArr: any) => {
  let newRes = [];
  for (let i = 0; i < inputArr.length; i++) {
    // console.log(inputArr[i]);
    if (inputArr[i].length > 0) {
      for (let j = 0; j < inputArr[i].length; j++) {
        newRes.push(inputArr[i][j]);
      }
    }
  }
  return newRes;
};

export const checkEmptyObj = (input: any) => {
  if (typeof input === "object" && !Array.isArray(input) && input !== null) {
    return false;
  }

  return Object.keys(input).length > 0 ? true : false;
};

export function preventNegativeInput(event: KeyboardEvent) {
  if (event.key === "-" || event.key === "e" || event.key === ".") {
    event.preventDefault();
  }
}

export function enforceMaxValue(event: any, maxValue: number) {
  const inputValue = (event.target as HTMLInputElement).value;

  if (Number(inputValue) > maxValue) {
    (event.target as HTMLInputElement).value = String(maxValue);
  }
}

export function toCamelCase(inputString: string) {
  // Ensure inputString is a string, handle empty or non-string inputs
  if (typeof inputString !== "string" || inputString.trim() === "") {
    throw new Error("Input should be a non-empty string.");
  }

  // Convert spaces followed by a character to uppercase
  // Remove all spaces
  // Convert first character to lowercase
  return inputString
    .replace(/\s+(.)/g, (_, match) => match.toUpperCase())
    .replace(/\s+/g, "")
    .replace(/^(.)/, (match) => match.toLowerCase());
}
