import { Component, OnDestroy } from "@angular/core";
import { RoundTwoService } from "./round-two.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-r2-survey",
  templateUrl: "./r2-survey.component.html",
  styleUrls: ["./r2-survey.component.css"],
})
export class R2SurveyComponent implements OnDestroy {
  isLoading: boolean = false;
  sections: string[] = ["Round two Data", "Reports", "Survey modification"];
  selectedSection: number = 0;

  private subscription: Subscription;
  constructor(private _roundTwoService: RoundTwoService) {
    this.subscription = this._roundTwoService.selectedSection$.subscribe(
      (section) => {
        this.selectedSection = section;
      }
    );
  }
  ngOnInit() {}
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
