<div class="container-PIS">
  <div class="table-container shadow">
    <div class="row action-info-inner">
      <div class="col-md-6">
        <div class="action-container">
          <span class="theme-container" *ngFor="let type of filterType">
            <label [ngClass]="{ active: selectedType == type }" for="{{ type }}">{{ type }}</label>
            <input type="radio" name="filter" id="{{ type }}" value="{{ type }}" (click)="handleForms($event)" />
          </span>
        </div>
      </div>
      <div class="col-md-4">
        <mat-form-field class="search-form-field" floatLabel="never">
          <input matInput [(ngModel)]="searchKey" placeholder="Search" autocomplete="off" (keyup)="applyFilter()" />
          <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="searchKey" (click)="onSearchClear()">
            <!-- <mat-icon>close</mat-icon> -->
            <i class="fa fa-times"></i>
          </button>
        </mat-form-field>
      </div>
      <div class="col-md-2">
        <div class="info-container">
          <div class="info">
            <p class="info-text">Valid:</p>
            <p class="info-value">{{ validRows?.length }}</p>
          </div>
          <div class="info">
            <p class="info-text">InValid:</p>
            <p class="info-value">{{ notValidRows?.length }}</p>
          </div>
          <!-- <div class="info">
                  <p class="info-text">Already Registered Managers</p>
                  <p class="info-value">{{registeredRows?.length}}</p>
                </div> -->
        </div>
      </div>

    </div>
    <div class="table-container">
      <mat-table [dataSource]="dataSource" matSort>
        <!-- Loop through all properties of the first data object to create columns -->
        <ng-container *ngFor="let prop of displayedColumns" [matColumnDef]="prop">
          <mat-header-cell style="width: 180px; white-space: nowrap;" *matHeaderCellDef mat-sort-header>{{ prop |
            camelCaseToTitleCase
            }}</mat-header-cell>
          <mat-cell *matCellDef="let row; let i = index" [ngClass]="{'invalid': isEditable(row, prop)}">
            <!-- If this is the "action" column, show edit/delete icons -->
            <ng-container *ngIf="prop === 'action'">
              <!-- <i class="fa fa-edit" style="padding-right: 10%; cursor: pointer" (click)="editRow(editForm, row, i)"></i> -->
              <i class="fa fa-trash" style="padding-right: 10%; cursor: pointer"
                (click)="deleteRow(deleteConfirmation, row, i)"></i>
            </ng-container>
            <ng-container *ngIf="demographics.includes(prop)">
              <mat-select [(value)]="row[prop]" (selectionChange)="onSelectionChange(row, prop, $event.value)"
                placeholder="Select {{ prop }}">
                <mat-option *ngFor="let option of getOptions(prop)" [value]="option">
                  {{ option }}
                </mat-option>
              </mat-select>
            </ng-container>

            <!-- Input for other properties -->
            <ng-container *ngIf="!demographics.includes(prop) && prop !== 'error' && prop !== 'action'">
              <input matInput (ngModelChange)="onInputChange(row, prop, $event)" [ngModel]="row[prop]" />
            </ng-container>
            <!-- <ng-container *ngIf="prop !== 'action' && prop !== 'error'">{{
              row[prop]
            }}</ng-container> -->
            <ng-container class="invalid" *ngIf="prop == 'error'">{{
              row[prop] | joinObjectValues
              }}</ng-container>
          </mat-cell>
        </ng-container>

        <!-- Create header row with column names -->
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

        <!-- Create data rows with object data -->
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>

      <!-- <mat-table [dataSource]="dataSource" matSort style="width:fit-content">

        <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
          <mat-header-cell style="margin-right: 10px;" *matHeaderCellDef mat-sort-header>
            {{ column | titlecase }}
            <span class="asterick">*</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element[column] }}
          </mat-cell>
        </ng-container>


        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table> -->

      <mat-paginator [length]="10" [pageSize]="10" [pageSizeOptions]="[1, 10, 25]" showFirstLastButtons>
      </mat-paginator>
      <button type="button" class="submit-btn" (click)="submitData(submitConfirmation)">
        Submit Data
      </button>
      <button type="button" class="validate-btn" (click)="validateData()">
        {{buttonText}}
      </button>

    </div>
  </div>

  <!-- Popups -->
  <ng-template #editForm>
    <div class="modal-header">
      <h4 style="font-size: 22px; text-transform: capitalize" class="modal-title pull-left">
        Edit Row -
        <p style="margin-bottom: 0; color: red" *ngFor="let error of errorsKey">
          {{ error }}
        </p>
      </h4>
      <button type="button " class="close pull-right" aria-label="Close " (click)="modalRef.hide()">
        <span aria-hidden="true ">&times;</span>
      </button>
    </div>
    <div class="modal-body row" *ngIf="editingFormData">
      <form (ngSubmit)="onEdittingForm(f)" #f="ngForm">
        <div class="form-group col-md-6 ptb-15" *ngFor="let key of editingFormDataKeys">
          <label [class.text-danger]="getError(key)" for="{{ key }}">{{ key | camelCaseToTitleCase
            }}<span *ngIf="editingFormData[key].required" class="asterick">*</span></label>
          <input class="form-control custom-form-control" name="{{ key }}" [(ngModel)]="editingFormData[key]"
            #input="ngModel" [required]="editingFormData[key].required" />
          <p class="help-block" *ngIf="!input.valid && input.touched">
            Please enter {{ key }}
          </p>
        </div>
        <div class="col-md-12 ptb-15">
          <input type="submit" class="form-control custom-form-control"
            style="background-color: #00526f; color: white; border-radius: 5px" [disabled]="!f.valid"
            value="Update Row" />
        </div>
      </form>
    </div>
  </ng-template>
  <ng-template #deleteConfirmation>
    <div class="modal-header">
      <h4 style="font-size: 22px; text-transform: capitalize" class="modal-title pull-left">
        Delete Row
      </h4>
      <button type="button " class="close pull-right" aria-label="Close " (click)="modalRef.hide()">
        <span aria-hidden="true ">&times;</span>
      </button>
    </div>
    <div class="modal-body row">
      <div class="col-md-12 ptb-15">
        <!-- <div class="row">
                      <label for="name">Are you sure you want to delete?</label>
                      <div class="form-group col-md-6 ptb-15">
                          <input type="button"
                              class="form-control custom-form-control"
                              value="Delete"
                              (click)="deleteRow()"/>
                      </div>
                      <div class="form-group col-md-6 ptb-15">
                          <input type="button"
                              class="form-control custom-form-control"
                              value="Cancel"
                              (click)="cancelDeletingRow()"/>
                      </div>
                  </div> -->

        <div class="modal-body text-center">
          <p>Are you sure you want to delete?</p>
          <button type="button" class="btn btn-default" (click)="confirm()">
            Yes
          </button>
          <button type="button" class="btn btn-primary" (click)="decline()">
            No
          </button>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #submitConfirmation>
    <div class="modal-header">
      <h4 style="font-size: 22px; text-transform: capitalize" class="modal-title pull-left">
        Submitting Data
      </h4>
      <button type="button " class="close pull-right" aria-label="Close " (click)="modalRef.hide()">
        <span aria-hidden="true ">&times;</span>
      </button>
    </div>
    <div class="modal-body row">
      <div class="col-md-12 ptb-15">
        <div class="modal-body text-center">
          <!-- <p>Are you sure you want to Submit the Data?</p> -->
          <p>
            Only Valid Data will be saved & Remaining invalid Data will be auto
            removed
          </p>
          <p>Number of valid Rows {{ validRows.length }}</p>
          <p>Number of Invalid Rows {{ notValidRows.length }}</p>
          <button type="button" class="btn btn-default" (click)="checkAndSubmitData()">
            Yes
          </button>
          <button type="button" class="btn btn-primary" (click)="modalRef.hide()">
            No
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</div>