<div class="container-fluid">
  <div class="row">
    <div class="col-md-12" style="margin-bottom: 10px">
      <div class="col-md-6 div-holder">
        <div class="row header">
          <div class="col-md-6 left-header">
            Estimated Employees in the uploaded sheet
            <p>{{ xlData.length }}</p>
          </div>
          <div class="col-md-6 right-header">
            Estimated Columns in the uploaded sheet
            <p>{{ headers.length }}</p>
          </div>
        </div>
        <div
          *ngIf="
            (isManagerUpload && uploadType == 'manager') ||
            (isParticipantUpload && uploadType == 'participant')
          "
        >
          <div class="modal-header">
            <h4 style="font-size: 22px" class="modal-title pull-left">
              Add Company Demographics
            </h4>
            <button
              type="button "
              class="close pull-right"
              aria-label="Close "
              (click)="openDemographicInput()"
            >
              <span aria-hidden="true ">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group col-md-12" style="text-align: center">
                  <p>
                    Please add the list of Demographics / You can also edit the
                    already added one.
                  </p>
                  <p style="color:#e30615cc">
                    Before adding any demographic please check if it's available in default demographic list.
                  </p>
                  <p>{{ errorMessage }}</p>
                  <input
                    type="text"
                    class="demographicInput"
                    (input)="errorMessage = ''"
                    [(ngModel)]="demographic"
                  />
                  <!-- [disabled]="demographic.length == 0 ||   headersAsObject.length == managerStandardDemographicList.length + this.managersDemographicsList.length " -->
                  <button
                    type="submit"
                    class="btn demographicSaveButton btn-primary"
                    (click)="
                      manageDemographicsInList(
                        uploadType == 'manager' ? 'manager' : 'participant',
                        'add',
                        0
                      )
                    "
                  >
                    {{ editDemographicList ? "Update" : "Add" }}
                  </button>

                  <!-- [disabled]="  headersAsObject.length !=   managerStandardDemographicList.length + this.managersDemographicsList.length " -->
                  <button
                    type="submit"
                    class="btn btn-default"
                    (click)="
                      saveDemographics(
                        uploadType == 'manager' ? 'manager' : 'participant'
                      )
                    "
                  >
                    Save
                  </button>
                  <div class="demographicListContainer">
                    <div
                      class="demographicList"
                      *ngFor="
                        let demographic of uploadType == 'manager'
                          ? managersDemographicsList
                          : participantsDemographicsList;
                        let i = index
                      "
                    >
                      <p>{{ demographic | titlecase }}</p>
                      <button
                        class="btn btn-default"
                        (click)="
                          manageDemographicsInList(
                            uploadType == 'manager' ? 'manager' : 'participant',
                            'remove',
                            i
                          )
                        "
                      >
                        &times;
                      </button>
                      <i
                        matTooltip="Edit Demographic"
                        matTooltipPosition="above"
                        class="fa fa-edit"
                        (click)="
                          manageDemographicsInList(
                            uploadType == 'manager' ? 'manager' : 'participant',
                            'edit',
                            i
                          )
                        "
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form
          *ngIf="
            (!isManagerUpload && uploadType == 'manager') ||
            (!isParticipantUpload && uploadType == 'participant')
          "
          [formGroup]="mappingForm"
          (ngSubmit)="onSubmit(mappingForm.value)"
        >
          <div class="demographicEditContainer">
            <p>Click on edit button to add or delete demographic</p>
            <div class="btn btn-primary" (click)="openDemographicInput()">
              Edit
            </div>
          </div>
          <div class="row">
            <div
              class="col-md-6"
              *ngFor="let demographic of standardDemographicList; let i = index"
            >
              <!-- <label>{{ demographic.label }}</label>
            <input type="text" [formControlName]="demographic.controlName"> -->
              <div class="form-group col-md-12 ptb-15">
                <label for="name"
                  >{{ demographic.label | titlecase }}
                  <span class="asterick">*</span>
                </label>
                <div class="flex">
                  <select
                    id="demographic.controlName"
                    [formControlName]="demographic.controlName"
                    name="demographic.controlName"
                    class="form-control custom-form-control"
                  >
                    <option value="" disabled [selected]="true">
                      Select one header
                    </option>
                    <option *ngFor="let header of headers" [ngValue]="header">
                      {{ header }}
                    </option>
                  </select>
                  <button
                    matTooltip="Delete this demographic"
                    class="deleteButton"
                    (click)="deleteControl(demographic.controlName)"
                  >
                    <span aria-hidden="true ">&times;</span>
                  </button>
                </div>

                <p class="help-block" *ngIf="false">
                  Please Select {{ demographic.label }} column
                </p>
              </div>
            </div>
            <div
              class="col-md-6"
              *ngFor="
                let demographic of uploadType == 'manager'
                  ? managerDynamicDemographicsList
                  : participantDynamicDemographicsList;
                let i = index
              "
            >
              <!-- <label>{{ demographic.label }}</label>
          <input type="text" [formControlName]="demographic.controlName"> -->
              <div class="form-group col-md-12 ptb-15">
                <label for="name"
                  >{{ demographic.label | titlecase }}
                  <span class="asterick">*</span>
                </label>
                <div class="flex">
                  <select
                    id="demographic.controlName"
                    [formControlName]="demographic.controlName"
                    name="demographic.controlName"
                    class="form-control custom-form-control"
                  >
                    <option value="" disabled [selected]="true">
                      Select one header
                    </option>
                    <option *ngFor="let header of headers" [ngValue]="header">
                      {{ header }}
                    </option>
                  </select>
                  <button
                    matTooltip="Delete this demographic"
                    class="deleteButton"
                    (click)="deleteControl(demographic.controlName)"
                  >
                    <span aria-hidden="true ">&times;</span>
                  </button>
                </div>

                <p class="help-block" *ngIf="false">
                  Please Select {{ demographic.label }} column
                </p>
              </div>
            </div>
          </div>
          <button
            class="btn btn-primary mappingSubmit"
            [disabled]="!mappingForm.valid"
            type="submit"
          >
            Submit
          </button>
        </form>
        <!-- <form [formGroup]="mappingForm" (ngSubmit)="onSubmit(mappingForm.value)">
      
          <div class="row">
            <div class="form-group ptb-15" style="width: 50%; margin: 10px auto">
              <label for="name">Unique Field ID<span class="asterick">*</span></label>
              <select id="empId" formControlName="empId" name="empId" class="form-control custom-form-control">
                <option value="" disabled [selected]="true">
                  Select one header
                </option>
                <option *ngFor="let header of headers" [ngValue]="header">
                  {{ header }}
                </option>
              </select>
              <p class="help-block" *ngIf="false">
                Please Select Your Employee ID Column
              </p>
            </div>
            <div class="col-md-6">
              <div class="form-group col-md-12 ptb-15">
                <label for="name">Employee Name <span class="asterick">*</span>
                </label>
                <select id="empName" formControlName="empName" name="empName" class="form-control custom-form-control">
                  <option value="" disabled [selected]="true">
                    Select one header
                  </option>
                  <option *ngFor="let header of headers" [ngValue]="header">
                    {{ header }}
                  </option>
                </select>
                <p class="help-block" *ngIf="false">
                  Please Select Employee Name column
                </p>
              </div>
              <div class="form-group col-md-12 ptb-15">
                <label for="name">Manager-1 Name <span class="asterick">*</span>
                </label>
                <select id="mgrName1" formControlName="mgrName1" name="mgrName1"
                  class="form-control custom-form-control">
                  <option value="" disabled [selected]="true">
                    Select one header
                  </option>
                  <option *ngFor="let header of headers" [ngValue]="header">
                    {{ header }}
                  </option>
                </select>
                <p class="help-block" *ngIf="false">
                  Please Select Manager-1 Name column
                </p>
              </div>
              <div class="form-group col-md-12 ptb-15">
                <label for="name">Manager-1 Mobile
                </label>
                <select id="mgrMob" formControlName="mgrMob" name="mgrMob" class="form-control custom-form-control">
                  <option value="" disabled [selected]="true">
                    Select one header
                  </option>
                  <option *ngFor="let header of headers" [ngValue]="header">
                    {{ header }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-12 ptb-15">
                <label for="name">Respondent Type <span class="asterick">*</span></label>
                <select id="empType" formControlName="empType" name="empType" class="form-control custom-form-control">
                  <option value="" disabled [selected]="true">
                    Select one header
                  </option>
                  <option *ngFor="let header of headers" [ngValue]="header">
                    {{ header }}
                  </option>
                </select>
              </div>

              <div class="form-group col-md-12 ptb-15">
                <label for="name">Batch Number</label>
                <select id="batchNumber" formControlName="batchNumber" name="batchNumber"
                  class="form-control custom-form-control">
                  <option value="" disabled [selected]="true">
                    Select one header
                  </option>
                  <option *ngFor="let header of headers" [ngValue]="header">
                    {{ header }}
                  </option>
                </select>
              </div>

              <div class="form-group col-md-12 ptb-15">
                <label for="name">Employee Company ID</label>
                <select id="empCompanyId" formControlName="empCompanyId" name="empCompanyId"
                  class="form-control custom-form-control" (ngModelChange)="changeValidators($event)">
                  <option value="" disabled [selected]="true">
                    Select one header
                  </option>
                  <option *ngFor="let header of headers" [ngValue]="header">
                    {{ header }}
                  </option>
                </select>
                <p class="help-block" *ngIf="false">
                  Please Select Your Employee Compnay ID Column
                </p>
              </div>
              <!-- <div class="form-group col-md-12 ptb-15">
					<label for="name">Employee Location </label>
					<select
					  id="empLocation"
					  formControlName="empLocation"
					  name="empLocation"
					  class="form-control custom-form-control"
					>
					  <option value="" disabled [selected]="true"
						>Select one header</option
					  >
					  <option *ngFor="let header of headers" [ngValue]="header">{{
						header
					  }}</option>
					</select>
			  </div> 
            </div>
            <div class="col-md-6">
        
              <div class="form-group col-md-12 ptb-15">
                <label for="name">Employee Email <span class="asterick">*</span>
                </label>
                <select id="empEmail" formControlName="empEmail" name="empEmail"
                  class="form-control custom-form-control">
                  <option value="" disabled [selected]="true">
                    Select one header
                  </option>
                  <option *ngFor="let header of headers" [ngValue]="header">
                    {{ header }}
                  </option>
                </select>
                <p class="help-block" *ngIf="false">
                  Please Select Employee Email column
                </p>
              </div>
              <div class="form-group col-md-12 ptb-15">
                <label for="name">Manager-1 Email <span class="asterick">*</span>
                </label>
                <select id="mgrEmail1" formControlName="mgrEmail1" name="mgrEmail1"
                  class="form-control custom-form-control">
                  <option value="" disabled [selected]="true">
                    Select one header
                  </option>
                  <option *ngFor="let header of headers" [ngValue]="header">
                    {{ header }}
                  </option>
                </select>
                <p class="help-block" *ngIf="false">
                  Please Select Manager-1 Email column
                </p>
              </div>
              <div class="form-group col-md-12 ptb-15">
                <label for="name">Employee Mobile
                </label>
                <select id="empMob" formControlName="empMob" name="empMob" class="form-control custom-form-control">
                  <option value="" disabled [selected]="true">
                    Select one header
                  </option>
                  <option *ngFor="let header of headers" [ngValue]="header">
                    {{ header }}
                  </option>
                </select>

              </div>
              <div class="form-group col-md-12 ptb-15">
                <label for="name">Batch ID</label>
                <select id="batchId" formControlName="batchId" name="batchId" class="form-control custom-form-control">
                  <option value="" disabled [selected]="true">
                    Select one header
                  </option>
                  <option *ngFor="let header of headers" [ngValue]="header">
                    {{ header }}
                  </option>
                </select>
              </div>

              <div class="form-group col-md-12 ptb-15">
                <label for="name">Batch Email</label>
                <select id="batchEmail" formControlName="batchEmail" name="batchEmail"
                  class="form-control custom-form-control">
                  <option value="" disabled [selected]="true">
                    Select one header
                  </option>
                  <option *ngFor="let header of headers" [ngValue]="header">
                    {{ header }}
                  </option>
                </select>
              </div>

              <div class="form-group col-md-12 ptb-15">
                <label for="name">Employee DOB</label>
                <select id="empDob" formControlName="empDob" name="empDob" class="form-control custom-form-control">
                  <option value="" disabled [selected]="true">
                    Select one header
                  </option>
                  <option *ngFor="let header of headers" [ngValue]="header">
                    {{ header }}
                  </option>
                </select>
                <p class="help-block" *ngIf="false">
                  Please Select employee dob column
                </p>
              </div>

              <!-- <div class="form-group col-md-12 ptb-15">
                <label for="name">Employee Department </label>
                <select
                  id="empDepartment"
                  formControlName="empDepartment"
                  name="empDepartment"
                  class="form-control custom-form-control"
                >
                  <option value="" disabled [selected]="true"
                    >Select one header</option
                  >
                  <option *ngFor="let header of headers" [ngValue]="header">{{
                    header
                  }}</option>
                </select>
              </div> 
            </div>
          </div>
          <input type="submit" name="submit" value="Submit Mapping" [disabled]="!mappingForm.valid"
            class="form-control custom-form-control"
            style="background-color: #00526f; color: white; font-weight: 500" />
        </form> -->
      </div>
      <div class="col-md-6">
        <h2 style="margin-top: 30px;color:#e30615cc">Already added {{ uploadType === 'manager' ? 'participant':'feedback giver' }} demographics.</h2>
          <div class="demographicContainer">
            <div *ngFor="let demo of alreadyAddedDemographics">
              {{demo}}
            </div>
          </div>
        <div class="table-container div-holder">
          <h4>Sample Data from uploaded sheet</h4>
          <table class="table" style="border: 1.5px solid green">
            <thead>
              <tr style="border: 1.5px solid green">
                <th
                  style="border: 1.5px solid green"
                  *ngFor="let header of headers"
                >
                  {{ header }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of xlData | slice : 1 : 11; let i = index">
                <td style="border: 1.5px solid green" *ngFor="let val of row">
                  {{ val }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
