<div style="background-color: white; padding: 15px" class="padding">
  <h3>Package Page Setting</h3>
  <div class="row" style="margin-top: 10px">
    <div class="col-md-6">
      <div class="form-group typeSelect">
        <label for="surveyType">Select Package Page:</label>
        <select
          class="form-control"
          id="surveyType"
          [(ngModel)]="pageId"
          (ngModelChange)="dataChanged($event)"
        >
          <option value="0" selected>Select</option>
          <option *ngFor="let data of allPages; index as i" value="{{ i + 1 }}">
            {{ data.page_name }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div style="padding: 20px">
    <div
      *ngIf="themeData?.length > 0 && pageId != 3"
      style="
        margin-bottom: 10px;
        background-color: rgb(252, 250, 250);
        border: 0.5px solid grey;
        border-radius: 10px;
        padding: 5px;
      "
    >
      <div class="row">
        <div class="col-md-4">
          <h3>Dimensions Order</h3>
        </div>
      </div>

      <table class="table table-striped" style="margin: auto">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Dimension</th>
            <th scope="col">Tooltip</th>
            <th scope="col">Rank Order</th>
            <th scope="col">Background Colour</th>
            <th scope="col">Font Colour</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let theme of themeData; index as i">
            <th scope="row">{{ i + 1 }}</th>
            <td>
              {{ theme.themeName }}
            </td>

            <td>
              <textarea
                maxlength="350"
                minlength="3"
                type="text"
                style="border: none"
                [(ngModel)]="theme.toolTip"
                rows="3"
              >
              </textarea>
            </td>

            <td>
              <input
                type="number"
                style="border: none; width: 30px"
                [(ngModel)]="theme.themeOrder"
              />
            </td>
            <td><input type="color" [(ngModel)]="theme.backgroundColor" /></td>
            <td>
              <select [(ngModel)]="theme.fontColor">
                <option selected value="">Choose color</option>
                <option value="#000000">Black</option>
                <option value="#ffffff">White</option>
              </select>
            </td>

            <td>
              <mat-slide-toggle
                [(ngModel)]="theme.isActive"
                [checked]="theme.isActive == 1 ? true : false"
              ></mat-slide-toggle>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <div class="row">
        <h4 style="margin-left: 8pc;">Overall Font Colours : <input type="color" [(ngModel)]="fontColor"></h4> 
        
      </div> -->
      <div class="text-center" style="margin: 12px">
        <a
          class="bold-text btn btn-gmi-primary mr-1"
          (click)="updateThemeData()"
          >Update Theme</a
        >
      </div>
    </div>
    <div *ngIf="componentMapping?.length > 0; else noComponentMapping">
      <div class="note padding">
        <p>Note:</p>
        <small>
          The Maximum and Minimum limit for Display Text is 45 and 3 Characters
          respectively.
        </small>
        <br />
        <small>
          The Maximum and Minimum limit for Tooltip is 350 and 3 Characters
          respectively.
        </small>
      </div>
      <div
        *ngFor="let component of componentMapping; let i = index"
        style="
          margin-bottom: 10px;
          border: 0.5px solid grey;
          border-radius: 10px;
          padding: 5px;
        "
      >
        <div class="row">
          <div class="col-md-4">
            <h4>Component {{ i + 1 }}</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <p style="font-weight: bold; font-size: 20px">
              {{ convertToTitleCase(component?.components?.componentName) }}
            </p>
          </div>
          <div class="col-md-4">
            <mat-slide-toggle
              [(ngModel)]="component.components.status"
              [checked]="component.components.status == 1 ? true : false"
              >{{
                component.components.status ? "Active" : "Inactive"
              }}</mat-slide-toggle
            >
            <!-- (change)="changeActiveQuestion(element._id, $event, editDate)" -->
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div class="form-group typeSelect">
              <label for="displayText">Display Text:</label>
              <input
                maxlength="45"
                minlength="3"
                [(ngModel)]="component.components.displayText"
                [disabled]="
                  displayTextNotEditable.includes(
                    component?.components?.componentName
                  )
                "
                class="form-control"
                type="text"
              />
            </div>
          </div>
          <div class="col-md-5">
            <div class="form-group typeSelect">
              <label for="displayText">Tooltip Description:</label>
              <textarea
                [disabled]="
                  toolTipNotEditable.includes(
                    component?.components?.componentName
                  )
                "
                [(ngModel)]="component.components.description"
                class="form-control"
                type="text"
                rows="4"
                maxlength="350"
                minlength="50"
              ></textarea>
            </div>
          </div>
        </div>
        <div
          *ngIf="component.subComponents"
          style="padding-top: 5px; padding-bottom: 5px"
        >
          <h4>Sub-Components:</h4>
          <div
            *ngFor="
              let subComponent of sortByKey(
                'subComponentId',
                component.subComponents
              );
              let i = index
            "
          >
            <div class="row">
              <div class="col-md-4">
                <p style="font-weight: bold">
                  {{ convertToTitleCase(subComponent?.subComponentName) }}
                </p>
              </div>
              <div class="col-md-4">
                <mat-slide-toggle
                  [disabled]="
                    onOffNotAvailable.includes(subComponent?.subComponentName)
                  "
                  [(ngModel)]="subComponent.status"
                  [checked]="subComponent.status == 1 ? true : false"
                  >{{
                    subComponent.status ? "Active" : "Inactive"
                  }}</mat-slide-toggle
                >
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-md-3">
                <div class="form-group typeSelect">
                  <label for="displayText">Display Text:</label>
                  <input
                    maxlength="3"
                    minlength="20"
                    *ngIf="
                      !dropDownChoiceAvailableSubComponent.includes(
                        subComponent?.subComponentName
                      )
                    "
                    [(ngModel)]="subComponent.displayText"
                    class="form-control"
                    type="text"
                    [disabled]="
                      displayTextNotEditable.includes(
                        subComponent?.subComponentName
                      )
                    "
                  />
                  <select
                    class="form-control"
                    [(ngModel)]="subComponent.displayText"
                    *ngIf="
                      dropDownChoiceAvailableSubComponent.includes(
                        subComponent?.subComponentName
                      )
                    "
                  >
                    <option value="0" selected>Select</option>
                    <option
                      *ngFor="
                        let option of subComponentDropDownChoices[
                          subComponent?.subComponentName
                        ]['options'];
                        index as i
                      "
                      value="{{ option }}"
                    >
                      {{ option }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-5">
                <div class="form-group typeSelect">
                  <label for="displayText">Tooltip Description: </label>
                  <textarea
                    maxlength="350"
                    minlength="3"
                    [(ngModel)]="subComponent.description"
                    class="form-control"
                    type="text"
                    rows="2"
                    [disabled]="
                      toolTipNotEditable.includes(
                        subComponent?.subComponentName
                      )
                    "
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="submit-button text-center" style="margin: 8px">
          <a class="bold-text btn btn-gmi-primary mr-1" (click)="updateData(i)"
            >Update</a
          >
        </div>
      </div>
    </div>
    <ng-template #noComponentMapping>
      <div *ngIf="pageId" class="text-center">
        <h2>
          No Setting's found for
          {{ allPages[pageId - 1].page_name }}. Do you want to add for the same
          ?
        </h2>
        <button class="btn btn-gmi-primary mr-1" (click)="addSettings()">
          Add Default Setting's for Page
          <span class="custom-bold-text">{{
            allPages[pageId - 1].page_name
          }}</span>
        </button>
        <br />
        <br />
        <div>
          <small class="note">
            Note: The Default setting will be added. You can change once it
            added.
          </small>
        </div>
      </div>
    </ng-template>
  </div>
</div>
