import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { CompanyServices } from "./company.service";
CompanyServices;

@Component({
  selector: "app-company",
  templateUrl: "./company.component.html",
  styleUrls: ["./company.component.css"],
})
export class CompanyComponent implements OnInit {
  constructor(
    private router: Router,
    private companyService: CompanyServices
  ) {}

  ngOnInit() {}

  onClickProfile() {
    this.router.navigate(["/company/company-dashboard/profile"]);
  }

  onLogout() {
    localStorage.clear();
  }
}
