import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { AdminServices } from "../../admin.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatSnackBar } from "@angular/material/snack-bar";
import Swal from "sweetalert2";
import { SelectionModel } from "@angular/cdk/collections";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import { SurveyLaunchDialog } from "./confirm-survey-dialog/survey-dialog.component";

@Component({
  selector: "app-round-two-data-process",
  templateUrl: "./round-two-data-process.component.html",
  styleUrls: ["./round-two-data-process.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class RoundTwoDataProcessComponent implements OnInit {
  isLoading: boolean = false;
  range = new FormGroup({
    startDate: new FormControl<Date | null>(null),
    endDate: new FormControl<Date | null>(null),
  });

  displayedColumns: string[] = [
    "select",
    "name",
    "email",
    "overAllScore",
    "teamMemberScore",
    "totalTeamMembers",
    "totalParticipants",
    "masterQuestionnaireId",
  ];
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  isManagersSelected: boolean = false;
  selectedDataType: string = "preProcessedData";
  isChecked!: boolean;
  isDisable: boolean = true;
  message!: string;
  years: number[] = [];
  isYearSelected: boolean = false;
  selectedYear!: number;

  mainPreprocessedData!: [];
  surveyStartDate: string = new Date(Date.now()).toUTCString();
  surveyEndDate!: string;
  @ViewChild(MatPaginator) set paginator(value: MatPaginator) {
    if (this.dataSource) {
      this.dataSource.paginator = value;
    }
  }

  constructor(
    private adminService: AdminServices,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.dataSource && this.dataSource.data.length > 0) {
      this.isDisable = false;
    }
    const currentYear = new Date().getFullYear();
    this.years.push(currentYear - 1, currentYear, currentYear + 1);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? "deselect" : "select"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
      row.id + 1
    }`;
  }

  getManagers() {
    const selectedDateRange = this.range.value;
    switch (this.selectedDataType) {
      case "preProcessedData":
        this.getPreProcessedData(selectedDateRange);
        break;
      case "processedData":
        this.getQualifiedManagers(selectedDateRange);
        break;
    }
  }
  selectType(event: any) {
    if (
      this.dataSource &&
      this.dataSource.data.length > 0 &&
      (event.value = "processedData")
    ) {
      this.dataSource.data = [];
      this.dataSource.paginator = this.paginator;
      this.selection.clear();
    }
  }
  getPreProcessedData(
    selectedDateRange: Partial<{ startDate: Date; endDate: Date }>
  ): void {
    this.isLoading = true;
    this.adminService.getR2PreProcessedDataB2C(selectedDateRange).subscribe({
      next: (response: any) => {
        this.mainPreprocessedData = response.data;

        this.dataSource = new MatTableDataSource(
          this.modifyData(this.mainPreprocessedData)
        );
        this.dataSource.paginator = this.paginator;

        this.isDisable = false;
        this.isLoading = false;
      },
      error: (err) => {
        console.log(err);
        Swal.fire({
          icon: "warning",
          title: err.error.message ?? "Something went wrong please try again.",
          showConfirmButton: true,
        });
        this.isLoading = false;
      },
    });
  }
  modifyData(managersData: any) {
    const modifiedData = managersData.map((item: any, index: number) => {
      return {
        id: index,
        name: item.manager_name,
        email: item.manager_email_id,
        overAllScore: item.overall_score,
        teamMemberScore: item.team_member_overall_score,
        totalTeamMembers: item.total_team_members,
        totalParticipants: item.total_participants,
        seSurveyId: item.survey_id,
      };
    });
    return modifiedData;
  }
  getQualifiedManagers(
    selectedDateRange: Partial<{ startDate: Date; endDate: Date }>
  ): void {
    this.isLoading = true;
    if (!this.selectedYear) {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: "Please select the year",
        showConfirmButton: true,
      });
      return;
    }
    const payload = {
      selectedDateRange: selectedDateRange,
      year: this.selectedYear,
    };
    this.adminService.getQualifiedListB2C(payload).subscribe({
      next: (res: any) => {
        const processedData = res.data.map((item: any, index: number) => {
          return {
            id: index,
            name: item.manager_name,
            email: item.manager_email,
            overAllScore: item.overall_score,
            teamMemberScore: item.team_memeber_score,
            totalTeamMembers: item.final_score,
            totalParticipants: item.total_team_memebers,
            seSurveyId: item.survey_id,
          };
        });
        this.dataSource = new MatTableDataSource(processedData);
        this.dataSource.paginator = this.paginator;
        this.isLoading = false;
      },
      error: (err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title:
            err.error.message ??
            err.error.error ??
            "Something went wrong please try again.",
          showConfirmButton: true,
        });
        this.isLoading = false;
      },
    });
  }
  filterProcessData(event: any): void {
    let filteredData: any[];

    if (event.checked) {
      filteredData = this.mainPreprocessedData.filter(
        (item: any) => item.isProcessed === true
      );
    } else {
      filteredData = this.mainPreprocessedData.filter(
        (item: any) => item.isProcessed === null || item.isProcessed === false
      );
    }

    this.dataSource = new MatTableDataSource(this.modifyData(filteredData));
    this.dataSource.paginator = this.paginator;
    this.cdr.detectChanges();
  }

  processData(gpmsRecognitionYear: number) {
    const seSurveyIdsOfSelectedManagers = this.selection.selected.map(
      (item: any) => item.seSurveyId
    );

    this.adminService
      .roundTwoAutomation({
        seSurveyIds: seSurveyIdsOfSelectedManagers,
      })
      .subscribe({
        next: (res) => {
          const { isManagerQualified } = res;
          if (isManagerQualified) {
            Swal.fire({
              title: res.message,
              text: "Go to processed data section",
              showConfirmButton: true,
              showCancelButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                console.log("confirm");
                this.selectedDataType = "processedData";
                this.dataSource.data = [];
                this.dataSource.paginator = this.paginator;
                this.selection.clear();
                // Call Qualified managers function
                this.getQualifiedManagers(this.range.value);
              } else if (result.isDismissed) {
                console.log("cancel");
                true;
              }
            });
          } else {
            Swal.fire(res.message, "", "success");
          }
        },
        error: (err) => {
          console.log(err);
          this._snackBar.open(err.error.message, "Warning", {
            horizontalPosition: "right",
            verticalPosition: "top",
            duration: 5000,
          });
        },
      });
  }

  openDialog(view: string): void {
    let year!: number;

    let dataPayload = {};
    switch (view) {
      case ViewType.PROCESS_SURVEY:
        dataPayload = {
          view: "viewSurvey",
        };
        break;
      case ViewType.SURVEY_LAUNCH:
        dataPayload = {
          managers: this.selection.selected.map((item) => item.email),
          startDate: this.surveyStartDate,
          endDate: this.surveyEndDate,
          gpmsRecognitionYear: year,
          view: "launchSurvey",
        };
        break;
    }

    const dialogRef = this.dialog.open(SurveyLaunchDialog, {
      data: dataPayload,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed", result);
      if (result.view === "launchSurvey") {
        this.launchSurvey(
          result?.startDate,
          result?.endDate,
          result?.gpmsRecognitionYear
        );
      } else {
        this.newProcessData(result?.gpmsRecognitionYear);
      }
    });
  }
  launchSurvey(startDate: string, endDate: string, year: number) {
    const managersList = this.selection.selected.map((item) => {
      return { name: item.name, email: item.email };
    });
    const launchSurveyData = {
      startDate,
      endDate,
      managers: managersList,
      gpmsRecognitionYear: year,
    };
    this.isLoading = true;
    this.adminService.launchR2Survey(launchSurveyData).subscribe({
      next: (res: any) => {
        if (res.status) {
          Swal.fire({
            title: "Survey launch sucessfully.",
            text: res.message,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.dataSource.data = [];
          this.dataSource.paginator = this.paginator;
          this.isLoading = false;
        }
      },
      error: (err) => {
        console.log(err.message);
        Swal.fire({
          title: "Try again.",
          text: err.message ?? "Something went wrong",
          icon: "error",
          showConfirmButton: false,
          timer: 2500,
        });
        this.isLoading = false;
      },
    });
  }

  selectYear(year: number): void {
    this.isYearSelected = true;
    this.selectedYear = year;
  }

  newProcessData(gpmsRecognitionYear: number): void | boolean {
    const seSurveyIdsOfSelectedManagers = this.selection.selected.map(
      (item: any) => item.seSurveyId
    );
    console.log(gpmsRecognitionYear);
    this.adminService
      .roundTwoAutomationV2({
        seSurveyIds: seSurveyIdsOfSelectedManagers,
        surveyGroupId: null,
        recognitionYear: gpmsRecognitionYear,
      })
      .subscribe({
        next: (res) => {
          const { isManagerQualified } = res;
          if (isManagerQualified) {
            Swal.fire({
              title: res.message,
              text: "Go to processed data section",
              showConfirmButton: true,
              showCancelButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                this.selectedDataType = "processedData";
                this.dataSource.data = [];
                this.dataSource.paginator = this.paginator;
                this.selection.clear();
                // Call Qualified managers function
                this.getQualifiedManagers(this.range.value);
              } else if (result.isDismissed) {
                true;
              }
            });
          } else {
            Swal.fire(res.message, "", "success");
          }
        },
        error: (err) => {
          console.log(err);
          this._snackBar.open(err.error.message, "Warning", {
            horizontalPosition: "right",
            verticalPosition: "top",
            duration: 5000,
          });
        },
      });
  }
}
export enum ViewType {
  SURVEY_LAUNCH = "launchSurvey",
  PROCESS_SURVEY = "viewSurvey",
}
