import { config } from "../app/config";

export const environment = {
  production: false,
  hostUrl: "http://localhost:4200",
  apiURl: "https://dev-api.greatmanagerinstitute.com",
  appUrl: "http://localhost:4401",
  apiReadUrl: "https://dev-api.greatmanagerinstitute.com",
  lfiUrl: config.lfiUrlUat,
  version: "0.0.1",
  // apiURl: config.apiUrl
};
