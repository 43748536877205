import { environment } from "../../environments/environment";
import { Injectable } from "@angular/core";

import { HttpClient, HttpParams } from "@angular/common/http";
import { Router } from "@angular/router";

// import "rxjs/add/operator/map";
import { BehaviorSubject, ReplaySubject, map } from "rxjs";
import { Observable, Subject } from "rxjs";
// import 'rxjs/add/operator/map';
// import { BehaviorSubject, ReplaySubject } from "rxjs";
import { param } from "jquery";
// import { Response } from "_debugger";

import { BsModalRef } from "ngx-bootstrap/modal";

@Injectable()
export class CompanyServices {
  activeModals: BsModalRef[] = [];
  // Subject type for service uses
  public managerData = new Subject<any>();
  public managerData1 = new ReplaySubject<any>();
  public selectedmanagersForPackages = new Subject<any>();
  public selectedManagers$ = this.selectedmanagersForPackages.asObservable();
  public counts = new ReplaySubject<any>();
  public refreshComponent = new Subject<any>();
  public countChecker = new ReplaySubject<any>();
  public statusTrack = new ReplaySubject<any>();
  // End
  public serverUrl = environment.apiReadUrl;
  public managerApiUrl = "/api/v2/surveyEngineRequest";
  public commonUserApiUrl = "/api/v2/user";
  public analyticsApiUrl = "/api/v2/analytics";
  public superAdminApiUrl = "/api/v2/superadmin";
  public companyUserApiUrl = "/api/v2/company";
  public companyNudgeAPI = "/api/v2/nudge";
  public realTimeApiUrl = "https://rtf-api.greatmanagerinstitute.com/api/";
  public managerUrl = "/api/v2/manager";
  public manager;
  public popUpShow = new Subject<any>();
  batchOptions: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  public companySettings: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private modalOpenSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private userListSubject = new BehaviorSubject<any[]>([]);
  public userList$: Observable<any[]> = this.userListSubject.asObservable();
  constructor(private http: HttpClient, private router: Router) {}

  showPopUpModel(data) {
    this.popUpShow.next(data);
  }

  roleGuard() {
    // //console.log(localStorage.getItem("role"))
    if (localStorage.getItem("role") != "company") {
      return false;
    } else {
      return true;
    }
  }
  updateCompanyNudgeSettings(data) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/updateCompanyNudgeSettings",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getallMentors(allmentor) {
    return this.http.get(
      this.serverUrl + this.managerUrl + "/getDigitalMentors",
      allmentor
    );
  }

  assignCompanyMentors(data) {
    return this.http
      .post(
        this.serverUrl +
          this.companyUserApiUrl +
          "/assignMentorFromCompanyAdmin",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  //sendEmailToParticipants
  sendEmailToParticipants(data) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/sendEmailToParticipants",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  //surveyReminderEmails
  surveyReminderEmails(data) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/surveyReminderEmails",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  //getEmailTemplates
  public getEmailTemplates(params) {
    return this.http
      .get(this.serverUrl + this.companyUserApiUrl + "/getEmailTemplates", {
        params: params,
      })
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getMentorReport(data) {
    return this.http
      .post(this.serverUrl + this.companyUserApiUrl + "/mentorReport", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  getNudgeConfiguration() {
    return this.http
      .get(this.serverUrl + this.companyUserApiUrl + "/getNudgeConfiguration")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  updateCategoryCompanyWeight(data) {
    return this.http
      .post(
        this.serverUrl +
          this.companyUserApiUrl +
          "/updateCategoryCompanyWeight",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  updateNudgeEligibility(data) {
    return this.http
      .post(
        this.serverUrl + this.commonUserApiUrl + "/updateNudgeEligibility",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  //scheduleEmail
  public scheduleEmail(params) {
    return this.http
      .post(this.serverUrl + this.companyUserApiUrl + "/scheduleEmail", params)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  //get Ongoing Surveys
  public getSurvey() {
    return this.http
      .get(this.serverUrl + this.companyUserApiUrl + "/getSurvey")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getExistingManagers(data) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/getExistingManagers",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  //Edit Surveys
  public extendSurvey(data) {
    return this.http
      .post(this.serverUrl + this.companyUserApiUrl + "/extendSurvey", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  //extend All Surveys
  public extendAllSurveys(data) {
    return this.http
      .post(this.serverUrl + this.companyUserApiUrl + "/extendAllSurveys", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  public addParticipantToSurvey(data) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/addParticipantToSurvey",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  public getSpocs() {
    return this.http
      .get(this.serverUrl + this.companyUserApiUrl + "/getSpocs")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  public sendEmailToSpocs(data) {
    return this.http
      .post(this.serverUrl + this.companyUserApiUrl + "/sendEmailToSpocs", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  public getScheduledEmails() {
    return this.http
      .get(this.serverUrl + this.companyUserApiUrl + "/getScheduledEmails")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  /*
  Description: getting all existing managers
  Date Of Creation: 11-1-2019
  Author: Aman Kumar Singh
  */

  getAllExistingManagers() {
    return this.http
      .get(this.serverUrl + this.companyUserApiUrl + "/getAllExistingManagers")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /*
  Description: Updating data for manager profile
  Date Of Creation: 11-1-2019
  Author: Aman Kumar Singh
  */
  updateProfileManager(data) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/updateProfileManager",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  changeManagerTypeToB2b(data) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/changeManagerTypeToB2b",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /*
  Description:API to fetch all the RTF details if exists
  Date Of Creation: 19-1-2024
  Author: Deepender Rana
  */

  fetchRTFExistingData(data: any) {
    return this.http
      .get(this.serverUrl + this.companyUserApiUrl + "/fetchRTFExistingData", {
        params: data,
      })
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /*
  Description: Adding participant from manager
  Date Of Creation: 11-1-2019
  Author: Aman Kumar Singh
  */

  addParticipantToManagers(data) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/addParticipantToManagers",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /*
  Description: Deleting participatings from Manager
  Date Of Creation: 11-1-2019
  Author: Aman Kumar Singh
  */

  deleteParticipantsToManager(data) {
    return this.http
      .post(
        this.serverUrl +
          this.companyUserApiUrl +
          "/deleteParticipantsToManager",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /*
  Description: Requesting to managers for merging data with managers
  Date Of Creation: 11-1-2019
  Author: Aman Kumar Singh
  */

  askForMerging(data) {
    return this.http
      .post(this.serverUrl + this.companyUserApiUrl + "/askForMerging", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /*
  Description: Apporving to managers for merging data with managers
  Date Of Creation: 11-1-2019
  Author: Aman Kumar Singh
  */

  approveForMergingData(data) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/approveForMergingData",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /*
  Description:getting company profile
  Date Of Creation: 11-1-2019
  Author: Aman Kumar Singh
  */

  getCompanyProfile(data) {
    //console.log(data);
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/getCompanyProfile",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  /*
  Description: forgot Password
  Date Of Creation: 11-1-2019
  Author: Aman Kumar Singh
  */

  forgotPassword(data) {
    return this.http
      .post(this.serverUrl + this.companyUserApiUrl + "/forgotPassword", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /*
  Description: Reset Password
  Date Of Creation: 11-1-2019
  Author: Aman Kumar Singh
  */

  resetPassword(data) {
    return this.http
      .post(this.serverUrl + this.companyUserApiUrl + "/resetPassword", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  /*
  Description: Adding individual managers
  Date Of Creation: 11-1-2019
  Author: Aman Kumar Singh
  */
  addIndividualManager(data) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/addIndividualManager",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  /*
  Description: Submitting the employee Data
  Date Of Creation: 10-1-2019
  Author: Krantikumar
  */
  submitEmployeeData(data) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/submitEmployeeData",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  /*
  Description: Assign packages to managers
  Date Of Creation: 11-1-2019
  Author: Aman Kumar Singh
  */
  assignPackagesToManager(data) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/assignPackagesToManager",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /*
     Description: Getting All Action Plans
     Date Of Creation: 11-1-2019
     Author: Aman Kumar Singh
    */

  getActionPlans() {}

  /*
  Description: Using as data source for talking with parent to child component
  Date Of Creation: 11-1-2019
  Author: Aman Kumar Singh
  Start
  */
  postCompanyAddedManagerList(data, clearData = false) {
    this.managerData.next(data);
  }
  postIndividualAddedManagerList(data, clearData = false) {
    this.managerData1.next(data);
  }
  collectSelectedManagers(data) {
    this.selectedmanagersForPackages.next(data);
  }
  countOfAllPackages(data: any) {
    this.counts.next(data);
  }
  getCompanyStatics(data) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/getCompanyStatics",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  refreshAllComponent(data) {
    this.refreshComponent.next(data);
  }
  countCheck(data) {
    this.countChecker.next(data);
  }
  statusTracer(data) {
    this.statusTrack.next(data);
  }
  /**End Here */

  /*
  Description:checking Email Address
  Date Of Creation: 11-1-2019
  Author: Aman Kumar Singh
  */

  checkingEmailAddress(data) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/checkingEmailAddress",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  sendEmailToManagers(data) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/sendEmailToManagers",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  revokeManagers(data) {
    return this.http
      .post(this.serverUrl + this.companyUserApiUrl + "/revokeManagers", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getTokenForImpersonate(params) {
    return this.http
      .post(
        this.serverUrl + this.commonUserApiUrl + "/getImpersonateAccess/",
        params
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getActionPlanBank(data) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/getActionPlanBank",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  addActionPlanToBank(data) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/addActionPlanToBank",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  getManagerActionPlanning(mgrId) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/getManagerActionPlanning",
        { mgrId: mgrId }
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getManagerSurvey(mgrId) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/getManagerSurvey", {
        mgrId: mgrId,
      })
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  getSasInfo(id) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/getSasInfo", { id })
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getCompanyManagersReports(params = {}) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/getCompanyManagersReports",
        params
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getSurveyMasterReport(params = {}) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/getSurveyMasterReport",
        params
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getCompanyScoreSheetReport(params = {}) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/getCompanyScoreSheetReport",
        params
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  getBatchDetails() {
    return this.http
      .post(this.serverUrl + this.companyUserApiUrl + "/getBatchDetails", "")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  loadBatchData(data) {
    return this.http
      .post(this.serverUrl + this.companyUserApiUrl + "/loadBatchData", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  addActionPlanToBankBulk(data) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/addActionPlanToBankBulk",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  disableRAP() {
    return this.http
      .post(this.serverUrl + this.companyUserApiUrl + "/disableRAP", "")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getManagers(data) {
    return this.http
      .post(this.serverUrl + this.companyUserApiUrl + "/getManagers", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  launchSurvey(data) {
    return this.http
      .post(this.serverUrl + this.companyUserApiUrl + "/launchSurvey", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  //Add Survey Details from Company Admin
  addSurveyDetails(data) {
    return this.http
      .post(
        this.serverUrl +
          this.companyUserApiUrl +
          "/launchSurveysFromCompanyAdminV2",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  //
  // change
  getResponseRate(params = {}) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/getResponseRate",
        params
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getLeadershipReport(params = {}) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/getLeadershipReport",
        params
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  // toggleScores
  toggleScores(data) {
    return this.http
      .post(this.serverUrl + this.companyUserApiUrl + "/toggleScores", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  // toggle Comments
  toggleComments(data) {
    return this.http
      .post(this.serverUrl + this.companyUserApiUrl + "/toggleComments", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  // getL_DStatus

  getL_DStatus() {
    return this.http
      .get(this.serverUrl + this.companyUserApiUrl + "/getL_DStatus")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getAp(params = {}) {
    return this.http
      .post(this.serverUrl + this.companyUserApiUrl + "/getAp", params)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  //update Survey Name
  updateSurveyName(data) {
    return this.http
      .post(this.serverUrl + this.companyUserApiUrl + "/updateSurveyName", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  getApReviewFormat(params = {}) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/getApReviewFormat",
        params
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  // downloadComment
  // downloadComment(){
  //   return this.http
  //   .get(
  //     this.serverUrl + this.managerUserApiUrl + "/getSurveyComments"
  //   )
  //   .map((response: Response) => {
  //     return <any>response;
  //   });
  // }
  addCustomLandD() {
    return this.http
      .get(this.serverUrl + this.companyUserApiUrl + "/addCustomLandD")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  // getAssessmentScores
  getAssessmentScores() {
    return this.http
      .get(this.serverUrl + this.companyUserApiUrl + "/getAssessmentScores")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getLearningParticipantsData(params = {}) {
    return this.http
      .post(
        this.serverUrl +
          this.companyUserApiUrl +
          "/getLearningParticipantsData",
        params
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getAllPossibleFilters(params = {}) {
    return this.http
      .get(this.serverUrl + this.companyUserApiUrl + "/getAllPossibleFilters", {
        params: params,
      })
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getLearningCompletionStatus(params = {}) {
    return this.http
      .post(
        this.serverUrl +
          this.companyUserApiUrl +
          "/getLearningCompletionStatus",
        params
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getLearningTierReport(params = {}) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/getLearningTierReport",
        params
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getLearningModuleWiseReport(params) {
    return this.http
      .post(
        this.serverUrl +
          this.companyUserApiUrl +
          "/getLearningModuleWiseReport",
        params
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getLearningMysteryBoxList(params = {}) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/getLearningMysteryBoxList",
        params
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getLearningTopListReport(params = {}) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/getLearningTopListReport",
        params
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getLearningMasterSheet(params = {}) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/getLearningMasterSheet",
        params
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  // getApReports
  getApReports(params = {}) {
    return this.http
      .post(this.serverUrl + this.companyUserApiUrl + "/getApReports", params)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  // getManagerActionPlanReports
  getManagerActionPlanReports() {
    return this.http
      .get(
        this.serverUrl + this.companyUserApiUrl + "/getManagerActionPlanReports"
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getSummaryReport(params) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/getSummaryReport",
        params
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  getRegisteredAndSasManager() {
    return this.http
      .get(
        this.serverUrl + this.companyUserApiUrl + "/getRegisteredAndSasManager"
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  // launchOnlyAp
  launchOnlyAp(data) {
    return this.http
      .post(this.serverUrl + this.companyUserApiUrl + "/launchOnlyAp", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  getSupervisorlists() {
    return this.http
      .get(this.serverUrl + this.companyUserApiUrl + "/getSupervisorlists")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  sendMailsToSuperVisor(data) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/sendMailsToSuperVisor",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  getIuReports() {
    return this.http
      .get(this.serverUrl + this.companyUserApiUrl + "/getIuReports")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  getCompanyWiseSurvey() {
    return this.http
      .get(this.serverUrl + this.companyUserApiUrl + "/getCompanyWiseSurvey")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getCompanyReport(data) {
    return this.http
      .post(this.serverUrl + this.companyUserApiUrl + "/getCompanyReport", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  getCompanyReportBlob(data) {
    return this.http.post(
      this.serverUrl + this.companyUserApiUrl + `/getCompanyReport`,
      data,
      {
        responseType: "blob",
      }
    );
  }
  // getSurveyById
  getSurveyById(data) {
    return this.http
      .post(this.serverUrl + this.companyUserApiUrl + "/getSurveyById", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  // updateSurvey
  updateSurvey(data) {
    return this.http
      .post(this.serverUrl + this.companyUserApiUrl + "/updateSurvey", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  // /getEmail/:type
  getEmail(data) {
    return this.http
      .get(this.serverUrl + this.companyUserApiUrl + `/getEmail/${data.type}`)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  // smstemplates
  smstemplates() {
    return this.http
      .get(this.serverUrl + this.companyUserApiUrl + `/smstemplates`)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  scheduleSms(companySurveyGroupId: string) {
    return this.http.get(
      this.serverUrl +
        this.companyUserApiUrl +
        "/scheduleSms/" +
        companySurveyGroupId
    );
  }
  deactivateSms(id: string) {
    return this.http.get(
      this.serverUrl + this.companyUserApiUrl + "/deactivateSms/" + id
    );
  }
  // createScheduleMail
  createScheduleMail(data) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + `/createScheduleMail/`,
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  createSMSSchedule(data) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + `/createSMSSchedule`,
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  // getManagerMisReport
  downloadManagerStatusReport() {
    return this.http
      .get(
        this.serverUrl +
          this.companyUserApiUrl +
          `/downloadManagerStatusReport/`
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  // getManagerPIS
  getManagerPIS() {
    return this.http.get(
      this.serverUrl + this.companyUserApiUrl + `/getPISData`,
      {
        responseType: "blob",
      }
    );
  }

  // toggleScoresByCompanySurveyId
  toggleScoresByCompanySurveyId(data) {
    return this.http
      .post(
        this.serverUrl +
          this.companyUserApiUrl +
          `/toggleScoresByCompanySurveyId/`,
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  // checkDataValidation
  checkDataValidation(data) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/checkDataValidation",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getScheduleEmailByCompanyId(data) {
    return this.http
      .post(
        this.serverUrl +
          this.companyUserApiUrl +
          "/getScheduleEmailByCompanyId",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  // checkDataValidation
  getCompanyDemographics(data) {
    return this.http
      .get(
        this.serverUrl +
          this.companyUserApiUrl +
          `/getCompanyDemographics/${data.type}`
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  updateScheduleEmailByCompanyId(data) {
    return this.http
      .post(
        this.serverUrl +
          this.companyUserApiUrl +
          "/updateScheduleEmailByCompanyId",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getAllBenchmarks() {
    return this.http
      .get(this.serverUrl + this.companyUserApiUrl + "/getAllBenchmarks")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  mapSurveyGrpWithBenchmark(data) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/mapSurveyGrpWithBenchmark",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  getOrgReportLink(data) {
    return this.http
      .post(this.serverUrl + this.companyUserApiUrl + "/getOrgReportLink", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  submitGmiFiTFeedback(data) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/submitGmiFiTFeedback",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  //addSubAreasToManagers",

  addSubAreasToManagers(data) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/addSubAreasToManagers",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  /*
  Description:assign batch
  Date Of Creation: 11-12-2020
  Author: Hamza Shaikh
  */
  assignBatch(data) {
    return this.http
      .post(this.serverUrl + this.companyUserApiUrl + "/assignBatch", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  /*
  Description:add goals
  Date Of Creation: 20-12-2020
  Author: Hamza Shaikh
  */
  addGoalsToManagers(data) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/addGoalsToManagers",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  /*
  Description:getApforms
  Author: Hamza Shaikh
  */
  getApForms(params = {}) {
    return this.http
      .post(this.serverUrl + this.companyUserApiUrl + "/getApForms", params)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  /*
  Description:getApforms
  Author: Hamza Shaikh
  */
  assignApForms(params = {}) {
    return this.http
      .post(this.serverUrl + this.companyUserApiUrl + "/assignApForms", params)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  masterReport(data) {
    return this.http
      .post(this.serverUrl + this.companyUserApiUrl + "/masterReport", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getAuditReport(data) {
    return this.http
      .post(this.serverUrl + this.companyUserApiUrl + "/getAuditReport", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  // getAllRequestType(){
  //   return this.http
  //   .get(this.serverUrl + this.companyUserApiUrl + "/getAllRequestType")
  //   .map((response: Response) => {
  //     return <any>response;
  //   });
  // }

  getRequestFromHr(data) {
    return this.http
      .post(this.serverUrl + this.companyUserApiUrl + "/getRequestFromHr", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getRequestDataAndType() {
    return this.http
      .get(this.serverUrl + this.companyUserApiUrl + "/getRequestDataAndType")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  requestActions(data) {
    return this.http
      .post(this.serverUrl + this.companyUserApiUrl + "/requestActions", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getSubthemes(data) {
    return this.http
      .post(this.serverUrl + this.managerApiUrl + "/getSubthemes", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getSurveyQuestionsTemplate() {
    return this.http
      .get(this.serverUrl + this.managerApiUrl + "/getSurveyQuestionsTemplate")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getSurveyGroupNudge(data) {
    return this.http
      .post(
        this.serverUrl + this.companyNudgeAPI + "/getSurveyGroupNudge",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getNudgesGoals() {
    return this.http
      .get(this.serverUrl + this.companyNudgeAPI + "/getNudgeGoalList")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  companyNudgeGoals(data) {
    return this.http
      .post(this.serverUrl + this.companyNudgeAPI + "/companyNudgeGoals", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  uploadActionPlansFromCompanyAdmin(data) {
    return this.http
      .post(
        this.serverUrl +
          this.companyUserApiUrl +
          "/uploadActionPlansFromCompanyAdmin",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getTwoByTwo(data) {
    return this.http.post(
      this.serverUrl + this.companyUserApiUrl + "/getTwoByTwo",
      data
    );
  }
  pauseRtfSurvey(data: any) {
    return this.http.post(
      this.serverUrl + this.companyUserApiUrl + "/pauseRealTimeFeedback",
      data
    );
  }
  resumeRtfSurvey(data: any) {
    return this.http.post(
      this.serverUrl + this.companyUserApiUrl + "/resumeRealTimeFeedback",
      data
    );
  }

  checkRtfSurveyIsPresent(companyId: number) {
    return this.http.get(
      this.realTimeApiUrl +
        `GMI/Surveys/GetSurveyByClient?clientId=${companyId}`
    );
  }

  getCompanyWhatsappTemplate(type: string) {
    return this.http.get(
      this.serverUrl +
        this.companyUserApiUrl +
        "/getCompanyWhatsappTemplate/" +
        type
    );
  }

  createScheduledWhatsapp(data: any) {
    return this.http.post(
      this.serverUrl + this.companyUserApiUrl + "/createScheduledWhatsapp",
      data
    );
  }

  addCompanyScheduleEmail(data: any) {
    return this.http.post(
      this.serverUrl +
        this.superAdminApiUrl +
        "/addLeaderboardReportCompanyScheduleEmail",
      data
    );
  }

  getScheduledWhatsapp(companySurveyGroupId: string) {
    return this.http.get(
      this.serverUrl +
        this.companyUserApiUrl +
        "/getScheduledWhatsapp/" +
        companySurveyGroupId
    );
  }

  deleteScheduledWhatsapp(id: string) {
    return this.http.delete(
      this.serverUrl + this.companyUserApiUrl + "/deleteScheduledWhatsapp/" + id
    );
  }

  getCompanyScheduleEmails(params) {
    return this.http
      .get(
        this.serverUrl + this.superAdminApiUrl + "/getCompanyScheduleEmails",
        {
          params: params,
        }
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  updateCompanyScheduleEmail(data: any) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/updateCompanyScheduleEmail",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /* getUserList(id, type) {
      if (type === "company") {
        return this.http
          .post(this.serverUrl + this.analyticsApiUrl + "/getUserList", {
            companyId: id,
          })
          .map((response: Response) => {
            return <any>response;
          });
      } else {
        return this.http
          .post(this.serverUrl + this.analyticsApiUrl + "/getUserList", {
            userId: id,
          })
          .map((response: Response) => {
            return <any>response;
          });
      }
    }*/
  getUserList(id, type) {
    const data = type === "company" ? { companyId: id } : { userId: id };

    return this.http
      .post(this.serverUrl + this.analyticsApiUrl + "/getUserList", data)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getLADUserList(id, type) {
    const data = type === "company" ? { companyId: id } : { userId: id };
    return this.http
      .post(this.serverUrl + this.analyticsApiUrl + "/getUserList", data)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
  // getSubThemeandDemographics(id: string) {
  //   return this.http
  //     .get(
  //       this.serverUrl +
  //         this.analyticsApiUrl +
  //         "/getSubThemeandDemographics/" +
  //         id
  //     )
  //     .pipe(
  //       map((response: Response) => {
  //         return <any>response;
  //       })
  //     );
  // }

  getSubThemeandDemographics(companyId?, surevyId?) {
    return this.http
      .get(
        this.serverUrl +
          this.analyticsApiUrl +
          "/getSubThemeandDemographics" +
          "/" +
          companyId +
          "/" +
          surevyId
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  getCompanyComponentMappingData(data: any) {
    return this.http
      .get(
        this.serverUrl +
          this.companyUserApiUrl +
          "/getCompanyComponentMappingData",
        {
          params: data,
        }
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  addUser(data) {
    return this.http
      .post(this.serverUrl + this.analyticsApiUrl + "/createUser", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  editUser(data) {
    return this.http
      .put(this.serverUrl + this.analyticsApiUrl + "/updateUser", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  deleteUser(id: string) {
    return this.http.delete(
      this.serverUrl + this.analyticsApiUrl + "/deleteUser/" + id
    );
  }

  // getComponentData(id: string) {
  //   return this.http.get(
  //     this.serverUrl + this.analyticsApiUrl + "/getAllComponentDetails/" + id
  //   );
  // }

  getComponentData(id?) {
    return this.http.get(
      this.serverUrl + this.analyticsApiUrl + "/getAllComponentDetails"
    );
  }

  addMapping(data) {
    return this.http
      .post(
        this.serverUrl + this.analyticsApiUrl + "/createComponentView",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  updateCompanyComponentMappingData(data: any) {
    return this.http
      .post(
        this.serverUrl +
          this.companyUserApiUrl +
          "/updateCompanyComponentMappingData",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  editMapping(data) {
    return this.http
      .put(
        this.serverUrl + this.analyticsApiUrl + "/updateComponentViewMapping",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  getAllCustomPages() {
    return this.http
      .get(this.serverUrl + this.companyUserApiUrl + "/getAllCustomPages")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  updateThemeData(data) {
    return this.http
      .post(this.serverUrl + this.companyUserApiUrl + "/updateThemeData", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  addSettings(data) {
    return this.http
      .post(this.serverUrl + this.companyUserApiUrl + "/addPageSettings", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  updateNudgeConfiguration(data) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/updateNudgeConfiguration",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  updateNudgeCategoryCompanyWeight(data) {
    return this.http
      .post(
        this.serverUrl +
          this.companyUserApiUrl +
          "/updateNudgeCategoryCompanyWeight",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  updateNudgeSetting(data) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/updateNudgeSetting",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  updateLADUserList(users: any[]) {
    this.userListSubject.next(users);
  }

  sendBulkEmailLad(users: any) {
    return this.http.post(
      this.serverUrl + this.analyticsApiUrl + "/sendUserEmails",
      users
    );
  }
  sendBulkEmailLadUser(body: any) {
    return this.http.post(
      this.serverUrl + this.analyticsApiUrl + "/sendBulkLoginMails",
      body
    );
  }
  generateGpmsOrgReport(reportData: GpmsReportBody) {
    //localhost:3500/api/v2/company/generateOrgReport
    http: return this.http.post(
      this.serverUrl + this.companyUserApiUrl + "/generateOrgReport",
      reportData
    );
  }
  toggleModal(open: boolean): void {
    this.modalOpenSubject.next(open);
  }
  getActiveModals(): BsModalRef[] {
    return this.activeModals;
  }
  addModal(modalRef: BsModalRef): void {
    this.activeModals.push(modalRef);
  }
  removeModal(modalRef: BsModalRef): void {
    const index = this.activeModals.indexOf(modalRef);
    if (index !== -1) {
      this.activeModals.splice(index, 1);
    }
    this.activeModals = [];
  }
  postPerformanceScores(data) {
    return this.http
      .post(
        this.serverUrl +
          this.companyUserApiUrl +
          "/insertIntoManagerCustomScore",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getOrgReport(data) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/getOrganisationReportV2",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  changeRTFMailStatus(data) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/changeRTFMailStatus",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getSurveyResponseRateV2(data) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/getSurveyResponseRateQueue",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  getMasterSurveyResponseRateV2(data) {
    return this.http
      .post(
        this.serverUrl +
          this.companyUserApiUrl +
          "/getMasterSurveyResponseRateQueue",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getSurveyScoreSheetV2(data) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/getSurveyScoreSheetQueue",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getOrgReportNew(data) {
    return this.http
      .post(
        this.serverUrl + this.companyUserApiUrl + "/getOrganisationReportQueue",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  updateCompanyGMCData(data) {
    return this.http
      .post(this.serverUrl + this.companyUserApiUrl + "/updateCompanyGMCData", {
        data,
      })
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  // assignNudgesToManagers
  assignNudgesToManagers(data) {
    return this.http
      .post(this.serverUrl + "/api/v2/nudge2/assignNudgesToManagers", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getLadEmailType() {
    return this.http
      .get(this.serverUrl + this.analyticsApiUrl + "/getLadEmailType")
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
  getAllSurveyGroup(companyId: number) {
    return this.http
      .get(
        this.serverUrl +
          this.analyticsApiUrl +
          "/getAllSurveyGroup" +
          "/" +
          companyId
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getRoleAccessData(companyId: number, surveyGroupId: number) {
    return this.http
      .get(
        this.serverUrl +
          this.analyticsApiUrl +
          "/getRoleAccessData" +
          "/" +
          companyId +
          "/" +
          surveyGroupId
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
  getUserSurveyGroup(userId: number) {
    return this.http
      .get(
        this.serverUrl +
          this.analyticsApiUrl +
          "/getUserSurveyGroup" +
          "/" +
          userId
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
  getCompanyConfigData(data: any) {
    return this.http
      .get(this.serverUrl + this.analyticsApiUrl + "/getCompanyConfigData", {
        params: data,
      })
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  editCompanyConfigData(data) {
    return this.http
      .put(
        this.serverUrl + this.analyticsApiUrl + "/updateCompanyConfigData",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getUserBenchmarkMapping(data: any) {
    return this.http.get(
      this.serverUrl + this.analyticsApiUrl + "/userBenchmarkMapping",
      {
        params: data,
      }
    );
  }

  saveUserBenchmarkMapping(data: any) {
    return this.http.post(
      this.serverUrl + this.analyticsApiUrl + "/userBenchmarkMapping",
      data
    );
  }

  getUserDemographics(data: any) {
    return this.http.get(
      this.serverUrl + this.companyUserApiUrl + "/getUserDemographics",
      { params: data }
    );
  }
}

export interface BenchmarkId {
  id: number;
}
export interface GpmsReportBody {
  surveyGroupId: string;
  benchmarkId: BenchmarkId[];
  seCompanyId: number;
}
