import { Component, OnInit, ChangeDetectorRef, ViewChild } from "@angular/core";
import { Route, ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { AdminServices } from "../admin.service";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ImageTransform, Dimensions } from "ngx-image-cropper";
import * as XLSX from "xlsx";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { CERTIFICATE_TEXT, constants } from "../../../app/constants";

import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Editor, Toolbar } from "ngx-editor";

export interface EmailData {
  from: string;
  subject: string;
  content: string;
}

@Component({
  selector: "app-certificate-generation",
  templateUrl: "./certificate-generation.component.html",
  styleUrls: ["./certificate-generation.component.css"],
})
export class CertificateGenerationComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  certificationDataForm!: FormGroup;
  stripColorOne: string = "#D30000";
  stripColorTwo: string = "#1E1E1E";
  headDetails: FormArray;
  modalRef: BsModalRef;
  companyName: string;
  seCompanyId: string;
  imageChangedEvent: any = "";
  programLogoChangedEvent: any = "";
  croppedImage: any = "";
  viewMode: number = 1;
  zoom: number = 1;
  gmiHeadData = [];
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  logoInDBExists = false;
  programLogoInDBExists = false;
  certificate = constants.IMAGE_URL + "Certificate/GMC+Certificate-+02.png";
  certifiedManagerList: any = new MatTableDataSource();
  signatures: any = [];
  isAssetesUploaded: boolean = false;
  displayedColumns: string[] = [
    "managerName",
    "managerEmailId",
    "personsInCC",
    "actions",
  ];
  emailData: EmailData = {
    from: "",
    subject: "",
    content: "",
  };
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: false,
    height: "auto",
    minHeight: "500px",
    maxHeight: "auto",
    width: "auto",
    translate: "true",
    enableToolbar: true,
    showToolbar: true,
    defaultParagraphSeparator: "",
    defaultFontName: "",
    defaultFontSize: "",
    fonts: [
      { class: "arial", name: "Arial" },
      { class: "times-new-roman", name: "Times New Roman" },
      { class: "calibri", name: "Calibri" },
      { class: "comic-sans-ms", name: "Comic Sans MS" },
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
    uploadUrl: "v1/image",
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: "top",
    toolbarHiddenButtons: [["insertImage", "insertVideo"]],
  };
  gmiHeadSelected = 1;
  companyHeadSeleted = 0;
  defaultCertificateName = constants.DEFAULT_SIGN_CERTIFICATE;
  managerName: string = "";
  iconSize: any;
  isIconValid: boolean = false;
  isProgramLogoValid: boolean = false;
  iconSizeStatus: string;
  programLogoSizeStatus: string;
  index: number = 0;
  sendCertificate: boolean = false;
  choosedOption: boolean = false;
  scheduleCertificate: boolean = false;
  sendOrScheduleEmail: string = "";
  scheduledFor: string = "";
  editor: Editor;
  toolbar: Toolbar = [
    ["bold", "italic"],
    ["underline", "strike"],
    ["code", "blockquote"],
    ["ordered_list", "bullet_list"],
    [{ heading: ["h1", "h2", "h3", "h4", "h5", "h6"] }],
    ["link", "image"],
    ["text_color", "background_color"],
    ["align_left", "align_center", "align_right", "align_justify"],
  ];
  constructor(
    private modalService: BsModalService,
    private adminService: AdminServices,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) {}

  ngOnInit() {
    this.editor = new Editor();
    this.companyName = this.route.snapshot.params["name"];
    this.seCompanyId = this.route.snapshot.params["id"];
    this.inItcertificationDataForm();
    this.getCompanyAssets();
    this.getEmailContent();
  }

  getCompanyAssets() {
    let dataObj = { seCompanyId: this.seCompanyId };
    this.adminService.getCompanyAssets(dataObj).subscribe((res: any) => {
      if (res.status) {
        this.setValuesFromDB(res);
      }
    });
  }

  inItcertificationDataForm() {
    this.certificationDataForm = this.fb.group({
      logo: ["", Validators.required],
      programLogo: [""],
      certificateIssueDate: "",
      certificateText: [
        CERTIFICATE_TEXT,
        [
          Validators.required,
          Validators.maxLength(230),
          Validators.minLength(20),
        ],
      ],
      headDetails: this.fb.array([this.createHeadDataGroup()]),
      gmiHeadDetails: this.fb.array([]),
    });
  }

  setValuesFromDB(res) {
    if (res.data.companyLogo) {
      this.logoInDBExists = true;
      this.isIconValid = true;
      this.certificationDataForm
        .get("logo")
        .setValue(`${constants.IMAGE_URL}${res.data.companyLogo}`);
    }

    if (res.data.programLogo) {
      this.programLogoInDBExists = true;
      this.isProgramLogoValid = true;
      this.certificationDataForm
        .get("programLogo")
        .setValue(`${constants.IMAGE_URL}${res.data.programLogo}`);
    }

    //to put the values of GMI heads in FormArray
    res.data.gmiHeadData.forEach((head) => {
      (this.certificationDataForm.get("gmiHeadDetails") as FormArray).push(
        this.fb.group({
          signImage: `${constants.IMAGE_URL}${head.signImage}`,
          name: head.name,
          designation: head.designation,
        })
      );
      head.name == "Dalreen Patro" &&
        this.signatures.push({
          signImage: `${constants.IMAGE_URL}${head.signImage}`,
          name: head.name,
          designation: head.designation,
        });
    });

    if (res.data.companyHeadData.length > 0) {
      const headDetails = this.certificationDataForm.get(
        "headDetails"
      ) as FormArray;
      if (headDetails) {
        while (headDetails.length !== 0) {
          headDetails.removeAt(0);
        }
      }
      res.data.companyHeadData.forEach((head) => {
        headDetails.push(
          this.fb.group({
            signImage: `${constants.IMAGE_URL}${head.signImage}`,
            name: head.name,
            designation: head.designation,
          })
        );
      });
    }
  }

  onFileSelectedSigns(event: any, index: number) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e: any) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const MAX_WIDTH = 200;
          const MAX_HEIGHT = 160;
          let width = img.width;
          let height = img.height;
          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.fillStyle = "#fff";
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(img, 0, 0, width, height);
          const dataUrl = canvas.toDataURL("image/jpeg", 1.0); // Change the quality here

          (this.certificationDataForm.get("headDetails") as FormArray).controls[
            index
          ]
            .get("signImage")
            .setValue(dataUrl);
        };
      };
    }
  }

  fileChangeEvent(event: any, identifier: string) {
    const maxSize = 1048576; // 1MB in bytes

    let imageChangedEvent: any;
    let isIconValid: boolean;
    let iconSizeStatus: string;

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const iconSize = file.size;

      if (iconSize > maxSize) {
        isIconValid = false;
        iconSizeStatus = "Icon size should be less than 1MB";
      } else {
        isIconValid = true;
        iconSizeStatus = "";

        const reader = new FileReader();
        reader.onload = (e: any) => {
          imageChangedEvent = e.target.result;

          switch (identifier) {
            case "companyLogo":
              this.certificationDataForm
                .get("logo")
                .setValue(imageChangedEvent);
              break;
            case "programLogo":
              this.certificationDataForm
                .get("programLogo")
                .setValue(imageChangedEvent);
              break;
          }
        };
        reader.readAsDataURL(file);
      }
    }

    if (identifier === "companyLogo") {
      this.iconSize = event.target.files[0].size;
      this.isIconValid = isIconValid;
      this.iconSizeStatus = iconSizeStatus;
      this.logoInDBExists = false;
    } else if (identifier === "programLogo") {
      this.iconSize = event.target.files[0].size;
      this.isProgramLogoValid = isIconValid;
      this.programLogoSizeStatus = iconSizeStatus;
      this.programLogoInDBExists = false;
    }
  }

  createHeadDataGroup(): FormGroup {
    return this.fb.group({
      name: ["", Validators.required],
      designation: ["", Validators.required],
      signImage: ["", Validators.required],
    });
  }

  removeHeadData(index: number): void {
    (this.certificationDataForm.get("headDetails") as FormArray).removeAt(
      index
    );
  }

  addNewHead() {
    (this.certificationDataForm.get("headDetails") as FormArray).push(
      this.createHeadDataGroup()
    );
  }

  submitFormData() {
    let sendObj = {
      companyName: this.companyName,
      companyLogo: this.certificationDataForm.get("logo").value,
      programLogo: this.certificationDataForm.get("programLogo").value,
      companyAssets: this.certificationDataForm.get("headDetails").value,
      seCompanyId: this.seCompanyId,
    };
    this.adminService
      .uploadCompanyAssets(sendObj)
      .subscribe((response: any) => {
        response && this.openSnackBar(response.message, response.status);
        if (response.status) {
          let currentUrl = this.router.url;
          this.router
            .navigateByUrl("/", { skipLocationChange: true })
            .then(() => {
              this.router.navigate([currentUrl]);
            });
        }
      });
  }

  closeModal() {
    this.modalRef.hide();
    this.modalService._hideModal(1);
  }

  openPreviewModal(template, managerName) {
    this.managerName = "";
    const config = {
      initialState: {},
      animated: true,
      class: "modal-lg",
    };
    this.modalRef = this.modalService.show(template, config);
    this.adminService.addModal(this.modalRef);
    this.managerName = managerName;
  }

  onFileChange(event: any) {
    const file: File = event.target.files[0];
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: "binary" });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      let data: any[] = XLSX.utils.sheet_to_json(ws, { header: 1 });
      const headers: any[] = data.shift();
      data = data.filter((data) => {
        return data.length > 0;
      });
      const output: any[] = data.map((row: any) => {
        const obj: any = {};
        row.forEach((cell: any, i: number) => {
          obj[headers[i]] = cell;
        });
        return obj;
      });
      this.certifiedManagerList.data = output;
      this.certifiedManagerList.paginator = this.paginator;
    };
    reader.readAsBinaryString(file);
  }

  exportExcel(data): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    this.saveAsExcelFile(excelBuffer, "Generated Certificate Manager List.");
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const fileUrl: string = URL.createObjectURL(data);
    const link: HTMLAnchorElement = document.createElement("a");
    link.href = fileUrl;
    link.download = `${fileName}.xlsx`;
    link.click();
  }

  addSignature(event, signatureData, type, indexNumber) {
    if (event.target.checked) {
      if (type == "company") {
        this.companyHeadSeleted += 1;
        this.signatures.unshift({ ...signatureData, index: this.index });
        this.index++;
        // if (this.signatures[indexNumber] == null) {
        // } else {
        //   this.signatures[indexNumber].name = signatureData.name;
        //   this.signatures[indexNumber].designation = signatureData.designation;
        // }
      } else {
        this.gmiHeadSelected += 1;
        this.signatures.push(signatureData);
      }
    } else {
      if (type == "company") {
        this.companyHeadSeleted -= 1;
      } else {
        this.gmiHeadSelected -= 1;
      }
      let index = this.signatures.findIndex((data: any) => {
        return data.name == signatureData.name;
      });
      index != -1 && this.signatures.splice(index, 1);
    }
  }

  getEmailContent() {
    this.adminService
      .getEmailTemplates({ emailType: "certificate_send" })
      .subscribe((res) => {
        if (res.status && res.data) {
          this.emailData.content = res.data.content;
          this.emailData.subject = res.data.subject;
          this.emailData.from = res.data.from;
        }
      });
  }

  openCrtificatePopup(template) {
    const config = {
      initialState: {},
      animated: true,
      class: "modal-md",
    };
    this.modalRef = this.modalService.show(template, config);
    this.adminService.addModal(this.modalRef);
  }

  generateCertificate() {
    const data = {
      sendOrScheduleEmail: this.sendOrScheduleEmail,
      scheduledFor: this.scheduledFor,
      emailData: this.emailData,
      managerList: this.certifiedManagerList.data,
      signatures: this.signatures,
      companyData: {
        companyName: this.companyName,
        companyLogo: this.certificationDataForm.value.logo,
        programLogo: this.certificationDataForm.value.programLogo,
        stripColorOne: this.stripColorOne,
        stripColorTwo: this.stripColorTwo,
        certificateText: this.certificationDataForm.value.certificateText,
        seCompanyId: this.seCompanyId,
        issueDate: this.certificationDataForm.value.certificateIssueDate,
      },
    };

    this.certifiedManagerList.data.length > 0 &&
      this.adminService.generateCertificate(data).subscribe((response: any) => {
        if (response.status) {
          this.exportExcel(response.data);
          this.openSnackBar(response.message, response.status);
          this.closeModal();
          this.router.navigate(["/admin/admin-dashboard/companies"]);
        }
      });
  }

  disableButton() {
    switch (this.gmiHeadSelected) {
      case 1:
        return this.companyHeadSeleted >= 1 && this.companyHeadSeleted < 4;
      case 2:
        return this.companyHeadSeleted >= 1 && this.companyHeadSeleted < 3;
    }
    return false;
  }

  openSnackBar(message, status) {
    this._snackBar.open(message, "", {
      duration: 2000,
      panelClass: status ? ["green-snackbar"] : ["red-snackbar"],
    });
  }

  splitValues(value: string): string[] {
    if (value) {
      return value.split(",").map((val) => val.trim() + "<br>");
    } else return [];
  }

  checkedIf(data) {
    return this.signatures.find((element) => {
      return data.name == element.name;
    });
  }

  sendOrSchedule(identifier) {
    this.choosedOption = true;
    this.sendOrScheduleEmail = identifier;
    switch (identifier) {
      case "send":
        this.sendCertificate = true;
        this.scheduleCertificate = false;
        break;
      case "schedule":
        this.sendCertificate = false;
        this.scheduleCertificate = true;
        break;
    }
  }

  deleteManager(index) {
    this.certifiedManagerList.data.splice(index, 1);
  }

  downloadSample() {
    const data = {
      managerName: this.certifiedManagerList.data[0].managerName,
      issueDate: this.certificationDataForm.value.certificateIssueDate,
      signatures: this.signatures,
      companyLogo: this.certificationDataForm.value.logo,
      programLogo: this.certificationDataForm.value.programLogo,
      stripColorOne: this.stripColorOne,
      stripColorTwo: this.stripColorTwo,
      certificateText: this.certificationDataForm.value.certificateText,
    };
    this.adminService
      .downloadCertificateSample(data)
      .subscribe((response: any) => {
        console.log(response, "pdfBuffer");
        const blob = new Blob([response], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Sample Certificate.pdf";
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }
  ngOnDestroy(): void {
    this.editor.destroy();
  }
}
