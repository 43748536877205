import { Component, OnInit, ViewChild } from "@angular/core";
import { CompanyServices } from "../../company.service";
import { GmcLearningService } from "../../../gmcLearning.service";
import * as XLSX from "xlsx";
// import * as jwt_decode from 'jwt-decode';
import { jwtDecode } from "jwt-decode";
import { ExcelService } from "../../../excel-service.service";
// import {
//   ApexAxisChartSeries,
//   ApexChart,
//   ChartComponent,
//   ApexDataLabels,
//   ApexXAxis,
//   ApexPlotOptions,
//   ApexYAxis,
//   ApexTitleSubtitle,
//   ApexFill,
//   ApexLegend,
//   ApexStroke,
//   ApexTooltip,
// } from 'ng-apexcharts';

// export type ChartOptions = {
//   series: ApexAxisChartSeries;
//   chart: ApexChart;
//   dataLabels: ApexDataLabels;
//   plotOptions: ApexPlotOptions;
//   xaxis: ApexXAxis;
// };
// export type tierReportChartOptions = {
//   series: ApexAxisChartSeries;
//   chart: ApexChart;
//   dataLabels: ApexDataLabels;
//   plotOptions: ApexPlotOptions;
//   yaxis: ApexYAxis;
//   xaxis: ApexXAxis;
//   fill: ApexFill;
//   colors: string[];
//   title: ApexTitleSubtitle;
// };
// export type moduleWiseChartOptions = {
//   series: ApexAxisChartSeries;
//   chart: ApexChart;
//   dataLabels: ApexDataLabels;
//   plotOptions: ApexPlotOptions;
//   yaxis: ApexYAxis;
//   xaxis: ApexXAxis;
//   fill: ApexFill;
//   tooltip: ApexTooltip;
//   stroke: ApexStroke;
//   legend: ApexLegend;
// };

@Component({
  selector: "app-l-d-reports",
  templateUrl: "./l-d-reports.component.html",
  styleUrls: ["./l-d-reports.component.scss"],
})
export class LDReportsComponent implements OnInit {
  reportType: String = "participantReport";
  participantReportData: any = [];
  moduleComplitionReportData: any = [];
  tierReportData: any = [];
  percentileReportData: any = [];
  moduleWiseReportData: any = [];
  mysteryBoxReportData: any = [];
  moduleWiseReportType: String = "module";
  topLearningReportData: any = [];
  topEngagedReportData: any = [];
  summaryReportdata: any = [];
  graphToggle: Boolean = false;
  // @ViewChild('moduleComplitionChart') chart!: ChartComponent;
  // @ViewChild('tierCompletionChart') tierChart!: ChartComponent;
  // @ViewChild('moduleWiseChart') moduleWiseChart!: ChartComponent;
  // public moduleWiseChartOptions!: Partial<moduleWiseChartOptions>;
  // public chartOptions!: Partial<ChartOptions>;
  // public tierChartOptions!: Partial<tierReportChartOptions>;
  dropdownList: any = [];
  selectedItems: any = [];
  dropdownSettings = {};
  dropdownSettings2 = {};
  loading: boolean = false;
  allPossibleFilters: any = [];
  finalDisplayFilters: any = [];
  totalManagers: Number = 0;
  summaryReportType = "";
  summaryFilter = "";
  summaryCellValue = "";
  companySelectedFeatures = [];

  constructor(
    private companyService: CompanyServices,
    private gmcLearningService: GmcLearningService,
    private excelService: ExcelService
  ) {}

  ngOnInit() {
    // this.loadReport(this.reportType);
    this.getPossibleFilters();
    this.dropdownSettings = {
      singleSelection: false,
      text: "Select Filters",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableSearchFilter: true,
      classes: "fieldFilterDropdown",
      maxHeight: 160,
    };
    this.dropdownSettings2 = {
      singleSelection: false,
      text: "Select Filters",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableSearchFilter: true,
      classes: "fieldFilterDropdown",
      maxHeight: 160,
    };
    this.checkForPackage();
  }

  checkForPackage() {
    if (localStorage.getItem("token") !== null) {
      let token: any = localStorage.getItem("token");
      let tokenData: any = jwtDecode(token);
      let selectedFeatures =
        typeof tokenData.selectedFeatures !== "undefined"
          ? tokenData.selectedFeatures
          : [];
      let tempSelected: any = [];
      if (selectedFeatures.length > 0) {
        selectedFeatures.forEach((feature: any) => {
          tempSelected.push(feature.type);
        });
        this.companySelectedFeatures = tempSelected;
      }
    }
  }

  onReloadReport() {
    this.loadReport(this.reportType);
  }

  loadReport(reportType: any) {
    this.graphToggle = false;
    switch (reportType) {
      case "participantReport":
        this.getLearningParticipantsData();
        break;
      case "moduleComplitionReport":
        this.getLearningCompletionStatus();
        break;
      case "tierReport":
        this.getLearningTierReport();
        break;
      case "moduleWiseReport":
        this.getLearningModuleWiseReport();
        break;
      case "mysteryBoxReport":
        this.getLearningMysteryBoxList();
        break;
      case "percentileReport":
        {
          this.getTopLearnerList();
          this.getTopEngagedList();
        }
        break;
      case "summaryReport":
        // this.getSummaryReport();
        break;
      case "masterReport":
        // this.downloadMasterExcel();
        break;
    }
  }

  toggleGraph() {
    this.graphToggle = !this.graphToggle;
  }

  changeModuleReportType(evt: any) {
    this.moduleWiseReportType = evt.target.value;
    switch (evt.target.value) {
      case "module":
        this.getLearningModuleWiseReport();
        break;
      case "theme":
        this.getLearningModuleWiseReport({ type: "themeWise" });
        break;
    }
  }

  onReportTypeChange(evt: any) {
    this.reportType = evt.target.value;
    // this.loadReport(evt.target.value);
  }

  getLearningParticipantsData(params: any = {}, excelDownload = false) {
    this.loading = true;
    params["filters"] = this.finalDisplayFilters;
    this.companyService
      .getLearningParticipantsData(params)
      .subscribe((res: any) => {
        if (res.status) {
          this.loading = false;
          if (excelDownload) {
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.data);
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
            this.excelService.exportAsExcelFile(wb, `Participant Report.xlsx`);
          } else {
            this.participantReportData =
              typeof res.data !== "undefined" ? res.data : [];
            this.totalManagers =
              typeof res.totalManagers !== "undefined" ? res.totalManagers : 0;
          }
        }
      });
  }

  getPossibleFilters() {
    this.loading = true;
    this.companyService.getAllPossibleFilters().subscribe((res: any) => {
      if (res.status) {
        this.loading = false;
        let data: any = res.data;
        // console.log('dataaaaaaaaaaaaa', data);

        data.forEach((filter: any) => {
          if (filter.options.length > 0) {
            this.dropdownList.push({
              id: filter.dbName,
              itemName: filter.filterName,
            });
            this.allPossibleFilters.push(filter);
          }
        });
        // console.log('dataaaaaaaaaaaaa', this.dropdownList);
      }
    });
  }

  getLearningCompletionStatus(params: any = {}, excelDownload = false) {
    this.loading = true;
    params["filters"] = this.finalDisplayFilters;
    this.companyService
      .getLearningCompletionStatus(params)
      .subscribe((res: any) => {
        if (res.status) {
          this.loading = false;
          if (excelDownload) {
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.data);
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
            this.excelService.exportAsExcelFile(
              wb,
              "Module Completion Report" + ".xlsx"
            );
          } else {
            let data: any = [];
            let categories: any = [];
            this.moduleComplitionReportData =
              typeof res.data !== "undefined" ? res.data : [];
            this.tierReportData =
              typeof res.data !== "undefined" ? res.data : [];
            res.data.forEach((element: any) => {
              data.push(element["% of Managers"]);
              categories.push(element["Range of Module Completion"]);
            });
            // this.tierChartOptions = {
            //   series: [
            //     {
            //       name: "% of Managers",
            //       data: data,
            //     },
            //   ],
            //   chart: {
            //     height: "auto",
            //     type: "bar",
            //   },
            //   plotOptions: {
            //     bar: {
            //       columnWidth: "40%",
            //       dataLabels: {
            //         position: "top", // top, center, bottom
            //       },
            //     },
            //   },
            //   dataLabels: {
            //     enabled: true,
            //     formatter: function (val) {
            //       return val + "";
            //     },
            //     offsetY: -20,
            //     style: {
            //       fontSize: "12px",
            //       colors: ["#304758"],
            //     },
            //   },
            //   xaxis: {
            //     categories: categories,
            //     position: "bottom",
            //     labels: {
            //       offsetY: 0,
            //       show: true,
            //     },
            //     axisBorder: {
            //       show: false,
            //     },
            //     axisTicks: {
            //       show: false,
            //     },
            //     crosshairs: {
            //       fill: {
            //         type: "gradient",
            //         gradient: {
            //           colorFrom: "#D8E3F0",
            //           colorTo: "#BED1E6",
            //           stops: [0, 100],
            //           opacityFrom: 0.4,
            //           opacityTo: 0.5,
            //         },
            //       },
            //     },
            //     tooltip: {
            //       enabled: true,
            //       offsetY: -35,
            //     },
            //   },
            //   fill: {
            //     type: "solid",
            //     gradient: {
            //       shade: "light",
            //       type: "horizontal",
            //       shadeIntensity: 0.25,
            //       gradientToColors: undefined,
            //       inverseColors: true,
            //       opacityFrom: 1,
            //       opacityTo: 1,
            //       stops: [50, 0, 100, 100],
            //     },
            //   },
            //   yaxis: {
            //     axisBorder: {
            //       show: false,
            //     },
            //     axisTicks: {
            //       show: false,
            //     },
            //     title: {
            //       text: "% of Managers",
            //       rotate: 90,
            //       offsetX: 0,
            //       offsetY: 0,
            //       style: {
            //         color: undefined,
            //         fontSize: "12px",
            //         fontFamily: "Helvetica, Arial, sans-serif",
            //         fontWeight: 600,
            //         cssClass: "apexcharts-yaxis-title",
            //       },
            //     },
            //   },
            //   title: {
            //     text: "Module Completion",
            //     floating: false,
            //     offsetY: 0,
            //     align: "center",
            //     style: {
            //       color: undefined,
            //       fontSize: "12px",
            //       fontFamily: "Helvetica, Arial, sans-serif",
            //       fontWeight: 600,
            //     },
            //   },
            // };
          }
        }
      });
  }

  getLearningTierReport(params: any = {}) {
    this.loading = true;
    params["filters"] = this.finalDisplayFilters;
    this.companyService.getLearningTierReport(params).subscribe((res: any) => {
      if (res.status) {
        this.loading = false;
        // let data = typeof res.data !== 'undefined' ? res.data : [];
        // this.tierReportData = data.filter((element) => { return element.batch != 'NA'; });
        this.tierReportData = typeof res.data !== "undefined" ? res.data : [];
        let data: any = [];
        let categories: any = [];
        res.data.forEach((element: any) => {
          data.push(element.noOfParticipantsPercent);
          categories.push(element.batch);
        });
        // this.tierChartOptions = {
        //   series: [
        //     {
        //       name: "% of Managers",
        //       data: data,
        //     },
        //   ],
        //   chart: {
        //     height: "auto",
        //     type: "bar",
        //     events: {
        //       click: function (chart, w, e) {
        //         // console.log(chart, w, e)
        //       },
        //     },
        //     toolbar: {
        //       show: false,
        //     },
        //   },
        //   colors: ["#26a0fc", "#ed9153", "#888888", "#ffca61", "#b0db72"],
        //   plotOptions: {
        //     bar: {
        //       columnWidth: "40%",
        //       distributed: true,
        //       dataLabels: {
        //         position: "top", // top, center, bottom
        //       },
        //     },
        //   },
        //   dataLabels: {
        //     enabled: false,
        //   },
        //   xaxis: {
        //     categories: categories,
        //   },
        //   yaxis: {
        //     axisBorder: {
        //       show: false,
        //     },
        //     axisTicks: {
        //       show: false,
        //     },
        //     title: {
        //       text: "% of Managers",
        //       rotate: 90,
        //       offsetX: 0,
        //       offsetY: 0,
        //       style: {
        //         color: undefined,
        //         fontSize: "12px",
        //         fontFamily: "Helvetica, Arial, sans-serif",
        //         fontWeight: 600,
        //         cssClass: "apexcharts-yaxis-title",
        //       },
        //     },
        //   },
        //   title: {
        //     text: "Tiers",
        //     floating: false,
        //     offsetY: 0,
        //     align: "center",
        //     style: {
        //       color: undefined,
        //       fontSize: "12px",
        //       fontFamily: "Helvetica, Arial, sans-serif",
        //       fontWeight: 600,
        //     },
        //   },
        // };
      }
    });
  }

  getLearningModuleWiseReport(params: any = {}, excelDownload = false) {
    this.loading = true;
    params["filters"] = this.finalDisplayFilters;
    this.companyService
      .getLearningModuleWiseReport(params)
      .subscribe((res: any) => {
        if (res.status) {
          this.loading = false;
          if (
            excelDownload &&
            typeof res.moduleWiseList !== "undefined" &&
            typeof res.themeWiseList !== "undefined"
          ) {
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
              res.moduleWiseList
            );
            XLSX.utils.book_append_sheet(wb, ws, "Module-wise Sheet");
            const ws2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
              res.themeWiseList
            );
            XLSX.utils.book_append_sheet(wb, ws2, "Dimension-wise Sheet");
            this.excelService.exportAsExcelFile(
              wb,
              "Module-wise Report" + ".xlsx"
            );
          } else {
            this.moduleWiseReportData =
              typeof res.data !== "undefined" ? res.data : [];
            let maxData: any = [];
            let avgData: any = [];
            let minData: any = [];
            let category: any = [];
            res.data.forEach((element: any) => {
              avgData.push(
                element.avgScore != null ? element.avgScore.toFixed() : 0
              );
              maxData.push(element.maxScore);
              minData.push(element.minScore);
              category.push(element.displayText);
            });
            // this.moduleWiseChartOptions = {
            //   series: [
            //     {
            //       name: "Avg Score",
            //       data: avgData,
            //     },
            //     {
            //       name: "Max Score",
            //       data: maxData,
            //     },
            //     {
            //       name: "Min Score",
            //       data: minData,
            //     },
            //   ],
            //   chart: {
            //     type: "bar",
            //     height: 400,
            //   },
            //   plotOptions: {
            //     bar: {
            //       horizontal: false,
            //       columnWidth: "55%",
            //       // endingShape: 'rounded',
            //     },
            //   },
            //   dataLabels: {
            //     enabled: false,
            //   },
            //   stroke: {
            //     show: true,
            //     width: 2,
            //     colors: ["transparent"],
            //   },
            //   xaxis: {
            //     categories: category,
            //   },
            //   yaxis: {
            //     title: {
            //       text: "Score",
            //     },
            //     max: 100,
            //   },
            //   fill: {
            //     opacity: 1,
            //   },
            //   tooltip: {
            //     y: {
            //       formatter: function (val) {
            //         return val + "";
            //       },
            //     },
            //   },
            // };
          }
        }
      });
  }

  getLearningMysteryBoxList(params: any = {}, excelDownload = false) {
    this.loading = true;
    params["filters"] = this.finalDisplayFilters;
    this.companyService
      .getLearningMysteryBoxList(params)
      .subscribe((res: any) => {
        if (res.status) {
          this.loading = false;
          if (excelDownload && typeof res.data !== "undefined") {
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.data);
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
            this.excelService.exportAsExcelFile(wb, this.reportType + ".xlsx");
          } else {
            this.mysteryBoxReportData =
              typeof res.data !== "undefined" ? res.data : [];
          }
        }
      });
  }

  downloadExcel() {
    let finalData: any = [];
    switch (this.reportType) {
      case "participantReport":
        {
          this.getLearningParticipantsData(
            { excelDownload: true, filter: this.selectedItems },
            true
          );
        }
        break;
      case "moduleComplitionReport":
        {
          finalData = this.getLearningCompletionStatus(
            { excelDownload: true },
            true
          );
        }
        break;
      case "tierReport":
        {
          this.tierReportData.forEach((element: any) => {
            finalData.push({
              Tiers: element.batch,
              "Count of Participant": element.noOfParticipants,
            });
          });
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(finalData);
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
          this.excelService.exportAsExcelFile(wb, this.reportType + ".xlsx");
        }
        break;
      case "moduleWiseReport":
        {
          this.getLearningModuleWiseReport({ excelDownload: true }, true);
        }
        break;
      case "percentileReport":
        {
          let params = {
            isCompany: true,
            percentile: 75,
            downloadExcel: true,
            filters: this.finalDisplayFilters,
          };
          this.companyService
            .getLearningTopListReport(params)
            .subscribe((res: any) => {
              if (
                res.status &&
                typeof res.topLearner !== "undefined" &&
                typeof res.topEng !== "undefined"
              ) {
                const wb: XLSX.WorkBook = XLSX.utils.book_new();
                const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
                  res.topLearner
                );
                XLSX.utils.book_append_sheet(wb, ws, "TopLearner List");
                const ws2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
                  res.topEng
                );
                XLSX.utils.book_append_sheet(wb, ws2, "TopEngaged List");
                this.excelService.exportAsExcelFile(
                  wb,
                  this.reportType + ".xlsx"
                );
              }
            });
        }
        break;
      case "mysteryBoxReport":
        {
          this.getLearningMysteryBoxList({ downloadExcel: true }, true);
        }
        break;
      case "summaryReport":
        // this.getSummaryReport(true);
        break;
      case "masterReport":
        this.downloadMasterExcel();
        break;
    }
  }

  downloadMasterExcel() {
    let params = {
      filters: this.finalDisplayFilters,
    };
    this.companyService.getLearningMasterSheet(params).subscribe((res: any) => {
      if (res.status && typeof res.data !== "undefined") {
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.data);
        XLSX.utils.book_append_sheet(wb, ws, "Master Sheet");
        if (
          typeof res.orientationData !== "undefined" &&
          res.orientationData.length > 0
        ) {
          const ws2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
            res.orientationData
          );
          XLSX.utils.book_append_sheet(wb, ws2, "Orientation Module");
        }
        this.excelService.exportAsExcelFile(wb, "Master Report" + ".xlsx");
      }
    });
  }

  getTopLearnerList() {
    let params = {
      isCompany: true,
      percentile: 75,
      filters: this.finalDisplayFilters,
    };
    this.gmcLearningService.getTopLearnerList(params).subscribe((res) => {
      if (res.status) {
        this.topLearningReportData =
          typeof res.data !== "undefined" ? res.data : [];
      }
    });
  }

  getTopEngagedList() {
    let params = {
      isCompany: true,
      percentile: 75,
      filters: this.finalDisplayFilters,
    };
    this.gmcLearningService.getTopEngagedList(params).subscribe((res) => {
      if (res.status) {
        this.topEngagedReportData =
          typeof res.data !== "undefined" ? res.data : [];
      }
    });
  }

  createOptionsForMultiSelect(options: any) {
    let newOptions: any = [];
    options.map((option: any) => {
      newOptions.push({
        id: option,
        itemName: option,
      });
    });
    return newOptions;
  }

  onItemSelect(item: any) {
    let newObject: any = {};
    this.allPossibleFilters.forEach((filter: any) => {
      if (filter.dbName == item.id) {
        newObject = filter;
        newObject["selectedItems"] = [];
        newObject["newOptions"] = [];
        filter.options.map((option: any) => {
          newObject["newOptions"].push({
            id: option,
            itemName: option,
          });
        });
        this.finalDisplayFilters.push(newObject);
      }
    });
    console.log("finalDisplayFilters", this.finalDisplayFilters);
  }

  OnItemDeSelect(item: any) {
    this.finalDisplayFilters = this.finalDisplayFilters.filter(
      (filter: any) => {
        return filter.dbName != item.id;
      }
    );
  }

  onSelectAll(items: any) {
    this.selectedItems = items;
    let alreadyIncluded: any = [];
    let newObject: any = {};
    this.finalDisplayFilters.map((already: any) => {
      alreadyIncluded.push(already.dbName);
    });
    this.selectedItems.forEach((item: any) => {
      this.allPossibleFilters.forEach((filter: any) => {
        if (filter.dbName == item.id && !alreadyIncluded.includes(item.id)) {
          newObject = filter;
          newObject["selectedItems"] = [];
          newObject["newOptions"] = [];
          filter.options.map((option: any) => {
            newObject["newOptions"].push({
              id: option,
              itemName: option,
            });
          });
          this.finalDisplayFilters.push(newObject);
        }
      });
    });
  }

  onDeSelectAll(items: any) {
    this.selectedItems = items;
    this.finalDisplayFilters = [];
  }

  onFilterItemSelect(item: any) {
    console.log("item", this.finalDisplayFilters);
  }

  onFilterItemDeSelect(item: any) {
    console.log("item", this.finalDisplayFilters);
  }

  onFilterSelectAll(items: any, itemId: any) {
    this.finalDisplayFilters.map((filter: any, index: any) => {
      if (filter.dbName == itemId) {
        this.finalDisplayFilters[index]["selectedItems"] = items;
      }
    });
    console.log("item", this.finalDisplayFilters);
  }

  onFilterDeSelectAll(items: any, itemId: any) {
    this.finalDisplayFilters.map((filter: any, index: any) => {
      if (filter.dbName == itemId) {
        this.finalDisplayFilters[index]["selectedItems"] = items;
      }
    });
    console.log("item", this.finalDisplayFilters);
  }

  changeSummaryFilters(evt: any, filter: any) {
    let value = evt.target.value;
    switch (filter) {
      case "type":
        this.summaryReportType = value;
        break;
      case "filter":
        this.summaryFilter = value;
        break;
      case "value":
        this.summaryCellValue = value;
        break;
    }
  }

  returnZero() {
    return 0;
  }

  fetchParticipantReportColumnData(participantData: any, filter: any) {
    let splitArray = filter.id.split(".");
    if (splitArray.length == 1) {
      return typeof participantData["personalInfo"][splitArray[0]] !==
        "undefined"
        ? participantData["personalInfo"][splitArray[0]]
        : "NA";
    } else if (splitArray.length == 2) {
      return typeof participantData[splitArray[0]] !== "undefined" &&
        typeof participantData[splitArray[0]][splitArray[1]] !== "undefined"
        ? participantData[splitArray[0]][splitArray[1]]
        : "NA";
    } else if (splitArray.length == 3) {
      return typeof participantData[splitArray[0]] !== "undefined" &&
        typeof participantData[splitArray[0]][splitArray[1]] !== "undefined" &&
        typeof participantData[splitArray[0]][splitArray[1]][splitArray[2]] !==
          "undefined"
        ? participantData[splitArray[0]][splitArray[1]][splitArray[2]]
        : "NA";
    }
  }

  onOpen() {
    this.dropdownList = [...this.dropdownList];
  }
}
