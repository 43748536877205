import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { AdminServices } from "../admin.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { BsModalService } from "ngx-bootstrap/modal";
import swal from "sweetalert2";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
@Component({
  selector: "app-nudge-subject",
  templateUrl: "./nudge-subject.component.html",
  styleUrls: ["./nudge-subject.component.css"],
})
export class NudgeSubjectComponent implements OnInit {
  constructor(
    private adminService: AdminServices,
    private modalService: BsModalService,
    private formBuilder: FormBuilder
  ) {}
  public subjectDataForm: FormGroup;
  isEdit: Boolean = false;
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  modalRef: BsModalRef;
  editSubjectData: any;

  nudgeSubjectMapping: any[] = [];
  displayedColumns: string[] = [
    "ID",
    "Title",
    "Sub Theme",
    "Code",
    "Custom",
    "Edit",
  ];
  public loading = true;
  searchQuery = "";
  filteredNudgeData = [];

  ngOnInit(): void {
    this.getNudgeSubjectMapping();
    this.subjectDataForm = this.formBuilder.group({
      id: new FormControl(null),
      nudge_id: new FormControl(null),
      subject: new FormControl("", [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(200),
        this.noWhitespaceValidator,
      ]),
      is_active: new FormControl(false),
    });
  }
  filterNudgeSubject(search) {
    if (search.length >= 3) {
      this.filteredNudgeData = this.nudgeSubjectMapping.filter(
        (nudge) =>
          nudge.title.toLowerCase().includes(search.toLowerCase()) ||
          nudge.subjects.some((subject) =>
            subject.subject.toLowerCase().includes(search.toLowerCase())
          )
      );
    } else {
      this.filteredNudgeData = this.nudgeSubjectMapping;
    }
  }
  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || "").trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }
  openConfirmation() {
    swal
      .fire({
        title: "Are you sure you want to continue?",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: this.isEdit ? "Update Subject" : "Yes",
        cancelButtonText: "Back to edit",
      })
      .then((result) => {
        if (result.value) {
          this.addSubjectData();
        }
      });
  }
  addSubjectData() {
    const formData = { ...this.subjectDataForm.value };

    if (!this.isEdit) {
      delete formData.id;
    }
    if (!formData.is_active) {
      formData.is_active = false;
    }
    const nudgeServiceCall = this.isEdit
      ? this.adminService.updateSubjectLine(formData)
      : this.adminService.addSubjectLine(formData);

    nudgeServiceCall.subscribe((res: any) => {
      if (res.status) {
        swal.fire(
          "Success",
          this.isEdit ? "Updated Successfully" : "Added Successfully"
        );
        this.hideEditModal();
        this.getNudgeSubjectMapping();
      } else {
        swal.fire("Error", res.message ? res.message : res.error);
      }
    });
  }
  hideEditModal() {
    this.isEdit = false;
    // this.isCustom = false;
    this.modalRef.hide();
    this.subjectDataForm.reset();
  }
  openAddSubjectModal(template: TemplateRef<any>, nudge_id) {
    this.subjectDataForm.patchValue({
      nudge_id: nudge_id,
    });
    const config = { class: "modal-lg" };
    this.modalRef = this.modalService.show(template, config);
    this.adminService.addModal(this.modalRef);
  }
  getNudgeSubjectMapping() {
    this.adminService.getNudgeSubjectMapping().subscribe((res: any) => {
      if (res.status) {
        this.nudgeSubjectMapping = res.data;
        this.filteredNudgeData = this.nudgeSubjectMapping;
        this.loading = false;
      }
    });
  }
  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }
  openEditSubjectModal(template: TemplateRef<any>, data, nudge_id) {
    this.editSubjectData = data;
    this.isEdit = true;
    this.subjectDataForm.patchValue({
      nudge_id: nudge_id,
      id: data.nudge_subject_id,
      subject: data.subject,
      is_active: data.is_active,
    });
    this.markFormGroupTouched(this.subjectDataForm);
    const config = { class: "modal-lg" };
    this.modalRef = this.modalService.show(template, config);
    this.modalRef.onHidden.subscribe(() => {
      this.hideEditModal();
    });
  }
}
