import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { PageEvent } from "@angular/material/paginator";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import {
  QUESTION_RESPONDENT_MAPPING,
  QUESTION_TYPES,
  RATING_OPTIONS,
} from "../../../../app/constants";
import swal from "sweetalert2";
import { AdminServices } from "../../admin.service";
export interface Subthemes {
  theme: string;
  subtheme: string;
  themeId: string | number;
  subthemeId: string | number;
}
@Component({
  selector: "app-survey-questions",
  templateUrl: "./survey-questions.component.html",
  styleUrls: ["./survey-questions.component.css"],
})
export class SurveyQuestionsComponent implements OnInit {
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = [
    "questionId",
    "questionText",
    "questionType",
    "theme",
    "subtheme",
    "questionPhrase",
    "actions",
  ];
  public surveyQuestionForm: FormGroup;
  isQuestionEdit: boolean = false;
  limit: number = 50;
  skip: number = 0;
  pageIndex: any = 0;
  themeArray: any = [];
  subthemeArray: any = [];
  filteredSubthemeArray: any = [];
  qrmArray = QUESTION_RESPONDENT_MAPPING;
  ratingOptionsArray = RATING_OPTIONS;
  questionTypes = QUESTION_TYPES;
  eligibleQuestionTypes = [1, 2, "1", "2"];
  qrmIdArray: any = [];
  modalRef: BsModalRef;
  constructor(
    private adminService: AdminServices,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.getQuestions();
    this.inItSurveyQuestionForm();
    this.getThemes();
    this.getSubthemes();
  }

  inItSurveyQuestionForm() {
    this.surveyQuestionForm = this.formBuilder.group({
      themeId: new FormControl("", [Validators.required]),
      subthemeId: new FormControl("", [Validators.required]),
      questionText: new FormControl("", [Validators.required]),
      questionPhrase: new FormControl("", [Validators.required]),
      questionTypeId: new FormControl(""),
      questionId: new FormControl(""),
      optionsArray: this.formBuilder.array([this.optionsArray("")]),
      questionRespondentMapping: this.formBuilder.array([
        this.questionRespondentMappingArray(),
      ]),
    });
  }

  getQuestions() {
    let data = {
      limit: this.limit,
      skip: this.skip,
    };
    this.adminService.getSurveyQuestions(data).subscribe((response) => {
      if (response.status) {
        this.dataSource.data.push(...response.data);
        this.dataSource.paginator = this.paginator;
      }
    });
  }

  // changeTheme() {
  //   const subthemes$ = this.adminService.getSubthemes;
  //   const searchTerm$ = this.surveyQuestionForm.controls[
  //     "themeId"
  //   ].valueChanges.pipe(startWith(this.surveyQuestionForm.value.themeId));
  //   this.filteredSubtheme$ = combineLatest([subthemes$, searchTerm$]).pipe(
  //     map(([subthemes, searchTerm]) =>
  //       subthemes.filter((data) => {
  //         return data.themeId.toString() == searchTerm.toString();
  //       })
  //     )
  //   );
  // }

  onChangeQuestionText(value) {
    if (this.isQuestionEdit) {
      return;
    }
    this.surveyQuestionForm.value.questionTypeId ==
      this.questionTypes["rating"] &&
      this.patchRespondentMapping({
        respondentArray: this.qrmArray,
        questionId: this.surveyQuestionForm.value.questionId,
        question_text: value,
      });
  }

  changeQuestionType(value) {
    this.surveyQuestionForm.value.optionsArray = [];
    this.surveyQuestionForm.value.questionRespondentMapping = [];
    value == this.questionTypes["rating"] &&
      this.patchOptions({
        optionsArray: this.ratingOptionsArray,
        questionId: this.surveyQuestionForm.value.questionId
          ? this.surveyQuestionForm.value.questionId
          : null,
      });
  }

  addUpdateQuestion(template: TemplateRef<any>, element, isQuestionEdit) {
    this.modalRef = this.modalService.show(template, { class: "modal-lg" });
    this.adminService.addModal(this.modalRef);
    this.isQuestionEdit = isQuestionEdit;

    if (isQuestionEdit) {
      this.onThemeChange(element.themeId);
      this.surveyQuestionForm.patchValue({
        themeId: element.themeId,
        subthemeId: element.subthemeId,
        questionText: element.questionText,
        questionTypeId: element.questionTypeId,
        questionId: element.questionId,
        questionPhrase: element.questionPhrase,
      });
      if (element.questionTypeId == 1) {
        let selectOptionsArray =
          element.options &&
          element.options.length > 0 &&
          element.options[0].id !== null
            ? element.options
            : this.ratingOptionsArray;
        this.patchOptions({
          optionsArray: selectOptionsArray,
          questionId: element.questionId,
        });

        let respondentArray =
          element.respondentMapping &&
          element.respondentMapping.length > 0 &&
          element.respondentMapping[0].id !== null
            ? element.respondentMapping
            : this.qrmArray;
        this.patchRespondentMapping({
          respondentArray: respondentArray,
          questionId: element.questionId,
          question_text: element.question_text,
        });
      }
    } else {
      this.surveyQuestionForm.reset();
    }
  }

  patchOptions(data) {
    const { optionsArray, questionId } = data;
    const formArray: any = this.formBuilder.array([]);
    for (let i = 0; i < optionsArray.length; i++) {
      formArray.push(
        this.formBuilder.group({
          answer_id: optionsArray[i].answer_id,
          answer_text: optionsArray[i].answer_text,
          id: optionsArray[i].id ? optionsArray[i].id : null,
          question_id: questionId,
        })
      );
    }
    this.surveyQuestionForm.setControl("optionsArray", formArray);
  }

  patchRespondentMapping(data) {
    const { respondentArray, questionId, question_text } = data;
    const formArray: any = this.formBuilder.array([]);
    for (let i = 0; i < respondentArray.length; i++) {
      formArray.push(
        this.formBuilder.group({
          id: respondentArray[i].qrmId ? respondentArray[i].qrmId : null,
          respondent_type: respondentArray[i].respondent_type,
          question_text: respondentArray[i].question_text
            ? respondentArray[i].question_text
            : question_text,
          question_id: questionId,
        })
      );
    }
    this.surveyQuestionForm.setControl("questionRespondentMapping", formArray);
  }

  searchQuestion(value) {
    let filterValue = value.trim().toLowerCase();
    this.dataSource.filter = filterValue;
  }

  optionsArray(forAnswerId) {
    return this.formBuilder.group({
      id: new FormControl("", []),
      answer_id: new FormControl(forAnswerId + 1, [Validators.required]),
      answer_text: new FormControl("", [Validators.required]),
      question_id: new FormControl("", [Validators.required]),
    });
  }

  get options(): FormArray {
    return <FormArray>this.surveyQuestionForm.get("optionsArray");
  }

  addOptions() {
    if (this.options.length < 10) {
      const forAnswerId = this.options.length;
      this.options.push(this.optionsArray(forAnswerId));
    }
  }

  removeOption(i) {
    if (this.options.length > 1) {
      this.options.removeAt(i);
    } else {
      this.options.reset();
    }
  }

  questionRespondentMappingArray() {
    return this.formBuilder.group({
      id: new FormControl("", []),
      respondent_type: new FormControl("", [Validators.required]),
      question_text: new FormControl("", []),
      question_id: new FormControl("", []),
    });
  }

  get questionMaping(): FormArray {
    return <FormArray>this.surveyQuestionForm.get("questionRespondentMapping");
  }

  addquestionMaping() {
    if (this.questionMaping.length < 10) {
      this.questionMaping.push(this.questionRespondentMappingArray());
    }
  }

  removequestionMaping(i) {
    if (this.questionMaping.length > 1) {
      this.questionMaping.removeAt(i);
    } else {
      this.questionMaping.reset();
    }
  }

  pageChanged(event: PageEvent) {
    if (event.pageIndex > this.pageIndex) {
      this.pageIndex = event.pageIndex;
      this.skip = this.skip + 50;
      this.getQuestions();
    }
  }

  getThemes() {
    this.adminService.getThemes().subscribe((data) => {
      if (data.status) {
        this.themeArray = data.data;
      }
    });
  }
  getSubthemes() {
    this.adminService.getSubthemessData().subscribe((data) => {
      if (data.status) {
        this.subthemeArray = data.data;
      }
    });
  }

  onThemeChange(event: any) {
    const value = event?.target?.value ? event?.target?.value : event;
    this.filteredSubthemeArray = this.subthemeArray.filter((data) => {
      return data.themeId == value;
    });
  }
  submitQuestion(identifier) {
    this.adminService
      .surveyQuestionsOperations({
        formData: {
          ...this.surveyQuestionForm.value,
        },
        identifier: identifier,
      })
      .subscribe((response) => {
        this.modalRef.hide();
        response && this.openSnackBar(response.message, response.status);
        // window.location.reload();
      });
  }

  deleteQuestion(id) {
    swal
      .fire({
        title: "Are You Sure You Want To Delete the question?",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
      })
      .then((result) => {
        if (result.value) {
          this.adminService
            .surveyQuestionsOperations({
              formData: {
                identifier: "delete",
              },
              questionId: id,
            })
            .subscribe((response) => {
              this.modalRef.hide();
              response && this.openSnackBar(response.message, response.status);
            });
        }
      });
  }

  openSnackBar(message, status) {
    this._snackBar.open(message, "", {
      duration: 2000,
      panelClass: status ? ["green-snackbar"] : ["red-snackbar"],
    });
  }
}
