<!-- //<app-loader *ngIf="loading"></app-loader> -->
<div class="row back-button" style="margin: 2%">
  <div class="container-fluid">
    <a
      style="padding: 10px; margin-top: 20px"
      href="javascript:void(0)"
      [routerLink]="['/company/company-dashboard']"
      class="create-new clr-grey"
      >Back To Dashboard</a
    >
  </div>
</div>

<div class="container" style="margin-top: 3%">
  <div class="row">
    <div class="col-md-6">
      <div class="form-header">
        <p>Add Custom Action Plan</p>
      </div>
      <div class="form-box panel">
        <form class="form" #form="ngForm" (ngSubmit)="onSubmit(form)">
          <input
            type="text"
            #actionName1="ngModel"
            [(ngModel)]="actionName"
            name="actionName"
            id=""
            placeholder="Action Plan Name"
            required
          />
          <textarea
            #description="ngModel"
            [(ngModel)]="selectedDescription"
            name="description"
            placeholder="Description"
            required
          >
          </textarea>
          <select
            (change)="onSelectInstrument($any($event.target).value)"
            required
            name="selectedSubtheme"
          >
            <option value="" disabled [selected]="true">
              Please Select Instrument
            </option>
            <option
              *ngFor="let instrument of optionsArray"
              value="{{ instrument.value }}"
            >
              {{ instrument.name }}
            </option>
            <!-- <option value="oldInstrument">Old Instrument</option> -->
          </select>
          <select
            required
            [(ngModel)]="selectedSubtheme"
            #selectedSubtheme1="ngModel"
            name="selectedSubtheme"
          >
            <option value="" disabled [selected]="true">
              Please Select Subtheme
            </option>
            <option
              *ngFor="let subtheme of subthemes"
              value="{{ subtheme.name }}"
            >
              {{ subtheme.name }}
            </option>
          </select>
          <select
            required
            [(ngModel)]="selectedDuration"
            name="selectedDuration"
            #selectedDuration1="ngModel"
          >
            <option value="" disabled [selected]="true">
              Please Select Duration
            </option>
            <option
              *ngFor="let duration of durationArray"
              value="{{ duration.name }}"
            >
              {{ duration.name }}
            </option>
          </select>
          <select
            required
            [(ngModel)]="selectedTheme"
            name="selectedTheme"
            #selectedDuration1="ngModel"
          >
            <option value="" disabled [selected]="true">
              Please Select Theme
            </option>
            <option *ngFor="let theme of themeArray" value="{{ theme.name }}">
              {{ theme.name }}
            </option>
          </select>
          <input type="submit" [disabled]="!form.valid" value="Submit" />
        </form>
      </div>
    </div>
    <div class="col-md-6">
      <div class="recommended-action panel">
        <div class="filter-box box">
          <select [(ngModel)]="filterText" (change)="filterSubtheme()">
            <option value="" disabled [selected]="true">Select Subtheme</option>
            <option
              *ngFor="let subtheme of subthemes"
              value="{{ subtheme.name }}"
            >
              {{ subtheme.name }}
            </option>
          </select>
        </div>
        <div class="actionPlan">
          <div *ngFor="let actionPlan of actionPlans" class="actionPlanBox">
            <p>{{ actionPlan.actionName }}</p>
            <p>{{ actionPlan.description }}</p>
            <p>
              {{ actionPlan.duration }}
              <button
                (click)="selectedActionPlan(actionPlan)"
                class="btn pull-right"
              >
                Select
              </button>
            </p>
            <p>{{ actionPlan.subtheme }}</p>
            <p>{{ actionPlan.theme }}</p>
          </div>
          <p
            style="
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              color: '#00526f';
              font-size: 20px;
            "
            *ngIf="actionPlans.length == 0"
          >
            Please select subtheme
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
