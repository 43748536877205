import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
// import { Response } from "@angular/http";
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';

@Injectable()
export class GmcLearningService {
  public gmcLearningApiUrl = '/api/v2/gmcLearning';
  public serverUrl = environment.apiURl;

  constructor(private http: HttpClient) {}

  getLearningUserStatistics(data: any) {
    return this.http
      .get(
        this.serverUrl + this.gmcLearningApiUrl + '/getLearningUserStatistics'
      )
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }

  getKotakTopUsersList(data: any) {
    return this.http
      .get(this.serverUrl + this.gmcLearningApiUrl + '/getKotakTopUsersList')
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }

  getLearningActivity() {
    return this.http
      .get(this.serverUrl + this.gmcLearningApiUrl + '/getMyActivity')
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }

  getTopLearnerList(data = {}) {
    return this.http
      .post(
        this.serverUrl + this.gmcLearningApiUrl + '/getTopLearnerList',
        data
      )
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }

  getTopEngagedList(data = {}) {
    return this.http
      .post(
        this.serverUrl + this.gmcLearningApiUrl + '/getTopEngagedList',
        data
      )
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }

  getMysteryBoxes(data: any) {
    return this.http
      .post(this.serverUrl + this.gmcLearningApiUrl + '/getMysteryBoxes', data)
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }

  updateMysteryBox(data: any) {
    return this.http
      .post(this.serverUrl + this.gmcLearningApiUrl + '/updateMysteryBox', data)
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }
  // getLdStats
  getLdStats() {
    return this.http
      .get(this.serverUrl + this.gmcLearningApiUrl + '/getLdStats')
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }

  downloadLearningBadgeCertificate() {
    return this.http
      .post(
        this.serverUrl +
          this.gmcLearningApiUrl +
          '/getLearningBadgeCertificate',
        {}
      )
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }
}
