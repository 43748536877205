import { Component, OnInit, ViewChild } from "@angular/core";
import { CompanyServices } from "../company.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { MatTooltipModule } from "@angular/material/tooltip";

// import * as jwt_decode from 'jwt-decode';
import { jwtDecode } from "jwt-decode";
import { dayAdd } from "../../../config/utils";
import * as moment from "moment";
import * as XLSX from "xlsx";
import swal from "sweetalert2";
import { validateEmail } from "../../constants";
type AOA = any[][];
import { Router, ActivatedRoute } from "@angular/router";
@Component({
  selector: "app-request-portal",
  templateUrl: "./request-portal.component.html",
  styleUrls: ["./request-portal.component.scss"],
})
export class RequestPortalComponent implements OnInit {
  @ViewChild("fileInput") fileInput: any;
  requestList!: Array<string>;
  showList: boolean = false;
  requestListOptions: any = {};
  showTimer: boolean = false;
  displayMgrProfile: boolean = false;
  displayEditProfile: boolean = false;
  display: any;
  modalRef!: BsModalRef;
  endError = false;
  startError = false;
  endDate: any;
  startDate: any;
  today = new Date();
  endtoday = dayAdd(Date.now(), "day", 1);
  maxDate = dayAdd(Date.now(), "month", 3);
  startDateMin: any;
  startDate1 = "";
  endDate1 = "";
  dateDisabled = false;
  managerData: any = {
    managerName: "",
    managerEmail: "",
    id: null,
  };
  employeeData: any = {
    empName: "",
    empEmail: "",
    id: null,
  };
  allEmpData: any = [];
  profileData: any = [];
  count: number = 0;
  deodedToken: any;
  requestName!: string;
  requests: any;
  showAllRequest: boolean = true;
  moment: any = moment;
  displayReqInfo: any = {};
  surveyData: any;
  totalParticipants: number = 0;
  getDataForEdit: any;
  data: any;
  wopts: XLSX.WritingOptions = { bookType: "xlsx", type: "array" };
  fileName: string = "GPMS_PIS_JS.xlsx";
  excelData = [];
  fileToUpload!: File;
  excelType = false;
  touched = false;
  isExcelModalShown: boolean = false;
  pisUploaded: boolean = false;
  userType: any = "";
  showError: boolean = false;
  empError: boolean = false;
  constructor(
    private companyService: CompanyServices,
    private modalService: BsModalService,
    private router: Router
  ) {}

  ngOnInit() {
    let token: any = localStorage.getItem("token");
    this.deodedToken = this.getDecodedAccessToken(token);
    //console.log(this.deodedToken, "token");
    this.showError = false;
    this.empError = false;
    this.pisUploaded = false;
    this.getRequesttype();
  }

  /**
   * For switching bewteen All new request and add request page
   */

  toggleRequest(toggleValue: any) {
    this.showAllRequest = toggleValue;
  }

  /**
   * For displayiing request list of Add/Edit PIS
   */

  showReqInfo(id: any) {
    this.displayReqInfo[id] == true
      ? (this.displayReqInfo = {})
      : (this.displayReqInfo[id] = true);
  }

  /**
   * Calling get api for all request data
   */
  getRequesttype() {
    this.companyService.getRequestDataAndType().subscribe((res: any) => {
      if (res.status) {
        this.requestList = res.data.requestsType;
        this.requests = res.data.requestList;
        this.surveyData = res.data.surveydata;
        this.totalParticipants = res.data.totalParticipants;
        this.userType = res.data.userType;
        //console.log("surveyData",this.surveyData);
      }
    });
  }

  /**
   * For display request list
   */
  showRequestList() {
    this.showList = !this.showList;
    this.requestListOptions = {};
    this.showTimer = false;
  }

  /**
   * For displayiing particular request area, adding id of list
   */
  requestOption(value: any) {
    this.requestName = value;
    this.requestListOptions[value] = true;
    this.showList = !this.showList;
    this.showTimer = !this.showTimer;
  }

  /**
   * For displaying different pop-up
   */

  manageProfile(data: any, key: any, item: any) {
    this.modalRef = this.modalService.show(
      data,
      Object.assign({}, { class: "address_popup" })
    );
    this.companyService.addModal(this.modalRef);
    //console.log("item",item);
    if (item != null) {
      this.managerData.managerName = item.managerName;
      this.managerData.managerEmail = item.managerEmail;
      this.managerData.id = item.managerId;
      this.getDataForEdit = item;
    }

    this.display = {};
    switch (key) {
      case "addManager":
        //this.display['displayMgrProfile'] = true;
        this.display = "displayMgrProfile";
        this.managerData = {};
        break;
      case "editProfile":
        //this.display['displayEditProfile'] = true;
        this.display = "displayEditProfile";
        break;
      case "empList":
        //this.display['displayAllEmpList'] = true;
        this.display = "displayAllEmpList";
        break;
      case "currentEmpList":
        //this.display['displayEmpList'] = true;
        this.display = "displayEmpList";
        //console.log("item",item)
        break;
      default:
        this.display = false;
    }
    //console.log("this.managerdata",this.managerData);
  }

  /**
   * For adding managers then displaying employee pop-up
   */
  addParticipants(value: any) {
    switch (value) {
      case "addManager":
        if (this.isValidEmail(this.managerData.managerEmail)) {
          if (this.validateManager(this.managerData.managerEmail)) {
            this.display = {};
            //this.display['displayAddEmp'] = true;
            this.display = "displayAddEmp";
            this.employeeData = {};
          }
        }
        break;
      case "addEmp":
        this.display = {};
        //this.display['displayAddEmp'] = true;
        this.display = "displayAddEmp";
        this.employeeData = {};
        break;
    }
  }

  /**
   * For adding employees then displaying employee list
   * Counting the no of employees added
   */
  addMoreEmployee() {
    if (this.isValidEmail(this.employeeData.empEmail)) {
      this.allEmpData.push(this.employeeData);
      //console.log(" this.allEmpData", this.allEmpData);
      this.count = this.allEmpData.length;
      this.employeeData = {};
    }
  }

  validateManager(email: any) {
    if (this.surveyData.length > 0) {
      let cntmgr = this.surveyData.filter((element: any) => {
        return element.managerEmail === email;
      }).length;
      //console.log("cntmgr",cntmgr)
      if (cntmgr > 0) {
        this.showError = true;
        return false;
      } else return true;
    } else return true;
  }

  /**
   * For removing employees
   */
  removeParticipant(value: any) {
    this.allEmpData = this.allEmpData.filter((item: any) => {
      if (item.empEmail != value.empEmail) {
        return item;
      }
    });
  }

  /**
   * For adding employees then calling back-end post api via service
   * Checking if total employee is greater than or equal to 3 or not
   */

  requestEmp(value: any) {
    //this.allEmpData.push(this.employeeData);
    let noOfEmp: any = {};
    if (typeof this.getDataForEdit != "undefined") {
      noOfEmp =
        typeof this.getDataForEdit.participants != "undefined"
          ? this.allEmpData.length + this.getDataForEdit.participants.length
          : this.allEmpData.length;
    }

    if (noOfEmp < 3) {
      this.empError = true;
      return false;
    } else {
      this.createRequest();
      return true;
    }
  }

  /**
   * For editing employee and manager data
   */

  editParticipants(allData: any, item: any) {
    //console.log('allData',allData);
    this.display = {};
    //this.display['displayEditEmp'] = true;
    this.display = "displayEditEmp";
    if (
      this.isValidEmail(item.emailId) &&
      this.isValidEmail(allData.managerEmail)
    ) {
      this.data = allData;
      this.managerData.managerName = allData.managerName;
      this.managerData.managerEmail = allData.managerEmail;
      this.employeeData.empName = item.name;
      this.employeeData.empEmail = item.emailId;
      this.employeeData.id = item.participantId;
    } else {
      this.showError = true;
    }
  }

  toJSONLocal(date: any) {
    var local = new Date(date);
    local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return local.toJSON().slice(0, 10);
  }

  /**
   * Validating survey date
   */

  checkDate(value: any) {
    if (value == "startDate") {
      if (this.startDate != null) {
        this.startError = false;
        this.startDate1 = this.startDate.toString().substring(3, 15);
        this.endtoday = dayAdd(this.startDate, "day", 1);
        this.maxDate = dayAdd(this.startDate, "month", 3);
      } else {
        this.startError = true;
      }
    } else if (value == "endDate") {
      if (this.endDate != null) {
        this.endError = false;
        this.startDateMin = dayAdd(this.endDate, "day", -1);
        this.endDate1 = this.endDate.toString().substring(3, 15);
      } else {
        this.endError = true;
      }
    }
  }

  /**
   * For showing project manager and HR actions on request list
   */
  reqListAction(item: any, type: any) {
    //console.log("item",item);
    let data: any = {
      id: item._id,
    };
    switch (type) {
      case "remove":
        data["request"] = "isActive";
        break;
      case "completed":
        data["request"] = "completed";
        break;
      case "extend":
        data["request"] = "extend";
        break;
    }
    this.companyService.requestActions(data).subscribe((res: any) => {
      if (res.status) {
        this.getRequesttype();
      }
    });
  }

  /**
   * For deleting manager or employee
   */
  deleteUser(view: any, delData: any, data: any) {
    //console.log("delData",delData);
    //console.log("data",data);
    let isSurvey = [];
    if (delData.participants) {
      isSurvey = delData.participants.filter((item: any) => {
        return item.surveySubmissionStatus == true;
      });
    }

    if (isSurvey.length >= 1) {
      this.showError = true;
      return false;
    } else {
      this.modalRef = this.modalService.show(
        view,
        Object.assign({}, { class: "address_popup" })
      );
      this.companyService.addModal(this.modalRef);
      // console.log("data",data);
      if (data != null) {
        this.managerData.managerName = data.managerName;
        this.managerData.managerEmail = data.managerEmail;
        this.managerData.id = data.managerId;
      } else {
        this.managerData.managerName = delData.managerName;
        this.managerData.managerEmail = delData.managerEmail;
        this.managerData.id = delData.managerId;
      }
      if (delData.emailId != null) {
        this.employeeData.empName = delData.name;
        this.employeeData.empEmail = delData.emailId;
        this.employeeData.id = delData.participantId;
        this.allEmpData.push(this.employeeData);
      }

      this.requestName = "Delete Profile";
    }
    this.display = false;
    this.modalRef.hide();
    return null;
  }

  showPis(showPisBox: any) {
    this.modalRef = this.modalService.show(
      showPisBox,
      Object.assign({}, { class: "address_popup" })
    );
    this.companyService.addModal(this.modalRef);
  }

  /**
   * Calling api for delete request
   */
  removeUser() {
    if (
      (this.managerData || this.employeeData) &&
      this.requestName == "Delete Profile"
    ) {
      this.createRequest();
    }
  }

  /**
   * For uploading PIS
   */
  onFileSelect(files: FileList) {
    const file = files.item(0);

    if (file !== null) {
      this.fileToUpload = file;
    } else {
      // Handle the case where files.item(0) is null
    }
  }

  uploadPis() {
    $("#fileSelect").trigger("click");
  }

  onFileChange(evt: any) {
    this.touched = true;

    let ext = this.getExtension(evt.srcElement.files[0].name);
    //console.log("ext",ext)
    if (ext == "csv" || ext == "xls" || ext == "xlsx") {
      //console.log('dd')
      this.excelType = true;
    } else {
      this.excelType = false;
    }
    const target: DataTransfer = <DataTransfer>evt.target;

    if (target.files.length !== 1) {
      throw new Error("Cannot use multiple files");
    }
    if (this.data) {
      this.data = [];
      //console.log("hreerg",this.data);
    }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      try {
        let wb: XLSX.WorkBook = XLSX.read(bstr, { type: "binary" });
        //console.log("wb", wb);
      } catch (Error: any) {
        if (Error.name == "Error") {
          alert(Error.message);
          (<HTMLInputElement>document.getElementById("fileSelect")).value = "";
          return null;
        }
      }
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: "binary" });
      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1 });
      //console.log("data", this.data);
      let regExFormatEmail =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.data[0] !== "undefined") {
        let serialNo = this.data[0][0] !== "undefined" ? this.data[0][0] : "";
        let mgrNameHeader =
          this.data[0][1] !== "undefined" ? this.data[0][1] : "";
        let mgrEmailHeader =
          this.data[0][2] !== "undefined" ? this.data[0][2] : "";
        let respondentTypeHeader =
          this.data[0][3] !== "undefined" ? this.data[0][3] : "";
        let empNameHeader =
          this.data[0][4] !== "undefined" ? this.data[0][4] : "";
        let empEmailHeader =
          this.data[0][5] !== "undefined" ? this.data[0][5] : "";
        if (
          serialNo != "Serial No./Unique ID" &&
          mgrNameHeader != "Manager Name" &&
          mgrEmailHeader != "Manager Email ID" &&
          respondentTypeHeader != "Respondent Type" &&
          empNameHeader != "Employee Name" &&
          empEmailHeader != "Employee Email ID"
        ) {
          swal.fire({
            title: "Error Uploading Excel",
            text: "Please check headers are correct or refer sample excel file",
            icon: "warning",
            showConfirmButton: true,
          });
          (<HTMLInputElement>document.getElementById("fileSelect")).value = "";
          return;
        }
      }
      let modifiedData: any = [];
      let emailIds = []; //Checking for duplicate
      let respondentTypeList = ["team member"];
      let modifiedObject: any = {};
      if (this.data !== undefined) {
        this.data.map(function (value: any, index: any) {
          if (
            index !== 0 &&
            value.slice(0, 5) !== "" &&
            typeof value.slice(0, 5) !== "undefined"
          ) {
            if (
              regExFormatEmail.test(value[2]) &&
              regExFormatEmail.test(value[5])
            ) {
              modifiedObject["SerialNo"] = value[0];
              modifiedObject["ManagerName"] = value[1];
              modifiedObject["ManagerEmail"] = value[2];
              modifiedObject["Respondent Type"] =
                value[3] !== "" ? value[3] : "";
              modifiedObject["EmployeeName"] = value[4];
              modifiedObject["EmployeeEmail"] = value[5];
              // modifiedObject["respondentType"] = value[2] !== "" ? value[2] : "";
              // console.log("modifiedObject",modifiedObject);
              modifiedData.push(modifiedObject);
              emailIds.push(value[5]);
            }
          }
        });

        if (modifiedData.length > 0) {
          this.excelData = modifiedData;
          this.requestName = "Add PIS";
        }
        this.pisUploaded = this.data.length > 0 ? true : false;
      }
    };

    reader.readAsBinaryString(target.files[0]);
  }

  /**
   * Calling api for create request
   */

  createRequest() {
    let empCount = this.allEmpData.filter((elm: any) => {
      return elm.empEmail != "";
    }).length;
    let data = {
      managerData: this.managerData != null ? this.managerData : null,
      employeeData: empCount > 0 ? this.allEmpData : null,
      startDate:
        this.startDate != null ? this.toJSONLocal(this.startDate) : null,
      endDate: this.endDate != null ? this.toJSONLocal(this.endDate) : null,
      userId: this.deodedToken.id,
      emailId: this.deodedToken.email,
      companyId: this.deodedToken.companyId,
      requestName: this.requestName,
      excelData: this.data != null ? this.data : null,
    };
    //console.log("data",data);
    this.allEmpData = [];
    this.managerData = {};
    this.getDataForEdit = [];

    this.companyService.getRequestFromHr(data).subscribe((res: any) => {
      if (res.status) {
        this.ngOnInit();
        this.toggleRequest(true);
      }
    });
  }

  /**
   * Validating email
   */

  isValidEmail(email: string) {
    console.log("validate email", email);
    if (validateEmail(email)) {
      this.showError = false;
    } else {
      this.showError = true;
    }
    //console.log("showError",this.showError);
    return validateEmail(email);
    // return validateEmail(email) == true ? this.showError = false : this.showError = true;
  }

  /**
   * Search manager name from Add/Edit profile list
   */
  searchManager(value: any) {
    let searchInput = value.toLowerCase();
    let table: any = document.getElementById("managerTable");
    let tr = table.getElementsByTagName("tr");
    let td, txtValue;
    for (let i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td")[0];
      if (td) {
        txtValue = td.textContent || td.innerText;
        if (txtValue.toLowerCase().indexOf(searchInput) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  }

  getExtension(path: any) {
    var basename = path.split(/[\\/]/).pop(), // extract file name from full path ...
      // (supports `\\` and `/` separators)
      pos = basename.lastIndexOf("."); // get last position of `.`

    if (basename === "" || pos < 1) {
      // if file name is empty or ...
      return "";
    } //  `.` not found (-1) or comes first (0)

    return basename.slice(pos + 1); // extract extension ignoring `.`
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwtDecode(token);
    } catch (Error) {
      return null;
    }
  }
}
