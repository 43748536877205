import { Component, OnInit, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import * as XLSX from 'xlsx';
import { CompanyServices } from '../company.service';
import swal from 'sweetalert2';

// AOA : array of array
type AOA = any[][];

@Component({
  selector: 'app-action-plan-feedback',
  templateUrl: './action-plan-feedback.component.html',
  styleUrls: ['./action-plan-feedback.component.css'],
})
export class ActionPlanFeedbackComponent implements OnInit {
  isMaxSelect = false;
  currentPage = 0;
  isEmptyDrop = true;
  isExcelDrop = true;
  isRadioChecked = false;
  loading: Boolean = false;
  origExcelData: AOA = [];
  refExcelData!: Array<any>;
  excelFirstRow = [];
  excelDataEncodeToJson: any = [];
  invalidExcelData: any = [];
  finalFeedback: any = [];
  excelTransformNum: any[] = [];
  sheetJsExcelName = 'null.xlsx';
  sheetCellRange: any;
  sheetMaxRow: any;
  localwSheet: any;
  localWorkBook: any;
  localPDF: any;
  sheetNameForTab: Array<string> = ['excel tab 1', 'excel tab 2'];
  totalPage = this.sheetNameForTab.length;
  selectDefault: any;
  sheetBufferRender: any;
  reportType: String = 'actionPlan';
  actionPlanReportData: any = [];
  actionPlanFeedbackData: any = [];
  implementationData = [];
  dropdownList: any = [];
  selectedItems: any = [];
  dropdownSettings: any = {};
  dropdownSettings2: any = {};
  allPossibleFilters: any = [];
  finalDisplayFilters: any = [];
  pdfFile: any;
  pdfSrc: any;
  pdfBufferRender: any;
  constructor(
    private companyService: CompanyServices,
    private router: Router,
    private route: ActivatedRoute,
    private el: ElementRef
  ) {}

  ngOnInit() {
    this.getPossibleFilters();
  }

  onClickRadioExcel() {
    if (this.localWorkBook === undefined) {
      swal.fire({
        title: 'Error',
        text: 'Something went wrong',
        icon: 'error',
        showConfirmButton: true,
        timer: 2500,
      });
      return;
    }
    this.isExcelDrop = true;
    this.isEmptyDrop = false;
  }
  onItemSelect(item: any) {
    let newObject: any = {};
    this.allPossibleFilters.forEach((filter: any) => {
      if (filter.dbName == item.id) {
        newObject = filter;
        newObject['selectedItems'] = [];
        newObject['newOptions'] = [];
        filter.options.map((option: any) => {
          newObject['newOptions'].push({
            id: option,
            itemName: option,
          });
        });
        this.finalDisplayFilters.push(newObject);
      }
    });
    console.log('finalDisplayFilters', this.finalDisplayFilters);
  }

  OnItemDeSelect(item: any) {
    this.finalDisplayFilters = this.finalDisplayFilters.filter(
      (filter: any) => {
        return filter.dbName != item.id;
      }
    );
  }
  onDeSelectAll(items: any) {
    this.selectedItems = items;
    this.finalDisplayFilters = [];
  }

  onFilterItemSelect(item: any) {
    console.log('item', this.finalDisplayFilters);
  }

  onFilterItemDeSelect(item: any) {
    console.log('item', this.finalDisplayFilters);
  }

  onFilterSelectAll(items: any, itemId: any) {
    this.finalDisplayFilters.map((filter: any, index: any) => {
      if (filter.dbName == itemId) {
        this.finalDisplayFilters[index]['selectedItems'] = items;
      }
    });
    console.log('item', this.finalDisplayFilters);
  }

  onFilterDeSelectAll(items: any, itemId: any) {
    this.finalDisplayFilters.map((filter: any, index: any) => {
      if (filter.dbName == itemId) {
        this.finalDisplayFilters[index]['selectedItems'] = items;
      }
    });
    console.log('item', this.finalDisplayFilters);
  }

  getPossibleFilters() {
    this.loading = true;
    this.companyService.getAllPossibleFilters().subscribe((res: any) => {
      if (res.status) {
        this.loading = false;
        let data = res.data;
        data.forEach((filter: any) => {
          if (filter.options.length > 0) {
            this.dropdownList.push({
              id: filter.dbName,
              itemName: filter.filterName,
            });
            this.allPossibleFilters.push(filter);
          }
        });
      }
    });
  }

  consoleHeight(evt: any) {
    if (evt.panel.nativeElement.clientHeight >= 255) {
      this.isMaxSelect = true;
    } else {
      this.isMaxSelect = false;
    }
  }

  transform(value: any): string {
    return (
      (value >= 26 ? this.transform(((value / 26) >> 0) - 1) : '') +
      'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[value % 26 >> 0]
    );
  }

  readerExcel(reader: any, index = 0) {
    /* reset array */
    this.origExcelData = [];
    reader.onload = (e: any) => {
      const data: string = e.target.result;
      const wBook: XLSX.WorkBook = XLSX.read(data, { type: 'array' });
      this.localWorkBook = wBook;
      const wsname: string = wBook.SheetNames[index];
      this.sheetNameForTab = wBook.SheetNames;
      this.totalPage = this.sheetNameForTab.length;
      this.selectDefault = this.sheetNameForTab[index];
      const wSheet: XLSX.WorkSheet = wBook.Sheets[wsname];
      this.localwSheet = wSheet;
      this.sheetCellRange = XLSX.utils.decode_range(wSheet['!ref']!);
      this.sheetMaxRow = this.sheetCellRange.e.r;
      this.origExcelData = <AOA>XLSX.utils.sheet_to_json(wSheet, {
        header: 1,
        range: wSheet['!ref'],
        raw: true,
      });
      this.refExcelData = this.origExcelData
        .slice(1)
        .map((value) => Object.assign([], value));
      this.excelTransformNum = [];
      for (let idx = 0; idx <= this.sheetCellRange.e.c; idx++) {
        this.excelTransformNum[idx] = this.transform(idx);
      }
      this.refExcelData.map((x) => x.unshift('#'));
      this.excelTransformNum.unshift('order');
      this.excelDataEncodeToJson = this.refExcelData.slice(0).map((item) =>
        item.reduce((obj: any, val: any, i: any) => {
          if (
            val.toLowerCase().trim() == 'yes' ||
            val.toLowerCase().trim() == 'no'
          ) {
            val = val.trim();
          }
          obj[this.excelTransformNum[i]] = val;
          return obj;
        }, {})
      );
      this.excelDataEncodeToJson = this.excelDataEncodeToJson.filter(function (
        el: any
      ) {
        console.log(el);
        console.log(Object.keys(el).length);
        return Object.keys(el).length > 1;
      });
      this.excelDataEncodeToJson.forEach((data: any, index: any) => {
        let valid = true;
        if (index == 0) {
          let headerData: any = {
            'Object Id': '',
            'Manager Name': '',
            'Email Id': '',
            'Action Plan Name': '',
            Description: '',
            G: 'Is the goal for the action plan clear?',
            M: 'Is the plan memorable?- catchy name, visible output, branding',
            I: 'Is the plan individualized and in line with organization focus areas/ team context?',
            F: 'Is frequency enough to see impact in 3 months?',
            I_: 'Is the implementation process of the plan clearly articulated?',
            T: 'Is there a tracking mechanism mentioned?',
            QA: 'Qualitative Inputs',
            'Practice Area': '',
            'Sub Practice Area': '',
            Goal: '',
            Type: '',
            'Changes - Team Behaviour': '',
            'Changes - Business Performance': '',
            Memorable: '',
            Duration: '',
            'Implementation Progress': '',
            'Success - Team Behaviour': '',
            'Success - Business Performance': '',
          };
          this.invalidExcelData.push(headerData);
        } else if (index != 0 && Object.keys(data).length > 1) {
          if (data.F.toLowerCase() != 'yes' && data.F.toLowerCase() != 'no') {
            valid = false;
          }
          if (data.G.toLowerCase() != 'yes' && data.G.toLowerCase() != 'no') {
            valid = false;
          }
          if (data.H.toLowerCase() != 'yes' && data.H.toLowerCase() != 'no') {
            valid = false;
          }
          if (data.I.toLowerCase() != 'yes' && data.I.toLowerCase() != 'no') {
            valid = false;
          }
          if (data.J.toLowerCase() != 'yes' && data.J.toLowerCase() != 'no') {
            valid = false;
          }
          if (data.K.toLowerCase() != 'yes' && data.K.toLowerCase() != 'no') {
            valid = false;
          }
        }
        if (!valid) {
          delete data.order;
          let tempData: any = {
            'Object Id': data.A,
            'Manager Name': data.B,
            'Email Id': data.C,
            'Action Plan Name': data.D,
            Description: data.E,
            G: data.F,
            M: data.G,
            I: data.H,
            F: data.I,
            I_: data.J,
            T: data.K,
            QA: data.L,
            'Practice Area': data.M,
            'Sub Practice Area': data.N,
            Goal: data.O,
            Type: data.P,
            'Changes - Team Behaviour': data.Q,
            'Changes - Business Performance': data.R,
            Memorable: data.S,
            Duration: data.T,
            'Implementation Progress': data.U,
            'Success - Team Behaviour': data.V,
            'Success - Business Performance': data.X,
          };
          this.invalidExcelData.push(tempData);
          this.excelDataEncodeToJson.splice(index, 1);
        }
      });
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      const ws1 = XLSX.utils.json_to_sheet(this.invalidExcelData);
      XLSX.utils.book_append_sheet(wb, ws1, 'Action Plans');
      XLSX.writeFile(wb, `AP Feedback Failed submission.xlsx`);
    };
  }
  onSelectAll(items: any) {
    this.selectedItems = items;
    let alreadyIncluded: any = [];
    let newObject: any = {};
    this.finalDisplayFilters.map((already: any) => {
      alreadyIncluded.push(already.dbName);
    });
    this.selectedItems.forEach((item: any) => {
      this.allPossibleFilters.forEach((filter: any) => {
        if (filter.dbName == item.id && !alreadyIncluded.includes(item.id)) {
          newObject = filter;
          newObject['selectedItems'] = [];
          newObject['newOptions'] = [];
          filter.options.map((option: any) => {
            newObject['newOptions'].push({
              id: option,
              itemName: option,
            });
          });
          this.finalDisplayFilters.push(newObject);
        }
      });
    });
  }
  inputExcelOnClick(evt: any) {
    const target: HTMLInputElement = evt.target;
    if (target.files!.length === 0) {
      swal.fire({
        title: 'Error',
        text: 'Something went wrong',
        icon: 'error',
        showConfirmButton: true,
        timer: 2500,
      });
    }
    if (target.files!.length > 1) {
      swal.fire({
        title: 'Error',
        text: 'Cannot use multiple files',
        icon: 'error',
        showConfirmButton: true,
        timer: 2500,
      });
    }
    this.sheetJsExcelName = evt.target.files.item(0).name;
    const reader: FileReader = new FileReader();
    this.readerExcel(reader);
    reader.readAsArrayBuffer(target.files![0]);
    this.sheetBufferRender = target.files![0];
    this.isEmptyDrop = false;
    this.isExcelDrop = true;
  }

  dropExcelOnChance(targetInput: Array<File>) {
    this.sheetJsExcelName = targetInput[0].name;
    if (targetInput.length !== 1) {
      swal.fire({
        title: 'Error',
        text: 'Cannot use multiple files',
        icon: 'error',
        showConfirmButton: true,
        timer: 2500,
      });
    }
    const reader: FileReader = new FileReader();
    this.readerExcel(reader);
    reader.readAsArrayBuffer(targetInput[0]);
    this.sheetBufferRender = targetInput[0];
    this.isEmptyDrop = false;
    this.isExcelDrop = true;
  }

  dropExcelBlock(fileList: Array<File>) {
    if (fileList.length === 0) {
      return;
    } else {
      this.isExcelDrop = false;
      swal.fire({
        title: 'Error',
        text: 'Something went wrong',
        icon: 'error',
        showConfirmButton: true,
        timer: 2500,
      });
    }
  }

  loadSheetOnTabClick(index: number) {
    this.currentPage = index;
    if (this.localWorkBook === undefined) {
      swal.fire({
        title: 'Error',
        text: 'Something went wrong',
        icon: 'error',
        showConfirmButton: true,
        timer: 2500,
      });
      return;
    }
    /* onload from this.localWorkBook, reReader from this.sheetBufferRender*/
    const reader: FileReader = new FileReader();
    this.readerExcel(reader, index);
    reader.readAsArrayBuffer(this.sheetBufferRender);
  }

  submitFeedback() {
    if (this.excelDataEncodeToJson.length > 0) {
      this.companyService
        .submitGmiFiTFeedback(this.excelDataEncodeToJson)
        .subscribe((res: any) => {
          if (res.status) {
            this.excelDataEncodeToJson = [];
            swal.fire({
              title: 'Submit',
              text: 'Successfully Submitted',
              icon: 'success',
              showConfirmButton: true,
              timer: 2500,
            });
          } else {
            swal.fire({
              title: 'Error',
              text: 'Something went wrong',
              icon: 'error',
              showConfirmButton: true,
              timer: 2500,
            });
          }
        });
    } else {
      swal.fire({
        title: 'Submit',
        text: 'Please upload excel file first',
        icon: 'warning',
        showConfirmButton: true,
        timer: 2500,
      });
    }
  }

  getApReviewFormat(params: any = {}) {
    this.loading = true;
    params['filters'] = this.finalDisplayFilters;
    this.companyService.getApReviewFormat(params).subscribe((res: any) => {
      if (res.status) {
        this.loading = false;
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        const ws1 = XLSX.utils.json_to_sheet(res.data);
        const ws2 = XLSX.utils.json_to_sheet(res.impl);
        XLSX.utils.book_append_sheet(wb, ws1, 'Action Plans');
        XLSX.utils.book_append_sheet(wb, ws2, 'Implementation Update');
        XLSX.writeFile(wb, `Action Plan Review Format.xlsx`);
      }
    });
  }
}
