<div class="main-box p-5">
    <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="start">

        <mat-tab>
            <ng-template mat-tab-label>
                <h3>Email Templates</h3>
            </ng-template>
            <div>
                <!-- <div class="col-md-12 text-right">
                    <div style="display: flex;justify-content: end; margin: 12px; gap: 12px;"><button
                            class="btn btn-secondary btn-newuser-clr">Add New
                            Email Template</button></div>
                </div> -->
                <table mat-table [dataSource]="dataSource">
                    <ng-container matColumnDef="ID">
                        <th mat-header-cell *matHeaderCellDef>ID</th>
                        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Template Name">
                        <th mat-header-cell *matHeaderCellDef>Template Name</th>
                        <td mat-cell *matCellDef="let element"> {{ element.template_name }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Template Subject">
                        <th mat-header-cell *matHeaderCellDef>Template Subject</th>
                        <td mat-cell *matCellDef="let element"> {{ element.subject }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Template Content">
                        <th mat-header-cell *matHeaderCellDef>Template Content</th>
                        <td mat-cell *matCellDef="let element">
                            <i (click)="openEmailView(emailView,element.template_content)"
                                class="fa fa-regular fa-eye"></i>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Total Sent">
                        <th mat-header-cell *matHeaderCellDef>Total Sent</th>
                        <td mat-cell *matCellDef="let element"> {{ element.total_sent }}
                    </ng-container><ng-container matColumnDef="Total Opened">
                        <th mat-header-cell *matHeaderCellDef>Total Opened</th>
                        <td mat-cell *matCellDef="let element"> {{ element.total_opened }}
                    </ng-container><ng-container matColumnDef="Open Rate">
                        <th mat-header-cell *matHeaderCellDef>Open Rate</th>
                        <td mat-cell *matCellDef="let element"> {{ element.open_rate }}
                    </ng-container>
                    <ng-container matColumnDef="Status">
                        <th mat-header-cell *matHeaderCellDef>Status</th>
                        <td mat-cell *matCellDef="let element"> {{ element.status == 1 ? 'Active':"Not Active"}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Action">
                        <th mat-header-cell *matHeaderCellDef>Action</th>
                        <td mat-cell *matCellDef="let element"><i (click)=deactivateEmailTemplate(element)
                                [matTooltip]="element.status == 1 ? 'Deactivate Template' : 'Activate Template'"
                                matTooltipPosition="above" class="fa fa-edit"
                                style="cursor: pointer; margin-left: 15px"></i></td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;let i = index"></tr>
                </table>
            </div>
            <!-- <app-survey-fillers></app-survey-fillers> -->
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <h3>AB Test Analysis</h3>
            </ng-template>
            <ngb-accordion #acc="ngbAccordion" [closeOthers]="true">
                <ngb-panel class="panel-header" *ngFor="let email of emailMap | keyvalue" id="toggle-{{ email.key }}">

                    <!-- Display the email as the title -->
                    <ng-template ngbPanelTitle>
                        <span style="margin-right: 20px;">
                            {{ email.key }}
                        </span>
                    </ng-template>

                    <!-- Panel content for displaying nudge data -->
                    <ng-template ngbPanelContent>
                        <div class="accordian-body">
                            <p>Template Name</p>
                            <p>Total Sent</p>
                            <p>Total Opened</p>
                            <p>Total Clicked</p>
                            <p>Open Rate</p>
                            <p>Click Rate</p>
                        </div>

                        <!-- Check if the nudge data exists -->
                        <div *ngIf="email.value.length > 0; else noSubject">
                            <!-- Loop through each nudge data associated with the email -->
                            <div *ngFor="let nudgeData of email.value" class="accordian-body">
                                <div>
                                    <div>{{ nudgeData.template_name }}</div>
                                    <div>{{ nudgeData.totalEmailsSent }}</div>
                                    <div>{{ nudgeData.totalEmailsOpened }}</div>
                                    <div>{{ nudgeData.totalEmailsClicked }}</div>
                                    <div>{{ nudgeData.openRate }}</div>
                                    <div> {{ nudgeData.clickRate }}</div>
                                </div>
                                <!-- <i (click)="openEditSubjectModal(addNudgeSubject, nudgeData, email.key)"
                                    matTooltip="Edit Nudge Subject" matTooltipPosition="above" class="fa fa-edit"
                                    style="cursor: pointer; margin-right: 15px">
                                </i> -->
                            </div>
                        </div>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>

            <!-- Placeholder for no subjects -->
            <ng-template #noSubject>
                <p>No subjects available.</p>
            </ng-template>
            <!-- 
            <table mat-table [dataSource]="analysisDataSource">
                <ng-container matColumnDef="Manager Email">
                    <th mat-header-cell *matHeaderCellDef>Manager Email</th>
                    <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                </ng-container>

                <ng-container matColumnDef="Template Name">
                    <th mat-header-cell *matHeaderCellDef>Template Name</th>
                    <td mat-cell *matCellDef="let element"> {{ element.template_names }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Total Sent">
                    <th mat-header-cell *matHeaderCellDef>Total Sent</th>
                    <td mat-cell *matCellDef="let element"> {{ element.totalEmailsSent }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Total Opened">
                    <th mat-header-cell *matHeaderCellDef>Total Opened</th>
                    <td mat-cell *matCellDef="let element"> {{ element.totalEmailsOpened }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Total Clicked">
                    <th mat-header-cell *matHeaderCellDef>Total Clicked</th>
                    <td mat-cell *matCellDef="let element"> {{ element.totalEmailsClicked }}
                </ng-container><ng-container matColumnDef="Open Rate">
                    <th mat-header-cell *matHeaderCellDef>Open Rate</th>
                    <td mat-cell *matCellDef="let element"> {{ element.openRate }}
                </ng-container>

                <ng-container matColumnDef="Click Rate">
                    <th mat-header-cell *matHeaderCellDef>Click Rate</th>
                    <td mat-cell *matCellDef="let element"> {{ element.clickRate }}
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="analysisDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: analysisDisplayedColumns;let i = index"></tr>
            </table> -->
        </mat-tab>
    </mat-tab-group>
</div>

<ng-template #emailView>
    <div class="modal-header">
        <h4 style="font-size: 22px" class="modal-title pull-left">
            Email Template
        </h4>
        <button type="button " class="close pull-right" aria-label="Close " (click)="hideEditModal()">
            <span aria-hidden="true ">&times;</span>
        </button>
    </div>
    <!-- <div class="modal-body">
        <div class="placeholders" *ngFor="let item of placeholders">
            <div>{{item}}</div>
          </div>
        <app-email-editor [content]="template_content" (contentChange)="onContentChange($event)"></app-email-editor>
        <div class="text-right">
            <button class="btn btn-secondary btn-newuser-clr">Save</button>
        </div>
    </div> -->
    <app-email-editor [content]="template_content" (contentChange)="onContentChange($event)"></app-email-editor>
</ng-template>