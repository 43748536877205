<div class="container">
  <div class="filter">
    <div class="searchBar">
      <input
        type="text"
        (keyup)="applyFilter($event.target['value'])"
        class="form-control search-icon"
        placeholder="Enter your search..."
        (keyup)="applyFilter($event.target['value'])"
      />
      <div class="clearfix"></div>
    </div>
    <div class="filter-container">
      <label class="form-check-label" for="toggleActiveSurveys">
        <input
          type="checkbox"
          id="toggleActiveSurveys"
          [(ngModel)]="showActiveSurveys"
          (change)="filterActiveSurveys()"
        />
        Show Active Surveys
      </label>
    </div>
  </div>
  <div *ngIf="isDataLoaded; else loading">
    <table mat-table [dataSource]="pagedDataSource" class="mat-elevation-z8">
      <!-- Manager Name Column -->
      <ng-container matColumnDef="managerName">
        <th mat-header-cell *matHeaderCellDef>Manager Name</th>
        <td mat-cell *matCellDef="let element">{{ element.managerName }}</td>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let element">{{ element.managerEmail }}</td>
      </ng-container>

      <!-- Company Name Column -->
      <ng-container matColumnDef="companyName">
        <th mat-header-cell *matHeaderCellDef>Company Name</th>
        <td mat-cell *matCellDef="let element">{{ element.companyName }}</td>
      </ng-container>

      <!-- Survey Name Column -->
      <ng-container matColumnDef="surveyName">
        <th mat-header-cell *matHeaderCellDef>Survey Name</th>
        <td mat-cell *matCellDef="let element">{{ element.surveyName }}</td>
      </ng-container>

      <!-- Current Start Date Column -->
      <ng-container matColumnDef="currentStartDate">
        <th mat-header-cell *matHeaderCellDef>Start Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.startDate | date : "dd/MM/yyyy" }}
        </td>
      </ng-container>

      <!-- Current End Date Column -->
      <ng-container matColumnDef="currentEndDate">
        <th mat-header-cell *matHeaderCellDef>End Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.endDate | date : "dd/MM/yyyy" }}
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let element">
          <i
            class="fa fa-edit"
            title="Edit"
            (click)="openEditModal(editTemplate, element)"
          ></i>
        </td>
      </ng-container>

      <!-- Header and Row Declarations -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <!-- Pagination Controls -->
    <div class="pagination-controls">
      <button
        mat-button
        (click)="previousPage()"
        [disabled]="currentPage === 0"
      >
        Previous
      </button>
      <span>Page {{ currentPage + 1 }} of {{ totalPages }}</span>
      <button
        mat-button
        (click)="nextPage()"
        [disabled]="currentPage >= totalPages - 1"
      >
        Next
      </button>
    </div>
  </div>
</div>
<ng-template #loading>
  <p>Loading data, please wait...</p>
</ng-template>

<ng-template #editTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Review Survey</h4>
    <button
      type="button "
      class="close pull-right"
      aria-label="Close "
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <form>
    <div class="surveyDateContainer">
      <div>
        <mat-form-field appearance="fill">
          <mat-label>Choose new start date</mat-label>
          <input
            matInput
            [(ngModel)]="updateStartDate"
            [matDatepicker]="startPicker"
            placeholder="Start Date"
            name="startDate"
            [min]="minStartDate"
            [disabled]="startDateDisabled"
            (dateChange)="onStartDateChange($event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="startPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #startPicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="fill">
          <mat-label>Choose new end date</mat-label>
          <input
            matInput
            [(ngModel)]="updateEndDate"
            [matDatepicker]="endPicker"
            placeholder="End Date"
            name="endDate"
            [min]="minEndDate"
            (dateChange)="validateDates()"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="endPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #endPicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="errorMessage" class="error-text">
      {{ errorMessage }}
    </div>
  </form>
  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="closeModal()">Cancel</button>
    <button
      class="btn btn-primary"
      [disabled]="!isFormValid"
      (click)="updateDates()"
    >
      Update
    </button>
  </div>
</ng-template>
