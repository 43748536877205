import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "textDemographicNameFormat",
})
export class TextDemographicNameFormatPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;

    // Insert a space before all uppercase letters and capitalize each word
    return value
      .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between lowercase and uppercase letters
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter of each word
  }
}
