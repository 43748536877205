<div class="container" style="background-color: white; margin-top: 50px; border-radius: 10px">
  <h2 class="m-3 red-header-text text-center">Nudge Configuration</h2>

  <div class="p-5 content">
    <div class="m-3">
      <h3>Nudge Delivery Platform:</h3>
      <div class="w-50 ml-3">
        <mat-slide-toggle class="toggle-btn" [checked]="nudgeConfiguration?.emailDelivery"
          (change)="onToggleChange($event, 'emailDelivery')">
          <p>Nudge Delivery via Email</p>
        </mat-slide-toggle>
        <mat-slide-toggle class="toggle-btn" [checked]="nudgeConfiguration?.whatsappDelivery"
          (change)="onToggleChange($event, 'whatsappDelivery')">
          <p>
            Nudge Delivery via Whatsapp
            <span class="" style="color: red"> Under testing </span>
          </p>
        </mat-slide-toggle>
      </div>
      <div class="flex">
        <div class="w-50">
          <h3>Nudge features:</h3>
          <div class="ml-3">
            <mat-slide-toggle class="toggle-btn" [checked]="nudgeConfiguration?.leaderboard"
              (change)="onToggleChange($event, 'leaderboard')">
              <p>
                Leaderboard
                <!-- <span class="" style="color: red"> Under testing </span> -->
              </p>
            </mat-slide-toggle>

            <div *ngIf="nudgeConfiguration?.leaderboard" class="">
              <h3 style="margin-bottom:5px;padding-bottom:0">Total Active Nudge Manager - {{activeNudgeManager}}</h3>
              <span>Show top
                <input placeholder="0" class="pe-input" [(ngModel)]="leaderBoardCount"
                  (change)="onLeaderBoardInputChange()" type="number" style="width: 5rem" /> users
              </span>
              <button style="padding: 4px;" class=""
                [disabled]="!valueChanged || leaderBoardCount < 1 || leaderBoardCount > maximumNudgeLeaderBoard "
                (click)="updateNudgeCompanySetting()">
                Save
              </button>
              <span *ngIf="leaderBoardCount < 1 || leaderBoardCount > maximumNudgeLeaderBoard"
                class="error-message">Count should be
                less
                than 1 and
                greater than {{maximumNudgeLeaderBoard}}</span>
            </div>

            <mat-slide-toggle class="toggle-btn" [checked]="nudgeConfiguration?.level"
              (change)="onToggleChange($event, 'level')">
              <p>Level</p>
            </mat-slide-toggle>
            <!-- <mat-slide-toggle class="toggle-btn" *ngIf="nudgeConfiguration?.level"
              [checked]="nudgeConfiguration?.badges" (change)="onToggleChange($event, 'badges')">
              <p>
                Badges
               
              </p>
            </mat-slide-toggle> -->

            <mat-slide-toggle class="toggle-btn" [checked]="nudgeConfiguration?.tfsScore"
              (change)="onToggleChange($event, 'tfsScore')">
              <p>TFS Score</p>
            </mat-slide-toggle>
            <mat-slide-toggle class="toggle-btn" [checked]="nudgeConfiguration?.superpower"
              (change)="onToggleChange($event, 'superpower')">
              <p>Superpower</p>
            </mat-slide-toggle>
          </div>
        </div>
        <div class="w-50">
          <h3>Email Settings:</h3>
          <div class="ml-3">
            <mat-slide-toggle class="toggle-btn" [checked]="nudgeConfiguration?.clientLogoInNudgeEmail"
              (change)="onToggleChange($event, 'clientLogoInNudgeEmail')">
              <p>
                Include Client Logo

              </p>
            </mat-slide-toggle>
            <mat-slide-toggle class="toggle-btn" [checked]="nudgeConfiguration?.plainTextEmail"
              (change)="onToggleChange($event, 'plainTextEmail')">
              <p>
                Optimize email (Plain text mode)
              </p>
            </mat-slide-toggle>
            <mat-slide-toggle class="toggle-btn" *ngIf="nudgeConfiguration?.leaderboard"
              [checked]="nudgeConfiguration?.leaderboardEmail" (change)="onToggleChange($event, 'leaderboardEmail')">
              <p>Send Leaderboard Email<span class="" style="color: red">
                </span></p>
            </mat-slide-toggle>
            <mat-slide-toggle class="toggle-btn" *ngIf="nudgeConfiguration?.level"
              [checked]="nudgeConfiguration?.levelInNudgeEmail" (change)="onToggleChange($event, 'levelInNudgeEmail')">
              <p>Include Level Info</p>
            </mat-slide-toggle>
          </div>
        </div>
      </div>
    </div>
    <!-- <hr />
    <div class="m-3">
      <h3>Assign organisation based priority weight to nudge goals:</h3>
      <table class="ml-3" style="min-width: 60%">
        <tr *ngFor="let category of categories; let i = index">
          <td style="width: max-content">
            <span style="width: max-content">{{ category.name }}:</span>
          </td>
          <td>
            <div class="cat-slider-wrapper">
              <input [disabled]="category.isResetMode" class="cat-slider" type="range" step="10"
                [(ngModel)]="category.currentValue" />
              <div class="flex">
                <span class="cat-slider-label">default</span>
                <span class="cat-slider-label">1</span>
                <span class="cat-slider-label">2</span>
                <span class="cat-slider-label">3</span>
                <span class="cat-slider-label">4</span>
                <span class="cat-slider-label">5</span>
                <span class="cat-slider-label">6</span>
                <span class="cat-slider-label">7</span>
                <span class="cat-slider-label">8</span>
                <span class="cat-slider-label">9</span>
                <span class="cat-slider-label">Max</span>
              </div>
            </div>
            <span class="error-message">{{ category.error }}</span>
          </td>
          <td class="flex-gap">
            <button class="save-button-wrapper" *ngIf="isEditMode(i)" (click)="onSave(i)">
              <span>&#10003;</span>
            </button>
            <button class="clear-button-wrapper" *ngIf="isEditMode(i)" (click)="onClear(i)">
              <span>&#10008;</span>
            </button>
            <button class="reset-button-wrapper" *ngIf="category.isResetMode" (click)="onReset(i)">
              <span>&#8634;</span>
            </button>
          </td>
        </tr>
      </table>
    </div>
    <hr /> -->
    <div class="m-3">
      <h3>Set minimum Nudge eligibility timeframe (days)</h3>
      <div class="flex-gap ml-3">
        <span>The minimum days after which a nudge can repeat is
          <input placeholder="30" class="pe-input" [(ngModel)]="pe.currentValue" (keyup)="onPeInputChange()"
            type="number" [disabled]="pe.isResetMode" style="width: 5rem" />
          days</span>
        <span class="error-message">{{ pe.error }}</span>
        <button class="save-button-wrapper" *ngIf="isPeEditMode()" (click)="onPeSave()">
          <span>&#10003;</span>
        </button>
        <button class="clear-button-wrapper" *ngIf="isPeEditMode()" (click)="onPeClear()">
          <span>&#10008;</span>
        </button>
        <button class="reset-button-wrapper" *ngIf="pe.isResetMode" (click)="onPeReset()">
          <span>&#8634;</span>
        </button>
      </div>
    </div>
  </div>
</div>