import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { environment } from "../environments/environment";
import io from "socket.io-client";
// import { io } from 'socket.io-client';
// import 'rxjs/add/operator/map';
// import { Subject,map } from 'rxjs';
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
// import * as jwt_decode from 'jwt-decode';
import { jwtDecode } from "jwt-decode";
// import 'rxjs/add/operator/map';
import { map } from "rxjs";
import { Subject } from "rxjs";

@Injectable()
export class DataService {
  public serverUrl = environment.apiURl;
  public superAdminApiUrl = "/api/v2/superadmin";
  private messageSource = new BehaviorSubject("");
  private socket;
  // private socket = io(this.serverUrl, { reconnection: true });
  currentMessage = this.messageSource.asObservable();
  localStorageToken: any;
  localView: any;

  constructor(private http: HttpClient) {
    const token: any = localStorage.getItem("token");
    // let localToken = this.getDecodedAccessToken(localStorage.getItem('token'));
    let localToken = this.getDecodedAccessToken(token);
    this.localStorageToken = JSON.stringify(localToken);
    this.localView = JSON.parse(this.localStorageToken);
    var connectionIds = localStorage.getItem("managerIDList");
    // this.socket = io(environment.apiURl, {query: 'socketConnection='+connectionIds});

    // this.socket = io(environment.apiURl, {
    //   query:
    //     'mentorId=' +
    //     localStorage.getItem('mentorId') +
    //     '&managerId=' +
    //     localStorage.getItem('managerId') +
    //     '&role=' +
    //     this.localView.role +
    //     '&managerIds=' +
    //     connectionIds,
    // });

    this.socket = io(environment.apiURl, {
      query: {
        mentorId: localStorage.getItem("mentorId"),
        managerId: localStorage.getItem("managerId"),
        role: this.localView.role,
        managerIds: connectionIds,
      },
    });
    // this.socket = io(environment.apiURl, {query: 'managerIds='+connectionIds+'&mentorId='+this.localView.id+'&role=Mentor'});
    // if(localStorage.getItem("managerIDList") != null){
    //   var list = localStorage.getItem("managerIDList").split("gmc");
    //   for(let i = 0; i< list.length-1; i++){
    //   this.socket = io(environment.apiURl, {query: 'managerIds='+list[i]+'&mentorId='+this.localView.id+'&role='+this.localView.role+'&role1=Manual'});
    // }
    // }
    // console.log(list);
  }

  changeMessage(message: string) {
    this.messageSource.next(message);
  }
  joinRoom(data: any) {
    this.socket.emit("join", data);
  }

  newUserJoined() {
    let observable = new Observable<{
      manager: String;
      message: String;
      joinedUser: String;
      joindeRole: String;
      users: Object;
    }>((observer) => {
      this.socket.on("user joined", (data: any) => {
        // console.log('user joined');
        // console.log(data);
        // console.log(this.socket);
        observer.next(data);
      });
      return () => {
        this.socket.disconnect();
      };
    });

    return observable;
  }

  leaveRoom(data: any) {
    this.socket.emit("leave", data);
  }

  sendMessage(data: any) {
    // console.log(data);
    this.socket.emit("message", data);
  }

  userTyping(data: any) {
    this.socket.emit("typing", data);
  }

  newMessageReceived() {
    let observable = new Observable<{
      manager: String;
      mentor: String;
      message: any;
    }>((observer) => {
      this.socket.on("new message", (data: any) => {
        // console.log('new message');
        // console.log(data);
        // console.log(this.socket);
        observer.next(data);
      });
      return () => {
        this.socket.disconnect();
      };
    });

    return observable;
  }

  userLeft() {
    let observable = new Observable<{
      manager: String;
      message: String;
      users: Object;
    }>((observer) => {
      this.socket.on("user left", (data: any) => {
        // console.log('user left');
        // console.log(data);
        // console.log(this.socket);
        observer.next(data);
      });
      return () => {
        this.socket.disconnect();
      };
    });

    return observable;
  }

  IsUserTyping() {
    let observable = new Observable<{ manager: String; message: String }>(
      (observer) => {
        this.socket.on("user typing", (data: any) => {
          // console.log('user typing')
          // console.log(data);
          // console.log(this.socket);
          observer.next(data);
        });
        return () => {
          this.socket.disconnect();
        };
      }
    );

    return observable;
  }

  storeChat(data: any) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/storeChat", data)
      .pipe(
        map((response: any) => {
          return <any>response;
        })
      );
  }

  getChat(data: any) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/getChat", data)
      .pipe(
        map((response: any) => {
          return <any>response;
        })
      );
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwtDecode(token);
    } catch (Error) {
      return null;
    }
  }

  disconnect() {
    this.socket.on("disconnect", (reason) => {
      // Handle the disconnection event here);
    });
    return () => {
      this.socket.disconnect();
    };
  }
}
