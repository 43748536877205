<div class="login-main">
  <div class="container-fluid">
    <div class="row">
      <div class="tbl">
        <div class="tbl-cel-left login-left"></div>

        <div class="tbl-cel-right">
          <div class="col-md-6 login-right text-center">
            <form [formGroup]="loginForm" (ngSubmit)="loginSubmit()">
              <div class="form-group">
                <img src="../../../assets/images/locked.png" />
                <h3>Sign In</h3>
              </div>

              <div class="form-group mt-30">
                <input
                  type="email"
                  class="form-control input-lg envelope-icon"
                  placeholder="Email Address"
                  formControlName="email"
                />
              </div>
              <div class="form-group">
                <input
                  type="password"
                  class="form-control input-lg lock-icon"
                  placeholder="Password"
                  formControlName="password"
                />
              </div>
              <app-google-recaptcha
                (onSuccess)="onCaptchaSuccess($event)"
                (onError)="onCaptchaError($event)"
              ></app-google-recaptcha>
              <div class="form-group mt-25">
                <button
                  type="submit"
                  class="btn btn-primary"
                  [disabled]="!loginForm.valid || !isVerifiedCaptcha"
                >
                  SIGN IN
                </button>
              </div>

              <div class="form-group mt-30">
                <a [routerLink]="'/admin/forgot-password'">Forgot Password</a>
                <!-- <a [routerLink]="'/register'">Sign Up</a> -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
