import { AdminServices } from '../admin.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataSource } from '@angular/cdk/table';
import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  TemplateRef,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
// import 'rxjs/add/observable/of';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-assign-coupon',
  templateUrl: './assign-coupon.component.html',
  styleUrls: ['./assign-coupon.component.css'],
})
export class AssignCouponComponent implements OnInit {
  registeredManagers: any;
  displayedColumns = ['email', 'code', 'feature', 'action'];
  packageArray = [
    { name: 'Profiling' },
    { name: 'E-Learning' },
    { name: 'Certification' },
    { name: 'Special' },
  ];
  package: any = '';
  email: any;
  isEmailValid: boolean;
  emailStatus: any;
  loading: boolean;
  inputEmailBgColor: any;
  inputEmailTextColor: any;
  dataSource = new MatTableDataSource();
  constructor(
    private router: Router,
    private adminService: AdminServices,
    private modalService: BsModalService,
    public snackBar: MatSnackBar
  ) {}
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  ngOnInit() {
    this.loading = true;
    this.adminService.registeredCouponManagers().subscribe((response: any) => {
      if (response.status) {
        this.loading = false;
        this.registeredManagers = response.data;
        this.dataSource.data = this.registeredManagers;
      }
    });
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
  onSelectPackage(event) {
    this.package = event;
  }

  validate() {
    this.emailStatus = '';
    //Validating Email..
    if (this.email.length <= 0) {
      this.emailStatus = '';
    } else {
      if (this.validateEmail(this.email)) {
        this.isEmailValid = true;
        this.emailStatus = '';
        this.adminService
          .checkCouponEmail(this.email)
          .subscribe((response: any) => {
            this.isEmailValid = true;
            this.emailStatus = '';
            if (response.status) {
              // console.log("Valid");
              this.emailStatus = '';
            } else {
              this.isEmailValid = false;
              this.emailStatus = response.message;
            }
          });
      } else {
        this.isEmailValid = false;
        this.emailStatus = 'Invalid Email ID';
      }
    }
  }
  validateEmail(email) {
    var re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  onAddPackage(form) {
    this.loading = true;
    this.adminService
      .assignCoupon(this.email, this.package)
      .subscribe((response: any) => {
        if (response.status) {
          this.loading = false;
          form.reset();
          this.package = '';
          Swal.fire({
            title: 'Package Assigned',
            text: 'Package assigned successfully',
            icon: 'success',
            showConfirmButton: false,
            timer: 2500,
          });
          this.onLoad();
        } else {
          this.loading = false;
          Swal.fire({
            title: 'Admin add',
            text: 'Something went wrong',
            icon: 'error',
            showConfirmButton: false,
            timer: 2500,
          });
        }
      });
  }
  onDelete(email) {
    this.loading = true;
    this.adminService.revokeCoupon(email).subscribe((response: any) => {
      if (response.status) {
        this.loading = false;
        Swal.fire({
          title: 'Coupon revoked',
          text: 'Coupon removed successfully',
          icon: 'success',
          showConfirmButton: false,
          timer: 2500,
        });
        this.onLoad();
      } else {
        this.loading = false;
        Swal.fire({
          title: 'Coupon revoked',
          text: 'Coupon removed successfully',
          icon: 'error',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    });
  }
  onLoad() {
    this.adminService.registeredCouponManagers().subscribe((response: any) => {
      if (response.status) {
        this.loading = false;
        this.registeredManagers = response.data;
        this.dataSource.data = this.registeredManagers;
      }
    });
  }
}
