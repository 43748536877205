import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { AdminServices } from "../admin.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FormBuilder } from "@angular/forms";
import Swal from "sweetalert2";
import { validateEmail } from "../../constants";
import { DatePipe } from "@angular/common";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
@Component({
  selector: "app-certificate-upload",
  templateUrl: "./certificate-upload.component.html",
  styleUrls: ["./certificate-upload.component.css"],
})
export class CertificateUploadComponent implements OnInit {
  myFiles: any = [];
  modalRef: BsModalRef;
  safeFileUrl: SafeResourceUrl;
  companyName: any;
  seCompanyId: any;
  selectedDate: string;
  issueDate: any;
  isFileInputDisabled: boolean = false;
  errorMessage: string | undefined;
  sendEmail: boolean = false;
  emailData: {
    from: string;
    cc: string;
    subject: string;
    content: string;
  } = {
    from: "",
    cc: "",
    subject: "",
    content: "",
  };
  endDate: any;
  formattedIssueDate: string;
  // "Congratulations! You are now Certified as a Great People Manager by GMI",
  constructor(
    private modalService: BsModalService,
    private adminService: AdminServices,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.companyName = this.route.snapshot.params["name"];
    this.seCompanyId = this.route.snapshot.params["id"];
    this.getEmailContent();
  }
  todayDate(): string {
    return new Date().toISOString().split("T")[0];
  }
  getFileDetails(e) {
    const files: FileList = e.target.files;
    if (files.length > 50) {
      this.errorMessage = `You are not allowed to send more than 50 certificates at a time.`;
      this.openSnackBar(this.errorMessage, false);
      const fileInput: HTMLInputElement = document.getElementById(
        "fileSelect"
      ) as HTMLInputElement;
      fileInput.value = "";
      return;
    }
    for (let i = 0; i < files.length; i++) {
      const file: File = files[i];

      let fileSize = Math.round(file.size / (1024 * 1024));
      if (fileSize >= 20) {
        this.errorMessage = `File size should be less than 20 mb for file: ${file.name}`;
        this.openSnackBar(this.errorMessage, false);
        const fileInput: HTMLInputElement = document.getElementById(
          "fileSelect"
        ) as HTMLInputElement;
        fileInput.value = "";
        return;
      }
      if (file.type !== "application/pdf") {
        this.errorMessage = `Invalid file format for file: ${file.name}`;
        this.openSnackBar(this.errorMessage, false);
        const fileInput: HTMLInputElement = document.getElementById(
          "fileSelect"
        ) as HTMLInputElement;
        fileInput.value = "";
        return;
      }

      const fileNameWithoutExtension = file.name.split(".pdf")[0];
      const isValidFileName = validateEmail(fileNameWithoutExtension);

      if (!isValidFileName) {
        this.errorMessage = `Invalid file name for file: ${file.name}`;
        this.openSnackBar(this.errorMessage, false);
        const fileInput: HTMLInputElement = document.getElementById(
          "fileSelect"
        ) as HTMLInputElement;
        fileInput.value = "";
        return;
      } else {
        const isDuplicate = this.myFiles.some(
          (existingFile) => existingFile.fileName === file.name
        );
        if (isDuplicate) {
          this.errorMessage = `Duplicate file name: ${file.name}`;
          this.openSnackBar(this.errorMessage, false);
          const fileInput: HTMLInputElement = document.getElementById(
            "fileSelect"
          ) as HTMLInputElement;
          fileInput.value = "";
          return;
        }

        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          let fileContent = event.target.result;
          this.myFiles.push({ file: fileContent, fileName: file.name });
        };
      }
    }
    this.errorMessage = undefined;
    this.clearFileInput();
  }
  clearFileInput() {
    const fileInput: HTMLInputElement = document.getElementById(
      "fileSelect"
    ) as HTMLInputElement;
    fileInput.value = "";
  }
  uploadCertificateFiles() {
    const isEmptyContent = (content: string): boolean => {
      const trimmedContent = content.trim();
      return !trimmedContent || trimmedContent === "<p> </p>";
    };

    if (this.sendEmail) {
      if (
        !this.emailData.subject.trim() ||
        isEmptyContent(this.emailData.content)
      ) {
        Swal.fire({
          title: "Missing Email Details",
          text: "Email subject and content cannot be empty.",
          icon: "warning",
          showConfirmButton: true,
        });
        return;
      }
    }

    let reqBody = {
      seCompanyId: this.seCompanyId,
      companyName: this.companyName,
      myFiles: this.myFiles,
      // issueDate: new Date(`${this.issueDate}T18:30:00.000+00:00`),
      // endDate: new Date(`${this.endDate}T18:30:00.000+00:00`),
      issueDate: new Date(this.issueDate),
      endDate: new Date(this.endDate),
      sendEmail: this.sendEmail,
    };
    this.sendEmail ? (reqBody["emailData"] = this.emailData) : null;
    this.adminService.uploadCertificate(reqBody).subscribe((res) => {
      if (res.status) {
        Swal.fire({
          title: "Certificate Upload",
          text: "Certificate Uploaded Successfully",
          icon: "success",
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          title: "Certificate Upload ",
          text: res.message,
          icon: "error",
          showConfirmButton: false,
          timer: 2500,
        });
      }
      // this.getFileDetails(null);
      this.myFiles = [];
      this.sendEmail = false;
      this.emailData = {
        cc: null,
        from: null,
        subject: null,
        content: null,
      };
      this.issueDate = this.endDate = null;
      this.errorMessage = undefined;
      this.clearFileInput();
      this.getEmailContent();
    });
  }
  deleteFile(index) {
    Swal.fire({
      title: "Are you sure you want to delete?",
      text: "You will not be able to recover this file!",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((isConfirm) => {
      if (isConfirm.value) {
        this.confirmDeleteFile(index);
      }
    });
  }
  confirmDeleteFile(index: number): void {
    this.myFiles.splice(index, 1);
  }
  updateEndDateOld() {
    const issueDateObj = new Date(this.issueDate);
    const endDateObj = new Date(
      issueDateObj.getFullYear() + 1,
      issueDateObj.getMonth(),
      issueDateObj.getDate()
    );

    this.endDate = endDateObj.toISOString().slice(0, 10);
  }
  toggleEmail() {
    this.sendEmail = !this.sendEmail;
  }
  getEmailContent() {
    this.adminService
      .getEmailTemplates({ emailType: "certificate_send" })
      .subscribe((res) => {
        if (res.status && res.data) {
          this.emailData.content = res.data.content;
          this.emailData.subject = res.data.subject;
          this.emailData.from = res.data.from;
        }
      });
  }

  onDateChange(event: any) {
    if (event.value) {
      this.issueDate = event.value;
      this.updateIssueDateAndEndDate();
    }
  }
  updateIssueDateAndEndDate() {
    this.formattedIssueDate = this.datePipe.transform(
      this.issueDate,
      "dd/MM/yyyy"
    );
    this.updateEndDate();
  }

  updateEndDate() {
    if (this.issueDate) {
      const endDateObj = new Date(this.issueDate);
      endDateObj.setFullYear(endDateObj.getFullYear() + 1);
      this.endDate = this.datePipe.transform(endDateObj, "MM/dd/yyyy");
    }
  }
  openPreviewModal(fileContent, template) {
    this.safeFileUrl =
      this.sanitizer.bypassSecurityTrustResourceUrl(fileContent);
    const config = {
      initialState: {},
      animated: true,
      class: "modal-lg",
    };
    this.modalRef = this.modalService.show(template, config);
    this.adminService.addModal(this.modalRef);
  }
  openSnackBar(message, status) {
    this._snackBar.open(message, "", {
      duration: 2000,
      panelClass: status ? ["green-snackbar"] : ["red-snackbar"],
    });
  }
}
