import { Component, OnInit, ViewChild } from "@angular/core";
import * as XLSX from "xlsx";
type AOA = any[][];
import { dayAdd, isEmpty } from "../../../config/utils";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { CompanyServices } from "../company.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import swal from "sweetalert2";
import { NgxDropdownConfig } from "ngx-select-dropdown";
@Component({
  selector: "app-action-subtheme",
  templateUrl: "./action-subtheme.component.html",
  styleUrls: ["./action-subtheme.component.css"],
})
export class ActionSubthemeComponent implements OnInit {
  @ViewChild("fileInput") fileInput:
    | { nativeElement: { value: string } }
    | undefined;
  errorsInData: any = [];
  excelType = false;
  excelHeader = false;
  touched = false;
  modalRef!: BsModalRef;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  headers: any = [];
  headerAsObject: any = [];
  excelSheetData: any = [];
  template!: string;
  subarea: string = "";
  area: string = "";

  filterManagers: Array<any> = [];
  selectedSubareas: any = [];
  selectedGoals: any = [];
  existingManagers: any = new MatTableDataSource<TableElement>([]);
  displayedColumns: string[] = ["name", "email", "action"];
  singleManagerDetails: any = {};
  areas = ["Connect", "Develop", "Inspire"];
  subareas = [
    { name: "Personal Best", value: "Inspire" },
    { name: "Expectation Setting", value: "Inspire" },
    { name: "Recognition", value: "Inspire" },
    { name: "Fun & Celebrations", value: "Inspire" },
    { name: "Fair Treatment", value: "Connect" },
    { name: "Information Sharing", value: "Connect" },
    { name: "Approachability", value: "Connect" },
    { name: "Candid Communication", value: "Connect" },
    { name: "Feedback Seeking", value: "Connect" },
    { name: "Reliability", value: "Connect" },
    { name: "Care for Individual", value: "Connect" },
    { name: "Work-Life Balance", value: "Connect" },
    { name: "Involvement in Decisions", value: "Develop" },
    { name: "Feedback Sharing", value: "Develop" },
    { name: "Development Focus", value: "Develop" },
    { name: "Guidance", value: "Develop" },
    { name: "Career Growth", value: "Develop" },
  ];
  goals: Array<any> = [
    { name: "Team Retention" },
    { name: "Team Engagement" },
    { name: "Customer Delight" },
    { name: "Customer Retention" },
    { name: "Other" },
  ];
  additionalGoals: string = "";

  configDropdownAreas: NgxDropdownConfig = {
    displayKey: "name",
    search: true,
    height: "auto",
    placeholder: "Select Access",
    // customComparator: () => {},
    limitTo: this.subareas.length,
    moreText: "more",
    noResultsFound: "No results found!",
    searchPlaceholder: "Search",
    searchOnKey: "name",
    customComparator: function (a: any, b: any): number {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
      // throw new Error('Function not implemented.');
    },
    clearOnSelection: false,
    inputDirection: "",
  };
  configDropdownGoals: NgxDropdownConfig = {
    displayKey: "name",
    search: true,
    height: "auto",
    placeholder: "Select goals",
    // customComparator: () => {},
    limitTo: this.selectedGoals.length,
    moreText: "more",
    noResultsFound: "No results found!",
    searchPlaceholder: "Search",
    searchOnKey: "name",
    customComparator: function (a: any, b: any): number {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
      // throw new Error('Function not implemented.');
    },
    clearOnSelection: false,
    inputDirection: "",
  };
  constructor(
    private companyService: CompanyServices,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.getManagers([]);
    this.existingManagers.paginator = this.paginator;
  }
  // Excel operations

  // This function is for getting the extension of the file
  getExtension(path: any) {
    var basename = path.split(/[\\/]/).pop(), // extract file name from full path ...
      // (supports `\\` and `/` separators)
      pos = basename.lastIndexOf("."); // get last position of `.`

    if (basename === "" || pos < 1)
      // if file name is empty or ...
      return ""; //  `.` not found (-1) or comes first (0)

    return basename.slice(pos + 1); // extract extension ignoring `.`
  }
  // This function is for getting the data from the excel sheet
  onFileChange(evt: any, template: any) {
    this.touched = true;
    //console.log(this.touched , 'touched')
    //console.log(evt.srcElement.files[0].name , 'fj');
    /* wire up file reader */
    let ext = this.getExtension(evt.srcElement.files[0].name);
    //console.log(ext)
    if (ext == "csv" || ext == "xls" || ext == "xlsx") {
      //console.log('dd')
      this.excelType = true;
    } else {
      this.excelType = false;
    }
    const target: DataTransfer = <DataTransfer>evt.target;

    if (target.files.length !== 1) throw new Error("Cannot use multiple files");
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      try {
        var wb: XLSX.WorkBook = XLSX.read(bstr, { type: "binary" });
      } catch (Error: any) {
        if (Error.name == "Error") {
          alert(Error.message);
          (<HTMLInputElement>document.getElementById("fileSelect")).value = "";
          return null;
        }
      }

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      // this.data = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1 });
      let data = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1 });
      console.log("data", this.excelSheetData);

      if (data[0][0] !== "Manager Name" || data[0][1] !== "Manager Email") {
        this.excelHeader = true;
        console.log("Something wrong in header name");
      } else {
        this.excelHeader = false;
      }
      let excelsheet = [];
      if (!this.excelHeader) {
        for (let i = 1; i < data.length; i++) {
          excelsheet.push({ name: data[i][0], email: data[i][1] });
        }
      }
      console.log("excelsheet", excelsheet);
      this.template = template;
      this.validateAndSearchData(excelsheet);

      // console.log(this.excelSheetData, "excwel sheet data");
    };

    reader.readAsBinaryString(target.files[0]);
  }

  // This function is for validating the data
  validateAndSearchData(data: any) {
    data = data.filter((item: any) => item !== undefined);
    let re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    for (let i = 0; i < data.length; i++) {
      if (data[i].email !== undefined) {
        if (isEmpty(data[i].email)) {
          this.errorsInData.push(data[i]);
        } else if (!re.test(data[i].email)) {
          this.errorsInData.push(data[i]);
        } else {
          this.excelSheetData.push(data[i].email);
        }
      }
    }
    if (this.errorsInData.length === 0) {
      this.getManagers(this.excelSheetData, this.template);
    }

    return true;
  }

  // For deleting the data from the excel sheet
  deleteExcelSheet() {
    if (this.fileInput) {
      this.fileInput.nativeElement.value = "";
      this.excelSheetData = [];
      this.excelType = false;
      this.touched = false;
      this.excelHeader = false;
      // this.getManagers([]);
      this.errorsInData = [];
    }
  }

  // End of the excel operations

  // Get managers from the excel sheet
  getManagers(managers: any, template = "") {
    let data = {
      managerEmails: managers,
      excelFilter: managers.length > 0 ? true : false,
    };
    this.companyService.getManagers(data).subscribe((res) => {
      if (res.status) {
        if (data.excelFilter && this.errorsInData.length == 0) {
          this.filterManagers = res.data;
          // open modal for filtering managers
          this.modalRef = this.modalService.show(template);
          this.companyService.addModal(this.modalRef);
        } else {
          let managers: any = [];
          res.data.forEach((item: any) => {
            managers.push({
              id: item._id,
              name: item.name,
              email: item.email,
              action: item.areas,
              goals: item.goals,
            });
          });
          this.existingManagers.data = managers;
          console.log(this.existingManagers);
        }
      }
    });
  }

  // Filter the table data

  applyFilter(filterValue: string) {
    this.existingManagers.filter = filterValue.trim().toLowerCase();
  }
  // selecting the subareas
  selectSubareas(event: any) {
    this.selectedSubareas = event.value;
  }
  //selecting goals
  selectGoals(event: any) {
    this.selectedGoals = event.value;
  }

  // selecting the areas
  addAdditionalSub() {
    if (this.subarea.trim() == "" || this.area.trim() == "") {
      swal.fire("Please enter the area and subarea");
      return false;
    }
    this.selectedSubareas.push({ name: this.subarea, value: this.area });
    this.subarea = "";
    this.area = "";
    return true;
  }
  addAdditionalGoals() {
    if (this.additionalGoals.trim() == "") {
      swal.fire("Please enter goal");
      return false;
    }
    this.selectedGoals.push({ name: this.additionalGoals });
    this.additionalGoals = "";
    return true;
  }
  /*
  // submit Subareas
  submit() {
    if (this.selectedSubareas.length == 0) {
      swal.fire('Error', 'Please select subareas', 'error');
      return false;
    }
    let data = {
      emails: this.filterManagers.map((manager) => manager.email),
      subAreas: this.selectedSubareas,
    };
    // console.log(data , "submission data")
    this.companyService.addSubAreasToManagers(data).subscribe((res: any) => {
      if (res.status) {
        this.closeModal();
        this.getManagers([]);
        swal.fire(
          'Subareas added',
          'Subareas has been sucessfully added!',
          'success'
        );
        return true;
      } else {
        return false;
      }
    });

    // console.log(this.selectedSubareas);
  }
*/
  submit() {
    if (this.selectedSubareas.length === 0) {
      swal.fire("Error", "Please select subareas", "error");
      return false;
    }

    let data = {
      emails: this.filterManagers.map((manager) => manager.email),
      subAreas: this.selectedSubareas,
    };

    // Use a flag to track success/failure
    let successFlag = false;

    // Subscribe to the service
    this.companyService.addSubAreasToManagers(data).subscribe(
      (res: any) => {
        if (res.status) {
          this.closeModal();
          this.getManagers([]);
          swal.fire(
            "Subareas added",
            "Subareas have been successfully added!",
            "success"
          );
          successFlag = true;
        } else {
          successFlag = false;
        }
      },
      (error) => {
        // Handle error if needed
        console.error("Error:", error);
        successFlag = false;
      },
      () => {
        // Finalize and return the success flag
        return successFlag;
      }
    );

    // This line will be reached before the asynchronous operation completes,
    // so you might not get the correct value immediately.
    return successFlag;
  }

  // SubmitGoals
  submitGoals(): boolean {
    if (this.selectedGoals.length == 0) {
      swal.fire("Error", "Please select Goals", "error");
      return false;
    }
    let data = {
      emails: this.filterManagers.map((manager) => manager.email),
      goals: this.selectedGoals,
    };
    this.companyService.addGoalsToManagers(data).subscribe((res) => {
      if (res.status) {
        this.closeModal();
        this.getManagers([]);
        this.selectedGoals = [];
        swal.fire(
          "Goals added",
          "Goals has been sucessfully added!",
          "success"
        );
        return true;
      } else {
        return false;
      }
    });
    return true;
  }

  // closing the modal
  closeModal() {
    if (this.modalRef) {
      this.modalRef.hide();
      // this.modalRef = null;
    }
    this.deleteExcelSheet();
  }

  // View manager details
  openViewModal(id: any, template: any) {
    this.singleManagerDetails = this.existingManagers.data.find(
      (manager: any) => manager.id == id
    );
    console.log(this.singleManagerDetails, "manage");
    this.modalRef = this.modalService.show(template);
    this.companyService.addModal(this.modalRef);
  }

  // Destroys the component
  ngOnDestroy() {
    if (this.fileInput) {
      this.fileInput.nativeElement.value = "";
    }
    if (this.modalRef) {
      this.modalRef.hide();
      // this.modalRef = null;
    }
  }
}

export interface TableElement {
  id: string;
  name: string;
  email: number;
  action: any;
  goals: any;
}
