import {
  Component,
  OnInit,
  TemplateRef,
  ChangeDetectorRef,
} from "@angular/core";
import { CompanyServices } from "../../company.service";
import { Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
// import * as jwt_decode from 'jwt-decode';
import { jwtDecode } from "jwt-decode";
import { takeUntil } from "rxjs/operators"; // for rxjs ^5.5.0 lettable operators
import { Subject } from "rxjs";
import { LoginServices } from "../../../login/login.service";
import { Subscription } from "rxjs";
import { combineLatest } from "rxjs";
import swal from "sweetalert2";

@Component({
  selector: "app-group-packages-assign",
  templateUrl: "./group-packages-assign.component.html",
  styleUrls: ["./group-packages-assign.component.css"],
})
export class GroupPackagesAssignComponent implements OnInit {
  private ngUnsubscribe: Subject<any> = new Subject();
  subscriptions: Subscription[] = [];
  selectedManagers: any = [];
  modalRef!: BsModalRef;
  basicCountRem = -1;
  premiumCountRem = -1;
  specialCountRem = -1;
  customCountRem = -1;
  packageSelectionError = true;
  basicCount: number = 0;
  specialCount: number = 0;
  premiumCount: number = 0;
  customCount: number = 0;
  companyInfo: any;
  loading!: boolean;
  buttonDisable = true;
  mode: any;
  seCompanyId!: string;
  basic: any;
  premium: any;
  special: any;
  customFeatures = [];
  constructor(
    private changeDetection: ChangeDetectorRef,
    private companyService: CompanyServices,
    private modalService: BsModalService,
    private router: Router,
    private loginService: LoginServices
  ) {}
  ngOnInit() {
    this.getSelectedManager();
    this.getAllPackagesNo();
    let token: any = localStorage.getItem("token");
    let deodedToken = this.getDecodedAccessToken(token);
    // console.log(deodedToken);
    this.seCompanyId = deodedToken.companyId;
    //// console.log(this.modalRef)
  }

  /*
  Description: Decoding the token
  Date Of Creation: 2019
  Author: Aman Kumar Singh
  */

  getDecodedAccessToken(token: string): any {
    try {
      return jwtDecode(token);
    } catch (Error) {
      return null;
    }
  }

  /*
  Description: Package tracker for data-table
  Date Of Creation: 2019
  Author: Aman Kumar Singh
  */

  statusChecker(data: any) {
    this.companyService.statusTracer(data);
  }

  /*
  Description: function for opening model
  Date Of Creation: 2019
  Author: Aman Kumar Singh
  */

  assignPackageModal(template: TemplateRef<any>) {
    this.packageSelectionError = true;
    const _combine = combineLatest(
      this.modalService.onHide,
      this.modalService.onHidden
    ).subscribe(() => this.changeDetection.markForCheck());
    this.subscriptions.push(
      this.modalService.onHide.subscribe((reason: string) => {
        this.mode = "";
        this.basic = null;
        this.basicCountRem = -1;
        this.specialCountRem = -1;
        this.premiumCountRem = -1;
        this.customCountRem = -1;
      })
    );
    this.subscriptions.push(
      this.modalService.onHidden.subscribe((reason: string) => {
        this.mode = "";
        this.basic = null;
        this.basicCountRem = -1;
        this.specialCountRem = -1;
        this.premiumCountRem = -1;
        this.customCountRem = -1;
      })
    );
    //// console.log(this.mode , 'mode');
    //// console.log(this.basic , 'ode');
    this.subscriptions.push(_combine);
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: "my-modal" })
    );
    this.companyService.addModal(this.modalRef);
  }

  /*
  Description: function is using for getting selected managers for asssigning packages
  Date Of Creation: 2019
  Author: Aman Kumar Singh
  */

  getSelectedManager() {
    this.companyService.selectedmanagersForPackages.subscribe(
      (response: any) => {
        this.selectedManagers = response;
        if (this.selectedManagers.length > 0) {
          this.buttonDisable = false;
          this.packageSelectionError = false;
        } else {
          this.packageSelectionError = true;
          this.buttonDisable = true;
        }
      }
    );
  }
  /*
  Description: function is using for getting selected managers for asssigning packages
  Date Of Creation: 2019
  Author: Aman Kumar Singh
  */

  countChecker(data: any) {
    this.companyService.countCheck(data);
  }

  getAllPackagesNo() {
    this.companyService.counts.subscribe((response) => {
      this.companyInfo = response;
      if (response?.packageData) {
        this.basicCount = response.packageData.basic;
        this.premiumCount = response.packageData.premium;
        this.specialCount = response.packageData.special;
        this.customCount = response.packageData.custom;
        this.customFeatures = response.selectedFeatures;
      } else {
        this.basicCount = response.totalBasic;
        this.premiumCount = response.totalPremium;
        this.specialCount = response.totalSpecial;
        this.customCount = response.totalCustom;
        this.customFeatures = response.selectedFeatures;
      }
    });
  }
  /*
  Description: function for updating no. of counts of licence
  Date Of Creation: 2019
  Author: Aman Kumar Singh
  */

  packageSelection(event: any) {
    this.mode = event.target.value;
    //// console.log(this.mode);
    switch (event.target.value) {
      case "Profiling":
        this.basicCountRem = this.basicCount - this.selectedManagers.length;
        if (this.basicCountRem >= 0 && this.selectedManagers.length > 0) {
          this.packageSelectionError = false;
        } else {
          this.packageSelectionError = true;
        }
        this.premiumCountRem = -1;
        this.specialCountRem = -1;
        this.customCountRem = -1;
        break;
      case "Certification":
        this.premiumCountRem = this.premiumCount - this.selectedManagers.length;
        if (this.premiumCountRem >= 0 && this.selectedManagers.length > 0) {
          this.packageSelectionError = false;
        } else {
          this.packageSelectionError = true;
        }
        this.basicCountRem = -1;
        this.specialCountRem = -1;
        this.customCountRem = -1;
        break;
      case "Special":
      case "Special-Post Survey":
        this.specialCountRem = this.specialCount - this.selectedManagers.length;
        if (this.specialCountRem >= 0 && this.selectedManagers.length > 0) {
          this.packageSelectionError = false;
        } else {
          this.packageSelectionError = true;
        }
        this.basicCountRem = -1;
        this.premiumCountRem = -1;
        this.customCountRem = -1;
        break;
      case "Custom":
        this.customCountRem = this.customCount - this.selectedManagers.length;
        if (this.customCountRem >= 0 && this.selectedManagers.length > 0) {
          this.packageSelectionError = false;
        } else {
          this.packageSelectionError = true;
        }
        this.basicCountRem = -1;
        this.premiumCountRem = -1;
        this.specialCountRem = -1;
        break;
    }
  }
  /*
  Description: submitting packages
  Date Of Creation: 2019
  Author: Aman Kumar Singh
  */

  submitPackageToManager() {
    this.loading = true;
    //// console.log(this.companyInfo)
    let invoice =
      this.companyInfo.package[this.companyInfo.package.length - 1].invoice;
    let data = {
      mode: this.mode,
      managers: this.selectedManagers,
      invoiceId: invoice,
      seCompanyId: this.seCompanyId,
      customFeatures: this.customFeatures,
      packageCount:
        this.mode === "Profiling"
          ? this.basicCountRem
          : this.mode === "Certification"
          ? this.premiumCountRem
          : this.mode === "Special" || this.mode === "Special-Post Survey"
          ? this.specialCountRem
          : this.mode === "Custom"
          ? this.customCountRem
          : 0,
    };

    this.companyService
      .assignPackagesToManager(data)
      .subscribe((response: any) => {
        this.loading = false;
        if (response.status) {
          this.statusChecker(response.managersData);
          switch (response?.updatedPackageData?.packageName) {
            case "Profiling":
              this.basicCount =
                response?.updatedPackageData?.packageCount ||
                response.companyData.totalBasic;
              break;
            case "Certification":
              this.premiumCount =
                response?.updatedPackageData?.packageCount ||
                response.companyData.totalPremium;
              break;
            case "Special":
            case "Special-Post Survey":
              this.specialCount =
                response?.updatedPackageData?.packageCount ||
                response.companyData.totalSpecial;
              break;
            case "Custom":
              this.customCount =
                response?.updatedPackageData?.packageCount ||
                response.companyData.totalCustom;
              break;
            default:
              break;
          }

          let counts = {
            basic: this.basicCount,
            premium: this.premiumCount,
            special: this.specialCount,
            custom: this.customCount,
            type: "change",
          };

          this.countChecker(counts);
          swal
            .fire({
              title: "Package Update",
              text: response.message,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            })
            .then(() => window.location.reload());
        } else {
          swal.fire({
            title: "Package Update",
            text: response.message,
            icon: "error",
            showConfirmButton: false,
            timer: 2500,
          });
        }
      });
    this.packageSelectionError = true;
    this.modalRef.hide();
    // this.modalRef = null;
    this.redirectTo("/company/company-dashboard");
  }

  closeModal() {
    this.modalRef.hide();
    // this.modalRef = null;
  }
  redirectTo(uri: string) {
    this.router
      .navigateByUrl("/", { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }
}
