import { environment } from "../../environments/environment";
import { Injectable } from "@angular/core";
import {
  HttpBackend,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { Router } from "@angular/router";
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/catch';
// import 'rxjs/add/observable/throw';
import { BehaviorSubject } from "rxjs";
import { Observable, Subject } from "rxjs";
import { config } from "../config";
import { TokenService } from "./lfi-token.service";
import { map } from "rxjs/operators";
import { BsModalRef } from "ngx-bootstrap/modal";
import { AnimationStyleMetadata } from "@angular/animations";
@Injectable()
export class AdminServices {
  // [x: string]: any;
  // updateB2cR2Survey(data: { endDate: any; r2EmailId: any; }) {
  //   throw new Error("Method not implemented.");
  // }
  activeModals: BsModalRef[] = [];
  public serverUrl = environment.apiReadUrl;
  public imageRecognitionUrl = environment.imageRecognitionUrl;
  public superAdminApiUrl = "/api/v2/superadmin";
  public uploadApiUrl = "/api/v2/files";
  public surveyEngine = "/api/v2/surveyEngineRequest";
  public triggersApiUrl = "/api/v2/triggers";
  public managerApiUrl = "/api/v2/manager";
  public landingApiUrl = "/api/v2/landing";
  public fileUrl = "/api/v2/files";
  public companyUrl = "/api/v2/company";
  public ssoApiUrl = "/api/v2/sso";
  public commonUserApiUrl = "/api/v2/user";
  public popUpShow = new Subject<any>();
  private $subThemes = new BehaviorSubject([]);
  private LFI_URL = environment.lfiUrl;
  public analyticsApiUrl = "/api/v2/analytics";
  private visualizationSubject = new BehaviorSubject<any[]>([]);
  public visualizationList$: Observable<any[]> =
    this.visualizationSubject.asObservable();
  constructor(
    private http: HttpClient,
    private router: Router,
    private tokenService: TokenService
  ) {}

  roleGuard() {
    if (!localStorage.getItem("token")) {
      return false;
    } else if (
      localStorage.getItem("token") &&
      localStorage.getItem("role") != "Super Admin"
    ) {
      return false;
    } else {
      return true;
    }
  }
  getNudges() {
    return this.http.get(this.serverUrl + this.superAdminApiUrl + "/getNudges");
  }
  getNudgeSubjectMapping() {
    return this.http.get(
      this.serverUrl + this.superAdminApiUrl + "/manageNudgeSubject"
    );
  }
  addSubjectLine(data) {
    return this.http.post(
      this.serverUrl + this.superAdminApiUrl + "/manageNudgeSubject",
      data
    );
  }
  getNudgeEmail() {
    return this.http.get(
      this.serverUrl + this.superAdminApiUrl + "/getNudgeEmailTemplate"
    );
  }
  getActiveABTestUsers() {
    return this.http.get(
      this.serverUrl + this.superAdminApiUrl + "/getActiveABTestUsers"
    );
  }
  deactivateEmailTemplate(data) {
    return this.http.post(
      this.serverUrl + this.superAdminApiUrl + "/deactivateEmailTemplate",
      data
    );
  }
  updateSubjectLine(data) {
    return this.http.put(
      this.serverUrl + this.superAdminApiUrl + "/manageNudgeSubject",
      data
    );
  }
  getNudgeCategories() {
    return this.http.get(
      this.serverUrl + this.superAdminApiUrl + "/getNudgeCategories"
    );
  }
  addCategory(data) {
    return this.http.post(
      this.serverUrl + this.superAdminApiUrl + "/manageCategory",
      data
    );
  }
  updateCategory(data) {
    return this.http.put(
      this.serverUrl + this.superAdminApiUrl + "/manageCategory",
      data
    );
  }
  uploadNudgeImages(data) {
    return this.http.post(
      this.serverUrl + this.uploadApiUrl + "/uploadNudgeImages",
      data
    );
  }
  addNudge(data) {
    return this.http.post(
      this.serverUrl + this.superAdminApiUrl + "/manageNudge",
      data
    );
  }
  updateNudge(data) {
    return this.http.put(
      this.serverUrl + this.superAdminApiUrl + "/manageNudge",
      data
    );
  }
  showPopUpModel(data) {
    this.popUpShow.next(data);
  }

  acceptTandC(data) {
    return this.http
      .post(this.serverUrl + this.managerApiUrl + "/acceptTandC", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  getR2B2CManagerList(year: number[]) {
    const options = {
      params: {
        year: year.join(","), // Assuming year is an array of numbers
      },
    };

    return this.http
      .get(
        `${this.serverUrl}${this.superAdminApiUrl}/getR2B2CManagerList`,
        options
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  checkToken(data) {
    return this.http
      .post(this.serverUrl + this.ssoApiUrl + "/checkToken", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  generateToken(data) {
    return this.http
      .post(this.serverUrl + this.commonUserApiUrl + "/generateToken", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  addUser(userData) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/addUser", userData)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  public getUsers() {
    return this.http
      .get(
        this.serverUrl +
          this.superAdminApiUrl +
          "/getUsers/" +
          localStorage.getItem("id")
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getUserDetails(userId) {
    return this.http
      .get(this.serverUrl + this.superAdminApiUrl + "/getUserDetails/" + userId)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  updateUser(userDetails) {
    return this.http
      .put(this.serverUrl + this.superAdminApiUrl + "/updateUser", userDetails)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  addManagerMantra(mantraData) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/addManagerMantra",
        mantraData
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getManagerMantras() {
    return this.http
      .get(
        this.serverUrl +
          this.superAdminApiUrl +
          "/getManagerMantras/" +
          localStorage.getItem("id")
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getManagerMantraDetails(managerMantraId) {
    return this.http
      .get(
        this.serverUrl +
          this.superAdminApiUrl +
          "/getManagerMantraDetails/" +
          managerMantraId
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  updateManagerMantra(managerMantraDetails) {
    return this.http
      .put(
        this.serverUrl + this.superAdminApiUrl + "/updateManagerMantra",
        managerMantraDetails
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  addSubAdmin(subAdminDetails) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/addSubAdmin",
        subAdminDetails
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getSubAdmins() {
    return this.http
      .get(
        this.serverUrl +
          this.superAdminApiUrl +
          "/getSubAdmins/" +
          localStorage.getItem("id")
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getSubAdminDetails(subAdminId) {
    return this.http
      .get(
        this.serverUrl +
          this.superAdminApiUrl +
          "/getSubAdminDetails/" +
          subAdminId
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  updateSubAdmin(subAdminDetails) {
    return this.http
      .put(
        this.serverUrl + this.superAdminApiUrl + "/updateSubAdmin",
        subAdminDetails
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /*corporate companies api methods*/

  getCorporateCompanies() {
    return this.http
      .get(
        this.serverUrl +
          this.superAdminApiUrl +
          "/getCorporateCompanies/" +
          localStorage.getItem("id")
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  addCorporateCompany(companyDetails) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/addCorporateCompany",
        companyDetails
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getCorporateCompanyDetails(corporateCompanyId) {
    return this.http
      .get(
        this.serverUrl +
          this.superAdminApiUrl +
          "/getCorporateCompanyDetails/" +
          corporateCompanyId
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  updateCorporateCompanyAdmin(corporateCompanyDetails) {
    return this.http
      .put(
        this.serverUrl + this.superAdminApiUrl + "/updateCorporateCompanyAdmin",
        corporateCompanyDetails
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getSurveys() {
    return this.http
      .get(
        this.serverUrl +
          this.superAdminApiUrl +
          "/getSurveys/" +
          localStorage.getItem("id")
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  addSurvey(surveyDetails) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/addSurvey",
        surveyDetails
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  publishSurvey(publishSurveyDetails) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/publishSurvey",
        publishSurveyDetails
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  assignSurvey(assignSurveyDetails) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/assignSurvey",
        assignSurveyDetails
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  getallCompanies() {
    return this.http
      .get(this.serverUrl + this.surveyEngine + "/allCompanies")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /* New COde*/

  /*
Description: This api is for adding companies
Date Of Creation: 24-12-2018
Author: Sukumar
*/

  addCompanies(companyDetails) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/addCompanies",
        companyDetails
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /*
  Description: This api is for getting companies added by admin
  Date Of Creation: 27-12-2018
  Author: Sukumar
  */
  getCompanies() {
    return this.http
      .get(this.serverUrl + this.superAdminApiUrl + "/getCompanies")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /*
  Description: This api is for getting companies added by admin
  Date Of Creation: 27-12-2018
  Author: Sukumar
  */
  checkWebsite(websiteUrl) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/checkWebsite", {
        websiteUrl,
      })
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /*
  Description: This api is for checking existing emails
  Date Of Creation: 31-12-2018
  Author: Sukumar
  */
  checkEmail(email) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/checkEmail", { email })
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /*
  Description: This api is for checking existing emails
  Date Of Creation: 31-12-2018
  Author: Sukumar
  */
  checkEmailForMentor(email) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/checkEmailForMentor", {
        email,
      })
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /*
  Description: This api is for getting companies admin list
  Date Of Creation: 28-12-2018
  Author: Sukumar
  */
  getCompanyAdmin(surveyEngineCompanyid) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/getCompanyAdmin", {
        surveyEngineCompanyid: surveyEngineCompanyid,
      })
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /*
  Description: This api is for adding new admin
  Date Of Creation: 28-12-2018
  Author: Sukumar
  */
  addCompanyAdmin(adminDetails) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/addCompanyAdmin",
        adminDetails
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  /*
  Description: This api is for editing company admin
  Date Of Creation: 28-10-2021
  Author: Hamza
  */
  editCompanyAdmin(adminDetails) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/editCompanyAdmin",
        adminDetails
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /*
  Description: This api is for adding new admin
  Date Of Creation: 28-12-2018
  Author: Sukumar
  */
  getallMentors(allmentor) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/getallMentors",
        allmentor
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /*
  Description: This api is for adding new admin
  Date Of Creation: 28-12-2018
  Author: Sukumar
  */
  assignMentor(data) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/assignMentor", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /*
  Description: This api is for adding new admin
  Date Of Creation: 28-12-2018
  Author: Sukumar
  */
  removeMentorFromManager(data) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/removeMentorFromManager",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  /*
  Description: This api is for adding new admin
  Date Of Creation: 28-12-2018
  Author: Sukumar
  */
  addMentor(mentorDetails) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/addMentor",
        mentorDetails
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /*
  Description: This api is for changing status
  Date Of Creation: 30-12-2018
  Author: Sukumar
  */
  changeStatus(email, status) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/changeStatus", {
        email,
        status,
      })
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /*
  Description: This api is for changing status
  Date Of Creation: 30-12-2018
  Author: Sukumar
  */
  changeGlobalCouponStatus(objectId, status) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/changeGlobalCouponStatus",
        { objectId, status }
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /*
  Description: This api is for changing status
  Date Of Creation: 30-12-2018
  Author: Sukumar
  */
  changeMentorStatus(email, status) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/changeMentorStatus", {
        email,
        status,
      })
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  /*
  Description: This api is for getting single company data
  Date Of Creation: 31-12-2018
  Author: Sukumar
  */
  getCompanyData(companyId) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/getCompanyData", {
        companyId: companyId,
      })
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /*
  Description: This api is for updating company data
  Date Of Creation: 31-12-2018
  Author: Sukumar
  */
  updateCompanyData(data) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/updateCompanyData", {
        data,
      })
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /*
  Description: This api is for updating company data
  Date Of Creation: 31-12-2018
  Author: Sukumar
  */
  checkCompany(companyId) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/checkCompany", {
        companyId,
      })
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /*
  Description: This api is for updating company data
  Date Of Creation: 31-12-2018
  Author: Sukumar
  */
  getManagers(data) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/getManagers", data)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  getB2cManagers() {
    return this.http
      .get(this.serverUrl + this.superAdminApiUrl + "/getB2cManagers")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /*
  Description: This api is for updating company data
  Date Of Creation: 31-12-2018
  Author: Sukumar
  */
  getCertifiedManagers() {
    return this.http
      .get(this.serverUrl + this.superAdminApiUrl + "/getCertifiedManagers")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getIndustries() {
    return this.http
      .get(this.serverUrl + this.companyUrl + "/getIndustries")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /*
  Description: This api is for getting information of single manager
  Date Of Creation: 02-01-2019
  Author: Sukumar
  */
  getManagerProfile(mgrId) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/getManagerProfile", {
        mgrId: mgrId,
      })
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /*
  Description: This api is for getting email template
  Date Of Creation: 02-01-2019
  Author: Sukumar
  */
  getAcceptTemplate() {
    return this.http
      .get(this.serverUrl + this.superAdminApiUrl + "/getAcceptTemplate")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /*
  Description: This api is for certification of manager
  Date Of Creation: 02-01-2019
  Author: Sukumar
  */
  managerCertification(email, status, content) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/managerCertification", {
        email,
        status,
        content,
      })
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /*
  Description: This api is for getting information of survey of single manager
  Date Of Creation: 02-01-2019
  Author: Sukumar
  */
  getManagerSurvey(mgrId) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/getManagerSurvey", {
        mgrId: mgrId,
      })
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /*
  Description: This api is for getting information of action planning
  Date Of Creation: 02-01-2019
  Author: Sukumar
  */
  getManagerActionPlanning(mgrId) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/getManagerActionPlanning",
        { mgrId: mgrId }
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /*
  Description: This api is for getting information of action planning
  Date Of Creation: 02-01-2019
  Author: Sukumar
  */
  getStatistics() {
    return this.http
      .get(this.serverUrl + this.superAdminApiUrl + "/getStatistics")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /*
  Description: This api is to give certificate to the manager
  Date Of Creation: 07-01-2019
  Author: Sukumar
  */
  issueCertificate(id) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/issueCertificate", {
        id: id,
      })
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /*
  Description: This api is to revoke certificate of manager
  Date Of Creation: 09-01-2019
  Author: Sukumar
  */
  revokeCertificate(id) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/revokeCertificate", {
        id: id,
      })
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /*
  Description: This api is to give certificate to the manager
  Date Of Creation: 09-01-2019
  Author: Sukumar
  */
  changeModerationStatus(id, status) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/changeModerationStatus",
        { id, status }
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /*
  Description: This api is to update company package
  Date Of Creation: 10-01-2019
  Author: Sukumar
  */
  updateCompanyPackage(id, basic, special, premium, custom, invoice) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/updateCompanyPackage", {
        id: id,
        basic,
        special,
        premium,
        custom,
        invoice,
      })
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  registeredCouponManagers() {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/registeredCouponManagers",
        {}
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  checkCouponEmail(email) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/checkCouponEmail", {
        email,
      })
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  /*
Description: This function is to assign coupon to manager
Month Of Creation: 25-01-2019
Author: Sukumar
*/
  assignCoupon(email, feature) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/assignCoupon", {
        email,
        feature,
      })
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getAllFeatures() {
    return this.http
      .get(this.serverUrl + this.commonUserApiUrl + "/getAllFeatures")
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /*
Description: This function is to assign global coupon
Month Of Creation: 27-11-2019
Author: Sukumar
*/
  addCoupondiscountPercentage(discount, feature) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/addCoupondiscountPercentage",
        { discount, feature }
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  /*
addCoupondiscountPercentage(discount, feature) {
  return this.http
    .post(
      this.serverUrl + this.superAdminApiUrl + "/addCoupondiscountPercentage",
      { discount, feature }
    )
    .pipe(map((response: Response) => {
      return <any>response;
    }));
}

/*
Description: This function is to assign global coupon
Month Of Creation: 27-11-2019
Author: Sukumar
*/
  getAllGlobalCoupons(data) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/getAllGlobalCoupons", {
        data,
      })
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  /*
Description: This function is to revoke coupon from manager
Month Of Creation: 25-01-2019
Author: Sukumar
*/
  revokeCoupon(email) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/revokeCoupon", { email })
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  getSasInfo(id) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/getSasInfo", { id })
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /*
Description: This function is to get Company List
Month Of Creation: 29-05-2019
Author: Aman
*/
  getCompanyList() {
    return this.http
      .get(this.serverUrl + this.superAdminApiUrl + "/getCompanyList")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  /*
Description: This function is to create Bu Login User
Month Of Creation: 29-05-2019
Author: Aman
*/
  addBuLoginUser(data) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/addBuLoginUser", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  /*
Description: This function is to get bu login users
Month Of Creation: 29-05-2019
Author: Aman
*/

  getAllBuLogins() {
    return this.http
      .get(this.serverUrl + this.superAdminApiUrl + "/getAllBuLogins")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  /*
Description: This function is to get Company Manager List
Month Of Creation: 29-05-2019
Author: Aman
*/

  getCompanyManagerList(id) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/getCompanyManagerList",
        id
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  /*
Description: This function is to get Logs based on  filters submmited
Month Of Creation: 29-05-2019
Author: Kranti
*/

  getLogsFromDB(data) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/getLogsFromDB", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  /*
Description: This function is to add BuLogin Id To SelectedManagers
Month Of Creation: 30-05-2019
Author: Aman
*/
  addBuLoginIdToSelectedManagers(data) {
    return this.http
      .post(
        this.serverUrl +
          this.superAdminApiUrl +
          "/addBuLoginIdToSelectedManagers",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  /*
Description: This function is to delete BuLogin Id To SelectedManagers
Month Of Creation: 30-05-2019
Author: Aman
*/
  removeManagerFromBuLogin(data) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/removeManagerFromBuLogin",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  deleteBuLogin(data) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/deleteBuLogin", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  getSubscribedManagersList() {
    return this.http
      .get(this.serverUrl + this.triggersApiUrl + "/getSubscribedManagersList")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  addTemplate(data) {
    return this.http
      .post(this.serverUrl + this.triggersApiUrl + "/addTemplate", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getTemplates() {
    return this.http
      .get(this.serverUrl + this.triggersApiUrl + "/getTemplates")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  createTask(data) {
    return this.http
      .post(this.serverUrl + this.triggersApiUrl + "/createTask", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  editTemplate(data) {
    return this.http
      .post(this.serverUrl + this.triggersApiUrl + "/editTemplate", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  deleteTemplate(data) {
    return this.http
      .post(this.serverUrl + this.triggersApiUrl + "/deleteTemplate", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  getAllTasks() {
    return this.http
      .get(this.serverUrl + this.triggersApiUrl + "/getAllTasks")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  deleteTask(data) {
    return this.http
      .post(this.serverUrl + this.triggersApiUrl + "/deleteTask", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  getRawData(data) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/getRawData", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getRawDataManager(data) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/getRawDataManager", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  // Get survey by individualCompany company url
  getIndividualCompanySurvey(companyId: string) {
    let params = new HttpParams().set("companyId", companyId);
    return this.http
      .get(this.serverUrl + this.companyUrl + "/getCompanyWiseSurvey", {
        params: params,
      })
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getAllRawDataBySurveyGroup(data) {
    // return this.http
    //   .post(this.serverUrl + this.superAdminApiUrl + "/getRawDataBulk", data)
    //   .pipe(map((response: Response) => {
    //     return <any>response;
    //   }));

    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/fetchRawData", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getReliabilityReport(data) {
    return this.http
      .post(this.serverUrl + this.companyUrl + "/getReliabilityReport", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getAllRawDataBulk(data) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/getRawDataBulk", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getAuditSheet(data) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/getAuditSheet", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  updateBu(data) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/updateBu", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  getTcData() {
    return this.http
      .get(this.serverUrl + this.managerApiUrl + "/getAcceptedTandC")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  getManagerCheckListData() {
    return this.http
      .get(this.serverUrl + this.managerApiUrl + "/getManagerCheckListData")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  getManagersScore() {
    return this.http
      .get(this.serverUrl + this.managerApiUrl + "/getAllScores")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  getManagerFeedback() {
    return this.http
      .get(this.serverUrl + this.managerApiUrl + "/getManagerFeedback")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  // getLdList
  getLdList(data) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/getLdList", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  // addCustomLd
  addCustomLd(data) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/addCustomLd", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getHrDetails() {
    return this.http
      .get(this.serverUrl + this.managerApiUrl + "/getHrDetails")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getManagerStatus() {
    return this.http
      .get(this.serverUrl + this.managerApiUrl + "/getManagerStatus")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  revokeDate(data) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/revokeDate", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  // getManagerLdLists
  getManagerLdLists() {
    return this.http
      .get(this.serverUrl + this.superAdminApiUrl + "/getManagerLdLists")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  // getR2CompanyManagerData
  getR2CompanyManagerData(data) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/getR2CompanyManagerData",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  sendEmailToHr(data) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/sendEmailToHr", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  //resend Email Logs service
  reSendEmailLogs(data) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/reSendEmailLogs", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  getAllRound2ListByCompany(data) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/getAllRound2ListByCompany",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  launchR2Survey(data) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/launchR2Survey", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  updateR2Survey(data) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/updateR2Survey", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  updateB2cR2Survey(data) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/updateB2cR2Survey", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  updateB2cSurvey(data) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/updateB2cSurvey", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  getR2ManagerList(data) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/getR2ManagerList", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  sendB2CMailToHr(data) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/sendB2CMailToHr", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  getListOfB2CAcceptedByHr(data) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/getListOfB2CAcceptedByHr",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  getR2Report(data) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/getR2Report", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getR2UserFeedbackReport(data) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/getR2FeedbackReport",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  createLandingTemp(data) {
    return this.http
      .post(this.serverUrl + this.landingApiUrl + "/createLandingTemp", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  updateManagerDetails(data) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/updateManagerDetails",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getAllLanding() {
    return this.http
      .get(this.serverUrl + this.landingApiUrl + "/getAllLanding")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  deleteLanding(templateId) {
    return this.http
      .post(this.serverUrl + this.landingApiUrl + "/deleteLanding", {
        templateId,
      })
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getUpdateLanding(templateId) {
    return this.http
      .get(
        this.serverUrl + this.landingApiUrl + "/getUpdateLanding/" + templateId
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  updateLanding(templateDetails) {
    return this.http
      .post(this.serverUrl + this.landingApiUrl + "/updateCompanyData", {
        templateDetails,
      })
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getSurvey() {
    return this.http
      .get(this.serverUrl + this.superAdminApiUrl + "/getSurvey")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getPulseSurveyParticipants() {
    return this.http
      .get(
        this.serverUrl + this.superAdminApiUrl + "/getPulseSurveyParticipants"
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getParticpants() {
    return this.http
      .get(this.serverUrl + this.superAdminApiUrl + "/getParticpants")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  updateSurvey(data) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/updateSurvey", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  updatePulseSurveyParticipants(data) {
    return this.http
      .post(
        this.serverUrl +
          this.superAdminApiUrl +
          "/updatePulseSurveyParticipants",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  updateParticpants(data) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/updateParticpants", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getUser() {
    return this.http
      .get(this.serverUrl + this.superAdminApiUrl + "/getUser")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  allManagersData() {
    return this.http
      .get(this.serverUrl + this.superAdminApiUrl + "/allManagersData")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getallMentorsRequests() {
    return this.http
      .get(this.serverUrl + this.superAdminApiUrl + "/getallMentorsRequests")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  mentorRequestAction(data) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/mentorRequestAction",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  certificateOfMerit() {
    return this.http
      .get(this.serverUrl + this.superAdminApiUrl + "/certificateOfMerit")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  assignCertificateOfMerit(data) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/assignCertificateOfMerit",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  gpmList() {
    return this.http
      .get(this.serverUrl + this.superAdminApiUrl + "/gpmList")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  gpmCertificate(data) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/gpmCertificate", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getLearningManager() {
    return this.http
      .get(this.serverUrl + this.superAdminApiUrl + "/getLearningManager")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getLearningScore(data) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/getLearningScore", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  // Getting the list of all the benchmarks
  getAllBenchmarks() {
    return this.http
      .get(this.serverUrl + this.superAdminApiUrl + "/getAllBenchmarks")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  // Getting the filter data for the benchmark
  getBenchmarkFilterData() {
    return this.http
      .get(this.serverUrl + this.superAdminApiUrl + "/getBenchmarkFilterData")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  // filterDataForBenchmark
  filterDataForBenchmark(data) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/filterDataForBenchmark",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  // createBenchmark
  createBenchmark(data) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/createBenchmark", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getRewardRequests(data) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/getRewardRequests", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  updateRewardRequestStatus(data) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/updateRewardRequestStatus",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  uploadIcon(data) {
    const headers = new HttpHeaders().set(
      "token",
      localStorage.getItem("token")
    );
    return this.http
      .post(this.serverUrl + this.fileUrl + "/uploadIcon", data, {
        headers: headers,
      })
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  //getEmailLogs
  getEmailLogs(params) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/getEmailLogs", params)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  reSendEmail(data) {
    return this.http
      .patch(this.serverUrl + this.superAdminApiUrl + "/getEmailLogs", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getEmailAnalyticsData(params) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/getEmailAnalyticsData",
        params
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  acceptTermsAndCondition(data) {
    return this.http
      .post(
        this.serverUrl + this.managerApiUrl + "/acceptTermsAndCondition",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  onScoreCalculation() {
    return this.http
      .post(this.serverUrl + this.managerApiUrl + "/onScoreCalculation", {})
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getNudgeList(data) {
    return this.http.post(
      this.serverUrl + this.superAdminApiUrl + "/getNudgeList",
      data
    );
  }
  getPagesData() {
    return this.http
      .get(this.serverUrl + this.superAdminApiUrl + "/getPage")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getComponentData() {
    return this.http
      .get(this.serverUrl + this.superAdminApiUrl + "/getComponent")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  editComponent(data) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/componentOperations",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  createPage(data) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/pageOperations", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  editPage(data) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/pageOperations", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  isActivePage(data) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/pageOperations", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  isActiveComponent(data) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/componentOperations",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  createComponent(data) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/componentOperations",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getSubComponentData() {
    return this.http
      .get(this.serverUrl + this.superAdminApiUrl + "/getSubComponent")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  createSubComponent(data) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/subComponentOperations",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  isActiveSubComponent(data) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/subComponentOperations",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  editSubComponent(data) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/subComponentOperations",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  // Getting the list of all the User Guide Hotspot Data
  getAllUserGuide(data) {
    return this.http
      .get(this.serverUrl + this.managerApiUrl + "/getUserGuideData", {
        params: data,
      })
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  updateNudgeLibrary(data) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/updateNudgeLibrary",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  userGuideOperations(data) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/userGuideOperations",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  createUserGuide(data) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/createUserGuide", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  updatedUserGuide(data) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/updatedUserGuide", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  addReasearchQuestion(data) {
    return this.http.post(
      this.serverUrl + this.superAdminApiUrl + "/addResearchQuestion",
      data
    );
  }

  updateResearchQuestion(data) {
    return this.http.post(
      this.serverUrl + this.superAdminApiUrl + "/updateResearchQuestion",
      data
    );
  }

  getResearchQuestion(data) {
    const params = new HttpParams().set(data.key, data.value);
    return this.http.get(
      this.serverUrl + this.superAdminApiUrl + "/getResearchQuestion?" + params
    );
  }

  deleteResearchQuestion(data) {
    const params = new HttpParams().set(data.key, data.value);
    return this.http.delete(
      this.serverUrl +
        this.superAdminApiUrl +
        "/deleteResearchQuestion?" +
        params
    );
  }

  downloadResearchQstReport(data) {
    return this.http.post(
      this.serverUrl + this.superAdminApiUrl + "/getResearchQuestionReport",
      data
    );
  }

  // Getting the list of all the Accomplishment Popover Data
  getAllPopover(data) {
    return this.http.get(
      this.serverUrl + this.superAdminApiUrl + "/getPopoverData",
      { params: data }
    );
  }

  get getSubthemes() {
    return this.$subThemes;
  }

  getSubthemesData() {
    return this.http
      .get(this.serverUrl + this.superAdminApiUrl + "/getSubthemes")
      .subscribe((response: any) => {
        response && response.status && this.$subThemes.next(response.data);
      });
  }

  getThemes() {
    return this.http
      .get(this.serverUrl + this.superAdminApiUrl + "/getThemes")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getSubthemessData() {
    return this.http
      .get(this.serverUrl + this.superAdminApiUrl + "/getSubthemes")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  subthemeOperations(data) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/subthemesOperations",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  // Edit and Delete Accomplishment Popover Data
  createPopoverData(data) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/createPopoverData", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  updatePopoverData(data) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/updatePopoverData", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getSurveyQuestions(data) {
    return this.http
      .get(this.serverUrl + this.superAdminApiUrl + "/getSurveyQuestions", {
        params: data,
      })
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  surveyQuestionsOperations(data) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/surveyQuestionsOperations",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getMasterQuestionnaires() {
    return this.http
      .get(this.serverUrl + this.superAdminApiUrl + "/getMasterQuestionnaires")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  saveMasterQuestionnaire(data) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/saveMasterQuestionnaire",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  deleteMasterQuestionnaireQuestion(data) {
    return this.http
      .post(
        this.serverUrl +
          this.superAdminApiUrl +
          "/deleteMasterQuestionnaireQuestion",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getQuestionnaireQuestions(data) {
    return this.http
      .get(
        this.serverUrl + this.superAdminApiUrl + "/getQuestionnaireQuestions",
        { params: data }
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getFilteredQuestions(data) {
    return this.http
      .get(this.serverUrl + this.superAdminApiUrl + "/getFilteredQuestions", {
        params: data,
      })
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getGupShupTemplate(query) {
    return this.http.get(
      this.serverUrl + this.superAdminApiUrl + "/getWhatsappTemplates?" + query
    );
    // const headers = new HttpHeaders()
    //   .set("content-type", "application/x-www-form-urlencoded")
    //   .set("apikey", config.gupshupApiKey);
    // return this.customHttpClient.get(
    //   "https://api.gupshup.io/sm/api/v1/template/list/GreatManagerInstitute",
    //   { headers: headers }
    // );
  }

  sendWhatsAppMsg(data) {
    return this.http.post(
      this.serverUrl + this.superAdminApiUrl + "/sendWhatsappMsg",
      data
    );
  }

  uploadCompanyAssets(data) {
    return this.http.post(
      this.serverUrl + this.superAdminApiUrl + "/uploadCompanyAssets",
      data
    );
  }

  getCompanyAssets(data: any) {
    return this.http.get(
      this.serverUrl + this.superAdminApiUrl + "/getCompanyAssets",
      {
        params: data,
      }
    );
  }

  generateCertificate(data) {
    return this.http.post(
      this.serverUrl + this.superAdminApiUrl + "/generateCertificate",
      data
    );
  }

  downloadCertificateSample(data) {
    return this.http.post(
      this.serverUrl + this.superAdminApiUrl + "/downloadCertificateSample",
      data,
      {
        responseType: "blob",
      }
    );
  }

  public getEmailTemplates(params) {
    return this.http
      .get(this.serverUrl + this.companyUrl + "/getEmailTemplates", {
        params: params,
      })
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getPreProceessedData(payloadData: { seSurveyIds: number[] }) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/getPreProceessedData",
        payloadData
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getQualifiedList(data) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/getQualifiedList", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  roundTwoAutomation(data) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/roundTwoAutomation",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
  roundTwoAutomationV2(payload: {
    seSurveyIds: number[];
    surveyGroupId: number | null;
    recognitionYear: number;
  }): Observable<any> {
    return this.http.post(
      this.serverUrl + this.superAdminApiUrl + "/roundTwoAutomationV2",
      payload
    );
  }

  roundTwoAutomationB2C(data) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/roundTwoAutomationB2C",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getQualifiedListB2C(data) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/getQualifiedListB2C",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getR2PreProcessedDataB2C(data) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/getR2PreProcessedDataB2C",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getCompanyScheduleEmails(params) {
    return this.http
      .get(
        this.serverUrl + this.superAdminApiUrl + "/getCompanyScheduleEmails",
        {
          params: params,
        }
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  updateCompanyScheduleEmail(data: any) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/updateCompanyScheduleEmail",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  // LFI SERVICE
  // getReport(formData: any): Observable<any> {
  //   const headers = new HttpHeaders({
  //     Authorization: `Bearer ${this.tokenService.getBearerToken()}`,
  //   });
  //   return this.http.post(this.LFI_URL + '/reports/clients', formData, {
  //     headers,
  //   });
  // }

  getReport(formData: any): Observable<Blob> {
    const headers = {
      Authorization: `Bearer ${this.tokenService.getBearerToken()}`,
    };
    return this.http.post<Blob>(`${this.LFI_URL}/reports/clients`, formData, {
      headers,
      responseType: "blob" as "json", // Important for Blob response
    });
  }

  getDataToAssignStatements(params) {
    return this.http
      .get(
        this.serverUrl + this.superAdminApiUrl + "/getDataToAssignStatements",
        {
          params: params,
        }
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getCompanyComponentMapping(companyId: number): Observable<any> {
    const companyData = {
      companyId: companyId,
    };
    return this.http.post(
      this.serverUrl + this.superAdminApiUrl + "/getCompnayComponentMapping",
      companyData
    );
  }

  getActiveModals(): BsModalRef[] {
    return this.activeModals;
  }
  addModal(modalRef: BsModalRef): void {
    this.activeModals.push(modalRef);
  }
  removeModal(modalRef?: BsModalRef): void {
    this.activeModals = [];
  }
  uploadCertificate(data: any): Observable<any> {
    return this.http.post(
      this.serverUrl + this.superAdminApiUrl + "/uploadMultipleCertificates",
      data
    );
  }
  getVisualization() {
    return this.http.get(
      this.serverUrl + this.analyticsApiUrl + "/getVisualization"
    );
  }
  createVisualization(data): Observable<any> {
    return this.http.post(
      this.serverUrl + this.analyticsApiUrl + "/createVisualization",
      data
    );
  }

  updateVisualization(id: number, data: any): Observable<any> {
    return this.http.put(
      this.serverUrl + this.analyticsApiUrl + "/updateVisualization",
      data
    );
  }

  deleteVisualization(id: number): Observable<any> {
    return this.http.delete(
      this.serverUrl + this.analyticsApiUrl + "/deleteVisualization" + `/${id}`
    );
  }
  updateVisualizationList(users: any[]) {
    this.visualizationSubject.next(users);
  }

  getSurveyDialogs() {
    return this.http
      .get(this.serverUrl + this.superAdminApiUrl + "/surveyDialogs")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  surveyDialogOperation(data) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/surveyDialogs", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getSurveyContentPages() {
    return this.http
      .get(this.serverUrl + this.superAdminApiUrl + "/surveyContentPages")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  surveyContentPageOperation(data) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/surveyContentPages",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  // uploadSurveyContentPageImages
  uploadSurveyContentPageImages(data) {
    return this.http.post(
      this.serverUrl + this.superAdminApiUrl + "/uploadSurveyContentPageImages",
      data
    );
  }

  getSurveyFillerMapping() {
    return this.http
      .get(this.serverUrl + this.superAdminApiUrl + "/surveyFillerMapping")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  surveyFillerMappingOperation(data) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/surveyFillerMapping",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  addSurveyFillerSequenceMapping(data) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/surveyFillerSequenceMapping",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getNudgeCronConfigs(): Observable<any> {
    return this.http
      .get(this.serverUrl + this.superAdminApiUrl + "/nudgeCronConfigs")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  nudgeCronConfigOperation(data: any): Observable<any> {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/nudgeCronConfigOperation",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getVaryingFromNames(data): Observable<any> {
    return this.http
      .get(this.serverUrl + this.superAdminApiUrl + "/varyingFromNames", {
        params: data,
      })
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  nudgeVaryingFromNamesOperation(data: any): Observable<any> {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + "/varyingFromNames", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  bulkUploadVaryingNames(names) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/bulkUploadVaryingNames",
        { names }
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getAllAnalysedFiles() {
    return this.http
      .get(this.imageRecognitionUrl + "/analyse/performance_report")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  analysePerformance(data: any) {
    return this.http
      .post(this.imageRecognitionUrl + "/analyse/performance_analysis", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  analyseToxicity(data: any) {
    return this.http
      .post(this.imageRecognitionUrl + "/analyse/toxicity_analysis", data)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getAnalysisTypes() {
    return this.http
      .get(this.imageRecognitionUrl + "/analyse/get_analysis_type")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getAnalysisFilesById(id: any) {
    return this.http
      .get(this.imageRecognitionUrl + "/analyse/get_analysis_by_type/" + id)
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getAllAchievement() {
    return this.http
      .get(this.serverUrl + this.superAdminApiUrl + "/get_all_achievement")
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  updateAchievement(data: any) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/update_achievement",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  addCustomAchievement(data: any) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/addCustomAchievement",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  getCustomAchievementDetails(data: any) {
    return this.http
      .post(
        this.serverUrl + this.superAdminApiUrl + "/getCustomAchievementDetails",
        data
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }

  // updateCompanyGMCData(data) {
  //   return this.http
  //     .post(this.serverUrl + this.superAdminApiUrl + "/updateCompanyGMCData", {
  //       data,
  //     })
  //     .pipe(
  //       map((response: Response) => {
  //         return <any>response;
  //       })
  //     );
  // }

  getSurveyGroupFromCompanyId(companyId: number) {
    return this.http
      .get(
        this.serverUrl +
          this.superAdminApiUrl +
          `/getSurveyGroupFromCompanyId?companyId=${companyId}`
      )
      .pipe(
        map((response: Response) => {
          return <any>response;
        })
      );
  }
}
