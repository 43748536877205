import { Component, OnInit } from "@angular/core";
import { AdminServices } from "../admin.service";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { BsModalService } from "ngx-bootstrap/modal";
import Swal from "sweetalert2";

@Component({
  selector: "app-b2-manager-dashboard",
  templateUrl: "./b2-manager-dashboard.component.html",
  styleUrls: ["./b2-manager-dashboard.component.css"],
})
export class B2ManagerDashboardComponent implements OnInit {
  dataSource: any = [];
  isDataLoaded: boolean = true;
  startDateDisabled: boolean = false;
  filterdata: any = [];
  managerDetail: any;
  minEndDate: Date;
  showActiveSurveys: boolean = false;
  updateEndDate: Date;
  updateStartDate: Date;
  minStartDate: Date;
  modalRef: BsModalRef;
  isFormValid = false;
  errorMessage = "";
  loading: boolean;
  constructor(
    private readonly adminService: AdminServices,
    private readonly modalService: BsModalService
  ) {}
  displayedColumns: string[] = [
    "managerName",
    "email",
    "companyName",
    "currentStartDate",
    "currentEndDate",
    "actions",
  ];

  pageSize = 10; // Records per page
  currentPage = 0;
  pagedDataSource = this.filterdata.slice(0, this.pageSize);
  ngOnInit(): void {
    this.getB2cManagers();
  }
  getB2cManagers() {
    this.adminService.getB2cManagers().subscribe((response: any) => {
      this.dataSource = response.data;
      this.filterdata = this.dataSource;
      this.isDataLoaded = true;
      this.updatePagedDataSource();
    });
  }
  get totalPages(): number {
    return Math.ceil(this.filterdata.length / this.pageSize);
  }
  onStartDateChange(event: any): void {
    this.updateStartDate = event.value;
    this.minEndDate = this.updateStartDate;

    if (this.updateEndDate && this.updateEndDate <= this.updateStartDate) {
      this.updateEndDate = null;
    }

    this.validateDates();
  }
  closeModal() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  updateDates() {
    let data = {
      startDate: this.updateStartDate,
      endDate: this.updateEndDate,
      surveyId: this.managerDetail.surveyId,
    };

    this.loading = true;

    this.adminService.updateB2cSurvey(data).subscribe((res) => {
      this.loading = false;
      if (res.status) {
        this.modalRef.hide();
        Swal.fire({
          title: "Survey Extended",
          text: res.message,
          icon: "success",
          showConfirmButton: false,
          timer: 2500,
        });
        this.getB2cManagers();
      }
    });
    this.closeModal();
  }
  validateDates(): void {
    this.errorMessage = "";
    this.isFormValid = true;
    let currentDate = new Date();

    currentDate.setDate(currentDate.getDate() + 1);
    let currentDateCheck = currentDate;
    currentDateCheck.setDate(currentDateCheck.getDate() - 1);

    if (
      this.updateEndDate >= currentDateCheck &&
      this.updateEndDate > this.updateStartDate
    ) {
      this.isFormValid = true;
    } else {
      this.errorMessage = "The endDate is not valid.";
      this.isFormValid = false;
    }
    // Ensure start date is less than the end date
    if (this.updateStartDate >= this.updateEndDate) {
      this.errorMessage = "Start date must be earlier than the end date.";
      this.isFormValid = false;
    }
  }
  nextPage(): void {
    if (this.currentPage < this.totalPages - 1) {
      this.currentPage++;
      this.updatePagedDataSource();
    }
  }

  previousPage(): void {
    if (this.currentPage > 0) {
      this.currentPage--;
      this.updatePagedDataSource();
    }
  }

  updatePagedDataSource(): void {
    const startIndex = this.currentPage * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.pagedDataSource = this.filterdata.slice(startIndex, endIndex);
  }

  openEditModal(template, manager: any) {
    this.managerDetail = manager;
    this.updateStartDate = new Date(this.managerDetail.startDate);
    this.updateEndDate = new Date(this.managerDetail.endDate);
    this.startDateDisabled = false;
    let currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);

    if (this.updateStartDate < currentDate) {
      this.startDateDisabled = true;
    }
    this.minEndDate =
      manager.endDate > currentDate ? manager.endDate : currentDate;
    this.minStartDate = currentDate;
    this.modalRef = this.modalService.show(template);
    this.adminService.addModal(this.modalRef);
    return false;
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();

    this.filterdata = this.dataSource.filter(
      (manager) =>
        manager.managerName?.toLowerCase().includes(filterValue) ||
        manager.managerEmail?.toLowerCase().includes(filterValue)
    );
    this.updatePagedDataSource();
    this.isDataLoaded = true;
  }
  filterActiveSurveys() {
    const currentDate = new Date();
    if (this.showActiveSurveys) {
      this.filterdata = this.dataSource.filter(
        (survey) => new Date(survey.endDate) > currentDate
      );
    } else {
      this.filterdata = [...this.dataSource];
    }
  }
}
