import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { AdminServices } from "../admin.service";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";

import { MatSnackBar } from "@angular/material/snack-bar";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

import * as XLSX from "xlsx";
import { SelectionModel } from "@angular/cdk/collections";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { BsModalService } from "ngx-bootstrap/modal";
import Swal from "sweetalert2";

@Component({
  selector: "app-logs",
  templateUrl: "./logs.component.html",
  styleUrls: ["./logs.component.css"],
})
export class LogsComponent implements OnInit {
  displayedColumns: string[] = [
    "select",
    "to",
    "subject",
    "date",
    "status",
    "action",
  ];
  displayedNewColumns = ["to", "subject", "status"];
  dataSource = new MatTableDataSource<EmailLogsInterface>();
  selection = new SelectionModel<EmailLogsInterface>(true, []);
  // dates Declaration
  fromDate: Date = new Date(new Date().setDate(new Date().getDate() - 2));
  toDate: Date = new Date();
  // pagination
  offset = 0;
  limit = 20;
  //search
  search: String = "";
  //
  selectedEmails: EmailLogsInterface[];
  selectedMailsLength: number;
  // params
  params;
  emailContent: any;
  modalRef!: BsModalRef;
  // Main File Starts here
  constructor(
    private adminServices: AdminServices,
    private emailLogsService: AdminServices,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.getEmail();
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  // Get All Email Logs of last seven Days
  getEmail() {
    this.params = {
      date: this.fromDate + "|" + this.toDate,
      search: this.search,
      offset: this.offset,
      limit: this.limit,
    };
    this.emailLogsService.getEmailLogs(this.params).subscribe((res) => {
      let emailLogsData = res.data;
      this.dataSource = new MatTableDataSource(emailLogsData);
      this.dataSource.paginator = this.paginator;
    });
    this.selection.clear();
  }

  // Date change event
  onChangeStartDate(event) {
    this.fromDate = event.value;
  }

  onChangeEndDate(event) {
    this.toDate = event.value;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  //Resend selected Mails
  confirmToSend() {
    Swal.fire({
      title: "Confirm to send selected mails",
      text: "resend Emails",
      icon: "warning",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Resend!",
    }).then((confirm) => {
      if (confirm) {
        this.selection.selected.forEach((data) => {
          let filterEmailLogs = {
            _id: data._id,
            to: data.to,
            subject: data.subject,
            from: data.from,
            status: data.status,
            cc: data.cc,
            bcc: data.bcc,
            message: data.content,
          };
          let Data = filterEmailLogs;
          this.adminServices.reSendEmail(Data).subscribe((res) => {
            console.log(res, "from resend Email Response");
            let swalInfo;
            if (!res.status) {
              swalInfo = {
                title: "Email Resend",
                text: "Something went wrong, please try again",
                type: "error",
              };
            } else {
              swalInfo = {
                title: "Email resend Sucessfully",
                text: res.err,
                type: "success",
              };
              this.modalRef.hide();
              this.getEmail();
            }
            Swal.fire({
              title: swalInfo.title,
              text: swalInfo.text,
              icon: swalInfo.type,
              showConfirmButton: false,
              timer: 2500,
            });
          });
        });
      } else {
        Swal.fire({
          title: "Email Resend",
          text: "Something went wrong, please try again",
          icon: "error",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    });
  }

  // Export to Excel
  exportExcel() {
    const workSheet = XLSX.utils.json_to_sheet(this.dataSource.data, {
      header: ["dataprop1", "dataprop2"],
    });
    const workBook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Sheet1");
    XLSX.writeFile(workBook, "sheet.xlsx");
  }

  // Active User Toggle
  activeUserToggle(event: MatSlideToggleChange) {
    let previousData = this.dataSource;
    let currentData = this.dataSource;
    if (event.checked == true) {
      let filterByStatus = [];
      currentData.data.forEach((e) => {
        if (e.status == false) {
          filterByStatus.push(e);
          this.dataSource.data = filterByStatus;
        }
      });
    } else {
      this.getEmail();
    }
  }

  // open modal
  openEmailModal(template) {
    this.selectedEmails = this.selection.selected;
    this.selectedMailsLength = this.selectedEmails.length;
    this.modalRef = this.modalService.show(template);
    this.adminServices.addModal(this.modalRef);
  }

  // Open Email template
  openEmailTemplate(templateRef: TemplateRef<any>, emailContent?) {
    this.emailContent = emailContent;
    this.modalRef = this.modalService.show(templateRef);
    this.adminServices.addModal(this.modalRef);
  }
  // server Side Pagination
  pageChange(event) {
    let pageIndex = event.pageIndex;
    let pageSize = event.pageSize;
    let previousIndex = event.previousPageIndex;

    if (pageIndex > previousIndex) {
      this.offset = this.offset + pageSize;
      this.limit = this.limit + pageSize;
    } else {
      this.offset = this.offset - pageSize;
      this.limit = this.limit - pageSize;
    }
    this.getEmail();
  }
}

export interface EmailLogsInterface {
  bcc: String;
  cc: String;
  content: String;
  createdAt: Date;
  from: String;
  status: Boolean;
  subject: String;
  to: String;
  _id: String;
}
