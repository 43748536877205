import { Component, OnInit } from "@angular/core";
import { GridOptions } from "ag-grid-community";
import { CompanyServices } from "../company.service";

@Component({
  selector: "app-email-logs",
  templateUrl: "./email-logs.component.html",
  styleUrls: ["./email-logs.component.css"],
})
export class EmailLogsComponent implements OnInit {
  public currentPage: number = 1;
  public totalPages: number = 1;
  public isFetching: boolean = false; // To prevent multiple API calls
  public isFilterApplied: boolean = false; // Flag to check if a filter is applied
  public totalLogs: number = 0;
  gridOptions: GridOptions = {
    columnDefs: [
      {
        field: "subject",
        headerName: "Subject",
        sortable: true,
        filter: true,
        width: 400,
      },
      {
        field: "email_to",
        headerName: "Recipient",
        sortable: true,
        pinned: "left",
        filter: true,
      },
      { field: "status", headerName: "Status", sortable: true, filter: true },
      { field: "sent_at", headerName: "Sent At", sortable: true, filter: true },
      {
        field: "user_type",
        headerName: "User Type",
        sortable: true,
        filter: true,
      },
    ],
    pagination: true,
    suppressMovableColumns: false,
    rowDragManaged: true,
    paginationPageSize: 50, // Number of rows per block
    onPaginationChanged: this.onPaginationChanged.bind(this),
    onFilterChanged: this.onFilterChanged.bind(this), // Handle filter changes
  };
  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }
  public rowData: any[] = [];
  constructor(private companyService: CompanyServices) {}
  ngOnInit(): void {
    this.fetchLogs();
  }

  fetchLogs(page: number = 1, append: boolean = false): void {
    if (this.isFetching || this.isFilterApplied) return; // Avoid duplicate API calls

    this.isFetching = true;
    this.companyService.getSesLogs({ page, limit: 500 }).subscribe(
      (response: any) => {
        if (append) {
          this.rowData = [...this.rowData, ...response.data]; // Append new data
        } else {
          this.rowData = response.data; // Replace data for initial fetch
        }

        this.currentPage = response.pagination.currentPage;
        this.totalPages = response.pagination.totalPages;
        this.totalLogs = response.pagination.totalLogs;
        this.isFetching = false;
      },
      () => {
        this.isFetching = false; // Reset fetching flag on error
      }
    );
  }
  onPaginationChanged(params: any): void {
    const currentPage = params.api.paginationGetCurrentPage() + 1;
    const totalPages = params.api.paginationGetTotalPages();
    if (currentPage === totalPages && this.currentPage < this.totalPages) {
      this.fetchLogs(this.currentPage + 1, true); // Fetch the next set of logs
    }
  }
  onFilterChanged(params: any): void {
    this.isFilterApplied = true; // Set the filter flag
    setTimeout(() => {
      this.isFilterApplied = false; // Reset the filter flag after fetching
    }, 500); // Add a small delay to avoid repeated API calls
  }
}
