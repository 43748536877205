import { Component, OnInit, TemplateRef } from "@angular/core";
import { CompanyServices } from "../company.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import swal from "sweetalert2";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { Validators, Editor, Toolbar } from "ngx-editor";
@Component({
  selector: "app-company-send-email",
  templateUrl: "./company-send-email.component.html",
  styleUrls: ["./company-send-email.component.css"],
})
export class CompanySendEmailComponent implements OnInit {
  loading: boolean;
  modalRef: BsModalRef;
  selectedManagers = [];
  showSubject = false;
  showDesc = false;
  template = "welcomeTemplate";
  formData: any = {
    cc: "",
    template: "",
    subject: "",
    htmlContent: "",
    to: "",
    placeholder: "",
    emailFormat: "",
  };

  emailFormats: any = [
    {
      type: "manager",
      name: "manager_registration_email",
      title: "Manager Registration Email",
    },
    {
      type: "manager",
      name: "manager_email_before_survey",
      title: "Email to Manager before Survey Launch",
    },
    {
      type: "manager",
      name: "manager_opted_for_default_password",
      title: "Manager Opted for Default Password",
    },
    {
      type: "employee",
      name: "team_member_login_details_1",
      title: "Team Memeber Login Details",
    },
    {
      type: "employee",
      name: "custom",
      title: "Custom",
    },
    {
      type: "manager",
      name: "custom",
      title: "Custom",
    },
  ];

  filteredEmailFormats = this.emailFormats;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: false,
    height: "auto",
    minHeight: "500px",
    maxHeight: "auto",
    width: "auto",
    minWidth: "0",
    translate: "true",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "Enter text here...",
    defaultParagraphSeparator: "",
    defaultFontName: "",
    defaultFontSize: "",
    fonts: [
      { class: "arial", name: "Arial" },
      { class: "times-new-roman", name: "Times New Roman" },
      { class: "calibri", name: "Calibri" },
      { class: "comic-sans-ms", name: "Comic Sans MS" },
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],

    uploadUrl: "v1/image",
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: "top",
    toolbarHiddenButtons: [["insertImage", "insertVideo"]],
  };
  error = {};
  touch = {};

  htmlContent: any;
  email = "";
  buttonDisable: boolean = true;
  subject = "Login Details for Great Manager Certification Platform";
  content = "To start the first step towards your managerial journey.";
  editor: Editor;
  toolbar: Toolbar = [
    ["bold", "italic"],
    ["underline", "strike"],
    ["code", "blockquote"],
    ["ordered_list", "bullet_list"],
    [{ heading: ["h1", "h2", "h3", "h4", "h5", "h6"] }],
    ["link", "image"],
    ["text_color", "background_color"],
    ["align_left", "align_center", "align_right", "align_justify"],
  ];
  constructor(
    private companyService: CompanyServices,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.editor = new Editor();
    this.getSelectedManagers();
    this.error["email"] = true;
    this.touch["email"] = false;
    this.error["template"] = true;
    this.touch["template"] = false;
    this.error["subject"] = true;
  }
  getSelectedManagers() {
    this.companyService.selectedmanagersForPackages.subscribe((response) => {
      this.selectedManagers = response;

      this.selectedManagers = this.selectedManagers.filter(
        (manager) => !manager?.accessRevoked
      );
      if (this.selectedManagers.length > 0) {
        this.buttonDisable = false;
      } else {
        this.buttonDisable = true;
      }
    });
  }
  openSendMailModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: "my-modal" })
    );
  }
  editSubject() {
    this.showSubject = !this.showSubject;
  }
  editDesc() {
    this.showDesc = !this.showDesc;
  }
  checkEmail(event) {
    this.touch["email"] = true;
    if (event.target.value == "") {
      this.error["email"] = true;
    } else {
      //console.log(this.validateEmail(event.target.value) , 'email')
      this.email = event.target.value;
      if (!this.validateEmail(this.email)) {
        this.error["email"] = true;
      } else {
        this.error["email"] = false;
      }
      //console.log(this.error['email'])
    }
    //console.log(this.email)
  }
  validateEmail(email) {
    return /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
      email
    );
  }
  subjectData(event) {
    this.touch["subject"] = true;
    if (event.target.value.trim() !== "") {
      this.subject = event.target.value;
      this.error["subject"] = false;
    } else {
      if (this.template !== "welcomeTemplate") {
        this.error["subject"] = true;
      } else {
        this.error["subject"] = false;
      }
    }
    //console.log(this.subject);
  }

  changeFormInput(event) {
    const value = event.target.value.trim();

    if (value) {
      this.formData.to = value;
      this.filteredEmailFormats = this.emailFormats.filter((data) => {
        return data.type == value;
      });
    }
  }

  changeEmailFormat(event) {
    const value = event.target.value;
    this.formData.emailFormat = value;
    const data = {
      type: value,
    };

    switch (value) {
      case "custom":
        this.formData.htmlContent = "Your Content Goes Here";
        this.formData.subject = "Your Subject is Here";
        this.formData.placeholder = "";
        break;
      default:
        this.getEmailContent(data);
        break;
    }
  }

  getEmailContent(data) {
    this.companyService.getEmail(data).subscribe((res) => {
      // console.log(res);
      if (res.status) {
        this.formData.htmlContent = res.data.content;
        this.formData.subject = res.data.subject;
        this.formData.placeholder = res.data.placeholder.toString();
      } else {
        swal.fire({
          title: "Email Template",
          text: "Something went wrong!",
          icon: "error",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    });
  }
  chooseTemplate(event) {
    this.touch["template"] = true;
    if (event.target.value.trim() !== "") {
      this.template = event.target.value;
      if (this.template === "customTemplate") {
        this.subject = "";
        this.showSubject = true;
        this.showDesc = true;
        this.error["subject"] = false;
      } else {
        this.content =
          "To start the first step towards your managerial journey.";
        this.subject = "Login Details for Great Manager Certification Platform";
        this.showSubject = false;
        this.showDesc = false;
        this.error["subject"] = true;
      }
      this.error["template"] = false;
    } else {
      this.error["template"] = true;
    }
  }
  descData(event) {
    if (event.target.value !== "") {
      this.content = event.target.value;
    } else {
      this.content = "To start the first step towards your managerial journey.";
    }
  }

  onSubmit() {
    // this.loading = true
    let data = {
      managers: this.selectedManagers,
      content: this.htmlContent,
      subject: this.subject,
      ccEmail: this.email,
      template: this.template,
    };

    this.companyService.sendEmailToManagers(data).subscribe((response) => {
      this.loading = false;
      if (response.status) {
        swal.fire({
          title: "Email Sent Status",
          text: response.message,
          icon: "success",
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        swal.fire({
          title: "Email Sent Status",
          text: response.message,
          icon: "error",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    });
    this.content = "To start the first step towards your managerial journey.";
    this.subject = "Login Details for Great Manager Certification Platform";
    this.error["email"] = true;
    this.error["subject"] = true;
    this.showSubject = false;
    this.showDesc = false;
    this.modalRef.hide();
    this.modalRef = null;
  }

  submit() {
    if (
      this.formData["subject"] !== "" &&
      this.formData["htmlContent"] !== "" &&
      this.formData["to"] !== ""
    ) {
      let data = {
        managers: this.selectedManagers,
        formData: this.formData,
      };
      this.companyService.sendEmailToManagers(data).subscribe((response) => {
        this.loading = false;
        if (response.status) {
          swal.fire({
            title: "Email Sent Status",
            text: response.message,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.modalService._hideModal(1);
          this.formData.subject = "";
          this.formData.to = "";
          this.formData.emailFormat = "";
          this.formData.htmlContent = "";
        } else {
          swal.fire({
            title: "Email Sent Status",
            text: response.message,
            icon: "error",
            showConfirmButton: false,
            timer: 2500,
          });
        }
      });
    } else {
      swal.fire({
        title: "Form Validation",
        text: "Please fill all the required field",
        icon: "error",
        showConfirmButton: false,
        timer: 2500,
      });
    }
  }
  ngOnDestroy(): void {
    this.editor.destroy();
  }
}
