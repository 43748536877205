<main class="request_container">
  <!-- for big screen -->
  <section class="request_list_section" *ngIf="showAllRequest === true">
    <div class="request_list_header">
      <button class="allRequest_btn">
        <p>All request</p>
      </button>
      <button class="add_new_requestBtn" (click)="toggleRequest(false)">
        <p>Add new request</p>
      </button>
    </div>

    <div class="table_container">
      <table class="req_list_table">
        <thead class="req_table_head">
          <tr>
            <th class="col_header">Request type</th>
            <th class="col_header2">Status</th>
            <th class="col_header1">
              Requested<br />
              Date
            </th>
            <th class="col_header1">
              Expected to<br />
              Complete
            </th>
            <th class="col_header1">Request Id</th>
          </tr>
        </thead>

        <tbody class="req_table_body">
          <tr *ngFor="let item of requests; index as i">
            <td class="req_name_area">
              <span>
                <i
                  class="fa fa-angle-down req_name_icon"
                  aria-hidden="true"
                  (click)="showReqInfo(item._id)"
                ></i>
                <p class="req_name">{{ item.request.requestName }}</p>
              </span>
              <div *ngIf="displayReqInfo[item._id] == true">
                <div
                  class="requested_pis"
                  *ngIf="item.request.requestName == 'Add PIS'"
                >
                  <a
                    href="https://media.greatmanagerinstitute.com/{{
                      item.request.pisUrl
                    }}"
                    target="_blank"
                    >Download PIS</a
                  >
                </div>
                <div
                  class="requested_details"
                  *ngIf="
                    item.request.requestName == 'Add/ Edit Profile' ||
                    item.request.requestName == 'Delete Profile'
                  "
                >
                  <li class="mgrList">
                    <p *ngIf="item.request.manager.name">
                      <strong>Manager Name:</strong>
                      {{ item.request.manager.name }}
                    </p>
                    <p *ngIf="item.request.manager.emailId">
                      <strong>Manager Email:</strong>
                      {{ item.request.manager.emailId }}
                    </p>
                  </li>
                  <ol
                    class="partList"
                    *ngIf="
                      item.request.participants != null &&
                      item.request.participants.length > 0
                    "
                  >
                    <li *ngFor="let participant of item.request.participants">
                      <p *ngIf="participant.name != null">
                        <strong>Name:</strong> {{ participant.name }}
                      </p>
                      <p *ngIf="participant.emailId != null">
                        <strong>Participant Email:</strong>
                        {{ participant.emailId }}
                      </p>
                    </li>
                  </ol>
                </div>
                <div
                  class="requested_details"
                  *ngIf="
                    item.request.requestName == 'Survey Date' ||
                    item.request.requestName == 'Change Date'
                  "
                >
                  <p *ngIf="item.request.date.startDate != null">
                    <strong>Start Date: </strong>
                    {{ moment(item.request.date.startDate).format("L") }}
                  </p>
                  <p>
                    <strong>End Date: </strong>
                    {{ moment(item.request.date.endDate).format("L") }}
                  </p>
                </div>
              </div>
            </td>
            <td class="req_status" *ngIf="item.status == true">
              <p class="req_status_completed">Completed</p>
            </td>
            <td class="req_status" *ngIf="item.status == false">
              <p class="req_status_pending">Pending</p>
            </td>
            <td class="req_createdAt">
              {{ moment(item.createdAt).format("L") }}
            </td>
            <td class="req_createdAt">
              <p class="inComplete" *ngIf="item.status === false">
                {{ item.expectedTime }} hours left
              </p>
              <p class="completed" *ngIf="item.status === true">
                Done in {{ item.completedTime }}hrs
              </p>
            </td>
            <td class="req_id">
              {{ item._id }}
            </td>
            <button class="req_action" [matMenuTriggerFor]="menu"></button>
            <mat-menu #menu="matMenu" class="action_menu">
              <button
                mat-menu-item
                (click)="reqListAction(item, 'remove')"
                *ngIf="userType === 'HR'"
              >
                Remove
              </button>
              <button
                mat-menu-item
                (click)="reqListAction(item, 'completed')"
                *ngIf="userType !== 'HR'"
              >
                Completed
              </button>
              <button
                mat-menu-item
                (click)="reqListAction(item, 'extend')"
                *ngIf="userType !== 'HR'"
              >
                Extend
              </button>
            </mat-menu>
          </tr>
        </tbody>
      </table>
    </div>
  </section>

  <!-- for mobile view -->

  <section class="mob_allreq_list_section" *ngIf="showAllRequest === true">
    <div class="mob_allreq_container">
      <div class="mob_allreq_header">
        <p class="mob_allreq_btn">All request</p>
      </div>
      <div class="mob_requests_card" *ngFor="let item of requests; index as i">
        <div class="row request_card_header">
          <div class="col-sm-6 col-xs-6 requestName">
            <p>{{ item.request.requestName }}</p>
          </div>
          <div class="col-sm-6 col-xs-6 requestStatus_area">
            <div class="requestStatus">
              <button class="requestCompleted" *ngIf="item.status == true">
                Completed
              </button>
              <button class="requestPending" *ngIf="item.status == false">
                Pending
              </button>
            </div>
            <button class="requestAction" [matMenuTriggerFor]="menu">
              <mat-menu #menu="matMenu" class="action_menu">
                <button
                  mat-menu-item
                  (click)="reqListAction(item, 'remove')"
                  *ngIf="userType === 'HR'"
                >
                  Remove
                </button>
                <button
                  mat-menu-item
                  (click)="reqListAction(item, 'completed')"
                  *ngIf="userType !== 'HR'"
                >
                  Completed
                </button>
                <button
                  mat-menu-item
                  (click)="reqListAction(item, 'extend')"
                  *ngIf="userType !== 'HR'"
                >
                  Extend
                </button>
              </mat-menu>
            </button>
          </div>
        </div>

        <div
          class="requested_pis"
          *ngIf="item.request.requestName == 'Add PIS'"
        >
          <a
            href="https://media.greatmanagerinstitute.com/{{
              item.request.pisUrl
            }}"
            target="_blank"
            >Download PIS</a
          >
        </div>

        <div
          class="requested_profile"
          *ngIf="item.request.requestName == 'Add/ Edit Profile'"
        >
          <div class="row request_details">
            <div class="col-sm-6 col-xs-6"><Strong>Manager Name:</Strong></div>
            <div class="col-sm-6 col-xs-6">{{ item.request.manager.name }}</div>
          </div>
          <div class="row request_details">
            <div class="col-sm-6 col-xs-6"><strong>Manager Email:</strong></div>
            <div class="col-sm-6 col-xs-6">
              {{ item.request.manager.emailId }}
            </div>
          </div>

          <ul
            class="emp_request_area"
            *ngIf="
              item.request.participants != null &&
              item.request.participants.length > 0
            "
          >
            <!-- <hr *ngIf="item.request.participants"> -->
            <li
              *ngFor="let participant of item.request.participants; index as i"
            >
              <div class="row request_details">
                <div class="col-sm-6 col-xs-6 emp_name">
                  <label>{{ i + 1 }}.</label>
                  <strong>Participant Name:</strong>
                </div>
                <div class="col-sm-6 col-xs-6">
                  {{ participant.name }}
                </div>
              </div>
              <div class="row request_details emp_email">
                <div class="col-sm-6 col-xs-6">
                  <strong>Participant Email:</strong>
                </div>
                <div class="col-sm-6 col-xs-6">
                  {{ participant.emailId }}
                </div>
              </div>
              <p></p>
              <p></p>
            </li>
          </ul>
        </div>

        <div class="row requested_date">
          <div class="col-sm-6 col-xs-6">
            <p class="requestedDate_label">Requested Date</p>
            <p class="date_value">
              {{ moment(item.createdAt).format("L") }}
            </p>
          </div>
          <div class="col-sm-6 col-xs-6">
            <p class="requestedDate_label">Expected Date</p>
            <p class="date_value" *ngIf="item.status === false">
              {{ item.expectedTime }} hours left
            </p>
            <p class="date_value completed" *ngIf="item.status === true">
              Done in {{ item.completedTime }}hrs
            </p>
          </div>
        </div>
      </div>
    </div>

    <button class="add_new_request" (click)="toggleRequest(false)">
      <p class="add_new_request_icon">Add new request</p>
    </button>
  </section>

  <!-- main request section -->
  <section class="new_request_section" *ngIf="showAllRequest === false">
    <!-- main request page header area -->
    <div class="request_header_area">
      <p class="new_request_header">Add a new request</p>

      <div class="all_request_btn" (click)="toggleRequest(true)">
        <div class="all_request_icon">
          <p class="all_requestbtn_text">All Request</p>
        </div>
      </div>
    </div>

    <!-- request list options -->
    <div class="request_list_area">
      <p class="_request_type">Request Type</p>

      <div class="select_reqst_area">
        <div class="drop_down_header" (click)="showRequestList()">
          <p class="select_reqst_type">Select a request type</p>
          <i
            class="fa fa-chevron-down select_drop_icon"
            [ngClass]="{
              downArrow: showList == true,
              upArrow: showList == false
            }"
          ></i>
        </div>

        <p *ngIf="showList != true" class="select_option_desc">
          Please select your request type from the list above!
        </p>

        <div *ngIf="showList == true" class="reqst_lists">
          <li *ngFor="let item of requestList" class="list_item">
            <p class="reqst_name" (click)="requestOption(item)">{{ item }}</p>
          </li>
        </div>
      </div>
    </div>

    <div class="mob_pis_timer" *ngIf="showTimer === true">
      <div class="mob_timer_icon">
        <p>
          Expected time<br />
          to complete
        </p>
        <p>24 hrs</p>
      </div>
      <div class="mob_timer_text"></div>
    </div>
    <!-- upload pis section -->

    <hr *ngIf="requestListOptions['Add PIS'] == true" class="divider" />
    <div *ngIf="requestListOptions['Add PIS'] == true" class="pis_container">
      <div class="col-md-9 col-sm-12 pis_section">
        <div class="pis">
          <p class="pis_header">PIS Upload</p>
          <p class="pis_description">
            Upload the detailed list of managers you are nominating to
            participate in the GPMS 2022 process and their respective team
            members. Please refer to the Sample PIS format for better
            understanding.
          </p>
          <div class="download_sample_pis">
            <a
              class="download_icon"
              href="https://media.greatmanagerinstitute.com/Sample_GPMS_PIS.xlsx"
            >
              <p class="download_text">Download sample PIS</p>
            </a>
          </div>
          <div class="upload_pis" id="upload" (click)="uploadPis()">
            <div class="upload_icon">
              <p class="upload_text" *ngIf="pisUploaded == false">Upload PIS</p>
              <p class="upload_text" *ngIf="pisUploaded == true">
                PIS Uploaded
              </p>
            </div>
          </div>
          <input
            style="width: 0; height: 0"
            id="fileSelect"
            type="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            name="fileUpload"
            ngModel
            required
            #fileUpload="ngModel"
            #fileInput
            (change)="onFileChange($event)"
          />
          <!-- <button *ngIf="showDeleteBtn" (click)="clearData()">
            Remove
          </button> -->
          <button class="pis_reqst_btn" (click)="createRequest()">
            Create Request
          </button>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 pis_timer">
        <div class="timer_icon"></div>
        <div class="timer_value">
          <p>
            Expected time<br />
            to complete
          </p>
          <p>24 hrs</p>
        </div>
      </div>
    </div>

    <!-- add / edit profile section -->
    <div
      class="row profile_container"
      *ngIf="requestListOptions['Add/ Edit Profile'] == true"
    >
      <div class="col-md-9 col-sm-12 profile_section">
        <p class="profile_title">Edit Profile</p>

        <div class="row profile_details_sec">
          <div class="col-md-6 col-sm-12 no_of_profiles">
            <li class="details_list">
              <span>
                <p class="profile_name">Total Managers</p>
                <p class="no_of_mgr">: {{ surveyData.length }}</p>
              </span>
            </li>
            <li class="details_list">
              <span>
                <p class="profile_name">Total Participants</p>
                <p class="no_of_participants">: {{ totalParticipants }}</p>
              </span>
            </li>
          </div>

          <div class="col-md-6 col-sm-12 search">
            <input
              class="search_input"
              placeholder="Search here"
              id="inputManager"
              (keyup)="searchManager($event.target)"
            />
          </div>
        </div>

        <div class="add_new_section">
          <button
            class="add_mgr_btn"
            (click)="manageProfile(profileAction, 'addManager', null)"
          >
            Add new manager
          </button>
          <div class="add_pis" (click)="showPis(showPisBox)">
            <p class="upload_pis_text">Upload PIS</p>
            <i
              class="fa fa-info-circle pis_info"
              matTooltip="Upload the detailed list of managers you are nominating to participate in the GPMS 2022 
              process and their respective team members. Please refer to the Sample PIS format for better understanding."
              matTooltipPosition="above"
              aria-hidden="true"
            ></i>
          </div>
        </div>

        <div class="list_section">
          <table class="mgr_added_table" id="managerTable">
            <thead>
              <tr>
                <th class="list_table_heading">Name / Email</th>
                <th class="table_list_heading">NoP</th>
                <th></th>
              </tr>
            </thead>
            <!-- <hr> -->
            <tbody>
              <tr *ngFor="let item of surveyData; index as i">
                <td>
                  <p class="table_list_name">
                    {{ item.managerName }}
                  </p>
                  <p class="table_list_email">
                    {{ item.managerEmail }}
                  </p>
                </td>
                <td>
                  <p class="table_list_nop">{{ item.noOfParticipant }}</p>
                </td>
                <td>
                  <div class="table_list_actions">
                    <button
                      class="add_parti"
                      (click)="manageProfile(profileAction, 'empList', item)"
                    ></button>
                    <button
                      class="edit_parti"
                      (click)="
                        manageProfile(profileAction, 'editProfile', item)
                      "
                    ></button>
                    <button
                      class="delete_parti"
                      (click)="deleteUser(showDeleteBox, item, null)"
                      (click)="modalRef.hide()"
                    ></button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 pis_timer">
        <div class="timer_icon"></div>
        <div class="timer_value">
          <p>
            Expected time<br />
            to complete
          </p>
          <p>24 hrs</p>
        </div>
      </div>
    </div>

    <!-- change date section -->
    <div
      class="survey_date_container"
      *ngIf="
        requestListOptions['Survey Date'] == true ||
        requestListOptions['Change Date'] == true
      "
    >
      <div class="col-md-9 col-sm-12 col-xs-12 date_change">
        <p class="dateChange_title">Date changes</p>
        <div class="row date_area">
          <div class="col-md-6 select_date">
            <p class="select_date_title">Select start date</p>
            <div class="enter_date">
              <input
                matInput
                disabled
                [matDatepicker]="picker1"
                placeholder="Select start date"
                [min]="today"
                [max]="startDateMin"
                [(ngModel)]="startDate"
                (ngModelChange)="checkDate('startDate')"
              />
              <mat-datepicker-toggle
                class="matIcon"
                matSuffix
                [for]="picker1"
              ></mat-datepicker-toggle>
              <mat-datepicker
                [disabled]="surveyData.length > 0 ? true : false"
                touchUi="true"
                #picker1
              ></mat-datepicker>
            </div>
          </div>
          <div class="col-md-6 select_date">
            <p class="select_date_title">Select end date</p>
            <div class="enter_date">
              <input
                matInput
                disabled
                [matDatepicker]="picker"
                placeholder="Select end date"
                [min]="endtoday"
                [max]="maxDate"
                [(ngModel)]="endDate"
                (ngModelChange)="checkDate('endDate')"
              />
              <mat-datepicker-toggle
                class="matIcon"
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker
                disabled="false"
                touchUi="true"
                #picker
              ></mat-datepicker>
            </div>
          </div>
        </div>
        <button class="add_part_btn" (click)="createRequest()">
          Create Request
        </button>
      </div>
      <div class="col-md-3 col-sm-6 pis_timer">
        <div class="timer_icon"></div>
        <div class="timer_value">
          <p>
            Expected time<br />
            to complete
          </p>
          <p>24 hrs</p>
        </div>
      </div>
    </div>
  </section>
</main>

<ng-template #profileAction>
  <div class="modal-body" id="popUp">
    <button
      type="button "
      class="close pull-right"
      aria-label="Close "
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true ">&times;</span>
    </button>
    <section class="popUp_section">
      <section class="add_manager" *ngIf="display == 'displayMgrProfile'">
        <div class="popUp_header">
          <button class="navigation"></button>
          <p class="header_name">Add manager profile</p>
        </div>
        <div class="row popUp_content_container">
          <div class="col-md-6 popUp_content">
            <label class="label_name"> Name </label>
            <input
              class="input_text"
              placeholder="Name of manager"
              required
              [(ngModel)]="managerData.managerName"
              name="managerName"
              #managerName="ngModel"
            />
          </div>
          <div class="col-md-6 popUp_content">
            <label class="label_name"> Email </label>
            <input
              class="input_text"
              placeholder="Email of manager"
              required
              [(ngModel)]="managerData.managerEmail"
              (change)="isValidEmail(managerData.managerEmail)"
              name="managerEmail"
              #managerEmail="ngModel"
            />
          </div>
        </div>
        <label class="error" *ngIf="showError"
          >Please validate input data!</label
        >
        <button
          class="add_part_btn"
          data-dismiss="modal"
          [disabled]="showError"
          (click)="addParticipants('addManager')"
        >
          Add participants
        </button>
      </section>

      <section class="add_employee" *ngIf="display == 'displayAddEmp'">
        <div class="popUp_header">
          <button class="navigation"></button>
          <p class="header_name">
            Add Participants of {{ managerData.managerName }}
          </p>
        </div>

        <div class="row popUp_content_container">
          <div class="col-md-6 popUp_content">
            <label class="label_name"> Name </label>
            <input
              class="input_text"
              placeholder="Name of participants"
              required
              [(ngModel)]="employeeData.empName"
              name="empName"
              #empName="ngModel"
            />
          </div>
          <div class="col-md-6 popUp_content">
            <label class="label_name"> Email </label>
            <input
              class="input_text"
              placeholder="Email of participants"
              required
              [(ngModel)]="employeeData.empEmail"
              (change)="isValidEmail(employeeData.empEmail)"
              name="empEmail"
              #empEmail="ngModel"
            />
          </div>
        </div>

        <div *ngIf="count > 0">
          <div
            class="row popUp_content_container"
            *ngFor="let item of allEmpData"
          >
            <!-- <button class="mob_remove_btn"
            (click)="removeParticipant(item)"
            ></button> -->
            <div class="col-md-6 popUp_content">
              <span class="label_area">
                <label class="label_name"> Name </label>
                <!-- <button class="mob_remove_btn"
                  (click)="removeParticipant(item)"
                >
                  <p class="mob_remove_text">Remove</p>
                </button> -->
              </span>
              <input
                class="input_text"
                placeholder="Name of participants"
                required
                [(ngModel)]="item.empName"
                name="empName"
                #empName="ngModel"
              />
            </div>
            <div class="col-md-6 popUp_content">
              <label class="label_email"> Email </label>
              <span class="email_area">
                <input
                  class="input_text_email"
                  placeholder="Email of participants"
                  required
                  [(ngModel)]="item.empEmail"
                  (change)="isValidEmail(item.empEmail)"
                  name="empEmail"
                  #empEmail="ngModel"
                />
                <button class="remove_btn" (click)="removeParticipant(item)">
                  <p class="remove_text">Remove</p>
                </button>
              </span>
            </div>
          </div>
        </div>

        <div class="add_more_area">
          <button class="add_more_btn" (click)="addMoreEmployee()">
            <!-- <p>+</p> -->
            <p>+ Add More</p>
          </button>
        </div>
        <label class="error" *ngIf="showError"
          >Please validate input data!</label
        >
        <label class="error" *ngIf="empError"
          >Minimum 3 participants required!</label
        >
        <button
          class="add_part_btn"
          data-dismiss="modal"
          [disabled]="showError"
          (click)="requestEmp('create')"
        >
          Create Request
        </button>
      </section>

      <section class="participants_list" *ngIf="display == 'displayEmpList'">
        <div class="popUp_header">
          <button class="navigation" (click)="modalRef.hide()"></button>
          <p class="header_name">
            Participants of {{ getDataForEdit.managerName }}
          </p>
        </div>
        <button class="add_new_emp_btn" (click)="addParticipants('addEmp')">
          Add new participants
        </button>
        <div class="list_area">
          <p class="list_header">Name / Email</p>
          <hr />
          <div
            class="row list_content"
            *ngFor="let item of getDataForEdit.participants"
          >
            <div class="col-md-6 list_details">
              <p class="list_emp_name">
                {{ item.name }}
              </p>
              <p class="list_emp_email">
                {{ item.emailId }}
              </p>
            </div>
            <div class="col-md-6 list_action">
              <span *ngIf="item.surveySubmissionStatus == false">
                <button
                  class="edit_parti"
                  [disabled]="item.surveySubmissionStatus == true"
                  (click)="editParticipants(getDataForEdit, item)"
                ></button>
                <button
                  class="delete_parti"
                  data-dismiss="modal"
                  [disabled]="item.surveySubmissionStatus == true"
                  (click)="deleteUser(showDeleteBox, item, getDataForEdit)"
                  (click)="modalRef.hide()"
                ></button>
              </span>

              <p
                class="submitted_info"
                *ngIf="item.surveySubmissionStatus == true"
              >
                Survey Submitted
              </p>
            </div>
          </div>
        </div>
      </section>
    </section>

    <section class="edit_manager" *ngIf="display == 'displayEditProfile'">
      <div class="nav_area">
        <div class="popUp_header">
          <button class="navigation"></button>
          <p class="header_name">Edit manager profile</p>
        </div>
        <div class="team_details_area">
          <p class="team_size">
            <strong>Team size:</strong> {{ getDataForEdit.noOfParticipant }}
          </p>
          <p
            class="open_editParticipants"
            (click)="manageProfile(null, 'currentEmpList', getDataForEdit)"
          >
            View / Edit participants
          </p>
        </div>
      </div>

      <div class="row popUp_content_container">
        <div class="col-md-6 popUp_content">
          <label class="label_name"> Name </label>
          <input
            class="input_text"
            placeholder="Name of manager"
            required
            [(ngModel)]="managerData.managerName"
            name="managerName"
            #managerName="ngModel"
          />
        </div>
        <div class="col-md-6 popUp_content">
          <label class="label_name"> Email </label>
          <input
            class="input_text"
            placeholder="Email of manager"
            required
            [(ngModel)]="managerData.managerEmail"
            (change)="isValidEmail(managerData.managerEmail)"
            name="managerEmail"
            #managerEmail="ngModel"
          />
        </div>
      </div>
      <label class="error" *ngIf="showError">Please validate input data!</label>
      <button
        class="add_part_btn"
        data-dismiss="modal"
        [disabled]="showError"
        (click)="requestEmp('edit')"
      >
        Create Request
      </button>
    </section>

    <section class="_participants_list" *ngIf="display == 'displayAllEmpList'">
      <div class="popUp_header">
        <button class="navigation" (click)="modalRef.hide()"></button>
        <p class="header_name">
          Participants of {{ getDataForEdit.managerName }}
        </p>
      </div>
      <button class="add_new_emp_btn" (click)="addParticipants('addEmp')">
        Add new participants
      </button>
      <div class="list_area">
        <p class="list_header">Name / Email</p>
        <hr />
        <div
          class="row list_content"
          *ngFor="let item of getDataForEdit.participants"
        >
          <div class="col-md-6 list_details">
            <p class="list_emp_name">
              {{ item.name }}
            </p>
            <p class="list_emp_email">
              {{ item.emailId }}
            </p>
          </div>
          <div class="col-md-6 list_action">
            <span *ngIf="item.surveySubmissionStatus == false">
              <button
                class="edit_parti"
                (click)="editParticipants(getDataForEdit, item)"
                [disabled]="item.surveySubmissionStatus == true"
              ></button>
              <button
                class="delete_parti"
                data-dismiss="modal"
                [disabled]="item.surveySubmissionStatus == true"
                (click)="deleteUser(showDeleteBox, item, getDataForEdit)"
                (click)="modalRef.hide()"
              ></button>
            </span>

            <p
              class="submitted_info"
              *ngIf="item.surveySubmissionStatus == true"
            >
              Survey Submitted
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="edit_participants" *ngIf="display == 'displayEditEmp'">
      <div class="popUp_header">
        <button class="navigation"></button>
        <p class="header_name">Edit Participants</p>
      </div>
      <div class="row popUp_content_container">
        <div class="col-md-6 popUp_content">
          <label class="label_name"> Name </label>
          <input
            class="input_text"
            placeholder="Name of participants"
            [(ngModel)]="employeeData.empName"
            name="empName"
            #empName="ngModel"
          />
        </div>
        <div class="col-md-6 popUp_content">
          <label class="label_name"> Email </label>
          <input
            class="input_text"
            placeholder="Email of participants"
            [(ngModel)]="employeeData.empEmail"
            (change)="isValidEmail(employeeData.empEmail)"
            name="empEmail"
            #empEmail="ngModel"
          />
        </div>
      </div>
      <label class="error" *ngIf="showError">Please validate input data!</label>
      <button
        class="add_part_btn"
        [disabled]="showError"
        (click)="requestEmp('edit')"
        data-dismiss="modal"
      >
        Create Request
      </button>
    </section>
  </div>
</ng-template>

<ng-template #showDeleteBox>
  <div class="">
    <section class="deletePopUp">
      <h3 class="header">Are you sure</h3>
      <p class="deleteDesc">
        The Manager's Profile will be deleted and will not be able to
        participate in the process!
      </p>
      <div class="deleteActions">
        <button
          class="acceptBtn"
          (click)="removeUser()"
          (click)="modalRef.hide()"
        >
          Yes
        </button>
        <button class="rejectBtn" (click)="modalRef.hide()">No</button>
      </div>
    </section>
  </div>
</ng-template>

<ng-template #showPisBox>
  <div class="">
    <section class="uploadPisBox">
      <div class="popUp_header">
        <button class="navigation" (click)="modalRef.hide()"></button>
        <p class="header_name">Upload PIS</p>
      </div>
      <div class="uploadArea">
        <p>
          Upload the detailed list of managers you are nominating to participate
          in <br />the GPMS 2022 process and their respective team members.
          Please refer to <br />the Sample PIS format for better understanding.
        </p>
        <div class="upload_pis" id="upload" (click)="uploadPis()">
          <div class="upload_icon">
            <p class="upload_text" *ngIf="pisUploaded == false">Upload PIS</p>
            <p class="upload_text" *ngIf="pisUploaded == true">PIS Uploaded</p>
          </div>
        </div>
        <input
          style="width: 0; height: 0"
          id="fileSelect"
          type="file"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          name="fileUpload"
          ngModel
          required
          #fileUpload="ngModel"
          #fileInput
          (change)="onFileChange($event)"
        />
      </div>
      <div class="uploadBtn">
        <button class="pis_reqst_btn" (click)="createRequest()">
          Create Request
        </button>
      </div>
    </section>
  </div>
</ng-template>
