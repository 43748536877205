import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class RoundTwoService {
  private selectedSectionSubject = new BehaviorSubject<number>(0);
  selectedSection$ = this.selectedSectionSubject.asObservable();
  private selectedManagers = [];
  private selectedDateRange: any;

  getCurrentSection(): number {
    return this.selectedSectionSubject.value;
  }

  setSelectedSection(currentSection: number): void {
    this.selectedSectionSubject.next(currentSection);
  }

  getSelectedDateRange() {
    return this.selectedDateRange;
  }

  setSelectedDateRange(dateRange: any): void {
    this.selectedDateRange = dateRange;
  }
}
