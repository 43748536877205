<div class="container main">
  <div>
    <div class="row pb-10">
      <div class="col-md-5">
        <div class="form-group pb-10">
          <input
            class="form-control custom-form-control"
            required
            id="subtheme"
            name="subtheme"
            type="text"
            placeholder="Search questionnaire name..."
            (keyup)="searchQuestionnaire($event.target['value'])"
          />
        </div>
      </div>
      <div class="col-md-5 text-right">
        <button
          (click)="showQuestionnaireModal(masterStructure, {}, false)"
          type="button"
          class="btn btn-secondary btn-newuser-clr"
        >
          Add New
        </button>
      </div>
    </div>
    <div class="questionnaireTable m-top-30">
      <mat-table [dataSource]="masterQuestionnaire" class="mat-elevation-z4">
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef
            >Master Questionnaire Id</mat-header-cell
          >
          <mat-cell
            class="text-center"
            *matCellDef="let element; let i = index"
          >
            {{ element.id }}</mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="questionnaireName">
          <mat-header-cell *matHeaderCellDef
            >Master Questionnaire Name</mat-header-cell
          >
          <mat-cell *matCellDef="let element">
            {{ element.questionnaireName }}</mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="edit">
          <mat-header-cell *matHeaderCellDef>Edit</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <i
              (click)="showQuestionnaireModal(masterStructure, element, true)"
              class="fa fa-edit"
              style="margin-right: 15px"
            ></i
          ></mat-cell>
        </ng-container>

        <!-- <ng-container matColumnDef="delete">
          <mat-header-cell *matHeaderCellDef>Delete</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <i class="fa fa-trash" style="margin-right: 15px"></i>
          </mat-cell>
        </ng-container> -->

        <mat-header-row
          *matHeaderRowDef="masterQuestionnaireColumns"
        ></mat-header-row>
        <mat-row
          *matRowDef="let col; columns: masterQuestionnaireColumns"
        ></mat-row>
      </mat-table>

      <mat-paginator
        [pageSizeOptions]="[15, 30, 50]"
        showFirstLastButtons
      ></mat-paginator>
      <div
        class="text-center emptyContainer"
        *ngIf="masterQuestionnaire.data.length === 0"
      >
        Not found any Master Questionnaire.
      </div>
    </div>
  </div>
  <ng-template #masterStructure>
    <div class="p-15">
      <div class="row pb-10">
        <div class="col-md-8">
          <h4>
            <b
              >{{ isInstrumentEdit ? "UPDATE " : "ADD " }}NEW SURVEY
              INSTRUMENT:</b
            >
          </h4>
        </div>
        <div class="col-md-4">
          <button
            type="button"
            class="btn-close close pull-right"
            aria-label="Close"
            (click)="modalRef?.hide()"
          >
            <span aria-hidden="true" class="visually-hidden">&times;</span>
          </button>
        </div>
      </div>

      <div class="row m-top-30">
        <form [formGroup]="masterQuestionnaireForm">
          <div class="col-md-6">
            <label>New Instrument Name:</label>
            <div class="form-group pb-10">
              <input
                class="form-control custom-form-control"
                required
                type="text"
                formControlName="masterQuestionnaireName"
                placeholder="Type here..."
                [attr.disabled]="isInstrumentEdit ? 'disabled' : null"
              />
            </div>
          </div>
          <div class="col-md-6">
            <label>Search and Click on the question to add:</label>
            <div class="form-group pb-10">
              <input
                class="form-control custom-form-control"
                required
                #questionText
                type="text"
                placeholder="Type here..."
                (keyup)="searchQuestion($event.target['value'])"
                [matAutocomplete]="auto"
              />
              <mat-autocomplete #auto="matAutocomplete">
                <!-- && question_text.value == '' -->
                <mat-option
                  (click)="selectQuestion(question, i)"
                  *ngFor="let question of filteredQuestions; let i = index"
                  [value]="question.question_id"
                  matTooltip="{{ question.question_text }}"
                  >{{ question.question_id }} :
                  {{ question.question_text }}</mat-option
                >
              </mat-autocomplete>
            </div>
          </div>
        </form>
      </div>

      <div>
        <h4><b>Fetch Existing Instrument's Questions.</b></h4>
        <div class="row">
          <div class="col-md-6">
            <label>Select Instrument Name</label>
            <div class="form-group pb-10">
              <select
                class="form-control custom-form-control"
                (change)="getQuestionnaireQuestions($event.target['value'])"
              >
                <option value="" selected>Select</option>
                <option
                  value="{{ value.id }}"
                  *ngFor="let value of instrumentOptionsArray"
                >
                  {{ value.id }} - {{ value.questionnaireName }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6 text-center" *ngIf="!isInstrumentEdit">
            <button
              (click)="dataSource = []"
              type="button"
              class="btn btn-secondary btn-newuser-clr m-top-30"
            >
              Clear Question Set
            </button>
          </div>
        </div>
      </div>
      <div class="note">
        <p>Note : Drag and drop on the question to change the survey order.</p>
      </div>
      <div
        class="questions-table m-top-30"
        (cdkDropListDropped)="dragAndDrop($event)"
        cdkDropList
      >
        <div class="row header-row">
          <div class="col-md-5 text-center">Question Text</div>
          <div class="col-md-2 text-center">Theme</div>
          <div class="col-md-2 text-center">Subtheme</div>
          <div class="col-md-2 text-center">Survey Order</div>
          <div class="col-md-1 text-center">Actions</div>
        </div>
        <div
          class="row question-row"
          cdkDrag
          [cdkDragData]="data"
          *ngFor="let data of dataSource; let i = index"
        >
          <div *ngIf="data?.question_status">
            <div class="col-md-5">{{ data.question_text }}</div>
            <div class="col-md-2 text-center">{{ data.theme }}</div>
            <div class="col-md-2 text-center">
              {{ data.question_type_id == 1 ? data.subtheme : "NA" }}
            </div>
            <div class="col-md-2 text-center">{{ i + 1 }}</div>
            <div class="col-md-1 text-center">
              <button
                *ngIf="!defaultSubthemes.includes(data.question_id)"
                mat-icon-button
                matTooltip="Click to Remove"
                class="iconbutton"
                (click)="deleteQuestionnaireQuestion(i)"
                color="warn"
              >
                <!-- <mat-icon>delete</mat-icon> -->
                <i class="fa fa-trash"></i>
              </button>
              <p *ngIf="defaultSubthemes.includes(data.question_id)">NA</p>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center pt-10">
        <button
          (click)="submitStructure(isInstrumentEdit ? 'Update' : 'Submit')"
          type="button"
          [disabled]="masterQuestionnaireForm.invalid"
          class="btn btn-secondary btn-newuser-clr"
        >
          {{ isInstrumentEdit ? "Update" : "Submit" }}
        </button>
      </div>
    </div>
  </ng-template>
</div>
