import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AdminServices } from "../admin.service";
import { saveAs } from "file-saver";

@Component({
  selector: "app-leadership-factories",
  templateUrl: "./leadership-factories.component.html",
  styleUrls: ["./leadership-factories.component.css"],
})
export class LeadershipFactoriesComponent implements OnInit {
  reportForm: FormGroup;

  constructor(private fb: FormBuilder, private _service: AdminServices) {
    this.reportForm = this.fb.group({
      startDate: [""],
      endDate: [""],
    });
  }
  ngOnInit() {}

  onDownloadReport() {
    const start_date = this.reportForm.get("startDate")
      ? this.reportForm.get("startDate").value
      : null;
    const end_date = this.reportForm.get("endDate")
      ? this.reportForm.get("endDate").value
      : null;

    this._service.getReport({ start_date, end_date }).subscribe({
      next: (blob: Blob) => {
        // Use FileSaver.js library to save the Blob as a file
        saveAs(blob, "client_report.xlsx");
      },
      error: (response) => {
        console.log("error", response.error);
      },
    });
  }
}
