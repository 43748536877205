import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { AdminServices } from "../admin.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { BsModalService } from "ngx-bootstrap/modal";
import swal from "sweetalert2";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
@Component({
  selector: "app-nudge-category",
  templateUrl: "./nudge-category.component.html",
  styleUrls: ["./nudge-category.component.css"],
})
export class NudgeCategoryComponent implements OnInit {
  constructor(
    private adminService: AdminServices,
    private modalService: BsModalService,
    private formBuilder: FormBuilder
  ) {}
  public nudgeCategoryTitle = "";
  public categoryDataForm: FormGroup;
  isEdit: Boolean = false;
  isCustom: Boolean = false;
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  modalRef: BsModalRef;
  editNudgeData: any;
  categories: any[] = [];
  subthemes: any[] = [];
  displayedColumns: string[] = [
    "ID",
    "Title",
    "Sub Theme",
    "Code",
    "Custom",
    "Edit",
  ];

  hideEditModal() {
    this.isEdit = false;
    this.isCustom = false;
    this.modalRef.hide();
    this.categoryDataForm.reset();
  }

  ngOnInit(): void {
    this.getCategory();
    this.categoryDataForm = this.formBuilder.group({
      id: new FormControl(null),
      title: new FormControl("", [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(200),
      ]),
      sub_theme_id: new FormControl("", [Validators.required]),
      is_custom: new FormControl(false),
    });
  }
  openConfirmation() {
    swal
      .fire({
        title: "Are you sure you want to continue?",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: this.isEdit ? "Update Category" : "Yes",
        cancelButtonText: "Back to edit",
      })
      .then((result) => {
        if (result.value) {
          this.addCategoryData();
        }
      });
  }
  addCategoryData() {
    const formData = { ...this.categoryDataForm.value };
    if (!this.isEdit) {
      delete formData.id;
    }
    if (!formData.is_custom) {
      formData.is_custom = false;
    }
    const nudgeServiceCall = this.isEdit
      ? this.adminService.updateCategory(formData)
      : this.adminService.addCategory(formData);

    nudgeServiceCall.subscribe((res: any) => {
      if (res.status) {
        swal.fire(
          "Success",
          this.isEdit ? "Updated Successfully" : "Added Successfully"
        );
        this.hideEditModal();
        this.getCategory();
      } else {
        swal.fire("Error", res.message ? res.message : res.error);
      }
    });
  }

  openEditNudgeModal(template: TemplateRef<any>, data) {
    this.editNudgeData = data;
    this.isEdit = true;
    this.categoryDataForm.patchValue({
      id: data.id,
      title: data.title,
      sub_theme_id: data.sub_theme_id,
      is_custom: data.is_custom,
    });
    this.markFormGroupTouched(this.categoryDataForm);
    const config = { class: "modal-lg" };
    this.modalRef = this.modalService.show(template, config);
    this.modalRef.onHidden.subscribe(() => {
      this.hideEditModal();
    });
  }
  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

  openAddNudgeModal(template: TemplateRef<any>, type: string) {
    const config = { class: "modal-lg" };
    this.modalRef = this.modalService.show(template, config);
    this.adminService.addModal(this.modalRef);
  }
  getCategory() {
    this.adminService.getNudgeCategories().subscribe((res: any) => {
      if (res.status) {
        this.dataSource.data = res.data.categories;
        this.subthemes = res.data.subthemes;
        this.dataSource.paginator = this.paginator;
      } else {
        swal.fire("Error", res.message ? res.message : res.error);
      }
    });
  }
}
