<app-loader *ngIf="isLoading"></app-loader>
<section *ngIf="!isLoading">
  <h2>B2C Round two process</h2>
  <div>
    <div class="section">
      <div
        class="section-item"
        (click)="selectedSection = i"
        *ngFor="let section of sections; let i = index"
        [ngClass]="{ 'active-section': selectedSection === i }"
      >
        {{ section }}
      </div>
    </div>
    <div [ngSwitch]="selectedSection">
      <div *ngSwitchCase="0">
        <app-round-two-data-process></app-round-two-data-process>
      </div>
      <div *ngSwitchCase="1">
        <app-round-two-reports></app-round-two-reports>
      </div>
      <div *ngSwitchCase="2">
        <app-r2-extension></app-r2-extension>
      </div>
    </div>
  </div>
</section>
