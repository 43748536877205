<div class="container main">
  <div>
    <div class="row">
      <div class="col-md-5">
        <div class="form-group pb-10">
          <input
            class="form-control custom-form-control"
            required
            type="text"
            placeholder="Search question..."
            (keyup)="searchQuestion($event.target['value'])"
          />
        </div>
      </div>
      <div class="col-md-5 text-right">
        <button
          (click)="addUpdateQuestion(question, {}, false)"
          type="button"
          class="btn btn-secondary btn-newuser-clr"
        >
          Add New
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-5">
        <!-- <label>Filter Questions by Master Questionnaire Id:</label>
        <div class="form-group pb-10">
          <input
            required
            type="text"
            placeholder="Enter Master Qyestionnair..."
          />
        </div> -->
      </div>
    </div>
    <div class="questions-table">
      <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z4">
        <ng-container matColumnDef="questionId">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Question Id</mat-header-cell
          >
          <mat-cell *matCellDef="let element">
            {{ element.questionId }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="questionText">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Question Text</mat-header-cell
          >
          <mat-cell *matCellDef="let element">
            {{ element.questionText }}</mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="questionType">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Question Type</mat-header-cell
          >
          <mat-cell *matCellDef="let element">
            {{ element.questionType }}</mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="theme">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Theme</mat-header-cell
          >
          <mat-cell *matCellDef="let element"> {{ element.theme }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="subtheme">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Sub Theme</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{ element.subtheme }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="questionPhrase">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Question Phrase</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{
            element.questionPhrase
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <i
              class="fa fa-edit"
              (click)="addUpdateQuestion(question, element, true)"
              style="margin-right: 15px"
            ></i>
            <!-- <i
              (click)="deleteQuestion(element.questionId)"
              class="fa fa-trash"
              style="margin-right: 15px"
            ></i> -->
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let col; columns: displayedColumns"></mat-row>
      </mat-table>

      <mat-paginator
        [pageSizeOptions]="[45, 60, 75]"
        (page)="pageChanged($event)"
      ></mat-paginator>
      <div
        class="text-center emptyContainer"
        *ngIf="dataSource.data.length === 0"
      >
        Not found any questions.
      </div>
    </div>
  </div>
  <ng-template #question>
    <div class="questions">
      <h4>
        <b>{{ isQuestionEdit ? "UPDATE" : "ADD" }} QUESTION:</b>
      </h4>
      <form [formGroup]="surveyQuestionForm">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group pb-10">
              <label for="themeId">Theme<span class="asterick">*</span></label>
              <select
                class="form-control custom-form-control"
                required
                id="themeId"
                name="themeId"
                formControlName="themeId"
                (change)="onThemeChange($event)"
              >
                <option value="" selected>Select</option>
                <option value="{{ theme.id }}" *ngFor="let theme of themeArray">
                  {{ theme.theme_name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group pb-10">
              <label for="subthemeId"
                >Sub Theme<span class="asterick">*</span></label
              >
              <select
                class="form-control custom-form-control"
                required
                id="subthemeId"
                name="subthemeId"
                formControlName="subthemeId"
              >
                <option value="" selected>Select</option>
                <option
                  value="{{ subtheme.subthemeId }}"
                  *ngFor="let subtheme of filteredSubthemeArray; let i = index"
                >
                  {{ subtheme.subtheme }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group pb-10">
              <label for="question">
                Question Phrase<span class="asterick">*</span></label
              >
              <input
                class="form-control custom-form-control"
                required
                id="questionPhrase"
                name="questionPhrase"
                type="text"
                formControlName="questionPhrase"
                placeholder="Enter question phrase"
                maxlength="60"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="questionType"
                >Question Type<span class="asterick">*</span></label
              >
              <select
                class="form-control custom-form-control"
                required
                id="questionTypeId"
                name="questionTypeId"
                formControlName="questionTypeId"
                (change)="changeQuestionType($event.target['value'])"
              >
                <option value="" selected>Select</option>
                <option value="1">Rating</option>
                <option value="2">Select</option>
                <option value="4">Open Ended</option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group pb-10">
              <label for="question">
                Question Text<span class="asterick">*</span></label
              >
              <input
                class="form-control custom-form-control"
                required
                id="questionText"
                name="questionText"
                type="text"
                formControlName="questionText"
                placeholder="Enter questionText"
                (change)="onChangeQuestionText($event.target['value'])"
              />
            </div>
          </div>
        </div>
        <div
          class="options mt-30"
          *ngIf="
            eligibleQuestionTypes.includes(
              +surveyQuestionForm.value.questionTypeId
            )
          "
        >
          <div class="row mb-20">
            <div class="col-md-6">
              <p><b>Rating/Select Question Options:</b></p>
            </div>
            <div
              class="col-md-4 text-center"
              *ngIf="[2].includes(+surveyQuestionForm.value.questionTypeId)"
            >
              <button
                type="button"
                style="color: white"
                class="btn btn-secondary btn-newuser-clr"
                (click)="addOptions()"
              >
                Add Option +
              </button>
            </div>
          </div>
          <div formArrayName="optionsArray">
            <div *ngFor="let o of options.controls; let i = index">
              <div class="row" [formGroupName]="i">
                <div class="col-md-6">
                  <div class="form-group pb-10">
                    <label for=""
                      >Option {{ i + 1 }}<span class="asterick">*</span></label
                    >
                    <input
                      class="form-control custom-form-control"
                      required
                      type="text"
                      formControlName="answer_text"
                      [attr.disabled]="
                        surveyQuestionForm.value.questionTypeId == '1'
                          ? 'disabled'
                          : null
                      "
                      placeholder="Type here.."
                    />
                  </div>
                </div>
                <div
                  class="col-md-6 text-center"
                  *ngIf="surveyQuestionForm.value.questionTypeId != '1'"
                >
                  <i
                    (click)="removeOption(i)"
                    class="fa fa-trash"
                    style="margin-top: 40px"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="questionRespondent mt-30"
          *ngIf="[1, '1'].includes(surveyQuestionForm.value.questionTypeId)"
        >
          <div class="row mb-20">
            <div class="col-md-6">
              <p><b>Question Respondent Mapping:</b></p>
            </div>
            <!-- <div class="col-md-4 text-center">
              <button
                type="button"
                style="color: white"
                class="btn btn-secondary btn-newuser-clr"
                (click)="addquestionMaping()"
              >
                Add Respondent +
              </button>
            </div> -->
          </div>
          <div formArrayName="questionRespondentMapping">
            <div *ngFor="let d of questionMaping.controls; let i = index">
              <div class="row" [formGroupName]="i">
                <div class="col-md-4">
                  <div class="form-group pb-10">
                    <label>
                      Respondent Type<span class="asterick">*</span></label
                    >
                    <select
                      class="form-control custom-form-control"
                      required
                      formControlName="respondent_type"
                      [attr.disabled]="true"
                    >
                      <option value="" selected>Select</option>
                      <option
                        *ngFor="let type of qrmArray"
                        value="{{ type.respondent_type }}"
                      >
                        {{ type.respondentTypeText }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group pb-10">
                    <label>Question Text<span class="asterick">*</span></label>
                    <input
                      class="form-control custom-form-control"
                      required
                      formControlName="question_text"
                      type="text"
                      placeholder="Type here.. "
                    />
                  </div>
                </div>
                <div class="col-md-2 text-center">
                  <i
                    class="fa fa-trash"
                    (click)="removequestionMaping(i)"
                    style="margin-top: 40px"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center">
          <!-- [disabled]="surveyQuestionForm.invalid &&( surveyQuestionForm.value.questionTypeId !== '4' && !surveyQuestionForm.controls.questionPhrase.invalid && !surveyQuestionForm.controls.questionText.invalid && !surveyQuestionForm.controls.subthemeId.invalid && !surveyQuestionForm.controls.themeId.invalid)" -->

          <button
            type="button"
            style="margin-top: 25px; color: white"
            class="btn btn-secondary btn-newuser-clr"
            (click)="submitQuestion(isQuestionEdit ? 'update' : 'create')"
            [disabled]="
              (surveyQuestionForm.value.questionTypeId == '4' &&
                (surveyQuestionForm.controls['questionPhrase'].invalid ||
                  surveyQuestionForm.controls['questionText'].invalid ||
                  surveyQuestionForm.controls['subthemeId'].invalid ||
                  surveyQuestionForm.controls['themeId'].invalid)) ||
              (surveyQuestionForm.value.questionTypeId != '4' &&
                surveyQuestionForm.invalid)
            "
          >
            {{ isQuestionEdit ? "Update" : "Add" }}
          </button>
        </div>
      </form>
    </div>
  </ng-template>
</div>
