<div class="container-fluid" style="padding-top: 3%">
  <!-- //<app-loader *ngIf="loading"></app-loader> -->
  <div
    class="col-md-12 form-card"
    style="
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    "
  >
    <div class="row">
      <div class="col-md-12" style="padding-bottom: 2%">
        <div class="row" style="padding-top: 2%">
          <form (ngSubmit)="onAddPackage(f)" #f="ngForm">
            <div class="col-md-4">
              <div class="form-group col-md-12 pb-10">
                <label for="title">Email<span class="asterick">*</span></label>
                <input
                  type="email"
                  name="email"
                  class="form-control custom-form-control"
                  placeholder="Enter manager email address"
                  [(ngModel)]="email"
                  (change)="validate()"
                  (keyup)="validate()"
                  required
                  #adminEmail="ngModel"
                />
                <p
                  class="warning"
                  *ngIf="!adminEmail.valid && adminEmail.touched"
                >
                  Please Enter manager email
                </p>
                <p class="warning" *ngIf="email != ''">{{ emailStatus }}</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group col-md-12 pb-10">
                <label for="email"
                  >Package<span class="asterick">*</span></label
                >
                <select
                  class="form-control custom-form-control"
                  [(ngModel)]="package"
                  name="package"
                  (change)="onSelectPackage($event.target['value'])"
                  ngModel
                  required
                  #packageName="ngModel"
                >
                  <option value="" selected>select</option>
                  <option
                    *ngFor="let package of packageArray"
                    [value]="package.name"
                  >
                    {{ package.name }}
                  </option>
                </select>
                <p
                  class="warning"
                  *ngIf="!packageName.valid && packageName.touched"
                >
                  Please Select package
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group col-md-12 pb-10" style="padding-top: 9%">
                <button
                  type="submit"
                  class="btn btn-primary addmantra-btn"
                  [disabled]="!f.valid || !isEmailValid"
                >
                  Assign coupon
                </button>
                <button
                  type="button"
                  class="btn btn-primary create-coupon-btn"
                  [routerLink]="[
                    '/admin/admin-dashboard/assign-coupon/create-coupon'
                  ]"
                >
                  Create Global coupon
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div
        class="col-md-12 col-xs-6 col-md-6 subadmin-title"
        style="padding: 0px !important"
      >
        <div class="col-md-7" style="padding-top: 2%; padding-bottom: 2%">
          <input
            type="text"
            (keyup)="applyFilter($event.target['value'])"
            class="form-control search-icon"
            placeholder="Enter your search..."
            (keyup)="applyFilter($event.target['value'])"
          />
          <div class="clearfix"></div>
        </div>
      </div>
      <div class="users-table">
        <div class="subadmin-table">
          <div class="example-container container mar-tp tab-size">
            <mat-table [dataSource]="dataSource" matSort>
              <!-- email id Column -->
              <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >Email id</mat-header-cell
                >
                <mat-cell *matCellDef="let element">
                  {{ element.email }}</mat-cell
                >
              </ng-container>

              <!-- code Column -->
              <ng-container matColumnDef="code">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >Coupon Code</mat-header-cell
                >
                <mat-cell *matCellDef="let element">{{
                  element.code
                }}</mat-cell>
              </ng-container>
              <!-- feature Column -->
              <ng-container matColumnDef="feature">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >Assigned Feature</mat-header-cell
                >
                <mat-cell *matCellDef="let element">
                  {{ element.feature }}</mat-cell
                >
              </ng-container>
              <!-- feature Column -->
              <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >Action</mat-header-cell
                >
                <mat-cell *matCellDef="let element">
                  <i
                    class="fa fa-trash"
                    style="font-size: 16px"
                    (click)="onDelete(element.email)"
                  ></i>
                </mat-cell>
              </ng-container>
              <mat-header-row
                *matHeaderRowDef="displayedColumns"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedColumns"
              ></mat-row>
            </mat-table>
            <mat-paginator
              [length]="7"
              [pageSize]="7"
              [pageSizeOptions]="[7, 14, 28]"
            >
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
