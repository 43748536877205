<div class="form-group col-md-12 pb-10 d-flex">
  <div class="" style="display: flex; justify-content: end; gap: 10px">
    <button
      style="float: right"
      class="bold-text btn btn-gmi-primary mr-1"
      (click)="openEmailTemplate(emailTemp)"
      *ngIf="selectedUsers.length > 0"
    >
      <!-- (click)="sendBulkMails()" -->
      <i class="fa fa-envelope" style="margin-right: 15px"></i>Send Mail
    </button>

    <button
      style="float: right"
      class="bold-text btn btn-gmi-primary mr-1"
      (click)="addUserPopup('addTemplate', addTemplate)"
    >
      <!-- (click)="openAddModal('addTemplate', addTemplate)" -->
      <i class="fa fa-user-plus" style="margin-right: 15px"></i>Add user
    </button>

    <button
      style="float: right"
      class="bold-text btn btn-gmi-primary mr-1"
      (click)="openAddModal('addConfig', addConfig)"
    >
      <i class="fa fa-user-plus" style="margin-right: 15px"></i>Add Company
      Configuration
    </button>

    <button
      style="float: right"
      class="bold-text btn btn-gmi-primary mr-1"
      (click)="
        openAddModal('managerDemographicsUpload', managerDemographicsUpload)
      "
    >
      <i class="fa fa-upload" aria-hidden="true" style="margin-right: 15px"></i>
      Upload Managers’ Data
    </button>

    <!-- <button
      class="bold-text btn btn-gmi-primary mr-1"
      (click)="openMapViewModal(mapView)"
    >
      <i class="fa fa-user-plus" style="margin-right: 15px"></i>Map View
    </button> -->
  </div>
</div>
<br />

<app-bounce-loader *ngIf="showLoading"></app-bounce-loader>
<div class="row">
  <div class="col-md-12">
    <h3 style="text-align: center">User list</h3>
    <table class="table table1" *ngIf="Userdata.length > 0; else noData">
      <tr>
        <th>
          <input
            class="form-check-input"
            type="checkbox"
            [(ngModel)]="selectAll"
            (change)="selectAllChanged()"
          />
        </th>
        <th>Email</th>
        <!-- <th>Status</th> -->
        <th>Map View</th>
        <th>Benchmark Access</th>
        <th>Edit</th>
        <th>Delete</th>
        <!-- <th>Delete</th> -->
      </tr>
      <tr *ngFor="let data of Userdata">
        <td>
          <input
            class="form-check-input"
            type="checkbox"
            [(ngModel)]="data.checked"
            (change)="onCheckboxChange($event, data)"
            value=""
            id="flexCheckDefault"
          />
        </td>
        <td>{{ data.email }}</td>
        <td class="text-center">
          <i
            data-toggle="tooltip"
            data-placement="top"
            title="Edit Mapping"
            *ngIf="data.component?.length > 0"
            class="fa fa-sliders cursor"
            style="color: darkcyan"
            (click)="openUserMapViewModal(userMappingViewTemplate, data)"
          >
          </i>

          <i
            data-toggle="tooltip"
            data-placement="top"
            title="Add Mapping"
            *ngIf="data.component?.length == 0"
            class="fa fa-plus cursor"
            style="color: darkgreen"
            (click)="openUserMapViewModal(userMappingViewTemplate, data)"
          >
          </i>
        </td>
        <td class="text-center">
          <i
            data-toggle="tooltip"
            data-placement="top"
            title="Manage Benchmark Access"
            class="fa fa-sliders cursor"
            style="color: darkcyan"
            (click)="
              manageBenchmarkAccessModal(manageBenchmarkTemplate, data.id)
            "
          >
          </i>
        </td>
        <!-- <td>
          <i
            data-toggle="tooltip"
            data-placement="top"
            title="Select Survey "
            class="fa fa-bar-chart cursor"
            (click)="openSurveyModal(surveyTemplate, data)"
          >
          </i>
        </td> -->

        <td>
          <i
            data-toggle="tooltip"
            data-placement="top"
            title="Edit User"
            class="fa fa-edit cursor"
            style="color: blue"
            (click)="openRoleEditModal(addTemplate, data)"
          >
          </i>
        </td>
        <td>
          <i
            data-toggle="tooltip"
            data-placement="top"
            title="Delete User"
            class="fa fa-trash cursor"
            style="color: red"
            (click)="deleteUser(data)"
          >
          </i>
        </td>
      </tr>
    </table>
    <ng-template #noData>
      <h4 style="display: flex; justify-content: center">No Data Found</h4>
    </ng-template>
  </div>
</div>

<ng-template #addTemplate>
  <div class="modal-content" style="width: 1050px">
    <div class="modal-header">
      <h4 style="font-size: 22px" class="modal-title pull-left">Add User</h4>
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="closeOpenModal()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12" style="padding: 0">
          <div class="form-group col-md-12">
            <h3>{{ isEdit ? "Update" : "Add" }} new user</h3>
            <form [formGroup]="roleForm">
              <div class="form-field">
                <mat-form-field class="width-full" appearance="fill">
                  <mat-label>Name</mat-label>
                  <input
                    matInput
                    formControlName="name"
                    placeholder="Enter Name"
                  />
                  <mat-error *ngIf="roleForm.get('name').hasError('required')">
                    Name is required
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-field">
                <mat-form-field class="width-full" appearance="fill">
                  <mat-label>Email</mat-label>
                  <input
                    matInput
                    formControlName="email"
                    placeholder="Enter Email"
                  />
                  <mat-error *ngIf="roleForm.get('email').hasError('required')">
                    Email is required
                  </mat-error>
                  <mat-error *ngIf="roleForm.get('email').hasError('email')">
                    Invalid email address
                  </mat-error>
                </mat-form-field>
              </div>
              <mat-accordion>
                <div formArrayName="mappings">
                  <mat-expansion-panel
                    *ngFor="let mapping of mappings.controls; let i = index"
                    [expanded]="i === mappings.length - 1"
                    [formGroupName]="i"
                  >
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Role access {{ i + 1 }}
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-form-field appearance="fill" class="width-full">
                      <mat-label>Survey Group</mat-label>
                      <mat-select formControlName="surveyGroupId">
                        <mat-option
                          *ngFor="let group of surveyGroups"
                          [value]="group.id"
                          [disabled]="isSurveyGroupIdSelected(group.id)"
                        >
                          {{ group.itemName }}
                        </mat-option>
                      </mat-select>
                      <mat-error
                        *ngIf="
                          mapping.get('surveyGroupId').hasError('required')
                        "
                      >
                        Survey Group is required
                      </mat-error>
                    </mat-form-field>
                    <angular2-multiselect
                      [data]="
                        demoNames[mapping.get('surveyGroupId').value] || []
                      "
                      formControlName="demoName"
                      [settings]="dropdownSettings"
                    ></angular2-multiselect>
                    <div
                      formArrayName="demoValues"
                      *ngFor="
                        let demoValueControl of getDemoValuesArray(i).controls;
                        let j = index
                      "
                    >
                      <h3>
                        {{
                          getDemoValueName(mapping.get("demoName").value[j]?.id)
                        }}
                      </h3>
                      <angular2-multiselect
                        [data]="
                          getDemoValues(mapping.get('demoName').value[j]?.id)
                        "
                        [formControlName]="j"
                        [settings]="dropdownSettings"
                      ></angular2-multiselect>
                    </div>
                    <button
                      type="button"
                      (click)="resetMapping(i)"
                      *ngIf="isEdit"
                      class="btn btn-danger margin-top"
                    >
                      Edit
                    </button>
                    <button
                      type="button"
                      (click)="removeMapping(i)"
                      *ngIf="isEdit"
                      class="btn btn-warning margin-top ml-6 button"
                    >
                      Remove
                    </button>
                    <div>
                      <h3 class="warning">
                        * If you want to change mapping click on edit or to
                        remove mapping click remove button
                      </h3>
                    </div>
                  </mat-expansion-panel>
                </div>
              </mat-accordion>
              <button
                type="button"
                class="btn btn-primary margin-top"
                (click)="addMappingNew()"
              >
                Add Role access
              </button>
            </form>
          </div>
        </div>
      </div>
      <div>
        <h3 class="warning">
          * If you dont select any survey group/filter the user will have access
          to all survey groups/filters by default
        </h3>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-gmi-primary"
        (click)="onSubmit(false)"
        *ngIf="!isEdit"
        [disabled]="!roleForm.valid"
      >
        Submit
      </button>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-gmi-primary"
        (click)="onSubmit(true)"
        *ngIf="isEdit"
        [disabled]="!roleForm.valid"
      >
        Update
      </button>
    </div>
  </div>
</ng-template>

<ng-template #userMappingViewTemplate>
  <div class="modal-content" style="width: 1050px">
    <div class="modal-header">
      <h4 style="font-size: 22px" class="modal-title pull-left">
        Map View to User
      </h4>
      <button
        type="button"
        class="close pull-right"
        aria-label="Close "
        (click)="closeModal()"
      >
        <span aria-hidden="true ">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <mat-accordion>
          <mat-expansion-panel
            *ngFor="let pageDetail of componentData.pageDetails"
          >
            <mat-expansion-panel-header>
              <mat-checkbox [(ngModel)]="pageDetail.is_active"></mat-checkbox>
              <span style="margin-left: 10px">{{
                pageDetail.component_name
              }}</span>
            </mat-expansion-panel-header>

            <div class="row">
              <h4 *ngIf="pageDetail.page_type == 2">Manager Demographics</h4>
              <ng-container
                *ngFor="let component of componentData.componentDetails"
              >
                <div *ngIf="component?.user_type == userType.MANAGER">
                  <mat-slide-toggle
                    [(ngModel)]="component.is_active"
                    class="col-sm-4"
                    *ngIf="component.page_type == pageDetail.page_type"
                  >
                    {{ component.component_name }}
                  </mat-slide-toggle>
                </div>
              </ng-container>
            </div>
            <div class="row">
              <h4 *ngIf="pageDetail.page_type == 2">
                Respondants Demographics
              </h4>
              <ng-container
                *ngFor="let component of componentData.componentDetails"
              >
                <div *ngIf="component.user_type == userType.RESPONDANTS">
                  <mat-slide-toggle
                    [(ngModel)]="component.is_active"
                    class="col-sm-4"
                    *ngIf="component.page_type == pageDetail.page_type"
                  >
                    {{ component.component_name }}
                  </mat-slide-toggle>
                </div>
              </ng-container>
            </div>
          </mat-expansion-panel>

          <ng-container *ngFor="let group of groupedComponents">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <ng-container *ngIf="+group.pageType == pageType.MENU_OPTIONS">
                  Menu Configuration
                </ng-container>
                <ng-container *ngIf="+group.pageType == pageType.THEME_COLOR">
                  Theme Colour Configuration
                </ng-container>
                <ng-container
                  *ngIf="
                    +group.pageType == pageType.VISUALIZATION &&
                    +group.userType == userType.MANAGER
                  "
                >
                  Manager Analytics
                </ng-container>
                <ng-container
                  *ngIf="
                    +group.pageType == pageType.VISUALIZATION &&
                    +group.userType == userType.RESPONDANTS
                  "
                >
                  Employee Analytics
                </ng-container>
                <ng-container
                  *ngIf="
                    +group.pageType == pageType.VISUALIZATION &&
                    +group.userType == userType.EMPLOYEE_COMMENT_VISUALIZATION
                  "
                >
                  Employee Comment Analytics
                </ng-container>
                <ng-container
                  *ngIf="
                    +group.pageType == pageType.VISUALIZATION &&
                    +group.userType == userType.ORGANIZATION
                  "
                >
                  Organization Analytics
                </ng-container>
              </mat-expansion-panel-header>

              <div class="option-container">
                <!-- <div class="title-section">
                  <h3 class="title" *ngIf="group.pageType == pageType.MENU_OPTIONS">Menu</h3>
                  <h3 class="title" *ngIf="group.pageType == pageType.THEME_COLOR">
                    Theme Colours
                  </h3>
                </div> -->

                <ng-container
                  *ngFor="let component of group.components; let i = index"
                >
                  <div *ngIf="+component.page_type == pageType.MENU_OPTIONS">
                    <div class="col-sm-4">
                      <mat-slide-toggle [(ngModel)]="component.is_active">
                        {{ component.component_name }}
                      </mat-slide-toggle>
                    </div>
                  </div>
                  <div *ngIf="+component.page_type == pageType.THEME_COLOR">
                    <div class="col-sm-4">
                      <p>{{ component.component_name }}</p>
                      <input
                        readonly
                        [(colorPicker)]="component.colour"
                        [style.background]="component.colour"
                        colorPicker
                      />
                    </div>
                  </div>
                </ng-container>

                <!-- Visualization and other configurations for pageType 5 -->
                <div *ngIf="+group.pageType == pageType.VISUALIZATION">
                  <table class="visualization-table">
                    <thead>
                      <tr>
                        <th *ngFor="let data of employeeAnalyticsTableHeader">
                          {{ data }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="
                          let component of group.components;
                          let i = index
                        "
                      >
                        <td>{{ component.component_name }}</td>
                        <td>
                          <mat-slide-toggle [(ngModel)]="component.is_active">
                          </mat-slide-toggle>
                        </td>
                        <td>
                          <!-- <ng-container *ngIf="component.editing">
                            <input
                              type="text"
                              [(ngModel)]="component.header"
                              (ngModelChange)="onHeaderInputChange()"
                            />
                            <i
                              class="fa fa-check"
                              style="color: darkgreen"
                              (click)="modify(component, 'header', 'save')"
                            ></i>
                            <i
                              class="fa fa-times"
                              style="color: red"
                              (click)="modify(component, 'header', 'cancel')"
                            ></i>
                          </ng-container> -->
                          <ng-container *ngIf="!component.editing">
                            {{ component.header }}
                            <!-- <i
                              class="fa fa-edit"
                              style="color: blue"
                              (click)="modify(component, 'header', 'edit')"
                            ></i> -->
                          </ng-container>
                        </td>
                        <td>
                          <!-- <ng-container *ngIf="component.editingDescription">
                            <input
                              class="col-sm-12"
                              type="text"
                              [(ngModel)]="component.description"
                              (ngModelChange)="onDescriptionInputChange()"
                            />
                            <i
                              class="fa fa-check"
                              style="color: green"
                              (click)="modify(component, 'description', 'save')"
                            ></i>
                            <i
                              class="fa fa-times"
                              style="color: red"
                              (click)="
                                modify(component, 'description', 'cancel')
                              "
                            ></i>
                          </ng-container> -->
                          <ng-container *ngIf="!component.editingDescription">
                            {{ component.description }}
                            <!-- <i
                              class="fa fa-edit"
                              style="color: blue"
                              (click)="modify(component, 'description', 'edit')"
                            ></i> -->
                          </ng-container>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </mat-expansion-panel>
          </ng-container>
        </mat-accordion>

        <button
          style="margin-top: 3%; margin-left: 87%"
          type="submit"
          class="btn btn-primary"
          (click)="saveMapping()"
          (click)="modalRef.hide()"
        >
          <!-- [disabled]="isAnyComponentEditing()" -->
          Save Mapping
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #managerDemographicsUpload let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h4 style="font-size: 22px" class="modal-title pull-left">
        Upload Demographics
      </h4>
      <button
        type="button"
        class="close pull-right"
        aria-label="Close "
        (click)="modalRef.hide(); resetValues()"
      >
        <span aria-hidden="true ">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <label for="demographicsTypeSelect">Demographics Type:</label>
        <select
          class="form-control"
          id="demographicsTypeSelect"
          (change)="changeDemographicsType($event)"
        >
          <option value="1">Performance</option>
          <option value="2">Retention</option>
        </select>
      </div>
      <div class="file-upload">
        <h3>Upload Excel File</h3>
        <label
          class="bold-text btn btn-gmi-primary mr-1"
          for="myFileInput"
          (click)="resetFileInput()"
        >
          <input type="file" id="myFileInput" (input)="onFileChange($event)" />
          Choose File +
        </label>

        <a [href]="sampleExcelLink" style="margin-left: 10px">
          Download Sample
        </a>

        <br />
      </div>
      <div *ngIf="displayedColumns.length > 2">
        <mat-table [dataSource]="displayedData">
          <ng-container
            *ngFor="let batch of displayedColumns"
            [matColumnDef]="batch"
          >
            <mat-header-cell *matHeaderCellDef> {{ batch }} </mat-header-cell>
            <mat-cell class="text-center" *matCellDef="let element">
              {{ element[batch] }}
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <mat-paginator
          [length]="managerList.data.length"
          [pageSize]="itemsPerPage"
          [pageSizeOptions]="[1, 5, 10]"
          (page)="onPageChange($event)"
        ></mat-paginator>
      </div>
      <div>
        <button
          class="bold-text btn btn-gmi-primary final-submit"
          (click)="submitPerformanceData()"
          [disabled]="managerList.data.length == 0"
          *ngIf="managerList.data.length != 0"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #emailTemp>
  <div class="modal-header">
    <h4
      style="font-size: 22px; text-transform: capitalize"
      class="modal-title pull-left"
    >
      Add email
    </h4>
    <button
      type="button "
      class="close pull-right"
      aria-label="Close "
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="form-container">
          <div class="input-group">
            <label for="emailType"
              >Email Type <span class="error">*</span></label
            >
            <select #emailTypeInput (change)="onEmailTypeChanged($event)">
              <option value="" selected disabled>
                Please select email type
              </option>
              <option *ngFor="let type of emailType" [value]="type.id">
                {{ type.name }}
              </option>
            </select>
          </div>
          <div class="input-group">
            <label for="subject"
              >Mail Subject <span class="error">*</span></label
            >
            <input
              #subjectInput
              type="text"
              name="subject"
              id="subject"
              [(ngModel)]="emailData[0].subject"
            />
          </div>
          <label for="content">Content <span class="error">*</span></label>

          <app-email-editor
            [(content)]="emailData[0].content"
            #contentInput
          ></app-email-editor>

          <div class="button-container">
            <button
              class="btn gmi-primary-btn"
              [disabled]="
                !emailTypeInput.value || !subjectInput.value || !contentInput
              "
              (click)="sendBulkMailsToUsers(emailTypeInput.value)"
            >
              <!-- (click)="sendBulkMails(emailTypeInput.value)" -->
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #surveyTemplate let-isVisible="isVisible">
  <div
    class="modal-header"
    *ngIf="modalService.config.initialState?.['isVisible'] "
  >
    <h4
      style="font-size: 22px; text-transform: capitalize"
      class="modal-title pull-left"
    >
      Select Survey
    </h4>
    <button
      type="button "
      class="close pull-right"
      aria-label="Close "
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="form-container">
          <div class="button-container">
            <div class="survey-container">
              <div
                class="survey-row"
                *ngFor="let survey of surveyData; let i = index"
              >
                <label>
                  <!-- [checked]="survey.checked" -->
                  <input
                    [checked]="survey.checked"
                    type="radio"
                    id="p"
                    [value]="survey"
                    (change)="toggleSurveySelectionNew(survey)"
                  />

                  {{ survey.surveyStartDate | date : "dd-MMM-yyyy" }} -
                  {{ survey.surveyEndDate | date : "dd-MMM-yyyy" }} ({{
                    survey.surveyGroupId
                  }})
                </label>
              </div>
            </div>

            <button
              *ngIf="modalService.config.initialState?.['isVisible'] "
              class="btn gmi-primary-btn"
              (click)="sendSelectedSurvey()"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #addConfig>
  <div class="modal-content" style="width: 1050px">
    <div class="modal-header">
      <h4 style="font-size: 22px" class="modal-title pull-left">
        Add Company Configuration
      </h4>
      <button
        type="button"
        class="close pull-right"
        aria-label="Close "
        (click)="closeModal()"
      >
        <span aria-hidden="true ">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <mat-accordion>
          <ng-container *ngFor="let group of groupedComponents">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <ng-container *ngIf="+group.pageType == pageType.MENU_OPTIONS">
                  Menu Configuration
                </ng-container>
                <ng-container *ngIf="+group.pageType == pageType.THEME_COLOR">
                  Theme Colour Configuration
                </ng-container>
                <ng-container
                  *ngIf="
                    +group.pageType == pageType.VISUALIZATION &&
                    +group.userType == userType.MANAGER
                  "
                >
                  Manager Analytics
                </ng-container>
                <ng-container
                  *ngIf="
                    +group.pageType == pageType.VISUALIZATION &&
                    +group.userType == userType.RESPONDANTS
                  "
                >
                  Employee Analytics
                </ng-container>
                <ng-container
                  *ngIf="
                    +group.pageType == pageType.VISUALIZATION &&
                    +group.userType == userType.EMPLOYEE_COMMENT_VISUALIZATION
                  "
                >
                  Employee Comment Visualization
                </ng-container>

                <ng-container
                  *ngIf="
                    +group.pageType == pageType.VISUALIZATION &&
                    +group.userType == userType.ORGANIZATION
                  "
                >
                  Organization Analytics
                </ng-container>
              </mat-expansion-panel-header>

              <div class="option-container">
                <!-- <div class="title-section">
                  <h3 class="title" *ngIf="group.pageType == pageType.MENU_OPTIONS">Menu</h3>
                  <h3 class="title" *ngIf="group.pageType == pageType.THEME_COLOR">
                    Theme Colours
                  </h3>
                </div> -->

                <ng-container
                  *ngFor="let component of group.components; let i = index"
                >
                  <div *ngIf="+component.page_type == pageType.MENU_OPTIONS">
                    <div class="col-sm-4">
                      <mat-slide-toggle [(ngModel)]="component.is_active">
                        {{ component.component_name }}
                      </mat-slide-toggle>
                    </div>
                  </div>
                  <div *ngIf="+component.page_type == pageType.THEME_COLOR">
                    <div class="col-sm-4">
                      <p>{{ component.component_name }}</p>
                      <input
                        readonly
                        [(colorPicker)]="component.colour"
                        [style.background]="component.colour"
                        colorPicker
                      />
                    </div>
                  </div>
                </ng-container>

                <!-- Visualization and other configurations for pageType 5 -->
                <div *ngIf="+group.pageType == pageType.VISUALIZATION">
                  <table class="visualization-table">
                    <thead>
                      <tr>
                        <th *ngFor="let data of employeeAnalyticsTableHeader">
                          {{ data }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="
                          let component of group.components;
                          let i = index
                        "
                      >
                        <td>{{ component.component_name }}</td>
                        <td>
                          <mat-slide-toggle [(ngModel)]="component.is_active">
                          </mat-slide-toggle>
                        </td>
                        <td>
                          <ng-container *ngIf="component.editing">
                            <input
                              type="text"
                              [(ngModel)]="component.header"
                              (ngModelChange)="onHeaderInputChange()"
                            />
                            <i
                              class="fa fa-check"
                              style="color: darkgreen"
                              (click)="modify(component, 'header', 'save')"
                            ></i>
                            <i
                              class="fa fa-times"
                              style="color: red"
                              (click)="modify(component, 'header', 'cancel')"
                            ></i>
                          </ng-container>
                          <ng-container *ngIf="!component.editing">
                            {{ component.header }}
                            <i
                              class="fa fa-edit"
                              style="color: blue"
                              (click)="modify(component, 'header', 'edit')"
                            ></i>
                          </ng-container>
                        </td>
                        <td>
                          <ng-container *ngIf="component.editingDescription">
                            <input
                              class="col-sm-12"
                              type="text"
                              [(ngModel)]="component.description"
                              (ngModelChange)="onDescriptionInputChange()"
                            />
                            <i
                              class="fa fa-check"
                              style="color: green"
                              (click)="modify(component, 'description', 'save')"
                            ></i>
                            <i
                              class="fa fa-times"
                              style="color: red"
                              (click)="
                                modify(component, 'description', 'cancel')
                              "
                            ></i>
                          </ng-container>
                          <ng-container *ngIf="!component.editingDescription">
                            {{ component.description }}
                            <i
                              class="fa fa-edit"
                              style="color: blue"
                              (click)="modify(component, 'description', 'edit')"
                            ></i>
                          </ng-container>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </mat-expansion-panel>
          </ng-container>
        </mat-accordion>

        <button
          style="margin-top: 3%; margin-left: 87%"
          type="submit"
          class="btn btn-primary"
          [disabled]="isAnyComponentEditing()"
          (click)="saveConfig()"
          (click)="modalRef.hide()"
        >
          Save Config
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #manageBenchmarkTemplate>
  <div class="modal-content" style="width: 1050px">
    <div class="modal-header">
      <h4 style="font-size: 22px" class="modal-title pull-left">
        Manage Benchmarks Access
      </h4>
      <button
        type="button"
        class="close pull-right"
        aria-label="Close "
        (click)="closeModal()"
      >
        <span aria-hidden="true ">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-5">
          <div
            class="form-group pb-10"
            style="max-height: 250px; overflow-y: scroll"
          >
            <h2 style="font-weight: bolder; position: sticky">
              Add Other Benchmark
            </h2>
            <div *ngFor="let benchmark of allBenchmarks" class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="{{ 'benchmark_' + benchmark.benchmark_list_id }}"
                name="other"
                value="{{ benchmark.benchmark_list_id }}"
                (change)="addOtherBenchmark(benchmark, $event.target)"
              />
              <label
                class="form-check-label"
                style="margin-left: 15px"
                for="{{ 'benchmark_' + benchmark.benchmark_list_id }}"
              >
                {{ benchmark.benchmark_name }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="benchmarkList.length > 0; else nobenchmarkList">
        <table class="table table1">
          <thead>
            <tr>
              <th>Serial No</th>
              <th>Benchmark Name</th>
              <th>Access</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let benchmark of benchmarkList; let i = index">
              <td>{{ i + 1 }}</td>
              <td>{{ benchmark.benchmark_name }}</td>
              <td>
                <mat-slide-toggle [(ngModel)]="benchmark.status">
                </mat-slide-toggle>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="text-center">
          <button
            type="submit"
            class="btn btn-primary"
            (click)="saveBenchmarkUserMapping(); modalRef.hide()"
          >
            Save
          </button>
        </div>
      </div>
      <ng-template #nobenchmarkList>
        <div class="text-center">
          <h2>No Default Benchmarks Found.</h2>
        </div>
      </ng-template>
    </div>
  </div>
</ng-template>
