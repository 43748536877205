import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { AdminServices } from "../../admin.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { QUESTION_TYPE_MAPPING } from "src/app/constants";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
@Component({
  selector: "app-survey-dialog",
  templateUrl: "./survey-dialog.component.html",
  styleUrls: ["./survey-dialog.component.css"],
})
export class SurveyDialogComponent implements OnInit {
  dialogForm: FormGroup;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = [
    "question_type_id",
    "header",
    "message",
    // "image",
    "status",
    "button_text",
    "actions",
  ];
  dataSource = new MatTableDataSource<any>();
  isUpdate: boolean = false;
  QUESTION_TYPE_MAPPING = QUESTION_TYPE_MAPPING;
  constructor(
    private formBuilder: FormBuilder,
    private adminService: AdminServices,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.getSurveyDialogs();
    this.initializeForm();
  }

  initializeForm() {
    this.dialogForm = this.formBuilder.group({
      id: [""],
      question_type_id: ["", [Validators.required]],
      header: [
        "",
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(50),
        ],
      ],
      message: [
        "",
        [
          Validators.required,
          Validators.minLength(50),
          Validators.maxLength(250),
        ],
      ],
      status: [false, [Validators.required]],
      button_text: [
        "",
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(20),
        ],
      ],
    });
  }

  createSurvey(data: any) {
    const { question_type_id, header, message, status, button_text, id } = data
      ? data
      : this.dialogForm.value;

    const paramsData = {
      id,
      question_type_id,
      header,
      message,
      status: status == false ? 0 : 1,
      button_text,
      mode: this.isUpdate ? "update" : "create",
    };

    this.adminService
      .surveyDialogOperation(paramsData)
      .subscribe((response) => {
        if (response.status && this.isUpdate) {
          this.updateInArray(paramsData, 0);
          this.dialogForm.reset();
          this.isUpdate = false;
          this.getSurveyDialogs();
        }
        this.openSnackBar(response.message, response.status);
      });
  }

  patchValues(element: any) {
    const { question_type_id, header, message, status, button_text, id } =
      element;
    this.dialogForm.patchValue({
      question_type_id,
      header,
      message,
      status,
      button_text,
      id,
    });
    this.isUpdate = true;
  }

  deleteSurvey(id: number) {
    this.adminService
      .surveyDialogOperation({ id, mode: "delete" })
      .subscribe((response) => {
        this.updateInArray(null, id);
        this.openSnackBar(response.message, response.status);
      });
  }

  getSurveyDialogs() {
    this.adminService.getSurveyDialogs().subscribe((response) => {
      this.dataSource.data = response.data;
      this.dataSource.paginator = this.paginator;
    });
  }

  updateInArray(element: any, id?: number) {
    const matchId = element ? element.id : id;
    const index = this.dataSource.data.findIndex((x) => x.id == matchId);
    if (index > -1) {
      element
        ? (this.dataSource.data[index] = element)
        : (this.dataSource.data[index]["status"] = 0);
      this.dataSource._updateChangeSubscription();
    }
  }

  openSnackBar(message: any, status: boolean) {
    this._snackBar.open(message, "", {
      duration: 2000,
      panelClass: status ? ["green-snackbar"] : ["red-snackbar"],
    });
  }

  statusChange($event: MatSlideToggleChange, element) {
    if (!$event.checked) {
      this.deleteSurvey(element.id);
    } else {
      this.isUpdate = true;
      element["status"] = 1;
      this.createSurvey(element);
    }
  }
}
