<div class="login-main">
  <div class="container-fluid clearfix">
    <!-- //<app-loader *ngIf="loading"></app-loader> -->
    <div class="row">
      <div class="tbl">
        <div class="tbl-cel-left"></div>

        <div class="tbl-cel-right">
          <form [formGroup]="newPasswordForm">
            <div class="col-md-6 login-right text-center">
              <div class="form-group">
                <img src="../../../assets/images/KEY.png" />
                <h3>Forgot Password</h3>
                <p style="color: #9b9b9b">
                  Password must
                  have at least 8 characters, should contain a mixture of both uppercase
                  and lowercase letters, a mixture of letters and numbers, and include
                  at least one special character, e.g., ! @ # ?
                </p>
              </div>

              <div class="form-group mt-30">
                <div class="input-group">
                  <input
                    type="password"
                    id="passwordView"
                    (change)="passwordCheck()"
                    class="form-control input-lg"
                    placeholder="Enter New Password"
                    formControlName="newPassword"
                  />
                  <span
                    class="input-group-addon"
                    style="
                      border-top-right-radius: 25px;
                      border-bottom-right-radius: 25px;
                      cursor: pointer;
                      background-color: #d7dce3;
                    "
                  >
                    <i
                      class="fa {{ eyeIcon }}"
                      aria-hidden="true"
                      (click)="togglePasswordView()"
                      style="color: #b8b8b8; font-size: 20px"
                    ></i>
                  </span>
                </div>
              </div>

              <div class="form-group">
                <div class="input-group">
                  <input
                    type="password"
                    id="passwordView1"
                    (change)="confirmPasswordCheck()"
                    class="form-control input-lg"
                    placeholder="Retype Password"
                    formControlName="confirmPassword"
                  />
                  <span
                    class="input-group-addon"
                    style="
                      border-top-right-radius: 25px;
                      border-bottom-right-radius: 25px;
                      cursor: pointer;
                      background-color: #d7dce3;
                    "
                  >
                    <i
                      class="fa {{ eyeIcon1 }}"
                      aria-hidden="true"
                      (click)="togglePasswordView1()"
                      style="color: #b8b8b8; font-size: 20px"
                    ></i>
                  </span>
                </div>
              </div>

              <div class="form-group mt-25">
                <button
                  type="submit"
                  [disabled]="passwordError || confirmPasswordError"
                  class="btn gmi-primary-btn"
                  (click)="newPassword()"
                >
                  CONFIRM PASSWORD
                </button>
              </div>

              <div class="form-group mt-25">
                <a routerLink="/manager">Cancel Request</a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
