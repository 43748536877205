import { AdminServices } from "../admin.service";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DataSource } from "@angular/cdk/table";
import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  TemplateRef,
} from "@angular/core";
// import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";

import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
// import { Observable } from 'rxjs/Observable';
// import 'rxjs/add/observable/of';
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import Swal from "sweetalert2";
@Component({
  selector: "app-create-mentor",
  templateUrl: "./create-mentor.component.html",
  styleUrls: ["./create-mentor.component.css"],
})
export class CreateMentorComponent implements OnInit {
  data: any;
  data1: any;
  parentMessage: any;
  companyData: any;
  addCompanyName: any;
  addCompanyId: any;
  modalRef: BsModalRef;
  addName: any;
  addEmail: any;
  isEmailValid: any;
  addGender: any = "";
  checkedItem = {};
  addMobile: any;
  industryType: any = "";
  companyNameFromChild: string = "";
  newCompanyNameFromChild: string = "";
  emailStatus: any;
  companyName: any;
  mobile: any;
  industrySubType: any = "";
  line1: any;
  line2: any;
  pinCode: any;
  city: any;
  state: any;
  websiteUrl: any;
  kpName: any;
  kpEmail: any;
  kpPhone: any;
  loading: boolean;
  websiteUrlStatus: any;
  companyId: any;
  package: any;
  textInput: boolean;
  invoice: any;
  surveyEngineCompanyId;
  country: any;
  premium: number = 0;
  special: number = 0;
  basic: number = 0;
  remainingPremium: number = 0;
  remainingSpecial: number = 0;
  remainingBasic: number = 0;
  isWebsiteValid: boolean = false;
  currentDate: any;
  MentorList = [];
  displayedColumns = ["managerName", "managerEmail", "actions"];
  gender = [{ value: "Male" }, { value: "Female" }];
  finalManagerList = [];
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  managerInfo: TemplateRef<any>;
  constructor(
    private router: Router,
    private adminService: AdminServices,
    private modalService: BsModalService,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.loading = true;
    this.adminService.getallMentors({}).subscribe((response: any) => {
      if (response.status) {
        this.data1 = [];
        this.loading = false;
        this.data = response.data;
        console.log(this.data);
        // this.totalManagers = response.data.length;
        this.data.forEach((mentor) => {
          let mentorInfo = {
            name: mentor.name,
            email: mentor.email,
            id: mentor._id,
          };
          this.data1.push(mentorInfo);
        });
        this.dataSource = new MatTableDataSource(this.data1);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      } else {
      }
      this.loading = false;
    });

    this.adminService.getallMentors({}).subscribe((response: any) => {
      if (response.status) {
        this.MentorList = response.data;
      } else {
      }
      this.loading = false;
    });
  }
  getHeight() {
    return { height: window.innerHeight + "px" };
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  // to search in table
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  openAddModal(template: TemplateRef<any>) {
    const config = { class: "modal-lg" };
    this.modalRef = this.modalService.show(template, config);
    this.adminService.addModal(this.modalRef);
  }

  // to get company name from other component
  getCompanyOutputValFromChild(selected: string) {
    if (selected) {
      this.companyNameFromChild = selected;
      this.newCompanyNameFromChild = "";
    }
  }

  // to check either company already exist or not
  gettIsNewCompanyFromParent(isNew: boolean) {
    if (isNew) {
      this.newCompanyNameFromChild = this.companyNameFromChild;
    }
  }

  // to add new comoany
  openManagerModal(template: TemplateRef<any>, id) {
    const config = { class: "modal-md" };
    this.modalRef = this.modalService.show(template, config);
    this.adminService.addModal(this.modalRef);
  }

  openAssignMentorModal(template: TemplateRef<any>) {
    const config = { class: "modal-md" };
    this.modalRef = this.modalService.show(template, config);
    this.adminService.addModal(this.modalRef);
  }

  // to get admin list
  getAdminList(addCompanyId) {
    this.adminService.getCompanyAdmin(addCompanyId).subscribe((response) => {
      this.data = response.data;
    });
  }

  // to add new admin to the company
  addMentor() {
    this.loading = true;
    var adminDetails = {
      name: this.addName,
      email: this.addEmail,
      status: true,
    };
    this.adminService.addMentor(adminDetails).subscribe((response: any) => {
      if (response.status) {
        this.loading = false;
        Swal.fire({
          title: "Mentor add",
          text: "Mentor added successfully",
          icon: "success",
          showConfirmButton: false,
          timer: 2500,
        });
        this.ngOnInit();
      } else {
        this.loading = false;
        Swal.fire({
          title: "Mentor add",
          text: "Mentor not added",
          icon: "error",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    });
    this.getAdminList(this.addCompanyId);
  }

  // to select gender
  onSelectgender(val) {
    this.addGender = val;
  }

  // to select industry type
  onSelectIndustryType(event) {
    this.industryType = event;
  }

  // to select industry sub type
  onSelectIndustrySubType(event) {
    this.industrySubType = event;
  }

  //to change status
  changeStatus(email, status) {
    this.adminService
      .changeMentorStatus(email, !status)
      .subscribe((response: any) => {
        if (response.status) {
          this.ngOnInit();
        } else {
        }
      });
  }

  // to reset form
  onSubmit(formData) {
    formData.reset();
  }
  validate() {
    //Validating Email..
    if (this.validateEmail(this.addEmail)) {
      this.isEmailValid = true;
      this.emailStatus = "";
      this.adminService
        .checkEmailForMentor(this.addEmail)
        .subscribe((response: any) => {
          this.isEmailValid = true;
          this.emailStatus = "";
          if (response.status) {
            // console.log("Valid");
          } else {
            this.isEmailValid = false;
            this.emailStatus = response.message;
          }
        });
    } else {
      this.isEmailValid = false;
      this.emailStatus = "InValid Email ID";
    }
  }

  // validate email
  validateEmail(email) {
    var re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  addPremium() {
    //to increase number of premium package
    this.premium = this.premium + 1;
  }
  subPremium() {
    //to decrease number of premium package
    if (this.premium == 0) {
      this.premium = 0;
    } else {
      this.premium = this.premium - 1;
    }
  }
  addSpecial() {
    //to increase number of special package
    this.special = this.special + 1;
  }
  subSpecial() {
    //to decrease number of special package
    if (this.special == 0) {
      this.special = 0;
    } else {
      this.special = this.special - 1;
    }
  }
  addBasic() {
    //to increase number of basic package
    this.basic = this.basic + 1;
  }
  subBasic() {
    //to decrease number of basic package
    if (this.basic == 0) {
      this.basic = 0;
    } else {
      this.basic = this.basic - 1;
    }
  }
}
