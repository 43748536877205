import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CompanyServices } from '../../company.service';

@Component({
  selector: 'app-manager-list-gpms',
  templateUrl: './manager-list-gpms.component.html',
  styleUrls: ['./manager-list-gpms.component.css'],
})
export class ManagerListGpmsComponent implements OnInit {
  displayedColumns: string[] = ['select', 'name', 'email'];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  dataSource = new MatTableDataSource();
  uploadedManagerList: any = [];
  managers: any = [];
  isRegister: any = {};
  isCertificate: any = {};
  isTeamFeedBack: any = {};
  isActionPlanning: any = {};
  revoke: any = {};
  isSas: any = {};
  isPackage: any = {};
  checkall: any;
  searchKey!: string;
  displayNoRecords = false;
  loading!: boolean;
  constructor(private companyService: CompanyServices) {}

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit() {
    this.getCompanyAddedManagerList();
  }

  getCompanyAddedManagerList() {
    this.companyService.managerData.subscribe((response: any) => {
      // console.log('hit', response);
      this.managers = response;
      this.uploadedManagerList = [];
      let modulesCompltedPresent = false;
      response.forEach((manager: any) => {
        if (manager.personalInfo.isVerified) {
          this.isRegister[manager._id] = true;
        } else {
          this.isRegister[manager._id] = false;
        }
        if (manager.personalInfo.accessRevoked) {
          this.revoke[manager._id] = true;
        } else {
          this.revoke[manager._id] = false;
        }
        if (manager.package_info.features.length > 0) {
          this.isPackage[manager._id] = true;
        } else {
          this.isPackage[manager._id] = false;
        }
        if (manager.selfAssesments.length > 0) {
          this.isSas[manager._id] = true;
        } else {
          this.isSas[manager._id] = false;
        }
        if (manager.selfAssesments.length > 0) {
          this.isSas[manager._id] = true;
        } else {
          this.isSas[manager._id] = false;
        }
        if (manager.managerInfo.badge.length > 0) {
          this.isCertificate[manager._id] = true;
        } else {
          this.isCertificate[manager._id] = false;
        }
        if (manager.surveys.length > 0) {
          this.isTeamFeedBack[manager._id] = true;
        } else {
          this.isTeamFeedBack[manager._id] = false;
        }
        if (
          manager.actionPlannings.length >= 2 &&
          manager.seDetails.seCompanyId === '8'
        ) {
          this.isActionPlanning[manager._id] = true;
        } else if (
          manager.actionPlannings.length > 0 &&
          manager.seDetails.seCompanyId !== '8'
        ) {
          this.isActionPlanning[manager._id] = true;
        } else {
          this.isActionPlanning[manager._id] = false;
        }
        if (!manager.personalInfo.batch) {
          manager.personalInfo.batch = 'No Batch Assigned';
        }
        if (manager.package_info.features.length > 0) {
          if (new Date(manager.package_info.endDate) > new Date(Date.now())) {
            manager.packageStatus = 'Active';
          } else {
            manager.packageStatus = 'Expire';
          }
        }
        if (manager.personalInfo.isRegisteredByCompany) {
          let managerInfo: any = {
            name: manager.personalInfo.name,
            email: manager.personalInfo.email,
            profileLink: manager.personalInfo.userName,
            batchName: manager.personalInfo.batchName,
            package: manager.packageStatus,
            packageName: manager?.package_info?.packageName,
            id: manager._id,
            participants: manager.participants,
            modulesCompleted:
              typeof manager.modulesCompleted !== 'undefined'
                ? manager.modulesCompleted
                : 'NA',
          };
          if (typeof manager.modulesCompleted !== 'undefined') {
            modulesCompltedPresent = true;
          }
          this.uploadedManagerList.push(managerInfo);
        }
      });
      // console.log('displayColumns', this.displayedColumns);
      if (
        modulesCompltedPresent &&
        !this.displayedColumns.includes('moduleCompleted')
      ) {
        this.displayedColumns.push('moduleCompleted');
      } else {
        this.displayedColumns = this.displayedColumns.filter((value) => {
          return value !== 'moduleCompleted';
        });
      }
      this.dataSource.data = [...this.uploadedManagerList];
    });
    console.log(this.dataSource, 'data12');
  }

  checkUncheckAll() {
    if (this.checkall) {
      this.checkall = true;
    } else {
      this.checkall = false;
    }
    this.uploadedManagerList.forEach((selectedManager: any) => {
      selectedManager.checked = this.checkall;
      this.onChange(selectedManager, selectedManager.checked);
    });
  }

  onChange(selectedManger: any, isChecked: boolean) {
    if (isChecked) {
      this.count++;
      if (!this.selectedManagers.includes(selectedManger)) {
        this.selectedManagers.push(selectedManger);
      }
    } else {
      this.count--;
      let index = this.selectedManagers.indexOf(selectedManger);
      this.selectedManagers.splice(index, 1);
    }
    this.collectSelectedManagers(this.selectedManagers);
  }
  count = 0;
  selectedManagers: any = [];
  updateCheckall(participant: any, isChecked: boolean) {
    ////console.log(isChecked);
    ////console.log(participant)
    var userTotal = this.uploadedManagerList.length;
    var count = 0;
    if (isChecked) {
      this.count++;
      if (!this.selectedManagers.includes(participant)) {
        this.selectedManagers.push(participant);
      }
    } else {
      this.count--;
      let index = this.selectedManagers.indexOf(participant);
      this.selectedManagers.splice(index, 1);
    }
    this.collectSelectedManagers(this.selectedManagers);
    if (userTotal == this.count) {
      this.checkall = true;
    } else {
      this.checkall = false;
    }
  }
  applyFilter() {
    this.dataSource.filter = this.searchKey.trim().toLowerCase();
    if (this.dataSource.filteredData.length == 0) {
      this.displayNoRecords = true;
    } else {
      this.displayNoRecords = false;
    }
  }

  onSearchClear() {
    this.searchKey = '';
    this.applyFilter();
  }

  collectSelectedManagers(data: any) {
    this.companyService.collectSelectedManagers(data);
  }
}
