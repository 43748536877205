<div class="container">
  <div class="page-header">
    <p class="title">Add Subareas/Goals to managers</p>
    <div class="excel-input-container">
      <p class="text">Upload Excel Sheet as a filter</p>
      <button class="btn gmi-upload-button" (click)="fileInput.click()">
        Upload File
      </button>
      <!-- <a href="https://s3.ap-south-1.amazonaws.com/s3.gmi/sample+filter.xlsx">
        Download Sample
      </a> -->
      <a href="https://media.greatmanagerinstitute.com/sample+filter.xlsx">
        Download Sample
      </a>
      <div class="sheet-container" *ngIf="fileInput?.files?.[0]">
        <p>
          <!-- {{ fileInput.files[0].name }} -->
          {{ fileInput?.files?.item(0)?.name }}
        </p>
        <p class="delete-button" (click)="deleteExcelSheet()">
          <i class="fa fa-trash-o" aria-hidden="true"></i>
        </p>
      </div>

      <input
        style="width: 0; height: 0"
        id="fileSelect"
        type="file"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        name="fileUpload"
        ngModel
        required
        #fileUpload="ngModel"
        #fileInput
        (change)="onFileChange($event, detailsModal)"
      />
      <p
        *ngIf="!excelType && touched"
        style="margin-top: 10px; color: red; font-size: 12px"
      >
        Invalid file type
      </p>
      <p
        *ngIf="excelHeader"
        style="margin-top: 10px; color: red; font-size: 12px"
      >
        Please add following header 1. Manager Name , 2. Manager Email
      </p>
      <div *ngIf="errorsInData.length > 0" class="error-container">
        <p class="text">Following Errors found in excel sheet</p>
        <ul>
          <li *ngFor="let error of errorsInData">
            {{ error.name }} - {{ error.email }}
          </li>
        </ul>
        <span>Please edit in excel sheet and again add it.</span>
      </div>
    </div>
  </div>
  <div class="main-container">
    <p class="title">Manager lists</p>
    <!--  adding material table -->
    <mat-form-field>
      <input
        matInput
        (keyup)="applyFilter($any($event.target).value)"
        placeholder="Filter"
      />
    </mat-form-field>
    <table *ngIf="existingManagers" mat-table [dataSource]="existingManagers">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
      </ng-container>
      <!-- Symbol Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td
          mat-cell
          *matCellDef="let element"
          (click)="openViewModal(element.id, viewSubareas)"
        >
          view
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      [pageSizeOptions]="[5, 10, 20]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>

<ng-template #detailsModal>
  <mat-tab-group>
    <mat-tab label="Subareas">
      <div class="modal-header">
        <h4 style="font-size: 22px" class="modal-title pull-left">
          Add subareas to managers
        </h4>
        <button
          type="button "
          class="close pull-right"
          aria-label="Close "
          (click)="closeModal()"
        >
          <span aria-hidden="true ">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="action-container">
              <p class="sub-title">Select subareas</p>
              <div class="subareas-container">
                <ngx-select-dropdown
                  (change)="selectSubareas($event)"
                  [multiple]="true"
                  [config]="configDropdownAreas"
                  [options]="subareas"
                ></ngx-select-dropdown>
              </div>
              <div class="optional-subareas-container">
                <p class="sub-title">Additional subareas</p>
                <div class="subareas-container">
                  <div class="form-group">
                    <select
                      name="area"
                      id="area"
                      class="form-control"
                      [(ngModel)]="area"
                    >
                      <option value="" disabled>Select Area</option>
                      <option *ngFor="let area of areas" value="{{ area }}">
                        {{ area }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="subarea">Please enter subarea name</label>
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        id="subarea"
                        [(ngModel)]="subarea"
                        placeholder="Subarea name"
                      />
                    </div>
                  </div>
                </div>
                <div class="btn-container">
                  <button class="gmi-primary-btn" (click)="addAdditionalSub()">
                    Add
                  </button>
                </div>
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Area</th>
                      <th>Subareas</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let area of selectedSubareas">
                      <td>{{ area.value }}</td>
                      <td>{{ area.name }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p class="sub-title">
                Selected Manager's list ({{ filterManagers.length }})
              </p>
              <div class="review-container">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let manager of filterManagers">
                      <td>{{ manager.name }}</td>
                      <td>{{ manager.email }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="btn-container">
                <button class="gmi-primary-btn" (click)="submit()">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Goals">
      <div class="modal-header">
        <h4 style="font-size: 22px" class="modal-title pull-left">
          Add goals to managers
        </h4>
        <button
          type="button "
          class="close pull-right"
          aria-label="Close "
          (click)="closeModal()"
        >
          <span aria-hidden="true ">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="action-container">
              <p class="sub-title">Select Goals</p>
              <div class="subareas-container">
                <ngx-select-dropdown
                  (change)="selectGoals($event)"
                  [multiple]="true"
                  [config]="configDropdownGoals"
                  [options]="goals"
                ></ngx-select-dropdown>
                <div class="form-group">
                  <label for="goals" class="sub-title m-top"
                    >Please enter additional goal name</label
                  >
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="goals"
                      [(ngModel)]="additionalGoals"
                      placeholder="Goals name"
                    />
                  </div>
                  <div class="btn-container">
                    <button
                      class="gmi-primary-btn"
                      (click)="addAdditionalGoals()"
                    >
                      Add
                    </button>
                  </div>
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th class="center">Selected Goals</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let goal of selectedGoals">
                        <td class="center">{{ goal.name }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <p class="sub-title m-top">
                    Selected Manager's list ({{ filterManagers.length }})
                  </p>
                  <div class="review-container">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let manager of filterManagers">
                          <td>{{ manager.name }}</td>
                          <td>{{ manager.email }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="btn-container">
                    <button class="gmi-primary-btn" (click)="submitGoals()">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</ng-template>

<ng-template #viewSubareas>
  <div class="modal-content">
    <div class="modal-header">
      <h4 style="font-size: 22px" class="modal-title pull-left">
        View subareas and goals
      </h4>
      <button
        type="button "
        class="close pull-right"
        aria-label="Close "
        (click)="modalRef.hide()"
      >
        <span aria-hidden="true ">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Sub Areas</th>
            <th>Goals</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ singleManagerDetails.name }}</td>
            <td>{{ singleManagerDetails.email }}</td>
            <td>
              <table class="table">
                <tr *ngFor="let area of singleManagerDetails.action">
                  <td>{{ area.name }}</td>
                </tr>
              </table>
            </td>
            <td>
              <table class="table">
                <tr *ngFor="let goal of singleManagerDetails.goals">
                  <td>{{ goal.name }}</td>
                </tr>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>
