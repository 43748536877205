import { Component, OnInit } from "@angular/core";
import { CompanyServices } from "../company.service";
import swal from "sweetalert2";
@Component({
  selector: "app-nudges-configuration",
  templateUrl: "./nudges-configuration.component.html",
  styleUrls: ["./nudges-configuration.component.css"],
})
export class NudgesConfigurationComponent implements OnInit {
  nudgeConfiguration: any = {};
  categories: any[] = [];
  leaderBoardCount = 0;
  activeNudgeManager = 0;
  maximumNudgeLeaderBoard = 100;
  valueChanged: boolean = false;
  // Priority Eligiblity
  pe: any = {
    initialValue: null,
    lastSavedValue: null,
    currentValue: null,
    isResetMode: false,
  };

  constructor(private companyService: CompanyServices) {}

  ngOnInit() {
    this.companyService.getNudgeConfiguration().subscribe((res) => {
      this.nudgeConfiguration = res.data.nudgeConfiguration;
      this.configureCategories(res.data.categories);
      this.pe.initialValue =
        res.data.nudgeSetting != null
          ? res.data.nudgeSetting.priority_eligibility
          : null;
      this.pe.currentValue = this.pe.initialValue;
      this.leaderBoardCount = res.data.nudgeSetting.leaderboard;
      this.activeNudgeManager = res.data.activeNudgeManager;
      if (this.activeNudgeManager < this.maximumNudgeLeaderBoard) {
        this.maximumNudgeLeaderBoard = this.activeNudgeManager;
      }
    });
  }
  onLeaderBoardInputChange() {
    if (
      this.leaderBoardCount >= 1 &&
      this.leaderBoardCount <= this.maximumNudgeLeaderBoard
    ) {
      this.valueChanged = true;
    }
  }
  updateNudgeCompanySetting() {
    this.companyService
      .updateCompanyNudgeSettings({
        leaderBoardCount: this.leaderBoardCount,
      })
      .subscribe((res) => {
        const swalType = res.status ? "success" : "error";
        swal.fire(res.status ? "Success" : "Error", res.message, swalType);
      });
  }
  configureCategories(categories) {
    let catList = [];
    categories.forEach((category) => {
      catList.push({
        nudge_category_id: category.nudge_category_id,
        name: category.name,
        initialValue: category.weight != null ? category.weight : 1,
        lastSavedValue: null,
        currentValue: category.weight != null ? category.weight : 1,
        isResetMode: false,
      });
    });
    this.categories = catList;
  }

  onSave(i: any) {
    let category = this.categories[i];
    category.error = null;
    if (category.currentValue <= 0) {
      category.currentValue = 1;
    }
    this.companyService
      .updateNudgeCategoryCompanyWeight({
        nudge_category_id: category.nudge_category_id,
        weight: category.currentValue,
      })
      .subscribe((res) => {
        // console.log("updateNudgeCategoryCompanyWeight subscribe", res);

        category.lastSavedValue = category.currentValue;
        category.isResetMode = true;
        setTimeout(() => {
          category.initialValue = category.lastSavedValue;
          category.isResetMode = false;
        }, 3000);
      });
  }

  onClear(i: any) {
    let category = this.categories[i];
    category.currentValue = category.initialValue;
    category.isResetMode = false;
  }

  onReset(i: any) {
    let category = this.categories[i];
    category.currentValue = category.initialValue;
    category.isResetMode = false;
    this.companyService
      .updateNudgeCategoryCompanyWeight({
        nudge_category_id: category.nudge_category_id,
        weight: category.currentValue,
      })
      .subscribe((res) => {
        // console.log("updateNudgeCategoryCompanyWeight subscribe", res);
      });
  }

  isEditMode(i: any) {
    let category = this.categories[i];
    if (category == null) return false;
    let defaultVal = category.currentValue == 0 && category.initialValue == 1;
    return (
      category.currentValue != category.initialValue &&
      !defaultVal &&
      (category.lastSavedValue == null ||
        category.currentValue != category.lastSavedValue)
    );
  }
  //clientLogoInNudgeEmail
  onToggleChange(event, type) {
    this.nudgeConfiguration[type] = event.checked;
    if (type == "leaderboard" && !event.checked)
      this.nudgeConfiguration.leaderboardEmail = false;
    if (type == "level" && !event.checked) {
      this.nudgeConfiguration.levelInNudgeEmail = false;
      this.nudgeConfiguration.badges = false;
    }
    // console.log("nudgeConfiguration", this.nudgeConfiguration);
    this.companyService
      .updateNudgeConfiguration({ nudgeConfiguration: this.nudgeConfiguration })
      .subscribe((response) => {
        // console.log("updateNudgeConfiguration subscribe", response);

        if (!response.status) {
          // revert
          this.nudgeConfiguration[type] = !event.checked;
        }
      });
  }

  onPeSave() {
    this.pe.error = null;
    if (this.pe.currentValue < 30 || this.pe.currentValue > 90) {
      this.pe.error = "Should be between 30 and 90";
      return;
    }
    this.companyService
      .updateNudgeSetting({
        priority_eligibility: this.pe.currentValue,
      })
      .subscribe((res) => {
        this.pe.lastSavedValue = this.pe.currentValue;
        this.pe.isResetMode = true;
        setTimeout(() => {
          this.pe.initialValue = this.pe.lastSavedValue;
          this.pe.isResetMode = false;
        }, 3000);
      });
  }

  onPeClear() {
    this.pe.currentValue = this.pe.initialValue;
    this.pe.isResetMode = false;
  }

  onPeInputChange() {
    if (this.pe.currentValue != null) {
      if (
        (this.pe.currentValue < 30 && this.pe.currentValue > 9) ||
        this.pe.currentValue > 90
      )
        this.pe.error = "Should be between 30 and 90";
      else this.pe.error = null;
    } else this.pe.error = null;
  }

  onPeReset() {
    this.pe.currentValue = this.pe.initialValue;
    this.pe.isResetMode = false;
    this.companyService
      .updateNudgeSetting({
        weight: this.pe.currentValue,
      })
      .subscribe((res) => {
        // console.log("onPeReset updateNudgeSetting subscribe", res);
      });
  }

  isPeEditMode() {
    return (
      this.pe.currentValue != this.pe.initialValue &&
      this.pe.currentValue >= 30 &&
      this.pe.currentValue <= 90 &&
      (this.pe.lastSavedValue == null ||
        this.pe.currentValue != this.pe.lastSavedValue)
    );
  }
}
