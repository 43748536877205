<div class="companyReportMain">
  <div class="container">
    <div class="reportSection">
      <div class="row">
        <div class="col-sm-12">
          <h3 class="reportTitle red-header-text">Reports</h3>
          <!-- <div class="totalManagerGroup" *ngIf="totalRegisteredManagers != 0">
            <i
              matTooltip="Total No. of Managers registered"
              matTooltipPosition="above"
              class="fa fa-users"
              aria-hidden="true"
            ></i
            ><span class="noOfManagers">{{ totalRegisteredManagers }}</span>
          </div> -->
          <div class="tabsSection">
            <tabset>
              <tab
                *ngIf="
                  access.lDReportsAccess && selectedFeatures.includes('l&d')
                "
                heading="L&D Reports"
              >
                <div class="tab-pane" *ngIf="selectedFeatures.includes('l&d')">
                  <app-l-d-reports></app-l-d-reports>
                </div>
              </tab>
              <tab
                *ngIf="
                  access.summariesAccess && selectedFeatures.includes('l&d')
                "
                heading="Summaries Reports"
              >
                <div *ngIf="selectedFeatures.includes('l&d')">
                  <app-summary-reports></app-summary-reports>
                </div>
              </tab>
              <tab
                *ngIf="
                  access.surveyReportsAccess && selectedFeatures.includes('sas')
                "
                heading="Survey Reports"
              >
                <div *ngIf="selectedFeatures.includes('sas')">
                  <app-survey-reports></app-survey-reports>
                </div>
              </tab>
              <tab
                *ngIf="
                  access.apReportsAccess && selectedFeatures.includes('ap')
                "
                heading="Action Plan Reports"
              >
                <div *ngIf="selectedFeatures.includes('ap')">
                  <app-action-plan-reports></app-action-plan-reports>
                </div>
              </tab>
            </tabset>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
