import {
  CdkDragDrop,
  CdkDragEnd,
  CdkDropList,
  moveItemInArray,
} from "@angular/cdk/drag-drop";
import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";

import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import {
  MatTable,
  MatTableDataSource,
  MatTableModule,
} from "@angular/material/table";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { AdminServices } from "../../admin.service";
// import { clonedeep } from 'lodash/cloneDeep';
import { cloneDeep } from "lodash";
import { debounceTime } from "rxjs/operators";
import { distinctUntilChanged } from "rxjs/operators";
import { constants, INSTRUMENT_OPTIONS_ARRAY } from "../../../constants";
import swal from "sweetalert2";
import { Subject } from "rxjs";
@Component({
  selector: "app-master-questionnaire",
  templateUrl: "./master-questionnaire.component.html",
  styleUrls: ["./master-questionnaire.component.css"],
})
export class MasterQuestionnaireComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("questionList") questionList: CdkDropList;
  @ViewChild("table") table: MatTable<any>;
  defaultSubthemes = [
    385, 386, 387, 388, 389, 391, 392, 393, 394, 395, 396, 397, 398, 399, 400,
    401, 402, 404, 405, 406, 407, 408, 410, 413, 472, 473, 474, 475, 492,
  ];
  modalRef: BsModalRef;
  masterQuestionnaire = new MatTableDataSource();
  dataSource = [];
  newQuestions = [];
  masterQuestionnaireColumns = ["id", "questionnaireName", "edit"];
  public masterQuestionnaireForm: FormGroup;
  filteredQuestions: any = [];
  displayedColumns = ["question_text", "survey_order", "actions"];
  instrumentOptionsArray: any = [];
  isInstrumentEdit: boolean = false;
  searchTextChanged = new Subject<string>();
  question_text: any = "";
  constructor(
    private adminService: AdminServices,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.getMasterQuestionnaires();
    this.inItmasterQuestionnaireForm();
    this.getFilteredQuestions();
  }

  getMasterQuestionnaires() {
    this.adminService.getMasterQuestionnaires().subscribe((response: any) => {
      this.instrumentOptionsArray = response.data;
      this.masterQuestionnaire.data = response ? response.data : [];
      this.masterQuestionnaire.paginator = this.paginator;
    });
  }

  searchQuestionnaire(value) {
    let filterValue = value.trim().toLowerCase();
    this.masterQuestionnaire.filter = filterValue;
  }

  getQuestionnaireQuestions(value) {
    if (value) {
      this.adminService
        .getQuestionnaireQuestions({
          master_questionnaire_id: value,
        })
        .subscribe((response: any) => {
          this.dataSource = [];
          this.dataSource.push(...response.data);
        });
    }
  }

  showQuestionnaireModal(template: TemplateRef<any>, data, isInstrumentEdit) {
    this.dataSource = [];
    this.isInstrumentEdit = isInstrumentEdit;
    if (isInstrumentEdit) {
      this.masterQuestionnaireForm.patchValue({
        masterQuestionnaireName: data.questionnaireName,
        masterQuestionnaireId: data.id,
      });
      this.getQuestionnaireQuestions(data.id);
    } else {
      this.masterQuestionnaireForm.reset();
    }
    this.modalRef = this.modalService.show(template, {
      class: "modal-lg",
      backdrop: "static",
      keyboard: false,
    });
    this.adminService.addModal(this.modalRef);
  }

  searchQuestion(value) {
    value.length > 5 ? this.searchTextChanged.next(value) : "";
  }

  getFilteredQuestions() {
    this.searchTextChanged
      .pipe(debounceTime(800), distinctUntilChanged())
      .subscribe((value) => {
        this.adminService
          .getFilteredQuestions({ questionText: value })
          .subscribe((response) => {
            if (response.status) {
              this.filteredQuestions = response.data;
            }
          });
      });
  }

  inItmasterQuestionnaireForm() {
    this.masterQuestionnaireForm = this.formBuilder.group({
      masterQuestionnaireName: new FormControl("", []),
      masterQuestionnaireId: new FormControl("", []),
      masterQuestionnaireStructure: [],
    });
  }

  selectQuestion(element, i) {
    let questionObject = {
      question_text: element.question_text,
      question_id: element.question_id,
      question_type_id: element.question_type_id,
      theme: element.theme,
      subtheme: element.subtheme,
      survey_order: i,
      report_order: i,
      question_status: 1,
      tags:
        element.question_type_id == 1
          ? "rating"
          : element.question_type_id == 2
          ? "select"
          : "opended",
    };

    const isQuestionExist = this.dataSource.some(
      (data: any) => data.question_id == element.question_id
    );
    isQuestionExist
      ? this.openSnackBar("Question already exist.", false)
      : (this.dataSource.push(questionObject),
        this.openSnackBar("Question added successfully.", true));
    this.dataSource = cloneDeep(this.dataSource);
  }

  dragAndDrop(event: CdkDragDrop<[]>) {
    moveItemInArray(this.dataSource, event.previousIndex, event.currentIndex);
  }

  submitStructure(data?) {
    this.dataSource = this.dataSource.map(
      ({ question_text: any, theme, subtheme, ...rest }) => ({ ...rest })
    );

    if (!this.isInstrumentEdit) {
      this.dataSource = this.dataSource.map(({ mqsId: any, ...rest }) => ({
        ...rest,
      }));
    }

    this.masterQuestionnaireForm.value.masterQuestionnaireStructure =
      this.dataSource;
    this.adminService
      .saveMasterQuestionnaire({ formData: this.masterQuestionnaireForm.value })
      .subscribe((response) => {
        this.modalRef.hide();
        response && this.openSnackBar(response.message, response.status);
        this.dataSource = [];
      });
  }

  deleteQuestionnaireQuestion(index) {
    swal
      .fire({
        title: "Are you sure you want to remove the question?",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
      })
      .then((result) => {
        if (result.value) {
          this.dataSource[index].question_status = 0;
          this.openSnackBar("Question removed successfully.", true);
          this.dataSource = cloneDeep(this.dataSource);
        }
        // this.adminService.deleteMasterQuestionnaireQuestion({ masterQuestionnaireId: mqsId }).subscribe((response) => {
        //   this.modalService.hide
        //     (1);
        //   response && swal(response.status ? "success" : "error", response.message)
        // });
      });
  }

  openSnackBar(message, status) {
    this._snackBar.open(message, "", {
      duration: 2000,
      panelClass: status ? ["green-snackbar"] : ["red-snackbar"],
      // horizontalPosition: "right",
      // verticalPosition: "top",
    });
  }

  closeModal() {
    this.modalRef.hide();
  }
}
