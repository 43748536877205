import { Injectable } from "@angular/core";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

@Injectable()
export class ExcelService {
  constructor() {}

  public exportAsExcelFile(
    workBook: XLSX.WorkBook,
    excelFileName: string
  ): void {
    const excelBuffer: any = XLSX.write(workBook, {
      bookType: "xlsx",
      type: "buffer",
    });

    this.saveAsExcelFile(excelBuffer, `${excelFileName}${EXCEL_EXTENSION}`);
  }

  public saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName
    );
  }
}
