import { Router } from "@angular/router";
import { AdminServices } from "../../admin.service";
import { Component, OnInit, TemplateRef } from "@angular/core";

import Swal from "sweetalert2";
import { SurveyEngineService } from "../../../services/surveyEngine.service";
// import { ManagerServices } from "../../../manager/manager.service";
import { companyTypes, validateEmail } from "../../../constants";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { FormGroup } from "@angular/forms";
import { NgxDropdownConfig } from "ngx-select-dropdown";
@Component({
  selector: "app-add-company",
  templateUrl: "./add-company.component.html",
  styleUrls: ["./add-company.component.css"],
})
export class AddCompanyComponent implements OnInit {
  form: FormGroup;
  groups: { groupName: string; groupOrder: number }[] = [
    { groupName: "", groupOrder: 1 },
  ];
  groupsSaved: boolean = false;
  selectedSector: string = "";
  selectedIndustryGroup: string = "";
  selectedIndustry: string = "";
  selectedSubIndustry: string = "";
  uniqueSectors = [];
  uniqueIndustryGroups = [];
  uniqueIndustries = [];
  uniqueSubIndustries = [];
  industryGroups = [];
  industries = [];
  subIndustries = [];
  responseRate: boolean = false;
  sasReport: boolean = false;
  selectedSurveyTemplate: number;
  surveyTemplate: any | object[] = [];
  showGroupsAndStatements: boolean = false;
  questionsFromDB = [];
  allDataPresentForGroupsAndStatements = false;
  questionsCount: number;
  modalRef: BsModalRef;
  industryTypeArray = [
    { name: "BFSI" },
    { name: "Hospitality" },
    { name: "Infrastructure & Real Estate" },
    { name: "Electronics" },
    { name: "Consulting" },
    { name: "Internet & Software" },
    { name: "Automotive" },
    { name: "Healthcare & Pharma" },
    { name: "Education" },
    { name: "Retail" },
    { name: "Media & Entertainment" },
    { name: "Manufacturing" },
    { name: "Social" },
    { name: "Logistics" },
    { name: "FMCG" },
    { name: "Legal" },
    { name: "Others" },
  ];
  industrySubTypeArray = [];
  packageArray = [
    { name: "Free" },
    { name: "Basic" },
    { name: "Premium" },
    { name: "Special" },
  ];
  companyTypes: any = companyTypes;

  imageSrc: any;
  addCorporateCompany: any;
  loading: boolean;
  companyName: any;
  custom: number = 0;
  mobile: any;
  industryType: any = "";
  industrySubType: any = "";
  line1: any;
  isNullCompanyValid: boolean;
  line2: any;
  pinCode: any;
  city: any;
  state: any;
  country: any;
  websiteUrl: any;
  accessUrl: any;
  kpName: any;
  kpEmail: any;
  kpUserType: any;
  textInput: boolean = false;
  kpPhone: any;
  websiteUrlStatus: any;
  companyId: any;
  package: any = "";
  invoice: any;
  premium: number = 0;
  special: number = 0;
  basic: number = 0;
  iconSize: number;
  iconSizeStatus: string;
  companyNameFromChild: string = "";
  newCompanyNameFromChild: string = "";
  isWebsiteValid: boolean = false;
  isCompanyValid: boolean = false;
  companyStatus: any;
  subIndustryDisable: boolean = false;
  findSECompayId: boolean = false;
  companyData: any;
  selectedFeatures = [];
  fileName: any;
  isIconValid: boolean;
  gpmsValue: boolean = false;
  hrName: any;
  hrEmail: any;
  companyType: string;
  isEmailValid: boolean;
  emailStatus: string;
  keyPersonArray = [];
  surveyGroupArray = [];
  errorText = "";
  features = [];
  selectedItems: any = [];
  config: NgxDropdownConfig = {
    displayKey: "value",
    search: true,
    height: "auto",
    placeholder: "Select features",
    // customComparator: () => {},
    limitTo: this.features.length,
    moreText: "more",
    noResultsFound: "No results found!",
    searchPlaceholder: "Search",
    searchOnKey: "value",
    // customComparator: function (a: any, b: any): number {
    //   throw new Error("Function not implemented.");
    // },
    customComparator: function (a: any, b: any): number {
      if (a?.name < b?.name) {
        return -1;
      }
      if (a?.name > b?.name) {
        return 1;
      }
      return 0;
      // throw new Error('Function not implemented.');
    },
    clearOnSelection: false,
    inputDirection: "",
  };
  isContactPersonDetails: boolean = false;
  responseRateEmail: boolean = false;
  managerRegistartionAndSas: boolean = false;

  gmcCriteria1: any = {
    actionPlanMin: 0,
    implementationMin: 0,
    learningDevelopmentMin: 0,
  };
  gmcCriteria: Map<string, number> = new Map<string, number>([
    ["actionPlanMin", 0],
    ["implementationMin", 0],
    ["learningDevelopmentMin", 0],
  ]);
  selectedGroupName: string = "";
  isRealTimeFeedback: boolean = false;
  isLeadership: boolean = false;
  showZoho: boolean = true;
  isGMC: boolean = false;
  existingCompany: boolean = false;
  constructor(
    private surveyEngineService: SurveyEngineService,
    private adminService: AdminServices,
    private router: Router, // private managerServices: ManagerServices
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.getAllFeaturesFromSE();
    this.getIndustries();
    this.getMasterQuestionair();
  }

  getAllFeaturesFromSE() {
    this.adminService.getAllFeatures().subscribe((res) => {
      if (res["status"]) {
        this.features = res["data"];
      }
    });
  }

  get availableQuestions() {
    return this.questionsFromDB.filter(
      (question) =>
        !this.selectedItems.some(
          (selectedItem: any) =>
            selectedItem.question.questionId === question.questionId
        )
    );
  }

  toggleItem(questionId: number, groupName: string) {
    const question = this.questionsFromDB.find(
      (q) => q.questionId == questionId
    );

    const selectedItemIndex = this.selectedItems.findIndex(
      (item: any) =>
        item.question.questionId == questionId && item.groupName == groupName
    );
    if (selectedItemIndex != -1) {
      this.selectedItems.splice(selectedItemIndex, 1);
    } else {
      this.selectedItems.push({ question, groupName });
    }
  }

  getQuestionTextById(questionId: number): string {
    const question = this.questionsFromDB.find(
      (q) => q.questionId == questionId
    );
    return question ? question.questionText : "";
  }

  getQuestionSubthemeById(questionId: number): string {
    const question = this.questionsFromDB.find(
      (q) => q.questionId == questionId
    );
    return question ? question.subTheme : "";
  }

  addGroup(groupName: string, groupOrder: number) {
    const newGroup = {
      groupName: groupName,
      groupOrder: this.groups.length + groupOrder,
    };
    this.groups.push(newGroup);
  }

  removeGroup(index: number) {
    this.groups.splice(index, 1);
  }

  isSaveButtonDisabled(): boolean {
    return this.groups.some((group) => !group.groupName || !group.groupOrder);
  }

  saveGroupsInDb() {
    this.adminService
      .getDataToAssignStatements({
        selectedSurveyTemplate: this.selectedSurveyTemplate,
      })
      .subscribe((response: any) => {
        if (response.status) {
          this.questionsFromDB = response.data;
          this.questionsCount = this.questionsFromDB.length;
          this.groupsSaved = true;
          this.selectedGroupName = this.groups[0].groupName;
        }
      });

    this.modalService._hideModal(1);
  }

  saveAll() {
    this.allDataPresentForGroupsAndStatements = true;
    this.modalService._hideModal(1);
  }

  showGroupAndAssignMentPopup(template: TemplateRef<any>) {
    const config = { class: "modal-lg custom-modal" };
    this.modalRef = this.modalService.show(template, config);
    this.adminService.addModal(this.modalRef);
  }

  onSelectIndustryType(event) {
    this.industryType = event;
  }

  onSelectIndustrySubType(event) {
    this.industrySubType = event;
  }

  onSelectPackage(event) {
    this.package = event;
  }

  selectFeature(feature) {
    this.selectedFeatures = feature.value;
  }

  companySetting(event) {
    let type = event.target.value;
    switch (type) {
      case "responseRate":
        this.responseRateEmail = true;
        break;
      case "sasReport":
        this.managerRegistartionAndSas = true;
        break;
      default:
        break;
    }
  }

  addCompany() {
    let profileV2Exist =
      this.selectedFeatures.filter(
        (feature) => feature.type == "profile_overview_v2"
      ).length > 0
        ? true
        : false;
    if (
      this.groupsSaved &&
      this.showGroupsAndStatements &&
      this.allDataPresentForGroupsAndStatements &&
      !profileV2Exist
    ) {
      // Swal({
      //   title: "Assigned survey statements",
      //   text: "As you have assigned survey statements please make sure to add Custom Profile feature in Custom Package",
      //   showCancelButton: true,
      //   cancelButtonText: "",
      // }).then((result) => {
      //   if (result.value) {
      //     this.onAddCompany();
      //   }
      // });
      Swal.fire({
        title: "Assigned survey statements",
        text: "As you have assigned survey statements please make sure to add Custom Profile feature in Custom Package",
        icon: "error",
        showConfirmButton: true,
      });
    } else {
      if (profileV2Exist) {
        Swal.fire({
          title: "Added Custom Profile",
          text: "As you have added 'Custom Profile', new manager scorecard will be assigned to all managers of this company, which would have to be configured using settings feature in the Company Admin Portal. Do you wish to proceed?",
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "Proceed",
          cancelButtonText: "Cancel",
        }).then((result) => {
          if (result.value) {
            this.onAddCompany();
          }
        });
      } else {
        this.onAddCompany();
      }
    }
  }

  resetGroupAndStatementsModal() {
    this.selectedGroupName = "";
    this.groups = [{ groupName: "", groupOrder: 1 }];
    this.questionsFromDB = [];
    this.questionsCount = 0;
    this.groupsSaved = false;
    this.selectedItems = [];
    this.allDataPresentForGroupsAndStatements = false;
    this.showGroupsAndStatements = false;
  }

  onAddCompany() {
    const selectedSector = this.uniqueSectors.find(
      (industryType) => industryType.sector_id == this.selectedSector
    );
    const selectedIndustryGroup = this.industryGroups.find(
      (industryType) =>
        industryType.industry_groups_id == this.selectedIndustryGroup
    );
    const selectedIndustry = this.industries.find(
      (industryType) => industryType.industries_id == this.selectedIndustry
    );
    const selectedSubIndustry = this.subIndustries.find(
      (industryType) =>
        industryType.sub_industries_id == this.selectedSubIndustry
    );
    const gmiSpoc = this.keyPersonArray.some(
      (keyPerson) => keyPerson.userType === "gmiSpoc"
    );

    const companySpoc = this.keyPersonArray.some(
      (keyPerson) => keyPerson.userType === "companySpoc"
    );

    if (!gmiSpoc || !companySpoc) {
      Swal.fire({
        title: "Error",
        text: "Please add GMI SPOC and Company SPOC",
        icon: "error",
        showConfirmButton: true,
        timer: 20000,
      });
      return;
    }
    if (
      (this.selectedFeatures.length > 0 && this.custom !== 0) ||
      this.basic !== 0 ||
      this.premium !== 0 ||
      this.special !== 0
    ) {
      if (this.imageSrc) {
        let iconObject = {
          fileString: this.imageSrc,
          fileName: this.fileName,
          companyName: this.companyName,
        };

        if (this.iconSize < 524288) {
          this.adminService.uploadIcon(iconObject).subscribe((response) => {
            if (response.status) {
              this.imageSrc = response.imageUrl;
              let iconAddress = response.imageUrl;
              iconAddress = iconAddress.replace(
                "https://s3.ap-south-1.amazonaws.com/s3.gmi/",
                ""
              );

              // console.warn("1",iconAddress)
              let companyDetails = {
                companySettings: {
                  sendResponseRateEmail: this.responseRateEmail,
                  sendSasEmail: this.managerRegistartionAndSas,
                },
                surveyMasterQuestionnaireId: this.selectedSurveyTemplate,
                surveyEngineCompanyId: this.companyId,
                companyName: this.companyName,
                sector: selectedSector,
                industryGroup: selectedIndustryGroup,
                industry: selectedIndustry,
                subIndustry: selectedSubIndustry,
                companySpecific: {
                  companyLogo: iconAddress,
                },
                address: {
                  line1: this.line1,
                  line2: this.line2,
                  pinCode: this.pinCode,
                  city: this.city,
                  state: this.state,
                  country: this.country,
                },
                websiteUrl: this.websiteUrl,
                accessUrl: this.accessUrl,
                keyPerson: this.keyPersonArray,
                contactDetails: {
                  hrName: this.hrName,
                  hrEmail: this.hrEmail,
                },
                package: {
                  basic: this.basic,
                  special: this.special,
                  premium: this.premium,
                  custom: this.custom,
                  invoice: this.invoice,
                  createdAt: new Date(),
                },
                companyType: this.companyType,
                totalBasic: this.basic,
                totalPremium: this.premium,
                totalSpecial: this.special,
                totalCustom: this.custom,
                selectedFeatures: this.selectedFeatures,
                gmcCriteria: {
                  ...(this.gmcCriteria.get("actionPlanMin") != undefined && {
                    actionPlanMin: this.gmcCriteria.get("actionPlanMin"),
                  }),
                  ...(this.gmcCriteria.get("implementationMin") !=
                    undefined && {
                    implementationMin:
                      this.gmcCriteria.get("implementationMin"),
                  }),
                  ...(this.gmcCriteria.get("learningDevelopmentMin") !=
                    undefined && {
                    learningDevelopmentMin: this.gmcCriteria.get(
                      "learningDevelopmentMin"
                    ),
                  }),
                },
                allDataPresentForGroupsAndStatements:
                  this.allDataPresentForGroupsAndStatements,
                dataForGroupsAndStatements: {
                  groups: this.groups,
                  requiredData: this.selectedItems,
                },
              };
              // this.companyData.industry = this.industryType;
              this.adminService
                .addCompanies(companyDetails)
                .subscribe((response) => {
                  this.addCorporateCompany = response;
                  if (this.addCorporateCompany.status) {
                    this.loading = false;
                    Swal.fire({
                      title: "Company successfully added",
                      text: this.addCorporateCompany.message,
                      icon: "success",
                      showConfirmButton: false,
                      timer: 3500,
                    });
                    this.router.navigate(["/admin/admin-dashboard/companies"]);
                  } else {
                    this.loading = false;
                    Swal.fire({
                      title: "Add Company",
                      text: this.addCorporateCompany.message,
                      icon: "error",
                      showConfirmButton: false,
                      timer: 3500,
                    });
                  }
                });
            } else {
              switch (response.statusCode) {
                case 1:
                  Swal.fire({
                    title: "Image Size",
                    text: "File size should not be greater than 500kb",
                    icon: "error",
                    showConfirmButton: true,
                    timer: 20000,
                  });
                  break;
                default:
                  Swal.fire({
                    title: "Image Size",
                    text: "Something went wrong.Please try again.",
                    icon: "error",
                    showConfirmButton: true,
                    timer: 20000,
                  });
                  break;
              }
            }
          });
        } else {
          Swal.fire({
            title: "Image Size",
            text: "File size should not be greater than 500kb",
            icon: "error",
            showConfirmButton: true,
            timer: 20000,
          });
          this.imageSrc = "";
          this.isIconValid = false;
        }
      } else {
        let companyDetails = {
          surveyEngineCompanyId: this.companyId,
          surveyMasterQuestionnaireId: this.selectedSurveyTemplate,
          companySettings: {
            sendResponseRateEmail: this.responseRateEmail,
            sendSasEmail: this.managerRegistartionAndSas,
          },
          companyName: this.companyName,
          sector: selectedSector,
          industryGroup: selectedIndustryGroup,
          industry: selectedIndustry,
          subIndustry: selectedSubIndustry,
          address: {
            line1: this.line1,
            line2: this.line2,
            pinCode: this.pinCode,
            city: this.city,
            state: this.state,
            country: this.country,
          },
          websiteUrl: this.websiteUrl,
          accessUrl: this.accessUrl,
          keyPerson: this.keyPersonArray,
          contactDetails: {
            hrName: this.hrName,
            hrEmail: this.hrEmail,
          },
          package: {
            basic: this.basic,
            special: this.special,
            premium: this.premium,
            custom: this.custom,
            invoice: this.invoice,
            createdAt: new Date(),
          },
          companyType: this.companyType,
          totalBasic: this.basic,
          totalPremium: this.premium,
          totalSpecial: this.special,
          totalCustom: this.custom,
          selectedFeatures: this.selectedFeatures,
          gmcCriteria: this.gmcCriteria,
          allDataPresentForGroupsAndStatements:
            this.allDataPresentForGroupsAndStatements,
          dataForGroupsAndStatements: {
            groups: this.groups,
            requiredData: this.selectedItems,
          },
        };
        if (this.isRealTimeFeedback) {
          companyDetails["isRealTimeFeedback"] = true;
          companyDetails["surveyTemplateId"] = this.selectedSurveyTemplate;
        }
        if (this.isLeadership) {
          companyDetails["isLeadership"] = true;
        }
        if (this.isGMC) {
          companyDetails["isGMC"] = true;
        }
        // ZohoScript Variable
        companyDetails["isZohoScript"] = this.showZoho;

        this.adminService.addCompanies(companyDetails).subscribe((response) => {
          this.addCorporateCompany = response;

          if (this.addCorporateCompany.status) {
            this.loading = false;
            Swal.fire({
              title: "Company successfully added",
              text: this.addCorporateCompany.message,
              icon: "success",
              showConfirmButton: false,
              timer: 3500,
            });
            this.router.navigate(["/admin/admin-dashboard/companies"]);
          } else {
            this.loading = false;
            Swal.fire({
              title: "Add Company",
              text: this.addCorporateCompany.message,
              icon: "error",
              showConfirmButton: false,
              timer: 3500,
            });
          }
        });
      }
    }

    //upload icon
    else {
      Swal.fire({
        title: "Add Company",
        text: "Please choose package",
        icon: "error",
        showConfirmButton: false,
        timer: 3500,
      });
    }
  }

  onSubmit(formData) {}
  validate() {
    //Validating Website..
    if (this.websiteUrl.length <= 0) {
      this.websiteUrlStatus = "";
    } else {
      if (this.validateWebsite(this.websiteUrl)) {
        this.isWebsiteValid = true;
        this.websiteUrlStatus = "";
        this.adminService
          .checkWebsite(this.websiteUrl)
          .subscribe((response: any) => {
            this.isWebsiteValid = true;
            this.websiteUrlStatus = "";
            if (response.status) {
              // console.log("Webiste not exists");
            } else {
              this.isWebsiteValid = false;
              this.websiteUrlStatus = response.message;
            }
          });
      } else {
        this.isWebsiteValid = false;
        this.websiteUrlStatus = "Invalid  web address";
      }
    }
  }

  // check whether webiste already exist or not
  validateWebsite(websiteUrl) {
    var re =
      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;
    return re.test(websiteUrl);
  }

  // get company name from other component
  getCompanyOutputValFromChild(selected: string) {
    if (selected) {
      this.companyName = selected;
    }
  }

  getIndustries() {
    this.adminService.getIndustries().subscribe((res) => {
      if (res.status) {
        res.data.forEach((item) => {
          // Check if the sector is not already in the uniqueSectors array
          if (
            !this.uniqueSectors.find(
              (uniqueItem) => uniqueItem.sector_id === item.sector_id
            )
          ) {
            this.uniqueSectors.push({
              sector_id: item.sector_id,
              sectors: item.sectors,
            });
          }

          // Check if the industry group is not already in the uniqueIndustryGroups array
          if (
            !this.uniqueIndustryGroups.find(
              (uniqueItem) =>
                uniqueItem.industry_groups_id === item.industry_groups_id
            )
          ) {
            this.uniqueIndustryGroups.push({
              industry_groups_id: item.industry_groups_id,
              industry_groups: item.industry_groups,
              industry_groups_foreign_id: item.industry_groups_foreign_id,
            });
          }

          // Check if the industry is not already in the uniqueIndustries array
          if (
            !this.uniqueIndustries.find(
              (uniqueItem) => uniqueItem.industries_id === item.industries_id
            )
          ) {
            this.uniqueIndustries.push({
              industries_id: item.industries_id,
              industries: item.industries,
              industries_foreign_id: item.industries_foreign_id,
            });
          }

          // Check if the sub-industry is not already in the uniqueSubIndustries array
          if (
            !this.uniqueSubIndustries.find(
              (uniqueItem) =>
                uniqueItem.sub_industries_id === item.sub_industries_id
            )
          ) {
            this.uniqueSubIndustries.push({
              sub_industries_id: item.sub_industries_id,
              sub_industries: item.sub_industries,
              sub_industries_foreign_id: item.sub_industries_foreign_id,
            });
          }
        });
      }
    });
  }

  filterData(type: string, id: number) {
    switch (type) {
      case "sectors":
        const selected = this.uniqueSectors.find(
          (industryType) => industryType.sector_id == id
        );
        this.industryGroups = this.uniqueIndustryGroups.filter(
          (item) => item.industry_groups_foreign_id == id
        );
        break;
      case "industryGroups":
        this.industries = this.uniqueIndustries.filter(
          (item) => item.industries_foreign_id == id
        );
        break;
      case "industries":
        this.subIndustries = this.uniqueSubIndustries.filter(
          (item) => item.sub_industries_foreign_id == id
        );
        break;
    }
  }
  // get company se id from other component
  getCompanyOutputIdFromChild(id: any) {
    this.companyStatus = "";
    this.existingCompany = false;
    if (id != null) {
      this.surveyEngineService
        .getSingleCompany(id)
        .subscribe((response: any) => {
          this.companyId = id == 0 ? 1 : id;
          if (response.status) {
            this.findSECompayId = true;
            this.existingCompany = response?.mongoFlag;
            this.companyStatus = this.existingCompany
              ? "Company Already Exist"
              : "";
            if (this.existingCompany) {
              this.industryType = response.data.industry;
              this.companyData = {
                name: response.data.name,
                industry: response.data.industry,
                hq: response.data.hq,
                country: response.data.country,
                number_of_managers: response.data.number_of_managers,
                number_of_employees: response.data.number_of_employees,
                client_type: response.data.client_type,
              };
            }
          }
        });
    }
  }

  // to check whether company already added or not
  checkCompany() {
    this.isCompanyValid = true;
    this.companyStatus = "";
    this.adminService
      .checkCompany(this.companyId)
      .subscribe((response: any) => {
        this.isCompanyValid = true;
        this.companyStatus = "";
        if (response.status) {
          // console.log("Webiste not exists");
        } else {
          this.isCompanyValid = false;
          this.companyStatus = response.message;
          // console.log(response.message);
        }
      });
  }

  showIcon(event: any) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      this.iconSize = event.target.files[0].size;
      if (this.iconSize > 524288) {
        this.iconSizeStatus = "Icon size should be less than 512kb.";
        this.isIconValid = false;
      } else {
        this.isIconValid = true;
      }
      // console.log(this.iconSize)
      const reader = new FileReader();
      reader.onload = (e) => (this.imageSrc = reader.result);
      this.fileName = event.target.files[0].name;
      // console.log(this.fileName);
      reader.readAsDataURL(file);
    }
  }

  // radioValue(event: any) {
  //   this.gpmsValue = event.target.value;
  // }

  validateEmailMain() {
    //Validating Email..
    if (validateEmail(this.hrEmail)) {
      this.adminService.checkEmail(this.hrEmail).subscribe((response: any) => {
        if (response.status) {
          this.isEmailValid = true;
          this.emailStatus = "";
        } else {
          this.isEmailValid = false;
          this.emailStatus = response.message;
        }
      });
    } else {
      this.isEmailValid = false;
      this.emailStatus = "InValid Email ID";
    }
  }

  onSelectCompanyType(event: any) {
    this.companyType = event.target.value;
    if (event.target.value === "GPMS") {
      this.gpmsValue = true;
    } else {
      this.gpmsValue = false;
    }
  }
  addAnotherKeyPerson() {
    const helpEmailCount = this.keyPersonArray.filter(
      (user) => user.userType === "helpEmail"
    );

    if (helpEmailCount.length >= 3) {
      this.errorText = "Cannot add more than 3 Help Email Person";
    } else {
      this.keyPersonArray.push({
        name: this.kpName,
        email: this.kpEmail,
        mobile: this.kpPhone,
        userType: this.kpUserType,
      });
    }

    this.kpName = "";
    this.kpEmail = "";
    this.kpPhone = "";
    this.kpUserType = "";
    this.errorText = "";
  }

  onKeyPersonEmailChange(e) {
    // console.log(e.target.value)
    if (e.target.value) {
      this.isContactPersonDetails = true;
    }
  }
  removePerson(email) {
    this.keyPersonArray = this.keyPersonArray.filter((id) => id.email != email);
    this.kpName = "";
    this.kpUserType = "";
    this.kpEmail = "";
    this.kpPhone = "";
    this.errorText = "";
  }
  patchKeyPerson(data) {
    this.keyPersonArray = this.keyPersonArray.filter(
      (id) => id.email != data.email
    );
    this.kpName = data.name;
    this.kpUserType = data.userType;
    this.kpEmail = data.email;
    this.kpPhone = data.mobile;
    this.errorText = "";
  }
  checkCheckBoxvalue(event) {
    this.isRealTimeFeedback = event.target.checked;
    this.getMasterQuestionair();
  }
  checkLeadershipBoxvalue(event) {
    this.isLeadership = event.target.checked;
  }
  checkGMCBoxvalue(event) {
    console.log(event.target.checked);
    this.isGMC = event.target.checked;
  }
  getMasterQuestionair() {
    this.adminService.getMasterQuestionnaires().subscribe({
      next: (res: any) => {
        if (res.status) {
          this.surveyTemplate = res.data;
        }
      },
    });
  }
  onChangeSurveyTemplate(value: number) {
    this.selectedSurveyTemplate = value;
  }

  surveyGroupStatus(event) {
    this.showGroupsAndStatements = event.checked;
  }
}
