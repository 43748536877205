<!-- <app-loader *ngIf="loading"></app-loader> -->
<a
  (click)="openSendMailModal(sendModal)"
  style="
    display: block;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
    text-decoration: none;
  "
  >Send Mails
  <span
    ><i
      class="fa fa-info-circle info"
      aria-hidden="true"
      matTooltip="Send emails to multiple/all managers"
      matTooltipClass="custom-tooltip"
    ></i></span
></a>
<ng-template #sendModal>
  <div class="modal-header">
    <h4 style="font-size: 22px" class="modal-title pull-left">
      Send Emails to your selected managers.
    </h4>
    <button
      type="button "
      class="close pull-right"
      aria-label="Close "
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div
    *ngIf="selectedManagers.length > 0; else showAddManagerMessage"
    class="modal-body"
  >
    <div class="row">
      <div class="col-md-6 table-container">
        To ({{ selectedManagers.length }})
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let manager of selectedManagers">
              <td>{{ manager.name }}</td>
              <td>{{ manager.email }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-6">
        <div class="email-preview-container">
          <div class="email-container">
            <i class="fa fa-file-image-o" aria-hidden="true"></i>
            <h3>Email Preview</h3>
          </div>
          <form>
            <div class="form-group">
              <p class="desc">* Required Field</p>
              <div class="form-group">
                <label for="template"
                  >Choose Recipent <span style="color: salmon">*</span></label
                >
                <select name="to" id="to" (change)="changeFormInput($event)">
                  <option value="" selected disabled>
                    Please select recipent
                  </option>
                  <option value="manager">Participants</option>
                  <option value="employee">Feedback Givers</option>
                </select>
              </div>

              <label for="template"
                >Choose Email Format <span style="color: salmon">*</span></label
              >
              <select
                name="emailFormat"
                id="emailFormat"
                (change)="changeEmailFormat($event)"
              >
                <option value="" selected disabled>
                  Please select Email Format
                </option>

                <option
                  *ngFor="let email of filteredEmailFormats"
                  value="{{ email.name }}"
                >
                  {{ email.title }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="cc">Cc <span style="color: salmon"></span></label>
              <input
                type="text"
                [(ngModel)]="formData.cc"
                name="cc"
                id="cc"
                placeholder="Enter email address"
              />
            </div>

            <div class="extra-container" *ngIf="formData.emailFormat != ''">
              <div class="form-group">
                <label for="subject"
                  >Subject <span style="color: salmon">*</span></label
                >
                <input
                  type="text"
                  name="subject"
                  id="subject"
                  [(ngModel)]="formData.subject"
                  placeholder="Enter Your Subject"
                />
              </div>
              <div class="placeholder">
                <p>{{ formData.placeholder }}</p>
              </div>
              <div class="form-group">
                <label for="message"
                  >Message <span style="color: salmon">*</span></label
                >
                <!-- <angular-editor
                  name="message"
                  [config]="editorConfig"
                  [placeholder]="'Enter text here...'"
                  [(ngModel)]="formData.htmlContent"
                ></angular-editor> -->
                <!-- <div class="editor">
                  <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
                  </ngx-editor-menu>
                  <ngx-editor
                    [ngModelOptions]="{ standalone: true }"
                    [editor]="editor"
                    [placeholder]="'Enter text here...'"
                    [(ngModel)]="formData.htmlContent"
                  >
                  </ngx-editor>
                </div> -->
                <app-email-editor
                  [(content)]="formData.htmlContent"
                ></app-email-editor>
                <!-- (contentChange)="onContentChange($event)" -->
              </div>

              <div class="form-group">
                <button class="btn btn-primary" (click)="submit()">Send</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #showAddManagerMessage>
  <p class="title">Select at least 1 manager to send email</p>
</ng-template>
