<div class="container-fluid">
  <!-- //<app-loader *ngIf="loading"></app-loader> -->
  <div class="row">
    <div>
      <div class="col-md-12">
        <div class="profile-info">
          <form (ngSubmit)="onSubmit(f)" #f="ngForm">
            <div class="col-md-6 col-sm-12 col-xs-12 mobileForm">
              <div class="col-md-12 form-card mobileForm">
                <div class="form-holder col-md-12">
                  <app-company-input
                    (outPutToParent)="getCompanyOutputValFromChild($event)"
                    (companyIdOutPutToParent)="
                      getCompanyOutputIdFromChild($event)
                    "
                  >
                  </app-company-input>

                  <p class="warning" style="padding-left: 3%">
                    {{ companyStatus }}
                  </p>
                  <input
                    type="hidden"
                    name="company"
                    [ngModel]="companyName"
                    #company="ngModel"
                  />
                  <!-- <div class="form-group col-md-12 pb-10">
                      <label for="title">Email<span class="asterick">*</span></label>
                      <input
                      type="email"
                      name="email"
                      class="form-control custom-form-control"
                      placeholder="Enter Company email address"
                      [(ngModel)]="email"
                      [disabled]="emailDisabled"
                      (change)="validate()"
                      required
                      #adminEmail = "ngModel">
                      <p class="warning" *ngIf="!adminEmail.valid && adminEmail.touched">
                        Please Enter your email
                      </p>
                      <p class="warning">{{emailStatus}}</p>
                    </div> -->
                  <!-- <div class="form-group col-md-12 pb-10">
                      <label for="title">Contact Number<span class="asterick">*</span></label>
                      <input
                      type="text"
                      name="mobile"
                      class="form-control custom-form-control"
                      placeholder="Enter Company contact number"
                      [(ngModel)]="mobile"
                      maxlength="10"
                      size="10"
                      required
                      #mobileNumber = "ngModel">
                      <p class="warning" *ngIf="!mobileNumber.valid && mobileNumber.touched">
                        Please Enter your contact number
                      </p>
                    </div> -->
                  <div class="form-group col-md-12 pb-10">
                    <label for="email"
                      >Sector<span class="asterick">*</span></label
                    >
                    <select
                      class="form-control custom-form-control"
                      ngModel
                      required
                      #selectedSectorModel="ngModel"
                      [(ngModel)]="selectedSector"
                      name="industryType"
                      (change)="
                        filterData('sectors', $any($event.target).value)
                      "
                    >
                      <option value selected>select</option>
                      <option
                        *ngFor="let industryType of uniqueSectors"
                        [value]="industryType.sector_id"
                      >
                        {{ industryType.sectors }}
                      </option>
                    </select>
                    <p
                      class="warning"
                      *ngIf="
                        !selectedSectorModel.valid &&
                        selectedSectorModel.touched
                      "
                    >
                      Please Select Your sector type
                    </p>
                  </div>
                  <div class="form-group col-md-12 pb-10">
                    <label for="email"
                      >Industry Group<span class="asterick">*</span></label
                    >
                    <select
                      class="form-control custom-form-control"
                      ngModel
                      required
                      #selectedIndustryGroupModel="ngModel"
                      [(ngModel)]="selectedIndustryGroup"
                      name="industryType"
                      (change)="
                        filterData('industryGroups', $any($event.target).value)
                      "
                    >
                      <option value selected>select</option>
                      <option
                        *ngFor="let industryType of industryGroups"
                        [value]="industryType.industry_groups_id"
                      >
                        {{ industryType.industry_groups }}
                      </option>
                    </select>
                    <p
                      class="warning"
                      *ngIf="
                        !selectedIndustryGroupModel.valid &&
                        selectedIndustryGroupModel.touched
                      "
                    >
                      Please Select Your Industry Group type
                    </p>
                  </div>
                  <div class="form-group col-md-12 pb-10">
                    <label for="email"
                      >Industry<span class="asterick">*</span></label
                    >
                    <select
                      class="form-control custom-form-control"
                      ngModel
                      required
                      #selectedIndustryModel="ngModel"
                      [(ngModel)]="selectedIndustry"
                      name="industryType"
                      (change)="
                        filterData('industries', $any($event.target).value)
                      "
                    >
                      <option value selected>select</option>
                      <option
                        *ngFor="let industryType of industries"
                        [value]="industryType.industries_id"
                      >
                        {{ industryType.industries }}
                      </option>
                    </select>
                    <p
                      class="warning"
                      *ngIf="
                        !selectedIndustryModel.valid &&
                        selectedIndustryModel.touched
                      "
                    >
                      Please Select Your Industry type
                    </p>
                  </div>
                  <div class="form-group col-md-12 pb-10">
                    <label for="email"
                      >Sub Industry<span class="asterick">*</span></label
                    >
                    <select
                      class="form-control custom-form-control"
                      ngModel
                      required
                      #selectedSubIndustryModel="ngModel"
                      [(ngModel)]="selectedSubIndustry"
                      name="industryType"
                    >
                      <option value selected>select</option>
                      <option
                        *ngFor="let industryType of subIndustries"
                        [value]="industryType.sub_industries_id"
                      >
                        {{ industryType.sub_industries }}
                      </option>
                    </select>
                    <p
                      class="warning"
                      *ngIf="
                        !selectedSubIndustryModel.valid &&
                        selectedSubIndustryModel.touched
                      "
                    >
                      Please Select Your Sub Industry type
                    </p>
                  </div>
                  <!-- <div class="form-group col-md-12 pb-10">
                    <label for="icon">Company Icon</label>
                    <input type="file" class="btn-primary" id="icon" (change)="showIcon($event)"
                      accept="image/x-png,image/gif,image/jpeg" name="icon">
                      <p class="warning" *ngIf="!isIconValid">
                        {{ iconSizeStatus}}
                      </p>
                    <img class="mt-10" *ngIf="imageSrc" [src]="imageSrc" alt="Your Icon" width="50" height="50">
                  </div> -->
                  <div class="form-group col-md-12 pb-10 web">
                    <label for="icon">Company Icon</label>
                    <br />
                    <a
                      class="btn btn-primary add-icon"
                      (click)="iconInput.click()"
                      >Click Here to Add Icon</a
                    >
                    <input
                      type="file"
                      class="btn-primary"
                      id="icon"
                      (change)="showIcon($event)"
                      accept="image/x-png,image/gif,image/jpeg"
                      #iconInput
                      name="icon"
                    />
                    <p class="warning" *ngIf="!isIconValid">
                      {{ iconSizeStatus }}
                    </p>
                    <img
                      class="mt-10"
                      *ngIf="imageSrc"
                      [src]="imageSrc"
                      alt="Your Icon"
                      width="50"
                      height="50"
                    />
                    <!-- <p class="warning" *ngIf="!website.valid && website.touched">
                      Please Enter your website url
                    </p> -->
                    <!-- <p class="warning">{{ websiteUrlStatus }}</p> -->
                  </div>

                  <div class="form-group col-md-12 pb-10">
                    <label for="address">Address</label>
                    <input
                      type="text"
                      class="form-control custom-form-control mt-10"
                      placeholder="Address line 1"
                      [(ngModel)]="line1"
                      name="line1"
                    />
                    <input
                      type="text"
                      class="form-control custom-form-control country-size mt-10"
                      placeholder="Country"
                      [(ngModel)]="country"
                      name="country"
                    />
                    <input
                      type="text"
                      class="form-control custom-form-control city-size mt-10"
                      placeholder="City"
                      [(ngModel)]="city"
                      name="city"
                    />
                    <input
                      type="text"
                      class="form-control custom-form-control pincode-size mt-10"
                      maxlength="7"
                      size="7"
                      placeholder="Pin Code"
                      [(ngModel)]="pinCode"
                      name="pincode"
                    />
                  </div>

                  <div class="form-group col-md-12 pb-10">
                    <label for="title"
                      >Website<span class="asterick">*</span></label
                    >
                    <input
                      type="text"
                      name="website"
                      class="form-control custom-form-control"
                      placeholder="Enter Website URL"
                      (change)="validate()"
                      [(ngModel)]="websiteUrl"
                      required
                      #website="ngModel"
                    />
                    <p
                      class="warning"
                      *ngIf="!website.valid && website.touched"
                    >
                      Please Enter your website url
                    </p>
                    <p class="warning" *ngIf="websiteUrl != ''">
                      {{ websiteUrlStatus }}
                    </p>
                  </div>
                  <div class="form-group col-md-12 pb-10">
                    <label for="title">Access Url</label>
                    <input
                      type="text"
                      name="accessurl"
                      class="form-control custom-form-control"
                      placeholder="Enter access URL"
                      (change)="validate()"
                      [(ngModel)]="accessUrl"
                      #accessurl="ngModel"
                    />
                    <!-- <p
                      class="warning"
                      *ngIf="!accessurl.valid && accessurl.touched"
                    >
                      Please Enter your access url
                    </p> -->
                  </div>

                  <!-- <div class="criteria">
                    <h5>GMC Criteria Setting:</h5>
                    <div class="form-group col-md-12 pb-10">
                      <label for="title">Minimum Action Plan</label>
                      <input
                        type="number"
                        name="actionPlanMin"
                        class="form-control custom-form-control"
                        placeholder="Enter Minimum Action Plan"
                        [(ngModel)]="gmcCriteria.actionPlanMin"
                      />
                    </div>
                    <div class="form-group col-md-12 pb-10">
                      <label for="title">Minimum Implementation Updates</label>
                      <input
                        type="number"
                        name="implementationMin"
                        class="form-control custom-form-control"
                        placeholder="Enter Minimum Implementation Updates"
                        [(ngModel)]="gmcCriteria.implementationMin"
                      />
                    </div>
                    <div class="form-group col-md-12 pb-10">
                      <label for="title"
                        >Minimum Learning and Development</label
                      >
                      <input
                        type="number"
                        name="learningDevelopmentMin"
                        class="form-control custom-form-control"
                        placeholder="Enter Minimum Learning and Development Plan"
                        [(ngModel)]="gmcCriteria.learningDevelopmentMin"
                      />
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-12 col-xs-12 mobileForm">
              <div
                class="col-md-12 form-card"
                mobileForm
                style="padding: 6px !important"
              >
                <div class="form-holder col-md-12">
                  <div class="rtf">
                    <h5>Real time feedback Setting:</h5>
                    <div class="form-group col-md-12 pb-10 d-flex">
                      <div class="rtf-flex">
                        <input
                          type="checkbox"
                          (change)="checkCheckBoxvalue($event)"
                        />
                        <label style="margin-right: 40px" for="title"
                          >Real time feedback</label
                        >

                        <input
                          type="checkbox"
                          (change)="checkLeadershipBoxvalue($event)"
                        />
                        <label for="title"
                          >Leadership Analytics Dashboard</label
                        >
                        <input
                          type="checkbox"
                          (change)="checkGMCBoxvalue($event)"
                        />
                        <label for="title">GMC Criteria</label>
                      </div>
                    </div>
                    <div
                      class="form-group col-md-12 pb-10"
                      *ngIf="isRealTimeFeedback"
                    >
                      <label for="title">Select Survey template</label>
                      <select
                        [(ngModel)]="selectedSurveyTemplate"
                        (change)="
                          onChangeSurveyTemplate($any($event.target).value)
                        "
                        [ngModelOptions]="{ standalone: true }"
                      >
                        class="form-control">
                        <option selected disabled>Select</option>
                        <option
                          *ngFor="let template of surveyTemplate"
                          value="{{ template.id }}"
                        >
                          {{ template.questionnaireName }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group col-md-12 pb-10">
                    <label for="title">Select Survey template</label>
                    <select
                      class="form-control custom-form-control"
                      [(ngModel)]="selectedSurveyTemplate"
                      [ngModelOptions]="{ standalone: true }"
                      (change)="resetGroupAndStatementsModal()"
                    >
                      class="form-control">
                      <option selected disabled>Select</option>
                      <option
                        *ngFor="let template of surveyTemplate"
                        value="{{ template.id }}"
                      >
                        {{ template.questionnaireName }} {{ template.id }}
                      </option>
                    </select>
                  </div>
                  <div
                    class="form-group col-md-12 pb-10"
                    *ngIf="selectedSurveyTemplate"
                  >
                    <label for="surveyGroup"
                      >Assign survey statements to new groups
                    </label>
                    <mat-slide-toggle
                      id="surveyGroup"
                      style="margin-left: 10px"
                      [checked]="showGroupsAndStatements"
                      (change)="surveyGroupStatus($event)"
                    ></mat-slide-toggle>
                  </div>
                  <div
                    *ngIf="showGroupsAndStatements"
                    class="form-group col-md-12 pb-10"
                  >
                    <div
                      class="AddMoreBtn"
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      "
                    >
                      <button
                        class="btn btn-danger add-icon"
                        (click)="showGroupAndAssignMentPopup(popupGroup)"
                      >
                        Create New Groups and Order
                      </button>
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        style="margin-left: 10px"
                        *ngIf="groupsSaved"
                      >
                        <path
                          class="text-success"
                          d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1.41 15.41L6.29 12.3a1 1 0 0 1 0-1.4 1 1 0 0 1 1.42 0L11 14.17l8.3-8.29a1 1 0 0 1 1.41 1.42l-9 9a1 1 0 0 1-1.42 0z"
                        />
                      </svg>
                    </div>

                    <div
                      class="AddMoreBtn"
                      *ngIf="groupsSaved"
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      "
                    >
                      <button
                        class="btn btn-danger add-icon"
                        (click)="showGroupAndAssignMentPopup(assignStatements)"
                      >
                        Assign Statements to Groups
                      </button>
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        style="margin-left: 10px"
                        *ngIf="allDataPresentForGroupsAndStatements"
                      >
                        <path
                          class="text-success"
                          d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1.41 15.41L6.29 12.3a1 1 0 0 1 0-1.4 1 1 0 0 1 1.42 0L11 14.17l8.3-8.29a1 1 0 0 1 1.41 1.42l-9 9a1 1 0 0 1-1.42 0z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div class="form-group col-md-12 pb-10">
                    <div *ngFor="let keyPerson of keyPersonArray">
                      <div *ngIf="keyPersonArray.length > 0">
                        <h5 class="rounded email">
                          Contact Person: {{ keyPerson.email
                          }}<i
                            class="fa fa-edit"
                            (click)="patchKeyPerson(keyPerson)"
                          ></i>
                          <span
                            class="ml-2"
                            (click)="removePerson(keyPerson.email)"
                            class="closeBtn"
                          >
                            X</span
                          >
                        </h5>
                      </div>
                    </div>
                    <label for="email"
                      >Contact Person Name<span class="asterick">*</span></label
                    >
                    <input
                      type="text"
                      name="kpName"
                      class="form-control custom-form-control mt-10"
                      placeholder="Person name"
                      [(ngModel)]="kpName"
                    />
                    <input
                      type="email"
                      class="form-control custom-form-control email-size mt-10"
                      placeholder="Enter email"
                      name="kpEmail"
                      [(ngModel)]="kpEmail"
                      (change)="onKeyPersonEmailChange($event)"
                    />
                    <input
                      type="number"
                      class="form-control custom-form-control cno-size mt-10"
                      placeholder="Contact No."
                      maxlength="10"
                      size="10"
                      name="kpPhone"
                      [(ngModel)]="kpPhone"
                    />
                  </div>

                  <div class="form-group col-md-12 pb-10 typeSelect">
                    <label for="kpUserType">Select User Type:</label>
                    <select
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="kpUserType"
                      class="form-control"
                      id="kpUserType"
                    >
                      <option selected disabled>Select</option>
                      <option value="companySpoc">Company Spoc</option>
                      <option value="gmiSpoc">GMI Spoc</option>
                      <option value="gmiHead">GMI Head</option>
                      <option value="helpEmail">Help Email</option>
                    </select>
                  </div>
                  <div class="AddMoreBtn">
                    <button
                      class="btn btn-danger add-icon"
                      (click)="addAnotherKeyPerson()"
                    >
                      Add More Contact Details
                    </button>
                    <p class="warning" *ngIf="errorText.length > 0">
                      {{ errorText }}
                    </p>
                  </div>
                  <!-- <div class="form-group col-md-12">
                    <label>GPMS Company <span class="asterick">*</span></label>
                    <div class="form-group col-md-12">

                      <label for="gpms" class="gpmsLabel">Yes</label>
                      <input type="radio" name="gpms" id="gpms" value="Yes" (click)="radioValue($event)">
                      <label for="gpms" class="gpmsLabel">No</label>
                      <input type="radio" name="gpms" id="gpms" value="No" (click)="radioValue($event)">


                    </div>
                    <div class="form-group col-md-12 pb-10" *ngIf="gpmsValue=='Yes'">
                      <label for="email">HR Details</label>
                      <input type="text" name="hrName" class="form-control custom-form-control mt-10"
                        placeholder="HR Name" [(ngModel)]="hrName" />
                      <input type="email" class="form-control custom-form-control  mt-10" placeholder="HR Email"
                        name="hrEmail" [(ngModel)]="hrEmail" />

                    </div>
                  </div> -->

                  <!-- <div class="form-group col-md-12 pb-10 typeSelect">
                    <label for="setting">Select Launch Setting:</label>
                    <select
                      (change)="companySetting($event)"
                      class="form-control"
                      id="etting"
                    >
                      <option
                        *ngFor="let item of mailTemplateArray"
                        [value]="item.value"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div> -->
                  <div class="form-group col-md-12 pb-10 typeSelect">
                    <label for="setting">Select Launch Setting:</label>
                    <div class="responseRateEmail flex flex-col justify-center">
                      <p>
                        <input
                          name="responseRate"
                          checked="responseRate"
                          value="responseRate"
                          type="checkbox"
                          [(ngModel)]="responseRate"
                          (change)="companySetting($event)"
                        />
                        Send Response Rate Email
                      </p>
                      <br />
                      <!-- *ngIf="gpmsValue" -->
                      <p>
                        <input
                          name="sasReport"
                          checked="sasReport"
                          value="sasReport"
                          type="checkbox"
                          [(ngModel)]="sasReport"
                          (change)="companySetting($event)"
                        />
                        Send SAS Email
                      </p>
                      <p>
                        <input
                          name="showZoho"
                          checked="showZoho"
                          type="checkbox"
                          [(ngModel)]="showZoho"
                        />
                        Show zoho chat bot
                      </p>
                    </div>
                  </div>
                  <div class="form-group col-md-12">
                    <label
                      >Select Company type
                      <span class="asterick">*</span></label
                    >
                    <select
                      class="form-control custom-form-control"
                      (change)="onSelectCompanyType($event)"
                    >
                      <!-- <option value="" selected hidden>select</option> -->

                      <option
                        *ngFor="let companyType of companyTypes"
                        [value]="companyType.type"
                      >
                        {{ companyType.name }}
                      </option>
                    </select>
                    <div class="form-group col-md-12" *ngIf="gpmsValue">
                      <label for="hr-details"
                        >HR Details<span class="asterick">*</span></label
                      >
                      <div id="hr-details" class="mt-10">
                        <input
                          type="text"
                          name="hrName"
                          class="form-control custom-form-control"
                          placeholder="HR Name"
                          [(ngModel)]="hrName"
                          required
                          #onHRName="ngModel"
                        />
                        <p
                          class="warning"
                          *ngIf="!onHRName.valid && onHRName.touched"
                        >
                          Please Enter HR Name
                        </p>

                        <input
                          type="email"
                          class="form-control custom-form-control mt-10"
                          placeholder="HR Email"
                          name="hrEmail"
                          (change)="validateEmailMain()"
                          [(ngModel)]="hrEmail"
                          required
                          #onHREmail="ngModel"
                        />
                        <p
                          class="warning"
                          *ngIf="!onHREmail.valid && onHREmail.touched"
                        >
                          Please Enter HR Email
                        </p>
                        <p class="warning">{{ emailStatus }}</p>
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-md-12 pb-10">
                    <label>Package <span class="asterick">*</span></label>
                    <br />
                    <label for="email">Invoice</label>
                    <input
                      type="text"
                      name="invoice"
                      class="form-control custom-form-control mt-10"
                      placeholder="Invoice number"
                      [(ngModel)]="invoice"
                    />
                  </div>
                  <div
                    class="form-group col-md-12 pb-10"
                    style="width: 33%; float: left"
                  >
                    <label for="email">Profiling</label>
                    <input
                      type="number"
                      name="basic"
                      style="text-align: center"
                      class="form-control custom-form-control mt-10"
                      placeholder="Number of premium special packages"
                      [(ngModel)]="basic"
                    />
                  </div>
                  <div class="form-group col-md-12 pb-10" style="width: 33%">
                    <label for="email">Certification</label>
                    <input
                      type="number"
                      name="premium"
                      style="text-align: center"
                      class="form-control custom-form-control mt-10"
                      placeholder="Number of premium packages"
                      [(ngModel)]="premium"
                    />
                  </div>
                  <div
                    class="form-group col-md-12 pb-10"
                    style="width: 33%; float: right"
                  >
                    <label for="email">Special</label>
                    <input
                      type="number"
                      name="special"
                      style="text-align: center"
                      class="form-control custom-form-control mt-10"
                      placeholder="Number of premium special packages"
                      [(ngModel)]="special"
                    />
                  </div>
                  <p class="small-text">Or</p>
                  <label>Assign Custom Package</label>
                  <input
                    type="number"
                    name="custom"
                    style="text-align: center"
                    class="form-control custom-form-control mt-10"
                    placeholder="Number of custom packages"
                    [(ngModel)]="custom"
                  />
                  <ngx-select-dropdown
                    style="margin: 20px"
                    (change)="selectFeature($event)"
                    [multiple]="true"
                    [config]="config"
                    [options]="features"
                  ></ngx-select-dropdown>
                  <div class="form-group col-md-12 pb-10" *ngIf="!imageSrc">
                    <button
                      type="submit"
                      class="btn btn-primary addmantra-btn"
                      (click)="addCompany()"
                      [disabled]="
                        !f.valid ||
                        !isWebsiteValid ||
                        (gpmsValue && !isEmailValid) ||
                        !isContactPersonDetails ||
                        existingCompany
                      "
                    >
                      Add
                    </button>
                  </div>
                  <div class="form-group col-md-12 pb-10" *ngIf="imageSrc">
                    <button
                      type="submit"
                      class="btn btn-primary addmantra-btn"
                      (click)="onAddCompany()"
                      [disabled]="
                        !f.valid ||
                        !isWebsiteValid ||
                        !isIconValid ||
                        (gpmsValue && !isEmailValid) ||
                        !isContactPersonDetails ||
                        existingCompany
                      "
                    >
                      Add
                    </button>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</div>

<ng-template #popupGroup>
  <table class="table">
    <thead>
      <tr>
        <th>Group Name</th>
        <th>Group Order</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let group of groups; let i = index">
        <td>
          <input
            type="text"
            placeholder="New Group Name"
            [(ngModel)]="group.groupName"
            class="form-control"
          />
        </td>
        <td>
          <input
            type="number"
            placeholder="New Group Order"
            [(ngModel)]="group.groupOrder"
            class="form-control"
          />
        </td>
        <td>
          <button
            type="button"
            class="btn btn-danger btn-sm"
            (click)="removeGroup(i)"
          >
            Remove
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="buttons">
    <button
      type="button"
      class="btn btn-primary add-icon"
      (click)="addGroup('', 1)"
    >
      Add More+
    </button>

    <button
      type="button"
      class="btn btn-primary add-icon"
      [disabled]="isSaveButtonDisabled() || groups.length == 0"
      (click)="saveGroupsInDb()"
    >
      Save All
    </button>
  </div>
</ng-template>

<ng-template #assignStatements>
  <!-- <div class="form-group">
    <label for="groupName">Select Group Name</label>
    <select class="form-control" id="groupName" [(ngModel)]="selectedGroupName">
      <option *ngFor="let item of groups" [value]="item.groupName">{{ item.groupName }}</option>
    </select>
  </div>


  <div class="form-group">
    <label for="statements">Select Statements</label>
    <select class="form-control" id="statements" multiple (click)="toggleItem($event.target.value,selectedGroupName)">
      <option *ngFor="let question of questionsFromDB" [value]="question.questionId">{{ question.questionText }}</option>
    </select>
  </div>
  
  <div *ngIf="selectedItems.length > 0">
    <h4>Selected Statements:</h4>
    <ul>
      <li *ngFor="let selectedItem of selectedItems">
        Group: {{ selectedItem.groupName }}, Question: {{ getQuestionTextById(selectedItem.question.questionId) }}
        <button (click)="toggleItem(selectedItem.questionId, selectedItem.groupName)">Remove</button>
      </li>
    </ul>
  </div>

  <div *ngIf="selectedItems.length > 0" class="save">
    <button class="btn btn-primary add-icon" (click)="saveAll()">Save All</button>
  </div> -->

  <div class="modal-body">
    <div class="form-group">
      <label for="groupName"><h4>Select Group Name</h4></label>
      <select
        class="form-control"
        id="groupName"
        [(ngModel)]="selectedGroupName"
      >
        <option *ngFor="let item of groups" [value]="item.groupName">
          {{ item.groupName }}
        </option>
      </select>
    </div>
    <div class="form-group" *ngIf="questionsCount - selectedItems.length != 0">
      <label for="statements"> <h4>Click on Statement to select</h4></label>
      <div class="table-container">
        <table class="table">
          <thead>
            <tr>
              <th>Question</th>
              <th>Theme</th>
              <th>Sub-Theme</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let question of availableQuestions"
              (click)="toggleItem(question.questionId, selectedGroupName)"
            >
              <td>{{ question.questionText }}</td>
              <td>{{ question.themeName }}</td>
              <td>{{ question.subTheme }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div *ngIf="selectedItems.length > 0" class="mt-4">
      <h4>Selected Statements:</h4>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Group Name</th>
            <th style="font-size: 16px">Statement/Subtheme Name</th>
            <th style="font-size: 16px">Question Text</th>
            <th style="width: 1%; white-space: nowrap">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let selectedItem of selectedItems">
            <td>{{ selectedItem.groupName }}</td>
            <td>
              {{ getQuestionSubthemeById(selectedItem.question.questionId) }}
            </td>
            <td>{{ getQuestionTextById(selectedItem.question.questionId) }}</td>
            <td>
              <button
                class="btn btn-danger btn-sm"
                (click)="
                  toggleItem(
                    selectedItem.question.questionId,
                    selectedItem.groupName
                  )
                "
              >
                Remove
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="modal-footer">
    <button
      *ngIf="selectedItems.length > 0"
      type="button"
      class="btn btn-primary add-icon"
      (click)="saveAll()"
    >
      Save All
    </button>
  </div>
</ng-template>
