<div>
  <mat-form-field>
    <mat-label>Years</mat-label>
    <mat-select [(ngModel)]="selectedYears" multiple>
      <mat-option *ngFor="let year of years" [value]="year">{{
        year
      }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Status</mat-label>
    <mat-select [(ngModel)]="selectedStatuses" multiple>
      <mat-option *ngFor="let status of surveyStatus" [value]="status">{{
        status
      }}</mat-option>
    </mat-select>
  </mat-form-field>

  <button class="btn get-data-btn" (click)="getB2CR2List()">Get Managers</button>


  <mat-form-field class="search">
    <mat-label>Search</mat-label>
    <input matInput placeholder="Search" [(ngModel)]="searchTerm" (keyup)="applyFilter()" />
  </mat-form-field>

  <div class="mat-elevation-z8">
    <table
      mat-table
      [dataSource]="managerFinalList"
      class="mat-elevation-z8 manager-table-container"
    >
      <ng-container matColumnDef="mgrName">
        <th mat-header-cell *matHeaderCellDef>Manager Name</th>
        <td mat-cell *matCellDef="let managerList">
          {{ managerList.managerName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="mgrEmail">
        <th mat-header-cell *matHeaderCellDef>Manager Email</th>
        <td mat-cell *matCellDef="let managerList">
          {{ managerList.managerEmail }}
        </td>
      </ng-container>

      <ng-container matColumnDef="year">
        <th mat-header-cell *matHeaderCellDef>R2 Year</th>
        <td mat-cell *matCellDef="let managerList">
          {{ managerList.year }}
        </td>
      </ng-container>

      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef>Start Date</th>
        <td mat-cell *matCellDef="let managerList">
          {{ managerList.startDate | date : "dd/MM/yyyy" : "UTC" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef>End Date</th>
        <td mat-cell *matCellDef="let managerList">
          {{ managerList.endDate | date : "dd/MM/yyyy" : "UTC" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let managerList">
          <button
            mat-button
            color="primary"
            (click)="openExtendSurvey(surveyTemplate, managerList)"
          >
            Select Date
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="b2cColumn"></tr>
      <tr mat-row *matRowDef="let row; columns: b2cColumn"></tr>
    </table>
  </div>
  <mat-paginator
    [length]="managerFinalList.data.length"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
  >
  </mat-paginator>

  <ng-template #surveyTemplate>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Review and Launch Survey</h4>
      <button
        type="button "
        class="close pull-right"
        aria-label="Close "
        (click)="modalRef.hide()"
      >
        <span aria-hidden="true ">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="form-container">
          <div class="managers-list-container">
            <div>
              <b class="title">
                {{
                  managerSurvey.endDate > currentDate.toISOString()
                    ? "Survey Is Ongoing!"
                    : "Survey Is Ended, Extend Survey!"
                }}
              </b>
              <br />
              <mat-form-field>
                <input
                  matInput
                  [(ngModel)]="updateEndDate"
                  [min]="minDate"
                  [matDatepicker]="picker"
                  placeholder="Choose new end date"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>

            <strong class="manager-list-detail">Managers Detail:</strong>
            <div class="manager-list">
              <p class="detail">
                <span>{{ managerSurvey.managerEmail }}</span>
              </p>
              <div class="dateContainer">
                <p class="">
                  Start Date :
                  {{ managerSurvey.startDate | date : "dd/MM/yyyy" : "UTC" }}
                </p>
                <p class="">
                  End Date :
                  {{ managerSurvey.endDate | date : "dd/MM/yyyy" : "UTC" }}
                </p>
              </div>
            </div>
          </div>
          <div class="button-container">
            <button
              type="submit"
              [disabled]="!updateEndDate"
              class="btn btn-success custom-btn"
              (click)="updateSurvey(managerSurvey.managerEmail,managerSurvey.year)"
            >
              Extend Survey
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
