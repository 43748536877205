import { Component, OnInit } from "@angular/core";
import Swal from "sweetalert2";
import { AdminServices } from "../admin.service";
import { Router } from "@angular/router";

import IdleTimer from "../../../app/Timer.js";
// import swal from "sweetalert2";
import { SIDE_BAR_ITEMS } from "../../../app/constants";
@Component({
  selector: "app-admin-dashboard",
  templateUrl: "./admin-dashboard.component.html",
  styleUrls: ["./admin-dashboard.component.css"],
})
export class AdminDashboardComponent implements OnInit {
  opened = false;
  isCorporateCompaniesActive: boolean = true;
  isCertificateModeraton: boolean = false;
  isManagerMis: boolean = false;
  isUserGuide: boolean = false;
  isAssignCoupon: boolean = false;
  isBenchmarkActive: boolean = false;
  isRound2: boolean = false;
  isTrigger: boolean = false;
  isMentor: boolean = false;
  isBuLogin: boolean = false;
  isLogs: boolean = false;
  isLanding: boolean = false;
  isNudges: boolean = false;
  isDatabase: boolean = false;
  isReferral: boolean = false;
  name: any;
  adminName: String = "";
  showPopup: Boolean = false;
  showRolePopup: Boolean = false;
  postDecode: any;
  received_token: any;
  user: any;
  userDetails: any;
  sideBarItems = SIDE_BAR_ITEMS;
  timer: any;
  userId: any;
  constructor(private adminService: AdminServices, private router: Router) {}

  ngOnInit() {
    this.timer = new IdleTimer({
      timeout: 900,
      onTimeout: () => {
        Swal.fire({
          title: "Session is expired!",
          text: "Your session is expired due to inactivity. Please sign in again",
          icon: "success",
          showConfirmButton: true,
          allowOutsideClick: false,
        }).then((value) => {
          localStorage.clear();
          if (this.adminService.getActiveModals().length > 0) {
            // console.log(this.adminService.getActiveModals().length);
            for (
              let i = 0;
              i < this.adminService.getActiveModals().length;
              i++
            ) {
              this.adminService.getActiveModals()[i].hide();
            }
          }
          this.adminService.removeModal();
          this.router.navigate(["/admin/"]);
        });
      },
    });
    let roles = localStorage.getItem("roles");

    if (roles) {
      this.showPopup = true;
    } else {
      this.showPopup = false;
    }
    this.adminService.popUpShow.subscribe((res) => {
      this.showRolePopup = res;
    });
    if (localStorage.getItem("token")) {
      this.adminName = localStorage.getItem("admin_name");
    } else {
      this.router.navigate(["/admin"]);
    }
  }

  onLogout() {
    localStorage.clear();
    this.router.navigate(["/admin"]);
  }
  openSidenavbar() {
    this.opened = !this.opened;
  }
  toggleRolesPopup() {
    this.showRolePopup = !this.showRolePopup;
  }
  backdropclose() {
    this.opened = false;
  }
  swalAlert() {
    Swal.fire({
      title: "Coming Soon",
      text: "",
      icon: "info",
      showConfirmButton: false,
      timer: 1000,
    });
  }
}
