import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
// import { map } from 'rxjs/Operator';
// import 'rxjs/add/operator/map';

@Injectable()
export class TriggersService {
  public triggersApiUrl = '/api/v2/triggers';
  public serverUrl = environment.apiURl;
  constructor(private http: HttpClient) {}

  subscription(subscription: PushSubscription) {
    return this.http.post(
      this.serverUrl + this.triggersApiUrl + '/subscription',
      subscription
    );
    // .map((response: Response) => {
    //   return <any>response;
    // });
  }

  addSubscription(subscription: any) {
    // console.log(subscription)
    return this.http.post(
      this.serverUrl + this.triggersApiUrl + '/addSubscription',
      subscription
    );
    // .map((response: Response) => {
    //   return <any>response;
    // });
  }
}
