<div class="container-fluid" style="padding-top: 1%">
  <!-- //<app-loader *ngIf="loading"></app-loader> -->
  <div class="row" style="background-color: #fbfbfb">
    <div class="col-md-12" style="padding-bottom: 2%">
      <div class="row" style="padding-top: 2%">
        <div class="col-md-3">
          <div class="cards">
            <div class="surveys">
              <p>Total Number of Managers</p>
              <p style="font-weight: 700">{{ totalManagers }}</p>
            </div>
            <div class="surveys-image">
              <img src="../../../assets/images/employees.png" />
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="cards" style="height: 87px">
            <div class="employees">
              <p>Total Number of Self Assesment Surveys</p>
              <p style="font-weight: 700">{{ totalSAS }}</p>
            </div>
            <div class="employees-image">
              <img src="../../../assets/images/surveysimg.png" />
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="cards">
            <div class="managers">
              <p>Total Number of Surveys</p>
              <p style="font-weight: 700">{{ totalSurveys }}</p>
            </div>
            <div class="managers-image">
              <img src="../../../assets/images/managersimg.png" />
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="cards">
            <div class="managers">
              <p class="small-text">Download Section</p>
              <!-- <div class="dropdown">
                <button
                  class="btn btn-primary dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                >
                  Download <span class="caret"></span>
                </button>
                  
                  
                </ul>
              </div> -->
              <button
                matTooltip="Gpms Accepted Manager List"
                (click)="getDownloadSheet('gpms', null)"
              >
                GPMS
              </button>
              <button
                matTooltip="Managers Checklist"
                (click)="getDownloadSheet('chl', null)"
              >
                CHL
              </button>
              <button
                matTooltip="Managers feedback"
                (click)="getDownloadSheet('mf', null)"
              >
                MF
              </button>
              <button
                matTooltip="Get Scores of all managers"
                (click)="getDownloadSheet('gs', null)"
              >
                GS
              </button>
              <button
                matTooltip="Managers Status"
                (click)="getDownloadSheet('ms', null)"
              >
                MS
              </button>
              <button
                matTooltip="Manager Learning Lists"
                (click)="getDownloadSheet('ldl', null)"
              >
                lDl
              </button>
              <button
                matTooltip="Total Managers Data"
                (click)="getDownloadSheet('TMD', null)"
              >
                TMD
              </button>
              <button
                matTooltip="Manager E-Learning"
                (click)="getDownloadSheet('MEL', eLearning)"
              >
                MEL
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="col-md-12 form-card"
      style="
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      "
    ></div>
    <div
      class="row"
      style="
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      "
    >
      <div class="col-md-12 subadmin-title" style="padding: 0px !important">
        <div class="col-md-5" style="padding-top: 2%">
          <input
            type="text"
            (keyup)="applyFilter($event.target['value'])"
            class="form-control search-icon"
            placeholder="Enter your search..."
            (keyup)="applyFilter($event.target['value'])"
          />
          <div class="clearfix"></div>
        </div>
        <div class="col-md-3" style="padding-top: 2%">
          <ngx-select-dropdown
            (change)="changeManagerType($event)"
            [(ngModel)]="managerTypeValue"
            [config]="config2"
            [options]="managerType"
          ></ngx-select-dropdown>
        </div>
        <div class="col-md-3" style="padding-top: 2%">
          <div class="">
            <div class="row">
              <div class="col-md-10">
                <ngx-select-dropdown
                  (change)="selectCompanies($event)"
                  [multiple]="true"
                  [config]="config"
                  [options]="companies"
                ></ngx-select-dropdown>
              </div>
              <div class="col-md-2">
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="downloadMIS()"
                >
                  <i class="fa fa-download"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <form [formGroup]="managerFilterForm" #formDirective="ngForm">
        <div
          class="col-md-12 flex justify-center items-center row-gap"
          style="padding: 20px"
        >
          <div class="col-md-2">
            <mat-form-field>
              <input
                matInput
                formControlName="startDate"
                [matDatepicker]="startDatePicker"
                placeholder="Package Start Date"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="startDatePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #startDatePicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field>
              <input
                matInput
                formControlName="endDate"
                [matDatepicker]="endDatePicker"
                placeholder="Package End Date"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="endDatePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #endDatePicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <ngx-select-dropdown
              #statusSelect
              formControlName="managerStatus"
              [config]="config3"
              [options]="managerStatus"
            ></ngx-select-dropdown>
          </div>
          <div class="col-md-2">
            <button
              class="btn btn-primary"
              (click)="filterManagers(managerFilterForm)"
              [disabled]="managerFilterForm.invalid"
              style="width: 100%"
            >
              <i class="fa fa-filter" aria-hidden="true"></i>Filter
            </button>
            <span class="resetFilter" (click)="resetFilter()"
              >Reset Filter</span
            >
          </div>
          <div class="col-md-2">
            <button
              class="btn btn-success"
              style="width: 100%"
              (click)="selectManagers()"
            >
              <i class="fa fa-whatsapp mr-4" aria-hidden="true"></i>Whatsapp
            </button>
          </div>
          <div class="col-md-2">
            <button class="btn btn-primary" style="width: 100%">
              <i class="fa fa-envelope mr-4" aria-hidden="true"></i>Emails
            </button>
          </div>
        </div>
      </form>
      <div class="users-table">
        <div class="subadmin-table">
          <div
            class="example-container container mar-tp tab-size"
            style="
              box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
                0 1px 2px rgba(0, 0, 0, 0.24);
            "
          >
            <mat-table [dataSource]="dataSource" matSort>
              <!-- Checkbox Column -->
              <ng-container matColumnDef="select">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  style="width: 50px; border: none"
                >
                  <mat-checkbox
                    (change)="$event ? toggleAllRows() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                    [aria-label]="checkboxLabel()"
                  >
                  </mat-checkbox>
                </th>
                <td
                  mat-cell
                  *matCellDef="let row"
                  style="width: 50px; border: none"
                >
                  <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)"
                    [aria-label]="checkboxLabel(row)"
                  >
                  </mat-checkbox>
                </td>
              </ng-container>
              <!-- EMail Column -->
              <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >Email id</mat-header-cell
                >
                <mat-cell *matCellDef="let element">
                  <div *ngIf="!ele">{{ element.email }}</div>
                  <div class="loading-wrapper" *ngIf="ele">
                    <div class="loading content last-row"></div>
                  </div>
                </mat-cell>
              </ng-container>

              <!-- Company Name Column -->
              <ng-container matColumnDef="company">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >Company name</mat-header-cell
                >
                <mat-cell *matCellDef="let element">
                  <div *ngIf="!ele">{{ element.company }}</div>
                  <div class="loading-wrapper" *ngIf="ele">
                    <div class="loading content last-row"></div>
                  </div>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="phone">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >Phone No.</mat-header-cell
                >
                <mat-cell *matCellDef="let element">
                  <div *ngIf="!ele">{{ element.mobileVerified }}</div>
                  <div class="loading-wrapper" *ngIf="ele">
                    <div class="loading content last-row"></div>
                  </div>
                </mat-cell>
              </ng-container>
              <!-- Status Column -->
              <ng-container matColumnDef="managerStatus">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >status</mat-header-cell
                >
                <mat-cell *matCellDef="let element" style="overflow: unset">
                  <div *ngIf="!ele">
                    <ul class="gmi-stepper">
                      <li
                        [ngClass]="{ active: element.isRegistered }"
                        class="active"
                        matTooltip="Register"
                      >
                        RG
                      </li>
                      <div
                        [ngClass]="{ activeDivider: element.isRegistered }"
                        class="divider"
                      ></div>
                      <li
                        [ngClass]="{ active: element.isSAS }"
                        matTooltip="Self Assesment"
                        (click)="openSaSModal(sasInfo, element._id)"
                      >
                        SAS
                      </li>
                      <div
                        [ngClass]="{ activeDivider: element.isSAS }"
                        class="divider"
                      ></div>
                      <li
                        [ngClass]="{ active: element.isPackage }"
                        matTooltip="Package"
                      >
                        PK
                      </li>
                      <div
                        [ngClass]="{ activeDivider: element.isPackage }"
                        class="divider"
                      ></div>
                      <li
                        [ngClass]="{ active: element.isTeamFeedBack }"
                        matTooltip="Team Feedback Survey"
                        (click)="openSurveyModal(SurveyInfo, element._id)"
                      >
                        TFS
                      </li>
                      <div
                        [ngClass]="{
                          activeDivider: element.isTeamFeedBack
                        }"
                        class="divider"
                      ></div>
                      <li
                        [ngClass]="{ active: element.isActionPlanning }"
                        (click)="
                          openActionPlanning(actionPlanInfo, element._id)
                        "
                        [ngStyle]="{
                          'background-color':
                            element.isActionPlanning == false &&
                            element.isCertificate == true
                              ? 'yellow'
                              : 'whitesmoke'
                        }"
                        matTooltip="Action Planning"
                      >
                        AP
                      </li>
                      <div
                        [ngClass]="{
                          activeDivider: element.isActionPlanning
                        }"
                        class="divider"
                      ></div>
                      <li
                        [ngClass]="{ active: element.isCertified }"
                        matTooltip="Certificate"
                      >
                        CF
                      </li>
                    </ul>
                  </div>
                  <div class="loading-wrapper" *ngIf="ele">
                    <div class="loading content last-row"></div>
                  </div>
                </mat-cell>
              </ng-container>

              <!-- Package Column -->
              <ng-container matColumnDef="package">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >Package</mat-header-cell
                >
                <mat-cell *matCellDef="let element">
                  <div *ngIf="!ele">{{ element.packageName }}</div>
                  <div class="loading-wrapper" *ngIf="ele">
                    <div class="loading content last-row"></div>
                  </div>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="expirydate">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >Expiry date</mat-header-cell
                >
                <mat-cell *matCellDef="let element">
                  <div *ngIf="!ele">
                    {{
                      element.expirydate ===
                      "Yet
                    to
                    register"
                        ? "Yet
                    to
                    register"
                        : expiryDate(element.expirydate)
                    }}
                  </div>
                  <div class="loading-wrapper" *ngIf="ele">
                    <div class="loading content last-row"></div>
                  </div>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="overall">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >Action</mat-header-cell
                >
                <mat-cell *matCellDef="let element">
                  <div *ngIf="!ele" style="position: absolute">
                    <!-- <button (click)="openOverallScoreModal(element._id ,overAllScoreInfo)" class="btn btn-primary">Overall Score</button>
                       <button class="btn btn-primary" (click)="getRawData(element._id , element.email)">RD</button> -->
                    <div class="dropdown">
                      <button
                        class="btn btn-primary dropdown-toggle"
                        (click)="getTfsData(element._id)"
                        type="button"
                        data-toggle="dropdown"
                      >
                        Download <span class="caret"></span>
                      </button>
                      <ul class="dropdown-menu">
                        <li>
                          <a
                            (click)="
                              openOverallScoreModal(
                                element._id,
                                overAllScoreInfo
                              )
                            "
                            >Overall Score</a
                          >
                        </li>
                        <li>
                          <a
                            (click)="
                              getRawDataManager(element._id, element.email)
                            "
                            >Raw Data</a
                          >
                        </li>
                        <li
                          style="
                            font-size: 15px;
                            font-weight: 700;
                            margin-left: 10px;
                          "
                        >
                          Auditsheet :
                        </li>
                        <li *ngFor="let survey of surveysData; index as i">
                          <a (click)="getAuditSheet(survey._id)"
                            >TFS {{ i + 1 }}</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="loading-wrapper" *ngIf="ele">
                    <div class="loading content last-row"></div>
                  </div>
                </mat-cell>
              </ng-container>
              <mat-header-row
                *matHeaderRowDef="displayedColumns"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedColumns"
              ></mat-row>
            </mat-table>
            <!-- <div class="nextBtn" *ngIf="!showPaginator">
              <div
                class="pointer"
                (click)="pageChanged('Prev')"
                [ngClass]="{ 'pointer-none': pageNumber == 1 }"
              >
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
                Previous
              </div>
              <h3 class="pageNumber">Page - {{ pageNumber }}</h3>
              <div class="mr-4 pointer ml-4" (click)="pageChanged('Next')">
                Next
                <i class="fa fa-arrow-right" aria-hidden="true"></i>
              </div>
            </div> -->
            <mat-paginator
              [length]="8"
              [pageSize]="8"
              [pageSizeOptions]="[5, 10, 15]"
            >
            </mat-paginator>
          </div>
        </div>
      </div>

      <div class="emailAnalyticsCard">
        <p class="analyticsHeader">Email Analytics Data</p>
        <div class="selectDate">
          <input
            matInput
            disabled
            [matDatepicker]="picker1"
            placeholder="Select Date"
            [(ngModel)]="analyticsDate"
          />
          <mat-datepicker-toggle
            class="matIcon"
            matSuffix
            [for]="picker1"
          ></mat-datepicker-toggle>
          <mat-datepicker
            disabled="false"
            touchUi="true"
            #picker1
          ></mat-datepicker>
        </div>
        <button
          class="btn btn-primary analyticsBtn"
          data-toggle="dropdown"
          (click)="getAnalyticsData()"
        >
          Download
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #SurveyInfo>
  <div class="modal-header">
    <h4 style="font-size: 22px" class="modal-title pull-left">
      Survey Information
    </h4>
    <button
      type="button "
      class="close pull-right"
      aria-label="Close "
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="surveysData.length != 0; else noSurveyData">
      <table style="margin-top: 0" class="table table-striped">
        <thead>
          <tr>
            <th>Sl. No</th>
            <th>Survey Name</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Overall Score</th>
            <th>Response Rate</th>
            <th>Participants</th>
          </tr>
        </thead>
        <tbody *ngFor="let survey of surveysData; index as i">
          <tr>
            <td>{{ i + 1 }}</td>
            <td>{{ survey.name }}</td>
            <td>{{ survey.startDate }}</td>
            <td>{{ survey.endDate }}</td>
            <td>
              {{
                survey.surveyScore.overall
                  ? survey.surveyScore.overall + "%"
                  : "not calculated"
              }}
            </td>
            <td>{{ surveyResponseRate[survey._id] }}%</td>
            <td>
              <ul>
                <li
                  style="list-style: none"
                  class="participants"
                  *ngFor="let participant of survey.participants; index as i"
                >
                  {{ i + 1 }}.
                  <div>
                    {{ participant.emailId }}<br />
                    {{ participant.respondentType }}
                  </div>
                  <br />
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>
<ng-template #noSurveyData>
  <p>No Survey Data!</p>
</ng-template>

<ng-template #actionPlanInfo>
  <div class="modal-header">
    <h4 style="font-size: 22px" class="modal-title pull-left">
      Action Plan Information
    </h4>
    <button
      type="button "
      class="close pull-right"
      aria-label="Close "
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="actionPlanningData.length != 0; else noActionData">
      <table style="margin-top: 0" class="table table-striped">
        <thead>
          <tr>
            <th>Sl. No</th>
            <th>Action Planning Name</th>
            <!-- <th>Start Date</th>
            <th>End Date</th> -->
            <th>Theme</th>
            <th>Duration</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody *ngFor="let action of actionPlanningData; index as i">
          <tr>
            <td>{{ i + 1 }}</td>
            <td>{{ action.actionName }}</td>
            <!-- <td>{{ action.startDate }}</td>
            <td>{{ action.endDate }}</td> -->
            <td>{{ action.theme }}</td>
            <td>{{ action.duration }}</td>
            <td>{{ action.description }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>
<ng-template #noActionData>
  <p>No Action Planning Data!</p>
</ng-template>

<ng-template #sasInfo>
  <div class="modal-header">
    <h4 style="font-size: 22px" class="modal-title pull-left">
      SelfAssesment Information
    </h4>
    <button
      type="button "
      class="close pull-right"
      aria-label="Close "
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="sasData.length != 0; else noSurveyData">
      <table style="margin-top: 0" class="table table-striped">
        <thead>
          <tr>
            <th>Sl. No</th>
            <th>Survey Name</th>
            <th>Overall Score</th>
            <th>Connect Score</th>
            <th>Develop Score</th>
            <th>Inspire Score</th>
          </tr>
        </thead>
        <tbody *ngFor="let survey of sasData; index as i">
          <tr>
            <td>{{ i + 1 }}</td>
            <td>{{ survey.details.surveyName }}</td>
            <td>{{ survey.details.surveyScore }}%</td>
            <td>{{ survey.details.cScore }}%</td>
            <td>{{ survey.details.dScore }}%</td>
            <td>{{ survey.details.iScore }}%</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>

<ng-template #overAllScoreInfo>
  <div class="modal-header">
    <h4 style="font-size: 22px" class="modal-title pull-left">Overall Score</h4>
    <button
      type="button "
      class="close pull-right"
      aria-label="Close "
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="overallScoreDetails; else noSurveyData">
      <table style="margin-top: 0" class="table table-striped">
        <thead>
          <tr>
            <th>Connect Score</th>
            <th>Develop Score</th>
            <th>Inspire Score</th>
            <th>Overall Score</th>
            <th>Subtheme</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ overallScoreDetails.connect }}%</td>
            <td>{{ overallScoreDetails.develop }}%</td>
            <td>{{ overallScoreDetails.inspire }}%</td>
            <td>{{ overallScoreDetails.overall }}%</td>
            <td>
              <thead>
                <tr>
                  <th>Subtheme Name</th>
                  <th>Subtheme Score</th>
                </tr>
              </thead>
              <tbody
                *ngFor="let score of overallScoreDetails.subthemeWiseScore"
              >
                <tr>
                  <td>
                    {{ score.subthemeName }}
                  </td>
                  <td>
                    {{ score.subThemeScore }}
                  </td>
                </tr>
              </tbody>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>

<ng-template #eLearning>
  <div class="modal-body">
    <button
      type="button "
      class="close pull-right"
      aria-label="Close "
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true ">&times;</span>
    </button>
    <mat-table [dataSource]="learningDataSource" matSort>
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >Name</mat-header-cell
        >
        <mat-cell *matCellDef="let element">
          <div (click)="getLearningData(element)">{{ element.name }}</div>
          <!-- <div class="loading-wrapper">
            <div class="loading content last-row"></div>
          </div> -->
        </mat-cell>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >Email</mat-header-cell
        >
        <mat-cell *matCellDef="let element">
          <div (click)="getLearningData(element)">{{ element.email }}</div>
          <!-- <div class="loading-wrapper">
            <div class="loading content last-row"></div>
          </div> -->
        </mat-cell>
      </ng-container>
      <mat-header-row
        *matHeaderRowDef="displayLearningColumns"
      ></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayLearningColumns"></mat-row>
    </mat-table>
    <!-- <mat-paginator [length]="4" [pageSize]="4" [pageSizeOptions]="[4, 8, 12]">
    </mat-paginator> -->
  </div>
</ng-template>

<ng-template #whatsapp>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      Send Whatsapp Message | Total Managers - {{ selectedManagers.length }}
    </h4>
    <button
      type="button"
      class="btn-close close pull-right"
      aria-label="Close"
      (click)="modalRef?.hide()"
    >
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="loading">
      <h3 class="fs-14 font-bold w-100">{{ totalNewUploadedManager }}</h3>
      <h3 class="fs-14 font-bold w-100">{{ messageSentToManager }}</h3>
      <mat-progress-bar
        mode="determinate"
        value="{{ percentCompletion }}"
      ></mat-progress-bar>
    </div>
    <div *ngIf="msgNotSentTo.length > 0 && !loading">
      <h3 class="fs-14 font-bold w-100">Cannot send message to</h3>
      <div class="flex">
        <div *ngFor="let manager of msgNotSentTo">
          <div class="selectedManagers">
            <h3>Email - {{ manager.email }}</h3>
            <h3 class="font-bold">Mob - {{ manager.mobileVerified }}</h3>
            <h6>Whatsapp Optin - {{ manager.whatsappOptinStatus }}</h6>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!loading && msgNotSentTo.length <= 0">
      <div>
        <div class="flex justify-between items-center">
          <div>
            <h3>{{ whatsappMsgRes }}</h3>
          </div>
          <h3 class="fs-14 font-bold">Select Template</h3>
          <!-- <div>
            <button class="btn btn-primary">Send</button>
          </div> -->
          <button
            class="btn btn-gmi"
            (click)="sendWhatsappMessage()"
            [disabled]="
              selectedGupShupTemplate['length'] <= 0 ||
              selectedManagers.length <= 0
            "
          >
            <i class="fa fa-paper-plane" aria-hidden="true"></i>Send Message
          </button>
        </div>
        <!-- <div *ngIf="gupshupTemplates.length > 0">
          <div *ngFor="let template of gupshupTemplates">
            <h1>{{ template.elementName }}</h1>
          </div>
        </div> -->
        <ngx-select-dropdown
          (change)="selectGupShupTemplate($event)"
          [config]="config4"
          [options]="gupshupTemplates"
        ></ngx-select-dropdown>
      </div>

      <!-- Selected template details -->
      <div *ngIf="(selectedGupShupTemplate | json) != '{}'">
        <div class="msgBox">
          <h3 class="fs-14 font-bold">Message Contents</h3>
          <div>{{ selectedGupShupTemplate["content"] }}</div>
        </div>
      </div>
      <div *ngIf="selectedManagers.length > 0" class="managerBody">
        <h3 class="fs-14 font-bold w-100">Selected Users</h3>
        <div *ngFor="let manager of selectedManagers">
          <div class="selectedManagers">
            <h3>Email - {{ manager.email }}</h3>
            <h3 class="font-bold">Mob - {{ manager.mobileVerified }}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
