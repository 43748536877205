<app-loader *ngIf="isLoading"></app-loader>
<h3>Choose survey date interval:</h3>
<section class="select-section" *ngIf="!isLoading">
  <mat-form-field appearance="fill">
    <mat-label>Enter a date range</mat-label>
    <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
      <input matStartDate formControlName="start" placeholder="Start date" />
      <input matEndDate formControlName="end" placeholder="End date" />
    </mat-date-range-input>
    <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>

    <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')"
      >Invalid start date</mat-error
    >
    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')"
      >Invalid end date</mat-error
    >
  </mat-form-field>
  <button
    mat-raised-button
    color="warn"
    [disabled]="
      range.controls.start.value == null || range.controls.end.value == null
    "
    (click)="downloadReport()"
  >
    Download Report
  </button>
</section>
