<div class="main-box p-5">
  <form [formGroup]="contentPageForm">
    <div class="row align-items-center">
      <div class="col-md-4">
        <div class="form-group pb-10">
          <label for="content_type"
            >Content Type<span class="asterick">*</span></label
          >
          <select
            class="form-control custom-form-control"
            required
            id="content_type"
            name="content_type"
            formControlName="content_type"
            (change)="onContentTypeChange($event)"
          >
            <option value="" selected>Select</option>
            <option value="video">Video</option>
            <option value="image">Image</option>
            <option value="text">Text</option>
          </select>
          <p
            class="error"
            *ngIf="
              contentPageForm?.get('content_type').hasError('required') &&
              contentPageForm.get('content_type').touched
            "
          >
            Content Type is required.
          </p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group pb-10">
          <label>Description/Message<span class="asterick">*</span></label>
          <textarea
            class="form-control custom-form-control"
            id="description"
            name="description"
            type="text"
            appNoSpaces
            required
            formControlName="description"
            rows="5"
            placeholder="Enter description..."
          ></textarea>

          <p
            class="error"
            *ngIf="
              contentPageForm.get('description').hasError('required') &&
              contentPageForm.get('description').touched
            "
          >
            Description/Message is required.
          </p>
          <p
            class="error"
            *ngIf="
              contentPageForm.get('description').hasError('minlength') &&
              (contentPageForm.get('description').touched || isUpdate)
            "
          >
            Description/Message must be at least 25 characters long.
          </p>
          <p
            class="error"
            *ngIf="
              contentPageForm.get('description').hasError('maxlength') &&
              (contentPageForm.get('description').touched || isUpdate)
            "
          >
            Description/Message cannot be more than 250 characters long.
          </p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="form-group pb-10">
          <label>Button Text<span class="asterick">*</span></label>
          <input
            class="form-control custom-form-control"
            required
            id="button_text"
            name="button_text"
            type="text"
            appNoSpaces
            formControlName="button_text"
            placeholder="Enter button_text name..."
          />
          <p
            class="error"
            *ngIf="
              contentPageForm.get('button_text').hasError('required') &&
              contentPageForm.get('button_text').touched
            "
          >
            Button Text is required.
          </p>
          <p
            class="error"
            *ngIf="
              contentPageForm.get('button_text').hasError('minlength') &&
              (contentPageForm.get('button_text').touched || isUpdate)
            "
          >
            Message must be at least 2 characters long.
          </p>
          <p
            class="error"
            *ngIf="
              contentPageForm.get('button_text').hasError('maxlength') &&
              (contentPageForm.get('button_text').touched || isUpdate)
            "
          >
            Button Text cannot be more than 25 characters long.
          </p>
        </div>
      </div>

      <div class="col-md-6">
        <div class="">
          <div class="form-group pb-10">
            <label>Image / GIF / Video<span class="asterick">*</span></label>
            <input
              id="fileSelect"
              type="file"
              [accept]="fileInputData.fileAccept"
              (change)="fileChangeEvent($event)"
              [disabled]="
                contentPageForm.value.content_type == '' ||
                contentPageForm.value.content_type == 'text'
              "
            />
            <p class="error">{{ fileInputData.note }}</p>
            <p class="error" *ngIf="contentPageForm.value.content_type == ''">
              Please select content type to upload file.
            </p>

            <p
              class="error"
              *ngIf="contentPageForm.get('url').hasError('required')"
            >
              URL is required.
            </p>
          </div>

          <div class="form-group" *ngIf="contentPageForm.value.url">
            <img
              *ngIf="contentPageForm.value.content_type == 'image'"
              oncontextmenu="return false;"
              style="
                max-width: 200px;
                max-height: 150px;
                border-radius: 5%;
                border: 1px solid #ccc;
              "
              width="200px"
              alt=""
              [src]="getImageUrl(contentPageForm.value.url)"
            />

            <video
              *ngIf="contentPageForm.value.content_type == 'video'"
              style="
                max-width: 200px;
                max-height: 150px;
                border-radius: 5%;
                border: 1px solid #ccc;
              "
              width="200px"
              oncontextmenu="return false;"
              autoplay
              controls
              controlsList="nodownload"
            >
              <source
                [src]="getImageUrl(contentPageForm.value.url)"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="pb-10 mt-5">
          <label>Status<span class="asterick">*</span></label>
          <br />
          <mat-slide-toggle
            formControlName="is_active"
            required
            [checked]="contentPageForm.value.is_active == 1 ? true : false"
            >{{
              contentPageForm.value.is_active == 1 ? "Active" : "Inactive"
            }}</mat-slide-toggle
          >
        </div>
      </div>
    </div>

    <div class="" style="display: flex; justify-content: center; gap: 20px">
      <div>
        <button
          type="button"
          (click)="createContentPage(null)"
          style="margin-top: 25px; color: white"
          class="btn btn-secondary btn-newuser-clr"
          [disabled]="contentPageForm.invalid"
        >
          {{ isUpdate ? "Update" : "Create" }}
        </button>
      </div>
      <div>
        <button
          *ngIf="isUpdate"
          type="button"
          style="margin-top: 25px; color: white"
          class="btn btn-secondary btn-newuser-clr"
          (click)="contentPageForm.reset(); isUpdate = false"
        >
          Add New
        </button>
      </div>
    </div>
  </form>

  <div class="main-box-table mat-elevation-z8">
    <div class="row">
      <div class="col-md-5">
        <div class="form-group pb-10">
          <input
            class="form-control custom-form-control"
            required
            type="text"
            placeholder="Search anything her..."
            (keyup)="searchFillers($event)"
          />
        </div>
      </div>
    </div>
    <mat-table [dataSource]="dataSource" class="w-100">
      <!-- Content Type Column -->
      <ng-container matColumnDef="content_type">
        <mat-header-cell *matHeaderCellDef> Content Type </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.content_type | titlecase }}
        </mat-cell>
      </ng-container>

      <!-- URL Column -->
      <ng-container matColumnDef="url">
        <mat-header-cell *matHeaderCellDef> Image/GIF/Video </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div *ngIf="element.url">
            <img
              *ngIf="element.content_type == 'image'"
              oncontextmenu="return false;"
              style="
                max-width: 100px;
                max-height: 75px;
                border-radius: 5%;
                border: 1px solid #ccc;
                margin-top: 5px;
                margin-bottom: 5px;
              "
              width="100px"
              alt=""
              [src]="getImageUrl(element.url)"
            />
            <video
              *ngIf="element.content_type == 'video'"
              style="
                max-width: 100px;
                max-height: 50px;
                border-radius: 5%;
                border: 1px solid #ccc;
              "
              width="100px"
              oncontextmenu="return false;"
              autoplay
              controls
              controlsList="nodownload"
            >
              <source [src]="getImageUrl(element.url)" type="video/mp4" />
            </video>
          </div>
          <div *ngIf="!element.url">
            <p>No Image/GIF/Video</p>
          </div>
        </mat-cell>
      </ng-container>

      <!-- Description Column -->
      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef>
          Description/Message
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.description }}
        </mat-cell>
      </ng-container>

      <!-- Button Text Column -->
      <ng-container matColumnDef="button_text">
        <mat-header-cell *matHeaderCellDef> Button Text </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.button_text }}
        </mat-cell>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="is_active">
        <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div>
            <div class="p-3">
              <mat-slide-toggle
                [(ngModel)]="element.is_active"
                (change)="statusChange($event, element)"
                [checked]="element.is_active == 1 ? true : false"
              >
              </mat-slide-toggle>
            </div>

            <div
              class="chip"
              [ngStyle]="{
                'background-color':
                  element.is_active == 1 ? '#D3FCD2' : '#FFAC82',
                color: element.is_active == 1 ? '#118D57' : '#B71D18'
              }"
            >
              {{ element.is_active == 1 ? "Active" : "Inactive" }}
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell class="text-center" *matCellDef="let element">
          <!-- <i
            class="fa fa-trash-o"
            style="padding-right: 10px; color: #ff5630"
            (click)="deleteContentPage(element.id)"
            aria-hidden="true"
          ></i> -->

          <i
            (click)="patchValues(element)"
            class="fa fa-pencil-square-o"
            aria-hidden="true"
          ></i>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[05, 10, 20]"></mat-paginator>
    <div
      class="text-center emptyContainer"
      *ngIf="dataSource.data.length === 0"
    >
      <h2>Not found any survey fillers.</h2>
    </div>
  </div>
</div>
