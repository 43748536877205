<!-- //<app-loader *ngIf="loading"></app-loader> -->
<a
  style="
    display: block;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
    text-decoration: none;
  "
  (click)="assignPackageModal(assignPackage)"
>
  Assign Package</a
>

<ng-template #assignPackage>
  <div class="modal-header">
    <h4
      style="font-size: 22px; text-transform: capitalize"
      class="modal-title pull-left"
    >
      Assign Packages to new managers.
    </h4>
    <button
      type="button "
      class="close pull-right"
      aria-label="Close "
      (click)="closeModal()"
    >
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-6">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let manager of selectedManagers">
              <td>{{ manager.name }}</td>
              <td>{{ manager.email }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-6 licenseContainer">
        <ul class="licenseInput">
          <li>
            Profiling License :
            {{ basicCountRem >= 0 ? basicCountRem : basicCount }}
            <input
              type="radio"
              id="inputChecked"
              name="basic"
              (change)="packageSelection($event)"
              [(ngModel)]="basic"
              value="Profiling"
            />
          </li>
          <li>
            Certification License :
            {{ premiumCountRem >= 0 ? premiumCountRem : premiumCount }}
            <input
              type="radio"
              id="inputChecked"
              name="special"
              (change)="packageSelection($event)"
              [(ngModel)]="basic"
              value="Certification"
            />
          </li>
          <li>
            Special License :{{
              specialCountRem >= 0 ? specialCountRem : specialCount
            }}
            <input
              type="radio"
              id="inputChecked"
              name="premium"
              (change)="packageSelection($event)"
              [(ngModel)]="basic"
              value="Special"
            />
          </li>
          <!-- <li>
            Special - Post :{{
              specialCountRem >= 0 ? specialCountRem : specialCount
            }}
            <input
              type="radio"
              id="inputChecked"
              name="premium"
              (change)="packageSelection($event)"
              [(ngModel)]="basic"
              value="Special-Post Survey"
            />
          </li> -->
          <li>
            Custom License :{{
              customCountRem >= 0 ? customCountRem : customCount
            }}
            <input
              type="radio"
              id="inputChecked"
              name="custom"
              (change)="packageSelection($event)"
              [(ngModel)]="basic"
              value="Custom"
            />
          </li>
        </ul>
        <p class="help-block" *ngIf="packageSelectionError">
          Please do not assign manager more than you have buyed.
        </p>
        <button
          class="btn btn-primary"
          [disabled]="packageSelectionError"
          (click)="submitPackageToManager()"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</ng-template>
