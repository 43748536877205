<app-universal-screen *ngIf="showRolePopup"></app-universal-screen>

<div class="admin-container">
  <div class="row">
    <div class="col-md-2" style="padding: 0px">
      <div class="sidebar">
        <div class="logo">
          <div class="logo-bg-clr">
            <img src="../../../assets/images/gmi_newlogo.png" height="70px" />
          </div>
        </div>
        <div>
          <ul class="sidebar-ul" *ngFor="let items of sideBarItems">
            <li>
              <a
                *ngIf="items.url !== undefined && items.url !== ''"
                [routerLink]="[items.url]"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                #rla="routerLinkActive"
              >
                <i
                  class="{{ items.icon }}"
                  style="font-size: 16px; padding-left: 2%"
                ></i>
                <span>{{ items.name }}</span>
              </a>
              <a *ngIf="items.url == undefined">
                <i
                  class="{{ items.icon }}"
                  style="font-size: 16px; padding-left: 2%"
                ></i>
                <span>{{ items.name }}</span>
                <span *ngIf="items.subItems && items.subItems.length > 0"
                  ><i
                    *ngIf="items.subItemOpen == false"
                    class="fa fa-angle-down"
                    aria-hidden="true"
                    (click)="items.subItemOpen = true"
                  ></i
                  ><i
                    *ngIf="items.subItemOpen == true"
                    class="fa fa-angle-up"
                    aria-hidden="true"
                    (click)="items.subItemOpen = false"
                  ></i>
                </span>
                <div *ngIf="items.subItemOpen == true">
                  <div *ngFor="let subItem of items.subItems">
                    <a
                      class="subItem"
                      [routerLink]="[subItem.url]"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                    >
                      <i
                        class="{{ subItem.icon }}"
                        style="font-size: 16px; padding-left: 2%"
                      ></i>
                      <span>{{ subItem.name }}</span>
                      <br />
                    </a>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-md-10" style="padding: 0px">
      <nav class="custom-nav">
        <div class="roles-container" *ngIf="showPopup">
          <button (click)="toggleRolesPopup()">Roles</button>
        </div>
        <div class="header">
          <p class="title">{{ name }}</p>
        </div>
        <div class="right-group">
          <p>{{ adminName }}</p>
          <div class="dropdown">
            <i
              class="fa fa-user-circle"
              aria-hidden="true"
              data-toggle="dropdown"
            ></i>
            <ul class="dropdown-menu">
              <li><a (click)="onLogout()">Log out</a></li>
            </ul>
          </div>
        </div>
      </nav>

      <main>
        <div class="router-div">
          <router-outlet></router-outlet>
        </div>
      </main>
    </div>
  </div>
</div>
