<div class="editor">
  <label for="content"
    >Note: Use <img src="../../../assets/images/attach.png" alt=""> option below to attach a
    clickable URL. Add the URL in both the URL and text sections. For a
    clickable email address, use mailto:'EmailId' in the URL section and type
    the same EmailId in the text section.</label
  >

  <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
  <ngx-editor
    [editor]="editor"
    [ngModelOptions]="{ standalone: true }"
    [placeholder]="'Enter text here...'"
    [(ngModel)]="content"
    (ngModelChange)="onContentChange($event)"
  ></ngx-editor>
</div>
