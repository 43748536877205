
<div class="page-wrapper">
  <table mat-table [dataSource]="dataSource">

    <!-- <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef> No. </th>
      <td mat-cell *matCellDef="let element"> {{element.position}} </td>
    </ng-container> -->

    <ng-container matColumnDef="Nudges like">
      <th mat-header-cell *matHeaderCellDef> Nudges like </th>
      <td mat-cell *matCellDef="let element"> {{element.likes}} </td>
    </ng-container>

    <ng-container matColumnDef="Mark done average">
      <th mat-header-cell *matHeaderCellDef> Mark done average </th>
      <td mat-cell *matCellDef="let element"> {{element.mark_done_average}} </td>
    </ng-container>

    <ng-container  matColumnDef="Nudge page view">
      <th mat-header-cell *matHeaderCellDef> Nudge page view </th>
      <td mat-cell *matCellDef="let element">{{element.nudge_page_view}}</td>
    </ng-container>
    
    <ng-container  matColumnDef="email open rate">
      <th mat-header-cell *matHeaderCellDef> email open rate </th>
      <td mat-cell *matCellDef="let element">{{element.email_open}}</td>
    </ng-container>

    <ng-container  matColumnDef="email click through rate">
      <th mat-header-cell *matHeaderCellDef> email click through rate </th>
      <td mat-cell *matCellDef="let element">{{element.email_click}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row  *matRowDef="let row; columns: displayedColumns;let i = index"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                 showFirstLastButtons
                 aria-label="Select page of periodic elements">
  </mat-paginator>
</div>