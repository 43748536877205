import { Component, OnInit } from "@angular/core";
import { CompanyServices } from "../company.service";
import { NavigationExtras, Router } from "@angular/router";
import { GMC_CRITERIA, GMC_CRITERIA_DEFAULT } from "src/app/constants";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
// import { AdminServices } from "../../admin/admin.service";
import Swal from "sweetalert2";
import { enforceMaxValue, preventNegativeInput } from "src/config/utils";
@Component({
  selector: "app-gmc",
  templateUrl: "./gmc.component.html",
  styleUrls: ["./gmc.component.css"],
})
export class GmcComponent implements OnInit {
  companyInfo;
  loading: boolean;
  companyId: any;
  batchOptions: any = [];
  packageOptions: any[] = [];
  gmcData: any;
  gmcOptions = GMC_CRITERIA;
  gmcDefault = GMC_CRITERIA_DEFAULT;
  constructor(
    private companyService: CompanyServices,
    private router: Router,
    private modalRef: BsModalRef,
    private modalService: BsModalService // private adminService: AdminServices
  ) {}

  ngOnInit(): void {
    this.getCompanyProfile();
  }
  getCompanyProfile() {
    this.companyService
      .getCompanyProfile({ companyId: this.companyId })
      .subscribe((response) => {
        this.loading = false;

        if (response.status) {
          this.companyInfo = response.data;
          this.gmcData = this.companyInfo?.companySettings?.gmcCriteria;

          if (typeof response.batchInCompany !== "undefined") {
            this.batchOptions = response?.batchInCompany;
            if (this.batchOptions.length == 0) {
              Swal.fire({
                title: "GMC Configuration Batches",
                text: "Cannot find Batch",
                icon: "error",
                showConfirmButton: true,
              });
            }
            // if(this.gmcData){
            //   this.packageOptions = this.batchOptions.map(() => {
            //     return JSON.parse(JSON.stringify(this.gmcOptions));
            //   });
            // }else{
            this.packageOptions = this.batchOptions.map(() => {
              return JSON.parse(JSON.stringify(this.gmcOptions));
            });
            // }
            this.companyService.batchOptions.next(response.batchInCompany);
            this.updatePackageOptions();
          }
        }
      });
  }
  updatePackageOptions() {
    for (let i = 0; i < this.batchOptions.length; i++) {
      const batch = this.batchOptions[i];
      const gmcBatchData = this.gmcData?.[batch];
      if (gmcBatchData) {
        this.packageOptions[i][0].value =
          gmcBatchData["learningDevelopmentMin"];
        this.packageOptions[i][1].value = gmcBatchData["actionPlanMin"];
        this.packageOptions[i][2].value = gmcBatchData["implementationMin"];
      } else {
        this.packageOptions[i][0].value = this.gmcData?.[
          "learningDevelopmentMin"
        ]
          ? this.gmcData["learningDevelopmentMin"]
          : this.gmcDefault.LEARNING_AND_DEVELOPMENT_MIN;
        this.packageOptions[i][1].value = this.gmcData?.["actionPlanMin"]
          ? this.gmcData["actionPlanMin"]
          : this.gmcDefault.ACTION_PLAN_MIN;
        this.packageOptions[i][2].value = this.gmcData?.["implementationMin"]
          ? this.gmcData["implementationMin"]
          : this.gmcDefault.IMPLEMENTATION_MIN;
      }
    }
  }
  onSwitchChange(batchIndex: number, questionIndex: number) {
    if (!this.packageOptions[batchIndex][questionIndex].switch) {
      this.packageOptions[batchIndex][questionIndex].value = 0;
    }
  }

  submit() {
    const gmcCriteria = {};
    this.batchOptions.forEach((batchName, index) => {
      const criteria = {
        learningDevelopmentMin: this.packageOptions[index][0].value,
        actionPlanMin: this.packageOptions[index][1].value,
        implementationMin: this.packageOptions[index][2].value,
      };
      gmcCriteria[batchName] = criteria;
    });

    this.companyService
      .updateCompanyGMCData(gmcCriteria)
      .subscribe((response: any) => {
        this.loading = false;

        Swal.fire({
          title: "Company update",
          text: response.status
            ? "Company GMC Configuration details updated"
            : "Company  GMC Configuration  details not updated",
          icon: response.status ? "success" : "error",
          showConfirmButton: false,
          timer: 2500,
        });
        this.router.navigate(["company/company-dashboard"]);
        this.modalRef.hide();
      });
  }
  preventNegativeInput(event: KeyboardEvent) {
    preventNegativeInput(event);
  }

  enforceMaxValue(event: any) {
    const maxValue = 100;
    enforceMaxValue(event, maxValue);
  }
  getColumnClass(): string {
    return this.batchOptions?.length ? "col-lg-10" : "col-lg-12";
  }
}
