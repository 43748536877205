<div class="container">
  <h4>Generate Certificate for {{ companyName }}.</h4>
  <div class="assets" *ngIf="!isAssetesUploaded">
    <div class="text-center">
      <h3>Upload Assets</h3>
    </div>
    <hr />
    <div class="row">
      <form [formGroup]="certificationDataForm">
        <div class="col-md-7">
          <div class="iconContainer">
            <h4 class="labelIcon text-center">Company Assets</h4>
            <hr />
            <label class="form-label">
              Click here to upload company logo.</label
            >
            <input
              type="file"
              class="gmiPrimaryBtn"
              name="myIcon"
              accept="image/x-png,image/jpeg"
              (change)="fileChangeEvent($event, 'companyLogo')"
            />
            <div class="error" *ngIf="!isIconValid">{{ iconSizeStatus }}</div>

            <div>
              <img
                *ngIf="isIconValid"
                class="dbImage"
                [src]="certificationDataForm.get('logo').value"
              />
            </div>
          </div>

          <div>
            <label class="form-label">
              Click here to upload program logo.</label
            >
            <input
              type="file"
              class="gmiPrimaryBtn"
              name="myIcon"
              accept="image/x-png,image/jpeg"
              (change)="fileChangeEvent($event, 'programLogo')"
            />
            <div class="error" *ngIf="!isProgramLogoValid">
              {{ programLogoSizeStatus }}
            </div>

            <div>
              <img
                *ngIf="isProgramLogoValid"
                class="dbImage"
                [src]="certificationDataForm.get('programLogo').value"
              />
            </div>
          </div>

          <div>
            <label class="form-label"> Choose Brand Colors.</label>
            <div class="strip-color">
              <div>
                <small>Choose Strip 1 Color</small>
                <br />
                <input
                  [cpSaveClickOutside]="true"
                  [style.background]="stripColorOne"
                  [(colorPicker)]="stripColorOne"
                  [cpOKButton]="true"
                />
              </div>
              <div>
                <small>Choose Strip 2 Color</small>
                <br />
                <input
                  [style.background]="stripColorTwo"
                  [(colorPicker)]="stripColorTwo"
                  [cpSaveClickOutside]="true"
                  [cpOKButton]="true"
                />
              </div>
            </div>
          </div>

          <div class="textInput m-10 w-100">
            <label for="certificateText" class="form-label"
              >Edit Certificate text</label
            >
            <textarea
              formControlName="certificateText"
              type="text"
              rows="5"
              maxlength="230"
              minlength="30"
              required
              class="w-100"
            >
            </textarea>
            <small class="note">Maximum character limit: 230</small>
          </div>

          <div class="textInput m-10 w-100">
            <label for="certificateIssueDate" class="form-label"
              >Edit Certificate Issue Date</label
            >
            <br />
            <input
              formControlName="certificateIssueDate"
              type="date"
              class="w-50 p-10"
              required
            />
          </div>

          <div formArrayName="headDetails">
            <p class="note m-top-30">
              Note: Select checkbox to select head.Max 4 heads can be
              selected(GMI + Company) .
            </p>
            <div
              *ngFor="
                let headGroup of certificationDataForm.get('headDetails')[
                  'controls'
                ];
                let i = index
              "
              class="headContainer"
            >
              <div class="flex">
                <div>
                  <h4>
                    Signature {{ i + 1 }} Details
                    <input
                      *ngIf="headGroup.valid"
                      class="headCheck"
                      style="margin-left: 15px"
                      [checked]="checkedIf(headGroup.value)"
                      (change)="
                        addSignature($event, headGroup.value, 'company', index)
                      "
                      type="checkbox"
                    />
                  </h4>
                </div>
                <div>
                  <button
                    mat-icon-button
                    matTooltip="Click to Remove"
                    class="iconbutton"
                    [disabled]="
                      certificationDataForm.get('headDetails').value.length == 1
                    "
                    (click)="removeHeadData(i)"
                  >
                    <!-- <mat-icon>delete</mat-icon> -->
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
              </div>

              <div [formGroupName]="i" class="headData">
                <div class="flex">
                  <div class="textInput">
                    <label for="name" class="form-label">Head Name</label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      formControlName="name"
                      placeholder="Name"
                      class="form-control"
                    />
                  </div>
                  <div class="textInput">
                    <label for="designation" class="form-label"
                      >Head Designation & Company Name</label
                    >
                    <input
                      type="text"
                      name="designation"
                      id="designation"
                      formControlName="designation"
                      placeholder="designation"
                      class="form-control"
                    />
                  </div>
                </div>
                <br />
                <div class="flex">
                  <div>
                    <label for="signature" class="form-label"
                      >Head Signatures</label
                    >
                    <input
                      type="file"
                      class="gmiPrimaryBtn"
                      name="signature"
                      id="signature"
                      accept="image/x-png"
                      (change)="onFileSelectedSigns($event, i)"
                    />
                  </div>
                  <div class="signatureBox">
                    <img
                      *ngIf="headGroup.value.signImage"
                      [src]="headGroup.value.signImage"
                      class="signImage"
                      [alt]="headGroup.value.signImage"
                    />
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </div>

          <div>
            <button
              type="button"
              [disabled]="
                certificationDataForm.get('headDetails').value.length > 3
              "
              class="newHeadButton"
              (click)="addNewHead()"
            >
              ADD NEW HEAD +
            </button>
          </div>

          <div class="text-center m-top-30">
            <button
              type="button"
              class="btn btn-secondary btn-newuser-clr"
              [disabled]="!certificationDataForm.valid || !isIconValid"
              (click)="submitFormData()"
            >
              Submit
            </button>
          </div>
        </div>
        <div class="col-md-5">
          <h4 class="text-center">GMI Heads</h4>
          <hr />
          <div
            class="m-bottom-30"
            *ngFor="
              let headData of certificationDataForm.get('gmiHeadDetails').value;
              let i = index
            "
          >
            <div class="flex">
              <div>
                <b>
                  <h4>Head Details {{ i + 1 }}</h4>
                </b>
              </div>
              <div>
                <input
                  type="checkbox"
                  name="gmiHead"
                  id="gmiHead"
                  class="headCheck"
                  (change)="
                    headData.name !== defaultCertificateName &&
                      addSignature($event, headData, 'gmi', index)
                  "
                  [disabled]="
                    headData.name == defaultCertificateName ? true : false
                  "
                  [checked]="
                    headData.name == defaultCertificateName
                      ? true
                      : false || checkedIf(headData)
                  "
                />
              </div>
            </div>

            <div class="flex">
              <div>
                <label for="">Head Name</label>
                <p class="headLabel">{{ headData.name }}</p>
              </div>
              <div>
                <label for="">Head Designation & Company</label>
                <p class="headLabel">{{ headData.designation }}</p>
              </div>
            </div>
          </div>

          <hr />
          <div class="m-top-30 text-center">
            <button
              type="button"
              class="preview-button"
              (click)="openPreviewModal(previewModal, '')"
              [disabled]="!disableButton()"
            >
              Preview Certificate
            </button>
            <br />
            <button
              type="button"
              class="m-top-30 btn btn-secondary btn-newuser-clr"
              (click)="isAssetesUploaded = true"
              [disabled]="!disableButton() || certificationDataForm.invalid"
            >
              Proceed
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="generateCertificate" *ngIf="isAssetesUploaded">
    <p (click)="isAssetesUploaded = false" style="cursor: pointer">Back</p>
    <h3 class="text-center">Upload Managers</h3>
    <div class="row">
      <div class="col-md-2">
        <div class="file-upload">
          <h5>Upload Excel File</h5>
          <label class="custom-file-upload" for="myFileInput">
            <input
              type="file"
              id="myFileInput"
              (change)="onFileChange($event)"
            />
            Choose File +
          </label>
          <br />

          <a
            href="https://media.greatmanagerinstitute.com/assests/certificates/sheets/sample+certitifed+managers.xlsx"
          >
            Download Sample
          </a>
        </div>
      </div>
      <div class="col-md-10">
        <div class="managerList">
          <h4>You are generating certificate for above managers.</h4>
          <mat-table
            [dataSource]="certifiedManagerList"
            class="mat-elevation-z4"
          >
            <!-- Manager Name Column -->
            <ng-container matColumnDef="managerName">
              <mat-header-cell *matHeaderCellDef>
                Manager Name
              </mat-header-cell>
              <mat-cell
                class="text-center"
                *matCellDef="let element; let i = index"
              >
                {{ element.managerName }}
                <p
                  *ngIf="element?.managerName.length > 22"
                  class="nameLongError"
                >
                  Name is too long. Certificate will not generate.
                </p>
              </mat-cell>
            </ng-container>

            <!-- Manager Email ID Column -->
            <ng-container matColumnDef="managerEmailId">
              <mat-header-cell *matHeaderCellDef>
                Manager Email ID
              </mat-header-cell>
              <mat-cell class="text-center" *matCellDef="let element">
                {{ element.managerEmailId }}
              </mat-cell>
            </ng-container>

            <!-- personsInCC Column -->
            <ng-container matColumnDef="personsInCC">
              <mat-header-cell *matHeaderCellDef>
                Persons in CC
              </mat-header-cell>
              <mat-cell
                *matCellDef="let element"
                [innerHTML]="splitValues(element.personsInCC)"
              >
              </mat-cell>
            </ng-container>

            <!-- previewCertificate -->
            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
              <mat-cell *matCellDef="let element; let i = index">
                <!-- <mat-icon
                  (click)="openPreviewModal(previewModal, element.managerName)"
                  >visibility</mat-icon
                > -->
                <i
                  class="fa fa-eye"
                  (click)="openPreviewModal(previewModal, element.managerName)"
                ></i>
                <!-- <mat-icon (click)="deleteManager(i)">delete</mat-icon> -->
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          </mat-table>
          <mat-paginator
            showFirstLastButtons
            [pageSizeOptions]="[10, 20, 30]"
          ></mat-paginator>
        </div>
      </div>
    </div>
    <hr />
    <div class="email-template">
      <h3 class="text-center">Email Operation</h3>
      <div class="row">
        <div class="col-md-7">
          <!-- <angular-editor
            [config]="editorConfig"
            [placeholder]="'Enter text here...'"
            [(ngModel)]="emailData.content"
          >
          </angular-editor> -->
          <!-- <div class="editor">
            <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
            </ngx-editor-menu>
            <ngx-editor
              [ngModelOptions]="{ standalone: true }"
              [editor]="editor"
              [placeholder]="'Enter text here...'"
              [(ngModel)]="emailData.content"
            >
            </ngx-editor>
          </div> -->
          <app-email-editor [(content)]="emailData.content"></app-email-editor>
          <!-- (contentChange)="onContentChange($event)" -->
        </div>
        <div class="col-md-5">
          <div class="form-container">
            <div class="placeholder-container">
              <h4><b>Placeholders:</b></h4>
              <ul>
                <li>%MANAGER_NAME%</li>
                <li>%COMPANY_NAME%</li>
                <!-- <li>%CERTIFICATE_LINK%</li> -->
                <li>%PACKAGE_EXPIRY_DATE%</li>
                <li>%CERTIFICATE_EXPIRY_DATE%</li>
              </ul>
            </div>
            <div class="input-group">
              <label for="from">From:</label>
              <input
                type="text"
                name="from"
                id="from"
                disabled
                [(ngModel)]="emailData.from"
              />
            </div>
            <div class="input-group">
              <label for="subject">Mail Subject:</label>
              <input
                type="text"
                name="subject"
                id="subject"
                [(ngModel)]="emailData.subject"
              />
            </div>
          </div>
          <div class="text-center">
            <button
              type="button"
              style="margin-top: 25px; color: white"
              class="btn btn-secondary btn-newuser-clr"
              (click)="openCrtificatePopup(sendOrSchdule)"
              [disabled]="certifiedManagerList.data.length == 0"
            >
              Generate Certificate
            </button>
            <br />
            <button
              type="button"
              style="margin-top: 25px"
              class="preview-button"
              (click)="downloadSample()"
              [disabled]="certifiedManagerList.data.length == 0"
            >
              Download Sample
            </button>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="scheduled-emails">
      <h3 class="text-center">Scheduled Certificates</h3>
      <app-schdule-send-certificates
        [seCompanyId]="seCompanyId"
      ></app-schdule-send-certificates>
    </div>
  </div>
</div>
<ng-template #previewModal>
  <div class="modal-content custom-modal-lg" id="custom-modal">
    <div class="modal-header">
      <h4 style="font-size: 22px" class="modal-title pull-left">Preview</h4>
      <button
        type="button "
        class="close pull-right"
        aria-label="Close "
        (click)="closeModal()"
      >
        <span aria-hidden="true ">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="certificate">
        <img
          [src]="certificate"
          width="1000px"
          height="800px"
          class="img-responsive"
        />
        <div class="certificate-strip">
          <div
            class="strip-one"
            [style.background]="stripColorOne"
            [style.border-right]="'2px solid ' + stripColorTwo"
          ></div>
          <div class="strip-two" [style.background]="stripColorTwo"></div>
          <div class="strip-three" [style.background]="stripColorTwo"></div>
          <div class="strip-four" [style.background]="stripColorTwo"></div>
        </div>
        <p
          [ngClass]="
            managerName && managerName.length > 15 ? 'nameLong' : 'name'
          "
        >
          {{ managerName ? managerName : "NAME SURNAME" }}
        </p>
        <div>
          <img
            alt="companyLogo"
            [src]="certificationDataForm.get('logo').value"
            class="companyLogo"
          />

          <img
            *ngIf="certificationDataForm.get('programLogo').value"
            alt="programLogo"
            [src]="certificationDataForm.get('programLogo').value"
            class="programLogo"
          />
        </div>
        <div class="certificate-text">
          <p>{{ this.certificationDataForm.value.certificateText }}</p>
        </div>
        <div class="certificate-signatures">
          <div class="certificate-signatures-flex">
            <div
              *ngFor="
                let data of signatures | sortby : 'index' : 'asc';
                let i = index
              "
            >
              <div class="signature-data">
                <img class="signPreview" src="{{ data.signImage }}" />
                <span>{{ data.name | uppercase }}</span>
                <small>{{ data.designation }}</small>
              </div>
            </div>
          </div>
          <p
            class="text-center"
            style="
              font-weight: bold;
              font-size: 11px;
              font-style: italic;
              margin-top: 5px;
              color: gray;
            "
          >
            The certificate issue date is
            {{
              this.certificationDataForm.value.certificateIssueDate
                ? (this.certificationDataForm.value.certificateIssueDate
                  | date : "d MMMM y")
                : "DD MM YYYY"
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #sendOrSchdule>
  <div class="modal-header">
    <h4 class="modal-title">Send or Schedule Certificate Dispatch</h4>
    <button type="button" class="close" (click)="closeModal()">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="!choosedOption">
    <p class="message m-10">
      Choose whether to send certificates now or schedule them:
    </p>
    <div class="confirmation-modal m-10">
      <button
        type="button"
        class="btn btn-primary m-10"
        (click)="sendOrSchedule('send')"
      >
        Send Certificates
      </button>
      <button
        type="button"
        class="btn btn-secondary m-10"
        (click)="sendOrSchedule('schedule')"
      >
        Schedule Certificates
      </button>
    </div>
  </div>
  <div class="modal-body" *ngIf="choosedOption && sendCertificate">
    <p class="message m-10">
      Are you sure you want to send the certificates now?
    </p>
    <div class="confirmation-modal m-10">
      <button
        type="button"
        class="btn btn-primary m-10"
        (click)="generateCertificate()"
      >
        Yes
      </button>
      <button
        type="button"
        class="btn btn-secondary m-10"
        (click)="choosedOption = false; sendCertificate = false"
      >
        Cancel
      </button>
    </div>
  </div>
  <div class="modal-body" *ngIf="choosedOption && scheduleCertificate">
    <p class="message m-10">Schedule Certificate Dispatch Time</p>
    <div class="textInput">
      <label for="name" class="form-label">Dispatch Time:</label>
      <input
        [owlDateTimeTrigger]="dt10"
        [owlDateTime]="dt10"
        [(ngModel)]="scheduledFor"
        placeholder="Click to choose time"
        class="form-control"
      />
      <!-- <owl-date-time [pickerMode]="'dialog'" #dt10></owl-date-time> -->
      <owl-date-time #dt10></owl-date-time>
    </div>
    <div class="confirmation-modal m-10">
      <button
        type="button"
        class="btn btn-primary m-10"
        (click)="generateCertificate()"
      >
        Schedule Certificate Dispatch
      </button>
      <button
        type="button"
        class="btn btn-secondary m-10"
        (click)="choosedOption = false; scheduleCertificate = false"
      >
        Cancel
      </button>
    </div>
  </div>
</ng-template>
