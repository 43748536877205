import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { getTemplate } from "./launch-survey-questions";
import { CompanyServices } from "../company.service";
import { dayAdd, isEmpty } from "../../../config/utils";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import swal from "sweetalert2";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { FormControl, NgForm, Validators } from "@angular/forms";
import * as XLSX from "xlsx";
import { ActivatedRoute, Router } from "@angular/router";
type AOA = any[][];
import moment from "moment";
// import io from "socket.io-client";
import io from "socket.io-client";
// import { io } from "socket.io-client";
import { environment } from "../../../environments/environment";
import { DataService } from "../data.service";
import { DatePipe } from "@angular/common";
import { FormModel } from "../survey-email-mgmt/survey-email-mgmt.component";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { Editor, Toolbar } from "ngx-editor";
@Component({
  selector: "app-launch-survey",
  templateUrl: "./launch-survey.component.html",
  styleUrls: ["./launch-survey.component.css"],
})
export class LaunchSurveyComponent implements OnInit {
  today = new Date();
  @ViewChild("fileInput") fileInput;
  @ViewChild("fileInputManager") fileInputManager: any;
  private serverUrl = environment.apiURl;
  private socket: any = io(this.serverUrl, { reconnection: true });
  // withCredentials: false,
  // transports: ["websocket"],
  // });
  modalClosedBySubmit: boolean = false;
  endtoday = dayAdd(Date.now(), "day", 1);
  numberOfQuestionsEachDay: number = 1;
  MAX_QUESTIONS = 35;
  MIN_VALUE = 1;
  // getTemplate("textAreaType", "SURVEY_TEMPLATE_1");
  radioQuestions: any = [];
  dropDownQuestions: any = [];
  textAreaQuestions: any = [];
  endDate = "";
  survey = [];
  modalRef: BsModalRef;
  startDate = "";
  isEditSurvey = false;
  hide: boolean = false;
  apOnlySurvey: boolean = false;
  sameEmail: boolean = false;
  text = "Select All";
  endError = true;
  startError = true;
  isMobileDisabled = false;
  surveyName: string;
  correctedStartDate: string;
  correctedEndDate: string;
  managerEmail: string;
  hrEmailAddress: string;
  hrsName: string;
  startDateMin: any;
  checkall: any;
  count = 0;
  emailManager = false;
  newRespondants = [];
  respondent = [];
  loading: boolean = true;
  threeRespondentSelect = true;
  errorsInData = [];
  errorsInRespondentData = [];
  excelType = false;
  excelHeader = false;
  touched = false;
  headers = [];
  headerAsObject = [];
  excelSheetData = [];
  apCheckBox: boolean = false;
  maxDate = dayAdd(Date.now(), "month", 3);
  fileName: string = "SheetJS.xlsx";
  uploadedFileName: string;
  finalDataInArray = [];
  excelSheetPlayGround: boolean = false;
  hrEmailSameWithManager: boolean = false;
  managers = [];
  completedCount: number = 0;
  totalSurveysCount: number = 0;
  editSurveyType: string = "updated";
  displayedColumns: string[] = ["name", "startDate", "endDate", "edit", "add"];
  private paginator: MatPaginator;
  private sort: MatSort;
  surveyType = "Pre";
  surveyTypeArray = ["Pre", "Pilot", "Interim", "RealTimeFeedback"];
  surveyTypeActionPlan = ["Post", "Recertification"];
  toTypes: string[] = ["All participants"];
  placeholder: string;
  hoveredIndex: number | null = null;
  isValidData: boolean = true;
  formData: FormModel = {
    name: "",
    bcc: "",
    to: "",
    subject: "",
    emailType: "",
    content: "",
    scheduleAt: "",
  };
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: false,
    height: "auto",
    minHeight: "500px",
    maxHeight: "auto",
    width: "auto",
    minWidth: "0",
    translate: "true",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "Enter text here...",
    defaultParagraphSeparator: "",
    defaultFontName: "",
    defaultFontSize: "",
    fonts: [
      { class: "arial", name: "Arial" },
      { class: "times-new-roman", name: "Times New Roman" },
      { class: "calibri", name: "Calibri" },
      { class: "comic-sans-ms", name: "Comic Sans MS" },
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
    uploadUrl: "v1/image",
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: "top",
    toolbarHiddenButtons: [["insertImage", "insertVideo"]],
  };
  allNewManagers = [];
  uploadTypeOnEdit: string = "manager";
  latestRespondentData = [];
  respondentDataOnEdit = [];

  pisDemographics: string[] = [];
  selectedDemographicOption: string[] = [];
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  dataSource = new MatTableDataSource();
  surveyId = "";
  participants = [];
  mgrId = "";
  spocs = [];
  spocEmail = "";
  cc = "";
  bcc = "";
  startDateDisabled = false;
  subject = "Survey Response Rate";
  content = "";
  updateStatus: Boolean = false;
  surveyCount: number = 0;
  response_rate = 0;
  supervisor: boolean = false;
  isAutoGeneratePswrd: boolean = false;
  researchSurveyGroup: boolean = true;
  magicLinkStatus: boolean = false;
  completed_participants = 0;
  total_participants = 0;
  singleSurvey: any = {};
  end_date = "";
  excelHeaderError: string = "";
  configHtml = {
    editable: true,
    spellcheck: true,
    height: "auto",
    minHeight: "0",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "Enter text here...",
    toolbar: [
      [
        "bold",
        "italic",
        "underline",
        "strikeThrough",
        "superscript",
        "subscript",
      ],
      ["fontName", "fontSize", "color"],
      [
        "justifyLeft",
        "justifyCenter",
        "justifyRight",
        "justifyFull",
        "indent",
        "outdent",
      ],
      [],
      [
        "paragraph",
        "blockquote",
        "removeBlockquote",
        "horizontalLine",
        "orderedList",
        "unorderedList",
      ],
    ],
  };
  isSurveyDataloaded: any = false;
  limit: number = 2000;
  skip: number = 0;

  // REALTIME
  isRealTimeFeedback: boolean = false;
  isRtfSelected: boolean = false;
  isRtfSurveyType: string = "";
  weeklyOffDays = new FormControl("", [Validators.required]);
  questionDays = new FormControl("", [Validators.required]);
  selectedTimes = new FormControl("", [Validators.required]);
  weekDays: any = [
    { day: "Monday", backgroundColor: "#f1f3f4", color: "#80868b" },
    { day: "Tuesday", backgroundColor: "#f1f3f4", color: "#80868b" },
    { day: "Wednesday", backgroundColor: "#f1f3f4", color: "#80868b" },
    { day: "Thursday", backgroundColor: "#f1f3f4", color: "#80868b" },
    { day: "Friday", backgroundColor: "#f1f3f4", color: "#80868b" },
    { day: "Saturday", backgroundColor: "#f1f3f4", color: "#80868b" },
    { day: "Sunday", backgroundColor: "#f1f3f4", color: "#80868b" },
  ];
  weeksSelected: boolean = false;
  daysSelected: boolean = false;
  monthSelected: boolean = false;
  selectedDays: string[] = [];
  companyId: any;
  companyData: any;
  surveyGroupId: any;
  isRealTimePause: boolean = false;
  resumeSurveyArray = [];
  isRtfSurveyAlreadyPresent: boolean = false;
  repeatType: string = "day";
  repeatAfter = 1;
  companySurveyGroupId: number;
  masterQuesionairId: number;
  surveyGroupMasterQuesionairId: number;
  editor: Editor;
  toolbar: Toolbar = [
    ["bold", "italic"],
    ["underline", "strike"],
    ["code", "blockquote"],
    ["ordered_list", "bullet_list"],
    [{ heading: ["h1", "h2", "h3", "h4", "h5", "h6"] }],
    ["link", "image"],
    ["text_color", "background_color"],
    ["align_left", "align_center", "align_right", "align_justify"],
  ];
  constructor(
    private companyService: CompanyServices,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private router: Router,
    private _companyShareData: DataService,
    public datePipe: DatePipe
  ) {}

  ngOnInit() {
    this.editor = new Editor();

    let data = { surveyId: this.route.snapshot.params["id"] };
    if (this.route.snapshot.params["id"]) {
      this.isEditSurvey = true;
      this.getSurveysWiseManagers(data);
    } else {
      this.getManagers([]);
    }
    this.getSurveyQuestionsTemplate();
    this.socket.on("LAUNCH_SURVEY_COUNTER", (data) => {
      if (!data.status) {
        this.editSurveyType = "launched";
        this.totalSurveysCount = data.totalCount;
        this.surveyCount = data.count;
        this.completedCount = (data.count / data.totalCount) * 100;
      } else {
        this.updateStatus = true;
      }
    });

    this.socket.on("UPDATE_SURVEY_COUNTER", (data) => {
      if (!data.status) {
        this.editSurveyType = "updated";
        this.totalSurveysCount = data.totalCount;
        this.surveyCount = data.count;
        this.completedCount = (data.count / data.totalCount) * 100;
      } else {
        this.updateStatus = true;
      }
    });
    this.surveyType = "Pre";
    const companyData = this._companyShareData.getToken();
    if (companyData) {
      this.getCompanyData(companyData);
    }
  }
  getCompanyData(companyData) {
    this.companyService
      .getCompanyProfile({ companyId: companyData.comapnyId })
      .subscribe(
        (res) => {
          // next: (res) => {
          if (res.status) {
            this.companyData = res.data;
            this.companyId = this.companyData.surveyEngineCompanyId;
            const companySettings = this.companyData.companySettings;
            this.pisDemographics = res.pisDemographics;
            this.selectedDemographicOption =
              companySettings.responseRateSelectedDemographics;
            this.isRealTimeFeedback =
              companySettings.sendRealTimeFeedbackSurvey;
          }
        }
        //  (err) => console.log(err),
      );
  }

  fetchRTFExistingData(companySurveyGroupId: number) {
    this.companyService
      .fetchRTFExistingData({ companySurveyGroupId })
      .subscribe((response) => {
        // console.log(response);
        this.repeatAfter = response.data.repeat_frequency;
        this.numberOfQuestionsEachDay = response.data.questions_per_day;
        this.repeatType = response.data.repeat_type;
        this.isValidData = false;
        if (response.data.repeat_type == "day") {
          this.daysSelected = true;
        } else if (response.data.repeat_type == "week") {
          this.weeksSelected = true;
          this.selectedDays = response.data.week_days.split(",");
          for (let i = 0; i < this.weekDays.length; i++) {
            const dayInfo = this.weekDays[i];

            if (this.selectedDays.includes(dayInfo.day)) {
              this.weekDays[i].backgroundColor = "#1a73e8";
              this.weekDays[i].color = "#ffffff";
            }
          }
        } else {
          this.monthSelected = true;
        }
      });
  }
  redirectToSurveyPage() {
    this.modalRef.hide();
    this.modalRef = null;
    this.surveyCount = 0;
    this.totalSurveysCount = 0;
    this.completedCount = 0;
    this.updateStatus = false;
    this.router.navigate(["/company/company-dashboard/survey-list"]);
  }

  openParticipantModel(template, surveyId) {
    this.singleSurvey = {};
    this.modalRef = this.modalService.show(template);
    this.companyService.addModal(this.modalRef);
    this.singleSurvey = this.managers.filter(
      (manager) => manager.surveyId == surveyId
    )[0];
    this.singleSurvey.deletedRespondents = [];
  }

  openAddManagerModal(template) {
    this.modalRef = this.modalService.show(template, {
      class: "modal-lg",
      backdrop: "static",
      keyboard: false,
    });

    this.modalRef.onHide.subscribe(() => {
      // Reset the allNewManagers array if the modal was closed by the close button and not by submitting

      if (!this.modalClosedBySubmit) {
        this.allNewManagers = [];
        this.errorsInData = [];
        this.excelHeader = false;
        this.uploadedFileName = "";
        this.latestRespondentData = [];
        this.respondentDataOnEdit = [];
      }
      this.modalClosedBySubmit = false;
    });
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getSurveysWiseManagers(data) {
    this.companyService.getSurveyById(data).subscribe((res) => {
      // console.log(res);
      if (res.status) {
        this.resumeSurveyArray = res.data;
        this.isRealTimePause = res.data[0].isRtfSurveyPause;
        this.surveyType = res.data[0].surveyType;
        this.startDateDisabled = true;
        this.startDate = res.data[0].startDate;
        this.surveyName = res.data[0].surveyName;
        this.endDate = res.data[0].endDate;
        this.surveyGroupId = res.data[0]._id;
        this.companySurveyGroupId = res.data[0].seCompanySurveyGroupId;
        this.magicLinkStatus = res.data[0].magicLinkStatus;
        this.surveyGroupMasterQuesionairId =
          res?.data[0]?.masterQuestionnaireId;
        if (new Date(this.endDate) < new Date(Date.now())) {
          this.endError = true;
        } else {
          this.endError = false;
        }

        this.managers = res.data;
        this.isAutoGeneratePswrd = this.managers.every(
          (manager) => manager?.personalSettings?.optedForAutoPassword === true
        );
        this.isMobileDisabled = this.managers.every(
          (manager) => manager?.personalSettings?.isMobileDisabled === true
        );
        this.managers.forEach((manager) => {
          if (manager.surveyType == "RealTimeFeedback") {
            this.isRtfSurveyType = manager.surveyType;
          }
        });
        if (this.isRtfSurveyType == "RealTimeFeedback") {
          this.fetchRTFExistingData(this.companySurveyGroupId);
        }
      }
    });
  }
  /**
   * @param  {} template
   */
  updateSurvey(template) {
    // console.log(this.surveyType,"ts")
    this.setRespondentUpdatedData();
    let data = {
      startDate: moment(this.startDate).format("YYYY-MM-DD"),
      endDate: moment(this.endDate).format("YYYY-MM-DD"),
      managers: this.managers,
      companySurveyId: this.managers[0]._id,
      surveyType: this.surveyType,
      surveyName: this.surveyName,
      apCheckBox: this.surveyType == "Post" ? true : this.apCheckBox,
      responseRateSelectedDemographics: this.selectedDemographicOption,
      newManagers: this.allNewManagers,
      surveyGroupId: this.surveyGroupId,
      isAutoGeneratePswrd: this.isAutoGeneratePswrd,
      isMobileDisabled: this.isMobileDisabled,
      masterQuestionnaireId: this.surveyGroupMasterQuesionairId,
      magicLinkStatus: this.magicLinkStatus,
    };
    if (data.surveyType == "RealTimeFeedback") {
      let cleanHtml = this.formData.content.replace(/&#10;/g, " ");
      data["repeatAfter"] = this.repeatAfter;
      data["repeatType"] = this.repeatType; //day, week, month
      // this.datePipe.transform(this.startDate, "dd")
      if (this.repeatType == "week") {
        data["scheduleDays"] = this.selectedDays.join(",");
      }

      data["selectedTimes"] = this.formData.scheduleAt;
      data["numberOfQuestionsEachDay"] = this.numberOfQuestionsEachDay;
      data["emailContent"] = cleanHtml;
      data["emailSubject"] = this.formData.subject;
      data["companySurveyGroupId"] = this.companySurveyGroupId;
      data["masterQuestionnaireId"] = this.masterQuesionairId;
    }
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
    });
    this.companyService.addModal(this.modalRef);
    // console.log(data , 'data')
    this.companyService.updateSurvey(data).subscribe((res) => {
      if (res.status) {
        // swal({
        //   title: "Survey Status",
        //   text: "Survey is updating please see the status on survey list card",
        //   type: "success",
        //   showConfirmButton: true,
        // });
        // this.router.navigate(["/company/company-dashboard/survey-list"]);
        // this.getSurvey();
      } else {
        swal.fire({
          title: "Survey Status",
          text: res?.message || "Something went wrong!",
          icon: "error",
          showConfirmButton: true,
        });
      }
    });
  }

  setRespondentUpdatedData() {
    if (this.respondentDataOnEdit?.length > 0) {
      this.managers.forEach((manager) => {
        manager.deletedRespondents = [];
        manager.newlyAddedParticipants = [];
        this.respondentDataOnEdit.forEach((participant) => {
          if (manager.managerEmail == participant.managerEmail) {
            let addObj = {
              name: participant.respondentName,
              emailId: participant.respondentEmail,
              respondentType: participant.respondentType,
            };
            if (participant.type == "Add") {
              manager.participants.push(addObj);
              manager.newlyAddedParticipants.push(addObj);
            }
            if (participant.type == "Delete") {
              manager.participants = manager.participants.filter(
                (p) => p.emailId !== participant.respondentEmail
              );
              addObj["seRespondantId"] = participant?.seRespondantId;
              addObj["participantId"] = participant?.participantId;

              manager.deletedRespondents.push(addObj);
            }
          }
        });
      });
    }
  }

  onSubmit1(form: NgForm) {
    if (
      form.value.emailId.toLowerCase() ==
      this.singleSurvey.managerEmail.toLowerCase()
    ) {
      this.emailManager = true;
    } else {
      this.emailManager = false;
      this.hide = true;
      let addRespondent = {
        name: form.value.name,
        emailId: form.value.emailId
          ? form.value.emailId.toLowerCase()
          : `test_${form.value.empCompanyId}@gmi.com`,
        respondentType: form.value.respondentType,
      };
      if (form.value.empCompanyId) {
        (addRespondent["empCompanyId"] = form.value.empCompanyId),
          (addRespondent["empDob"] = form.value.empDob);
      }
      let existing = this.singleSurvey.participants.filter((respondant) => {
        return (
          respondant.emailId.toLowerCase() ==
            form.value.emailId.toLowerCase() ||
          (respondant.emailId.toLowerCase() ==
            form.value.emailId.toLowerCase() &&
            respondant.respondentType == form.value.respondentType)
        );
      });
      if (existing.length > 0) {
        this.sameEmail = true;
      } else {
        // this.singleSurvey.participants.push(addRespondent);
        let index = this.managers.findIndex(
          (man) => man.surveyId == this.singleSurvey.surveyId
        );
        if (index !== -1) {
          this.managers[index].participants.push(addRespondent);
          this.managers[index].newlyAddedParticipants.push(addRespondent);
        }
        this.sameEmail = false;
      }
    }
    form.reset();
  }

  deleteParticipant(emailId) {
    let index = this.managers.findIndex(
      (manager) => manager.surveyId == this.singleSurvey.surveyId
    );
    if (index !== -1) {
      let index1 = this.managers[index].participants.findIndex(
        (survey) => survey.emailId == emailId
      );
      if (index1 !== -1) {
        if (this.managers[index].participants[index1].participantId) {
          this.managers[index].deletedRespondents.push(
            this.managers[index].participants[index1]
          );
          this.managers[index].participants.splice(index1, 1);
        } else {
          this.managers[index].participants.splice(index1, 1);
        }
      }
    }
  }

  getManagers(managers, type: string = "any") {
    let data = {
      managerEmails: managers,
      excelFilter: managers.length > 0 ? true : false,
      ...(managers.length > 0 && {
        limit: 2000,
        skip: 0,
      }),
    };
    // this.loading = true;
    this.companyService.getManagers(data).subscribe((res) => {
      // this.loading = false;
      if (res.status) {
        switch (type) {
          case "manager":
            const existingManagerEmails = this.managers.map(
              (manager) => manager.managerEmail
            );

            this.allNewManagers = res.data.filter(
              (manager) => !existingManagerEmails.includes(manager.email)
            );
            break;
          case "respondent":
            this.latestRespondentData = res.data;
            this.mapRespondentData();

            break;
          default:
            this.managers = res.data;
        }

        // if (this.isRealTimeFeedback && this.isRtfSelected) {
        //   const filteredManagers = this.managers.filter(
        //     (managers) => managers.scores !== null
        //   );
        //   this.managers = filteredManagers;
        // }
      }
    });
  }

  mapRespondentData() {
    this.managers.forEach((manager) => {
      this.latestRespondentData.forEach((updatedManager) => {
        if (manager?.managerEmail == updatedManager?.email) {
          let existingParticipants = manager?.participants;
          let updatedParticipants = updatedManager?.latestParticipants;
          existingParticipants.forEach((existingParticipant) => {
            const found = updatedParticipants.some(
              (updatedParticipant) =>
                updatedParticipant.emailId === existingParticipant.emailId
            );
            // only delete respondents with survey submission status false
            if (!found && !existingParticipant.surveySubmissionStatus) {
              let addObj = {
                managerName: manager?.managerName,
                managerEmail: manager?.managerEmail,
                respondentName: existingParticipant.name,
                respondentEmail: existingParticipant.emailId,
                respondentType: existingParticipant.respondentType,
                seRespondantId: existingParticipant.seRespondantId,
                participantId: existingParticipant.participantId,
                type: "Delete",
              };
              this.respondentDataOnEdit.push(addObj);
            }
          });

          updatedParticipants.forEach((updatedParticipant) => {
            const found = existingParticipants.some(
              (existingParticipant) =>
                existingParticipant.emailId === updatedParticipant.emailId
            );

            if (!found) {
              let addObj = {
                managerName: manager?.managerName,
                managerEmail: manager?.managerEmail,
                respondentName: updatedParticipant.name,
                respondentEmail: updatedParticipant.emailId,
                respondentType: updatedParticipant.respondentType,
                type: "Add",
              };
              this.respondentDataOnEdit.push(addObj);
            }
          });
        }
      });
    });
  }

  loadMoreManager() {
    let data = {
      limit: this.limit,
      skip: this.skip + this.limit,
    };
    this.skip += this.limit;

    this.companyService.getManagers(data).subscribe((res) => {
      // this.loading = false;
      if (res.status) {
        this.managers.push(...res.data);
      }
    });
  }
  checkStartDate() {
    if (this.startDate !== "") {
      this.startError = false;
      this.correctedStartDate = this.startDate.toString().substring(3, 15);
      this.endtoday = dayAdd(this.startDate, "day", 1);
      this.maxDate = dayAdd(this.startDate, "month", 3);
    } else {
      this.startError = true;
    }
  }

  checkEndDate() {
    if (this.endDate !== "") {
      this.endError = false;
      this.correctedEndDate = this.endDate.toString().substring(3, 15);
      this.startDateMin = dayAdd(this.endDate, "day", -1);
    } else {
      this.endError = true;
    }
  }
  changeSurveyName() {
    this.endError = false;
  }

  numberOfQuestionChange(event?) {
    // this.numberOfQuestionsEachDay = event.target.value;
    if (this.numberOfQuestionsEachDay > this.MAX_QUESTIONS) {
      this.numberOfQuestionsEachDay = this.MAX_QUESTIONS;
    }
  }

  checkUncheckAll() {
    if (this.checkall) {
      this.checkall = true;
      this.text = "Unselect All";
    } else {
      this.checkall = false;
      this.text = "Select All";
    }
    if (this.supervisor) {
      this.managers.forEach((participant) => {
        participant.checked = this.checkall;
        if (!participant.superVisorSurvey && participant.superVisorAdded) {
          this.onChange(participant, participant.checked);
        }
      });
    } else {
      this.managers.forEach((participant) => {
        participant.checked = this.checkall;
        if (!participant.surveyOngoing && participant.participants) {
          if (
            this.surveyType !== "Post" ||
            (this.surveyType == "Post" && participant.actionPlans)
          ) {
            this.onChange(participant, participant.checked);
          }
        }
      });
    }
  }

  updateCheckall(participant: any, isChecked: boolean) {
    let userTotal = this.managers.length;
    if (isChecked) {
      this.count++;
      if (!this.respondent.includes(participant)) {
        this.respondent.push(participant);
      }
    } else {
      this.count--;
      let index = this.respondent.indexOf(participant);
      this.respondent.splice(index, 1);
    }
    if (userTotal == this.count) {
      this.checkall = true;
      this.text = "Unselect All";
    } else {
      this.checkall = false;
      this.text = "Select All";
    }
    // console.log(this.respondent , 'participant')
  }

  onChange(participant: any, isChecked: boolean) {
    if (isChecked) {
      this.count++;
      if (!this.respondent.includes(participant)) {
        this.respondent.push(participant);
      }
    } else {
      this.count--;
      let index = this.respondent.indexOf(participant);
      this.respondent.splice(index, 1);
    }
    // console.log(this.respondent , 'respondents')
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    this.companyService.addModal(this.modalRef);
  }

  respondentData(template) {
    if (this.apOnlySurvey) {
      this.aplaunchSurvey();
      // this.getSurvey();
    } else {
      let data = {
        surveyName: this.surveyName,
        managers: this.respondent,
        startDate: moment(this.startDate).format("YYYY-MM-DD"),
        endDate: moment(this.endDate).format("YYYY-MM-DD"),
        apCheckBox: this.apCheckBox,
        supervisor: this.supervisor,
        surveyType: this.surveyType,
        isMobileDisabled: this.isMobileDisabled,
        isAutoGeneratePswrd: this.isAutoGeneratePswrd,
        responseRateSelectedDemographics: this.selectedDemographicOption,
        researchSurveyGroup: this.researchSurveyGroup,
        magicLinkStatus: this.magicLinkStatus,
      };

      if (data.surveyType == "RealTimeFeedback") {
        let cleanHtml = this.formData.content.replace(/&#10;/g, " ");
        data["repeatAfter"] = this.repeatAfter;
        data["repeatType"] = this.repeatType; //day, week, month
        this.datePipe.transform(this.startDate, "dd");
        if (this.repeatType == "week") {
          data["scheduleDays"] = this.selectedDays.join(",");
        }
        // if (this.repeatType == "month") {
        //   data["scheduleDays"] = this.selectedDays.join(",");
        // }
        data["selectedTimes"] = this.formData.scheduleAt;
        data["RealTimeFeedback"] = this.companyData.surveyEngineCompanyId; //need to check if needed or not
        data["isRtfSurveyAlreadyPresent"] = this.isRtfSurveyAlreadyPresent; //need to check if needed or not
        data["numberOfQuestionsEachDay"] = this.numberOfQuestionsEachDay;
        data["emailContent"] = cleanHtml;
        data["emailSubject"] = this.formData.subject;
      }

      this.modalRef.hide();
      this.modalRef = null;

      // this.loading = true;
      this.modalRef = this.modalService.show(template, {
        backdrop: "static",
        keyboard: false,
      });
      this.companyService.addModal(this.modalRef);
      this.companyService.addSurveyDetails(data).subscribe((res) => {
        if (res.status) {
          swal.fire({
            title: "Survey Status",
            text: "Survey is launched Successfully!",
            icon: "success",
            showConfirmButton: true,
          });
          // .then(function (isConfirm) {
          //   if (isConfirm) {
          // this.router.navigate(["/company/company-dashboard/survey-list"]);
          //   }
          // });
          // this.getSurvey();
        } else {
          swal.fire({
            title: "Survey Status",
            text: res.message || "Something went wrong!",
            icon: "error",
            showConfirmButton: true,
          });
        }
      });
      this.loading = false;
    }
  }

  aplaunchSurvey() {
    let data = {
      managers: this.respondent,
      startDate: this.startDate,
      endDate: this.endDate,
    };
    this.loading = true;
    this.companyService.launchOnlyAp(data).subscribe((res) => {
      if (res.status) {
        swal.fire({
          title: "Adding Survey",
          text: "Action Plan feedback survey successfully launched",
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });

        this.loading = false;

        this.modalRef.hide();
        this.modalRef = null;
      } else {
        swal.fire({
          title: "Survey Status",
          text: "Something went wrong!",
          icon: "error",
          showConfirmButton: true,
        });
      }
    });
  }

  extendAllSurvey(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    this.companyService.addModal(this.modalRef);
  }

  getExtension(path) {
    var basename = path.split(/[\\/]/).pop(), // extract file name from full path ...
      // (supports `\\` and `/` separators)
      pos = basename.lastIndexOf("."); // get last position of `.`

    if (basename === "" || pos < 1)
      // if file name is empty or ...
      return ""; //  `.` not found (-1) or comes first (0)

    return basename.slice(pos + 1); // extract extension ignoring `.`
  }

  onFileChange(evt: any, type = "change") {
    this.touched = true;
    this.excelSheetData = [];
    this.uploadedFileName = "";
    //console.log(this.touched , 'touched')
    //console.log(evt.srcElement.files[0].name , 'fj');
    /* wire up file reader */
    let ext = this.getExtension(evt.srcElement.files[0].name);
    //console.log(ext)
    if (ext == "csv" || ext == "xls" || ext == "xlsx") {
      //console.log('dd')
      this.excelType = true;
    } else {
      this.excelType = false;
    }
    const target: DataTransfer = <DataTransfer>evt.target;

    if (target.files.length !== 1) throw new Error("Cannot use multiple files");
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      try {
        var wb: XLSX.WorkBook = XLSX.read(bstr, { type: "binary" });
      } catch (Error) {
        if (Error.name == "Error") {
          alert(Error.message);
          (<HTMLInputElement>document.getElementById("fileSelect")).value = "";
          return null;
        }
      }

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      // this.data = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1 });
      let data = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1 });
      if (data.length == 0 || data.length == 1) {
        this.excelHeader = true;
        this.allNewManagers = [];
        this.excelHeaderError =
          "Please add following header 1. Manager Name , 2. Manager Email or add some email and name";
        return;
      }

      if (data[0][0] !== "Manager Name" || data[0][1] !== "Manager Email") {
        this.excelHeader = true;
        this.excelHeaderError =
          "Please add following header 1. Manager Name , 2. Manager Email or add some email and name";
      } else {
        this.excelHeader = false;
      }
      if (type == "respondent") {
        this.respondentDataOnEdit = [];
      } else {
        this.allNewManagers = [];
      }
      // console.log(data);
      let excelsheet = [];
      if (!this.excelHeader) {
        for (let i = 1; i < data.length; i++) {
          excelsheet.push({
            name: data[i][0].trim(),
            email: data[i][1]?.toLowerCase(),
          });
        }
      }
      this.validateAndSearchData(excelsheet, type);

      // console.log(this.excelSheetData, "excwel sheet data");
    };
    this.uploadedFileName = target.files[0].name;
    reader.readAsBinaryString(target.files[0]);
  }

  // let

  validateAndSearchData(data, type) {
    let re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // console.log(type);
    for (let i = 0; i < data.length; i++) {
      if (isEmpty(data[i].email) || !re.test(data[i].email.trim())) {
        this.errorsInData.push({
          email: data[i].email,
          errorMessage: "Enter a valid email address",
        });
      } else {
        this.excelSheetData.push(data[i].email.toLowerCase());
      }
    }

    switch (type) {
      case "manager":
        if (this.excelSheetData.length > 0) {
          this.getManagers(this.excelSheetData, type);
        }
        break;
      case "respondent":
        this.getManagers(this.excelSheetData, type);
        break;
      default:
        this.getManagers(this.excelSheetData);
    }

    return true;
  }

  deleteExcelSheet() {
    this.fileInput.nativeElement.value = "";
    this.excelSheetData = [];
    this.excelType = false;
    this.touched = false;
    this.excelHeader = false;
    this.getManagers([]);
    this.errorsInData = [];
  }
  deleteExcelSheetManagers() {
    this.allNewManagers = [];
    this.excelType = false;
    this.touched = false;
    this.excelHeader = false;
    this.errorsInData = [];
  }
  dataChanged() {
    switch (this.surveyType) {
      case "Post":
        this.apCheckBox = true;
        this.isRtfSelected = false;
        this.isValidData = true;
        break;
      case "Pre":
        this.apCheckBox = false;
        this.isRtfSelected = false;
        this.isValidData = true;
        break;
      case "Interim":
        this.apCheckBox = false;
        this.isRtfSelected = false;
        this.isValidData = true;
        break;
      case "RealTimeFeedback":
        this.isRtfSelected = true;
        this.apCheckBox = false;
        this.isValidData = false;
        break;
      default:
        this.isRtfSelected = false;
        this.apCheckBox = false;
        this.isValidData = true;
    }
    this.respondent = [];
    this.text = "Unselect All";
    this.checkUncheckAll();
    this.getManagers([]);
  }

  getSurveyQuestionsTemplate() {
    this.companyService.getSurveyQuestionsTemplate().subscribe((res) => {
      if (res.status) {
        for (let i = 0; i < res.data.length; i++) {
          switch (res.data[i].question_type_id) {
            case 1:
              this.radioQuestions.push(res.data[i]);
              break;
            case 2:
              this.dropDownQuestions.push(res.data[i]);
              break;
            case 4:
              this.textAreaQuestions.push(res.data[i]);
              break;
          }
        }
        this.masterQuesionairId = res.masterQuesionairId;
      }
    });
  }
  selectDropDown(days: string) {
    switch (days) {
      case "weekDays":
        break;
      case "sendDays":
        break;
    }
  }
  pauseRtf() {
    const data = {
      managers: this.managers,
      surveyGroupId: this.surveyGroupId,
    };
    this.companyService.pauseRtfSurvey(data).subscribe({
      next: (res: any) => {
        if (res.status) {
          swal.fire({
            title: "Pause Survey",
            text: "Survey Pause Successfully",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });
          this.isRealTimePause = true;
        }
      },
    });
  }
  resumeRtf() {
    const resumeSurvey = {
      startDate: moment(this.startDate).format("YYYY-MM-DD"),
      endDate: moment(this.endDate).format("YYYY-MM-DD"),
      managers: this.resumeSurveyArray,
      clientId: this.companyData.surveyEngineCompanyId,
      surveyGroupId: this.surveyGroupId,
    };
    this.companyService.resumeRtfSurvey(resumeSurvey).subscribe({
      next: (res: any) => {
        if (res.status) {
          swal.fire({
            title: "Resume Survey",
            text: "Survey Resume Successfully",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      },
      error: (err) => console.log(err),
    });
  }

  checkRealtimeSurveyIsPresent() {
    this.companyService.checkRtfSurveyIsPresent(this.companyId).subscribe({
      next: (res: any) => {
        if (res.status && res.message == "Survey Exists") {
          this.isRtfSurveyAlreadyPresent = true;
        }
      },
    });
  }
  changeBackground(i) {
    const day = this.weekDays[i].day;
    const index = this.selectedDays.indexOf(day);
    // console.log(index);

    if (index !== -1) {
      if (this.selectedDays.length > 1) {
        this.selectedDays.splice(index, 1);
        this.weekDays[i].backgroundColor = "#f1f3f4";
        this.weekDays[i].color = "#80868b";
      }
    } else {
      this.selectedDays.push(day);
      this.weekDays[i].backgroundColor = "#1a73e8";
      this.weekDays[i].color = "#ffffff";
    }
    // console.log(this.selectedDays);
  }
  showDayOnHover(index: number): void {
    this.hoveredIndex = index;
  }

  hideDayOnHover(): void {
    this.hoveredIndex = null;
  }
  openEmailTemplate(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: "medium-modal" })
    );
    this.companyService.addModal(this.modalRef);
  }
  handleForm(event) {
    if (event.target.name === "emailType") {
      let data = {
        type: event.target.value,
      };
      this.getEmailContent(data);
    } else if (event.target.name === "cc") {
      this.cc = event.target.value;
    }
    this.formData[event.target.name] = event.target.value;
  }
  handleFormTimeChange(event) {
    let value = event.value;
    const hours = value.getHours();
    const minutes = value.getMinutes();

    const formattedTime = `${this.padZero(hours)}:${this.padZero(minutes)}`;
    this.formData["scheduleAt"] = formattedTime;
  }
  padZero(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }

  getEmailContent(data) {
    this.companyService.getEmail(data).subscribe((res) => {
      if (res.status) {
        this.formData.bcc = res.data.bcc;
        this.formData.content = res.data.content;
        this.formData.subject = res.data.subject;
        this.toTypes = res.data.recipients;
        this.placeholder = res.data.placeholder.toString();
      } else {
        swal.fire({
          title: "Email Template",
          text: "Something went wrong!",
          icon: "error",
          showConfirmButton: true,
        });
      }
    });
  }

  handleEditorChange(content: string) {
    // console.log(content);
    // this.findExtraPlaceholder(this.placeholder, content);
  }

  // findExtraPlaceholder(template: string, placeholder: any) {
  //   const regex = /%(\w+)%/g;
  //   const headerContent = template
  //     .match(regex)
  //     .filter((header) => header !== "%ICONURL%");
  //   const placeHolderData = placeholder
  //     .match(regex)
  //     .filter((header) => header !== "%ICONURL%");
  //   this.isAllHeadersIncluded = headerContent.every((item) =>
  //     placeHolderData.includes(item)
  //   );

  //   this.missingPlaceholders = headerContent.filter(
  //     (item) => !placeHolderData.includes(item)
  //   );
  //   this.additionalPlaceholders = placeHolderData.filter(
  //     (item) => !headerContent.includes(item)
  //   );

  //   if (
  //     this.missingPlaceholders.length > 0 &&
  //     this.additionalPlaceholders.length > 0
  //   ) {
  //     this.missingHeader = `The following placeholders are missing in the Email Template: ${this.missingPlaceholders}`;
  //     this.additionalHeader = `The Email Template has the following additional placeholders: ${this.additionalPlaceholders}`;
  //     this.isValidData = true;
  //   } else if (this.missingPlaceholders.length > 0) {
  //     this.missingHeader = `The following placeholders are missing in the Email Template: ${this.missingPlaceholders}`;
  //     // this.isValidData = true;
  //   } else if (this.additionalPlaceholders.length > 0) {
  //     this.additionalHeader = `The Email Template has the following additional placeholders: ${this.additionalPlaceholders}`;
  //     this.isValidData = true;
  //   } else {
  //     this.isValidData = false;
  //   }
  // }
  repeatSelection(event) {
    this.repeatType = event.target.value;
    switch (event.target.value) {
      case "day":
        this.weeksSelected = false;
        this.daysSelected = true;
        this.monthSelected = false;
        break;
      case "week":
        this.weeksSelected = true;
        this.daysSelected = false;
        this.monthSelected = false;
        const today = this.datePipe.transform(new Date(), "EEEE"); // Get full day name
        const todayIndex = this.weekDays.findIndex(
          (days) => days.day === today
        );
        if (todayIndex !== -1) {
          this.weekDays[todayIndex].backgroundColor = "#1a73e8";
          this.weekDays[todayIndex].color = "#ffffff";
          this.selectedDays.push(this.weekDays[todayIndex].day);
        }

        break;
      case "month":
        this.weeksSelected = false;
        this.daysSelected = false;
        this.monthSelected = true;
        break;

      default:
        this.weeksSelected = false;
        this.daysSelected = false;
        this.monthSelected = false;
    }
  }
  increment() {
    if (this.repeatAfter < this.MAX_QUESTIONS) {
      this.repeatAfter += 1;
    }
  }

  decrement() {
    if (this.repeatAfter > 1) {
      this.repeatAfter -= 1;
    }
  }

  validateData() {
    for (let key in this.formData) {
      if (this.formData[key].trim() == "") {
        return false;
      }
    }

    return true;
  }
  emailValidate(email) {
    let valid =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      );
    return valid;
  }

  submitData() {
    if (!this.validateData()) {
      swal.fire({
        title: "Form Validation",
        text: "Please fill the required field",
        icon: "error",
        showConfirmButton: true,
        timer: 250000,
      });
    } else if (this.cc.trim() !== "" && !this.emailValidate(this.cc)) {
      swal.fire({
        title: "Form Validation",
        text: "Please check the email format of CC",
        icon: "error",
        showConfirmButton: true,
        timer: 250000,
      });
    } else {
      let cleanHtml = this.formData.content.replace(/&#10;/g, " ");
      // console.log(cleanHtml, "html");
      let data = {
        emailType: this.formData.emailType,
        name: this.formData.name,
        // to: this.formData.to == "Others" ? this.toSend : this.formData.to,
        subject: this.formData.subject,
        content: cleanHtml,
        bcc: this.formData.bcc,
        cc: this.cc,
        // startDate:
        // this.scheduleAt && this.scheduleAt ? this.scheduleAt[0] : undefined,
        // endDate:
        //   this.scheduleAt && this.scheduleAt.length > 1
        //     ? this.scheduleAt[1]
        //     : undefined,
        // type: this.scheduleAt ? "schedule" : "now",
        // managers: this.selection.selected,
        companySurveyId: this.surveyId,
      };
      this.isValidData = true;
      this.modalRef.hide();
    }
  }
  checkMaxRepetition() {
    if (this.repeatAfter > this.MAX_QUESTIONS) {
      this.repeatAfter = this.MAX_QUESTIONS;
    }
  }
  changeDemographic(option: string, insert: boolean) {
    if (!this.selectedDemographicOption.includes(option) && insert) {
      this.selectedDemographicOption.push(option);
    } else {
      const index = this.selectedDemographicOption.indexOf(option);
      this.selectedDemographicOption.splice(index, 1);
    }
  }
  ngOnDestroy(): void {
    this.editor.destroy();
  }
  prepareLaunchData() {
    if (this.allNewManagers.length > 0) {
      this.allNewManagers = this.allNewManagers.filter((manager) => {
        if (
          this.surveyType === "Post" ||
          this.surveyType === "Recertification"
        ) {
          return (
            manager.surveyOngoing === false &&
            manager.participants === true &&
            manager.seManagerId &&
            manager.actionPlans === true
          );
        } else {
          return (
            manager.surveyOngoing === false &&
            manager.participants === true &&
            manager.seManagerId
          );
        }
      });
    }
    this.uploadedFileName = "";
    this.modalClosedBySubmit = true;
    this.modalRef.hide();
  }

  resetFileInput() {
    // Reset the value of the file input
    const fileInput = document.getElementById("fileSelect") as HTMLInputElement;

    fileInput.value = "";
  }
  updateUploadType(event) {
    this.uploadTypeOnEdit = event.target.value;
    this.excelHeaderError = "";
  }
}
