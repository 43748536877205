import {
  Component,
  ChangeDetectorRef,
  OnInit,
  ViewChild,
  TemplateRef,
  OnDestroy,
  ElementRef,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { CompanyServices } from "../../company.service";
import { SelectionModel } from "@angular/cdk/collections";
import { MatSnackBar } from "@angular/material/snack-bar";
import { LoginServices } from "../../../login/login.service";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { BsModalService } from "ngx-bootstrap/modal";
import * as XLSX from "xlsx";
import swal from "sweetalert2";
// import { WindowRef } from "../../../manager/pricing/WindowRef";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { NgForm } from "@angular/forms";
import { Subject, map } from "rxjs";
import { Router } from "@angular/router";
import { dateFormat, isEmpty } from "../../../../config/utils";
// import * as jwt_decode from 'jwt-decode';
import { jwtDecode } from "jwt-decode";
import { WindowRef } from "./WindowRef";
import { DEFAULT_DEMOGRAPHICS, validateEmail } from "src/app/constants";
@Component({
  selector: "app-company-added-manager-list",
  templateUrl: "./company-added-manager-list.component.html",
  styleUrls: ["./company-added-manager-list.component.css"],
})
export class CompanyAddedManagerListComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  @ViewChild("f") profileForm!: NgForm;
  displayedColumns: string[] = ["select", "name", "email", "status", "action"];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  dataSource = new MatTableDataSource();

  uploadedManagerList: any = [];
  isRegister: any = {};
  participants: any = [];
  emailManager = false;
  sasData: any = [];
  hide = false;
  smallLoading = false;
  sameEmail = false;
  revoke: any = {};
  managerEmail = "";
  mgrId = "";
  threeRespondentSelect: any = false;
  isSas: any = {};
  isPackage: any = {};
  editShow: any = {};
  email1 = "";
  modalRef!: BsModalRef;
  isCertificate: any = {};
  isTeamFeedBack: any = {};
  isActionPlanning: any = {};
  selection = new SelectionModel(true, []);
  globalCopy: any;
  data: any;
  data9: any;
  managers: any = [];
  managerProfile: any;
  packagesFeature: any = [];
  currentSelected: any;
  flagStatus: boolean = false;
  loginId: any;
  filterText!: string;
  surveyResponseRate: any = {};
  subsPackage: any;
  package: any;
  packageId!: any;
  price: any;
  loading!: boolean;
  sub_id: any;
  special: any = [];
  free: any = [];
  basic: any = [];
  premium: any = [];
  searchKey!: string;
  revokedManager = {};
  actionPlanningData: any = [];
  nameError = false;
  emailError = false;
  emailExists = false;
  displayNoRecords = false;
  actionPlans: any = [];
  actionLoader!: boolean;
  participantId = "";
  name1 = "";
  checkall: any = false;
  surveysData: any = [];
  selectedFeatures: any = [];
  public serverUrl = environment.hostUrl;
  public commonUserApiUrl = "/v1/user";
  public companyApiUrl = "/v1/company";
  age1: any;
  gender1: any;
  participant = {
    name: "",
    emailId: "",
    respondentType: "",
  };
  isUpdate: boolean = false;
  showMessage: boolean = false;
  selectedDemographic: string = "";
  selectedOption: string = "";
  currentDemographicOptions: any = [];
  selectedDemographics: any[] = [];
  showCustomDemographicForm = false;
  newDemographicName = "";
  newDemographicOption = "";
  defaultDemographics: any = DEFAULT_DEMOGRAPHICS.filter(
    (data) => data.type !== "manager" && data.show
  );
  alreadyAddedDemographics: any = [];
  filteredDemographics: any = [];
  isDemoUpdate: boolean = false;
  isCustomDemoEdit: boolean = false;
  currentParticipantDemographics: any = [];
  currentIndex: number = -1;
  constructor(
    private companyService: CompanyServices,
    public snackBar: MatSnackBar,
    private modalService: BsModalService,
    private loginService: LoginServices,
    public winRef: WindowRef,
    private http: HttpClient,
    private cd: ChangeDetectorRef,
    private router: Router
  ) {}

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit() {
    this.decodeToken();
    this.getCompanyAddedManagerList();
    this.refreshComponent();
    this.statusChecker();
  }

  decodeToken() {
    if (localStorage.getItem("token") !== null) {
      let token: any = localStorage.getItem("token");
      let tokenData: any = jwtDecode(token);
      let selectedFeatures =
        typeof tokenData.selectedFeatures !== "undefined"
          ? tokenData.selectedFeatures
          : [];
      let tempSelected: any = [];
      if (selectedFeatures.length > 0) {
        selectedFeatures.forEach((feature: any) => {
          tempSelected.push(feature.type);
        });
        this.selectedFeatures = tempSelected;
        if (
          this.selectedFeatures.includes("l&d") &&
          !this.selectedFeatures.includes("ap") &&
          !this.selectedFeatures.includes("sas")
        ) {
          this.displayedColumns = ["select", "name", "email"];
        }
      }
    }
  }

  addToSelection(row: any, event: any, checkbox: any) {
    ////console.log(row , event)
    ////console.log(checkbox)
  }

  getActionPlanningForMgr(id: any, template: any) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: "my-modal" })
    );
    this.companyService.addModal(this.modalRef);
    this.companyService.getManagerActionPlanning(id).subscribe((res: any) => {
      if (res.status) {
        // res.data.forEach(data => {
        //   data.startDate = dateFormat(data.startDate, "dd-mm-yyyy");
        //   data.endDate = dateFormat(data.endDate, "dd-mm-yyyy");
        // });
        this.actionPlanningData = res.data;
      }
    });
  }

  getCompanyAddedManagerList() {
    this.companyService.managerData.subscribe((response) => {
      this.managers = response;
      this.uploadedManagerList = [];
      let modulesCompltedPresent = false;
      let batchNamePresent = false;
      response.forEach((manager) => {
        this.isRegister[manager._id] = manager.personalInfo.isVerified;
        this.revoke[manager._id] = manager.personalInfo.accessRevoked;
        this.isPackage[manager._id] = manager.packageStatus;
        this.isSas[manager._id] = manager.sasStatus;
        this.isCertificate[manager._id] = manager.certificateStatus;
        this.isTeamFeedBack[manager._id] = manager.tfsStatus;
        this.isActionPlanning[manager._id] = manager.actionPlanStatus;

        // if (manager.personalInfo && !manager.personalInfo.batchName) {
        //   manager.personalInfo.batchName = "No Batch Assigned";
        // }
        if (manager.packageStatus) {
          if (
            manager.packageEndDate &&
            new Date(manager.packageEndDate) > new Date(Date.now())
          ) {
            manager.packageStatus = "Active";
          } else {
            manager.packageStatus = "Expire";
          }
        }
        if (manager.personalInfo.isRegisteredByCompany) {
          let managerInfo = {
            name: manager.personalInfo.name,
            email: manager.personalInfo.email,
            profileLink: manager.personalInfo.userName,
            batchName: manager.personalInfo.batchName,
            package: manager.packageStatus,
            packageName: manager.packageName,
            id: manager._id,
            participants: manager.participants,
            modulesCompleted:
              manager.modulesCompleted !== 0 ? manager.modulesCompleted : "NA",
            accessRevoked: manager.personalInfo.accessRevoked,
          };
          if (manager.modulesCompleted !== 0) {
            modulesCompltedPresent = true;
          }
          this.uploadedManagerList.push(managerInfo);
        }
      });
      if (
        modulesCompltedPresent &&
        !this.displayedColumns.includes("moduleCompleted")
      ) {
        this.displayedColumns.push("moduleCompleted");
      } else {
        this.displayedColumns = this.displayedColumns.filter((value) => {
          return value !== "moduleCompleted";
        });
      }

      if (
        this.uploadedManagerList.filter((manager) => manager.batchName !== "")
          .length > 0 &&
        this.displayedColumns.includes("batch") === false
      ) {
        this.displayedColumns.push("batch");
      }

      this.checkall = false;
      this.count = 0;
      this.dataSource.data = [...this.uploadedManagerList];
    });

    ////console.log(this.dataSource  , 'data12')
  }

  openSurveyModal(modal: any, id: any) {
    this.modalRef = this.modalService.show(
      modal,
      Object.assign({}, { class: "my-modal" })
    );
    this.companyService.addModal(this.modalRef);
    this.companyService.getManagerSurvey(id).subscribe((res: any) => {
      if (res.status) {
        res.data.forEach((data: any) => {
          data.startDate = dateFormat(data.startDate, "dd-mm-yyyy");
          data.endDate = dateFormat(data.endDate, "dd-mm-yyyy");
          let surveySubmittedRes = data.participants.filter(
            (part: any) => part.surveySubmissionStatus == true
          );
          this.surveyResponseRate[data._id] =
            (surveySubmittedRes.length / data.participants.length) * 100;
          if (isEmpty(data.surveyScore)) {
            data.surveyScore = "Not Calculated";
          }
        });
        this.surveysData = res.data;
      }
    });
  }

  statusChecker() {
    this.companyService.statusTrack.subscribe((data: any) => {
      ////console.log('uodat')
      this.uploadedManagerList.forEach((manager: any) => {
        data.forEach((updatedManager: any) => {
          if (manager.id == updatedManager._id) {
            manager.package = "Active";
            this.isPackage[manager.id] = true;
            ////console.log( this.isPackage[manager.id]  , manager.id)
          }
        });
      });
    });
  }

  refreshComponent() {
    this.companyService.refreshComponent.subscribe((response: any) => {
      let managerInfo = {
        name: response.personalInfo.name,
        email: response.personalInfo.email,
        profileLink: response.personalInfo.userName,
        package: response.packageStatus,
        packageName: response.packageName,
        id: response._id,
        participants: response.participants,
      };
      this.managers.push(response);
      this.uploadedManagerList.push(managerInfo);
      this.dataSource.data = [...this.uploadedManagerList];
    });
  }

  checkUncheckAll() {
    if (this.checkall) {
      this.checkall = true;
    } else {
      this.checkall = false;
    }
    this.uploadedManagerList.forEach((selectedManager: any) => {
      selectedManager.checked = this.checkall;
      this.onChange(selectedManager, selectedManager.checked);
    });
  }

  count = 0;
  selectedManagers: any = [];
  updateCheckall(participant: any, isChecked: boolean) {
    ////console.log(isChecked);
    ////console.log(participant)
    var userTotal = this.uploadedManagerList.length;
    var count = 0;
    if (isChecked) {
      this.count++;
      if (!this.selectedManagers.includes(participant)) {
        this.selectedManagers.push(participant);
      }
    } else {
      this.count--;
      let index = this.selectedManagers.indexOf(participant);
      this.selectedManagers.splice(index, 1);
    }
    this.collectSelectedManagers(this.selectedManagers);
    if (userTotal == this.count) {
      this.checkall = true;
    } else {
      this.checkall = false;
    }
  }

  onChange(selectedManger: any, isChecked: boolean) {
    if (isChecked) {
      this.count++;
      if (!this.selectedManagers.includes(selectedManger)) {
        this.selectedManagers.push(selectedManger);
      }
    } else {
      this.count--;
      let index = this.selectedManagers.indexOf(selectedManger);
      this.selectedManagers.splice(index, 1);
    }
    this.collectSelectedManagers(this.selectedManagers);
  }

  openSaSModal(modal: any, id: any) {
    this.modalRef = this.modalService.show(
      modal,
      Object.assign({}, { class: "my-modal" })
    );
    this.companyService.addModal(this.modalRef);
    this.companyService.getSasInfo(id).subscribe((res: any) => {
      if (res.status) {
        res.data.forEach((data1: any) => {
          data1.details.surveyScore = data1.details.surveyScore
            ? `${parseInt(data1.details.surveyScore).toFixed(0)}%`
            : "Not submitted";
          data1.details.cScore = data1.details.cScore
            ? `${parseInt(data1.details.cScore).toFixed(0)}%`
            : "Not submitted";
          data1.details.iScore = data1.details.iScore
            ? `${parseInt(data1.details.iScore).toFixed(0)}%`
            : "Not submitted";
          data1.details.dScore = data1.details.dScore
            ? `${parseInt(data1.details.dScore).toFixed(0)}%`
            : "Not submitted";
        });
        this.sasData = res.data;
      }
    });
  }

  applyFilter() {
    this.dataSource.filter = this.searchKey.trim().toLowerCase();
    if (this.dataSource.filteredData.length == 0) {
      this.displayNoRecords = true;
    } else {
      this.displayNoRecords = false;
    }
  }

  onSearchClear() {
    this.searchKey = "";
    this.applyFilter();
  }

  collectSelectedManagers(data: any) {
    this.companyService.collectSelectedManagers(data);
  }

  copyToClipboard(id: any) {
    this.globalCopy = document.getElementById(`copy${id}`);
    this.globalCopy.select();
    ////console.log(id, "ddd");
    document.execCommand("copy");
    this.snackBar.open("copied to clipboard", "", {
      duration: 3000,
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    this.companyService.addModal(this.modalRef);
  }

  editProfile(template: TemplateRef<any>, id: any) {
    this.modalRef = this.modalService.show(template);
    this.companyService.addModal(this.modalRef);
    ////console.log(this.managers, "sdsdddd");
    this.managers.forEach((manager: any) => {
      if (manager._id == id) {
        this.managerProfile = manager;
      }
    });
  }

  onSubmit2(form: NgForm, id: any) {
    ////console.log(form.value);
    ////console.log(id);
    this.loading = true;
    for (let key in form.value) {
      if (form.value[key] == "") {
        for (let newKey in this.managers) {
          if (newKey == key) {
            form.value[key] = this.managers[newKey];
          }
        }
      }
    }
    let data = {
      formData: form.value,
      mgrId: id,
    };
    this.companyService
      .updateProfileManager(data)
      .subscribe((response: any) => {
        this.loading = false;
        if (response.status) {
          this.uploadedManagerList.forEach((manager: any) => {
            if (manager.id == data.mgrId) {
              manager.email = data.formData.emailId;
              manager.name = data.formData.name;
            }
          });
          swal.fire({
            title: "Profile Update",
            text: response.message,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
        } else {
          swal.fire({
            title: "Profile Update",
            text: response.message,
            icon: "error",
            showConfirmButton: false,
            timer: 2500,
          });
        }
      });

    this.modalRef.hide();
    // this.modalRef = null;
  }

  checkEmail() {
    this.emailExists = true;
    if (this.managerProfile.personalInfo.email !== "") {
      let index = this.managerProfile.participants.findIndex(
        (part: any) => part.emailId == this.managerProfile.personalInfo.email
      );
      ////console.log(index);
      if (index !== -1) {
      } else {
        let data = {
          email: this.managerProfile.personalInfo.email,
          id: this.managerProfile._id,
        };
        ////console.log(data)
        this.companyService
          .checkingEmailAddress(data)
          .subscribe((response: any) => {
            if (!response.status) {
              this.emailExists = true;
            } else {
              this.emailExists = false;
            }
          });
      }
    }
  }

  addParticipantsToManager() {
    let data = {
      mgrId: this.mgrId,
      data: this.participants,
    };
    this.loading = true;
    this.companyService
      .addParticipantToManagers(data)
      .subscribe((response: any) => {
        this.loading = false;
        if (response.status) {
          swal.fire({
            title: "Add Participant",
            text: response.message,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.editShow[this.email1] = false;
          this.participant.name = null;
          this.participant.emailId = null;
          this.participant.respondentType = null;
        } else {
          swal.fire({
            title: "Add Participant",
            text: response.message,
            icon: "error",
            showConfirmButton: false,
            timer: 2500,
          });
        }
      });
    this.modalRef.hide();
    // this.modalRef = null;
  }

  oldParticipants: any = [];
  addParticipantToManager(template: TemplateRef<any>, id: any) {
    this.modalRef = this.modalService.show(template, {
      class: "modal-lg",
      backdrop: "static",
    });
    this.currentParticipantDemographics = [];
    this.getCompanyDemographics();
    this.companyService.addModal(this.modalRef);
    this.managers.forEach((manager: any) => {
      if (manager._id == id) {
        this.participants = manager.participants;
        this.oldParticipants = [...manager.participants];
        this.managerEmail = manager.personalInfo.email;
        this.mgrId = manager._id;
      }
      if (this.participants.length > 2) {
        this.threeRespondentSelect = false;
        // this.hide=false
      } else {
        this.threeRespondentSelect = true;
      }
    });
  }

  onSubmit1(form: NgForm) {
    if (
      !this.participant.name.trim() ||
      !validateEmail(this.participant.emailId.trim())
    ) {
      this.showMessage = true;
      return;
    }
    this.showMessage = false;
    if (this.isUpdate) {
      this.updateParticipant();
      return;
    }

    if (form.value.emailId == this.managerEmail) {
      this.emailManager = true;
    } else {
      this.emailManager = false;
      this.hide = true;
      let addRespondent = {
        name: form.value.name,
        emailId: form.value.emailId.toLowerCase(),
        respondentType: form.value.respondentType,
        demographics: this.currentParticipantDemographics.map((data) => {
          return {
            company_demographics_name: !data.default
              ? this.transformString(data.company_demographics_name)
              : data.company_demographics_name,
            value: data.value,
          };
        }),
      };
      let existing = this.participants.filter((respondant) => {
        return (
          respondant.emailId.toLowerCase() ==
            form.value.emailId.toLowerCase() ||
          (respondant.emailId.toLowerCase() ==
            form.value.emailId.toLowerCase() &&
            respondant.respondentType == form.value.respondentType)
        );
      });
      if (existing.length > 0) {
        this.sameEmail = true;
      } else {
        this.participants.push(addRespondent);
        this.resetForms();
        this.currentParticipantDemographics = [];
        this.sameEmail = false;
        if (this.participants.length > 2) {
          this.threeRespondentSelect = false;
        } else {
          this.threeRespondentSelect = true;
        }
      }
    }
    form.reset();
  }

  transformString(input) {
    return input
      .toLowerCase() // Convert the string to lowercase
      .split(" ") // Split the string by spaces
      .map((word, index) => {
        // Capitalize the first letter of each word except the first word
        return index === 0
          ? word
          : word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(""); // Join the words back into a single string
  }
  deleteParticipantToManager(data: any) {
    this.loading = true;
    if (data._id) {
      ////console.log(data);
      this.companyService
        .deleteParticipantsToManager({ data: data, mgrId: this.mgrId })
        .subscribe((response: any) => {
          this.loading = false;
          if (response.status) {
            this.snackBar.open(response.message, "", {
              duration: 3000,
            });
          }
        });
      let index = this.participants.findIndex(
        (participant: any) => participant.emailId == data.emailId
      );
      this.participants.splice(index, 1);
      if (this.participants.length > 2) {
        this.threeRespondentSelect = false;
      } else {
        this.threeRespondentSelect = true;
      }
    } else {
      let index = this.participants.findIndex(
        (participant: any) => participant.emailId == data.emailId
      );
      this.participants.splice(index, 1);
      this.loading = false;
      if (this.participants.length > 2) {
        this.threeRespondentSelect = false;
      } else {
        this.threeRespondentSelect = true;
      }
    }
  }

  changeName(event: any) {
    if (event.target.value == "") {
      this.nameError = true;
    } else {
      this.nameError = false;
    }
    let index = this.participants.findIndex(
      (participant: any) => participant.emailId == this.email1
    );
    if (index != -1) {
      this.participants[index].name = event.target.value;
    }
  }

  changeEmail(event: any) {
    let oldParticipants = [...this.participants];
    if (event.target.value == "") {
      this.emailError = true;
    } else {
      this.emailError = false;
      let index = this.participants.findIndex(
        (participant: any) => participant.emailId == this.email1
      );
      ////console.log(this.managerEmail , 'managerEmail')
      if (this.managerEmail == event.target.value) {
        this.emailManager = true;
      } else {
        this.emailManager = false;
      }
      let existing = this.participants.filter((respondant: any) => {
        return respondant.emailId == event.target.value;
      });

      if (index != -1 && !this.emailManager && existing.length == 0) {
        this.participants[index].emailId = event.target.value;
      }
    }
  }

  patchParticipantValues(data: any, index: number) {
    this.currentIndex = index;
    this.participant.name = data.name;
    this.participant.emailId = data.emailId;
    this.participant.respondentType = data.respondentType;
    this.currentParticipantDemographics = data?.demographics
      ? data?.demographics
      : [];
    this.isUpdate = true;
  }

  updateParticipant() {
    const data = this.participants[this.currentIndex];
    const index = this.participants.findIndex(
      (participant: any) =>
        participant.emailId.toLowerCase() ===
        this.participant.emailId.toLowerCase()
    );
    if (index > -1 && index != this.currentIndex) {
      this.openSnackBar("Participant already exist.", false);
      return;
    }
    if (data) {
      this.participants[this.currentIndex].name = this.participant.name;
      this.participants[this.currentIndex].emailId = this.participant.emailId;
      this.participants[this.currentIndex].respondentType =
        this.participant.respondentType;
      (this.participants[this.currentIndex].demographics =
        this.currentParticipantDemographics.map((data) => {
          return {
            company_demographics_name: !data.default
              ? this.transformString(data.company_demographics_name)
              : data.company_demographics_name,
            value: data.value,
          };
        })),
        (this.participant.name = null);
      this.participant.emailId = null;
      this.participant.respondentType = null;
      this.currentParticipantDemographics = [];
      this.currentIndex = -1;
      this.openSnackBar("Participant updated successfully.", true);
    }
  }

  openModal1(template: TemplateRef<any>, id: any) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: "my-modal" })
    );
    this.companyService.addModal(this.modalRef);
    this.managers.forEach((manager: any) => {
      if (manager._id == id) {
        this.managerProfile = manager;
        this.loginService.features().subscribe((response: any) => {
          this.packagesFeature = response.docs;
          if (this.packagesFeature) {
            this.loginService.packageName(id).subscribe((response: any) => {
              this.currentSelected = response.docs;
              if (this.currentSelected) {
                if (this.packagesFeature.length > 0) {
                  this.flagStatus = false;
                  this.packagesFeature.forEach(
                    (packagesFeature: any, index: any) => {
                      // packagesFeature.flag = true;
                      if (
                        packagesFeature.name == this.currentSelected &&
                        this.flagStatus == false
                      ) {
                        packagesFeature.flag = true;
                        this.flagStatus = true;
                      } else if (this.flagStatus == true) {
                        packagesFeature.flag = false;
                      } else {
                        packagesFeature.flag = true;
                      }
                    }
                  );
                }
              }
            });
          }
        });
      }
    });
  }

  subscribe(packageName: any, amount: any) {
    this.loading = true;
    if (packageName == "Special") {
      this.sub_id = this.packagesFeature[3]._id;
      this.packagesFeature[0].features.forEach((res: any) => {
        this.special.push(res.key);
      });
      this.packagesFeature[1].features.forEach((res: any) => {
        this.special.push(res.key);
      });
      this.packagesFeature[2].features.forEach((res: any) => {
        this.special.push(res.key);
      });
      this.packagesFeature[3].features.forEach((res: any) => {
        this.special.push(res.key);
      });
      this.managerSubscription(
        amount,
        this.managerProfile.personalInfo.email,
        this.sub_id,
        this.managerProfile._id,
        this.special,
        packageName
      );
    } else if (packageName == "Free") {
      this.sub_id = this.packagesFeature[0]._id;
      this.packagesFeature[0].features.forEach((res: any) => {
        this.free.push(res.key);
      });
      this.loginService
        .addFeatures(this.free, this.managerProfile._id, packageName)
        .subscribe((response) => {
          ////console.log(response);
          this.uploadedManagerList.forEach((manager: any) => {
            if (manager.id == this.managerProfile._id) {
              manager.package = "Active";
            }
          });
          this.loading = false;
          this.modalRef.hide();
          // this.modalRef = null;
        });
    } else if (packageName == "Basic") {
      this.sub_id = this.packagesFeature[1]._id;
      this.packagesFeature[0].features.forEach((res: any) => {
        this.basic.push(res.key);
      });
      this.packagesFeature[1].features.forEach((res: any) => {
        this.basic.push(res.key);
      });
      this.managerSubscription(
        amount,
        this.managerProfile.personalInfo.email,
        this.sub_id,
        this.managerProfile._id,
        this.basic,
        packageName
      );
    } else if (packageName == "Premium") {
      this.sub_id = this.packagesFeature[2]._id;
      this.packagesFeature[0].features.forEach((res: any) => {
        this.premium.push(res.key);
      });
      this.packagesFeature[1].features.forEach((res: any) => {
        this.premium.push(res.key);
      });
      this.packagesFeature[2].features.forEach((res: any) => {
        this.premium.push(res.key);
      });
      this.managerSubscription(
        amount,
        this.managerProfile.personalInfo.email,
        this.sub_id,
        this.managerProfile._id,
        this.premium,
        packageName
      );
      this.loading = false;
    }
  }

  managerSubscription(
    amount: any,
    email: any,
    sub_id: any,
    id: any,
    packageName: any,
    subPackage: any
  ) {
    ////console.log(amount, "amoutn");
    this.loginId = id;
    this.subsPackage = subPackage;
    this.package = packageName;
    this.packageId = sub_id;
    this.price = amount * 100;
    let options = {
      key: "rzp_test_taFORmK6Ekzb4m",
      // key: 'rzp_live_v0FdLtDQ2apNzC',
      amount: this.price,
      name: subPackage,
      description: "You are one step away from your awesome journey",
      handler: this.paymentCapture.bind(this),

      prefill: {
        name: "Anonymous",
        email: email,
      },
      notes: {
        address: "Goa,India",
      },
      theme: {
        color: "#005071",
      },
    };
    const rzp1 = new this.winRef.nativeWindow.Razorpay(options);
    rzp1.open();
  }

  paymentCapture(response: any) {
    const amountInPaisa = this.price;
    const paymentId = response.razorpay_payment_id;
    this.capturePayment(
      amountInPaisa,
      paymentId,
      this.loginId,
      this.subsPackage
    );
  }

  capturePayment(amount: any, paymentId: any, id: any, packageName: any) {
    this.http
      .post(this.serverUrl + this.companyApiUrl + "/capture", {
        amount,
        paymentId,
        id,
      })
      .pipe(
        map((response5) => {
          return <any>response5;
        })
      )
      .subscribe((response1: Response) => {
        this.data = response1;
        if (this.data.status) {
          this.loginService
            .addFeatures(this.package, id, packageName)
            .subscribe((response) => {
              this.loading = false;
              this.data9 = response;
              if (this.data9.status) {
                this.uploadedManagerList.forEach((manager: any) => {
                  if (manager.id == id) {
                    manager.package = "Active";
                  }
                });
                this.currentSelected = packageName;
                if (this.packagesFeature.length > 0) {
                  this.flagStatus = false;
                  this.packagesFeature.forEach(
                    (packagesFeature: any, index: any) => {
                      if (
                        packagesFeature.name == this.currentSelected &&
                        this.flagStatus == false
                      ) {
                        packagesFeature.flag = true;
                        this.flagStatus = true;
                      } else if (this.flagStatus == true) {
                        packagesFeature.flag = false;
                      } else {
                        packagesFeature.flag = true;
                      }
                    }
                  );
                }
                this.flagStatus = false;
                swal.fire({
                  title: "Subscription successfull",
                  text:
                    "You have subscribed to our" +
                    " " +
                    this.subsPackage +
                    " " +
                    "Packages",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 2500,
                });

                this.cd.detectChanges();
              } else {
                swal.fire({
                  title: "Something went wrong",
                  text: "Error happen",
                  icon: "error",
                  showConfirmButton: false,
                  timer: 2500,
                });
              }
            });
        } else {
          swal.fire({
            title: "Something went wrong",
            text: "Error happen",
            icon: "error",
            showConfirmButton: false,
            timer: 2500,
          });
        }
      });
    this.modalRef.hide();
    // this.modalRef = null;
  }

  managerRevoke(id: any) {
    ////console.log('id :' , id)
    this.loading = true;
    let data = {
      mgrId: id,
    };
    this.companyService.revokeManagers(data).subscribe((response: any) => {
      this.loading = false;
      // console.log(response)
      if (response.status) {
        this.revoke[id] = !this.revoke[id];
        swal.fire({
          title: "Request Completed",
          text: response.message,
          icon: "success",
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        swal.fire({
          title: "Revoke manager",
          text: response.message,
          icon: "error",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    });
  }

  goTocompanyActionPlan(id: any) {
    this.router.navigate([
      "/company/company-dashboard/add-action-plan/" + id + "/add/single",
    ]);
  }

  ngOnDestroy() {
    this.dataSource = new MatTableDataSource();
    // this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  onFileChange(event: any) {
    const file: File = event.target.files[0];
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: "binary" });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      let data: any[] = XLSX.utils.sheet_to_json(ws, { raw: true, header: 1 });
      const headers: any[] = data.shift().map((header: any) => header.trim());
      data = data.filter((data) => {
        return data.length > 0;
      });
      const output: any[] = data.map((row: any) => {
        const obj: any = {};
        row.forEach((cell: any, i: number) => {
          obj[headers[i]] = cell;
        });
        return obj;
      });

      const groupedData = output.reduce((acc: any, row: any) => {
        const email = row.email;

        if (email) {
          const lowercaseEmail = email.toLowerCase();

          if (!acc[lowercaseEmail]) {
            acc[lowercaseEmail] = row;
          }
        }

        return acc;
      }, {});

      const selctedManagerGroupData = this.selectedManagers.reduce(
        (acc: any, row: any) => {
          const email = row.email;
          if (email) {
            const lowercaseEmail = email.toLowerCase();

            if (!acc[lowercaseEmail]) {
              acc[lowercaseEmail] = row;
            }
          }
          return acc;
        },
        {}
      );

      this.dataSource.data.forEach((item: any) => {
        if (groupedData[item.email]) {
          item.checked = true;
          !selctedManagerGroupData[item.email] &&
            this.selectedManagers.push(item);
        }
      });

      this.collectSelectedManagers(this.selectedManagers);

      swal.fire({
        title: "Manager Selection",
        text: `${this.selectedManagers.length} Managers Selected.`,
        icon: "success",
        showConfirmButton: true,
        timer: 2500,
      });
      event.target.value = null;
    };
    reader.readAsBinaryString(file);
  }
  impersonateUser(managerId) {
    let params = {
      userId: managerId,
      role: "manager",
    };
    this.companyService.getTokenForImpersonate(params).subscribe((res) => {
      if (res.status) {
        const targetDomain = environment.appUrl;

        const userInfo = {
          isImpersonate: "true",
          token: res.data.token?.toString(),
        };
        const queryString = new URLSearchParams(userInfo).toString();
        window.open(`${targetDomain}/impersonate?${queryString}`, "_blank");
      }
    });
  }

  currentDemoIndex: number = -1;

  onDemographicSelect() {
    if (this.selectedDemographic) {
      const index = this.defaultDemographics.findIndex(
        (data) => data.company_demographics_name == this.selectedDemographic
      );
      if (index > -1) {
        this.currentDemographicOptions =
          this.defaultDemographics[index].company_demographics_options_name;
      }
      this.selectedOption = "";
    }
  }

  addDemographic() {
    if (
      !this.validateDemographic(this.selectedDemographic, this.selectedOption)
    )
      return;

    if (this.isDemographicExists(this.selectedDemographic)) {
      this.openSnackBar(
        `'${this.selectedDemographic}' or similar has already been added!`,
        false
      );
      return;
    }

    this.addManagerDemographic(
      this.selectedDemographic,
      this.selectedOption,
      true
    );
    this.resetDemographicForm();
  }

  addCustomDemographic() {
    if (
      !this.validateDemographic(
        this.newDemographicName,
        this.newDemographicOption
      )
    )
      return;

    if (this.isDemographicExists(this.newDemographicName)) {
      this.openSnackBar(
        `'${this.newDemographicName}' or similar has already been added!`,
        false
      );
      return;
    }

    this.addManagerDemographic(
      this.newDemographicName,
      this.newDemographicOption,
      false
    );
    this.resetCustomDemographicForm();
  }

  validateDemographic(name, option) {
    if (!name || !option) {
      this.openSnackBar(
        "Demographic name and option should not be empty.",
        false
      );
      return false;
    }
    return true;
  }

  isDemographicExists(demographicName) {
    return this.currentParticipantDemographics.some(
      (data) =>
        data.company_demographics_name.toLowerCase() ===
          demographicName.toLowerCase() ||
        this.areNamesSimilar(
          data.company_demographics_name.trim().toLowerCase(),
          demographicName.trim().toLowerCase()
        )
    );
  }

  addManagerDemographic(name, option, isDefault) {
    this.currentParticipantDemographics.push({
      company_demographics_name: name.trim(),
      value: option.trim(),
      default: isDefault,
    });
  }

  resetDemographicForm() {
    this.currentDemographicOptions = [];
    this.selectedDemographic = null;
    this.selectedOption = null;
  }

  resetCustomDemographicForm() {
    this.newDemographicName = null;
    this.newDemographicOption = null;
    this.showCustomDemographicForm = false;
  }

  patchValues(demographic, index) {
    // this.scrollToForm();
    this.resetForms();
    if (demographic.default) {
      this.selectedDemographic = demographic.company_demographics_name;
      this.onDemographicSelect();
      this.selectedOption = demographic.value;
      this.isDemoUpdate = true;
    } else {
      this.currentDemoIndex = index;
      this.newDemographicName = demographic.company_demographics_name;
      this.newDemographicOption = demographic.value;
      this.isCustomDemoEdit = true;
    }
  }

  resetForms() {
    this.currentDemographicOptions = [];
    this.selectedDemographic = null;
    this.selectedOption = null;
    this.isDemoUpdate = false;
    this.isCustomDemoEdit = false;
    this.showCustomDemographicForm = false;
  }

  updateDemographic() {
    this.updateDemographicValue(this.selectedDemographic, this.selectedOption);
    this.resetForms();
    this.openSnackBar("Demographic updated successfully.", true);
  }

  updateCustomDemographic() {
    const data = this.currentParticipantDemographics[this.currentDemoIndex];
    if (data) {
      this.currentParticipantDemographics[
        this.currentDemoIndex
      ].company_demographics_name = this.newDemographicName;
      this.currentParticipantDemographics[this.currentDemoIndex].value =
        this.newDemographicOption;
    }
    this.resetForms();
    this.openSnackBar("Demographic updated successfully.", true);
  }

  updateDemographicValue(name, value) {
    const index = this.currentParticipantDemographics.findIndex(
      (data) => data.company_demographics_name === name
    );
    if (index > -1) {
      this.currentParticipantDemographics[index].value = value;
    }
  }

  removeDemographic(index: number) {
    this.currentParticipantDemographics.splice(index, 1);
  }

  openSnackBar(message: any, status: any) {
    this.snackBar.open(message, "", {
      duration: 5000,
      panelClass: status ? ["green-snackbar"] : ["red-snackbar"],
    });
  }

  areNamesSimilar(existingName: string, newName: string): boolean {
    // Check if either name contains the other (fuzzy match)
    return existingName.includes(newName) || newName.includes(existingName);
  }

  getCompanyDemographics() {
    this.companyService
      .getCompanyDemographics({ type: 2 })
      .subscribe((res: any) => {
        if (res.status) {
          this.alreadyAddedDemographics = res.data;
        }
      });
  }

  // Function to filter demographics based on the input
  filterDemographics() {
    const filterValue = this.newDemographicName.toLowerCase();
    const defaultDemo = this.defaultDemographics.map(
      (demo) => demo.company_demographics_name
    );

    this.filteredDemographics = this.alreadyAddedDemographics.filter(
      (option) =>
        !defaultDemo.includes(this.transformString(option)) &&
        option.toLowerCase().includes(filterValue)
    );
  }

  // Optional function to format the display value
  displayFn(demographic?: string): string {
    return demographic ? demographic : "";
  }
}
