import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], searchText: string): any {
    let item = 'no content'
    if(!items) return item;
    if(!searchText) return items;
searchText = searchText.toLowerCase();
return items.filter( it => {
  if(it.subtheme){
    return it.subtheme.toLowerCase().includes(searchText)
  }
    });
   }
  }


