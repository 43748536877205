<div class="company-statics" *ngIf="displayView == 'basic'">
  <h3 class="red-header-text">
    Organization-level Manager Management Platform
  </h3>
  <p>
    Monitor and manage the following: mapping of managers with employees,
    surveys executed by managers, other survey details.
  </p>
  <div class="grid-wrapper">
    <div class="emp-count flex cards cards-shadow">
      <div>
        <p clas>Total Participants</p>
        <h1>{{ totalEmployees }}</h1>
      </div>

      <i class="fa fa-user fa-2x"></i>
    </div>
    <div class="managers-count cards cards-shadow">
      <div>
        <p>Total Managers</p>
        <h1>{{ totalManagers }}</h1>
      </div>
      <div>
        <p>Registered Managers</p>
        <h1>{{ registeredCount }}</h1>
      </div>
      <div>
        <p>Unregistered Managers</p>
        <h1>{{ unRegisteredCount }}</h1>
      </div>
    </div>
    <div class="surveys-count flex cards cards-shadow">
      <div>
        <p>Total Surveys</p>
        <h1>{{ totalSurveys }}</h1>
      </div>

      <!-- <i class="fas fa-poll-h fa-2x"></i> -->
      <i class="fa fa-tasks fa-2x"></i>
    </div>
    <div class="ap-count flex cards cards-shadow">
      <div>
        <p>Total Action Plans Created</p>
        <h1>{{ totalApCount }}</h1>
      </div>
      <i class="uil uil-file-plus"></i>
    </div>
    <div class="ap-feedback-count flex cards cards-shadow">
      <div>
        <p>Total Feedback on Action Plans</p>
        <h1>{{ totalApFeedback }}</h1>
      </div>

      <i class="fa fa-file fa-2x"></i>
    </div>
    <div class="unique-ap-count flex cards cards-shadow">
      <div>
        <p>Unique Action Plan uploaded</p>
        <h1>{{ uniqueApUploaded }}</h1>
      </div>

      <i class="fa fa-cloud-upload fa-2x"></i>
    </div>
    <div class="total-imple-count flex cards cards-shadow">
      <div>
        <p>Total Implementation Updates</p>
        <h1>{{ totalImpleUpdates }}</h1>
      </div>
      <i class="fa fa-lightbulb fa-2x"></i>
    </div>
  </div>
  <div class="excel-upload cards cards-shadow">
    <div class="excel-upload-content">
      <h1>Employee Data Management</h1>
      <p class="small-text">
        Upload an Excel Sheet of your Manager-Employee mapping to add
        participants in bulk. Add each participant's team details one-by-one.
      </p>
      <p class="small-text">
        <strong
          >Enter respondent type in excel from these options('Peer', 'Reporting
          Manager', 'Senior', 'Team Member')</strong
        >
      </p>
      <a
        class="bold-text btn btn-gmi-primary mr-1"
        (click)="openModal(uploadTemplate)"
        >Upload Excel</a
      >

      <a class="bold-text btn btn-dark" (click)="openModal(addTemplate)"
        >Add one-by-one</a
      >
      <a class="bold-text btn btn-dark" (click)="downloadManagerPis()"
        >Download PIS</a
      >
    </div>
    <div
      id="excelDragAndDrop"
      class="drag"
      appDragDrop
      (filesChangeEmiter)="dropExcelOnChange($event)"
      (filesInvalidEmiter)="dropExcelBlock($event)"
      [allowed_extensions]="[
        'xlsx',
        'xls',
        'xlsm',
        'xltx',
        'xltm',
        'xlsb',
        'xlam'
      ]"
    >
      <h1><i class="fa fa-upload"></i>Upload Excel</h1>
      <h3>Drop your files here</h3>
      <p>Excel format is suggested</p>
    </div>
  </div>
</div>

<div class="col-md-12 many" *ngIf="!isDataLoading && displayView == 'learning'">
  <!-- Basic View Template -->
  <!-- <div class="basicView" *ngIf="displayView == 'basic'">
    <h3 class="red-header-text" style="font-weight: 700">
      Organization-level Manager Management Platform
    </h3>
    <p>
      Monitor and manage the following: mapping of managers with employees,
      surveys executed by managers, other survey details.
    </p>
    <div class="col-md-12">
      <div class="row">
        <ul class="license">
          <li>Basic License : {{ basicCount1 }}</li>
          <li>Premium License : {{ premiumCount1 }}</li>
          <li>Special License : {{ specialCount1 }}</li>
        </ul>
        <div class="col-md-3">
          <div class="cards">
            <div class="surveys-image">
              <img src="../../../assets/images/surveysimg.png" />
              <p class="center-icon">
                {{ totalSurveys }}
                <span
                  ><i
                    class="fa fa-info-circle info"
                    aria-hidden="true"
                    matTooltip="Total number of surveys"
                    matTooltipClass="custom-tooltip"
                  ></i
                ></span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="cards">
            <div class="employees-image">
              <img src="../../../assets/images/employees.png" />
              <p class="center-icon">
                {{ totalEmployees }}
                <span
                  ><i
                    class="fa fa-info-circle info"
                    aria-hidden="true"
                    matTooltip="Total number of employees"
                    matTooltipClass="custom-tooltip"
                  ></i
                ></span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="cards">
            <div class="managers-image">
              <img src="../../../assets/images/managersimg.png" />

              <p class="center-icon">
                {{ totalManagers }}
                <span
                  ><i
                    class="fa fa-info-circle info"
                    aria-hidden="true"
                    matTooltip="Total number of managers"
                    matTooltipClass="custom-tooltip"
                  ></i
                ></span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-3" *ngIf="pisAccess">
          <div class="cards" style="padding-bottom: 20px">
            <div class="">
              <p>Employee data management</p>
              <p class="small-text">
                Upload an Excel Sheet of your Manager-Employee mapping to add
                participants in bulk. Add each participant's team details
                one-by-one.
              </p>
              <p class="small-text">
                <strong
                  >Enter respondent type in excel from these options('Peer',
                  'Reporting Manager', 'Senior', 'Team Member')</strong
                >
              </p>
              <a class="bold-text" (click)="openModal(uploadTemplate)"
                >Upload Excel</a
              >

              <a class="bold-text" (click)="openModal(addTemplate)"
                >Add one-by-one</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- Learning Template -->
  <div class="learningTemplate" *ngIf="displayView == 'learning'">
    <h3 style="font-weight: 700">Company Admin Portal</h3>
    <p>
      Manage your managers information and track their progress on the program
    </p>
    <div class="col-md-12" *ngIf="!isDataLoading">
      <div class="row">
        <div class="col-md-3">
          <div class="cards">
            <div class="managers">
              <p class="headingTitle">Total Number of Managers</p>
              <p>{{ totalManagers }}</p>
            </div>
            <div class="managers-image">
              <img src="../../../assets/images/managersimg.png" />
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="cards" style="padding-bottom: 20px">
            <div class="">
              <p class="headingTitle">Data Management</p>
              <p class="small-text">
                Upload an excel of your managers participating in the program.
                Or add each manager’s detail one-by-one.
              </p>
              <a
                style="font-weight: 700; font-size: 13px"
                (click)="openModal(uploadTemplate)"
                >Upload Excel</a
              >

              <a class="bold-text" (click)="openModal(addTemplate)"
                >Add one-by-one</a
              >
              <a class="bold-text btn btn-dark" (click)="downloadManagerPis()"
                >Download PIS</a
              >
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div
            id="summaryGraph"
            *ngIf="
              summaryChartOptions != undefined &&
              summaryChartOptions.chart != undefined
            "
          >
            <apx-chart
              [series]="summaryChartOptions.series!"
              [chart]="summaryChartOptions.chart"
              [dataLabels]="summaryChartOptions.dataLabels!"
              [plotOptions]="summaryChartOptions.plotOptions!"
              [colors]="summaryChartOptions.colors!"
              [yaxis]="summaryChartOptions.yaxis!"
              [xaxis]="summaryChartOptions.xaxis!"
              [fill]="summaryChartOptions.fill!"
              [title]="summaryChartOptions.title!"
            ></apx-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Data Statistics -->

<!-- Upload Template -->

<ng-template #uploadTemplate let-modal>
  <div class="modal-header">
    <h4 style="font-size: 22px" class="modal-title pull-left">Upload PIS</h4>
    <button
      type="button "
      class="close pull-right"
      aria-label="Close "
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group col-md-12" style="text-align: center">
          <p>Please select the action you want to perform</p>
          <div class="uploadInputContainer">
            <div
              class="uploadInput"
              [ngClass]="{ active: uploadType == 'manager' }"
            >
              <input
                class=""
                type="radio"
                name="uploadType"
                value="manager"
                [(ngModel)]="uploadType"
              />
              <label class="">Manager</label>
            </div>
            <div
              [ngClass]="{ active: uploadType == 'participant' }"
              class="uploadInput"
            >
              <input
                type="radio"
                name="uploadType"
                value="participant"
                [(ngModel)]="uploadType"
              />
              <label class="">Respondents</label><br />
            </div>
            <form (ngSubmit)="onSubmit(f)" #f="ngForm">
              <input
                style="width: 0; height: 0"
                id="fileSelect"
                type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                name="fileUpload"
                ngModel
                required
                #fileUpload="ngModel"
                #fileInput
                (change)="onFileChange($event)"
              />
              <button
                type="submit"
                [disabled]="!f.valid || !excelType"
                class="btn btn-primary"
              >
                Submit
              </button>
              <p
                *ngIf="!excelType && touched"
                style="color: red; font-size: 12px"
              >
                Invalid file type
              </p>
            </form>
          </div>
          <button class="btn btn-primary" (click)="fileInput.click()">
            Upload File
          </button>
          <p
            *ngIf="f.valid"
            style="
              background-color: whitesmoke;
              padding: 8px;
              border-radius: 10px;
              margin-top: 5px;
            "
          >
            <!-- {{ fileInput.files?.item(0).name }} -->
            {{ fileInput?.files?.item(0)?.name }}
          </p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #addDemographics let-modal>
  <div class="modal-header">
    <h4 style="font-size: 22px" class="modal-title pull-left">
      Add Company Demographics
    </h4>
    <button
      type="button "
      class="close pull-right"
      aria-label="Close "
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group col-md-12" style="text-align: center">
          <p>
            Please add the list of Demographics / You can also edit the already
            added one.
          </p>
          <p>{{ errorMessage }}</p>
          <input
            type="text"
            (input)="errorMessage = ''"
            [(ngModel)]="demographic"
          />

          <button
            type="submit"
            [disabled]="demographic.length == 0"
            (click)="
              manageDemographicsInList(
                isManagerUpload ? 'manager' : 'participant',
                'add'
              )
            "
          >
            Add
          </button>
          <div
            *ngFor="
              let demographic of isManagerUpload
                ? managersDemographicsList
                : participantsDemographicsList;
              let i = index
            "
          >
            <div>
              <p>{{ demographic }}</p>
              <button
                (click)="
                  manageDemographicsInList(
                    isManagerUpload ? 'manager' : 'participant',
                    'remove',
                    i
                  )
                "
              >
                &times;
              </button>
              <i
                matTooltip="Edit Demographic"
                matTooltipPosition="above"
                class="fa fa-edit"
                style="cursor: pointer; margin-left: 15px"
                (click)="
                  manageDemographicsInList(
                    isManagerUpload ? 'manager' : 'participant',
                    'edit',
                    i
                  )
                "
              ></i>
            </div>
          </div>
          <button type="submit" [disabled]="" (click)="fileInput.click()">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- Add Template -->
<ng-template #addTemplate>
  <div class="modal-header">
    <h4 style="font-size: 22px" class="modal-title pull-left">Add a entry</h4>
    <button
      type="button "
      class="close pull-right"
      aria-label="Close "
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group col-md-12">
          <form
            (ngSubmit)="addManagerOneByOne(addManagerForm)"
            #addManagerForm="ngForm"
          >
            <div class="row">
              <div class="col-md-6">
                <label>Manager Name<span class="asterick">*</span></label>
                <input
                  type="text"
                  name="name"
                  required
                  id="name"
                  [(ngModel)]="manager.name"
                  #name="ngModel"
                  class="form-control custom-form-control"
                  placeholder="Enter Manager's name"
                />
                <p
                  class="help-block"
                  style="color: red"
                  *ngIf="!name.valid && name.touched"
                >
                  Please Enter Manager's Name
                </p>
              </div>
              <div class="col-md-6">
                <label for="email"
                  >Manager Email<span class="asterick">*</span></label
                >
                <input
                  type="email"
                  name="email"
                  id="email"
                  [(ngModel)]="manager.email"
                  required
                  #email="ngModel"
                  (change)="checkManagerEmail($event)"
                  class="form-control custom-form-control"
                  placeholder="Enter Manager Email id"
                />
                <p
                  class="help-block"
                  style="color: red"
                  *ngIf="!email.valid && email.touched"
                >
                  Please Enter Manager's Email-Id
                </p>
                <p style="color: red" *ngIf="!inValidEmail && email.touched">
                  Please enter valid email Id.
                </p>
                <p
                  style="color: red"
                  *ngIf="!managerAddValidation && email.touched"
                >
                  Email Address already exists.
                </p>
              </div>
            </div>

            <div class="col-md-12">
              <div #formSection>
                <h2 style="margin: 0">Add Demographics</h2>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <!-- Step 1: Dropdown for selecting existing demographics -->
                  <div class="form-group col-md-12">
                    <label for="demographicNameSelect"
                      >Select Demographic</label
                    >
                    <select
                      class="form-control custom-form-control"
                      id="demographicNameSelect"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="selectedDemographic"
                      (change)="onDemographicSelect()"
                    >
                      <option value="" selected>Select a Demographic</option>
                      <option
                        *ngFor="let demographic of defaultDemographics"
                        [ngValue]="demographic.company_demographics_name"
                      >
                        {{
                          demographic.company_demographics_name
                            | textDemographicNameFormat
                        }}
                      </option>
                    </select>
                  </div>

                  <!-- Step 2: Show options for the selected demographic -->
                  <div
                    class="form-group col-md-12"
                    *ngIf="
                      (selectedDemographic &&
                        currentDemographicOptions.length > 0) ||
                      isDemoUpdate
                    "
                  >
                    <label for="demographicOptionSelect">Select Option</label>
                    <select
                      *ngIf="currentDemographicOptions.length > 0"
                      class="form-control custom-form-control"
                      id="demographicOptionSelect"
                      [(ngModel)]="selectedOption"
                      [ngModelOptions]="{ standalone: true }"
                    >
                      <option value="" selected>Select an Option</option>
                      <option *ngFor="let option of currentDemographicOptions">
                        {{ option }}
                      </option>
                    </select>

                    <input
                      *ngIf="currentDemographicOptions.length == 0"
                      type="text"
                      class="form-control custom-form-control"
                      placeholder="Enter Demographic Option Name"
                      [(ngModel)]="selectedOption"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </div>

                  <!-- Step 3: Button to selected demographic and options -->
                  <div class="form-group col-md-12 text-center mb-10">
                    <button
                      type="button"
                      class="gmi-primary-btn example-header"
                      (click)="
                        !isDemoUpdate ? addDemographic() : updateDemographic()
                      "
                    >
                      {{ !isDemoUpdate ? "Add" : "Update" }}
                    </button>
                  </div>

                  <!-- Step 4: Button to add new custom demographic and options -->
                  <div class="form-group col-md-12 text-center mb-10">
                    <button
                      type="button"
                      class="gmi-secondary-btn"
                      (click)="
                        showCustomDemographicForm = !showCustomDemographicForm
                      "
                    >
                      Add New Demographic
                    </button>
                  </div>

                  <!-- Form for adding a new custom demographic and its options -->
                  <div
                    *ngIf="showCustomDemographicForm || isCustomDemoEdit"
                    class=""
                  >
                    <div class="form-group mb-10">
                      <!-- <input
                        type="text"
                        class="form-control custom-form-control"
                        placeholder="Enter Demographic Name"
                        [(ngModel)]="newDemographicName"
                        [ngModelOptions]="{ standalone: true }"
                        #demographicNameInput="ngModel"
                        maxlength="50"
                        required
                      /> -->
                      <input
                        type="text"
                        class="form-control custom-form-control"
                        placeholder="Enter Demographic Name"
                        [(ngModel)]="newDemographicName"
                        [ngModelOptions]="{ standalone: true }"
                        #demographicNameInput="ngModel"
                        maxlength="50"
                        appNoSpaces
                        required
                        [matAutocomplete]="auto"
                        (input)="filterDemographics()"
                      />
                      <mat-autocomplete
                        #auto="matAutocomplete"
                        [displayWith]="displayFn"
                      >
                        <mat-option
                          *ngFor="let option of filteredDemographics"
                          [value]="option"
                        >
                          {{ option }}
                        </mat-option>
                      </mat-autocomplete>
                      <small
                        style="color: red"
                        *ngIf="
                          demographicNameInput.invalid &&
                          demographicNameInput.touched
                        "
                      >
                        Demographic Name is required and must not exceed 50
                        characters.
                      </small>
                    </div>
                    <div class="form-group mb-10">
                      <input
                        type="text"
                        class="form-control custom-form-control"
                        placeholder="Enter Option Name"
                        [(ngModel)]="newDemographicOption"
                        appNoSpaces
                        [ngModelOptions]="{ standalone: true }"
                        #demographicOptionInput="ngModel"
                        required
                        maxlength="50"
                      />
                      <small
                        style="color: red"
                        *ngIf="
                          demographicOptionInput.invalid &&
                          demographicOptionInput.touched
                        "
                      >
                        Demographic Name is required and must not exceed 50
                        characters.
                      </small>
                    </div>
                    <div class="form-group col-md-12 text-center mb-10">
                      <button
                        type="button"
                        class="btn btn-success"
                        (click)="
                          !isCustomDemoEdit
                            ? addCustomDemographic()
                            : updateCustomDemographic()
                        "
                      >
                        {{
                          !isCustomDemoEdit
                            ? "Save Demographic"
                            : "Update Demographic"
                        }}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div *ngIf="alreadyAddedDemographics.length > 0">
                    <h3 style="margin: 0">Already uploaded demographics:</h3>
                    <div
                      class=""
                      style="
                        display: flex;
                        justify-content: flex-start;
                        gap: 5px;
                        align-items: center;
                        flex-wrap: wrap;
                        border: 1px solid black;
                        padding: 10px;
                        border-radius: 5px;
                        margin-bottom: 10px;
                      "
                    >
                      <small
                        style="
                          margin: 0;
                          border: 1px solid #f82937cc;
                          border-radius: 5px;
                          padding: 2px 10px 2px 10px;
                          font-weight: bolder;
                          background: #faeaebcc;
                        "
                        *ngFor="let demographic of alreadyAddedDemographics"
                      >
                        {{ demographic }}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- List of selected demographics -->
            <div
              class="col-md-12 mb-10"
              *ngIf="currentManagerDemographics.length > 0"
            >
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th class="bold-text-v2">Demographics Name</th>
                    <th class="bold-text-v2">Option Name</th>
                    <th class="bold-text-v2">Edit</th>
                    <th class="bold-text-v2">Remove</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let demographic of currentManagerDemographics;
                      let i = index
                    "
                  >
                    <td>
                      {{
                        demographic.company_demographics_name
                          | textDemographicNameFormat
                      }}
                    </td>
                    <td>
                      {{ demographic.value }}
                    </td>
                    <td>
                      <!-- *ngIf="
                            defaultDemographicsArray.includes(
                              demographic?.company_demographics_name
                            )
                          " -->
                      <button
                        type="button"
                        class="btn btn-warning"
                        (click)="patchValues(demographic, i)"
                      >
                        <i class="fa fa-pencil" aria-hidden="true"></i>
                      </button>
                    </td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-danger"
                        (click)="removeDemographic(i)"
                      >
                        <i class="fa fa-trash" aria-hidden="true"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div style="text-align: center">
              <button
                [disabled]="
                  !addManagerForm.valid ||
                  !managerAddValidation ||
                  !inValidEmail
                "
                type="submit"
                style="
                  margin-top: 10px;
                  background-color: #e30615cc;
                  color: white;
                  border-radius: 5px;
                  width: 50%;
                  padding: 10px;
                  border: none;
                "
              >
                Add Manager
              </button>
              <!-- <input
                  type="submit"
                  class="form-control custom-form-control"
                  style="
                    margin-top: 10px;
                    background-color: #e30615cc;
                    color: white;
                    border-radius: 5px;
                    width: 50%;
                  "
                  [disabled]="
                    !addManagerForm.valid ||
                    !managerAddValidation ||
                    !inValidEmail
                  "
                  value="Add Manager"
                /> -->
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>
