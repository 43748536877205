<div class="container main">
  <div class="">
    <div class="row">
      <div class="col-md-5">
        <div class="form-group pb-10">
          <input
            class="form-control custom-form-control"
            required
            id="subtheme"
            name="subtheme"
            type="text"
            placeholder="Search Component name..."
            (keyup)="searchComponent($event)"
          />
        </div>
      </div>
      <div class="col-md-5 text-right">
        <button
          type="button"
          (click)="componentModal(addEditUserComponent, {}, false, {})"
          class="btn btn-secondary btn-newuser-clr"
        >
          Add New
        </button>
      </div>
    </div>
    <div class="Component-table">
      <mat-table matSort [dataSource]="dataSource" class="mat-elevation-z4">
        <ng-container matColumnDef="serialNo">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Serial No</mat-header-cell
          >
          <mat-cell
            class="text-center"
            *matCellDef="let element; let i = index"
            >{{ i + 1 }}</mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="component">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Component</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{
            element.component_name
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="defaultName">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Default Name</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{
            element.default_name
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="toolTip">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Tool Tip</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{
            element.default_tooltip_desc
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
          <mat-cell *matCellDef="let element" class="action-buttons">
            <!-- <mat-slide-toggle
              [checked]="element.is_active === 1"
              (change)="isActive(element.id, $event)"
            >
              {{ activeStatus }}
            </mat-slide-toggle> -->
            <button
              mat-icon-button
              matTooltip="Click to Edit"
              color="primary"
              (click)="
                componentModal(addEditUserComponent, element, true, element.id)
              "
              class="iconbutton"
            >
              <i class="fa fa-pencil"></i>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>

      <mat-paginator
        [pageSizeOptions]="[15, 30, 50]"
        showFirstLastButtons
      ></mat-paginator>

      <div
        class="text-center emptyContainer"
        *ngIf="dataSource.data.length === 0"
      >
        Not found any Components.
      </div>
    </div>
  </div>
</div>

<ng-template #addEditUserComponent>
  <button
    type="button "
    (click)="closeModal()"
    class="close"
    aria-label="Close "
  >
    &times;
  </button>
  <div class="modal-body">
    <form class="form" [formGroup]="componentForm">
      <label for="name"
        >Component Name:<small class="text-danger">*</small></label
      >
      <input
        formControlName="componentName"
        required
        type="text"
        placeholder="Enter name"
        maxlength="45"
      />
      <div
        *ngIf="
          componentForm.get('componentName').invalid &&
          componentForm.get('componentName').touched
        "
      ></div>

      <label for="page">Select Page<small class="text-danger">*</small></label>
      <select formControlName="pageId" required>
        <option [value]="null" disabled>Select a Page</option>
        <option *ngFor="let page of pageOptions" [value]="page.id">
          {{ page.page_name }}
        </option>
      </select>
      <div
        *ngIf="
          componentForm.get('pageId').invalid &&
          componentForm.get('pageId').touched
        "
      ></div>

      <label for="defaultName"
        >Default Name:<small class="text-danger">*</small></label
      >
      <input
        formControlName="defaultName"
        required
        type="text"
        placeholder="Enter name"
        maxlength="45"
      />

      <label for="tooltip"
        >Default Tool Tip:<small class="text-danger">*</small></label
      >
      <textarea
        formControlName="tooltip"
        required
        rows="3"
        placeholder="Enter tooltip"
        maxlength="350"
      ></textarea>

      <br />

      <button
        class="p-2"
        type="button"
        [disabled]="componentForm.invalid"
        (click)="
          isEdit
            ? editComponent(componentForm.get('_id').value)
            : addComponentName()
        "
      >
        {{ isEdit ? "Update Component" : "Add Component" }}
      </button>
      <br />
    </form>
  </div>
</ng-template>
