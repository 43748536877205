import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";

import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AdminServices } from "../../admin.service";
import swal from "sweetalert2";
import { Observable } from "rxjs";
import { map } from "rxjs/internal/operators/map";
import { THEMES_ARRAY } from "../../../../app/constants";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { MatSnackBar } from "@angular/material/snack-bar";
export interface Subthemes {
  theme: string;
  subtheme: string;
  themeId: string | number;
  subthemeId: string | number;
}
@Component({
  selector: "app-subtheme",
  templateUrl: "./subtheme.component.html",
  styleUrls: ["./subtheme.component.css"],
})
export class SubthemeComponent implements OnInit {
  public subThemeForm: FormGroup;
  dataSource = new MatTableDataSource<Subthemes>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  subthemesAsMatTableDataSource$: Observable<MatTableDataSource<Subthemes>> =
    this.adminService.getSubthemes.pipe(
      map((data) => {
        const dataSource = this.dataSource;
        dataSource.paginator = this.paginator;
        dataSource.data = data;
        return dataSource;
      })
    );
  displayedColumns = ["serialNo", "theme", "subtheme", "actions"];
  isEdit: boolean = false;
  themeArray: any[] = [];
  modalRef: BsModalRef;
  constructor(
    private adminService: AdminServices,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.getThemes();
    this.inItSubthemeForm();
    this.getSubthemes();
  }

  inItSubthemeForm() {
    this.subThemeForm = this.formBuilder.group({
      themeId: new FormControl("", [Validators.required]),
      subtheme: new FormControl("", [Validators.required]),

      subthemeId: new FormControl("", []),
    });
  }

  getThemes() {
    this.adminService.getThemes().subscribe((data) => {
      if (data.status) {
        this.themeArray = data.data;
      }
    });
  }

  getSubthemes() {
    this.adminService.getSubthemesData();
  }

  showSubthemeModal(template: TemplateRef<any>, subthemeData, isEdit) {
    this.isEdit = isEdit;
    if (this.isEdit) {
      this.subThemeForm.patchValue({
        themeId: subthemeData.themeId,
        subtheme: subthemeData.subtheme,
        subthemeId: subthemeData.subthemeId,
      });
    } else {
      this.subThemeForm.reset();
    }
    this.modalRef = this.modalService.show(template);
    this.adminService.addModal(this.modalRef);
  }

  deleteSubtheme(id) {
    swal
      .fire({
        title: "Survey Status",
        text: "Something went wrong!",
        icon: "error",
        showConfirmButton: true,
      })
      .then((result) => {
        if (result.value) {
          let data = {
            identifier: "delete",
            subthemeId: id,
          };
          this.subthemeOperationsService(data);
        }
      });
  }

  setData(identifier) {
    let data = {
      formData: this.subThemeForm.value,
      identifier: identifier,
    };
    identifier == "update"
      ? (data["subthemeId"] = this.subThemeForm.value.subthemeId)
      : "";

    this.subthemeOperationsService(data);
    this.modalRef.hide();
  }

  subthemeOperationsService(data) {
    this.adminService.subthemeOperations(data).subscribe((response) => {
      response && this.openSnackBar(response.message, response.status);
      if (response && response.status) {
        this.getSubthemes();
      }
      this.modalRef.hide();
    });
  }

  searchSubtheme(event: any) {
    const value = event.target.value;
    let filterValue = value.trim().toLowerCase();
    this.dataSource.filter = filterValue;
  }

  openSnackBar(message, status) {
    this._snackBar.open(message, "", {
      duration: 2000,
      panelClass: status ? ["green-snackbar"] : ["red-snackbar"],
    });
  }
}
