// import { Pipe, PipeTransform } from '@angular/core';
// @Pipe({
//   name: 'search'
// })
// export class FilterPipe implements PipeTransform {

//   transform(items: any[], searchText: string): any[] {
//     console.log(items);
//     console.log(searchText);
//     if(!items) return [];
//     if(!searchText) return items;
// searchText = searchText.toLowerCase();
// return items.filter( it => {
//       return it.actionName.toLowerCase().includes(searchText);
//       // value.replace(matchingString, "<mark>" + matchingString + "</mark>");
//     });
//    }
// }

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "search"
})
export class FilterPipe implements PipeTransform {
  public transform(value, keys: string, term: string) {
    if (!term) return value;
    return (value || []).filter(item =>
      keys
        .split(",")
        .some(
          key =>
            item.hasOwnProperty(key) && new RegExp(term, "gi").test(item[key])
        )
    );
  }
}
