<app-loader *ngIf="loading"></app-loader>
<a (click)="openSendMailModal(sendModal)" style="
    display: block;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
    text-decoration: none;
  ">Reset AP/L&D
    <span><i class="fa fa-info-circle info" aria-hidden="true" matTooltip="Reset AP/L&D of Managers"
            matTooltipClass="custom-tooltip"></i></span></a>
<ng-template #sendModal>
    <div class="modal-header">
        <h4 style="font-size: 22px" class="modal-title pull-left">
            Reset AP/L&D of selected managers.
        </h4>
        <button type="button " class="close pull-right" aria-label="Close " (click)="modalRef.hide()">
            <span aria-hidden="true ">&times;</span>
        </button>
    </div>
    <div *ngIf="selectedManagers.length > 0; else showAddManagerMessage" class="modal-body">
        <div>
            <p class="">{{ selectedManagers.length }} Managers Selected</p>
        </div>
        <div class="row">
            <div class="col-md-8 table-container">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let manager of selectedManagers">
                            <td>{{ manager.name }}</td>
                            <td>{{ manager.email }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-4 text-center">
                <div class="apld"><div><input id="checkbox" type="checkbox" [(ngModel)]="ap" />
                    <label for="checkbox">Action Plan</label>
                </div>
                <div><input id="checkbox" type="checkbox" [(ngModel)]="ld" />
                    <label for="checkbox">L&D</label>
                </div></div>
              
                <div class="form-group">
                  
                    <button class="btn btn-primary" [disabled]="this.selectedManagers.length == 0"
                        (click)="resetAPLD(assignedStatus)">
                        Submit
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #showAddManagerMessage>
    <div class="text-center">
        <p class="">Select at least 1 manager to reset AP/L&D.</p>
    </div>
</ng-template>

<ng-template #assignedStatus>
    <div class="modal-header">
        <h4 style="font-size: 22px" class="modal-title pull-left">Assign Status</h4>
        <button type="button " class="close pull-right" aria-label="Close " *ngIf="updateStatus"
            (click)="modalRef.hide()">
            <span aria-hidden="true ">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="status-container" *ngIf="totalCount > 0">
            <p class="title">Total no of Managers: {{ totalCount }}</p>
            <progress class="custom-progress" value="{{ completedCount }}" max="100">
                {{ completedCount }}
            </progress>
            <p class="status-count" *ngIf="!updateStatus">
                Updated <strong>{{ count }}</strong> of
                <strong>{{ totalCount }}</strong> Managers.
            </p>
            <div class="action-container">
                <p class="success-text">{{ statusText }}</p>
                <!-- <button class="btn gmi-primary-btn" (click)="redirectToSurveyPage()">
          Go to the survey page
        </button> -->
            </div>
        </div>
        <!-- <div class="message-container" *ngIf="totalCount == 0">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            <p class="loading-text">
                {{
                statusText !== ""
                ? statusText
                : "Please wait we are fetching the Data"
                }}
            </p>
        </div> -->
    </div>
</ng-template>