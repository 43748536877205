import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joinObjectValues',
})
export class JoinObjectValuesPipe implements PipeTransform {
  transform(arr: any[], separator: string = ', '): string {
    if (!arr) {
      return '';
    }
    if (arr.length === 1) {
      return Object.values(arr[0]).join('');
    } else {
      return arr
        .map((obj) => Object.values(obj).join(separator))
        .join(separator);
    }
  }
}
