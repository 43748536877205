import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { AdminServices } from "../../admin.service";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";

@Component({
  selector: "app-round-two-reports",
  templateUrl: "./round-two-reports.component.html",
  styleUrls: ["./round-two-reports.component.css"],
})
export class RoundTwoReportsComponent implements OnInit {
  isLoading: boolean = false;
  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });
  constructor(private adminService: AdminServices) {}

  ngOnInit(): void {}

  downloadReport(): void {
    this.isLoading = true;

    const selectedDateRange = {
      dateRange: this.range.value,
    };
    this.adminService.getR2Report(selectedDateRange).subscribe({
      next: (res: any) => {
        this.downloadExcel(res?.data, "R2-sheet");
        Swal.fire({
          icon: "success",
          title: res.message ?? "Your report has been download sucessfully",
          showConfirmButton: false,
          timer: 2000,
        });
      },
      error: (err) => {
        console.log(err.message);
        Swal.fire({
          icon: "error",
          title: err.error ?? "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 2000,
        });
        this.isLoading = false;
      },
      complete: () => {
        this.range.reset();
        this.isLoading = false;
      },
    });
  }
  downloadExcel(
    sheetData: any,
    fileName: string,
    sheetName: string = "Sheet1"
  ) {
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }
}
