<div class="container supervisor-container">
  <div class="desc-container shadow">
    <a
      href="javascript:void(0)"
      [routerLink]="['/company/company-dashboard']"
      class="create-new clr-grey"
      style="padding: 10px 0"
    >
      <em>
        <i
          class="fa fa-arrow-left"
          aria-hidden="true"
          style="margin-right: 5px"
        ></i> </em
      >Back</a
    >
    <p class="desc" style="margin: 0 !important">
      Check the supervisor details and launch the login details
    </p>
  </div>
  <div
    class="row shadow main-container"
    *ngIf="superVisorLists.length > 0; else defaultMessage"
  >
    <div class="col-md-6">
      <p class="sub-title">Supervisor List</p>
      <div class="select-box">
        <a href="javascript:void()" style="cursor: pointer">
          <label style="cursor: pointer; font-size: 16px" for="checkbox">{{
            text
          }}</label></a
        >
        <input
          style="display: none"
          id="checkbox"
          type="checkbox"
          [(ngModel)]="checkall"
          (change)="checkUncheckAll()"
        />
      </div>
      <div class="supervisor-list">
        <accordion>
          <accordion-group
            *ngFor="let supervisor of superVisorLists; let i = index"
          >
            <div accordion-heading>
              <p class="header-text">
                {{ supervisor._id }} ({{ supervisor.managers.length }})
              </p>
              <input
                style="float: right; width: 15px; height: 15px"
                id="checkbox{{ i }}"
                type="checkbox"
                [(ngModel)]="supervisor.checked"
                (change)="
                  updateCheckall(supervisor, $any($event.target).checked)
                "
              />
              <label for="checkbox{{ i }}"></label>
            </div>
            <p class="title">Manager's details</p>

            <ul class="my-list-cont panel">
              <li *ngFor="let manager of supervisor.managers">
                {{ manager.email }}
              </li>
            </ul>
            <p class="inner-text"></p>
          </accordion-group>
        </accordion>
      </div>
    </div>
    <div class="col-md-6">
      <p class="sub-title">Send the mail</p>
      <div class="form-container">
        <label>Subject <span class="error">*</span></label>
        <input
          type="text"
          placeholder="Enter the subject"
          name="subject"
          (change)="handleSubjectChange($event)"
        />
        <span class="error">{{ subjectError }}</span>
      </div>
      <div class="form-container">
        <label>Placeholders:</label>
        <p class="text">%NAME% , %URL%</p>
        <label>Message <span class="error">*</span></label>
        <ckeditor
          #editor
          class="testing"
          [editor]="Editor"
          data="{{ describe }}"
          (change)="handleEditorChange($event)"
        ></ckeditor>
        <span class="error">{{ messageError }}</span>
      </div>
      <div class="button-container">
        <button class="submit-button" (click)="onSubmitClick()">
          <span> Send</span>
          <i class="fa fa-paper-plane" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #defaultMessage>
  <h2>No Supervisor is found!</h2>
</ng-template>
