import { FormControl, FormBuilder, FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { startWith } from "rxjs";
import { map } from "rxjs";
import { debounceTime } from "rxjs";
import { distinctUntilChanged } from "rxjs";

import { SurveyEngineService } from "./../../services/surveyEngine.service";
import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
} from "@angular/core";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "app-company-input",
  template: `<div class="form-group col-md-12 ptb-15">
    <label
      for="title"
      style="font-size: 12px !important;font-weight: 500 !important;"
      >Company Title<span class="asterick">*</span>
    </label>
    <input
      type="text"
      placeholder="Search & select your current company"
      aria-label="Number"
      matInput
      name="company"
      style="background-color: #fff;border: 1px solid #ccc;padding-left: 3%;"
      [formControl]="myControl"
      [matAutocomplete]="auto"
      class="form-control custom-form-control searchInput"
      (change)="companyAddedByChange()"
      required
    />
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
      <mat-option *ngIf="isLoading" class="is-loading">
        <mat-spinner diameter="50"></mat-spinner>
      </mat-option>
      <ng-container *ngIf="!isLoading">
        <mat-option
          *ngFor="let option of filteredOptions | async"
          [value]="option"
          (onSelectionChange)="companyAdded($event)"
        >
          {{ option }}
        </mat-option>
      </ng-container>
    </mat-autocomplete>
    <!-- <p class="adding-info" *ngIf="newCompanyAlert">
      You have added a new company out of this list
    </p>
    <p class="warning" *ngIf="alreadyExistsAlert">Company already exists</p> -->
  </div>`,
  styleUrls: ["../corporate-companies/corporate-companies.component.css"],
})
export class CompanyInputComponent implements OnInit {
  myControl: FormControl = new FormControl();
  options = [];
  companyId = [];
  filteredOptions: Observable<string[]>;
  newCompanyAlert: boolean = false;
  alreadyExistsAlert: boolean = false;
  result: any;
  data = "";
  isLoading = false;

  @Output()
  outPutToParent = new EventEmitter<string>();
  @Output()
  outPutIsNewCompanyToParent = new EventEmitter<boolean>();
  @Output()
  companyIdOutPutToParent = new EventEmitter<number>();

  constructor(private surveyEngineService: SurveyEngineService) {}

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      debounceTime(1000),
      distinctUntilChanged(),
      map((val) => {
        this.isLoading = true;
        if (val != "") {
          this.isLoading = false;
          return this.filter(val);
        }
        this.isLoading = false;
        return null;
      })
    );

    this.surveyEngineService.getAllCompanies().subscribe((response: any) => {
      response.data.forEach((company) => {
        this.options.push(company.name);
        this.companyId.push(company.id);
      });
    });
  }

  companyAdded(val) {
    //Sending Data to parent Form
    let isNew = this.checkNewCompany(val.source.value);
    if (isNew) {
      this.outPutToParent.emit(val.source.value);
      this.companyIdOutPutToParent.emit(0);
    } else {
      this.companyIdOutPutToParent.emit(this.companyId[this.result]);
      this.outPutToParent.emit(this.options[this.result]);
    }
    this.outPutIsNewCompanyToParent.emit(isNew);
  }

  companyAddedByChange() {
    //Sending Data to parent Form
    let isNew = this.checkNewCompany(this.myControl.value);
    if (isNew) {
      this.outPutToParent.emit(this.myControl.value);
      this.companyIdOutPutToParent.emit(0);
    } else {
      this.outPutToParent.emit(this.options[this.result]);
      this.companyIdOutPutToParent.emit(this.companyId[this.result]);
    }
    this.outPutIsNewCompanyToParent.emit(isNew);
  }

  checkNewCompany(value) {
    this.result = this.options.findIndex(
      (item) => value.toLowerCase() == item.toLowerCase()
    );
    if (this.result != -1) {
      //In array
      this.newCompanyAlert = false;
      this.alreadyExistsAlert = true;
      return this.newCompanyAlert;
    } else {
      //Not in array
      this.alreadyExistsAlert = false;
      this.newCompanyAlert = true;
      return this.newCompanyAlert;
    }
  }

  filter(val: string): string[] {
    return this.options.filter(
      (option) => option.toLowerCase().indexOf(val.toLowerCase()) === 0
    );
  }
}
