import { Component, OnInit } from '@angular/core';
import { CompanyServices } from '../company.service';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from '../../../environments/environment';
import { isDevMode } from '@angular/core';
import { constants, INSTRUMENT_OPTIONS_ARRAY } from '../../constants';
@Component({
  selector: 'app-company-action-bank',
  templateUrl: './company-action-bank.component.html',
  styleUrls: ['./company-action-bank.component.css'],
})
export class CompanyActionBankComponent implements OnInit {
  actionPlans: any = [];
  loader!: boolean;
  subthemes: any = [
    // { id: 1, name: "Fair Treatment" },
    // { id: 2, name: "Information Sharing" },
    // { id: 3, name: "Approachability" },
    // { id: 4, name: "Candid Communication" },
    // { id: 5, name: "Feedback Seeking" },
    // { id: 6, name: "Reliability" },
    // { id: 7, name: "Care for Individual" },
    // { id: 8, name: "Work-Life Balance" },
    // { id: 9, name: "Involvement in Decisions" },
    // { id: 10, name: "Feedback Sharing" },
    // { id: 11, name: "Development Focus" },
    // { id: 12, name: "Guidance" },
    // { id: 13, name: "Career Growth" },
    // { id: 14, name: "Personal Best" },
    // { id: 15, name: "Expectation Setting" },
    // { id: 16, name: "Recognition" },
    // { id: 17, name: "Fun & Celebrations" },
  ];
  filterText = '';
  actionName = '';
  selectedSubtheme = '';
  selectedDuration = '';
  selectedDescription = '';
  selectedTheme = '';
  loading!: boolean;
  type!: string;
  mgrId: any;
  durationArray = [
    { name: 'Select' },
    { name: 'Annually' },
    { name: 'Bi-Annually' },
    { name: 'Bi-Monthly' },
    { name: 'Bi-Weekly' },
    { name: 'Daily' },
    { name: 'Fortnightly' },
    { name: 'Monthly' },
    { name: 'Quarterly' },
    { name: 'Weekly' },
  ];
  themeArray = [{ name: 'Connect' }, { name: 'Develop' }, { name: 'Inspire' }];
  optionsArray = INSTRUMENT_OPTIONS_ARRAY;
  constructor(
    private companyService: CompanyServices,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.mgrId = this.route.snapshot.params['id'];
    this.type = this.route.snapshot.params['type'];
  }

  getActionBank(data: any) {
    this.loader = true;
    this.loading = true;
    this.companyService.getActionPlanBank(data).subscribe((res) => {
      this.loader = false;
      this.loading = false;
      if (res.status) {
        this.actionPlans = this.removeDuplicates(res.data, 'actionName');
      }
    });
  }

  removeDuplicates(myArr: any, prop: any) {
    return myArr.filter((obj: any, pos: any, arr: any) => {
      return arr.map((mapObj: any) => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }
  selectedActionPlan(data: any) {
    this.actionName = data.actionName;
    this.selectedDuration = data.duration;
    this.selectedSubtheme = data.subtheme;
    this.selectedTheme = data.theme;
    this.selectedDescription = data.description;
  }
  onSubmit(formData: NgForm) {
    let addActionPlan = {
      actionName: formData.value.actionName,
      duration: formData.value.selectedDuration,
      subtheme: formData.value.selectedSubtheme,
      theme: formData.value.selectedTheme,
      description: formData.value.description,
      mgrId: this.mgrId,
    };
    formData.reset();
    this.selectedDuration = '';
    this.selectedSubtheme = '';
    this.selectedTheme = '';
    this.loading = true;
    if (this.type === 'single') {
      this.companyService
        .addActionPlanToBank(addActionPlan)
        .subscribe((res) => {
          this.loading = false;
          if (res.status) {
            Swal.fire({
              title: 'Company Action Plan',
              text: res.message,
              icon: 'success',
              showConfirmButton: true,
              timer: 2500,
            });
          } else {
            Swal.fire({
              title: 'Company Action Plan',
              text: res.message,
              icon: 'error',
              showConfirmButton: true,
              timer: 2500,
            });
          }
        });
    } else if (this.type === 'bulk') {
      this.companyService
        .addActionPlanToBankBulk(addActionPlan)
        .subscribe((res) => {
          this.loading = false;
          console.log(res);
        });
    }
  }
  filterSubtheme() {
    // console.log(this.filterText ,'filter')
    let data = {
      subtheme: this.filterText.trim(),
    };
    this.getActionBank(data);
  }

  onSelectInstrument(value: any) {
    let data = {
      masterQuesionairId: value,
    };
    this.getSubthemes(data);
  }

  getSubthemes(data: any) {
    this.companyService.getSubthemes(data).subscribe((res) => {
      if (res.status) {
        this.subthemes = res.data.filter((subtheme: any) => {
          return (
            subtheme.theme !== undefined &&
            subtheme.theme !== 'No theme' &&
            subtheme.theme !== 'Overriding'
          );
        });
      }
    });
  }
}
