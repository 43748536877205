import { Component, OnInit } from '@angular/core';
import { CompanyServices } from '../../company.service';
import * as XLSX from 'xlsx';
import * as EXCELJS from 'exceljs';
import { saveAs } from 'file-saver';
// import * as jwt_decode from 'jwt-decode';
import { jwtDecode } from 'jwt-decode';
import { ExcelService } from '../../../excel-service.service';

@Component({
  selector: 'app-survey-reports',
  templateUrl: './survey-reports.component.html',
  styleUrls: ['./survey-reports.component.scss'],
})
export class SurveyReportsComponent implements OnInit {
  reportType = 'managerReport';
  totalResponseRate!: number;
  managerReport: any = [];
  scoreSheetReport: any = [];
  surveyReponseReport: any = [];
  leadershipReport: any = [];
  dropdownList: any = [];
  selectedItems: any = [];
  dropdownSettings = {};
  dropdownSettings2 = {};
  loading: Boolean = false;
  allPossibleFilters: any = [];
  finalDisplayFilters: any = [];
  constructor(
    private companyService: CompanyServices,
    private excelService: ExcelService
  ) {}
  companyDetails: any = {};
  gmcCriteria: any = {};
  ngOnInit() {
    // this.loadReport(this.reportType);
    this.getPossibleFilters();
    this.decodeCompanyDetails();
    this.dropdownSettings = {
      singleSelection: false,
      text: 'Select Filters',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'fieldFilterDropdown',
      maxHeight: 160,
    };
    this.dropdownSettings2 = {
      singleSelection: false,
      text: 'Select Filters',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'fieldFilterDropdown',
      maxHeight: 160,
    };
  }

  decodeCompanyDetails() {
    if (localStorage.getItem('token') != null) {
      let token: any = localStorage.getItem('token');
      this.companyDetails = jwtDecode(token);
    }
  }

  getManagerReport(params: any, excelDownload = false) {
    this.loading = true;
    params['filters'] = this.finalDisplayFilters;
    params['excelDownload'] = excelDownload;
    this.companyService
      .getCompanyManagersReports(params)
      .subscribe((res: any) => {
        this.loading = false;
        // if (res.status) {

        // }
        if (excelDownload) {
          // const wb: XLSX.WorkBook = XLSX.utils.book_new();
          // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.firstSheet);
          // const ws1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.secondSheet);
          // const ws2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.thirdSheet);
          // const ws3: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.fourthSheet);
          // XLSX.utils.book_append_sheet(wb, ws, "Report after survey");
          // XLSX.utils.book_append_sheet(wb, ws1, "Report after action planning");
          // XLSX.utils.book_append_sheet(wb, ws2, "Report during implementation");
          // XLSX.utils.book_append_sheet(wb, ws3, "Report after final survey");
          // XLSX.writeFile(wb, "ManagerReport.xlsx");
          // let wb = XLSX.read(res.data, { type: "buffer" });
          // XLSX.writeFile(wb, "ManagerReport.xlsx");
          var byteArray = new Uint8Array(res.data);
          this.excelService.saveAsExcelFile(byteArray, 'Manager Report.xlsx');
          // var a = window.document.createElement("a");
          // a.href = window.URL.createObjectURL(
          //   new Blob([byteArray], { type: "application/octet-stream" })
          // );

          // a.download = "Manager Report.xlsx";

          // document.body.appendChild(a);
          // a.click();
          // document.body.removeChild(a);
        } else {
          this.managerReport = {
            reportAfterSurvey: res.firstSheet,
            reportAfterAP: res.secondSheet,
            reportDuringImplementation: res.thirdSheet,
            reportAfterFinalSurvey: res.fourthSheet,
          };
        }
      });
  }

  returnZero() {
    return 0;
  }

  getScoreSheetReport(params: any = {}, excelDownload = false) {
    this.loading = true;
    params['filters'] = this.finalDisplayFilters;
    params['excelDownload'] = excelDownload;
    this.companyService
      .getCompanyScoreSheetReport(params)
      .subscribe((res: any) => {
        this.loading = false;
        // console.log(res.data,'1')
        if (excelDownload) {
          // const wb: XLSX.WorkBook = XLSX.utils.book_new();
          // console.log(res.firstSheet, "first sheet");
          // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.firstSheet);
          // const ws1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.secondSheet);
          // XLSX.utils.book_append_sheet(wb, ws, "SelfAssesment");
          // XLSX.utils.book_append_sheet(wb, ws1, "Team Feedback ");
          // XLSX.writeFile(wb, "ScoreSheet.xlsx");
          // let wb = XLSX.read(res.data, { type: "buffer" });
          // XLSX.writeFile(wb, "ScoreSheet.xlsx");
          var byteArray = new Uint8Array(res.data);
          this.excelService.saveAsExcelFile(
            byteArray,
            'Manager Scoresheet.xlsx'
          );
          // var a = window.document.createElement("a");
          // a.href = window.URL.createObjectURL(
          //   new Blob([byteArray], { type: "application/octet-stream" })
          // );

          // a.download = "Manager Scoresheet.xlsx";

          // document.body.appendChild(a);
          // a.click();
          // document.body.removeChild(a);
        } else {
          this.scoreSheetReport = {
            selfAssessment: res.firstSheet,
            teamFeedback: res.secondSheet,
          };
        }
      });
  }

  getSurveyResponseRate(params: any = {}, excelDownload = false) {
    this.loading = true;
    params['filters'] = this.finalDisplayFilters;
    this.companyService.getResponseRate(params).subscribe((res: any) => {
      this.loading = false;
      let total = 0;
      let actualParticipant = 0;
      //console.log("res.data",res.data);
      res.data.forEach((responseRate: any) => {
        //console.log("responseRate",responseRate);
        total += Number(responseRate['#Team Members']);
        //console.log("total", responseRate["#Team Members"]);
        actualParticipant += Number(responseRate['#Respondents']);
        //console.log("actualParticipant", responseRate["#Respondents"]);
      });
      this.totalResponseRate = (actualParticipant / total) * 100;
      this.totalResponseRate = Number(this.totalResponseRate.toFixed(2));
      //console.log(this.totalResponseRate,"TOTAL RESPONSE RATE");
      if (res.status) {
        if (excelDownload) {
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.data);
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
          this.excelService.exportAsExcelFile(wb, 'Response Rate.xlsx');
        } else {
          this.surveyReponseReport =
            typeof res.data !== 'undefined' ? res.data : [];
        }
      }
    });
  }

  getLeadershipReport(params: any = {}, excelDownload = false) {
    this.loading = true;
    params['filters'] = this.finalDisplayFilters;
    if (excelDownload && this.leadershipReport.length > 0) {
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
        this.leadershipReport
      );
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      this.excelService.exportAsExcelFile(wb, 'Leadership Dashboard.xlsx');
      this.loading = false;
    } else {
      this.companyService.getLeadershipReport(params).subscribe((res: any) => {
        this.loading = false;
        if (res.status) {
          if (excelDownload) {
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.data);
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            this.excelService.exportAsExcelFile(
              wb,
              'Leadership Dashboard.xlsx'
            );
          } else {
            this.leadershipReport =
              typeof res.data.managerLeaderboardData !== 'undefined'
                ? res.data.managerLeaderboardData
                : [];

            this.gmcCriteria =
              typeof res.data.gmcCriteria !== 'undefined'
                ? res.data.gmcCriteria
                : {};
          }
        }
      });
    }
  }

  loadReport(reportType: any) {
    switch (reportType) {
      case 'managerReport':
        // this.getManagerReport();
        break;
      case 'scoresheet':
        // this.getScoreSheetReport();
        break;
      case 'surveyResponse':
        this.getSurveyResponseRate();
        break;
      case 'masterReport':
        // this.getMasterReport();
        break;
      case 'leaderShipReport':
        this.getLeadershipReport();
        break;
    }
  }

  getMasterReport(params: any = {}, excelDownload = false) {
    this.loading = true;
    params['filters'] = this.finalDisplayFilters;
    params['excelDownload'] = excelDownload;
    this.companyService.getSurveyMasterReport(params).subscribe((res: any) => {
      this.loading = false;
      if (excelDownload) {
        // var byteArray = new Uint8Array(res.data);
        // var a = window.document.createElement('a');
        // a.href = window.URL.createObjectURL(
        //   new Blob([byteArray], { type: 'application/octet-stream' })
        // );

        // a.download = 'MIS.xlsx';

        // document.body.appendChild(a);
        // a.click();
        // document.body.removeChild(a);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.masterSheet);
        const ws1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.focusAreas);
        const ws2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.surveyThemes);
        const ws3: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
          res.surveyComments
        );
        const ws4: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
          res.actionPlanManager
        );
        const ws5: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
          res.actionPlanTeamMember
        );
        const ws6: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.actionPlanAll);
        XLSX.utils.book_append_sheet(wb, ws, 'Mastersheet');
        XLSX.utils.book_append_sheet(wb, ws1, 'Focus areas for AP');
        XLSX.utils.book_append_sheet(wb, ws2, 'Scores across themes');
        XLSX.utils.book_append_sheet(wb, ws3, 'Team Comments');
        XLSX.utils.book_append_sheet(wb, ws4, 'RM Feedback on Action Plans');
        XLSX.utils.book_append_sheet(wb, ws5, 'TM Feedback on Action Plans');
        XLSX.utils.book_append_sheet(wb, ws6, 'Feedback on Action Plans');
        this.excelService.exportAsExcelFile(wb, 'MIS.xlsx');
      }
    });
  }

  downloadExcel() {
    switch (this.reportType) {
      case 'managerReport':
        this.getManagerReport({}, true);
        break;
      case 'scoresheet':
        this.getScoreSheetReport({}, true);
        break;
      case 'surveyResponse':
        this.getSurveyResponseRate({}, true);
        break;
      case 'masterReport':
        this.getMasterReport({}, true);
        break;
      case 'leaderShipReport':
        // this.getLeadershipReport({}, true);
        this.downloadLeadershipReport();
        break;
    }
  }

  onReportTypeChange(evt: any) {
    this.reportType = evt.target.value;
    this.loadReport(evt.target.value);
  }

  getPossibleFilters() {
    this.loading = true;
    this.companyService.getAllPossibleFilters().subscribe((res: any) => {
      if (res.status) {
        this.loading = false;
        let data: any = res.data;
        data.forEach((filter: any) => {
          if (filter.options.length > 0) {
            this.dropdownList.push({
              id: filter.dbName,
              itemName: filter.filterName,
            });
            this.allPossibleFilters.push(filter);
          }
        });
      }
    });
  }

  onItemSelect(item: any) {
    let newObject: any = {};
    this.allPossibleFilters.forEach((filter: any) => {
      if (filter.dbName == item.id) {
        newObject = filter;
        newObject['selectedItems'] = [];
        newObject['newOptions'] = [];
        filter.options.map((option: any) => {
          newObject['newOptions'].push({
            id: option,
            itemName: option,
          });
        });
        this.finalDisplayFilters.push(newObject);
      }
    });
  }

  OnItemDeSelect(item: any) {
    this.finalDisplayFilters = this.finalDisplayFilters.filter(
      (filter: any) => {
        return filter.dbName != item.id;
      }
    );
  }

  onSelectAll(items: any) {
    this.selectedItems = items;
    let alreadyIncluded: any = [];
    let newObject: any = {};
    this.finalDisplayFilters.map((already: any) => {
      alreadyIncluded.push(already.dbName);
    });
    this.selectedItems.forEach((item: any) => {
      this.allPossibleFilters.forEach((filter: any) => {
        if (filter.dbName == item.id && !alreadyIncluded.includes(item.id)) {
          newObject = filter;
          newObject['selectedItems'] = [];
          newObject['newOptions'] = [];
          filter.options.map((option: any) => {
            newObject['newOptions'].push({
              id: option,
              itemName: option,
            });
          });
          this.finalDisplayFilters.push(newObject);
        }
      });
    });
  }

  onDeSelectAll(items: any) {
    this.selectedItems = items;
    this.finalDisplayFilters = [];
  }

  onFilterItemSelect(item: any) {}

  onFilterItemDeSelect(item: any) {}

  onFilterSelectAll(items: any, itemId: any) {
    this.finalDisplayFilters.map((filter: any, index: any) => {
      if (filter.dbName == itemId) {
        this.finalDisplayFilters[index]['selectedItems'] = items;
      }
    });
  }

  onFilterDeSelectAll(items: any, itemId: any) {
    this.finalDisplayFilters.map((filter: any, index: any) => {
      if (filter.dbName == itemId) {
        this.finalDisplayFilters[index]['selectedItems'] = items;
      }
    });
  }

  onReloadReport() {
    this.loadReport(this.reportType);
  }

  onOpen() {
    this.dropdownList = [...this.dropdownList];
  }

  downloadLeadershipReport() {
    if (this.leadershipReport.length > 0) {
      const headers = Object.keys(this.leadershipReport[0]);
      const workbook = new EXCELJS.Workbook();
      const worksheet = workbook.addWorksheet('Post Project MIS Data');
      const boldStyle = { bold: true, size: 18, color: { argb: 'ffffffff' } };
      worksheet.getCell('A1').value = '';
      worksheet.mergeCells('A1:B2');

      worksheet.getCell('C1').value = 'Demographics';
      worksheet.mergeCells('C1:H2');

      worksheet.getCell('I1').value = 'Pre Survey';
      worksheet.mergeCells('I1:R2');
      worksheet.getCell('S1').value = 'Post Survey';
      worksheet.mergeCells('S1:AB2');
      worksheet.getCell('AC1').value = 'Qualifying Checks';

      worksheet.mergeCells('AC1:AK2');
      worksheet.mergeCells('AL1:AM2');

      let header = worksheet.getRow(1);
      header.height = 50;

      header.eachCell((cell: any, cellIndex: any) => {
        cell.font = boldStyle;
        cell.alignment = {
          horizontal: 'center',
          vertical: 'middle',
        };
        if (cellIndex < 3) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF2F5496' },
          };
        }
        if (cellIndex > 2 && cellIndex < 10) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFC55A11' },
          };
        }
        if (cellIndex > 9 && cellIndex < 20) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF548135' },
          };
        }
        if (cellIndex > 20 && cellIndex < 30) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF2D75B5' },
          };
        }
        if (cellIndex > 29 && cellIndex < 38) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFB9002' },
          };
        }
      });

      let headerRowOne = worksheet.addRow(headers);
      let headerRowTwo = worksheet.getRow(3);
      headerRowTwo.height = 50;

      let subHeader = worksheet.addRow([
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        'Yes / No',
        'Not more than 10 point drop',
        'High Moderate Low',
        'Min ' + this.gmcCriteria.learningDevelopmentMin + ' modules',
        'Min' + this.gmcCriteria.implementationMin + ' posts',
        '60% or more on at least 1 plan',
        'Yes / No',
        'Enter Score',
        'Certified / Not Certified ',
        '',
        '',
      ]);
      let headerRowThree = worksheet.getRow(4);
      headerRowThree.height = 30;

      subHeader.eachCell((cell: any) => {
        cell.font = { bold: true, size: 11, color: { argb: 'ffc00000' } };
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
      });

      headerRowOne.eachCell((cell: any, cellIndex: any) => {
        cell.font = {
          bold: true,
          size: 12,
        };
        cell.border = {
          left: { style: 'thin' },
          right: { style: 'thin' },
        };
        cell.alignment = {
          horizontal: 'center',
          vertical: 'middle',
        };
        if (cellIndex < 3) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFB4C6E7' },
          };
        }
        if (cellIndex > 2 && cellIndex < 9) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFEF2CB' },
          };
        }
        if (cellIndex > 8 && cellIndex < 19) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFA8D08D' },
          };
        }
        if (cellIndex > 18 && cellIndex < 29) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'ff9cc2e5' },
          };
        }
        if (cellIndex > 28 && cellIndex < 38) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'ffffe598' },
          };
        }
        if (cellIndex > 37) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'ffffff99' },
          };
        }
      });

      headers.forEach((header, index) => {
        const column = worksheet.getColumn(index + 1);
        column.width = Math.min(40, header.length + 2);
      });

      this.leadershipReport.forEach((rowData: any, index: any) => {
        const row = worksheet.addRow(Object.values(rowData));
        row.eachCell((cell: any) => {
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
        });
      });

      workbook.xlsx.writeBuffer().then((buffer: any) => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(blob, `${this.companyDetails.company} Leaderboard.xlsx`);
      });
    }
  }
}
