import { LoginServices } from '../../../login/login.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormControlName,
  Validators,
} from '@angular/forms';
import { HttpResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { CompanyServices } from '../../company.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm!: FormGroup;
  data: any;
  modal: any;
  el!: HTMLElement;

  @ViewChild('myDiv') myDiv!: ElementRef;

  constructor(
    private ls: LoginServices,
    private router: Router,
    private companyService: CompanyServices
  ) {}

  ngOnInit() {
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      role: new FormControl('company'),
    });
  }

  onResetPassword() {}

  onforgotpassword() {
    this.ls
      .forgotPassword(this.forgotPasswordForm.value)
      .subscribe((response: Response) => {
        this.data = response;
        console.log(response);
        if (this.data.status) {
          // document.getElementById("openModalButton").click();
          this.el = this.myDiv.nativeElement as HTMLElement;
          this.el.click();
        } else {
          Swal.fire({
            title: 'Forgot Password',
            text: this.data.message,
            icon: 'error',
            showConfirmButton: true,
            timer: 3500,
          });
        }
      });
  }

  checkemail() {
    if (this.forgotPasswordForm.value.email) {
      return false;
    } else {
      return true;
    }
  }

  onSet() {
    this.router.navigate(['/company']);
  }

  onClickX() {
    this.router.navigate(['/company']);
  }
}
