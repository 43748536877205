import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "firstPart",
})
export class FirstPartPipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      const parts = value.split("-");
      return parts[0].trim();
    }
    return value;
  }
}
