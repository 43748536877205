import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { AdminServices } from "../admin.service";
import { Validators, Editor, Toolbar } from "ngx-editor";
import swal from "sweetalert2";
interface NudgeData {
  template_name: string;
  totalEmailsSent: number;
  totalEmailsOpened: number;
  totalEmailsClicked: number;
  openRate: string;
  clickRate: string;
}

// Define the structure for the email map
type EmailMap = { [email: string]: NudgeData[] };
@Component({
  selector: "app-nudge-ab",
  templateUrl: "./nudge-ab.component.html",
  styleUrls: ["./nudge-ab.component.css"],
})
export class NudgeAbComponent implements OnInit {
  dataSource = new MatTableDataSource<any>();
  analysisDataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatPaginator) analysisPaginator: MatPaginator;
  modalRef: BsModalRef;
  emailMap: EmailMap = {};
  displayedColumns: string[] = [
    "ID",
    "Template Name",
    "Template Subject",
    "Template Content",
    "Total Sent",
    "Total Opened",
    "Open Rate",
    "Total Clicked",
    "Click Rate",
    "Status",
    "Action",
  ];
  analysisDisplayedColumns: string[] = [
    "Manager Email",
    "Template Name",
    "Total Sent",
    "Total Opened",
    "Total Clicked",
    "Open Rate",
    "Click Rate",
  ];
  public placeholders: string[] = [
    "%COMPANY_ICON%",
    "%NAME%",
    "%STREAK%",
    "%STREAK_BAR%",
    "%TITLE%",
    "%DESCRIPTION%",
    "%LEVEL%",
  ];
  editor: Editor;
  template_content;
  toolbar: Toolbar = [
    ["bold", "italic"],
    ["underline", "strike"],
    ["code", "blockquote"],
    ["ordered_list", "bullet_list"],
    [{ heading: ["h1", "h2", "h3", "h4", "h5", "h6"] }],
    ["link", "image"],
    ["text_color", "background_color"],
    ["align_left", "align_center", "align_right", "align_justify"],
  ];
  constructor(
    private adminService: AdminServices,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.editor = new Editor();
    this.getNudgeEmail();
    this.getActiveABTestUsers();
  }
  onContentChange(event) {}
  getNudgeEmail() {
    this.adminService.getNudgeEmail().subscribe((res: any) => {
      if (res.status) {
        this.dataSource.data = res.data;
        this.dataSource.paginator = this.paginator;
      } else {
        swal.fire("Error", res.message ? res.message : res.error);
      }
    });
  }
  getActiveABTestUsers() {
    this.adminService.getActiveABTestUsers().subscribe((res: any) => {
      if (res.status) {
        this.emailMap = res.data;
        this.analysisDataSource.data = res.data;
        this.analysisDataSource.paginator = this.analysisPaginator;
      } else {
        swal.fire("Error", res.message ? res.message : res.error);
      }
    });
  }
  deactivateEmailTemplate(email) {
    let data = {
      id: email.id,
      status: !email.status,
    };
    this.adminService.deactivateEmailTemplate(data).subscribe((res: any) => {
      if (res.status) {
        swal.fire("Success", res.message);
        this.getNudgeEmail();
      } else {
        swal.fire("Error", res.message);
      }
    });
  }
  hideEditModal() {
    this.modalRef.hide();
  }
  openEmailView(template: TemplateRef<any>, content) {
    this.template_content = content;
    const config = { class: "modal-lg" };
    this.modalRef = this.modalService.show(template, config);
    this.adminService.addModal(this.modalRef);
  }
}
