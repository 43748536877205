import {
  Component,
  OnInit,
  TemplateRef,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from "@angular/core";
import * as xlsx from "xlsx";
import { CompanyServices } from "../company.service";
import { NgForm } from "@angular/forms";
import * as XLSX from "xlsx";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
// import * as jwt_decode from "jwt-decode";
import { jwtDecode } from "jwt-decode";
import { DataService } from "../data.service";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import swal from "sweetalert2";
import { saveAs } from "file-saver";
import {
  ApexAxisChartSeries,
  ChartComponent,
  ApexChart,
  ApexDataLabels,
  ApexXAxis,
  ApexPlotOptions,
  ApexYAxis,
  ApexTitleSubtitle,
  ApexFill,
  ApexLegend,
  ApexGrid,
  ApexOptions,
  ApexStroke,
} from "ng-apexcharts";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DEFAULT_DEMOGRAPHICS } from "src/app/constants";
type AOA = any[][];

export type summaryReportChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  colors: string[];
  title: ApexTitleSubtitle;
  legend: ApexLegend;
  grid: ApexGrid;
};

export type summaryChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
};

@Component({
  selector: "app-company-header",
  templateUrl: "./company-header.component.html",
  styleUrls: ["./company-header.component.css"],
})
export class CompanyHeaderComponent implements OnInit {
  private ngUnsubscribe: Subject<any> = new Subject();
  @ViewChild("summaryGraph") summaryChart!: ChartComponent;
  @ViewChild("formSection") formSection: ElementRef;
  public summaryChartOptions!: Partial<summaryReportChartOptions>;
  managers = [];
  uploadType: string = "manager"; // set default to manager
  managersDemographicsList: string[] = [];
  participantsDemographicsList: string[] = [];
  demographic: string = "";
  isManagerUpload: boolean = false;
  isParticipantUpload: boolean = false;
  errorMessage: string = "";
  data: any;
  companyInfo = [];
  basicCount1 = 0;
  specialCount1 = 0;
  premiumCount1 = 0;
  modalRef!: BsModalRef;
  companyName!: string;
  @ViewChild("fileInput") fileInput1: any;
  fileInputRef!: ElementRef<HTMLInputElement>;
  isDob: boolean = false;
  isAge: boolean = false;
  isTenureWithOrg: boolean = false;
  isDateOfJoining: boolean = false;
  wopts: XLSX.WritingOptions = { bookType: "xlsx", type: "array" };
  fileName: string = "SheetJS.xlsx";
  fileInput: any;
  manager = {
    name: "",
    email: "",
    mobile: null,
  };
  selectedDemographic: string = "";
  selectedOption: string = "";
  currentDemographicOptions: any = [];
  selectedDemographics: any[] = [];
  showCustomDemographicForm = false;
  newDemographicName = "";
  newDemographicOption = "";

  defaultDemographics: any = DEFAULT_DEMOGRAPHICS.filter(
    (data) => data.type !== "participant" && data.show
  );
  alreadyAddedDemographics: any = [];
  filteredDemographics: any = [];
  isDemoUpdate: boolean = false;
  isCustomDemoEdit: boolean = false;

  currentManagerDemographics: any = [];
  constructor(
    private companyService: CompanyServices,
    private modalService: BsModalService,
    private router: Router,
    private dataService: DataService,
    private cd: ChangeDetectorRef,
    private _snackBar: MatSnackBar
  ) {}
  companyId!: string;
  selectedFeatures: any = [];
  isDataLoading = true;
  totalManagers = 0;
  registeredCount = 0;
  unRegisteredCount = 0;
  totalEmployees = 0;
  totalSurveys = 0;
  totalApCount = 0;
  totalImpleUpdates = 0;
  totalApFeedback = 0;
  uniqueApUploaded = 0;
  sheetJsExcelName = "null.xlsx";

  displayView = "basic"; //1. basic 2. l&d
  pisAccess: Boolean = false;
  ngOnInit() {
    let token: any = localStorage.getItem("token");
    let deodedToken = this.getDecodedAccessToken(token);
    this.companyId = deodedToken.companyId;
    this.companyName = deodedToken.company;
    this.getManagersList();
    this.getCompanyInfo();
    this.getCounts();
    this.checkAccess();
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwtDecode(token);
    } catch (Error) {
      return null;
    }
  }
  checkAccess() {
    let token: any = localStorage.getItem("token");
    let decodeToken = this.getDecodedAccessToken(token);
    if (decodeToken) {
      this.pisAccess =
        decodeToken.access.filter((acc: any) => acc.value == "pis").length > 0;
    }
  }
  getCounts() {
    this.companyService.countChecker.subscribe((response) => {
      this.basicCount1 = response.basic;
      this.premiumCount1 = response.premium;
      this.specialCount1 = response.special;
    });
  }

  getManagersList() {
    this.companyService.managerData.subscribe((response) => {
      this.managers = response;
      // console.log(this.managers , 'man')
    });
  }

  callRefreshComponent(data: any) {
    this.companyService.refreshAllComponent(data);
  }
  addDemographicToList(demographic: string, list: string[]): void {
    if (list.includes(demographic)) {
      this.errorMessage = "Demographics already exists";
    } else {
      list.push(demographic);
    }
  }

  manageDemographicsInList(type: string, action: string, index?: number) {
    if (this.demographic.trim() === "" && action == "add") {
      return; // If demographic is empty, do nothing
    }

    let tempList: any =
      type == "manager"
        ? this.managersDemographicsList
        : this.participantsDemographicsList;
    switch (action) {
      case "add":
        if (tempList.includes(this.demographic)) {
          this.errorMessage = "Demographics already exists";
        } else {
          tempList.push(this.demographic);
        }
        break;
      case "edit":
        tempList.splice(index, 1, this.demographic);
        break;
      case "remove":
        tempList.splice(index, 1);
        break;
    }
    // Reset the demographic input and selected index
    this.demographic = "";
  }

  getCompanyInfo() {
    this.isDataLoading = true;
    this.companyService
      .getCompanyStatics({ companyId: this.companyId })
      .subscribe((response: any) => {
        if (response.status) {
          this.totalSurveys = response.totalSurveys;
          this.totalManagers = response.totalManagers;
          this.totalEmployees = response.totalEmployees;
          this.totalApCount = response.totalApCount;
          this.totalImpleUpdates = response.totalImpleUpdates;
          this.totalApFeedback = response.totalApFeedback;
          this.uniqueApUploaded = response.uniqueApUploaded;
          this.registeredCount = response.registeredCount;
          this.unRegisteredCount = response.unRegisteredCount;

          this.isDataLoading = false;

          let selectedFeatures =
            typeof response.selectedFeatures !== "undefined"
              ? response.selectedFeatures
              : [];
          selectedFeatures.forEach((feature: any) => {
            this.selectedFeatures.push(feature.type);
          });

          if (
            this.selectedFeatures.includes("learning_dashboard") &&
            this.selectedFeatures.includes("l&d")
          ) {
            this.displayView = "learning";
          }
          this.prepareChartData(response);
        }

        // console.log('displayView', this.displayView);
        // console.log('test', response);
        // this.basicCount1 = response.totalBasic;
        // this.premiumCount1 = response.totalPremium;
        // this.specialCount1 = response.totalSpecial;
      });
  }

  prepareChartData(response: any) {
    let data = [];
    let categories = ["Registered", "Not Registered"];

    if (
      typeof response.registeredCount !== "undefined" &&
      typeof response.unRegisteredCount !== "undefined"
    ) {
      data.push(response.registeredCount);
      data.push(response.unRegisteredCount);
    }

    this.summaryChartOptions = {
      series: [
        {
          name: "No of Managers",
          data: data,
        },
      ],
      chart: {
        height: "auto",
        type: "bar",
        events: {
          click: function (chart, w, e) {
            // console.log(chart, w, e)
          },
        },
        toolbar: {
          show: false,
        },
      },
      colors: ["#00E396", "#FEB019"],
      plotOptions: {
        bar: {
          columnWidth: "40%",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      grid: {
        show: false,
      },
      xaxis: {
        categories: categories,
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        title: {
          text: "No. of Managers",
          rotate: 90,
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
            cssClass: "apexcharts-yaxis-title",
          },
        },
      },
    };
  }

  openModal(template: TemplateRef<any>, uploadType?: string) {
    this.modalRef = this.modalService.show(template, {
      class: "modal-lg", // or 'modal-sm' for a small modal
    });

    this.companyService.addModal(this.modalRef);
    this.getCompanyDemographics();
    this.currentManagerDemographics = [];
    switch (uploadType) {
      case "manager":
        this.isManagerUpload = true;
        break;
      case "participant":
        this.isParticipantUpload = true;
        break;
    }
  }

  getExtension(filename: any): string {
    return filename.split(".").pop();
  }
  excelType = false;
  touched = false;
  onFileChange(evt: any): void {
    this.touched = true;
    /* wire up file reader */
    let ext = this.getExtension(evt.srcElement.files[0].name);
    this.excelType = ext === "csv" || ext === "xls" || ext === "xlsx";
    const target: DataTransfer = <DataTransfer>evt.target;
    if (target.files.length !== 1) throw new Error("Cannot use multiple files");
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      try {
        var wb: XLSX.WorkBook = XLSX.read(bstr, { type: "binary" });
      } catch (Error: any) {
        if (Error.name == "Error") {
          alert(Error.message);
          (<HTMLInputElement>document.getElementById("fileSelect")).value = "";
          return null;
        }
      }

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      this.data = <AOA>XLSX.utils.sheet_to_json(ws, {
        header: 1,
      });
      if (this.data.length === 0) {
        swal.fire({
          title: "You have Uploaded Empty File",
          text: "Please Upload Valid File",
          icon: "error",
          showConfirmButton: false,
          timer: 2500,
        });
        return false;
      } else {
        const dataHeaders = this.data[0];
        const ageKeywords = ["age"];
        const dobKeywords = ["dob", "birth", "dateofbirth"];
        const tenureWithOrgKeywords = ["tenure", "org"];
        const dateOfJoiningKeywords = ["date", "joining"];
        const ageNotKeywords = ["manager", "language"];
        const managerKeywords = ["manager"];
        this.isAge = this.checkHeaderIndices(
          dataHeaders,
          ageKeywords,
          ageNotKeywords,
          "one"
        );
        this.isDob = this.checkHeaderIndices(
          dataHeaders,
          dobKeywords,
          [],
          "one"
        );

        this.isTenureWithOrg = this.checkHeaderIndices(
          dataHeaders,
          tenureWithOrgKeywords,
          managerKeywords,
          "all"
        );
        this.isDateOfJoining = this.checkHeaderIndices(
          dataHeaders,
          dateOfJoiningKeywords,
          [],
          "all"
        );

        // const dateIndices = this.data[0]
        //   .map((header: string, index: number) => ({ header, index }))
        //   .filter(({ header }) => header.toLowerCase().includes('date'))
        //   .map(({ index }) => index);
        // const ageIndices = this.data[0]
        //   .map((header: string, index: number) => ({ header, index }))
        //   .filter(
        //     ({ header }) =>
        //       header.toLowerCase().includes('age') &&
        //       !header.toLowerCase().includes('manager') &&
        //       !header.toLowerCase().includes('language')
        //   )
        //   .map(({ index }) => index);

        const dateIndices = this.data[0]
          .map(
            (
              header: string,
              index: number
            ): { header: string; index: number } => ({ header, index })
          )
          .filter(({ header }: { header: string }) =>
            header.toLowerCase().includes("date")
          )
          .map(({ index }: { index: number }) => index);

        const ageIndices = this.data[0]
          .map(
            (
              header: string,
              index: number
            ): { header: string; index: number } => ({ header, index })
          )
          .filter(
            ({ header }: { header: string }) =>
              header.toLowerCase().includes("age") &&
              !header.toLowerCase().includes("manager") &&
              !header.toLowerCase().includes("language")
          )
          .map(({ index }: { index: number }) => index);
        dateIndices.push(...ageIndices);

        // const ageIndices = this.data[0]
        //   .map((header: string, index: number) => ({ header, index }))
        //   .filter(
        //     ({ header }) =>
        //       header.toLowerCase().trim().includes("age") &&
        //       !header.toLowerCase().trim().includes("manager") &&
        //       !header.toLowerCase().trim().includes("language")
        //   );
        // ageIndices.length > 0 ? (this.isAge = true) : null;
        // const dobIndices = this.data[0]
        //   .map((header: string, index: number) => ({ header, index }))
        //   .filter(
        //     ({ header }) =>
        //       header.toLowerCase().trim().includes("dob") ||
        //       header.toLowerCase().trim().includes("birth") ||
        //       header.toLowerCase().trim().includes("dateofbirth")
        //   );
        // dobIndices.length > 0 ? (this.isDob = true) : null;
        // const tenureWithOrgIndices = this.data[0]
        //   .map((header: string, index: number) => ({ header, index }))
        //   .filter(
        //     ({ header }) =>
        //       header.toLowerCase().trim().includes("tenure") &&
        //       header.toLowerCase().trim().includes("org") &&
        //       !header.toLowerCase().trim().includes("manager")
        //   );
        // tenureWithOrgIndices.length > 0 ? (this.isTenureWithOrg = true) : null;
        // const dateOfJoiningIndices = this.data[0]
        //   .map((header: string, index: number) => ({ header, index }))
        //   .filter(
        //     ({ header }) =>
        //       header.toLowerCase().trim().includes("date") &&
        //       header.toLowerCase().trim().includes("joining")
        //   );
        // dateOfJoiningIndices.length > 0 ? (this.isDateOfJoining = true) : null;
        // if (!this.isAge && !this.isDob) {
        //   (<HTMLInputElement>document.getElementById("fileSelect")).value = "";
        //   swal.fire({
        //     title: "Doesn't include required data",
        //     text: "Please Upload 'Age' or 'Date of birth' of Users",
        //     type: "error",
        //     showConfirmButton: false,
        //     timer: 5000,
        //   });
        //   return false;
        // }
        // if (!this.isDateOfJoining && !this.isTenureWithOrg) {
        //   (<HTMLInputElement>document.getElementById("fileSelect")).value = "";
        //   swal.fire({
        //     title: "Doesn't include required data",
        //     text: "Please Upload 'Date of Joining' or 'Tenure With Org' of Users",
        //     type: "error",
        //     showConfirmButton: false,
        //     timer: 5000,
        //   });
        //   return false;
        // }
        if (dateIndices.length > 0) {
          this.data.forEach((row: any[]) => {
            dateIndices.forEach((dateIndex: any) => {
              if (typeof row[dateIndex] === "number") {
                const date = new Date((row[dateIndex] - 25569) * 86400 * 1000);
                const day =
                  date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
                const month =
                  date.getMonth() < 9
                    ? `0${date.getMonth() + 1}`
                    : date.getMonth() + 1;
                row[dateIndex] = `${day}/${month}/${date.getFullYear()}`;
              }
            });
          });
        }
        this.dataService.setUploadType(this.uploadType);
      }
      return null;
    };

    reader.readAsBinaryString(target.files[0]);
    // return true;
  }
  checkHeaderIndices(
    headers: string[],
    keywords: string[],
    excludeKeywords: string[],
    type: any
  ): boolean {
    let headerIndices;
    switch (type) {
      case "one":
        headerIndices = headers
          .map((header: string, index: number) => ({ header, index }))
          .filter(
            ({ header }) =>
              keywords.some((keyword) =>
                header.toLowerCase().trim().includes(keyword)
              ) &&
              !excludeKeywords.some((excludeKeyword) =>
                header.toLowerCase().trim().includes(excludeKeyword)
              )
          );

        return headerIndices.length > 0;
      case "all":
        headerIndices = headers
          .map((header: string, index: number) => ({ header, index }))
          .filter(
            ({ header }) =>
              keywords.every((keyword) =>
                header.toLowerCase().trim().includes(keyword)
              ) &&
              !excludeKeywords.some((excludeKeyword) =>
                header.toLowerCase().trim().includes(excludeKeyword)
              )
          );

        return headerIndices.length > 0;
    }
    return false;
  }
  managerAddValidation = false;
  inValidEmail = false;

  checkManagerEmail(event: any) {
    //console.log(event.target.value)
    const regex =
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (regex.test(event.target.value)) {
      this.inValidEmail = true;
    } else {
      this.inValidEmail = false;
      this.managerAddValidation = true;
      return false;
    }
    let index = this.managers.findIndex(
      (manager: any) =>
        manager.personalInfo.email.toLowerCase() ==
        event.target.value.toLowerCase()
    );
    //console.log(index ,'wi')
    this.inValidEmail = true;
    if (index == -1) {
      this.managerAddValidation = true;
    } else {
      this.managerAddValidation = false;
    }
    return null;
  }

  loading!: boolean;
  addManagerOneByOne(form: NgForm) {
    this.loading = true;
    let data = {
      managerName: form.value.name.trim(),
      managerEmail: form.value.email.trim(),
      seCompanyId: this.companyId,
      demographics: this.currentManagerDemographics.map((data) => {
        return {
          company_demographics_name: !data.default
            ? this.transformString(data.company_demographics_name)
            : data.company_demographics_name,
          value: data.value,
        };
      }),
    };
    if (data.managerName == "") {
      swal.fire({
        title: "Valid name",
        text: "Valid name",
        icon: "error",
        showConfirmButton: false,
        timer: 2500,
      });
      return false;
    }
    this.companyService
      .addIndividualManager(data)
      .subscribe((response: any) => {
        this.loading = false;
        if (response.status) {
          this.callRefreshComponent(response.data);
          this.resetForms();
          this.manager.email = null;
          this.manager.name = null;
          this.currentManagerDemographics = [];
          this.totalManagers = this.totalManagers + 1;
          swal.fire({
            title: "Add Manager",
            text: response.message,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
        } else {
          swal.fire({
            title: "Add Manager",
            text: response.message,
            icon: "error",
            showConfirmButton: false,
            timer: 2500,
          });
        }
      });
    this.modalRef.hide();
    // this.modalRef = null;
    return null;
  }

  transformString(input) {
    return input
      .toLowerCase() // Convert the string to lowercase
      .split(" ") // Split the string by spaces
      .map((word, index) => {
        // Capitalize the first letter of each word except the first word
        return index === 0
          ? word
          : word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(""); // Join the words back into a single string
  }

  dropExcelOnChange(targetInput: Array<File>) {
    this.sheetJsExcelName = targetInput[0].name;
    if (targetInput.length !== 1) {
      swal.fire({
        title: "Error",
        text: "Cannot use multiple files",
        icon: "error",
        showConfirmButton: true,
        timer: 2500,
      });
    }
    const reader: FileReader = new FileReader();

    reader.onload = (e: any) => {
      const bstr: string = e.target.result;

      var wb: XLSX.WorkBook = XLSX.read(bstr, { type: "binary" });
      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      this.data = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1 });

      // TODO added check for manager and participants
      this.dataService.setManagerDemographics(this.data);
      this.router.navigate(["/company/company-dashboard/xl-dataMapping"]);
    };
    reader.readAsBinaryString(targetInput[0]);
  }

  dropExcelBlock(fileList: Array<File>) {
    if (fileList.length === 0) {
      return;
    } else {
      swal.fire({
        title: "Error",
        text: "Only excel format is allowed",
        icon: "error",
        showConfirmButton: true,
        timer: 2500,
      });
    }
  }

  downloadManagerPis() {
    this.companyService.getManagerPIS().subscribe((blob: Blob) => {
      const filename = "PIS-DATA.xlsx";
      saveAs(blob, filename);
    });
  }
  onSubmit(form: NgForm) {
    if (this.data == undefined) {
      alert("Please upload excel sheet!!");
    } else {
      this.modalRef.hide();
      // this.modalRef = null;
      this.uploadType == "manager"
        ? this.dataService.setManagerDemographics(
            this.data.filter((e: any) => e.length)
          )
        : this.dataService.setParticipantDemographics(
            this.data.filter((e: any) => e.length)
          );
      let data = {
        isAge: this.isAge,
        isDob: this.isDob,
        isDateOfJoining: this.isDateOfJoining,
        isTenureWithOrg: this.isTenureWithOrg,
      };
      this.dataService.setPisHeaderData(data);
      this.router.navigate(["/company/company-dashboard/xl-dataMapping"]);
    }
  }
  getFilesLength(): number {
    return this.fileInput?.files?.length || 0;
  }
  ngOnDestroy() {
    // this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  currentIndex: number = -1;
  onDemographicSelect() {
    if (this.selectedDemographic) {
      const index = this.defaultDemographics.findIndex(
        (data) => data.company_demographics_name == this.selectedDemographic
      );
      if (index > -1) {
        this.currentDemographicOptions =
          this.defaultDemographics[index].company_demographics_options_name;
      }
      this.selectedOption = "";
    }
  }

  addDemographic() {
    if (
      !this.validateDemographic(this.selectedDemographic, this.selectedOption)
    )
      return;

    if (this.isDemographicExists(this.selectedDemographic)) {
      this.openSnackBar(
        `'${this.selectedDemographic}' or similar has already been added!`,
        false
      );
      return;
    }

    this.addManagerDemographic(
      this.selectedDemographic,
      this.selectedOption,
      true
    );
    this.resetDemographicForm();
  }

  addCustomDemographic() {
    if (
      !this.validateDemographic(
        this.newDemographicName,
        this.newDemographicOption
      )
    )
      return;

    if (this.isDemographicExists(this.newDemographicName)) {
      this.openSnackBar(
        `'${this.newDemographicName}' or similar has already been added!`,
        false
      );
      return;
    }

    this.addManagerDemographic(
      this.newDemographicName,
      this.newDemographicOption,
      false
    );
    this.resetCustomDemographicForm();
  }

  validateDemographic(name, option) {
    if (!name || !option) {
      this.openSnackBar(
        "Demographic name and option should not be empty.",
        false
      );
      return false;
    }
    return true;
  }

  isDemographicExists(demographicName) {
    return this.currentManagerDemographics.some(
      (data) =>
        data.company_demographics_name.trim().toLowerCase() ===
        demographicName.trim().toLowerCase()
    );
  }

  addManagerDemographic(name, option, isDefault) {
    this.currentManagerDemographics.push({
      company_demographics_name: name.trim(),
      value: option.trim(),
      default: isDefault,
    });
  }

  resetDemographicForm() {
    this.currentDemographicOptions = [];
    this.selectedDemographic = null;
    this.selectedOption = null;
  }

  resetCustomDemographicForm() {
    this.newDemographicName = null;
    this.newDemographicOption = null;
    this.showCustomDemographicForm = false;
  }

  patchValues(demographic, index: number) {
    // this.scrollToForm();
    this.resetForms();
    if (demographic.default) {
      this.selectedDemographic = demographic.company_demographics_name;
      this.onDemographicSelect();
      this.selectedOption = demographic.value;
      this.isDemoUpdate = true;
    } else {
      this.currentIndex = index;
      this.newDemographicName = demographic.company_demographics_name;
      this.newDemographicOption = demographic.value;
      this.isCustomDemoEdit = true;
    }
  }

  resetForms() {
    this.currentDemographicOptions = [];
    this.selectedDemographic = null;
    this.selectedOption = null;
    this.isDemoUpdate = false;
    this.isCustomDemoEdit = false;
    this.showCustomDemographicForm = false;
  }

  updateDemographic() {
    this.updateDemographicValue(this.selectedDemographic, this.selectedOption);
    this.resetForms();
    this.openSnackBar("Demographic updated successfully.", true);
  }

  updateCustomDemographic() {
    const data = this.currentManagerDemographics[this.currentIndex];
    if (data) {
      this.currentManagerDemographics[
        this.currentIndex
      ].company_demographics_name = this.newDemographicName;
      this.currentManagerDemographics[this.currentIndex].value =
        this.newDemographicOption;
    }
    this.resetForms();
    this.openSnackBar("Demographic updated successfully.", true);
  }

  updateDemographicValue(name, value) {
    const index = this.currentManagerDemographics.findIndex(
      (data) => data.company_demographics_name === name
    );
    if (index > -1) {
      this.currentManagerDemographics[index].value = value;
    }
  }

  removeDemographic(index: number) {
    this.currentManagerDemographics.splice(index, 1);
  }

  openSnackBar(message: any, status: any) {
    this._snackBar.open(message, "", {
      duration: 5000,
      panelClass: status ? ["green-snackbar"] : ["red-snackbar"],
    });
  }

  areNamesSimilar(existingName: string, newName: string): boolean {
    // Check if either name contains the other (fuzzy match)
    return existingName.includes(newName) || newName.includes(existingName);
  }

  scrollToForm() {
    // Scroll smoothly to the form section
    this.formSection.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }

  getCompanyDemographics() {
    this.companyService
      .getCompanyDemographics({ type: 1 })
      .subscribe((res: any) => {
        if (res.status) {
          this.alreadyAddedDemographics = res.data;
        }
      });
  }

  // Function to filter demographics based on the input
  filterDemographics() {
    const filterValue = this.newDemographicName.toLowerCase();
    const defaultDemo = this.defaultDemographics.map(
      (demo) => demo.company_demographics_name
    );

    this.filteredDemographics = this.alreadyAddedDemographics.filter(
      (option) =>
        !defaultDemo.includes(this.transformString(option)) &&
        option.toLowerCase().includes(filterValue)
    );
  }

  // Optional function to format the display value
  displayFn(demographic?: string): string {
    return demographic ? demographic : "";
  }
}
