import { Component, OnInit, TemplateRef } from "@angular/core";
import { CompanyServices } from "../company.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import Swal from "sweetalert2";
import { Router } from "@angular/router";

@Component({
  selector: "app-assign-batches",
  templateUrl: "./assign-batches.component.html",
  styleUrls: ["./assign-batches.component.css"],
})
export class AssignBatchesComponent implements OnInit {
  selectedManagers: any = [];
  modalRef!: BsModalRef;
  batchName!: string;
  constructor(
    private companyService: CompanyServices,
    private modalService: BsModalService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getSelectedManagers();
  }

  getSelectedManagers() {
    this.companyService.selectedmanagersForPackages.subscribe((response) => {
      this.selectedManagers = response;
    });
  }
  openAssignBatchesModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: "my-modal" })
    );
    this.companyService.addModal(this.modalRef);
  }
  submit() {
    let data = {
      managers: this.selectedManagers,
      batchName: this.batchName,
    };
    this.companyService.assignBatch(data).subscribe((response) => {
      if (response.status) {
        Swal.fire({
          title: "Batch successfully added",
          text: response.message,
          icon: "success",
          showConfirmButton: false,
          timer: 3500,
        });
        this.modalRef.hide();
        // this.modalRef = null;
        this.redirectTo("/company/company-dashboard");
      } else {
        Swal.fire({
          title: "Something went wrong",
          text: response.message,
          icon: "error",
          showConfirmButton: false,
          timer: 3500,
        });
      }
    });
  }
  redirectTo(uri: string) {
    this.router
      .navigateByUrl("/", { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }
}
