<div class="lfi-container">
  <form
    [formGroup]="reportForm"
    class="form-container"
    (ngSubmit)="onDownloadReport()"
  >
    <div class="form-row">
      <label for="startDate">Choose Start Date:</label>
      <input type="date" id="startDate" formControlName="startDate" />
    </div>
    <div class="form-row">
      <label for="endDate">Choose End Date:</label>
      <input type="date" id="endDate" formControlName="endDate" />
    </div>
    <button type="submit">Download Report</button>
  </form>
</div>
