import { Component, Input, OnInit } from "@angular/core";
import { CompanyServices } from "../company.service";
import swal from "sweetalert2";
import { jwtDecode } from "jwt-decode";

@Component({
  selector: "app-pages-setting",
  templateUrl: "./pages-setting.component.html",
  styleUrls: ["./pages-setting.component.css"],
})
export class PagesSettingComponent implements OnInit {
  componentMapping: any = [];
  themeData: any = [];
  pageId: number = 0;
  allPages: any = [];
  modifiedData: any = {};
  onOffNotAvailable: any = ["overall_score"];
  toolTipNotEditable: any = [
    "download_tfs_report",
    "description_choice",
    "view_all_button",
    "historic_feedback_summary",
    "journey_progress",
  ];
  displayTextNotEditable: any = [
    "promoter",
    "self_assessment",
    "benchmark",
    "current_score",
    "download_tfs_report",
    "description_choice",
    "bottom_toggle",
    "top_toggle",
    "historic_feedback_summary",
    "journey_progress",
  ];

  dropDownChoiceAvailableSubComponent: any = ["description_choice"];
  subComponentDropDownChoices: any = {
    description_choice: {
      options: [
        "You have y assigned feedback givers",
        "x out of y have provided you feedback",
      ],
    },
  };
  companyId: any;
  constructor(private companyService: CompanyServices) {}

  ngOnInit() {
    this.getAllCustomPages();
  }

  getCompanyComponentMappingData() {
    let token: any = localStorage.getItem("token");
    let tokenData: any = jwtDecode(token);
    this.companyId = tokenData.companyId;
    this.companyService
      .getCompanyComponentMappingData({ pageId: this.pageId })
      .subscribe((response) => {
        if (response.status) {
          this.componentMapping = response.data;
          if (this.componentMapping.length > 0) {
            this.themeData = this.componentMapping[0].components.themeData;
            //   if (
            //     this.componentMapping[0].components.companyId != this.companyId
            //   ) {
            //     this.componentMapping[0].components.id = null;
            //     this.componentMapping[0].components.status = false;
            //     this.componentMapping[0].components.companyId = this.companyId;
            //   }
          }
        }
      });
  }

  getAllCustomPages() {
    this.companyService.getAllCustomPages().subscribe((response) => {
      if (response && response.status) {
        this.allPages = response.data;
      }
    });
  }

  dataChanged(value) {
    this.pageId = value;
    this.getCompanyComponentMappingData();
  }

  convertToTitleCase(inputText) {
    const words = inputText.split("_");
    const titleCaseText = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return titleCaseText;
  }

  updateData(index) {
    this.companyService
      .updateCompanyComponentMappingData({ data: this.componentMapping[index] })
      .subscribe((response) => {
        swal.fire({
          title: response.status ? "Success" : "Error",
          text: response.message,
          icon: response.status ? "success" : "error",
          showConfirmButton: true,
          timer: 2500,
        });
      });
  }

  updateThemeData() {
    this.companyService
      .updateThemeData({ data: this.themeData })
      .subscribe((response) => {
        swal.fire({
          title: response.status ? "Success" : "Error",
          text: response.message,
          icon: response.status ? "success" : "error",
          showConfirmButton: true,
          timer: 2500,
        });
      });
  }

  addSettings() {
    const data = {
      pageId: this.pageId,
    };
    this.companyService.addSettings(data).subscribe((response) => {
      swal.fire({
        title: response.status ? "Success" : "Error",
        text: response.message,
        icon: response.status ? "success" : "error",
        showConfirmButton: true,
      });
      response.status && this.getCompanyComponentMappingData();
    });
  }

  sortByKey(key: string, array: []) {
    if (array.length == 0 || !array) {
      return [];
    }

    const sortedArray = array.sort((a, b) => {
      if (a[key] < b[key]) {
        return -1;
      } else if (a[key] > b[key]) {
        return 1;
      } else {
        return 0;
      }
    });

    return sortedArray;
  }
}
