import { Router, RouterModule } from "@angular/router";
import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { CompanyServices } from "../company.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
// import * as jwt_decode from 'jwt-decode';
import { jwtDecode } from "jwt-decode";
import * as XLSX from "xlsx";
import { constants } from "../../constants";
type AOA = any[][];
import IdleTimer from "../../../../src/app/Timer.js";
import swal from "sweetalert2";
@Component({
  selector: "app-company-dashboard",
  templateUrl: "./company-dashboard.component.html",
  styleUrls: ["./company-dashboard.component.css"],
})
export class CompanyDashboardComponent implements OnInit {
  @ViewChild("fileInput") fileInput!: ElementRef;
  data: AOA = [];
  data1: AOA = [];
  modalRef!: BsModalRef;
  companyName!: string;
  companyLogo!: string;
  headers = [];
  showRolePopup: Boolean = false;
  showPopup: Boolean = false;
  wopts: XLSX.WritingOptions = { bookType: "xlsx", type: "array" };
  fileName: string = "SheetJS.xlsx";
  companyId!: string;
  timer: any;
  access: any = {
    reportsAccess: false,
    surveyAccess: false,
    leadershipDashboardAccess: false,
    nudgesAccess: false,
    emailLogsAccess: false,
  };
  companyInfo: any = {};
  activeModals: BsModalRef[] = [];
  constructor(
    private router: Router,
    private companyService: CompanyServices,
    private modalService: BsModalService // private managerService: ManagerServices
  ) {}

  // constructor(private router: Router, private companyService: CompanyServices, private modalService: BsModalService,) { }

  ngOnInit() {
    this.timer = new IdleTimer({
      timeout: 900,
      onTimeout: () => {
        this.logOutModel("sessionExpired");
      },
    });
    let roles = localStorage.getItem("roles");
    if (roles) {
      this.showPopup = true;
    }
    if (this.companyService.roleGuard()) {
      let token: any = localStorage.getItem("token");
      let deodedToken = this.getDecodedAccessToken(token);
      this.companyName = deodedToken.company;
      this.companyId = deodedToken.companyId;
      this.getCompanyProfile();
    } else {
      this.showPopup = false;
    }
    this.companyService.popUpShow.subscribe((res) => {
      this.showRolePopup = res;
    });
    let token: any = localStorage.getItem("token");
    let deodedToken = this.getDecodedAccessToken(token);
    // console.log(deodedToken, "token");
    this.companyName = deodedToken.company;
    this.checkAccess();
  }

  logOutModel(type: any) {
    let title = "";
    let text = "";
    switch (type) {
      case "sessionExpired":
        title = "Session is expired!";
        text =
          "Your session is expired due to inactivity. Please sign in again";
        localStorage.clear();

        break;
      case "accessRevoked":
        title = "Access is revoked!";
        text = "Your access is revoked. Please contact adminstrator";
        break;
      case "ipBlocked":
        title = "IP is blocked!";
        text = "Your IP is blocked. Please contact administrator";
        break;
    }
    this.companyService.getActiveModals().forEach((modalRef) => {
      modalRef.hide();
      this.companyService.removeModal(modalRef);
    });
    swal
      .fire({
        title: title,
        text: text,
        icon: "success",
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      .then((value: any) => {
        localStorage.clear();

        // this.companyService.getActiveModals().forEach((modalRef) => {
        //   modalRef.hide();
        //   this.companyService.removeModal(modalRef);
        // });
        // if (this.companyService.getActiveModals().length > 0) {
        //   this.companyService.getActiveModals()[0].hide();
        //   this.companyService.removeModal();
        // }
        this.router.navigate(["/company/"]);
      });
  }
  openFileInput() {
    this.fileInput.nativeElement.click();
  }
  // ngOnDestroy() {
  //   this.timer.clear();
  // }

  toggleRolesPopup() {
    this.showRolePopup = !this.showRolePopup;
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwtDecode(token);
    } catch (Error) {
      return null;
    }
  }

  checkAccess() {
    let token: any = localStorage.getItem("token");
    let decodeToken = this.getDecodedAccessToken(token);
    if (decodeToken) {
      this.access.reportsAccess =
        decodeToken.access.filter((acc: any) => acc.value == "report").length >
        0;
      this.access.surveyAccess =
        decodeToken.access.filter((acc: any) => acc.value == "survey").length >
        0;
      this.access.actionPlanFeedbackAccess =
        decodeToken.access.filter((acc: any) => acc.value == "ap_review")
          .length > 0;
      this.access.leadershipDashboardAccess =
        decodeToken.access.filter((acc) => acc.value == "leadership_dashboard")
          .length > 0;
      this.access.nudgesAccess =
        decodeToken.access.filter((acc) => acc.value == "nudges").length > 0;
      this.access.gmcAccess =
        decodeToken.access.filter((acc) => acc.value == "gmc").length > 0;
      this.access.emailLogsAccess =
        decodeToken.access.filter((acc) => acc.value == "email_logs").length > 0;
    }
    // console.log(this.access);
  }

  getCompanyProfile() {
    this.companyService
      .getCompanyProfile({ companyId: this.companyId })
      .subscribe((response: any) => {
        if (response.status) {
          this.companyInfo = response.data;
          let companyAdminInfo =
            typeof response.companyAdmin !== "undefined"
              ? response.companyAdmin
              : {};

          if (
            response.data.companySpecific != undefined &&
            response.data.companySpecific.companyLogo != undefined
          ) {
            this.companyLogo = `${constants.IMAGE_URL}${response.data.companySpecific.companyLogo}`;
          }
          let whitelistIps =
            typeof response.whitelistIps !== "undefined"
              ? response.whitelistIps
              : {};
          if (
            whitelistIps &&
            typeof whitelistIps.allowedips !== "undefined" &&
            whitelistIps.allowedips.length > 0
          ) {
            let userIp =
              typeof response.userIp !== "undefined" ? response.userIp : "";
            if (!whitelistIps.allowedips.includes(userIp)) {
              this.logOutModel("ipBlocked");
            }
          }
          if (!companyAdminInfo.status) {
            this.logOutModel("accessRevoked");
          }
        }
      });
  }

  onClickProfile() {
    this.router.navigate(["/company/company-dashboard/profile"]);
  }

  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>evt.target;
    if (target.files.length !== 1) throw new Error("Cannot use multiple files");
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: "binary" });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1 });
      this.data1 = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1 });
      //// console.log(this.data);
    };
    reader.readAsBinaryString(target.files[0]);
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    this.companyService.addModal(this.modalRef);
  }
  update() {
    //// console.log('update')
  }

  onLogout() {
    localStorage.clear();
  }
}
