import { Component, OnInit, TemplateRef } from "@angular/core";
import { AdminServices } from "../admin.service";
import swal from "sweetalert2";
// import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { BsModalService } from "ngx-bootstrap/modal";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import clonedeep from "lodash.clonedeep";

@Component({
  selector: "app-accomplishment-popover",
  templateUrl: "./accomplishment-popover.component.html",
  styleUrls: ["./accomplishment-popover.component.css"],
})
export class AccomplishmentPopoverComponent implements OnInit {
  public popoverDataForm: FormGroup;
  dataSource = new MatTableDataSource<any>();
  displayedColumns = [
    "title",
    "description",
    "characterUrl",
    "popoverColour",
    "popoverLocation",
    "reason",
    "actions",
  ];
  isEdit: boolean = false;
  modalRef: BsModalRef;
  currentIndex: number;
  constructor(
    private adminService: AdminServices,
    private modalService: BsModalService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.getPopoverData();
    this.popoverData();
  }

  // to search in table
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim().toLowerCase();
    this.dataSource.filter = filterValue;
  }

  getPopoverData() {
    this.adminService
      .getAllPopover({ isActive: true })
      .subscribe((res: any) => {
        if (res.status) {
          this.dataSource.data = res.data;
        }
      });
  }
  popoverData() {
    this.popoverDataForm = this.formBuilder.group({
      _id: new FormControl("", []),
      title: new FormControl("", [Validators.required]),
      description: new FormControl("", [Validators.required]),
      characterUrl: new FormControl("", [Validators.required]),
      popoverColour: new FormControl("", [Validators.required]),
      popoverLocation: new FormControl("", [Validators.required]),
      reason: new FormControl("", [Validators.required]),
    });
  }
  showPopoverModal(
    template: TemplateRef<any>,
    popoverData: any,
    isEdit: boolean,
    index
  ) {
    this.isEdit = isEdit;
    this.currentIndex = index;
    if (this.isEdit) {
      this.currentIndex = index;
      this.popoverDataForm.patchValue({
        title: popoverData.title,
        description: popoverData.description,
        characterUrl: popoverData.characterUrl,
        popoverColour: popoverData.popoverColour,
        popoverLocation: popoverData.popoverLocation,
        _id: popoverData._id,
        reason: popoverData.reason,
        isActive: popoverData.isActive,
      });
    } else {
      this.popoverDataForm.reset();
    }
    this.modalRef = this.modalService.show(template);
    this.adminService.addModal(this.modalRef);
  }

  closeModal() {
    this.modalService.hide(1);
  }

  // CREATE Popover Data
  addNewData() {
    this.adminService
      .createPopoverData(this.popoverDataForm.value)
      .subscribe((res) => {
        res && swal.fire(res.status ? "success" : "error", res.message);
        this.closeModal();
        res.status ? this.getPopoverData() : "";
      });
  }

  //EDIT Function
  editExistingData() {
    this.adminService
      .updatePopoverData(this.popoverDataForm.value)
      .subscribe((res) => {
        if (res.status) {
          this.dataSource.data.map((data, index) => {
            if (index == this.currentIndex) {
              (data["title"] = this.popoverDataForm.value.title),
                (data["description"] = this.popoverDataForm.value.description),
                (data["characterUrl"] =
                  this.popoverDataForm.value.characterUrl),
                (data["popoverColour"] =
                  this.popoverDataForm.value.popoverColour),
                (data["popoverLocation"] =
                  this.popoverDataForm.value.popoverLocation),
                (data["reason"] = this.popoverDataForm.value.reason);
            }
          });
          this.closeModal();
          swal.fire("success", res.message);
        } else {
          swal.fire("error", res.message);
        }
      });
  }

  deletePopoverData(element, index) {
    swal
      .fire({
        title: "Are You Sure You Want To Delete This User Guide ?",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
      })
      .then((result) => {
        if (result.value) {
          element.isActive = false;
          this.adminService.updatePopoverData(element).subscribe((res) => {
            if (res.status) {
              this.dataSource.data.splice(index, 1);
              this.dataSource.data = clonedeep(this.dataSource.data);
            }
          });
        }
      });
    this.closeModal();
  }
}
