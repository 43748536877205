import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "survey-launch-dialog",
  templateUrl: "./confirm-survey-launch-dialogue.html",
})
export class SurveyLaunchDialog {
  isSurveyLaunchButtonDisable: boolean = true;
  isDateSelected: boolean = false;
  isYearSelected: boolean = false;
  years: number[] = [];
  currentView: "viewSurvey" | "launchSurvey";
  constructor(
    public dialogRef: MatDialogRef<SurveyLaunchDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.currentView = this.data.view;
    const currentYear = new Date().getFullYear();
    this.years.push(currentYear - 1, currentYear, currentYear + 1);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  addEvent(event: any) {
    this.data.endDate = new Date(event.value).toUTCString();
    this.isDateSelected = true;
  }
}
