import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  ChangeDetectorRef,
} from "@angular/core";
import { DataService } from "../data.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { CompanyServices } from "../company.service";
// import swal from 'sweetalert2';
import Swal from "sweetalert2";
import { Router } from "@angular/router";
// import * as jwt_decode from 'jwt-decode';
import { jwtDecode } from "jwt-decode";

@Component({
  selector: "app-xl-data-update-nsave",
  templateUrl: "./xl-data-update-nsave.component.html",
  styleUrls: ["./xl-data-update-nsave.component.css"],
})
export class XlDataUpdateNsaveComponent implements OnInit {
  xlData: any;
  dataSource: any = new MatTableDataSource([]);
  displayedColumns: string[] = [];
  selectedType: string = "All";
  searchKey!: string;
  data1 = [];
  modalRef!: BsModalRef;
  editingFormData: any;
  editingFormDataKeys!: string[];
  errorsKey: string[] = [];
  errors!: string[];
  standardDemographics: string[] = [];
  public dynamicDemographics: any[] = [];
  pisHeaderData: object = {};
  editIndex!: number;
  deleteRowData: any;
  valid: number = 0;
  invalid: number = 0;
  existingMgrList: any;
  secompanyId!: number;
  companyName!: "";
  industry!: "";
  subIndustry!: "";
  registeredRows: Array<any> = [];
  allRows: Array<any> = [];
  validRows: Array<any> = [];
  notValidRows: Array<any> = [];
  customDemographic: string[] = [
    "state",
    "city",
    "country",
    "tenureWithManager",
    "teamSize",
    "teamComposition",
    "workExperience",
    "levelOfSeniority",
    "departmentFunction",
    "layerOfReportee",
    "tenureAsManager",
    "tenureWithOrganisation",
    "gender",
    "age",
  ];
  public buttonText: string = "Validate Data";
  public demographics = [
    "tenureWithManager",
    "teamSize",
    "teamComposition",
    "workExperience",
    "levelOfSeniority",
    "layerOfReportee",
    "tenureAsManager",
    "tenureWithOrganisation",
    "gender",
    "age",
  ];
  //TODO: add small cases also
  optionsMap: { [key: string]: string[] } = {
    age: [
      "25 years or younger",
      "26 - 34 years",
      "35 - 44 years",
      "45 - 54 years",
      "55 years or above",
    ],
    gender: ["Male", "Female", "Non Binary", "Prefer not to disclose"],
    tenureWithManager: [
      "Upto 6 months",
      "More than 6 months upto 1 year",
      "More than 1 year upto 2 years",
      "More than 2 years upto 5 years",
      "More than 5 years upto 10 years",
      "Over 10 years",
    ],
    teamSize: [
      "Upto 2",
      "3-5",
      "6-10",
      "11-15",
      "16-20",
      "20-25",
      "25-30",
      "Above 30",
    ],
    teamComposition: [
      "All working from office",
      "All working from home",
      "Hybrid working",
    ],
    workExperience: [
      "Upto 1 year",
      "More than 1 year upto 2 years",
      "More than 2 years upto 5 years",
      "More than 5 years upto 10 years",
      "More than 10 years upto 15 years",
      "More than 15 years upto 20 years",
      "More than 20 years upto 25 years",
      "More than 25 years upto 30 years",
      "Over 30 years",
    ],
    levelOfSeniority: [
      "Individual Contributor",
      "Frontline Manager",
      "Mid Level Manager",
      "Senior Manager",
      "CXO/CEO/Owner",
    ],
    // departmentFunction: [
    //   "Marketing",
    //   "Sales",
    //   "Accounting and Finance",
    //   "HRM",
    //   "Technology",
    //   "Operations/Production",
    //   "R&D",
    //   "Quality Assurance",
    //   "Purchase",
    // ],
    layerOfReportee: [
      "None",
      "1 layer",
      "2 layers",
      "3 layers",
      "4 layers",
      "Greater than 4 layers",
    ],
    tenureAsManager: [
      "Upto 6 months",
      "More than 6 months upto 1 year",
      "More than 6 months upto 2 years",
      "More than 2 years upto 5 years",
      "More than 5 years upto 10 years",
      "More than 10 years upto 15 years",
      "Over 15 years",
    ],
    tenureWithOrganisation: [
      "Upto 6 months",
      "More than 6 months upto 1 year",
      "More than 1 year upto 2 years",
      "More than 2 years upto 5 years",
      "More than 5 years upto 10 years",
      "More than 10 years upto 15 years",
      "More than 15 years upto 20 years",
      "Over 20 years",
    ],
  };

  respondentTypeList = ["Peer", "Reporting Manager", "Senior", "Team Member"];
  filterType: string[] = [
    "All",
    "Valid",
    "Not Valid",
    "Registered Managers",
    "Registered As B2C",
  ];
  uploadType: string = "manager";

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;

  constructor(
    private data: DataService,
    private modalService: BsModalService,
    private companyService: CompanyServices,
    private router: Router
  ) {}

  ngOnInit() {
    this.data.uploadType$.subscribe((type) => {
      this.uploadType = type;
    });
    this.makeTable(this.selectedType, this.uploadType);
  }

  makeTable(type: any, uploadType: any) {
    uploadType == "manager"
      ? this.data.managerDemographics.subscribe(
          (message) => (this.xlData = message)
        )
      : this.data.participantDemographic.subscribe(
          (message) => (this.xlData = message)
        );
    this.allRows = this.xlData.allData;
    this.standardDemographics = this.xlData.standardDemographic;
    this.dynamicDemographics = this.xlData.dynamicDemographic
      ? this.xlData.dynamicDemographic
      : [];
    this.pisHeaderData = this.xlData.pisHeaderData;
    const rows = Object.keys(this.allRows[0]);
    this.displayedColumns = [...rows, "action"];
    !this.displayedColumns.includes("error") &&
      this.displayedColumns.push("error");
    if (this.displayedColumns.indexOf("status") > -1) {
      this.displayedColumns.splice(this.displayedColumns.indexOf("status"), 1);
    }

    // this.registeredRows = this.xlData.registeredRows;
    this.validRows = this.xlData.validRows;
    this.notValidRows = this.xlData.notValidRows;
    switch (type) {
      case "All":
        this.dataSource.data = [...this.allRows];
        break;
      case "Valid":
        this.dataSource.data = [...this.validRows];
        break;
      case "Not Valid":
        this.dataSource.data = [...this.notValidRows];
        break;
      case "Registered Managers":
        // this.dataSource.data = [...this.registeredRows];
        let registeredRowsArray = [];
        for (let i = 0; i < this.allRows.length; i++) {
          const row = this.allRows[i];
          const statusObj = row.status.find(
            (obj: any) => obj.participantEmailId === 1
          );
          if (statusObj !== undefined) {
            registeredRowsArray.push(row);
          }
          this.dataSource.data = [...registeredRowsArray];
        }
        break;
    }
    this.updateOptionsMapFromAllRows();
  }
  updateOptionsMapFromAllRows(): void {
    // Create a temporary map to store unique user-provided values
    const tempOptionsMap: { [key: string]: Set<string> } = {};

    // Iterate over allRows to populate the temporary map
    this.allRows.forEach((row) => {
      Object.keys(row).forEach((key) => {
        if (row[key]) {
          const normalizedValue = row[key].toString().trim().toLowerCase();
          if (!tempOptionsMap[key]) {
            tempOptionsMap[key] = new Set<string>();
          }
          if (
            this.optionsMap[key]?.some(
              (option) =>
                option.trim().toLowerCase() === normalizedValue &&
                option != row[key]
            )
          ) {
            tempOptionsMap[key].add(row[key]);
          }
        }
      });
    });
    // Merge the temporary map into the original optionsMap
    Object.keys(tempOptionsMap).forEach((key) => {
      if (!this.optionsMap[key]) {
        this.optionsMap[key] = [];
      }
      // Add unique values from tempOptionsMap to optionsMap
      this.optionsMap[key].push(...Array.from(tempOptionsMap[key]));
    });
  }

  getValidation(data: any) {
    this.companyService.checkDataValidation(data).subscribe((res: any) => {
      if (res.status) {
        let data = {
          allData: res.allRows,
          validRows: res.validRows,
          notValidRows: res.notValidRows,
          standardDemographic: this.standardDemographics,
          dynamicDemographic: this.dynamicDemographics,
          pisHeaderData: this.pisHeaderData,
        };
        // this.data.mappedData(data);
        this.uploadType == "manager"
          ? this.data.setManagerDemographics(data)
          : this.data.setParticipantDemographics(data);
        this.makeTable(this.selectedType, this.uploadType);
      } else {
        Swal.fire({
          title: "Data validation",
          text: "Something went wrong!",
          showConfirmButton: true,
          icon: "error",
        });
      }
    });
  }
  isEditable(row: any, prop: string): boolean {
    return row.error?.some((error: any) => error.hasOwnProperty(prop));
  }
  getOptions(prop: string): string[] {
    return this.optionsMap[prop] || [];
  }
  onSelectionChange(row: any, prop: string, value: string): void {
    row[prop] = value;
    if (row.error) {
      row.error = row.error.filter((error: any) => !error.hasOwnProperty(prop));
    }
    const index = this.allRows.findIndex(
      (item) => item.uniqueId === row.uniqueId
    );
    if (index !== -1) {
      this.allRows[index] = row;
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  onSearchClear() {
    this.searchKey = "";
    this.applyFilter();
  }

  applyFilter() {
    this.dataSource.filter = this.searchKey.trim().toLowerCase();
  }
  getError(key: any) {
    if (this.errors && this.errors.length > 0) {
      const error = this.errors.find((e) => e[key]);
      return error ? error[key] : null;
    }
    return null;
  }
  onInputChange(row: any, prop: string, value: string): void {
    row[prop] = value;
    if (row.error) {
      row.error = row.error.filter((error: any) => !error.hasOwnProperty(prop));
    }
    const index = this.allRows.findIndex(
      (item) => item.uniqueId === row.uniqueId
    );
    if (index !== -1) {
      this.allRows[index] = row;
    }
  }
  validateData() {
    let data = {
      data: this.allRows,
      type: this.uploadType,
      pisHeaderData: this.pisHeaderData,
    };
    this.getValidation(data);
  }
  editRow(template: TemplateRef<any>, data: any, i: any) {
    this.editIndex = i;
    this.errors = [];
    this.errorsKey = [];
    this.editingFormDataKeys = Object.keys(data);
    this.editingFormDataKeys = this.editingFormDataKeys.filter(
      (e) => e !== "error" && e !== "status"
    );
    this.editingFormData = data;
    data.error && (this.errors = data.error);

    if (this.errors && this.errors.length > 0) {
      for (const error of this.errors) {
        for (const value of Object.values(error)) {
          this.errorsKey.push(value);
        }
      }
    }
    if (Object.keys(data).length > 0) {
      this.modalRef = this.modalService.show(
        template,
        Object.assign({}, { class: "my-modal" })
      );
      this.companyService.addModal(this.modalRef);
    }
  }
  deleteRow(template: TemplateRef<any>, data: any, i: any) {
    this.deleteRowData = data;
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: "modal-sm" })
    );
    this.companyService.addModal(this.modalRef);
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwtDecode(token);
    } catch (Error) {
      return null;
    }
  }

  submitData(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: "modal-sm" })
    );
    this.companyService.addModal(this.modalRef);
  }
  confirm(): void {
    let dupAttay = [...this.allRows];
    let index = dupAttay.findIndex(
      (row) => row.uniqueId == this.deleteRowData.uniqueId
    );
    if (index !== -1) {
      dupAttay.splice(index, 1);
    }
    let data = {
      data: dupAttay,
      type: this.uploadType,
      pisHeaderData: this.pisHeaderData,
    };
    this.getValidation(data);
    this.modalRef.hide();
  }
  decline(): void {
    this.modalRef.hide();
  }

  onEdittingForm(formData: any) {
    let updatedData = formData.value;
    const index = this.allRows.findIndex(
      (item) => item.uniqueId === updatedData.uniqueId
    );
    if (index !== -1) {
      this.allRows[index] = updatedData;
    }
    this.modalRef.hide();
    // this.modalRef = null;
    let data = {
      data: this.allRows,
      type: this.uploadType,
      pisHeaderData: this.pisHeaderData,
    };
    this.getValidation(data);
    return true;
  }
  checkAndSubmitData() {
    let token: any = localStorage.getItem("token");
    let deodedToken = this.getDecodedAccessToken(token);
    this.secompanyId = deodedToken.companyId;
    this.companyName = deodedToken.company;
    this.industry = deodedToken.industry;
    this.subIndustry = deodedToken.subIndustry;
    const firstRow = this.validRows[0]; // Only look at the first object
    this.customDemographic.forEach((demographic) => {
      if (
        firstRow[demographic] &&
        !this.dynamicDemographics.includes(demographic)
      ) {
        this.dynamicDemographics.push(demographic);
      }
    });
    let data = {
      pisData: this.validRows,
      pisType: this.uploadType,
      custom:
        this.dynamicDemographics.length > 0 ? this.dynamicDemographics : [],
    };
    this.companyService.submitEmployeeData(data).subscribe((response: any) => {
      if (response.status) {
        Swal.fire({
          title:
            this.uploadType == "manager"
              ? "Participants Added successfully"
              : "Respondents Added successfully",
          text: response.message,
          icon: "success",
          showConfirmButton: false,
          timer: 2500,
        });

        // swal({
        //   title:
        //     this.uploadType == 'manager'
        //       ? 'Participants Added successfully'
        //       : 'Respondents Added successfully',
        //   text: response.message,
        //   type: 'success',
        //   showConfirmButton: false,
        //   timer: 2500,
        // });
      } else {
        Swal.fire({
          title:
            this.uploadType == "manager"
              ? "Participants Not Added"
              : "Respondents Not Added",
          text: response.message,
          icon: "success",
          showConfirmButton: false,
          timer: 2500,
        });
      }
      this.modalRef.hide();
      // this.modalRef = null;
      this.redirectTo("/company/company-dashboard");
    });
  }
  redirectTo(uri: string) {
    this.router
      .navigateByUrl("/", { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }
  handleForms(event: any) {
    this.selectedType = event.target.value;
    switch (event.target.value) {
      case "All":
        this.dataSource.data = [...this.allRows];
        break;
      case "Valid":
        this.dataSource.data = [...this.validRows];
        break;
      case "Not Valid":
        this.dataSource.data = [...this.notValidRows];
        break;
      case "Registered Managers":
        // this.dataSource.data = [...this.registeredRows];
        let registeredRowsArray = [];
        for (let i = 0; i < this.allRows.length; i++) {
          const row = this.allRows[i];
          const statusObj = row.status.find(
            (obj: any) => obj.participantEmailId === 1
          );
          if (statusObj !== undefined) {
            registeredRowsArray.push(row);
          }
          this.dataSource.data = [...registeredRowsArray];
        }
        break;
      case "Registered As B2C":
        let B2CRowsArray = [];
        for (let i = 0; i < this.allRows.length; i++) {
          const row = this.allRows[i];
          const statusObj = row.status.find((obj: any) => obj.isB2C === 1);
          if (statusObj !== undefined) {
            B2CRowsArray.push(row);
          }
          this.dataSource.data = [...B2CRowsArray];
        }
        break;
    }
  }
}
