import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";

import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { AdminServices } from "../../admin.service";
// import { element } from 'protractor';
import swal from "sweetalert2";
@Component({
  selector: "app-schdule-send-certificates",
  templateUrl: "./schdule-send-certificates.component.html",
  styleUrls: ["./schdule-send-certificates.component.css"],
})
export class SchduleSendCertificatesComponent implements OnInit {
  closeModal() {
    throw new Error("Method not implemented.");
  }
  @Input() seCompanyId: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  scheduledEmails = new MatTableDataSource();
  displayedColumns: string[] = [
    "serialNo",
    "name",
    "createdAt",
    "scheduleFor",
    "sentStatus",
    "actions",
  ];
  scheduledForDataObject = {
    id: "",
    scheduleFor: "",
    active: true,
  };
  modalRef: BsModalRef;
  constructor(
    private modalService: BsModalService,
    private adminService: AdminServices,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.getCompanyScheduleEmails();
  }

  getCompanyScheduleEmails() {
    this.adminService
      .getCompanyScheduleEmails({
        seCompanyId: this.seCompanyId,
        emailType: "gmc_certificate_to_manager",
      })
      .subscribe((response) => {
        if (response.status) {
          this.scheduledEmails.data = response.data;
          this.scheduledEmails.paginator = this.paginator;
        }
      });
  }

  editScheduleFor(element, template) {
    this.scheduledForDataObject.id = element._id;
    this.scheduledForDataObject.scheduleFor = element.scheduleFor;
    const config = {
      initialState: {},
      animated: true,
      class: "modal-md",
    };
    this.modalRef = this.modalService.show(template, config);
    this.adminService.addModal(this.modalRef);
  }

  deleteEmail(element) {
    const data = {
      id: element._id,
      updateObject: {
        active: false,
      },
    };
    swal
      .fire({
        title: "Are You Sure You Want To delete this schedule email?",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
      })
      .then((result) => {
        if (result.value) {
          this.onSubmit(data);
        }
      });
  }

  onSubmit(data) {
    const updateData =
      Object.keys(data).length > 0
        ? data
        : {
            id: this.scheduledForDataObject.id,
            updateObject: {
              scheduleFor: this.scheduledForDataObject.scheduleFor,
            },
          };
    this.adminService
      .updateCompanyScheduleEmail(updateData)
      .subscribe((response) => {
        this.openSnackBar(response.message, response.status);
        response.status && this.getCompanyScheduleEmails();
        this.modalService._hideModal(1);
      });
  }

  openSnackBar(message, status) {
    this._snackBar.open(message, "", {
      duration: 2000,
      panelClass: status ? ["green-snackbar"] : ["red-snackbar"],
    });
  }
}
