<div class="container m-20">
    <div class="box-container">
        <div class="m-20">
            <a href="javascript:void(0)" [routerLink]="['/company/company-dashboard/survey-list']"
                class="create-new clr-grey" style="padding: 10px 0">
                <em>
                    <i class="fa fa-arrow-left" aria-hidden="true" style="margin-right: 5px"></i> </em>Back</a>
        </div>
        <h3 class="title">Survey SMS Management</h3>
        <p class="sub-title">
            The survey sms command runs every 15 minutes. For Example, A sms
            message scheduled at 15:07 will be sent at 15:15 and an sms message
            scheduled at 15:17 will be sent at 15:30
        </p>
    </div>
    <div class="box-container m-20">
        <div class="row">
            <div class="button-container">
                <button class="btn gmi-primary-btn float-right" (click)="openEmailTemplate(emailTemp)">
                    Create New Scheduled SMS
                </button>
            </div>
            <div class="table-container" *ngIf="scheduledSms; else noEmailFoundMesasge">
                <table mat-table [dataSource]="scheduledSms" matSort>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                        <td mat-cell *matCellDef="let whatsapp">
                            {{ whatsapp.name | firstPart }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="scheduleFor">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            ScheduleAt
                        </th>
                        <td mat-cell *matCellDef="let whatsapp">
                            {{ whatsapp.schedule_at | date : "MMM d, y, h:mm a" }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="sentStatus">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Sent Status
                        </th>
                        <td mat-cell *matCellDef="let whatsapp">
                            {{ whatsapp.sent == 0? 'false':'true'}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="createdAt">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Created At
                        </th>
                        <td mat-cell *matCellDef="let whatsapp">
                            {{ whatsapp.created_at | date : "MMM d, y, h:mm a" }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                        <td mat-cell *matCellDef="let whatsapp">
                            <div class="action-container">
                                <p (click)="deleteScheduledSMS(whatsapp.id)">
                                    <i class="fa fa-trash" aria-hidden="true"></i>
                                </p>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
</div>

<ng-template #emailTemp>
    <div class="modal-header">
        <h4 style="font-size: 22px; text-transform: capitalize" class="modal-title pull-left">
            Add Whatsapp
        </h4>
        <button type="button " class="close pull-right" aria-label="Close " (click)="modalRef.hide()">
            <span aria-hidden="true ">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <h4 class="sub-title">SMS operation</h4>

                <form [formGroup]="templateForm" (ngSubmit)="onSubmit()" class="form-container">
                    <div class="input-group">
                        <label for="templateName">Template Name <span class="error">*</span></label>
                        <select name="templateName" id="templateName" formControlName="template"
                            (change)="changeTemplate($event)">
                            <option [value]="value.sms_communication_type_mapping_id"
                                *ngFor="let value of smsTemplates">
                                {{ value.name }}
                            </option>
                        </select>
                    </div>
                    <div class="input-group">
                        <label for="name">Name <span class="error">*</span></label>
                        <input type="text" id="name" formControlName="name" />
                    </div>
                    <div class="input-group">
                        <label for="to">To <span class="error">*</span></label>
                        <select name="to" id="to" formControlName="to">
                            <option [value]="value" *ngFor="let value of toTypesTemplate">
                                {{ value }}
                            </option>
                        </select>
                    </div>
                    <div class="input-group">
                        <label for="scheduleAt">ScheduleAt</label>

                        <div class="extra-input">
                            <input [owlDateTimeTrigger]="dt10" [owlDateTime]="dt10" [selectMode]="'range'"
                                [min]="startAt" formControlName="scheduleAt" />
                            <owl-date-time #dt10></owl-date-time>
                        </div>
                    </div>
                    <div *ngIf="selectedTemplate.placeholders">
                        <div formArrayName="placeholders">
                            <div *ngFor="let placeholder of placeholders.controls; let i = index" [formGroupName]="i">
                                <div class="input-group">
                                    <label for="placeholder-{{i}}">{{ placeholder.get('name').value }}<span class="error">*</span></label>
                                    <input id="placeholder-{{i}}" type="text" formControlName="value"
                                        [placeholder]="placeholder.get('name').value" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="(selectedTemplate | json) != '{}'">
                        <div class="msgBox">
                            <!-- <div class="placeHolder">
                                <div *ngFor="let data of keyValuePairs">
                                    <div class="flex">
                                        <h3>{{ data[0] }}</h3>
                                        <h3>{{ data[1] }}</h3>
                                    </div>
                                </div>
                            </div> -->
                            <div>
                                <h3 class="fs-14 font-bold">SMS Contents</h3>
                                <div>{{ selectedTemplate.template }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="button-container">
                        <button type="submit" class="btn" [disabled]="!templateForm.valid">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #noEmailFoundMesasge>
    <div class="no-message">
        <p class="title">No Scheduled SMS found!</p>
    </div>
</ng-template>