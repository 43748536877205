import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs';
import { map } from 'rxjs';
import { debounceTime } from 'rxjs';
import { distinctUntilChanged } from 'rxjs';
import { AdminServices } from '../admin.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-local-company-input',
  template: `<div class="form-group">
    <input
      type="text"
      placeholder="Search & select your current company"
      aria-label="Number"
      matInput
      name="company"
      style="background-color: #fff;border: 2px solid #ccc;padding-left: 10px;"
      [formControl]="myControl"
      [matAutocomplete]="auto"
      class="form-control custom-form-control searchInput"
      (change)="companyAddedByChange()"
      required
    />
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
      <mat-option *ngIf="isLoading" class="is-loading">
        <mat-spinner diameter="50"></mat-spinner>
      </mat-option>
      <ng-container *ngIf="!isLoading">
        <mat-option
          *ngFor="let option of filteredOptions | async"
          [value]="option"
          (onSelectionChange)="companyAdded($event)"
        >
          {{ option }}
        </mat-option>
      </ng-container>
    </mat-autocomplete>
    <p style="color:red;" *ngIf="newCompanyAlert">No Company found!</p>
  </div>`,
  styleUrls: ['../corporate-companies/corporate-companies.component.css'],
})
export class LocalCompanyInputComponent implements OnInit {
  myControl: FormControl = new FormControl();
  options = [];
  companyId = [];
  filteredOptions: Observable<string[]>;
  newCompanyAlert: boolean = false;
  result: any;
  data = '';
  isLoading = false;

  @Output()
  outPutToParent = new EventEmitter<string>();
  @Output()
  outPutIsNewCompanyToParent = new EventEmitter<boolean>();
  @Output()
  companyIdOutPutToParent = new EventEmitter<number>();

  constructor(private adminService: AdminServices) {}

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      debounceTime(1000),
      distinctUntilChanged(),
      map((val) => {
        this.isLoading = true;
        if (val != '') {
          this.isLoading = false;
          return this.filter(val);
        }
        this.isLoading = false;
        return null;
      })
    );

    this.adminService.getCompanyList().subscribe((res) => {
      if (res.data.length > 0) {
        res.data.forEach((company) => {
          // console.log(company , 'company')
          this.options.push(company.name);
          this.companyId.push(company.seCompanyId);
        });
      } else {
        alert('Companies is not fetched!');
      }
    });
  }

  companyAdded(val) {
    //Sending Data to parent Form
    if (val.source.value.trim() === '') {
      return false;
    }
    let isNew = this.checkNewCompany(val.source.value);
    if (isNew) {
      this.outPutToParent.emit(val.source.value);
      this.companyIdOutPutToParent.emit(1);
    } else {
      this.companyIdOutPutToParent.emit(this.companyId[this.result]);
      this.outPutToParent.emit(this.options[this.result]);
    }
    this.outPutIsNewCompanyToParent.emit(isNew);
    return null;
  }

  companyAddedByChange() {
    let isNew = this.checkNewCompany(this.myControl.value);
    if (isNew) {
      this.outPutToParent.emit(this.myControl.value);
      this.companyIdOutPutToParent.emit(1);
    } else {
      this.outPutToParent.emit(this.options[this.result]);
      this.companyIdOutPutToParent.emit(this.companyId[this.result]);
    }
    this.outPutIsNewCompanyToParent.emit(isNew);
  }

  checkNewCompany(value) {
    this.result = this.options.findIndex(
      (item) => value.toLowerCase() == item.toLowerCase()
    );

    if (this.result != -1) {
      //In array
      this.newCompanyAlert = false;
      return this.newCompanyAlert;
    } else {
      //Not in array
      this.newCompanyAlert = true;
      return this.newCompanyAlert;
    }
  }

  filter(val: string): string[] {
    return this.options.filter(
      (option) => option.toLowerCase().indexOf(val.toLowerCase()) === 0
    );
  }
}
