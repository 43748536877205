import { Component, OnInit, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { AdminServices } from "../admin.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-nudge-crons",
  templateUrl: "./nudge-crons.component.html",
  styleUrls: ["./nudge-crons.component.css"],
})
export class NudgeCronsComponent implements OnInit {
  nudgeForm: FormGroup;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = [
    "schedule_time",
    "data_percentage",
    "is_active",
    "actions",
  ];
  dataSource = new MatTableDataSource<any>();
  isUpdate: boolean = false;
  totalPercentage: number = 0;

  constructor(
    private formBuilder: FormBuilder,
    private adminService: AdminServices,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.getNudgeCronConfigs();
    this.initializeForm();
  }

  initializeForm() {
    this.nudgeForm = this.formBuilder.group({
      id: [""],
      schedule_time: [
        "",
        [Validators.required, this.timeRangeValidator("08:00", "18:00")],
      ],
      data_percentage: [
        "",
        [Validators.required, Validators.min(0), Validators.max(100)],
      ],
      is_active: [true, [Validators.required]],
    });
  }

  createNudgeConfig(data: any) {
    const { schedule_time, data_percentage, is_active, id, statusChange } = data
      ? data
      : this.nudgeForm.value;

    const paramsData = {
      id,
      schedule_time,
      data_percentage,
      is_active: is_active ? 1 : 0,
      mode: this.isUpdate ? "update" : "create",
      statusChange,
    };

    this.adminService
      .nudgeCronConfigOperation(paramsData)
      .subscribe((response) => {
        if (response.status && this.isUpdate) {
          this.updateInArray(paramsData, 0);
        }
        this.isUpdate = false;
        this.getNudgeCronConfigs();
        this.nudgeForm.reset();
        this.openSnackBar(response.message, response.status);
      });
  }

  patchValues(element: any) {
    const { schedule_time, data_percentage, is_active, id } = element;
    this.nudgeForm.patchValue({
      schedule_time,
      data_percentage,
      is_active,
      id,
    });
    this.isUpdate = true;
  }

  getNudgeCronConfigs() {
    this.adminService.getNudgeCronConfigs().subscribe((response) => {
      this.dataSource.data = response.data;
      this.dataSource.paginator = this.paginator;
      this.getTotalPercentage();
    });
  }

  updateInArray(element: any, id?: number) {
    const matchId = element ? element.id : id;
    const index = this.dataSource.data.findIndex((x) => x.id == matchId);
    if (index > -1) {
      element
        ? (this.dataSource.data[index] = element)
        : (this.dataSource.data[index]["is_active"] = 0);
      this.dataSource._updateChangeSubscription();
    }
  }

  openSnackBar(message: any, status: boolean) {
    this._snackBar.open(message, "", {
      duration: 5000,
      panelClass: status ? ["green-snackbar"] : ["red-snackbar"],
    });
  }

  statusChange($event, element) {
    element.is_active = $event.checked ? 1 : 0;
    element["statusChange"] = true;
    this.isUpdate = true;
    this.createNudgeConfig(element);
  }

  formatScheduleTime(scheduleTime: string): string {
    if (!scheduleTime) return "";
    let [hours, minutes] = scheduleTime.split(":").map(Number);
    // If minutes are "01", change them to "00"
    if (minutes === 1) {
      minutes = 0;
    }
    // Convert 24-hour format to 12-hour format and determine AM/PM
    const period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert hour to 12-hour format
    const formattedTime = `${this.padZero(hours)}:${this.padZero(
      minutes
    )} ${period}`;
    return formattedTime;
  }

  padZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  getTotalPercentage() {
    const total = this.dataSource.data.reduce((acc, element) => {
      // If element is active, add its percentage, otherwise return acc unchanged
      return element.is_active == 1
        ? acc + Number(element.data_percentage)
        : acc;
    }, 0); // Initial value for acc is set to 0

    this.totalPercentage = total;
  }
  timeRangeValidator(min: string, max: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value = control.value;
      if (!value) {
        return null; // Return null if there's no value (required validator will handle this)
      }
      const time = value.split(":");
      const timeInMinutes = parseInt(time[0], 10) * 60 + parseInt(time[1], 10);

      const minTime = min.split(":");
      const minTimeInMinutes =
        parseInt(minTime[0], 10) * 60 + parseInt(minTime[1], 10);

      const maxTime = max.split(":");
      const maxTimeInMinutes =
        parseInt(maxTime[0], 10) * 60 + parseInt(maxTime[1], 10);

      // Check if time is within the range
      if (
        timeInMinutes < minTimeInMinutes ||
        timeInMinutes > maxTimeInMinutes
      ) {
        return { timeRange: true }; // Return error if time is outside the range
      }
      return null; // No error if time is within the range
    };
  }
}
