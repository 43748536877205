import {
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
} from "@angular/core";
import { AdminServices } from "../../admin.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import swal from "sweetalert2";
import { USER_TYPE } from "../../../constants";
@Component({
  selector: "app-leadership-analytics",
  templateUrl: "./leadership-analytics.component.html",
  styleUrls: ["./leadership-analytics.component.css"],
})
export class LeadershipAnalyticsComponent implements OnInit {
  visualizations: any = [];
  errorMessage: string = "";
  visualizationForm: FormGroup;

  isEditMode: boolean = false;
  visualizationId: number | null = null;
  modalRef: any;
  // userType = USER_TYPE;
  userTypes = [
    { key: "1", value: "Manager" },
    { key: "2", value: "Employee" },
    { key: "3", value: "Organization" },
  ];
  defaultColor = "#666";
  color: any;
  constructor(
    private adminService: AdminServices,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    private cdref: ChangeDetectorRef
  ) {
    this.visualizationForm = this.fb.group({
      name: ["", Validators.required],
      type: ["5"],
      is_active: [true, Validators.required],
      page_type: ["5"],
      header: ["", Validators.required],
      description: ["", Validators.required],
      colour: [""],
      content: ["", Validators.required],
      is_filter: [false, Validators.required],
      icon_url: [""],
      user_type: ["", Validators.required],
    });
    this.color = this.visualizationForm.get("colour").value || "";
  }

  ngOnInit(): void {
    this.adminService.getVisualization().subscribe(
      (data: any) => {
        this.visualizations = data.data;
        this.visualizations.sort((a, b) => a.page_type - b.page_type);
        this.adminService.updateVisualizationList(this.visualizations);
      },
      (error: string) => {
        this.errorMessage = error;
      }
    );
  }
  openAddModal(identifier: string, template: TemplateRef<any>, data?: any) {
    let config: ModalOptions = {
      backdrop: "static",
      ignoreBackdropClick: true,
    };

    switch (identifier) {
      case "addTemplate":
        config["class"] = "modal-lg";
        this.isEditMode = false;
        this.visualizationForm.reset({
          name: "",
          type: "5",
          is_active: true,
          page_type: "5",
          header: "",
          description: "",
          colour: "",
          content: "",
          is_filter: false,
          icon_url: "",
          user_type: "",
        });
        this.color = "";
        break;
      case "editTemplate":
        config["class"] = "modal-lg";
        this.isEditMode = true;
        this.visualizationForm.patchValue(data);
        this.visualizationId = data.id;
        break;
    }
    this.modalRef = this.modalService.show(template, config);
    this.adminService.addModal(this.modalRef);
  }

  deleteVisualizationDetail(data) {
    swal
      .fire({
        title: "Are you sure you want to delete?",
        text: "You will not be able to recover this user!",
        // icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
      .then((isConfirm) => {
        if (isConfirm.value) {
          this.confirmDelete(data);
        }
      });
  }

  confirmDelete(data) {
    this.adminService.deleteVisualization(data.id).subscribe((res: any) => {
      if (res.status) {
        swal.fire("success", res.message);
        this.modalRef.hide();
        this.visualizations = this.visualizations.filter(
          (item) => item.id != data.id
        );
        this.visualizations = this.visualizations.filter(
          (item) => item.id != data.id
        );

        this.adminService.updateVisualizationList(this.visualizations);
      } else {
        swal.fire("error", res.message);
      }
    });
  }
  onColorChange(color: string) {
    this.color = color;
    this.visualizationForm.get("colour").setValue(color);
  }
  onSubmit(): void {
    if (this.visualizationForm.invalid) {
      return;
    }
    const formData = this.visualizationForm.value;

    if (this.isEditMode && this.visualizationId !== null) {
      formData.id = this.visualizationId;
      this.adminService
        .updateVisualization(this.visualizationId, formData)
        .subscribe((res: any) => {
          if (res.status) {
            swal.fire("success", res.message);
            const index = this.visualizations.findIndex(
              (u) => u.id == this.visualizationId
            );

            if (index != -1) this.visualizations[index] = formData;

            this.adminService.updateVisualizationList(this.visualizations);
            this.modalRef.hide();
          } else {
            swal.fire("error", res.message);
          }
        });
    } else {
      this.adminService.createVisualization(formData).subscribe((res: any) => {
        if (res.status) {
          swal.fire("success", res.message);
          formData.id = res.data;
          this.visualizations.push(formData);
          this.adminService.updateVisualizationList(this.visualizations);

          this.modalRef.hide();
        } else {
          swal.fire("error", res.message);
        }
      });
    }
  }
  handleChange(index) {
    // this.selectedObject = this.userType[index];
  }
}
