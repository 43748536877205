<div class="login-main">
  <div class="container-fluid clearfix">
    <!-- //<app-loader *ngIf="loading"></app-loader> -->
    <div class="row">
      <div class="tbl">
        <div class="tbl-cel-left"></div>
        <div class="tbl-cel-right">
          <form [formGroup]="forgotPasswordForm">
            <div class="col-md-6 login-right text-center">
              <div class="form-group">
                <img src="../../../assets/images/KEY.png" />
                <h3>Forgot Password</h3>
                <p style="color: #9b9b9b">
                  Forgotten your password? Enter your email address below to
                  begin the reset process
                </p>
              </div>

              <div class="form-group mt-30">
                <input
                  type="email"
                  class="form-control input-lg envelope-icon"
                  placeholder="Enter your email id"
                  formControlName="email"
                />

                <!-- <span *ngIf="forgotPasswordForm.get('email').hasError('required') && forgotPasswordForm.get('email').touched">
                  Email is required
                </span>
                <span *ngIf="forgotPasswordForm.get('email').hasError('email')&&!forgotPasswordForm.get('email').hasError('required')">
                  Invalid Email
                </span>
 -->
              </div>

              <div class="form-group mt-25">
                <button
                  type="button"
                  class="btn btn-primary btn-lg"
                  (click)="onforgotpassword()"
                  [disabled]="!forgotPasswordForm.valid"
                >
                  RESET PASSWORD
                </button>
                <button
                  style="display: none"
                  #myDiv
                  type="button"
                  class="btn btn-primary btn-lg"
                  data-toggle="modal"
                  data-target="#myModal"
                >
                  set
                </button>
              </div>

              <!-- <button type="button" class="btn btn-info btn-lg" data-toggle="modal" data-target="#myModal">Open Modal</button> -->

              <!-- Modal -->
              <div
                class="modal"
                id="myModal"
                role="dialog"
                data-keyboard="false"
                data-backdrop="static"
              >
                <div class="modal-dialog modal-custom-width">
                  <!-- Modal content-->
                  <div class="modal-content">
                    <div class="modal-header mh-custom">
                      <button
                        type="button"
                        class="closebutton"
                        data-dismiss="modal"
                        (click)="onClickX()"
                      >
                        &times;
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="modal-body">
                        <div class="modal-forgot">
                          <div class="modal-left">
                            <img
                              class="resetp-img"
                              src="../../../assets/images/KEY.png"
                            />
                          </div>
                          <div class="modal-right">
                            <h4>Reset Password Confirmation</h4>
                            <p>Check Your Mail for Password Reset Link</p>
                            <ul class="ul-preset">
                              <li>
                                A password reset message has been sent to your
                                email address.Please click the
                                <br />
                                link in that message to reset your password
                              </li>
                              <li>
                                If you do not receive password reset message
                                within a few minutes,
                                <br />
                                Please check your spam folder or other filtering
                                tool.
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                    </div>

                    <button
                      type="submit"
                      class="btn okgotit"
                      data-dismiss="modal"
                      (click)="onSet()"
                    >
                      OK GOT IT
                    </button>
                  </div>
                </div>
              </div>

              <div class="form-group mt-30">
                <a routerLink="/admin">Back to Login</a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
