import { Component, OnInit, ViewChild } from "@angular/core";
import { CompanyServices } from "../company.service";
import * as XLSX from "xlsx";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { constants } from "src/app/constants";

// import * as jwt_decode from 'jwt-decode';
import { jwtDecode } from 'jwt-decode';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: "app-pre-upload-action-plan",
  templateUrl: "./pre-upload-action-plan.component.html",
  styleUrls: ["./pre-upload-action-plan.component.css"],
})
export class PreUploadActionPlanComponent implements OnInit {
  managerList: MatTableDataSource<any> = new MatTableDataSource<any>();
  seCompanyId!: number;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  samplePdfUpload =
    constants.IMAGE_URL +
    "sheets/action-plan/Pre%2BUpload%2BAction%2BPlan%2BSample%2BSheet%2BNew.xlsx";

  displayedColumns: string[] = [
    "managerName",
    "managerEmailId",
    "actionPlanName",
    "theme",
    "subPracticeArea",
    "goal",
    "description",
    "duration",
    "implementation",
    "type",
    "memorable",
    "implTeamBehaviour",
    "implBusinessPerformance",
    "isPresentForCommunity",
    "status",
    "addedBy",
  ];

  constructor(
    private companyService: CompanyServices,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    if (localStorage.getItem("token") !== null) {
      let tokenData: any = jwtDecode(localStorage.getItem("token"));
      this.seCompanyId = tokenData.companyId;
    }
  }

  onFileChange(event: any) {
    const file: File = event.target.files[0];
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: "binary" });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      let data: any[] = XLSX.utils.sheet_to_json(ws, { raw: true, header: 1 });
      const headers: any[] = data.shift().map((header: any) => header.trim());
      data = data.filter((data) => {
        return data.length > 0;
      });
      const output: any[] = data.map((row: any) => {
        const obj: any = {};
        row.forEach((cell: any, i: number) => {
          obj[headers[i]] = cell;
        });
        return obj;
      });
      this.managerList.data = output;
      this.managerList.paginator = this.paginator;
      event.target.value = null;
    };
    reader.readAsBinaryString(file);
  }

  searchManager(value: string) {
    let filterValue = value.trim().toLowerCase();
    this.managerList.filter = filterValue;
  }

  submitActionPlans() {
    this.companyService
      .uploadActionPlansFromCompanyAdmin({
        seCompanyId: this.seCompanyId,
        managerList: this.managerList.data,
      })
      .subscribe({
        next: (res: any) => {
          if (res.status) {
            res.data && res.data.length > 0 && this.exportExcel(res.data);
            this.managerList.data = [];
            this.openSnackBar(res.message, true);
          }
        },
        error: (err: any) => {
          let errorMessage = "Data Validation: " + err.error.error;
          this.openSnackBar(errorMessage, false);
        },
      });
  }

  exportExcel(data: any): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    this.saveAsExcelFile(excelBuffer, "Added by Action Plan Sheet.");
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const fileUrl: string = URL.createObjectURL(data);
    const link: HTMLAnchorElement = document.createElement("a");
    link.href = fileUrl;
    link.download = `${fileName}.xlsx`;
    link.click();
  }

  openSnackBar(message: any, status: any) {
    this._snackBar.open(message, "", {
      duration: 2000,
      panelClass: status ? ["green-snackbar"] : ["red-snackbar"],
    });
  }
}
