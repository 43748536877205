import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-view-batches',
  templateUrl: './view-batches.component.html',
  styleUrls: ['./view-batches.component.css']
})
export class ViewBatchesComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit() {
  }
  redirectToBatchesDashboard(){
    this.router.navigate(['/company/company-dashboard/view-batches']);
  }
}
