import { Component, Input, OnInit, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { AdminServices } from "../../admin.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { constants } from "src/app/constants";

@Component({
  selector: "app-survey-fillers",
  templateUrl: "./survey-fillers.component.html",
  styleUrls: ["./survey-fillers.component.css"],
})
export class SurveyFillersComponent implements OnInit {
  contentPageForm: FormGroup;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  fileInputData: {
    fileAccept: string;
    note: string;
  } = {
    fileAccept: "",
    note: "",
  };

  IMAGE_URL = constants.IMAGE_URL;
  displayedColumns: string[] = [
    "content_type",
    "url",
    "description",
    "button_text",
    "is_active",
    "actions",
  ];
  dataSource = new MatTableDataSource<any>();
  isUpdate: boolean = false;
  acceptedFileTypes: any = [];

  constructor(
    private formBuilder: FormBuilder,
    private adminService: AdminServices,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.getSurveyContentPages();
    this.initializeForm();
  }

  searchFillers(event: any) {
    const value = event.target.value;
    let filterValue = value.trim().toLowerCase();
    this.dataSource.filter = filterValue;
  }

  initializeForm() {
    this.contentPageForm = this.formBuilder.group({
      id: ["", []],
      content_type: ["", [Validators.required]],
      url: ["", [this.urlRequiredIfContentType()]],
      description: [
        "",
        [
          Validators.required,
          Validators.minLength(25),
          Validators.maxLength(250),
        ],
      ],
      button_text: [
        "",
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(25),
        ],
      ],
      is_active: [false, [Validators.required]],
    });
  }

  createContentPage(data: any) {
    const { content_type, url, description, is_active, id, button_text } = data
      ? data
      : this.contentPageForm.value;

    const paramsData = {
      content_type,
      url,
      description,
      is_active: is_active == true ? 1 : 0,
      id,
      button_text,
      mode: this.isUpdate ? "update" : "create",
    };

    this.adminService
      .surveyContentPageOperation(paramsData)
      .subscribe((response) => {
        if (response.status) {
          switch (paramsData["mode"]) {
            case "create":
              let newData = {
                ...this.contentPageForm.value,
                id: response.data.newdId,
              };
              this.dataSource.data.push(newData);
              this.dataSource._updateChangeSubscription();
              break;
            case "update":
              this.updateInArray(this.contentPageForm.value, 0);
              break;
          }
          this.contentPageForm.reset();
          this.getSurveyContentPages();
          this.isUpdate = false;
        }
        this.openSnackBar(response.message, response.status);
      });
  }

  patchValues(element: any) {
    const { content_type, url, description, is_active, id, button_text } =
      element;

    this.contentPageForm.patchValue({
      content_type,
      url,
      description,
      is_active: is_active == 1 ? true : false,
      id,
      button_text,
    });
    this.onContentTypeChange({ target: { value: content_type } });
    this.isUpdate = true;
  }

  deleteContentPage(id: number) {
    this.adminService
      .surveyContentPageOperation({ id, mode: "delete" })
      .subscribe((response) => {
        this.updateInArray(null, id);
        this.openSnackBar(response.message, response.status);
      });
  }

  getSurveyContentPages() {
    this.adminService.getSurveyContentPages().subscribe((response) => {
      if (response.status) {
        this.dataSource.data = response.data;
        this.dataSource.paginator = this.paginator;
      }
    });
  }

  updateInArray(element: any, id?: number) {
    const matchId = element ? element.id : id;
    const index = this.dataSource.data.findIndex((x) => x.id == matchId);
    if (index > -1) {
      element
        ? (this.dataSource.data[index] = element)
        : (this.dataSource.data[index]["is_active"] = 0);
      this.dataSource._updateChangeSubscription();
    }
  }

  openSnackBar(message: any, status: boolean) {
    this._snackBar.open(message, "", {
      duration: 2000,
      panelClass: status ? ["green-snackbar"] : ["red-snackbar"],
    });
  }

  fileChangeEvent(events): void {
    var reader = new FileReader();
    if (events.target.files[0].size > 5242880) {
      this.openSnackBar("File size should be less than 5MB.", false);
      const fileInput: HTMLInputElement = document.getElementById(
        "fileSelect"
      ) as HTMLInputElement;
      fileInput.value = "";
      return;
    }

    // Check file type
    if (!this.acceptedFileTypes.includes(events.target.files[0].type)) {
      this.openSnackBar(
        `Invalid file type. Please upload  ${this.acceptedFileTypes}.`,
        false
      );
      const fileInput: HTMLInputElement = document.getElementById(
        "fileSelect"
      ) as HTMLInputElement;
      fileInput.value = "";
      return;
    }

    reader.readAsDataURL(events.target.files[0]); // read file as data url
    reader.onload = (event: any) => {
      const img = new Image();
      img.src = event.target.result;
      // img.onload = () => {
      //   const width = img.width;
      //   const height = img.height;

      //   if (width > 1920 || height > 1080) {
      //     this.openSnackBar(
      //       "Image dimensions should not exceed 1920x1080 pixels",
      //       false
      //     );

      //     const fileInput: HTMLInputElement = document.getElementById(
      //       "fileSelect"
      //     ) as HTMLInputElement;
      //     fileInput.value = "";
      //     return;
      //   }
      // };
      let file = event.target.result;
      let data = {
        file: {
          data: file,
          name: events.target.files[0].name,
          type: events.target.files[0].type,
          size: events.target.files[0].size,
        },
      };
      this.adminService
        .uploadSurveyContentPageImages(data)
        .subscribe((res: any) => {
          if (res.status) {
            this.contentPageForm.patchValue({
              url: res.data.uploadedUrl,
            });
          }
          this.openSnackBar(res.message, res.status);
        });
    };
  }

  /**
   * Handles the change event of the content type select element.
   * Updates the file accept attribute and the note based on the selected value.
   *
   * @param {Event} event - The change event object
   * @return {void} This function does not return anything
   */
  onContentTypeChange(event: any): void {
    // Get the selected value from the event target
    const value = (event.target as HTMLSelectElement).value;

    if (value === "image") {
      this.fileInputData.fileAccept =
        "image/x-png,image/gif,image/jpeg,image/png"; // Accept image files (PNG, GIF, JPEG)
      this.fileInputData.note =
        "Note: Please upload an image file (PNG, GIF, JPEG).";
      this.acceptedFileTypes = [
        "image/x-png",
        "image/gif",
        "image/jpeg",
        "image/png",
      ];
    } else if (value === "video") {
      this.fileInputData.fileAccept = "video/mp4"; // Accept video files (MP4)
      this.fileInputData.note = "Note: Please upload a video file (MP4).";
      this.acceptedFileTypes = ["video/mp4"];
    } else {
      this.fileInputData.fileAccept = ""; // Reset accept attribute
      this.fileInputData.note = ""; // Reset note
    }
  }

  getImageUrl(url) {
    const newUrl = `${this.IMAGE_URL}${url}`;
    return newUrl;
  }

  /**
   * Handles the status change event of a slide toggle.
   * If the slide toggle is turned off, it calls the deleteContentPage function.
   * If the slide toggle is turned on, it sets the isUpdate flag to true,
   * sets the is_active property of the element to 1 and calls the createContentPage function.
   *
   * @param {MatSlideToggleChange} $event - The event object for the slide toggle change event.
   * @param {any} element - The element object containing the id and is_active properties.
   */
  statusChange($event: MatSlideToggleChange, element) {
    // If the slide toggle is turned off, delete the content page
    if (!$event.checked) {
      this.deleteContentPage(element.id);
    } else {
      // If the slide toggle is turned on, update the content page
      this.isUpdate = true; // Set the isUpdate flag to true
      element["is_active"] = 1; // Set the is_active property of the element to 1
      this.createContentPage(element); // Call the createContentPage function with the element
    }
  }

  /**
   * Returns a validator function that checks if the URL is required based on the selected content type.
   * If the selected content type is "image" or "video" and the URL is empty, it returns an object with a 'required' key.
   * Otherwise, it returns null.
   *
   * @return {ValidatorFn} The validator function.
   */
  urlRequiredIfContentType(): ValidatorFn {
    // The validator function that checks if the URL is required based on the selected content type.
    return (control: AbstractControl): { [key: string]: any } | null => {
      // Get the selected value of the content type control.
      const contentType = this.contentPageForm?.get("content_type")?.value;

      // Check if the selected content type is "image" or "video" and the URL is empty.
      if (
        (contentType === "image" || contentType === "video") &&
        !control.value
      ) {
        // If the conditions are met, return an object with a 'required' key.
        return { required: true };
      } else {
        // Otherwise, return null.
        return null;
      }
    };
  }
}
