import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
// import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

import * as FileSaver from "file-saver";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import swal from "sweetalert2";
import * as XLSX from "xlsx";
import { read, utils } from "xlsx";
import { AdminServices } from "../../admin.service";

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

export interface nudgeActiveTable {
  nudge: string;
  active: string;
  actions: any;
}
@Component({
  selector: "app-nudges-active",
  templateUrl: "./nudges-active.component.html",
  styleUrls: ["./nudges-active.component.css"],
})
export class NudgesActiveComponent implements OnInit {
  dataSource = new MatTableDataSource<nudgeActiveTable>();
  displayedColumns: string[] = ["Theme", "Sub Theme", "Nudges", "Actions"];
  excelData: Array<any> = [];
  modalRef: BsModalRef;
  editNudge: FormGroup;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  edit: any;

  constructor(
    private adminService: AdminServices,
    private modalService: BsModalService,
    private fb: FormBuilder
  ) {
    this.editNudge = this.fb.group({
      editNudgeContent: new FormControl(),
    });
    let row: any;
    let data = {
      isActive: true,
    };
    this.adminService.getNudgeList(data).subscribe((res: any) => {
      this.dataSource.data = res.data;
      res.data.forEach((res) => {
        row = {
          Id: res._id,
          Theme: res.theme,
          "Sub Theme": res.subTheme,
          Nudge: res.content,
          "Character Count": res.characterCount,
          Active: res.characterCount,
          Edited: "",
        };
        this.excelData.push(row);
      });
    });
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  readyToExport = [
    { id: 1, name: "a" },
    { id: 2, name: "b" },
    { id: 3, name: "c" },
  ];

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();

    this.dataSource.filter = filterValue;
  }

  exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "buffer",
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  async convertExcelToJson(file) {
    let reader = new FileReader();
    let workbookkk;
    let XL_row_object;
    let json_object;
    let finalExcel = [];
    let finalData = {};
    reader.readAsBinaryString(file[0]);
    return new Promise((resolve, reject) => {
      reader.onload = function () {
        let data = reader.result;
        workbookkk = read(data, { type: "binary" });
        workbookkk.SheetNames.forEach(function (sheetName) {
          XL_row_object = utils.sheet_to_json(workbookkk.Sheets[sheetName]);
          json_object = JSON.stringify(XL_row_object);
          resolve(XL_row_object);
        });
      };
    }).then((res: Array<any>) => {
      let editCount = 0;
      res.forEach((data) => {
        finalData = {};
        finalData["id"] = data.Id;
        finalData["theme"] = data.Theme;
        finalData["subTheme"] = data["Sub Theme"];
        finalData["nudgeContent"] = data.Nudge;
        finalData["characterCount"] = data.Edited
          ? data.Nudge.length
          : data["Character Count"];
        finalData["active"] = data.Active;
        finalData["edited"] = data.Edited;
        finalExcel.push(finalData);
        if (data.Edited == true) {
          if (data.Nudge.length > 200) {
            editCount += 1;
          }
        }
      });
      if (editCount > 0) {
        swal.fire({
          title: "Nudges Count exceeded charater Limit 200",
          icon: "error",
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        let data = {
          excelData: finalExcel,
          isFile: true,
        };

        this.PostExcel(data);
      }
    });
  }

  PostExcel(data) {
    this.adminService.updateNudgeLibrary(data).subscribe((res) => {});
  }

  sendExcel(file) {
    this.convertExcelToJson(file);
  }

  deleteNudge() {
    swal
      .fire({
        title: "Are you sure?",
        text: "You will not be able to recover this Nudge!",
        icon: "error",
        showCancelButton: true,
        showConfirmButton: true,
      })
      .then(function (isConfirm) {
        if (isConfirm) {
        } else if (isConfirm.dismiss) {
        }
      });
  }

  viewModals(id, template) {
    this.edit = this.dataSource.data.filter((x: any) => x._id == id);
    this.edit = this.edit[0];
    this.editNudge.controls["editNudgeContent"].setValue(this.edit.content);
    this.modalRef = this.modalService.show(template);
    this.adminService.addModal(this.modalRef);
  }

  editSingleNudge() {
    this.edit.content = this.editNudge.controls["editNudgeContent"].value;
    let finalData = {};
    finalData["id"] = this.edit._id;
    finalData["theme"] = this.edit.theme;
    finalData["subTheme"] = this.edit.subTheme;
    finalData["nudgeContent"] = this.edit.content;
    finalData["characterCount"] = this.edit.characterCount;
    finalData["active"] = this.edit.isActive;
    finalData["edited"] = true;
    let data = {
      excelData: finalData,
      isFile: false,
    };
    this.PostExcel(data);
  }
}
