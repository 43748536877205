<div class="container-fluid" style="padding-top: 3%">
  <!-- //<app-loader *ngIf="loading"></app-loader> -->
  <div
    class="col-md-12 form-card"
    style="
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    "
  >
    <div class="row">
      <div
        class="col-md-12 col-xs-6 col-md-6 subadmin-title"
        style="padding: 0px !important"
      >
        <div class="col-md-7" style="padding-top: 2%; padding-bottom: 2%">
          <input
            type="text"
            (keyup)="applyFilter($event.target['value'])"
            class="form-control search-icon"
            placeholder="Enter your search..."
            (keyup)="applyFilter($event.target['value'])"
          />
          <div class="clearfix"></div>
        </div>
        <div class="col-md-5">
          <!--  <button type="button" (click)="openAddModal(addTemplate)" style="margin-right: -21%;float: right;margin-top: 6%;" class="btn btn-secondary btn-newuser-clr">Add mentor account
                    </button> -->
          <!-- <button
            [routerLink]="['/admin/admin-dashboard/mentor/create-mentor']"
            style="margin-right: -21%; float: right; margin-top: 6%"
            class="btn btn-secondary btn-newuser-clr"
          >
            Add mentor account
          </button> -->

          <!--  <i class="fa fa-user-plus" (click)="openAddModal()"
                                        style="padding-right: 7%;">Add mentor account</i> -->
          <ng-template #addTemplate>
            <div class="modal-header">
              <h4 style="font-size: 22px" class="modal-title pull-left">
                Add User
              </h4>
              <button
                type="button "
                class="close pull-right"
                aria-label="Close "
                (click)="modalRef.hide()"
              >
                <span aria-hidden="true ">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12" style="padding: 0">
                  <div class="form-group col-md-6">
                    <h3>Add new user</h3>
                    <form (ngSubmit)="onSubmit(f)" #f="ngForm">
                      <label for="name"
                        >Mentor Name<span class="asterick">*</span></label
                      >
                      <input
                        type="text"
                        name="name"
                        [(ngModel)]="addName"
                        class="form-control custom-form-control"
                        placeholder="Enter user name"
                        required
                        #addUser="ngModel"
                        style="margin-bottom: 3%"
                      />
                      <p
                        class="warning"
                        *ngIf="!addUser.valid && addUser.touched"
                      >
                        Please Enter mentor name
                      </p>
                      <label for="email"
                        >Email<span class="asterick">*</span></label
                      >
                      <input
                        type="text"
                        name="email"
                        (change)="validate()"
                        [(ngModel)]="addEmail"
                        class="form-control custom-form-control"
                        placeholder="Enter Email id"
                        required
                        #addAdminEmail="ngModel"
                        style="margin-bottom: 3%"
                      />
                      <p
                        class="warning"
                        *ngIf="!addAdminEmail.valid && addAdminEmail.touched"
                      >
                        Please Enter email
                      </p>
                      <p class="warning">{{ emailStatus }}</p>
                      <button
                        style="margin-top: 5%"
                        type="submit"
                        class="btn btn-primary"
                        (click)="addMentor()"
                        (click)="modalRef.hide()"
                        [disabled]="!f.valid || !isEmailValid"
                      >
                        Add Mentor
                      </button>
                    </form>
                  </div>
                  <div class="col-md-6">
                    <h3 style="text-align: center">Mentor list</h3>
                    <table class="table" style="margin-top: 9% !important">
                      <tr>
                        <th>Email</th>
                        <th>Status</th>
                      </tr>
                      <tr *ngFor="let data of MentorList">
                        <td>{{ data.email }}</td>
                        <td>
                          <input
                            class="tgl tgl-ios"
                            id="{{ data.email }}"
                            (click)="changeStatus(data.email, data.status)"
                            name="status"
                            type="checkbox"
                            [checked]="data.status"
                          />
                          <label class="tgl-btn" for="{{ data.email }}"></label>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="users-table">
        <div class="subadmin-table">
          <div class="example-container container mar-tp tab-size">
            <mat-table [dataSource]="dataSource" matSort>
              <ng-container matColumnDef="managerName">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >Manager Name and EmailId</mat-header-cell
                >
                <mat-cell *matCellDef="let element">
                  {{ element.managerName }}
                  <br />
                  {{ element.managerEmail }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="mentorName">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >Mentor Name and EmailId</mat-header-cell
                >
                <mat-cell *matCellDef="let element">
                  {{ element.mentorName }}
                  <br />
                  {{ element.mentorEmail }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="managerCompany">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >Manager Company</mat-header-cell
                >
                <mat-cell *matCellDef="let element">
                  {{ element.company }}</mat-cell
                >
              </ng-container>

              <ng-container matColumnDef="managerType">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >Manager Type</mat-header-cell
                >
                <mat-cell *matCellDef="let element">{{
                  element.isB2BB2C
                }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >Actions</mat-header-cell
                >
                <mat-cell *matCellDef="let element">
                  <i
                    class="fa fa-cog"
                    style="padding-left: 11%"
                    (click)="openActionModal(managerActions, element.requestId)"
                  ></i>
                  <ng-template #managerActions>
                    <div class="modal-header">
                      <h4 style="font-size: 22px" class="modal-title pull-left">
                        Manager Actions
                      </h4>
                      <button
                        type="button "
                        class="close pull-right"
                        aria-label="Close "
                        (click)="modalRef.hide()"
                      >
                        <span aria-hidden="true ">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-md-12" style="padding: 0">
                          <div class="col-md-12" style="text-align: center">
                            <span style="padding-right: 2%">
                              <mat-button-toggle
                                (click)="action('accept')"
                                [ngStyle]="changeAcceptColor()"
                                >Accept</mat-button-toggle
                              >
                            </span>
                            <span>
                              <mat-button-toggle
                                (click)="action('reject')"
                                [ngStyle]="changeRejectColor()"
                                >Reject</mat-button-toggle
                              >
                            </span>
                          </div>
                          <div class="col-md-12" style="padding-top: 2%">
                            <div class="email-preview-container">
                              <div
                                class="bottom-bar"
                                style="text-align: center"
                              >
                                <button
                                  class="btn"
                                  (click)="modalRef.hide()"
                                  (click)="onRequestAction(element.requestId)"
                                  [disabled]="valid"
                                  type="button"
                                >
                                  Update status
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >Approval Status</mat-header-cell
                >
                <mat-cell *matCellDef="let element">
                  {{ element.approvalStatus }}</mat-cell
                >
              </ng-container>

              <mat-header-row
                *matHeaderRowDef="displayedColumns"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedColumns"
              ></mat-row>
            </mat-table>
            <mat-paginator
              [length]="7"
              [pageSize]="7"
              [pageSizeOptions]="[7, 14, 28]"
            >
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
