<app-universal-screen *ngIf="showRolePopup"></app-universal-screen>
<div class="company-dash">
  <div class="container">
    <div>
      <div class="left-nav">
        <img class="gmiLogo" src="../../../assets/images/gmi_newlogo.png" />
        <img
          *ngIf="companyLogo"
          src="{{ companyLogo }}"
          class="clientCompanyLogo"
          width="auto"
          height="60px"
        />
      </div>
      <div class="right-nav">
        <ul>
          <li>
            <span class="roles-container" *ngIf="showPopup">
              <button (click)="toggleRolesPopup()">Roles</button>
            </span>
          </li>
          <li>
            <div class="dropdown">
              <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                <img
                  class="pl-13"
                  src="../../../assets/images/userprofile.png"
                />
              </a>
              <h6>{{ companyName }}</h6>
              <div class="dropdown-menu right-drop">
                <!-- <a class="dropdown-item" [routerLink]="['/company/company-dashboard/profile']" (click)="onClickProfile()">Profile</a> -->
                <a
                  class="dropdown-item"
                  [routerLink]="['/company']"
                  style="border: none"
                  (click)="onLogout()"
                  >Logout</a
                >
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
  <nav class="navbar navbar-default nav-color">
    <div class="container">
      <ul class="nav navbar-nav">
        <li
          routerLinkActive="active"
          routerLink="/company/company-dashboard"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <a>Dashboard</a>
        </li>
        <li
          *ngIf="access.surveyAccess"
          routerLinkActive="active"
          routerLink="survey-list"
        >
          <a>Surveys</a>
        </li>
        <li
          *ngIf="access.nudgesAccess"
          routerLinkActive="active"
          routerLink="nudges-configuration"
        >
          <a>Nudges</a>
        </li>
        <li
          *ngIf="access.reportsAccess"
          routerLinkActive="active"
          routerLink="reports"
        >
          <a>Reports</a>
        </li>
        <li
          *ngIf="access.actionPlanFeedbackAccess"
          routerLinkActive="active"
          routerLink="action-plan-feedback"
        >
          <a>Action Plan Review</a>
        </li>
        <li routerLinkActive="active" routerLink="request-portal">
          <a>Request Portal</a>
        </li>
        <li
          *ngIf="access.leadershipDashboardAccess"
          routerLinkActive="active"
          routerLink="leadership-dashboard"
        >
          <a>Leadership Analytics Dashboard</a>
        </li>

        <li
          routerLinkActive="active"
          *ngIf="access.gmcAccess"
          routerLink="gmc-criteria"
        >
          <a>GMC Criteria</a>
        </li>
      </ul>
    </div>
  </nav>
</div>

<div>
  <router-outlet></router-outlet>
</div>
