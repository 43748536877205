import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CompanyServices } from "../company.service";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { SelectionModel } from "@angular/cdk/collections";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import swal from "sweetalert2";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { DataService } from "../../data.service";
import { environment } from "../../../environments/environment";
import io from "socket.io-client";
import { log } from "util";
import { Validators, Editor, Toolbar } from "ngx-editor";
@Component({
  selector: "app-survey-email-mgmt",
  templateUrl: "./survey-email-mgmt.component.html",
  styleUrls: ["./survey-email-mgmt.component.css"],
})
export class SurveyEmailMgmtComponent implements OnInit {
  managers: any = new MatTableDataSource([]);
  parentData = "Hello from parent";
  selection = new SelectionModel<ManagerModel>(true, []);
  filters: any = {};
  displayedColumns: string[] = ["select", "name", "email"];
  displayedColumnsForEmails: string[] = [
    "name",
    "scheduleFor",
    "sentStatus",
    "createdAt",
    "action",
  ];
  toSend: string;
  scheduleEmails: any = new MatTableDataSource([]);
  toTypes: string[] = [
    "All Managers",
    "All participants",
    "Not Respondent",
    "Only Registered Managers",
    "Not Registered Managers",
    "Not Registered Participant",
    "Registered Participant",
    "Only Respondent",
  ];
  formData: FormModel = {
    name: "",
    bcc: "",
    to: "",
    subject: "",
    emailType: "",
    content: "",
  };
  private serverUrl = environment.apiURl;

  private socket: any = io(this.serverUrl, { reconnection: true });
  cc: string = "";
  formDataError = {};
  scheduleAt: any;
  placeholder: string;
  emailTypes: string[] = [];
  surveyId: string;
  startAt = new Date(Date.now());
  modalRef: BsModalRef;
  responseRate;
  isRTFSurvey: boolean = false;
  seCompanySurveyGroupId: number;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: false,
    height: "auto",
    minHeight: "500px",
    maxHeight: "auto",
    width: "auto",
    minWidth: "0",
    translate: "true",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "Enter text here...",
    defaultParagraphSeparator: "",
    defaultFontName: "Times New Roman",
    defaultFontSize: "",
    fonts: [
      { class: "arial", name: "Arial" },
      { class: "times-new-roman", name: "Times New Roman" },
      { class: "calibri", name: "Calibri" },
      { class: "comic-sans-ms", name: "Comic Sans MS" },
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
    uploadUrl: "v1/image",
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: "top",
    toolbarHiddenButtons: [["insertImage", "insertVideo", "fontName"]],
  };
  isAllHeadersIncluded: Boolean;
  public surveyType = ["pre", "post", "recertification", "pilot"];
  public showSurveyFeedback: Boolean = false;
  public showResponseMailInDrop: Boolean = false;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  showSasMailInDropDown: boolean;
  missingHeader: string;
  additionalHeader: string;
  isValidData: boolean;
  missingPlaceholders: any;
  additionalPlaceholders: any;
  isOrgReportGenerated: boolean = false;
  isManagerReportGenerated: boolean = false;
  isKeyPersonAvailable: boolean = false;
  isSpocOrHrAvailable: boolean = false;
  editor: Editor;
  toolbar: Toolbar = [
    ["bold", "italic"],
    ["underline", "strike"],
    ["code", "blockquote"],
    ["ordered_list", "bullet_list"],
    [{ heading: ["h1", "h2", "h3", "h4", "h5", "h6"] }],
    ["link", "image"],
    ["text_color", "background_color"],
    ["align_left", "align_center", "align_right", "align_justify"],
  ];
  magicLinkStatus: boolean = false;

  completedCount: number;
  updateStatus: boolean = false;
  totalEmailSentCount: any;
  emailSentCount: number = 0;
  router: any;
  public smsTemplates: any[] = [];
  constructor(
    private companyService: CompanyServices,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private dataService: DataService
  ) {}

  ngOnInit() {
    // this.editorConfig.toolbarHiddenButtons.push(["fontName"]);
    this.editor = new Editor();
    this.filters["all"] = true;
    let data = {
      surveyId: this.route.snapshot.params["id"],
      filters: this.filters,
    };
    this.surveyId = this.route.snapshot.params["id"];
    if (data.surveyId) {
      this.getScheduleEmails();
      this.getSurveyDetails(data.surveyId);
    }
    let token = localStorage.getItem("token");
    let decodedToken = this.dataService.getDecodedAccessToken(token);
    this.getCompanyProfile(decodedToken._id);
    this.socket.on("SENT_EMAIL", (data) => {
      if (!data.status) {
        this.totalEmailSentCount = data.totalCount;
        this.emailSentCount = data.count;
        this.completedCount =
          (this.emailSentCount / this.totalEmailSentCount) * 100;
      } else {
        this.updateStatus = true;
        this.totalEmailSentCount = 0;
        this.emailSentCount = 0;
        this.completedCount = 0;
      }
    });
  }
  getCompanyProfile(id) {
    this.companyService
      .getCompanyProfile({ companyId: id })
      .subscribe((response) => {
        if (response.status) {
          this.responseRate =
            response.data.companyType == "B2B(Hi-Tech)" ||
            response.data.companyType == "GPMS"
              ? "response_rate_mail_to_hr_new"
              : "tfs_response_rate_b2b";
          this.showResponseMailInDrop =
            response.data.companySettings.sendResponseRateEmail;
          this.showSasMailInDropDown = response.data.companyType === "GPMS";

          let tempManagerReport = response.surveyGroup.find(
            (item: any) => item._id == this.surveyId
          );
          const orgReportLink = tempManagerReport.orgReportLink;
          const KeyPerson = tempManagerReport.keyPerson;
          const hrDetails = tempManagerReport.contactDetails;
          if (tempManagerReport && tempManagerReport.managerReportsSent) {
            this.isManagerReportGenerated =
              tempManagerReport.managerReportsSent;
          }
          if (
            orgReportLink &&
            typeof orgReportLink == "string" &&
            typeof orgReportLink !== null
          ) {
            this.isOrgReportGenerated = true;
          }
          if (KeyPerson && KeyPerson.length > 0) {
            this.isKeyPersonAvailable = true;
          }
          if (hrDetails) {
            this.isSpocOrHrAvailable = true;
          }
        } else {
          swal.fire({
            title: "Company Profile",
            text: response.message,
            icon: "error",
            showConfirmButton: false,
            timer: 2500,
          });
        }
      });
  }

  getSurveyDetails(id) {
    let data = {
      surveyId: id,
    };
    this.companyService.getSurveyById(data).subscribe((res) => {
      if (res.status) {
        res.data.length > 0
          ? (this.showSurveyFeedback = this.surveyType.includes(
              res.data[0].surveyType.toLowerCase()
            ))
          : null;
        res.data.length > 0
          ? ((this.isRTFSurvey = res.data[0].surveyType == "RealTimeFeedback"),
            (this.seCompanySurveyGroupId = res.data[0].seCompanySurveyGroupId))
          : false;
        this.magicLinkStatus =
          res.data.length > 0 ? res.data[0].magicLinkStatus : false;
      } else {
        swal.fire({
          title: "Survey Status",
          text: "Something went wrong!",
          icon: "error",
          showConfirmButton: true,
        });
      }
    });
  }
  getScheduleEmails() {
    let data = {
      surveyId: this.surveyId,
    };
    this.companyService.getScheduleEmailByCompanyId(data).subscribe((res) => {
      if (res.status) {
        let filterMails = res.data.filter((mail) => mail.active == true);

        this.scheduleEmails = new MatTableDataSource(filterMails);
        this.scheduleEmails.sort = this.sort;
        this.scheduleEmails.paginator = this.paginator;
      } else {
        swal.fire({
          title: "Survey Status",
          text: "Something went wrong!",
          icon: "error",
          showConfirmButton: true,
        });
      }
    });
  }

  changeExistingManagerFilters(evt) {
    let value = evt.target.value;
    let filters = {};
    if (
      typeof this.filters.batchName !== "undefined" &&
      this.filters.batchName !== ""
    ) {
      filters["batchName"] = this.filters.batchName;
    }
    switch (value) {
      case "registered":
        filters["registered"] = true;
        break;
      case "notRegistered":
        filters["notRegistered"] = true;
        break;
    }
    this.filters = filters;
    // this.getManagersBySurvey({ surveyId: this.surveyId, filters: filters });
  }
  getSMSTemplateDetails() {
    this.companyService.smstemplates().subscribe((res) => {
      if (res.status) {
        this.smsTemplates = res.data;
      } else {
        swal.fire({
          title: "Error",
          text: "Something went wrong!",
          icon: "error",
          showConfirmButton: true,
        });
      }
    });
  }
  redirectToSurveyPage() {
    this.modalRef.hide();
    this.modalRef = null;
    this.emailSentCount = 0;
    this.totalEmailSentCount = 0;
    this.completedCount = 0;
    this.updateStatus = false;
    this.router.navigate(["/company/company-dashboard/survey-list"]);
  }
  applyFilter(filterValue: string) {
    this.managers.filter = filterValue.trim().toLowerCase();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.managers.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.managers.data.forEach((row) => this.selection.select(row));
  }

  checkboxLabel(row?: ManagerModel): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
      row.name + 1
    }`;
  }

  handleForm(event) {
    this.resetAddEmail()
    if (event.target.name === "emailType") {
      let data = {
        type: event.target.value,
      };
      this.getEmailContent(data);
    } else if (event.target.name === "cc") {
      this.cc = event.target.value;
    }
    this.formData[event.target.name] = event.target.value;
  }

  getEmailContent(data) {
    this.companyService.getEmail(data).subscribe((res) => {
      if (res.status) {
        this.formData.bcc = res.data.bcc;
        this.formData.content = res.data.content;
        this.formData.subject = res.data.subject;
        this.toTypes = res.data.recipients;
        this.placeholder = res.data.placeholder.toString();
      } else {
        swal.fire({
          title: "Email Template",
          text: "Something went wrong!",
          icon: "error",
          showConfirmButton: true,
        });
      }
    });
  }

  submitData(template) {
    if (!this.validateData()) {
      swal.fire({
        title: "Form Validation",
        text: "Please fill the required field",
        icon: "error",
        showConfirmButton: true,
        timer: 250000,
      });
      // this.modalRef.hide()
    } else if (this.cc.trim() !== "" && !this.emailValidate(this.cc)) {
      swal.fire({
        title: "Form Validation",
        text: "Please check the email format of CC",
        icon: "error",
        showConfirmButton: true,
        timer: 250000,
      });
    } else {
      this.modalRef = this.modalService.show(
        template
        // Object.assign({}, { class: "medium-modal" })
      );
      let cleanHtml = this.formData.content.replace(/&#10;/g, " ");

      let data = {
        emailType: this.formData.emailType,
        name: this.formData.name,
        to: this.formData.to == "Others" ? this.toSend : this.formData.to,
        subject: this.formData.subject,
        content: cleanHtml,
        bcc: this.formData.bcc,
        cc: this.cc,
        startDate:
          this.scheduleAt && this.scheduleAt ? this.scheduleAt[0] : undefined,
        endDate:
          this.scheduleAt && this.scheduleAt.length > 1
            ? this.scheduleAt[1]
            : undefined,
        type: this.scheduleAt ? "schedule" : "now",
        managers: this.selection.selected,
        companySurveyId: this.surveyId,
        seCompanySurveyGroupId: this.seCompanySurveyGroupId,
      };
      // return;
      this.companyService.createScheduleMail(data).subscribe((res) => {
        if (res.status) {
          this.formData = {
            name: "",
            bcc: "",
            to: "",
            subject: "",
            emailType: "",
            content: "",
          };

          swal
            .fire({
              title: "Email Management",
              text: res.message,
              icon: "success",
              showConfirmButton: true,
              timer: 250000,
            })
            .then(() => {
              this.resetAddEmail();

              this.modalService.hide();
            });
          this.getScheduleEmails();
        } else {
          swal.fire({
            title: "Survey Status",
            text: "Something went wrong!",
            icon: "error",
            showConfirmButton: true,
          });
        }
        this.modalRef.hide();
        this.modalRef = null;
      });
    }
    // if(this.validateData()){
    //   this.modalRef = this.modalService.show(
    //     template
    //     // Object.assign({}, { class: "medium-modal" })
    //   );
    // }

    // this.companyService.addModal(this.modalRef);
  }

  handleEditorChange(content: string) {
    this.findExtraPlaceholder(this.placeholder, content);
  }

  resetAddEmail() {
    this.missingPlaceholders = [];
    this.additionalPlaceholders = [];

    this.missingHeader = ``;
    this.additionalHeader = ``;
  }

  findExtraPlaceholder(
    template: string,
    placeholder: any,
    headersToExclude = ["%ICONURL%"]
  ) {
    const regex = /%(\w+)%/g;
    const headerContent = template
      .match(regex)
      .filter((header) => !headersToExclude.includes(header));
    const placeHolderData = placeholder
      .match(regex)
      .filter((header) => header !== headersToExclude);
    this.isAllHeadersIncluded = headerContent.every((item) =>
      placeHolderData.includes(item)
    );

    this.missingPlaceholders = headerContent.filter(
      (item) => !placeHolderData.includes(item)
    );
    this.additionalPlaceholders = placeHolderData.filter(
      (item) =>
        !headerContent.includes(item) && !headersToExclude.includes(item)
    );

    if (
      this.missingPlaceholders.length > 0 &&
      this.additionalPlaceholders.length > 0
    ) {
      this.missingHeader = `The following placeholders are missing in the Email Template: ${this.missingPlaceholders}`;
      this.additionalHeader = `The Email Template has the following additional placeholders: ${this.additionalPlaceholders}`;
      this.isValidData = false;
    } else if (this.missingPlaceholders.length > 0) {
      this.missingHeader = `The following placeholders are missing in the Email Template: ${this.missingPlaceholders}`;
      this.isValidData = false;
    } else if (this.additionalPlaceholders.length > 0) {
      this.additionalHeader = `The Email Template has the following additional placeholders: ${this.additionalPlaceholders}`;
      this.isValidData = true;
    } else {
      this.isValidData = true;
    }
  }

  emailValidate(email) {
    let valid =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      );
    return valid;
  }

  closeAddEmailModal() {
    this.modalRef.hide();
    this.resetAddEmail();
  }

  validateData() {
    for (let key in this.formData) {
      if (this.formData[key].trim() == "") {
        return false;
      }
    }

    if (
      this.formData["emailType"] == "tfs_response_rate_b2b" &&
      !this.scheduleAt
    ) {
      return false;
    }

    if (this.formData["emailType"] == "respoondent_mail_with_magic_link") {
      let headersToExclude = ["%EMPLOYEE_NAME%", "%END_DATE%", "%ICONURL%"];
      this.findExtraPlaceholder(
        this.placeholder,
        this.formData.content,
        headersToExclude
      );
      if (!this.isValidData) {
        // swal.fire({
        //   title: "Form Validation",
        //   text: "Please use required placeholders",
        //   icon: "error",
        //   showConfirmButton: true,
        //   timer: 250000,
        // });
        return false;
      }
    }

    return true;
  }
  openEmailTemplate(template: TemplateRef<any>) {
    this.resetAddEmail();
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: "medium-modal" })
    );
    this.companyService.addModal(this.modalRef);
  }
  deleteEmail(emailId) {
    // console.log(emailId)
    swal
      .fire({
        title: "Delete the email template",
        showCancelButton: true,
        showConfirmButton: true,
        icon: "warning",
        allowOutsideClick: false,
      })
      .then((isConfirm) => {
        let data = {
          emailId,
        };
        this.companyService
          .updateScheduleEmailByCompanyId(data)
          .subscribe((res) => {
            if (res.status) {
              this.getScheduleEmails();
            }
          });
      });
  }
  onContentChange(content: string) {
    this.formData.content = content;
  }
  ngOnDestroy(): void {
    this.editor.destroy();
  }
}

export interface ManagerModel {
  name: string;
  email: string;
  noOfParticipants: Array<[]>;
}

export interface EmailModel {
  name: string;
  scheduleFor: string;
  sentStatus: string;
  createdAt: string;
  action: string;
}
export interface FormModel {
  name: string;
  bcc: string;
  cc?: string;
  to: string;
  subject: string;
  emailType: string;
  content: string;
  scheduleAt?: string;
}
