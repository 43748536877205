<div class="container">
  <div class="back-container">
    <a href="javascript:void(0)" [routerLink]="['/company/company-dashboard/']" class="create-new clr-grey"
      style="padding: 10px 0">
      <em>
        <i class="fa fa-arrow-left" aria-hidden="true" style="margin-right: 5px"></i> </em>Back</a>
  </div>

  <button *ngIf="access.launchButtonAccess" class="btn btn-gmi-primary gmi-primary-btn"
    [routerLink]="['/company/company-dashboard/launch-survey']">
    Launch Survey
  </button>
  <div *ngIf="surveyLists && surveyLists.length > 0; else defaultTemplate" class="survey-list-container">
    <div class="row">
      <div class="col-md-6" *ngFor="let survey of surveyLists">
        <div class="survey-card">
          <div class="survey-info">
            <div class="info-header">
              <p class="survey-name">
                {{
                survey.surveyName ? survey.surveyName : "Great Manager Survey"
                }}
              </p>
              <p class="action-click" matTooltip="Update Survey Name" (click)="
                  openUpdateSurveyNameModal(
                    survey._id,
                    survey.surveyName,
                    updateSurveyNameModal
                  )
                ">
                <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
              </p>
            </div>
            <div class="info-header">
              <p class="small-date">Created At: {{ survey.createdAt }}</p>
              <p class="status-text">{{ survey.text }}</p>
            </div>

            <p class="bold-text">Total surveys: {{ survey.surveyLength }}</p>
            <p class="bold-text">Survey Type : {{ survey.surveyType }}</p>

            <div class="date-container">
              <p class="small-date">Start Date: {{ survey.startDate }}</p>
              <p class="small-date">End Date: {{ survey.endDate }}</p>
            </div>
          </div>
          <p class="bold-text">Actions</p>
          <div class="action">
            <p (click)="redirectToPage('edit', survey._id)" class="action-click" matTooltip="Survey Edit">
              <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
            </p>
            <p (click)="redirectToPage('email', survey._id)" class="action-click" matTooltip="Email Management">
              <i class="fa fa-envelope" aria-hidden="true"></i>
            </p>
            <p (click)="redirectToPage('whatsapp', survey._id)" class="action-click" matTooltip="Whatsapp Management">
              <i class="fa fa-whatsapp" aria-hidden="true"></i>
            </p>
            <p (click)="redirectToPage('sms', survey._id)" class="action-click" matTooltip="SMS Management">
              <i class="fa fa-comment" aria-hidden="true"></i>
            </p>
            <p class="action-click" matTooltip="{{ this.surveyToggleText[survey._id] }}">
              <mat-slide-toggle [(ngModel)]="surveyToggle[survey._id]"
                (click)="redirectToPage('score', survey._id)"></mat-slide-toggle>

              <!-- <input
                class="tgl tgl-ios"
                (click)="redirectToPage('score', survey._id)"
                id="{{ surveyToggle[survey._id] }}"
                name="{{ surveyToggle[survey._id] }}"
                type="checkbox"
                [checked]="surveyToggle[survey._id]"
              />
              <label
                class="tgl-btn"
                for="{{ surveyToggle[survey._id] }}"
              ></label> -->
            </p>
            <p matTooltip="Leaderboard Report" class="action-click" (click)="redirectToPage('leaderboard', survey._id)">
              <i class="fa fa-bar-chart" aria-hidden="true"></i>
            </p>
            <p *ngIf="access.generateOrgReportAccess && survey.text == 'Closed!'"
              (click)="generateOrgReport(survey, viewOrgReport)" class="report-button">
              {{
              survey.orgReport &&
              survey.orgReport != null &&
              survey.orgReport !== ""
              ? "View GPMS org report"
              : "Generate the org report for GPMS"
              }}
            </p>
            <p class="action-click" matTooltip="Enable/Disable RTF mail" *ngIf="
                survey.surveyType == 'RealTimeFeedback' &&
                isRTFMailEnabled(survey)
              ">
              <mat-slide-toggle [(ngModel)]="rtfMailToggle[survey._id]" (click)="changeRTFMailStatus(survey._id)"
                [ngClass]="{ 'toggle-two': true }"></mat-slide-toggle>
            </p>
            <!-- <button
              class="btn-left gmi-primary-btn"
              (click)="
                openAssignNudge(
                  survey.surveyOrder,
                  survey.surveyType,
                  survey,
                  nudge
                )
              "
            >
              Assign Nudge
            </button> -->
            <!-- <select class="nudge-dropdown" #companyNudge (change)="updateCompanyNudge(survey.surveyOrder,survey.surveyType,companyNudge.value)" >
              <option disabled selected value> -- select a nudge goal -- </option>
              <option [value]="goal.goalName" *ngFor="let goal of nudgesGoalOption.data">{{goal.goalName}}</option>
            </select> -->
            <!-- <p
              matTooltip="Generating the org report"
              *ngIf="survey.mappedBenchmark.length > 0"
              matTooltip="Survey is already mapped with {{
                survey.mappedBenchmark.length
              }} benchmarks"
            >
              Mapped
            </p> -->
          </div>
          <p class="bold-text">Reports - Old</p>
          <div class="reports">
            <p class="report-button" (click)="report(survey._id, 'responseRate')">
              Survey Response Rate
            </p>
            <p class="report-button" (click)="report(survey._id, 'surveyScoreSheet')">
              Survey Scoresheet
            </p>
            <p class="report-button" (click)="report(survey._id, 'pis')">PIS</p>
            <p class="report-button" (click)="masterReport(survey._id)">
              Master Report
            </p>
            <!-- Dropdown with report -->
            <div class="dropdown" *ngIf="access.gpmsReportsAccess">
              <button class="report-button" type="button" data-toggle="dropdown">
                GPMS report <span class="caret"></span>
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a (click)="report(survey._id, 'gpms')">Manager Report</a>
                </li>
                <!-- <li>
                  <a (click)="generateOrgReport(survey, viewOrgReport)"
                    >Generate org report</a
                  >
                </li> -->
                <li>
                  <a *ngIf="survey.orgReport" (click)="orgReport(survey.orgReport, 'org')">Org report</a>
                </li>
                <li>
                  <a *ngIf="survey.orgReport" (click)="orgReport(survey.summReportLink, 'summ')">Summary Org report</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="reports">
            <div class="dropdown">
              <button class="report-button" type="button" data-toggle="dropdown">
                Org Report <span class="caret"></span>
              </button>
              <ul class="dropdown-menu">
                <li *ngIf="survey?.reportLinks?.orgReport?.link">
                  <a [href]="getUrl(survey?.reportLinks?.orgReport?.link)">
                    Existing One (Date:
                    {{
                    survey?.reportLinks?.orgReport?.createdAt
                    | date : "dd MMMM yy"
                    }})
                  </a>
                </li>
                <li *ngIf="
                    survey?.reportLinks?.orgReport?.link == undefined ||
                    !isGreaterThanSurveyEndDate(
                      survey?.reportLinks?.orgReport?.createdAt,
                      survey.endDate
                    )
                  ">
                  <a (click)="getOrgReport(survey._id)">Generate New Report</a>
                </li>
              </ul>
            </div>
            <p class="report-button" (click)="report(survey._id, 'surveyComments')">
              Survey Comment Report
            </p>
            <p class="report-button" (click)="getTwoByTwoData(survey._id, twoByTwoModal)">
              See 2x2 Data
            </p>
            <p class="report-button" (click)="auditReport(survey._id)">
              Audit Report (Comment)
            </p>
          </div>
          <p class="bold-text">Reports - New</p>
          <div class="reports">
            <div class="dropdown">
              <button class="report-button" type="button" data-toggle="dropdown">
                Org Report<span class="caret"></span>
              </button>
              <ul class="dropdown-menu">
                <li *ngIf="survey?.reportLinks?.orgReportTwo?.link">
                  <a [href]="getUrl(survey?.reportLinks?.orgReportTwo?.link)">
                    Existing One (Date:
                    {{
                    survey?.reportLinks?.orgReportTwo?.createdAt
                    | date : "MMM d, y, h:mm:ss a"
                    }})
                  </a>
                </li>
                <li *ngIf="
                    survey?.reportLinks?.orgReportTwo?.link == undefined ||
                    !isGreaterThanSurveyEndDate(
                      survey?.reportLinks?.orgReportTwo?.createdAt,
                      survey.endDate
                    )
                  ">
                  <a (click)="
                      getOrgReportNew(survey._id, survey.seCompanySurveyGroupId)
                    ">Generate New Report</a>
                </li>
              </ul>
            </div>
            <div class="dropdown">
              <button class="report-button" type="button" data-toggle="dropdown">
                Survey Response Rate<span class="caret"></span>
              </button>
              <ul class="dropdown-menu">
                <li *ngIf="survey?.reportLinks?.surveyResponseRateLink?.link">
                  <a [href]="
                      getUrl(survey?.reportLinks?.surveyResponseRateLink?.link)
                    ">
                    Existing One (Date:
                    {{
                    survey?.reportLinks?.surveyResponseRateLink?.createdAt
                    | date : "MMM d, y, h:mm:ss a"
                    }})
                  </a>
                </li>
                <li *ngIf="
                    survey?.reportLinks?.surveyResponseRateLink?.link ==
                      undefined ||
                    !isGreaterThanSurveyEndDate(
                      survey?.reportLinks?.surveyResponseRateLink?.createdAt,
                      survey.endDate
                    )
                  ">
                  <a (click)="
                      getSurveyResponseRateV2(
                        survey._id,
                        survey.seCompanySurveyGroupId
                      )
                    ">Generate New Report</a>
                </li>
              </ul>
            </div>
            <div class="dropdown">
              <button class="report-button" type="button" data-toggle="dropdown">
                Master Report<span class="caret"></span>
              </button>
              <ul class="dropdown-menu">
                <li *ngIf="
                    survey?.reportLinks?.masterSurveyResponseRateLink?.link
                  ">
                  <a [href]="
                      getUrl(
                        survey?.reportLinks?.masterSurveyResponseRateLink?.link
                      )
                    ">
                    Existing One (Date:
                    {{
                    survey?.reportLinks?.masterSurveyResponseRateLink
                    ?.createdAt | date : "MMM d, y, h:mm:ss a"
                    }})
                  </a>
                </li>
                <li *ngIf="
                    survey?.reportLinks?.masterSurveyResponseRateLink?.link ==
                      undefined ||
                    !isGreaterThanSurveyEndDate(
                      survey?.reportLinks?.masterSurveyResponseRateLink
                        ?.createdAt,
                      survey.endDate
                    )
                  ">
                  <a (click)="
                      getMasterSurveyResponseRateV2(
                        survey._id,
                        survey.seCompanySurveyGroupId
                      )
                    ">Generate New
                  </a>
                </li>
              </ul>
            </div>
            <div class="dropdown">
              <button class="report-button" type="button" data-toggle="dropdown">
                Survey Scoresheet<span class="caret"></span>
              </button>
              <ul class="dropdown-menu">
                <li *ngIf="survey?.reportLinks?.surveyScoreSheetLink?.link">
                  <a [href]="
                      getUrl(survey?.reportLinks?.surveyScoreSheetLink?.link)
                    ">
                    Existing One (Date:
                    {{
                    survey?.reportLinks?.surveyScoreSheetLink?.createdAt
                    | date : "MMM d, y, h:mm:ss a"
                    }})
                  </a>
                </li>
                <li *ngIf="
                    survey?.reportLinks?.surveyScoreSheetLink?.link ==
                      undefined ||
                    !isGreaterThanSurveyEndDate(
                      survey?.reportLinks?.surveyScoreSheetLink?.createdAt,
                      survey.endDate
                    )
                  ">
                  <a (click)="
                      getSurveyScoreSheetV2(
                        survey._id,
                        survey.seCompanySurveyGroupId
                      )
                    ">Generate New</a>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="survey.isSurveyLaunchFailed">
            <p class="bold-text">Real Time Feedback</p>
            <p class="rtf-button" (click)="report(survey._id, 'orgReport')">
              Resume Survey Launch
            </p>
            <p class="rtf">REAL TIME FEEDBACK FAILS TO LAUNCH</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-container">
    <div class="footer">
      <div class="footer-text">
        <div class="footer-text-left">
          <p></p>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #defaultTemplate>
  <p>No Surveys is created</p>
</ng-template>
<ng-template #mappingTemplate>
  <div class="modal-header">
    <h4 style="font-size: 22px" class="modal-title pull-left">
      Generate Org report
    </h4>
    <button type="button " class="close pull-right" aria-label="Close " (click)="modalRef.hide()">
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="table-container m-10">
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target['value'])" placeholder="Search benchmark" />
      </mat-form-field>

      <table mat-table [dataSource]="benchmarks" matSort>
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="benchmarkName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Benchmark name
          </th>
          <td mat-cell *matCellDef="let benchmark">
            {{ benchmark.benchmarkName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Created At</th>
          <td mat-cell *matCellDef="let benchmark">
            {{ benchmark.createdAt }}
          </td>
        </ng-container>
        <!-- <ng-container matColumnDef="noOfParticipants">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Participants</th>
          <td mat-cell *matCellDef="let manager">
            {{ manager.noOfParticipants.length }}
          </td>
        </ng-container> -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons></mat-paginator>
    </div>
    <div class="btn-container">
      <button class="btn gmi-primary-btn" (click)="mapBenchmark()">
        Map Benchmark and Generate Report
      </button>
    </div>
  </div>
</ng-template>
<ng-template #nudge>
  <div class="modal-header">
    <h4 style="font-size: 22px" class="modal-title pull-left">
      Assign Nudge Goal
    </h4>
    <button type="button " class="close pull-right" aria-label="Close " (click)="modalRef.hide()">
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <app-assign-nudges [surveyOrderDetails]="surveyOrderDetails"></app-assign-nudges>
</ng-template>
<ng-template #updateSurveyNameModal>
  <div class="modal-header">
    <h4 style="font-size: 22px" class="modal-title pull-left">
      Update Survey Name
    </h4>
    <button type="button " class="close pull-right" aria-label="Close " (click)="modalRef.hide()">
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <input maxlength="24" [(ngModel)]="updateSurveyName" class="form-control" style="margin-bottom: 15px"
      placeholder="Enter Survey Name" />

    <div class="btn-container">
      <button type="submit" class="btn gmi-primary-btn" (click)="surveyNameUpdate()">
        Update
      </button>
    </div>
  </div>
</ng-template>
<ng-template #twoByTwoModal>
  <div class="modal-header">
    <h4 style="font-size: 22px" class="modal-title pull-left">2 x 2 Matrix</h4>
    <button type="button " class="close pull-right" aria-label="Close " (click)="modalRef.hide()">
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="centerDiv">
      <div class="rotate">
        <h4>Competence</h4>
      </div>
      <div class="flex">
        <div class="backgroundq1">
          <p>Q2</p>
          <h6>Task Master</h6>
          <h1>{{ q2 ? q2 : 0 }}%</h1>
        </div>
        <div class="backgroundq2">
          <p>Q1</p>
          <h6>Great People Manager</h6>
          <h1>{{ q1 ? q1 : 0 }}%</h1>
        </div>
      </div>
      <div class="flex">
        <div class="backgroundq3">
          <p>Q3</p>
          <h6>Aspiring Manager</h6>
          <h1>{{ q3 ? q3 : 0 }}%</h1>
        </div>
        <div class="backgroundq4">
          <p>Q4</p>
          <h6>Avuncular Manager</h6>
          <h1>{{ q4 ? q4 : 0 }}%</h1>
        </div>
      </div>
      <div class="xAxisLabel">
        <h4>Care for Individual</h4>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #viewOrgReport>
  <div class="modal-header">
    <h4 class="modal-title pull-left">GPMS organisation report link</h4>
    <button type="button " class="close pull-right" aria-label="Close " (click)="modalRef.hide()">
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h3>Organisation report for this survey group is already generated</h3>
    <h4>Company Name: {{ companyName }}</h4>
    <p>
      Report link: <a>{{ gpmsOrgReportLink }}</a>
    </p>
    Note:
    <ul>
      <li>
        The New report link url is changed if you see old url please use this
        url
        <a>https://app.greatmanagerinstitute.com/reports/org-report/ </a>after
        this add last ids as it is.
      </li>
      <li>
        Example of final link is
        https://app.greatmanagerinstitute.com/reports/org-report/1234/123
      </li>
    </ul>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modalRef.hide()">
      Close
    </button>
  </div>
</ng-template>
<ng-template #viewReport>
  <div class="modal-header">
    <h4 class="modal-title pull-left">GPMS organisation report link</h4>
    <button type="button " class="close pull-right" aria-label="Close " (click)="modalRef.hide()">
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h3>Organisation report generated sucessfully</h3>
    <p>
      Report link: <a>{{ gpmsOrgReportLink }}</a>
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modalRef.hide()">
      Close
    </button>
  </div>
</ng-template>