<div class="popoverColor">
  <div class="spaceBtwn">
    <input
      type="text"
      placeholder="Search.."
      class="searchBar"
      (keyup)="applyFilter($event.target['value'])"
    />
    <button
      class="gmi-primary-btn mr-5 text-right"
      (click)="showPopoverModal(addEditPopover, {}, false, {})"
    >
      Add New
    </button>
  </div>
  <div class="overflowContainer">
    <table
      class="mat-elevation-z8 demo-table"
      mat-table
      [dataSource]="dataSource"
    >
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef>Title</th>
        <td mat-cell *matCellDef="let element">{{ element.title }}</td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let element">{{ element.description }}</td>
      </ng-container>

      <ng-container matColumnDef="characterUrl">
        <th mat-header-cell *matHeaderCellDef>Character Url</th>
        <td mat-cell *matCellDef="let element">{{ element.characterUrl }}</td>
      </ng-container>

      <ng-container matColumnDef="popoverColour">
        <th mat-header-cell *matHeaderCellDef>Colour</th>
        <td mat-cell *matCellDef="let element">{{ element.popoverColour }}</td>
      </ng-container>

      <ng-container matColumnDef="popoverLocation">
        <th mat-header-cell *matHeaderCellDef>Location</th>
        <td mat-cell *matCellDef="let element">
          {{ element.popoverLocation }}
        </td>
      </ng-container>

      <ng-container matColumnDef="reason">
        <th mat-header-cell *matHeaderCellDef>Reason</th>
        <td mat-cell *matCellDef="let element">{{ element.reason }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let element; let i = index">
          <div style="display: inline-flex">
            <i
              class="fa fa-edit"
              style="margin-right: 15px"
              title="Edit"
              (click)="showPopoverModal(addEditPopover, element, true, i)"
            ></i>
            <i
              class="fa fa-trash"
              style="margin-right: 15px"
              (click)="deletePopoverData(element, i)"
              title="Delete"
            ></i>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <div
      class="text-center emptyContainer"
      *ngIf="dataSource.data.length === 0"
    >
      Please Add Popover Data:
    </div>
  </div>

  <ng-template #addEditPopover>
    <div class="modal-header">
      <h4 style="font-size: 22px" class="modal-title pull-left">
        Popover Form:
      </h4>
      <button
        type="button "
        class="close pull-right"
        aria-label="Close "
        (click)="closeModal()"
      >
        <span aria-hidden="true ">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form class="form" [formGroup]="popoverDataForm">
        <label for="title">Title:<small class="text-danger">*</small> </label>
        <input
          formControlName="title"
          required
          type="text"
          placeholder="Enter title"
        />
        <div
          *ngIf="
            popoverDataForm.get('title').invalid &&
            popoverDataForm.get('title').touched
          "
        ></div>
        <label for="description"
          >Description:<small class="text-danger">*</small>
        </label>
        <input
          formControlName="description"
          required
          type="text"
          placeholder="Enter description"
        />

        <label for="characterUrl"
          >Character Url:<small class="text-danger">*</small>
        </label>
        <input
          required
          formControlName="characterUrl"
          type="text"
          placeholder="Enter characterUrl."
        />

        <label for="popoverColour"
          >Colour:<small class="text-danger">*</small>
        </label>
        <input
          required
          formControlName="popoverColour"
          type="text"
          placeholder="Enter popoverColour. Ex: #ffffff"
        />

        <label for="popoverLocation"
          >Location:<small class="text-danger">*</small>
        </label>
        <input
          formControlName="popoverLocation"
          type="text"
          placeholder="Enter Location."
        />

        <label for="reason">Reason:<small class="text-danger">*</small> </label>
        <input
          required
          formControlName="reason"
          type="text"
          placeholder="Enter reason"
        />
        <br />
        <button
          *ngIf="!isEdit"
          class="p-2"
          type="button"
          (click)="addNewData()"
          [disabled]="popoverDataForm.invalid"
        >
          Add Popover
        </button>
        <button
          *ngIf="isEdit"
          type="button"
          (click)="editExistingData()"
          [disabled]="popoverDataForm.invalid"
        >
          Update Popover
        </button>
        <br />
        <p
          class="text-danger text-center m-2"
          *ngIf="popoverDataForm.touched && popoverDataForm.invalid"
        >
          Please Fill All Mandatory Fields
        </p>
      </form>
    </div>
  </ng-template>
</div>
