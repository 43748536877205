import { Component, OnInit } from '@angular/core';
import { jwtDecode } from 'jwt-decode';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css'],
})
export class PageNotFoundComponent implements OnInit {
  redirectUrl: String = '/';

  constructor() {}

  ngOnInit() {
    //Identify login screen
    if (localStorage.getItem('token') != null) {
      const tokenPayload: any = jwtDecode(localStorage.getItem('token'));
      if (tokenPayload) {
        switch (tokenPayload.role) {
          case 'Admin':
            this.redirectUrl = '/admin';
            break;
          case 'company':
            this.redirectUrl = '/company';
            break;
        }
      }
    }
    console.log(this.redirectUrl, 'redirectUrl');

    //Clear login data
    localStorage.clear();
  }
}
