import { Component, OnInit } from "@angular/core";
import { AdminServices } from "../admin.service";

@Component({
  selector: "app-survey-experience-management",
  templateUrl: "./survey-experience-management.component.html",
  styleUrls: ["./survey-experience-management.component.css"],
})
export class SurveyExperienceManagementComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
