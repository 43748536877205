import { Component, OnInit, TemplateRef } from "@angular/core";
import { CompanyServices } from "../company.service";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { BsModalService } from "ngx-bootstrap/modal";
import swal from "sweetalert2";
import io from "socket.io-client";
import { environment } from "src/environments/environment";
import { SocketService } from "src/app/services/socket.service";
@Component({
  selector: "app-assign-nudges",
  templateUrl: "./assign-nudges.component.html",
  styleUrls: ["./assign-nudges.component.css"],
})
export class AssignNudgesComponent implements OnInit {
  selectedManagers: any[] = [];
  modalRef: BsModalRef;
  private serverUrl = environment.apiURl;
  private socket: any = io(this.serverUrl, { reconnection: true });
  totalCount: number = 0;
  count: number = 0;
  completedCount: number = 0;
  updateStatus: boolean = false;
  loading: boolean = false;
  statusText: string = "";

  constructor(
    private companyService: CompanyServices,
    private modalService: BsModalService,
    public socketService: SocketService
  ) {}

  ngOnInit(): void {
    this.getSelectedManagers();
    this.getAssignedStatus();
  }

  getSelectedManagers() {
    this.companyService.selectedmanagersForPackages.subscribe((response) => {
      this.selectedManagers = response;
    });
  }

  openSendMailModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      class: "my-modal",
      backdrop: "static",
      keyboard: false,
    });
  }

  assignNudges(template) {
    const data = {
      managerEmailIds: this.selectedManagers.map((manager) => manager.email),
    };

    this.companyService.assignNudgesToManagers(data).subscribe((response) => {
      swal.fire({
        title: "Assign Nudges Status",
        text: response.message,
        icon: response.status ? "success" : "error",
        showConfirmButton: false,
        timer: 2500,
      });
      response.status && this.modalService._hideModal(1);
    });
    this.loading = false;
    this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
    });
  }

  getAssignedStatus() {
    this.socketService.on("ASSIGN_NUDGES_COUNTER", (data) => {
      if (!data.status) {
        this.totalCount = data.totalCount;
        this.count = data.count;
        this.completedCount = (data.count / data.totalCount) * 100;
      } else {
        this.updateStatus = true;
        this.modalRef.hide();
      }
      this.statusText = data.statusText;
    });
  }
}
