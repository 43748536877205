import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
// import 'rxjs/add/observable/of';
import { CompanyServices } from '../company.service';
import swal from 'sweetalert2';
@Component({
  selector: 'app-approve-data',
  templateUrl: './approve-data.component.html',
  styleUrls: ['./approve-data.component.css'],
})
export class ApproveDataComponent implements OnInit {
  constructor(
    private companyService: CompanyServices,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {}
  agreeToMerge() {
    let data = { token: this.route.snapshot.params['token'] };
    //console.log(data.token , 'uehf')
    this.companyService.approveForMergingData(data).subscribe((response) => {
      if (response.status) {
        swal.fire({
          title: 'Approved Data',
          text: response.message,
          icon: 'success',
          showConfirmButton: false,
          timer: 2500,
        });
        this.router.navigate(['/manager']);
      } else {
        swal.fire({
          title: 'Approved Data',
          text: response.message,
          icon: 'error',
          showConfirmButton: false,
          timer: 2500,
        });
        this.router.navigate(['/manager']);
      }
    });
  }
}
