<div class="upload-container">
  <div class="upload-section">
    <h1>Upload Toxicity Files</h1>

    <!-- Drag & Drop Area -->
    <div
      class="upload-box"
      (drop)="onDrop($event)"
      (dragover)="onDragOver($event)"
    >
      <img
        src="../../../assets/images/upload.png"
        alt="Upload Icon"
        class="upload-icon"
      />
      <p>
        Drag & Drop your files here<br />Or
        <span class="browse-text" (click)="fileInput.click()">Browse</span> to
        upload
      </p>
      <small>Only xlsx files with max size of 15 MB</small>
      <input
        type="file"
        #fileInput
        multiple
        (change)="onFileSelected($event)"
        accept=".xlsx"
        hidden
      />
    </div>

    <!-- Uploaded Files List -->
    <div class="uploaded-files">
      <div class="file-item" *ngFor="let file of uploadedFiles; let i = index">
        <img src="../../../assets/images/upload.png" alt="File Icon" />
        <span
          >{{ file.name }} ({{ (file.size / 1024 / 1024).toFixed(2) }} MB)</span
        >
        <span class="remove-file" (click)="removeFile(i)">&times;</span>
      </div>
    </div>

    <!-- Save Button -->
    <div class="buttons-container">
      <button
        class="save-button"
        (click)="triggerAnalysis()"
        [disabled]="!uploadedFiles.length"
      >
        Analyse
      </button>
      <button
        class="btn btn-outline-primary"
        (click)="downloadFile(demoDataLink)"
      >
        Download Demo Data
      </button>
      <!-- <button class="cancel-button" (click)="clearFiles()">Cancel</button> -->
    </div>

    <!-- Instructions -->
    <div class="instructions">
      <h3>Instructions</h3>
      <ol>
        <li>
          Download the <strong>Manager Scoresheet</strong> to prepare your data.
        </li>
        <li>
          Ensure the Excel file contains exactly two sheets named:
          <ul>
            <li><strong>Manager Scores</strong></li>
            <li><strong>Comments</strong></li>
          </ul>
        </li>
        <!-- <li>
          Add <strong>Performance</strong> and
          <strong>Retention</strong> columns to the scoresheet with the
          following values <strong> (High,Medium,Low,NA).</strong>
        </li> -->
        <li>
          In the <strong>Manager Scores</strong> sheet, include the following
          columns:
          <ul>
            <li>Manager Name</li>
            <li>Manager Email</li>
            <li>Overall</li>
            <li>Saas Score</li>
            <li>Approachability</li>
            <li>Conflict Management</li>
            <li>Fair Treatment</li>
            <li>Involvement in Decisions</li>
            <li>Listening</li>
            <li>Respectful</li>
            <li>Accountability</li>
            <li>Openness to Ideas</li>
          </ul>
        </li>
        <li>
          In the <strong>Comments</strong> sheet, include the following columns:
          <ul>
            <li>Manager Name</li>
            <li>Manager Email</li>
            <li>Respondent Name</li>
            <li>Respondent Email</li>
            <li>Suggestions</li>
          </ul>
        </li>
        <li>Upload the prepared file using the drag-and-drop area above.</li>
        <li>
          Click <strong>"Analyse"</strong> to process the uploaded file and
          generate results.
        </li>
      </ol>
    </div>
  </div>

  <div class="socket-updates">
    <h2>Real-Time Toxicity Updates</h2>
    <div class="status-messages" *ngIf="statusMessages.length">
      <div
        *ngFor="let status of statusMessages; let i = index"
        class="status-item"
      >
        <span>{{ status }}</span>
      </div>
    </div>
    <div class="no-updates" *ngIf="!statusMessages.length">
      <p>No updates yet. Status messages will appear here.</p>
    </div>
  </div>
</div>

<div class="table-container">
  <!-- Search Input -->
  <div class="search-container">
    <input
      type="text"
      class="form-control"
      placeholder="Search by file name..."
      [(ngModel)]="searchText"
      (input)="filterTable()"
    />
  </div>

  <!-- Table -->
  <table class="styled-table">
    <thead>
      <tr>
        <th>File Name</th>
        <th>Created At</th>
        <th>Updated At</th>
        <th>Available Downloads</th>
        <th>Input</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="!paginatedFiles || !paginatedFiles.length">
        <td colspan="5" class="text-center">No files available to display</td>
      </tr>
      <tr *ngFor="let file of paginatedFiles">
        <td>{{ extractFileName(file.input_file) }}</td>
        <td>{{ file.created_at | date : "short" }}</td>
        <td>{{ file.updated_at | date : "short" }}</td>
        <td>
          <div class="btn-container">
            <button
              *ngFor="let outputFile of file.output_files"
              class="btn btn-primary download-btn"
              (click)="downloadFile(outputFile.output_file)"
            >
              Download {{ outputFile.types }}
            </button>
          </div>
        </td>
        <td>
          <button
            class="btn btn-outline-primary btn-sm input-btn"
            (click)="downloadFile(file.input_file)"
          >
            Download Input
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <!-- Pagination Controls -->
  <div class="pagination-container">
    <button
      class="btn btn-secondary"
      [disabled]="currentPage === 1"
      (click)="goToPage(currentPage - 1)"
    >
      Previous
    </button>

    <span *ngFor="let page of paginationPages" class="pagination-page">
      <button
        class="btn btn-outline-primary"
        [class.active]="page === currentPage"
        (click)="goToPage(page)"
      >
        {{ page }}
      </button>
    </span>

    <button
      class="btn btn-secondary"
      [disabled]="currentPage === totalPages"
      (click)="goToPage(currentPage + 1)"
    >
      Next
    </button>
  </div>
</div>
