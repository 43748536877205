import { AdminServices } from "../admin.service";
import { Router } from "@angular/router";
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  TemplateRef,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
// import { MAT_DATE_FORMATS } from '@angular/material/';

// import 'rxjs/add/observable/of';
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
// import io from "socket.io-client";
import io from "socket.io-client";
import * as XLSX from "xlsx";
import { arrOfArrtoArrOfObj, dateFormat, isEmpty } from "../../../config/utils";
import Swal from "sweetalert2";
import { MANAGER_STATUS, MANAGER_TYPE } from "../../constants";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SelectionModel } from "@angular/cdk/collections";
import { environment } from "../../../environments/environment";
import { MAT_DATE_FORMATS } from "@angular/material/core";
import { NgxDropdownConfig } from "ngx-select-dropdown";

export const MY_FORMATS = {
  parse: {
    dateInput: "LL",
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};

@Component({
  selector: "app-manager-mis",
  templateUrl: "./manager-mis.component.html",
  styleUrls: ["./manager-mis.component.css"],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }],
})
export class ManagerMisComponent implements OnInit {
  data: any;
  modalRef: BsModalRef;
  selectedGupShupTemplate = {};
  selectedManagers = [];
  // private socket = io(environment.apiURl, { reconnection: true });
  private socket = io(environment.apiURl, { reconnection: true });

  displayedColumns = [
    "select",
    "email",
    "phone",
    "company",
    "managerStatus",
    "package",
    "expirydate",
    "overall",
  ];
  displayLearningColumns = ["name", "email"];
  selection = new SelectionModel(true, []);
  showPaginator = false;
  ele: boolean = true;
  isRegister: any = {};
  isSas = {};
  isPackage = {};
  currentDate: any;
  isTeamFeedBack = {};
  isActionPlanning = {};
  isCertificate = {};
  totalManagers: any = 0;
  totalSAS: any = 0;
  sheetData: any = [];
  totalSurveys: any = 0;
  loading: boolean;
  surveysData = [];
  sasData = [];
  pageNumber: number = 1;
  selectedCompanies = [];
  surveyResponseRate = {};
  actionPlanningData = [];
  overallScoreDetails: any = {};
  gupshupTemplates = [];
  managerTypeValue = "D2C";
  managerStatusValue = "";
  managerFilterForm: FormGroup;
  whatsappTemplateForm: FormGroup;
  @ViewChild("whatsapp") whatsAppModal: any;
  managerType = MANAGER_TYPE;
  managerStatus = MANAGER_STATUS;
  percentCompletion = "";
  totalNewUploadedManager = "";
  messageSentToManager = "";
  companies = [];
  whatsappMsgRes = "";
  msgNotSentTo = [];
  config: NgxDropdownConfig = {
    displayKey: "viewValue",
    search: true,
    height: "auto",
    placeholder: "Select Filter",
    // customComparator: () => {},
    limitTo: 5,
    moreText: "more",
    noResultsFound: "No results found!",
    searchPlaceholder: "Search",
    searchOnKey: "viewValue",
    customComparator: function (a: any, b: any): number {
      throw new Error("Function not implemented.");
    },
    clearOnSelection: false,
    inputDirection: "",
  };
  config2 = {
    ...this.config,
    placholder: "Manager Type",
  };
  config3 = {
    ...this.config,
    placeholder: "Manager status",
  };
  config4 = {
    ...this.config,
    placeholder: "Template Name",
    displayKey: "name",
    searchOnKey: "name",
  };
  //   displayKey: "viewValue",
  //   search: true,
  //   height: "auto",
  //   placeholder: "Manager Type",
  //   customComparator: () => {},
  //   limitTo: 5,
  //   moreText: "more",
  //   noResultsFound: "No results found!",
  //   searchPlaceholder: "Search",
  //   searchOnKey: "viewValue",
  // };
  // config3 = {
  //   displayKey: "viewValue",
  //   search: true,
  //   height: "auto",
  //   placeholder: "Manager status",
  //   customComparator: () => {},
  //   limitTo: 5,
  //   moreText: "more",
  //   noResultsFound: "No results found!",
  //   searchPlaceholder: "Search",
  //   searchOnKey: "viewValue",
  // };
  limit = 10;
  skip = 0;
  //   displayKey: "name",
  //   search: true,
  //   height: "auto",
  //   placeholder: "Template Name",
  //   customComparator: () => {},
  //   limitTo: 5,
  //   moreText: "more",
  //   noResultsFound: "No results found!",
  //   searchPlaceholder: "Search",
  //   searchOnKey: "name",
  // };
  dataSource: MatTableDataSource<ManagerData>;
  learningDataSource = new MatTableDataSource();
  analyticsDate: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("TABLE") table: ElementRef;
  @ViewChild("statusSelect") statusSelect;
  @ViewChild("formDirective") formDirective;
  constructor(
    private adminService: AdminServices,
    private modalService: BsModalService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.loading = true;
    this.adminService.getStatistics().subscribe((response: any) => {
      if (response.status) {
        this.loading = false;
        this.totalManagers = response.managers;
        this.totalSurveys = response.surveys;
        this.totalSAS = response.sas;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    });
    this.socket.on("TOTAL_MSG_SENT", (data) => {
      this.percentCompletion = data;
    });
    this.socket.on("UPLOADED_ALL_MANAGER", (data) => {
      this.totalNewUploadedManager = data;
    });
    this.socket.on("MESSAGE_SENT_SUCCESSFULLY", (data) => {
      this.messageSentToManager = data;
    });

    // Filter for managerForm
    this.managerFilterForm = new FormGroup({
      startDate: new FormControl("", [Validators.required]),
      endDate: new FormControl("", [Validators.required]),
      managerStatus: new FormControl("", [Validators.required]),
    });

    this.whatsappTemplateForm = new FormGroup({
      templateId: new FormControl(""),
    });

    let data = {
      isRegisteredByCompany: this.managerTypeValue === "D2C" ? false : true,
      // limit: this.limit,
      // skip: this.skip,
    };

    this.getGupShupTemplates();
    this.getManagers(data);
    this.getCompanyList();
  }
  selectCompanies(event) {
    this.selectedCompanies = event.value;
  }

  resetFilter() {
    this.formDirective.resetForm();
    this.managerFilterForm.reset();
    let data = {
      isRegisteredByCompany: this.managerTypeValue === "D2C" ? false : true,
      // limit: this.limit,
      // skip: this.skip,
    };
    this.getManagers(data);
  }

  getGupShupTemplates() {
    this.adminService.getGupShupTemplate({}).subscribe((res: any) => {
      res.status && res.data.length > 0
        ? (this.gupshupTemplates = res.data)
        : (this.gupshupTemplates = []);
    });
  }
  getCompanyList() {
    this.loading = true;
    this.adminService.getCompanyList().subscribe((res) => {
      this.loading = false;
      if (res.status) {
        let companies = [];
        res.data.forEach((company) => {
          let companyData = {
            viewValue: company.name,
            value: company.seCompanyId,
            type: "company",
          };
          companies.push(companyData);
        });
        this.companies = [...companies];
      } else {
        Swal.fire({
          title: "Error",
          text: res.message,
          icon: "error",
        });
      }
    });
  }

  expiryDate(date: Date) {
    let d = new Date(date);
    let year = d.getFullYear();
    let month = d.getMonth();
    let day = d.getDate();
    let changedDate = "";

    changedDate = `${day}/${month + 1}/${year}`;
    return changedDate;
  }

  openSnackBar(message, status) {
    this._snackBar.open(message, "", {
      duration: 2000,
      panelClass: status == "success" ? ["green-snackbar"] : ["red-snackbar"],
      horizontalPosition: "right",
      verticalPosition: "top",
    });
  }

  // to download tables tata in excel format
  downloadMIS() {
    let newManagerMisData = [];
    if (this.selectedCompanies.length > 0) {
      this.data.forEach((manager) => {
        this.selectedCompanies.forEach((company) => {
          if (manager.seDetails !== undefined) {
            if (manager.seDetails.seCompanyId == company.value) {
              let count = 0;
              let managerStatus;
              let d = new Date(manager.package_info.endDate);
              let year = d.getFullYear();
              let month = d.getMonth();
              let day = d.getDate();
              let changedDate = "";
              // let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
              // let monthNames = ["January", "February", "March", "April", "May", "June",
              //   "July", "August", "September", "October", "November", "December"
              // ];
              changedDate = `${day}/${month + 1}/${year}`;
              if (manager.personalInfo.isVerified) {
                if (count == 0) {
                  managerStatus = "Registration";
                }
              }
              if (manager.personalInfo.view !== "sas") {
                if (count == 0) {
                  managerStatus = "Self Assesment";
                }
              }
              if (manager.package_info.packageName) {
                // for status tracker
                if (count == 0) {
                  managerStatus = "Package Selection";
                }
              }
              if (manager.surveys.length > 0) {
                if (count == 0) {
                  managerStatus = "Team Feedback";
                }
              }

              if (manager.actionPlannings.length > 0) {
                if (count == 0) {
                  managerStatus = "Action Planning";
                }
              }
              if (manager.managerInfo.badge.length > 0) {
                if (count == 0) {
                  managerStatus = "Certified";
                }
              }

              if (manager.personalInfo.mobile == undefined) {
                manager.personalInfo.mobile = "Yet To Register";
              }
              if (manager.package_info.packageName == undefined) {
                manager.package_info.packageName = "Yet To Register";
              }
              if (manager.package_info.endDate === undefined) {
                changedDate = "Yet to register";
              }
              //////////////////************//////////////////////
              let couponCodeUser, couponCodeUsed, utmSource;
              if (manager.managerInfo.paymentSource) {
                if (manager.managerInfo.paymentSource.includes("coupon")) {
                  couponCodeUser = "Yes";
                  couponCodeUsed = manager.managerInfo.paymentSource;
                } else {
                  couponCodeUser = "No";
                  couponCodeUsed = manager.managerInfo.paymentSource;
                }
              }
              if (manager.managerInfo.utmSource) {
                utmSource = manager.managerInfo.utmSource;
              } else {
                utmSource = "default";
              }
              let managerSheetInfo = {
                name: manager.personalInfo.name,
                company: manager.personalInfo.company,
                designation: manager.personalInfo.designation,
                email: manager.personalInfo.email,
                mobileVerified: manager.isMobileVerified,
                utmSource: utmSource,
                isVerified: manager.isVerified,
                industry: manager.personalInfo.industry,
                packageName: manager.package_info.packageName,
                registeredByCompany: manager.registeredByCompany,
                isRegistered: manager.isRegistered,
                isSAS: manager.isSAS,
                isProfileDetails: "",
                isActionPlan: manager.isActionPlan,
                isPackage: manager.isPackage,
                isTeamFeedBack: manager.isTeamFeedBack,
                isActionPlanning: manager.isActionPlanning,
                isCertified: manager.isCertified,
                isMobileVerified: manager.isMobileVerified,
                landingSource: manager.landingSource,
                CreatedAt: manager.timestamps.created_at,
                expirydate: changedDate,
                paymentId: manager.package_info.paymentId,
                packageStartDate: manager.package_info.startDate,
                packageEndDate: manager.package_info.endDate,
                subscriptionAmount:
                  isNaN(manager.package_info.subscriptionAmount) == true
                    ? manager.package_info.subscriptionAmount
                    : manager.package_info.subscriptionAmount / 100,
                couponCodeUser: couponCodeUser,
                couponCodeUsed: couponCodeUsed,
              };
              this.currentDate = new Date();
              var firstDate = new Date(this.currentDate),
                secondDate = new Date(manager.package_info.endDate),
                currentDateInSeconds = firstDate.getTime(),
                endDateInSeconds = secondDate.getTime();
              if (currentDateInSeconds >= endDateInSeconds) {
                managerSheetInfo = {
                  name: manager.personalInfo.name,
                  company: manager.personalInfo.company,
                  designation: manager.personalInfo.designation,
                  email: manager.personalInfo.email,
                  mobileVerified: manager.isMobileVerified,
                  utmSource: utmSource,
                  isVerified: manager.isVerified,
                  industry: manager.personalInfo.industry,
                  packageName: "Package Expired",
                  registeredByCompany: manager.registeredByCompany,
                  isRegistered: manager.isRegistered,
                  isSAS: manager.isSAS,
                  isProfileDetails: "",
                  isActionPlan: manager.isActionPlan,
                  isPackage: manager.isPackage,
                  isTeamFeedBack: manager.isTeamFeedBack,
                  isActionPlanning: manager.isActionPlanning,
                  isCertified: manager.isCertified,
                  isMobileVerified: manager.isMobileVerified,
                  landingSource: manager.landingSource,
                  CreatedAt: manager.timestamps.created_at,
                  expirydate: changedDate,
                  paymentId: manager.package_info.paymentId,
                  packageStartDate: manager.package_info.startDate,
                  packageEndDate: manager.package_info.endDate,
                  subscriptionAmount:
                    isNaN(manager.package_info.subscriptionAmount) == true
                      ? manager.package_info.subscriptionAmount
                      : manager.package_info.subscriptionAmount / 100,
                  couponCodeUser: couponCodeUser,
                  couponCodeUsed: couponCodeUsed,
                };
              }
              newManagerMisData.push(managerSheetInfo);
            }
          }
          this.sheetData = [...newManagerMisData];
        });
      });
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.sheetData);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    } else {
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.sheetData);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "SheetJS.xlsx");
    }
  }
  getTfsData(id) {
    this.adminService.getManagerSurvey(id).subscribe((res) => {
      if (res.status) {
        res.data.forEach((data) => {
          data.startDate = dateFormat(data.startDate, "dd-mm-yyyy");
          data.endDate = dateFormat(data.endDate, "dd-mm-yyyy");
          let surveySubmittedRes = data.participants.filter(
            (part) => part.surveySubmissionStatus == true
          );
          this.surveyResponseRate[data._id] =
            (surveySubmittedRes.length / data.participants.length) * 100;
          if (isEmpty(data.surveyScore)) {
            data.surveyScore = "Not Calculated";
          }
        });
        this.surveysData = res.data;
      }
    });
  }

  openSaSModal(modal, id) {
    this.modalRef = this.modalService.show(
      modal,
      Object.assign({}, { class: "my-modal" })
    );
    this.adminService.addModal(this.modalRef);
    this.adminService.getSasInfo(id).subscribe((res) => {
      if (res.status) {
        res.data.forEach((data1) => {
          data1.details.surveyScore = parseInt(
            data1.details.surveyScore
          ).toFixed(0);
          data1.details.cScore = parseInt(data1.details.cScore).toFixed(0);
          data1.details.iScore = parseInt(data1.details.iScore).toFixed(0);
          data1.details.dScore = parseInt(data1.details.dScore).toFixed(0);
        });
        this.sasData = res.data;
      }
    });
  }
  openSurveyModal(modal, id) {
    this.modalRef = this.modalService.show(
      modal,
      Object.assign({}, { class: "my-modal" })
    );
    this.adminService.addModal(this.modalRef);
    this.adminService.getManagerSurvey(id).subscribe((res) => {
      if (res.status) {
        res.data.forEach((data) => {
          data.startDate = dateFormat(data.startDate, "dd-mm-yyyy");
          data.endDate = dateFormat(data.endDate, "dd-mm-yyyy");
          let surveySubmittedRes = data.participants.filter(
            (part) => part.surveySubmissionStatus == true
          );
          this.surveyResponseRate[data._id] =
            (surveySubmittedRes.length / data.participants.length) * 100;
          if (isEmpty(data.surveyScore)) {
            data.surveyScore = "Not Calculated";
          }
        });
        this.surveysData = res.data;
      }
    });
  }
  openActionPlanning(modal, id) {
    this.modalRef = this.modalService.show(
      modal,
      Object.assign({}, { class: "my-modal" })
    );
    this.adminService.addModal(this.modalRef);
    this.adminService.getManagerActionPlanning(id).subscribe((res) => {
      if (res.status) {
        res.data.forEach((data) => {
          data.startDate = dateFormat(data.startDate, "dd-mm-yyyy");
          data.endDate = dateFormat(data.endDate, "dd-mm-yyyy");
        });
        this.actionPlanningData = res.data;
      }
    });
  }
  openOverallScoreModal(managerId, modal) {
    this.overallScoreDetails = {};
    this.adminService.getManagerProfile(managerId).subscribe((res) => {
      if (res.status) {
        this.modalRef = this.modalService.show(
          modal,
          Object.assign({}, { class: "my-modal" })
        );
        this.adminService.addModal(this.modalRef);
        let subThemeScoresArray = [];
        let manager = res.data;
        if (!isEmpty(manager.scores) && manager.scores !== undefined) {
          for (let key in manager.scores.subthemeWiseScore) {
            subThemeScoresArray.push({
              subthemeName: manager.scores.subthemeWiseScore[key].subthemeName,
              subThemeScore:
                manager.scores.subthemeWiseScore[key].score.toFixed(0),
            });
          }
          manager.scores.subthemeWiseScore = subThemeScoresArray;
          this.overallScoreDetails = manager.scores;
        } else {
          this.overallScoreDetails = false;
        }
      }
    });
  }

  getRawData(id, email) {
    this.adminService.getRawData({ id: id }).subscribe((res) => {
      if (res.status) {
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(res.newArray);
        XLSX.utils.book_append_sheet(wb, ws, `TFS-${1}`);
        // for (let i = 0; i < res.data.length; i++) {

        // }
        XLSX.writeFile(wb, `${email}.xlsx`);
      }
    });
  }

  getAuditSheet(surveyId) {
    this.adminService.getAuditSheet({ surveyId: surveyId }).subscribe((res) => {
      if (res.status) {
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        const ws1 = XLSX.utils.json_to_sheet(res.rawData);
        const ws2 = XLSX.utils.json_to_sheet(res.auditSheet);
        XLSX.utils.book_append_sheet(wb, ws1, "Raw Data");
        XLSX.utils.book_append_sheet(wb, ws2, "Audit Sheet");
        XLSX.writeFile(wb, `AuditSheet.xlsx`);
      }
    });
  }

  getRawDataManager(id, email) {
    this.adminService.getRawDataManager({ id: id }).subscribe((res) => {
      if (res.data.length > 0) {
        this.loading = false;
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        let newRes = [];
        newRes = arrOfArrtoArrOfObj(res.data);
        const ws = XLSX.utils.json_to_sheet(newRes);
        XLSX.utils.book_append_sheet(wb, ws, "All Managers");
        XLSX.writeFile(wb, `${email}.xlsx`);
      } else {
        Swal.fire({
          title: "Error",
          text: "No Raw Data Found",
          icon: "error",
        });
      }
    });
  }

  getDownloadSheet(type, eLearning) {
    switch (type) {
      case "gpms":
        this.loading = true;
        this.adminService.getTcData().subscribe((res) => {
          this.loading = false;
          if (res.status) {
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            const ws1 = XLSX.utils.json_to_sheet(res.data);
            XLSX.utils.book_append_sheet(wb, ws1, "Sheet1");
            XLSX.writeFile(wb, `GPMS list.xlsx`);
          }
        });

        break;
      case "chl":
        this.adminService.getManagerCheckListData().subscribe((res) => {
          this.loading = false;
          if (res.status) {
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            const ws1 = XLSX.utils.json_to_sheet(res.data);
            XLSX.utils.book_append_sheet(wb, ws1, "Sheet1");
            XLSX.writeFile(wb, `Managers Checklist.xlsx`);
          }
        });
        break;
      case "mf":
        this.adminService.getManagerFeedback().subscribe((res) => {
          this.loading = false;
          if (res.status) {
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            const ws1 = XLSX.utils.json_to_sheet(res.data);
            XLSX.utils.book_append_sheet(wb, ws1, "Sheet1");
            XLSX.writeFile(wb, `Managers feedback list.xlsx`);
          }
        });
        break;
      case "gs":
        this.loading = true;
        this.adminService.getManagersScore().subscribe((res) => {
          this.loading = false;
          if (res.status) {
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            const ws1 = XLSX.utils.json_to_sheet(res.data);
            XLSX.utils.book_append_sheet(wb, ws1, "Sheet1");
            XLSX.writeFile(wb, `managers scores.xlsx`);
          }
        });
        break;
      case "ms":
        this.loading = true;
        this.adminService.getManagerStatus().subscribe((res) => {
          this.loading = false;
          if (res.status) {
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            const ws1 = XLSX.utils.json_to_sheet(res.data);
            XLSX.utils.book_append_sheet(wb, ws1, "Sheet1");
            XLSX.writeFile(wb, `ms lists.xlsx`);
          }
        });
        break;
      case "ldl":
        this.loading = true;
        this.adminService.getManagerLdLists().subscribe((res) => {
          this.loading = false;
          if (res.status) {
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            const ws1 = XLSX.utils.json_to_sheet(res.data);
            XLSX.utils.book_append_sheet(wb, ws1, "Sheet1");
            XLSX.writeFile(wb, `Learning lists.xlsx`);
          }
        });
        break;
      case "TMD":
        this.loading = true;
        this.adminService.allManagersData().subscribe((res) => {
          this.loading = false;
          if (res.status) {
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            const ws1 = XLSX.utils.json_to_sheet(res.data);
            XLSX.utils.book_append_sheet(wb, ws1, "Sheet1");
            XLSX.writeFile(wb, `Total Managers data.xlsx`);
          }
        });
        break;
      case "MEL":
        this.loading = true;
        this.modalRef = this.modalService.show(
          eLearning,
          Object.assign({}, { class: "popup-mantra" })
        );
        this.adminService.addModal(this.modalRef);
        this.adminService.getLearningManager().subscribe((res) => {
          this.loading = false;
          if (res.status) {
            // console.log(res.data);
            this.learningManager(res.data);
          }
        });
        break;
      // allManagersData
      default:
        break;
    }
  }

  // for search in entite table
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  learningManager(data) {
    let array = [];
    data.forEach((item) => {
      array.push({
        name: item.personalInfo.name,
        email: item.personalInfo.email,
      });
    });

    this.learningDataSource.data = array;
    // console.log("table",this.learningDataSource.data);
  }

  getLearningData(element) {
    let data = {
      email: element.email,
    };
    this.adminService.getLearningScore(data).subscribe((res) => {
      if (res.status == true) {
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        const ws1 = XLSX.utils.json_to_sheet(res.data);
        XLSX.utils.book_append_sheet(wb, ws1, "Sheet1");
        XLSX.writeFile(wb, `Manager E-Learning Data.xlsx`);
      } else if (res.status == false) {
        Swal.fire({
          title: "Module not found!",
          text: res.message,
          icon: "info",
        });
      }
    });
  }

  getAnalyticsData() {
    let data = {
      date: this.analyticsDate,
    };
    this.adminService.getEmailAnalyticsData(data).subscribe((res) => {
      if (res.status && res.data.length > 0) {
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        const ws1 = XLSX.utils.json_to_sheet(res.data);
        XLSX.utils.book_append_sheet(wb, ws1, "Sheet1");
        XLSX.writeFile(wb, `Email Analytics Data.xlsx`);
      } else {
        Swal.fire({
          title: "Data not available for this date!",
          icon: "warning",
        });
      }
    });
  }

  getManagers(data = null) {
    this.adminService.getManagers(data).subscribe((response: any) => {
      if (response.status) {
        this.loading = false;
        this.data = response.data;
        this.sheetData = response.data;
        this.dataSource = new MatTableDataSource(this.data);
        this.ele = false;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
      this.loading = false;
    });
  }

  changeManagerType(event) {
    let data = this.checkManagerType(event.value);
    this.getManagers(data);
  }

  checkManagerType(value) {
    switch (value) {
      case "ALL":
        return null;
      case "D2C":
        return { isRegisteredByCompany: false };
      default:
        return { isRegisteredByCompany: true };
    }
  }

  filterManagers(formValue) {
    this.showPaginator = true;
    const data = { ...formValue.value };
    let bodyObj = this.checkManagerType(this.managerTypeValue);
    for (const key in data) {
      switch (key) {
        case "startDate":
          bodyObj[key] = new Date(data[key]).toISOString();
          break;
        case "endDate":
          bodyObj[key] = new Date(data[key]).toISOString();
          break;
        case "managerStatus":
          bodyObj[key] = data[key];
          break;
      }
    }
    this.getManagers(bodyObj);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows =
      this.dataSource && this.dataSource.data
        ? this.dataSource.data.length
        : [];
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.dataSource.filteredData.length > 0
      ? this.selection.select(...this.dataSource.filteredData)
      : this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? "deselect" : "select"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
      row.position + 1
    }`;
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: "modal-lg" });
    this.adminService.addModal(this.modalRef);
  }

  selectManagers() {
    this.selectedManagers = [...this.selection.selected];
    this.openModal(this.whatsAppModal);
  }

  sendWhatsappMessage() {
    if (
      this.selectedManagers.length > 0 &&
      Object.keys(this.selectedGupShupTemplate).length > 0
    ) {
      let userObj = this.selectedManagers.map((manager) => {
        return (({
          _id,
          name,
          email,
          mobileVerified,
          whatsappOptinStatus,
        }) => ({
          _id,
          name,
          email,
          mobileVerified,
          whatsappOptinStatus,
        }))(manager);
      });
      let data = {
        template: { ...this.selectedGupShupTemplate },
        user: [...userObj],
      };
      this.loading = true;
      this.adminService.sendWhatsAppMsg(data).subscribe((res: any) => {
        this.loading = false;
        let message = "";
        res.status ? (message = "success") : (message = "error");
        this.openSnackBar(res.message, message);
        this.whatsappMsgRes && res.message;
        this.msgNotSentTo = [...res.data];
      });
    }
  }

  selectGupShupTemplate(event) {
    this.selectedGupShupTemplate = event.value;
  }

  pageChanged(type) {
    if (type == "Next") {
      this.skip = this.skip + this.limit;
      this.pageNumber += 1;
    }
    if (type == "Prev") {
      this.skip = this.skip !== 0 ? this.skip - this.limit : 0;
      this.pageNumber > 1 ? (this.pageNumber -= 1) : 1;
    }
    let data = {
      isRegisteredByCompany: this.managerTypeValue === "D2C" ? false : true,
      limit: this.limit,
      skip: this.skip,
    };
    this.getManagers(data);
  }
}

// for data table row type
export interface ManagerData {
  email: any;
  company: any;
  package: any;
  expirydate: any;
  id: any;
}
