<div class="container m-20">
  <div class="box-container">
    <div class="m-20">
      <a
        href="javascript:void(0)"
        [routerLink]="['/company/company-dashboard/survey-list']"
        class="create-new clr-grey"
        style="padding: 10px 0"
      >
        <em>
          <i
            class="fa fa-arrow-left"
            aria-hidden="true"
            style="margin-right: 5px"
          ></i> </em
        >Back</a
      >
    </div>
    <h3 class="title">Survey Email Management</h3>
    <p class="sub-title">
      The survey emails command runs every 15 minutes. For Example, A mail
      scheduled at 15:07 will be sent at 15:15 and an email scheduled at 15:17
      will be sent at 15:30
    </p>
  </div>
  <div class="box-container m-20">
    <div class="row">
      <div class="button-container">
        <button
          class="btn gmi-primary-btn float-right"
          (click)="openEmailTemplate(smsTemp)"
        >
          Create New SMS
        </button>
      </div>
      <div class="button-container">
        <button class="btn gmi-primary-btn float-right" (click)="openEmailTemplate(emailTemp)">
          Create New Email
        </button>
      </div>
      <div
        class="table-container"
        *ngIf="scheduleEmails; else noEmailFoundMesasge"
      >
        <table mat-table [dataSource]="scheduleEmails" matSort>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let manager">{{ manager.name }}</td>
          </ng-container>
          <ng-container matColumnDef="scheduleFor">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              ScheduleAt
            </th>
            <td mat-cell *matCellDef="let manager">
              {{ manager.scheduleFor }}
            </td>
          </ng-container>
          <ng-container matColumnDef="sentStatus">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Sent Status
            </th>
            <td mat-cell *matCellDef="let manager">
              {{ manager.sentStatus }}
            </td>
          </ng-container>
          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Created At
            </th>
            <td mat-cell *matCellDef="let manager">
              {{ manager.createdAt }}
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
            <td mat-cell *matCellDef="let manager">
              <div class="action-container">
                <!-- <p (click)="editForm(manager._id , )"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></p>  -->
                <p (click)="deleteEmail(manager._id)">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </p>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumnsForEmails"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumnsForEmails"
          ></tr>
        </table>
        <mat-paginator
          [pageSizeOptions]="[10, 20]"
          showFirstLastButtons
        ></mat-paginator>
      </div>

      <!-- <div class="col-md-6">
        <h4 class="sub-title">Managers List</h4>
        <mat-form-field>
          <input
            matInput
            (keyup)="applyFilter($event.target.value)"
            placeholder="Search Manager"
          />
        </mat-form-field>
        <div *ngIf="managers" class="table-container m-10">
          <table mat-table [dataSource]="managers" matSort>
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                  *ngIf="formData.to == 'Other'"
                  (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                  [aria-label]="checkboxLabel()"
                >
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox
                  *ngIf="formData.to == 'other'"
                  (click)="$event.stopPropagation()"
                  (change)="$event ? selection.toggle(row) : null"
                  [checked]="selection.isSelected(row)"
                  [aria-label]="checkboxLabel(row)"
                >
                </mat-checkbox>
              </td>
            </ng-container>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
              <td mat-cell *matCellDef="let manager">{{ manager.name }}</td>
            </ng-container>
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
              <td mat-cell *matCellDef="let manager">{{ manager.email }}</td>
            </ng-container>
            <ng-container matColumnDef="noOfParticipants">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Participants</th>
              <td mat-cell *matCellDef="let manager">
                {{ manager.noOfParticipants.length }}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <mat-paginator
            [pageSizeOptions]="[10, 20]"
            showFirstLastButtons
          ></mat-paginator>
        </div>
      </div> -->
    </div>
  </div>
</div>

<ng-template #emailTemp>
  <div class="modal-header">
    <h4
      style="font-size: 22px; text-transform: capitalize"
      class="modal-title pull-left"
    >
      Add email
    </h4>
    <button
      type="button "
      class="close pull-right"
      aria-label="Close "
      (click)="closeAddEmailModal()"
    >
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <h4 class="sub-title">Email operation</h4>

        <small class="text-danger"></small>
        <div class="form-container">
          <div *ngIf="placeholder" class="placeholder-container">
            <p class="placeholder">{{ placeholder }}</p>
          </div>
          <div class="input-group">
            <label for="emailType"
              >Email Type <span class="error">*</span></label
            >
            <select
              name="emailType"
              id="emailType"
              (change)="handleForm($event)"
            >
              <option value="" selected disabled>
                Please select email type
              </option>
              <option value="team_member_login_details" *ngIf="!isRTFSurvey">
                Team Member Login Details
              </option>
              <option value="survey_reminder" *ngIf="!isRTFSurvey">
                Survey Reminder
              </option>
              <option value="spoc_response_rate" *ngIf="!isRTFSurvey">
                SPOC Response rate
              </option>
              <option
                *ngIf="
                  showSurveyFeedback && showResponseMailInDrop && !isRTFSurvey
                "
                value="{{ responseRate }}"
              >
                Survey Response Rate
              </option>
              <option value="manager_registration_email">
                Manager Registration
              </option>
              <option value="manager_registration_email_reminder">
                Manager Registration Reminder
              </option>
              <option value="manager_opted_for_default_password">
                Managers opted for Default Password
              </option>
              <option
                *ngIf="magicLinkStatus && !isRTFSurvey"
                value="respoondent_mail_with_magic_link"
              >
                Employees opted for Magic Link
              </option>
              <option
                value="employee_opted_for_default_password"
                *ngIf="!isRTFSurvey"
              >
                Employee opted for Default Password
              </option>
              <option
                *ngIf="showSasMailInDropDown && !isRTFSurvey"
                value="manager_registration_and_sas_report"
              >
                Manager Registration and SAS Response Rate
              </option>
              <option
                *ngIf="isOrgReportGenerated && !isRTFSurvey"
                value="gpms_org_report"
              >
                Send org report + manager scorecards to SPOC
              </option>
              <option *ngIf="!isManagerReportGenerated" value="manager_report">
                Send individual manager scorecards
              </option>
              <option value="custom" *ngIf="!isRTFSurvey">Custom</option>
              <option value="rtf_respondent_mail" *ngIf="isRTFSurvey">
                Real time feedback mail to Respondents
                <!-- <option value="custom">Custom</option> -->
              </option>
            </select>
          </div>
          <div class="input-group">
            <label for="name">Name <span class="error">*</span></label>
            <input
              type="text"
              name="name"
              id="name"
              (change)="handleForm($event)"
              [(ngModel)]="formData.name"
            />
          </div>
          <div class="input-group">
            <!-- <span class="error">*</span> -->
            <label for="cc">CC </label>
            <input
              type="email"
              name="cc"
              id="cc"
              (change)="handleForm($event)"
              [(ngModel)]="formData.cc"
            />
          </div>
          <!-- <div class="input-group">
            <label for="bcc">BCC <span class="error">*</span></label>
            <input
              type="email"
              name="bcc"
              id="bcc"
              (change)="handleForm($event)"
              [(ngModel)]="formData.bcc"
            />
          </div> -->
          <div class="input-group">
            <label for="subject"
              >Mail Subject <span class="error">*</span></label
            >
            <input
              type="text"
              name="subject"
              id="subject"
              (change)="handleForm($event)"
              [(ngModel)]="formData.subject"
            />
          </div>
          <div class="input-group">
            <label for="to">To <span class="error">*</span></label>
            <select name="to" id="to" (change)="handleForm($event)">
              <option value="" selected disabled>
                Please pick the recipients
              </option>
              <option *ngFor="let i of toTypes" value="{{ i }}">{{ i }}</option>
            </select>
          </div>
          <div class="input-group" *ngIf="formData.to == 'Others'">
            <label for="subject">Please Enter To Email. </label>
            <div class="extra-input">
              <input type="text" [(ngModel)]="toSend" name="email" />
            </div>
          </div>
          <div class="input-group">
            <label for="subject"
              >ScheduleAt
              <span
                *ngIf="formData.emailType == 'tfs_response_rate_b2b'"
                class="error"
                >*</span
              ></label
            >

            <div class="extra-input">
              <input
                name="schedule"
                [owlDateTimeTrigger]="dt10"
                [owlDateTime]="dt10"
                [selectMode]="'range'"
                [min]="startAt"
                [(ngModel)]="scheduleAt"
              />
              <!-- <owl-date-time [pickerMode]="'dialog'" #dt10></owl-date-time> -->
              <owl-date-time #dt10></owl-date-time>
            </div>
          </div>
          <label for="content">Content <span class="error">*</span></label>
          <!-- <div class="input-group"> -->
          <!-- <angular-editor
              [config]="editorConfig"
              [placeholder]="'Enter text here...'"
              [(ngModel)]="formData.content"
              (ngModelChange)="handleEditorChange($event)"
              [ngModelOptions]="{ standalone: true }"
              name="emailContent"
            ></angular-editor> -->

          <!-- <div class="editor">
              <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
              </ngx-editor-menu>
              <ngx-editor
                [ngModelOptions]="{ standalone: true }"
                [editor]="editor"
                [placeholder]="'Enter text here...'"
                [(ngModel)]="formData.content"
              >
              </ngx-editor>
            </div> -->

          <app-email-editor
            [content]="formData.content"
            (contentChange)="onContentChange($event)"
          ></app-email-editor>
          <!-- (contentChange)="onContentChange($event)" -->

          <!-- <email-editor (loaded)="editorLoaded()"></email-editor> -->
          <!-- </div> -->
          <p
            class="text-danger text-center"
            *ngIf="missingPlaceholders?.length > 0"
          >
            {{ missingHeader }}
          </p>
          <p
            class="text-info text-center"
            *ngIf="additionalPlaceholders?.length > 0"
          >
            {{ additionalHeader }}
          </p>
          <div class="button-container">
            <button
              class="btn gmi-primary-btn"
              (click)="submitData(emailCount)"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #smsTemp>
  <div class="modal-header">
    <h4 style="font-size: 22px; text-transform: capitalize" class="modal-title pull-left">
      Add SMS
    </h4>
    <button type="button " class="close pull-right" aria-label="Close " (click)="modalRef.hide()">
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <h4 class="sub-title">SMS operation</h4>

        <small class="text-danger"></small>
        <div class="form-container">
         
          <div class="input-group">
            <label for="emailType">SMS Type <span class="error">*</span></label>
            <select name="emailType" id="emailType" (change)="handleForm($event)">
              <option value="" selected disabled>
                Please select SMS type
              </option>
            
            </select>
          </div>
          <div class="input-group">
            <label for="name">Name <span class="error">*</span></label>
            <input type="text" name="name" id="name" (change)="handleForm($event)" [(ngModel)]="formData.name" />
          </div>
          <div class="input-group">
            <!-- <span class="error">*</span> -->
            <label for="cc">CC </label>
            <input type="email" name="cc" id="cc" (change)="handleForm($event)" [(ngModel)]="formData.cc" />
          </div>
          <!-- <div class="input-group">
            <label for="bcc">BCC <span class="error">*</span></label>
            <input
              type="email"
              name="bcc"
              id="bcc"
              (change)="handleForm($event)"
              [(ngModel)]="formData.bcc"
            />
          </div> -->
          <div class="input-group">
            <label for="subject">Mail Subject <span class="error">*</span></label>
            <input type="text" name="subject" id="subject" (change)="handleForm($event)"
              [(ngModel)]="formData.subject" />
          </div>
          <div class="input-group">
            <label for="to">To <span class="error">*</span></label>
            <select name="to" id="to" (change)="handleForm($event)">
              <option value="" selected disabled>
                Please pick the recipients
              </option>
              <option *ngFor="let i of toTypes" value="{{ i }}">{{ i }}</option>
            </select>
          </div>
          <div class="input-group" *ngIf="formData.to == 'Others'">
            <label for="subject">Please Enter To Email. </label>
            <div class="extra-input">
              <input type="text" [(ngModel)]="toSend" name="email" />
            </div>
          </div>
          <div class="input-group">
            <label for="subject">ScheduleAt
              <span *ngIf="formData.emailType == 'tfs_response_rate_b2b'" class="error">*</span></label>

            <div class="extra-input">
              <input name="schedule" [owlDateTimeTrigger]="dt10" [owlDateTime]="dt10" [selectMode]="'range'"
                [min]="startAt" [(ngModel)]="scheduleAt" />
              <!-- <owl-date-time [pickerMode]="'dialog'" #dt10></owl-date-time> -->
              <owl-date-time #dt10></owl-date-time>
            </div>
          </div>
          <label for="content">Content <span class="error">*</span></label>
          <!-- <div class="input-group"> -->
          <!-- <angular-editor
              [config]="editorConfig"
              [placeholder]="'Enter text here...'"
              [(ngModel)]="formData.content"
              (ngModelChange)="handleEditorChange($event)"
              [ngModelOptions]="{ standalone: true }"
              name="emailContent"
            ></angular-editor> -->

          <!-- <div class="editor">
              <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
              </ngx-editor-menu>
              <ngx-editor
                [ngModelOptions]="{ standalone: true }"
                [editor]="editor"
                [placeholder]="'Enter text here...'"
                [(ngModel)]="formData.content"
              >
              </ngx-editor>
            </div> -->

          <app-email-editor [content]="formData.content" (contentChange)="onContentChange($event)"></app-email-editor>
          <!-- (contentChange)="onContentChange($event)" -->

          <!-- <email-editor (loaded)="editorLoaded()"></email-editor> -->
          <!-- </div> -->
          <p class="text-danger text-center" *ngIf="missingPlaceholders?.length > 0">
            {{ missingHeader }}
          </p>
          <p class="text-info text-center" *ngIf="additionalPlaceholders?.length > 0">
            {{ additionalHeader }}
          </p>
          <div class="button-container">
            <button class="btn gmi-primary-btn" [disabled]="isValidData" (click)="submitData(emailCount)">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #emailCount>
  <div class="modal-header">
    <h4 style="font-size: 22px" class="modal-title pull-left">
      Email Sent Status
    </h4>
  </div>
  <div class="modal-body">
    <div class="status-container" *ngIf="totalEmailSentCount > 0">
      <p class="title">Total no of Email {{ totalEmailSentCount }}</p>
      <progress class="custom-progress" value="{{ completedCount }}" max="100">
        {{ completedCount }}
      </progress>
      <p class="status-count" *ngIf="!updateStatus">
        Sent <strong>{{ emailSentCount }}</strong> of
        <strong>{{ totalEmailSentCount }}</strong> Emails.
      </p>

      <div class="action-container" *ngIf="updateStatus">
        <p class="success-text">Successfully sent all the emails.</p>
        <button class="btn gmi-primary-btn" (click)="redirectToSurveyPage()">
          Go to the survey page
        </button>
      </div>
    </div>
    <div class="message-container" *ngIf="totalEmailSentCount == 0">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>

      <p class="loading-text">Please wait we are fetching the Data</p>
    </div>
  </div>
</ng-template>

<ng-template #noEmailFoundMesasge>
  <div class="no-message">
    <p class="title">No Emails are found!</p>
  </div>
</ng-template>
