<div class="main-box p-5">
  <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="start">
    <mat-tab>
      <ng-template mat-tab-label>
        <h3>Survey Dialog</h3>
      </ng-template>
      <app-survey-dialog></app-survey-dialog>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label> <h3>Survey Fillers</h3> </ng-template>
      <app-survey-fillers></app-survey-fillers>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label> <h3>Survey Content Mapping</h3> </ng-template>
      <app-survey-filler-mapping></app-survey-filler-mapping>
    </mat-tab>
  </mat-tab-group>
</div>
