<a (click)="openAssignBatchesModal(batchModal)" class="batches"
  >Assign Batches</a
>

<ng-template #batchModal>
  <div class="modal-header">
    <h4 style="font-size: 22px" class="modal-title pull-left">
      Assign Batches to your selected managers.
    </h4>
    <button
      type="button "
      class="close pull-right"
      aria-label="Close "
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div
    *ngIf="selectedManagers.length > 0; else showAddManagerMessage"
    class="modal-body"
  >
    <div class="row">
      <div class="col-md-6 table-container">
        Selected Managers ({{ selectedManagers.length }})
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let manager of selectedManagers">
              <td>{{ manager.name }}</td>
              <td>{{ manager.email }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-6">
        <div class="batch-preview-container">
          <div class="center">
            <h3>Assign Batches</h3>
            <form>
              <div class="form-group">
                <label for="batchName">Batch Name</label>
                <input
                  type="text"
                  [(ngModel)]="batchName"
                  name="batchName"
                  id="batchName"
                  placeholder="Enter batch name"
                  required
                  #batchNameControl="ngModel"
                />
                <p
                  class="warning"
                  *ngIf="batchNameControl.touched && !batchName"
                >
                  Required field
                </p>
              </div>
              <div class="form-group">
                <button
                  class="btn btn-primary"
                  (click)="submit()"
                  [disabled]="!batchName"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #showAddManagerMessage>
  <p class="title">Select at least 1 manager to assign batches</p>
</ng-template>
