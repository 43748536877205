<!-- //<app-loader *ngIf="loading"></app-loader> -->
<div class="batch-dashboard-container shadow">
  <a
    href="javascript:void(0)"
    [routerLink]="['/company/company-dashboard']"
    class="create-new clr-grey"
    style="padding: 10px 0"
  >
    <em>
      <i
        class="fa fa-arrow-left"
        aria-hidden="true"
        style="margin-right: 5px"
      ></i> </em
    >Back</a
  >
  <div class="row">
    <div class="col-md-3">
      <div class="batch-list-container">
        <div class="header"><p class="title">Batch List</p></div>
        <ul>
          <li
            [ngStyle]="{
              backgroundColor:
                activeTabId == batch.id ? '#e2e2e2' : 'whitesmoke'
            }"
            *ngFor="let batch of batchListData"
            (click)="loadBatchData(batch.id)"
          >
            {{ batch.batchName }}
          </li>
        </ul>
      </div>
    </div>
    <div class="col-md-6">
      <div class="batch-list-data">
        <p class="title">Batch Details</p>
        <div class="dashboard-container">
          <mat-form-field
            style="width: 50%"
            class="search-form-field"
            floatLabel="never"
          >
            <input
              style="
                border: none;
                color: #000;
                font-size: 14px;
                font-weight: 300;
              "
              matInput
              [(ngModel)]="searchKey"
              placeholder="Search"
              autocomplete="off"
              (keyup)="applyFilter()"
            />
            <button
              mat-button
              matSuffix
              mat-icon-button
              aria-label="Clear"
              *ngIf="searchKey"
              (click)="onSearchClear()"
            >
              <!-- <mat-icon>close</mat-icon>
               -->
               <i class="fa fa-times"></i>
            </button>
          </mat-form-field>
          <mat-table #table [dataSource]="dataSource" matSort>
            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Name</mat-header-cell
              >
              <mat-cell *matCellDef="let element">
                {{ element.name }}
                <span matTooltip="Total participants">
                  ({{ element.participants.length }})</span
                >
              </mat-cell>
            </ng-container>
            <!-- Email Column -->
            <ng-container matColumnDef="email">
              <mat-header-cell *matHeaderCellDef mat-sort-header
                >email</mat-header-cell
              >
              <mat-cell *matCellDef="let element">{{ element.email }}</mat-cell>
            </ng-container>
            <!-- Status column -->
            <ng-container matColumnDef="status">
              <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
              <mat-cell *matCellDef="let element" style="overflow: unset">
                <ul class="gmi-stepper">
                  <li
                    [ngClass]="{ active: isRegister[element.id] }"
                    class="active"
                    matTooltip="Register"
                  >
                    RG
                  </li>
                  <div
                    [ngClass]="{ activeDivider: isRegister[element.id] }"
                    class="divider"
                  ></div>
                  <li
                    [ngClass]="{ active: isSas[element.id] }"
                    matTooltip="Self Assesment"
                    (click)="openSaSModal(sasInfo, element.id)"
                  >
                    SAS
                  </li>
                  <div
                    [ngClass]="{ activeDivider: isSas[element.id] }"
                    class="divider"
                  ></div>
                  <li
                    [ngClass]="{ active: isPackage[element.id] }"
                    matTooltip="Package: {{ element.packageName }}"
                  >
                    PK
                  </li>
                  <div
                    [ngClass]="{ activeDivider: isPackage[element.id] }"
                    class="divider"
                  ></div>
                  <li
                    [ngClass]="{ active: isTeamFeedBack[element.id] }"
                    matTooltip="Team Feedback Survey"
                    (click)="openSurveyModal(SurveyInfo, element.id)"
                  >
                    TFS
                  </li>
                  <div
                    [ngClass]="{ activeDivider: isTeamFeedBack[element.id] }"
                    class="divider"
                  ></div>
                  <li
                    [ngClass]="{ active: isActionPlanning[element.id] }"
                    [ngStyle]="{
                      'background-color':
                        isActionPlanning[element.id] == false &&
                        isCertificate[element.id] == true
                          ? 'yellow'
                          : 'whitesmoke'
                    }"
                    (click)="
                      getActionPlanningForMgr(element.id, actionPlanInfo)
                    "
                    matTooltip="Action Planning"
                  >
                    AP
                  </li>
                  <div
                    [ngClass]="{ activeDivider: isActionPlanning[element.id] }"
                    class="divider"
                  ></div>
                  <li
                    [ngClass]="{ active: isCertificate[element.id] }"
                    matTooltip="Certificate"
                  >
                    CF
                  </li>
                </ul>
              </mat-cell>
            </ng-container>
            <!-- Action Column -->
            <mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          </mat-table>
          <mat-card *ngIf="displayNoRecords" style="padding: 100px">
            <h5 style="text-align: center">
              We couldn't find data for
              <span style="color: red">"{{ dataSource.filter }}"</span>
            </h5>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #actionPlanInfo>
  <div class="modal-header">
    <h4 style="font-size: 22px" class="modal-title pull-left">
      Action Plan Information
    </h4>
    <button
      type="button "
      class="close pull-right"
      aria-label="Close "
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="actionPlanningData.length != 0; else noActionData">
      <table style="margin-top: 0" class="table table-striped">
        <thead>
          <tr>
            <th>Sl. No</th>
            <th>Action Planning Name</th>
            <!-- <th>Start Date</th>
            <th>End Date</th> -->
            <th>Theme</th>
            <th>Duration</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody *ngFor="let action of actionPlanningData; index as i">
          <tr>
            <td>{{ i + 1 }}</td>
            <td>{{ action.actionName }}</td>
            <!-- <td>{{ action.startDate }}</td>
            <td>{{ action.endDate }}</td> -->
            <td>{{ action.theme }}</td>
            <td>{{ action.duration }}</td>
            <td>{{ action.description }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>

<ng-template #noActionData>
  <p>No Action Planning Data!</p>
</ng-template>

<ng-template #SurveyInfo>
  <div class="modal-header">
    <h4 style="font-size: 22px" class="modal-title pull-left">
      Survey Information
    </h4>
    <button
      type="button "
      class="close pull-right"
      aria-label="Close "
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="surveysData.length != 0; else noSurveyData">
      <table style="margin-top: 0" class="table table-striped">
        <thead>
          <tr>
            <th>Sl. No</th>
            <th>Survey Name</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Overall Score</th>
            <th>Response Rate</th>
            <th>Participants</th>
          </tr>
        </thead>
        <tbody *ngFor="let survey of surveysData; index as i">
          <tr>
            <td>{{ i + 1 }}</td>
            <td>{{ survey.name }}</td>
            <td>{{ survey.startDate }}</td>
            <td>{{ survey.endDate }}</td>
            <td>
              {{
                survey.surveyScore.overall
                  ? survey.surveyScore.overall + "%"
                  : "not calculated"
              }}
            </td>
            <td>{{ surveyResponseRate[survey._id] }}%</td>
            <td>
              <ul>
                <li
                  style="list-style: none"
                  *ngFor="let participant of survey.participants"
                >
                  {{ participant.emailId }}
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>
<ng-template #noSurveyData>
  <p>No Survey Data!</p>
</ng-template>

<ng-template #sasInfo>
  <div class="modal-header">
    <h4 style="font-size: 22px" class="modal-title pull-left">
      SelfAssesment Information
    </h4>
    <button
      type="button "
      class="close pull-right"
      aria-label="Close "
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="sasData.length != 0; else noSurveyData">
      <table style="margin-top: 0" class="table table-striped">
        <thead>
          <tr>
            <th>Sl. No</th>
            <th>Survey Name</th>
            <th>Overall Score</th>
            <th>Connect Score</th>
            <th>Develop Score</th>
            <th>Inspire Score</th>
          </tr>
        </thead>
        <tbody *ngFor="let survey of sasData; index as i">
          <tr>
            <td>{{ i + 1 }}</td>
            <td>{{ survey.details.surveyName }}</td>
            <td>{{ survey.details.surveyScore }}</td>
            <td>{{ survey.details.cScore }}</td>
            <td>{{ survey.details.dScore }}</td>
            <td>{{ survey.details.iScore }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>
