<div class="userGuide">
  <div class="spaceBtwn">
    <input
      type="text"
      placeholder="Search.."
      class="searchBar"
      (keyup)="applyFilter($any($event.target).value)"
    />
    <button
      class="gmi-primary-btn mr-5 text-right"
      (click)="showUserGuideModal(addEditUserGuide, {}, false, {})"
    >
      Add New
    </button>
  </div>
  <div class="overflowContainer">
    <table
      class="mat-elevation-z8 demo-table"
      mat-table
      [dataSource]="dataSource"
    >
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name.</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="content">
        <th mat-header-cell *matHeaderCellDef>Content</th>
        <td mat-cell *matCellDef="let element">{{ element.content }}</td>
      </ng-container>

      <ng-container matColumnDef="module">
        <th mat-header-cell *matHeaderCellDef>Module</th>
        <td mat-cell *matCellDef="let element">{{ element.module }}</td>
      </ng-container>

      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef>Location</th>
        <td mat-cell *matCellDef="let element">{{ element.location }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let element; let i = index">
          <div style="display: inline-flex">
            <i
              class="fa fa-edit"
              style="margin-right: 15px"
              title="Edit"
              (click)="showUserGuideModal(addEditUserGuide, element, true, i)"
            ></i>
            <i
              class="fa fa-trash"
              style="margin-right: 15px"
              (click)="deleteUserGuide(element, i)"
              title="Delete"
            ></i>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <div
      class="text-center emptyContainer"
      *ngIf="dataSource.data.length === 0"
    >
      Please Add User Guide Data:
    </div>
  </div>

  <ng-template #addEditUserGuide>
    <div class="modal-header">
      <h4 style="font-size: 22px" class="modal-title pull-left">
        User Guide Form:
      </h4>
      <button
        type="button "
        class="close pull-right"
        aria-label="Close "
        (click)="closeModal()"
      >
        <span aria-hidden="true ">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form class="form" [formGroup]="userGuideForm">
        <label for="name">Name:<small class="text-danger">*</small> </label>
        <input
          formControlName="name"
          required
          type="text"
          placeholder="Enter name"
        />
        <div
          *ngIf="
            userGuideForm.get('name').invalid &&
            userGuideForm.get('name').touched
          "
        ></div>
        <label for="name">Content:<small class="text-danger">*</small> </label>
        <input
          formControlName="content"
          required
          type="text"
          placeholder="Enter content."
        />

        <label for="name">Location:<small class="text-danger">*</small> </label>
        <input
          required
          formControlName="location"
          type="text"
          placeholder="Enter location."
        />

        <label for="name">Module:<small class="text-danger">*</small> </label>
        <input
          required
          formControlName="module"
          type="text"
          placeholder="Enter module. Ex: my-surveys, community"
        />

        <label for="name">Description: </label>
        <input
          formControlName="description"
          type="text"
          placeholder="Enter description."
        />
        <br />
        <button
          *ngIf="!isEdit"
          class="p-2"
          type="button"
          (click)="addNewData()"
          [disabled]="userGuideForm.invalid"
        >
          Add User Guide
        </button>
        <button
          *ngIf="isEdit"
          type="button"
          (click)="editExistingData()"
          [disabled]="userGuideForm.invalid"
        >
          Update User Guide
        </button>
        <br />
        <p
          class="text-danger text-center m-2"
          *ngIf="userGuideForm.touched && userGuideForm.invalid"
        >
          Please Fill All Mandatory Fields
        </p>
      </form>
    </div>
  </ng-template>
</div>
