<div class="container">
  <div style="width: 20%">
    <h2>Visualizations</h2>
  </div>
  <button
    style="float: right; margin-top: -50px; margin-bottom: 10px"
    class="bold-text btn btn-gmi-primary mr-1"
    (click)="openAddModal('addTemplate', addTemplate)"
  >
    <i class="fa fa-user-plus" style="margin-right: 15px"></i>Create New
    Visualization
  </button>

  <table
    class="table table-striped table-bordered"
    *ngIf="visualizations.length > 0"
  >
    <thead>
      <tr>
        <th>Name</th>
        <!-- <th>Type</th> -->
        <!-- <th>Created At</th>
        <th>Updated At</th> -->
        <!-- <th>Page Type</th> -->
        <th>Header</th>
        <th>Description</th>
        <th>Content</th>
        <th>Colour</th>
        <th>User Type</th>
        <th>Active</th>
        <th>Filter</th>
        <th>Icon URL</th>
        <th>Edit</th>
        <th>Delete</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let visualization of visualizations">
        <td>{{ visualization.name }}</td>
        <!-- <td>{{ visualization.type }}</td> -->
        <!-- <td>{{ visualization.created_at }}</td>
        <td>{{ visualization.updated_at }}</td> -->
        <!-- <td>{{ visualization.page_type }}</td> -->
        <td>{{ visualization.header ? visualization.header : " - " }}</td>
        <td>
          {{ visualization.description ? visualization.description : " - " }}
        </td>
        <td>{{ visualization.content ? visualization.content : " - " }}</td>
        <td>{{ visualization.colour ? visualization.colour : " - " }}</td>
        <td>
          <ng-container *ngIf="visualization.user_type == 1"
            >Manager Analytics</ng-container
          >
          <ng-container *ngIf="visualization.user_type == 2"
            >Employee Analytics</ng-container
          >
          <ng-container *ngIf="visualization.user_type == 3"
            >Organization Analytics</ng-container
          >
        </td>
        <td>{{ visualization.is_active ? "Yes" : "No" }}</td>
        <td>{{ visualization.is_filter ? "Yes" : "No" }}</td>
        <td>
          <img
            [src]="visualization.icon_url"
            alt="{{ visualization.name }} Logo"
            width="50"
          />
        </td>
        <!-- <td>{{ visualization.user_type }}</td> -->
        <td>
          <i
            data-toggle="tooltip"
            data-placement="top"
            title="Edit User"
            class="fa fa-edit cursor"
            style="color: blue"
            (click)="openAddModal('editTemplate', addTemplate, visualization)"
          >
          </i>
        </td>
        <td>
          <i
            data-toggle="tooltip"
            data-placement="top"
            title="Delete User"
            class="fa fa-trash cursor"
            style="color: red"
            (click)="deleteVisualizationDetail(visualization)"
          >
          </i>
        </td>
      </tr>
    </tbody>
  </table>
  <div
    *ngIf="visualizations.length === 0 && !errorMessage"
    class="alert alert-info"
  >
    No visualizations available.
  </div>
</div>

<ng-template #addTemplate>
  <div class="modal-content" style="width: 1050px">
    <div class="modal-header">
      <h4 style="font-size: 22px" class="modal-title pull-left">
        {{ !isEditMode ? "Add Visualization" : "Edit Visualization" }}
      </h4>
      <button
        type="button"
        class="close pull-right"
        aria-label="Close "
        (click)="modalRef.hide()"
      >
        <span aria-hidden="true ">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12" style="padding: 0">
          <div class="form-group col-md-12">
            <!-- <h3>
              {{ isEditMode ? "Add New Visualization" : "Edit Visualization" }}
            </h3> -->
            <form [formGroup]="visualizationForm" (ngSubmit)="onSubmit()">
              <div class="form-group">
                <label for="name">Name </label>
                <input
                  type="text"
                  id="name"
                  formControlName="name"
                  class="form-control"
                  [readOnly]="isEditMode"
                />
                <div
                  *ngIf="
                    visualizationForm.get('name')?.invalid &&
                    visualizationForm.get('name')?.touched
                  "
                  class="text-danger"
                >
                  Name is required.
                </div>
              </div>

              <div class="form-group">
                <label for="header">Header</label>
                <input
                  type="text"
                  id="header"
                  formControlName="header"
                  class="form-control"
                />
                <div
                  *ngIf="
                    visualizationForm.get('header')?.invalid &&
                    visualizationForm.get('header')?.touched
                  "
                  class="text-danger"
                >
                  Header is required.
                </div>
              </div>
              <div class="form-group">
                <label for="description">Description</label>
                <input
                  type="text"
                  id="description"
                  formControlName="description"
                  class="form-control"
                />
                <div
                  *ngIf="
                    visualizationForm.get('description')?.invalid &&
                    visualizationForm.get('description')?.touched
                  "
                  class="text-danger"
                >
                  Description is required.
                </div>
              </div>

              <div class="form-group">
                <label for="content">Content</label>
                <input
                  type="text"
                  id="content"
                  formControlName="content"
                  class="form-control"
                />
                <div
                  *ngIf="
                    visualizationForm.get('content')?.invalid &&
                    visualizationForm.get('content')?.touched
                  "
                  class="text-danger"
                >
                  Content is required.
                </div>
              </div>
              <div class="form-group">
                <label for="colour">Colour</label>

                <input
                  readonly
                  [style.background]="color || '#ffffff'"
                  [(colorPicker)]="color"
                  (colorPickerChange)="onColorChange($event)"
                  formControlName="colour"
                  class="form-control"
                />
                <!-- <input
                  type="text"
                  id="colour"
                  formControlName="colour"
                  class="form-control"
                /> -->
                <div
                  *ngIf="
                    visualizationForm.get('colour')?.invalid &&
                    visualizationForm.get('colour')?.touched
                  "
                  class="text-danger"
                >
                  Colour is required.
                </div>
              </div>
              <div class="form-group">
                <label for="icon_url">Icon URL</label>
                <input
                  type="text"
                  id="icon_url"
                  formControlName="icon_url"
                  class="form-control"
                />
                <div
                  *ngIf="
                    visualizationForm.get('icon_url')?.invalid &&
                    visualizationForm.get('icon_url')?.touched
                  "
                  class="text-danger"
                >
                  Icon URL is required.
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group col-sm-4">
                  <label style="margin-right: 15px">User Type</label>
                  <select
                    (change)="handleChange($event.target['selectedIndex'])"
                    formControlName="user_type"
                  >
                    <option *ngFor="let item of userTypes" [value]="item.key">
                      {{ item.value }}
                    </option>
                  </select>
                  <div
                    *ngIf="
                      visualizationForm.get('user_type')?.invalid &&
                      visualizationForm.get('user_type')?.touched
                    "
                    class="text-danger"
                  >
                    User Type is required.
                  </div>
                </div>
                <div class="form-group col-sm-4">
                  <input
                    type="checkbox"
                    id="is_filter"
                    formControlName="is_filter"
                    class="ml-5 form-check-input"
                  />
                  <label for="is_filter" style="margin-left: 15px"
                    >Is Filter</label
                  >
                </div>
                <div class="form-group col-sm-4">
                  <input
                    type="checkbox"
                    id="is_active"
                    formControlName="is_active"
                    class="ml-5 form-check-input"
                  />
                  <label for="is_active" style="margin-left: 15px"
                    >Is Active</label
                  >
                </div>
              </div>

              <button
                type="submit"
                class="btn btn-gmi-primary"
                [disabled]="visualizationForm.invalid"
              >
                {{ isEditMode ? "Update" : "Create" }}
              </button>
              <!-- <button type="button" class="btn btn-secondary">Cancel</button> -->
            </form>
            <div *ngIf="errorMessage" class="alert alert-danger mt-2">
              {{ errorMessage }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
