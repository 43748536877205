<div class="reportMain">
  <!-- <div class="row">
    <div class="col-sm-12">
      <button class="btn btn-primary downloadMasterExcel" (click)="downloadMasterExcel()">Download Master Excel</button>
    </div>
  </div> -->
  <div class="row">
    <div class="col-sm-4">
      <div class="form-group">
        <label for="reportTypeSelect">Report Type:</label>
        <select
          class="form-control"
          id="reportTypeSelect"
          (change)="onReportTypeChange($event)"
          [(ngModel)]="reportType"
        >
          <option value="participantReport">Participants Report</option>
          <option value="moduleComplitionReport">Module Completion</option>
          <option
            value="tierReport"
            *ngIf="companySelectedFeatures.includes('learning_dashboard')"
          >
            Tier-Wise Report
          </option>
          <option
            value="percentileReport"
            *ngIf="companySelectedFeatures.includes('learning_dashboard')"
          >
            Percentile Report
          </option>
          <option value="moduleWiseReport">Module-wise Score Report</option>
          <option
            value="mysteryBoxReport"
            *ngIf="companySelectedFeatures.includes('learning_dashboard')"
          >
            Mystery Box Report
          </option>
          <!-- <option value="summaryReport">Summary Report</option> -->
          <option value="masterReport">Master Report</option>
        </select>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="rightButtonGroup">
        <button
          class="btn gmi-primary-btn downloadExcel"
          (click)="downloadExcel()"
        >
          Download Excel
        </button>
      </div>
    </div>
    <div class="col-sm-4"></div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="form-group">
        <label for="reportTypeSelect">Description:</label>
        <p *ngIf="reportType == 'participantReport'">
          This is a comprehensive report that provides participants' information
          including their demographic details & progress on the portal.
        </p>
        <p *ngIf="reportType == 'moduleComplitionReport'">
          This is a frequency distribution report capturing the percentage of
          participants across various stages of module completion.
        </p>
        <p *ngIf="reportType == 'tierReport'">
          This is a frequency distribution report capturing the percentage of
          participants across various certification tiers.
        </p>
        <p *ngIf="reportType == 'percentileReport'">
          This section provides two types of reports: <br />1) The top
          percentile achievers basis their scores. <br />2) The top percentile
          achievers basis their module completion.
        </p>
        <p *ngIf="reportType == 'moduleWiseReport'">
          This is a module-wise summary report providing information on
          participant score distribution & completion.
        </p>
        <p *ngIf="reportType == 'mysteryBoxReport'">
          This is a report capturing the engagement points earned by
          participants as they unlock the mystery boxes.
        </p>
        <p *ngIf="reportType == 'masterReport'">
          This is a comprehensive report providing all possible
          participant-related data in one file.
        </p>
      </div>
    </div>
  </div>
  <div class="filters">
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label for="reportTypeSelect">Filters:</label>
          <angular2-multiselect
            [data]="dropdownList"
            (onOpen)="onOpen()"
            [(ngModel)]="selectedItems"
            [settings]="dropdownSettings"
            (onSelect)="onItemSelect($event)"
            (onDeSelect)="OnItemDeSelect($event)"
            (onSelectAll)="onSelectAll($event)"
            (onDeSelectAll)="onDeSelectAll($event)"
          >
          </angular2-multiselect>
        </div>
      </div>
      <div class="col-sm-4" *ngIf="reportType != 'masterReport'">
        <button
          class="btn gmi-primary-btn refreshData"
          (click)="onReloadReport()"
        >
          Apply Filters
        </button>
      </div>
      <div class="col-sm-4"></div>
    </div>
    <div class="row">
      <div
        class="col-sm-3"
        *ngFor="let filters of finalDisplayFilters; let i = index"
      >
        <div class="form-group">
          <label for="reportTypeSelect">{{ filters.filterName }}:</label>
          <angular2-multiselect
            [data]="filters.newOptions"
            [(ngModel)]="filters.selectedItems"
            [settings]="dropdownSettings2"
            (onSelect)="onFilterItemSelect($event)"
            (onDeSelect)="onFilterItemDeSelect($event)"
            (onSelectAll)="onFilterSelectAll($event, filters.dbName)"
            (onDeSelectAll)="onFilterDeSelectAll($event, filters.dbName)"
          >
          </angular2-multiselect>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="reportType != 'masterReport'">
    <div class="col-sm-12">
      <div class="form-group">
        <label for="reportTypeSelect">Note:</label>
        <p>
          1) All data in the reports is based on number of registered managers &
          does not include unregistered managers.<br />2) Multiple filters may
          be applied for reports at demographic-levels & their various
          combinations
        </p>
      </div>
    </div>
  </div>
  <app-bounce-loader *ngIf="loading"></app-bounce-loader>
  <div
    class="participatnReportData"
    *ngIf="reportType == 'participantReport' && !loading"
  >
    <div class="table-responsive" *ngIf="participantReportData.length > 0">
      <table class="table">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Email</th>
            <th *ngFor="let filter of selectedItems; let i = index">
              {{ filter.itemName }}
            </th>
            <th style="text-align: center; width: 10%">
              No of modules completed
            </th>
            <th style="text-align: center; width: 10%">Score</th>
            <th
              style="width: 10%"
              *ngIf="companySelectedFeatures.includes('learning_dashboard')"
            >
              Tier
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of participantReportData; let i = index">
            <td>{{ i + 1 }}</td>
            <td>
              {{
                data.personalInfo["name"] != undefined
                  ? data.personalInfo["name"]
                  : "NA"
              }}
            </td>
            <td>
              {{
                data.personalInfo["email"] != undefined
                  ? data.personalInfo["email"]
                  : "NA"
              }}
            </td>
            <td *ngFor="let filter of selectedItems; let i = index">
              {{ fetchParticipantReportColumnData(data, filter) }}
            </td>
            <td style="text-align: center">
              {{
                data.completedModule != undefined ? data.completedModule : "NA"
              }}
            </td>
            <td style="text-align: center">
              {{ data.avgScore != undefined ? data.avgScore.toFixed() : 0 }}
            </td>
            <td *ngIf="companySelectedFeatures.includes('learning_dashboard')">
              {{ data.batch != undefined ? data.batch : "NA" }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="noResultFound" *ngIf="participantReportData.length == 0">
      <h3>No Data Found</h3>
    </div>
  </div>
  <div
    class="moduleComplitionData"
    *ngIf="reportType == 'moduleComplitionReport' && !loading"
  >
    <div class="row">
      <div class="col-sm-6">
        <div
          class="table-responsive"
          *ngIf="moduleComplitionReportData.length > 0"
        >
          <table class="table">
            <thead>
              <tr>
                <th>Percentage of Modules Completed</th>
                <th style="text-align: center">No. of Managers</th>
                <th style="text-align: center">% of Managers</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let data of moduleComplitionReportData; let i = index"
              >
                <td>
                  {{ data["Range of Module Completion"] }}
                  <i
                    style="cursor: pointer"
                    *ngIf="i == 0"
                    matTooltip="Registered but no learning module completed"
                    matTooltipPosition="above"
                    class="fa fa-info-circle"
                    aria-hidden="true"
                  >
                  </i>
                </td>
                <td style="text-align: center">{{ data["#Managers"] }}</td>
                <td style="text-align: center">{{ data["% of Managers"] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="moduleComplitionChart">
          <!-- <div
            id="moduleComplitionChart"
            *ngIf="
              tierChartOptions != undefined &&
              tierChartOptions.chart != undefined &&
              moduleComplitionReportData.length > 0
            "
          > -->
          <!-- <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart"
              [dataLabels]="chartOptions.dataLabels" [plotOptions]="chartOptions.plotOptions"
              [xaxis]="chartOptions.xaxis"></apx-chart> -->
          <!-- <apx-chart
              [series]="tierChartOptions.series"
              [chart]="tierChartOptions.chart"
              [dataLabels]="tierChartOptions.dataLabels"
              [plotOptions]="tierChartOptions.plotOptions"
              [yaxis]="tierChartOptions.yaxis"
              [xaxis]="tierChartOptions.xaxis"
              [fill]="tierChartOptions.fill"
              [title]="tierChartOptions.title"
            ></apx-chart> -->
          <!-- </div> -->
        </div>
      </div>
    </div>
    <div class="noResultFound" *ngIf="moduleComplitionReportData.length == 0">
      <h3>No Data Found</h3>
    </div>
  </div>
  <div class="tierWiseReport" *ngIf="reportType == 'tierReport' && !loading">
    <div class="row">
      <div class="col-sm-6">
        <div class="table-responsive" *ngIf="tierReportData.length > 0">
          <table class="table">
            <thead>
              <tr>
                <th>Tiers</th>
                <th style="text-align: center">No. of Managers</th>
                <th style="text-align: center">% of Managers</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of tierReportData; let i = index">
                <td>{{ data.batch }}</td>
                <td style="text-align: center">{{ data.noOfParticipants }}</td>
                <td style="text-align: center">
                  {{ data.noOfParticipantsPercent }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-sm-6">
        <!-- <div
          id="tierCompletionChart"
          *ngIf="
            tierChartOptions != undefined &&
            tierChartOptions.chart != undefined &&
            tierReportData.length > 0
          "
        >
          <apx-chart
            [series]="tierChartOptions.series"
            [chart]="tierChartOptions.chart"
            [dataLabels]="tierChartOptions.dataLabels"
            [plotOptions]="tierChartOptions.plotOptions"
            [yaxis]="tierChartOptions.yaxis"
            [xaxis]="tierChartOptions.xaxis"
            [fill]="tierChartOptions.fill"
            [colors]="tierChartOptions.colors"
            [title]="tierChartOptions.title"
          ></apx-chart>
        </div> -->
      </div>
    </div>
    <div class="noResultFound" *ngIf="tierReportData.length == 0">
      <h3>No Data Found</h3>
    </div>
  </div>
  <div
    class="percentileReport"
    *ngIf="reportType == 'percentileReport' && !loading"
  >
    <div class="tabsSection">
      <ul class="nav nav-tabs">
        <li class="active">
          <a data-toggle="tab" href="#topLearner">
            <span>Top Learner</span>
          </a>
        </li>
        <li>
          <a data-toggle="tab" href="#topEngaged">
            <span>Top Engaged</span>
          </a>
        </li>
      </ul>
    </div>
    <div class="tab-content">
      <div id="topEngaged" class="tab-pane fade animated fadeIn">
        <div class="table-responsive" *ngIf="topLearningReportData.length > 0">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Email</th>
                <th>Segment</th>
                <th style="text-align: center">Percentile</th>
                <th style="text-align: center">Modules Completed</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let rankList of topEngagedReportData; let i = index">
                <td style="width: 10%">
                  {{ i + 1 }}
                </td>
                <td>{{ rankList.personalInfo.name }}</td>
                <td>{{ rankList.personalInfo.email }}</td>
                <td>
                  {{
                    rankList.personalInfo.otherCompanyInfo != undefined &&
                    rankList.personalInfo.otherCompanyInfo.segment != undefined
                      ? rankList.personalInfo.otherCompanyInfo.segment
                      : "NA"
                  }}
                </td>
                <td style="text-align: center">
                  {{ rankList.percentile.toFixed() }}
                </td>
                <td style="text-align: center">
                  {{ rankList.completedModule }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="noResultFound" *ngIf="topEngagedReportData.length == 0">
          <h3>No Data Found</h3>
        </div>
      </div>
      <div id="topLearner" class="tab-pane fade in active animated fadeIn">
        <div class="table-responsive" *ngIf="topLearningReportData.length > 0">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Email</th>
                <th>Segment</th>
                <th style="text-align: center">Pecentile</th>
                <th style="text-align: center">Score</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let rankList of topLearningReportData; let i = index">
                <td>
                  {{ i + 1 }}
                </td>

                <td>{{ rankList.personalInfo.name }}</td>
                <td>{{ rankList.personalInfo.email }}</td>
                <td>
                  {{
                    rankList.personalInfo.otherCompanyInfo != undefined &&
                    rankList.personalInfo.otherCompanyInfo.segment != undefined
                      ? rankList.personalInfo.otherCompanyInfo.segment
                      : "NA"
                  }}
                </td>
                <td style="text-align: center">
                  {{ rankList.percentile.toFixed() }}
                </td>
                <td style="text-align: center">
                  {{ rankList.avgScore.toFixed() }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="noResultFound" *ngIf="topLearningReportData.length == 0">
          <h3>No Data Found</h3>
        </div>
      </div>
    </div>
  </div>
  <div
    class="moduleWiseReport"
    *ngIf="reportType == 'moduleWiseReport' && !loading"
  >
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label for="reportTypeSelect">Type:</label>
          <select
            class="form-control"
            id="reportTypeSelect"
            (change)="changeModuleReportType($event)"
            [(ngModel)]="moduleWiseReportType"
          >
            <option value="module">Module-wise Report</option>
            <option value="theme">Dimension-wise Report</option>
          </select>
        </div>
      </div>
      <div class="col-sm-4"></div>
      <div class="col-sm-4">
        <button
          type="button"
          class="btn btn-lg btn-toggle"
          data-toggle="button"
          aria-pressed="true"
          autocomplete="off"
          (click)="toggleGraph()"
        >
          <div class="handle"></div>
        </button>
      </div>
    </div>
    <div
      class="table-responsive"
      *ngIf="!graphToggle && moduleWiseReportData.length > 0"
    >
      <table class="table">
        <thead>
          <tr>
            <th>Dimensions</th>
            <th *ngIf="moduleWiseReportType == 'module'">L&D Module Name</th>
            <th style="text-align: center">No. of Managers</th>
            <th style="text-align: center">Avg Score</th>
            <th style="text-align: center">Min Score</th>
            <th style="text-align: center">Max Score</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of moduleWiseReportData; let i = index">
            <td>{{ data.theme != undefined ? data.theme : 0 }}</td>
            <td *ngIf="moduleWiseReportType == 'module'">
              {{ data.displayText }}
            </td>
            <td style="text-align: center">
              {{
                data.noOfManager != undefined &&
                data.noOfManager != "" &&
                data.noOfManager != null
                  ? data.noOfManager.toFixed()
                  : 0
              }}
            </td>
            <td style="text-align: center">
              {{
                data.avgScore != undefined &&
                data.avgScore != "" &&
                data.avgScore != null
                  ? data.avgScore.toFixed()
                  : 0
              }}
            </td>
            <td style="text-align: center">
              {{
                data.minScore != undefined &&
                data.minScore != "" &&
                data.minScore != null
                  ? data.minScore.toFixed()
                  : 0
              }}
            </td>
            <td style="text-align: center">
              {{
                data.maxScore != undefined &&
                data.maxScore != "" &&
                data.maxScore != null
                  ? data.maxScore.toFixed()
                  : 0
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="noResultFound" *ngIf="moduleWiseReportData.length == 0">
      <h3>No Data Found</h3>
    </div>
    <div
      class="graphView"
      *ngIf="graphToggle && moduleWiseReportData.length > 0"
    >
      <!-- <div
        id="moduleWiseChart"
        *ngIf="
          moduleWiseChartOptions != undefined &&
          moduleWiseChartOptions.chart != undefined
        "
      >
        <apx-chart
          [series]="moduleWiseChartOptions.series"
          [chart]="moduleWiseChartOptions.chart"
          [dataLabels]="moduleWiseChartOptions.dataLabels!"
          [plotOptions]="moduleWiseChartOptions.plotOptions"
          [yaxis]="moduleWiseChartOptions.yaxis"
          [legend]="moduleWiseChartOptions.legend"
          [fill]="moduleWiseChartOptions.fill"
          [stroke]="moduleWiseChartOptions.stroke"
          [tooltip]="moduleWiseChartOptions.tooltip"
          [xaxis]="moduleWiseChartOptions.xaxis"
        ></apx-chart>
      </div> -->
    </div>
  </div>
  <div
    class="mysteryBoxReport"
    *ngIf="reportType == 'mysteryBoxReport' && !loading"
  >
    <div class="table-responsive" *ngIf="mysteryBoxReportData.length > 0">
      <table class="table">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Email</th>
            <th style="text-align: center">No. of Modules completed</th>
            <th style="text-align: center">Engagement Points</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of mysteryBoxReportData; let i = index">
            <td>{{ i + 1 }}</td>
            <td>{{ data.personalInfo.name }}</td>
            <td>{{ data.personalInfo.email }}</td>
            <td style="text-align: center">{{ data.noOfModules }}</td>
            <td style="text-align: center">{{ data.rewardPoints }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="noResultFound" *ngIf="mysteryBoxReportData.length == 0">
      <h3>No Data Found</h3>
    </div>
  </div>
</div>
