<div class="main-box">
  <form [formGroup]="surveyPageForm">
    <div class="row align-items-center">
      <div class="col-md-4">
        <div class="form-group pb-10">
          <label>Page Name<span class="asterick">*</span></label>
          <input
            class="form-control custom-form-control"
            required
            id="name"
            name="name"
            type="text"
            formControlName="name"
            appNoSpaces
            placeholder="Enter name name..."
          />
          <p
            class="error"
            *ngIf="
              surveyPageForm.get('name').hasError('required') &&
              surveyPageForm.get('name').touched
            "
          >
            Page Text is required.
          </p>
          <p
            class="error"
            *ngIf="
              surveyPageForm.get('name').hasError('minlength') &&
              surveyPageForm.get('name').touched
            "
          >
            Page must be at least 5 characters long.
          </p>
          <p
            class="error"
            *ngIf="
              surveyPageForm.get('name').hasError('maxlength') &&
              surveyPageForm.get('name').touched
            "
          >
            Page Text cannot be more than 20 characters long.
          </p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="pb-10 mt-5">
          <label>Active Status<span class="asterick">*</span></label>
          <br />
          <mat-slide-toggle
            formControlName="is_active"
            [checked]="surveyPageForm.value.is_active == 1 ? true : false"
            >{{
              surveyPageForm.value.is_active == 1 ? "Active" : "Inactive"
            }}</mat-slide-toggle
          >
        </div>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-md-4">
        <div class="pb-10 mt-5">
          <label>Feature Status<span class="asterick">*</span></label>
          <br />
          <mat-slide-toggle
            formControlName="status"
            [checked]="surveyPageForm.value.status == 1 ? true : false"
            >{{
              surveyPageForm.value.status == 1 ? "ON" : "OFF"
            }}</mat-slide-toggle
          >
        </div>
      </div>
    </div>
    <div class="" style="display: flex; justify-content: center; gap: 20px">
      <div>
        <button
          type="button"
          style="margin-top: 25px; color: white"
          class="btn btn-secondary btn-newuser-clr"
          [disabled]="surveyPageForm.invalid"
          (click)="createCustomPage(null)"
        >
          {{ isUpdate ? "Update" : "Create" }}
        </button>
      </div>
      <div>
        <button
          *ngIf="isUpdate"
          type="button"
          style="margin-top: 25px; color: white"
          class="btn btn-secondary btn-newuser-clr"
          (click)="surveyPageForm.reset(); isUpdate = false"
        >
          Add New
        </button>
      </div>
    </div>
  </form>

  <div class="text-center mt-30" *ngIf="surveyFillerMapping.length === 0">
    <h2>Not Found any Survey Pages.</h2>
  </div>

  <div class="mt-30">
    <p class="error">
      If all the pages are off, this feature will be disabled.
    </p>
    <mat-accordion class="example-headers-align" multi>
      <mat-expansion-panel
        *ngFor="let panel of surveyFillerMapping; let i = index"
      >
        <mat-expansion-panel-header>
          <mat-panel-title class="fontBold">
            {{ i + 1 }}. {{ panel.name }}
            <div style="margin-left: 20px">
              <mat-slide-toggle
                [(ngModel)]="panel.status"
                (change)="statusChange($event, panel, i)"
                matTooltip="You can change the status of the panel to on/off the feature."
              >
              </mat-slide-toggle>
            </div>
            <div
              class="chip"
              style="margin-left: 10px"
              [ngStyle]="{
                'background-color': panel.status == 1 ? '#D3FCD2' : '#FFAC82',
                color: panel.status == 1 ? '#118D57' : '#B71D18'
              }"
            >
              {{ panel.status == 1 ? "On" : "Off" }}
            </div>
          </mat-panel-title>
          <div style="margin-right: 20px">
            <mat-slide-toggle
              [(ngModel)]="panel.is_active"
              [disabled]="checkIfAnyPageisActive() && panel.is_active == 0"
              (change)="activeStatusChange($event, panel, i)"
              [checked]="panel.is_active == 1 ? true : false"
            >
            </mat-slide-toggle>
          </div>

          <div
            class="chip"
            [ngStyle]="{
              'background-color': panel.is_active == 1 ? '#D3FCD2' : '#FFAC82',
              color: panel.is_active == 1 ? '#118D57' : '#B71D18'
            }"
          >
            {{ panel.is_active == 1 ? "Active" : "Inactive" }}
          </div>

          <i
            (click)="patchValues(panel)"
            class="fa fa-pencil-square-o"
            style="margin-right: 20px"
            aria-hidden="true"
            matTooltip="Update Survey Page Name"
          ></i>
        </mat-expansion-panel-header>

        <div class="mt-20 row">
          <div class="col-md-4">
            <div class="form-group pb-10">
              <label for=""
                >Select Filler Type <span class="asterick">*</span></label
              >
              <select
                class="form-control custom-form-control"
                [(ngModel)]="surveyContentPagesData.currentFillerType"
                (change)="changeFillerType($event)"
              >
                <option value="" selected>Select</option>
                <option
                  [value]="type.value"
                  *ngFor="
                    let type of surveyContentPagesData.fillerTypes;
                    let i = index
                  "
                >
                  {{ type.type }}
                </option>
              </select>
            </div>
          </div>
          <div
            class="col-md-3"
            *ngIf="surveyContentPagesData.currentFillerType == 'survey'"
          >
            <button
              type="button"
              style="margin-top: 25px; color: white"
              class="btn btn-secondary btn-newuser-clr"
              (click)="
                addCurrentFiller(
                  i,
                  panel.id,
                  surveyContentPagesData.currentFillerType
                )
              "
            >
              Add Survey Question
            </button>
          </div>
          <div
            class="col-md-4"
            *ngIf="surveyContentPagesData.currentFillerType == 'filler'"
          >
            <div class="form-group pb-10">
              <label for=""
                >Select Filler <span class="asterick">*</span></label
              >
              <select
                class="form-control custom-form-control"
                required
                (change)="selectFiller($event)"
              >
                <option value="" selected>Select</option>
                <option
                  [value]="filler.id"
                  *ngFor="
                    let filler of surveyContentPagesData.surveyContentPagesArray;
                    let i = index
                  "
                >
                  {{ filler.description }}
                </option>
              </select>
            </div>
          </div>
          <div
            class="col-md-4"
            *ngIf="
              surveyContentPagesData.currentFillerType == 'filler' &&
              surveyContentPagesData.currentFillerData
            "
          >
            <div
              style="
                background-color: white;
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
                padding: 10px;
              "
            >
              <p class="">
                Content Type:
                <span class="fontBold">{{
                  surveyContentPagesData.currentFillerData.content_type
                    | titlecase
                }}</span>
              </p>
              <p class="">
                Message/Description:
                <span class="fontBold">
                  {{
                    surveyContentPagesData.currentFillerData.description
                  }}</span
                >
              </p>
              <p class="">
                Button Text:
                <span class="fontBold">
                  {{
                    surveyContentPagesData.currentFillerData.button_text
                  }}</span
                >
              </p>
              <div class="filler-add-card">
                <div>
                  <button
                    type="button"
                    style="color: white"
                    class="btn btn-secondary btn-newuser-clr"
                    [disabled]="checkIfFillerDataEmpty()"
                    (click)="
                      addCurrentFiller(
                        i,
                        panel.id,
                        surveyContentPagesData.currentFillerType
                      )
                    "
                  >
                    Add
                    <i class="fa fa-plus-square" aria-hidden="true"></i>
                  </button>
                </div>
                <div>
                  <button
                    class="preview-button"
                    [disabled]="checkIfFillerDataEmpty()"
                    (click)="viewModal(viewModalModal)"
                  >
                    View
                    <i class="fa fa-eye" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p class="error mt-20">
            Note: You can drag and drop card to change the position.
          </p>
        </div>
        <div
          (cdkDropListDropped)="dragAndDrop($event, i)"
          cdkDropList
          class="mt-30"
        >
          <div
            cdkDrag
            [cdkDragData]="filler"
            *ngFor="let filler of panel.surveySequenceData; let j = index"
          >
            <div class="filler-card" *ngIf="filler.contentType != 'survey'">
              <div>
                <p class="fillerTitle">Position</p>
                <p class="fillerSecondary">{{ j + 1 }}</p>
                <div>
                  <mat-slide-toggle
                    (change)="fillerStatusChange($event, i, j)"
                    matTooltip="Activate/Deactivate"
                    [checked]="filler.is_active == 1 ? true : false"
                    >{{
                      filler.is_active == 1 ? "Active" : "Inactive"
                    }}</mat-slide-toggle
                  >
                </div>
              </div>
              <div>
                <p class="fillerTitle">Content Type</p>
                <p class="fillerSecondary">
                  {{ filler.contentType | titlecase }}
                </p>
              </div>
              <div>
                <p class="fillerTitle">Description</p>
                <p class="fillerSecondary">{{ filler.description }}</p>
              </div>
              <div>
                <p class="fillerTitle">Button Text</p>
                <p class="fillerSecondary">{{ filler.buttonText }}</p>
              </div>
              <div
                *ngIf="
                  filler.contentType == 'image' || filler.contentType == 'text'
                "
              >
                <p class="fillerTitle">Image</p>
                <img
                  oncontextmenu="return false;"
                  style="
                    max-width: 100px;
                    max-height: 50px;
                    border-radius: 5%;
                    border: 1px solid #ccc;
                  "
                  width="100px"
                  alt=""
                  [src]="getImageUrl(filler.url)"
                />
              </div>
              <div *ngIf="filler.contentType == 'video'">
                <p class="fillerTitle">Video</p>
                <video
                  style="
                    max-width: 100px;
                    max-height: 50px;
                    border-radius: 5%;
                    border: 1px solid #ccc;
                  "
                  width="100px"
                  oncontextmenu="return false;"
                  autoplay
                  controls
                  controlsList="nodownload"
                >
                  <source [src]="getImageUrl(filler.url)" type="video/mp4" />
                </video>
              </div>
            </div>
            <div class="filler-card" *ngIf="filler.contentType == 'survey'">
              <div>
                <p class="fillerTitle">Position</p>
                <p class="fillerSecondary">{{ j + 1 }}</p>
              </div>
              <div>
                <p class="blinking-text">
                  {{ filler.description }}
                </p>
              </div>
              <div></div>
            </div>
          </div>
        </div>
        <div
          class="text-center"
          *ngIf="
            panel.surveySequenceData && panel.surveySequenceData?.length == 0
          "
        >
          <h2>Not Found any Survey Fillers.</h2>
        </div>
        <div class="text-center">
          <button
            type="button"
            style="margin-top: 25px; color: white"
            class="btn btn-secondary btn-newuser-clr"
            (click)="submitMapping(i)"
            [matTooltip]="
              panel?.surveySequenceData?.length == 0
                ? 'Add Survey Fillers to Save.'
                : 'Save'
            "
            [disabled]="panel.surveySequenceData?.length == 0"
          >
            Save
          </button>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<ng-template #viewModalModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Filler Content Details</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="modalBody">
      <div class="fillerContent">
        <div class="content">
          <img
            oncontextmenu="return false;"
            *ngIf="
              surveyContentPagesData.currentFillerData?.url &&
              surveyContentPagesData.currentFillerData.content_type == 'image'
            "
            [src]="IMAGE_URL + surveyContentPagesData.currentFillerData.url"
            alt=""
          />
          <video
            oncontextmenu="return false;"
            autoplay
            controls
            controlsList="nodownload"
            *ngIf="
              surveyContentPagesData.currentFillerData?.url &&
              surveyContentPagesData.currentFillerData.content_type == 'video'
            "
          >
            <source
              [src]="IMAGE_URL + surveyContentPagesData.currentFillerData.url"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
        <p class="message">
          {{ surveyContentPagesData.currentFillerData.description }}
        </p>
      </div>
    </div>
  </div>
</ng-template>
