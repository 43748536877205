import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { AdminServices } from "../../admin.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";

import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Observable } from "rxjs";
import { map } from "rxjs/internal/operators/map";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

export interface ComponentDetails {
  id: number;
  component_name: string;
  is_active: number;
}

@Component({
  selector: "app-sub-component",
  templateUrl: "./sub-component.component.html",
  styleUrls: ["./sub-component.component.css"],
})
export class SubComponentComponent implements OnInit {
  public subComponentForm: FormGroup;

  dataSource = new MatTableDataSource<ComponentDetails>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  currentIndex: any;
  modalRef: BsModalRef;
  componentOptions: any;
  constructor(
    private _snackBar: MatSnackBar,
    private adminService: AdminServices,
    private formBuilder: FormBuilder,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.getSubComponentData();
    this.initComponent();
    this.getComponentData();
  }
  displayedColumns = [
    "serialNo",
    "SubComponent",
    "defaultName",
    "defaultToolTip",
    "actions",
  ];
  isEdit: boolean = false;
  activeStatus: boolean;
  public subThemeForm: FormGroup;

  getSubComponentData() {
    this.adminService.getSubComponentData().subscribe((response: any) => {
      if (response.status) {
        this.dataSource.data = response.data;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      } else {
        console.error("Failed to fetch Sub Component:", response.message);
      }
    });
  }

  getComponentData() {
    this.adminService.getComponentData().subscribe({
      next: (response: any) => {
        if (response.status) {
          this.componentOptions = response.data;
        } else {
          console.error("Failed to fetch pages:", response.message);
        }
      },
      error: (error) => {
        console.error("Error fetching pages:", error);
      },
    });
  }

  initComponent() {
    this.subComponentForm = this.formBuilder.group({
      _id: new FormControl("", []),
      subComponentName: new FormControl("", [
        Validators.required,
        Validators.maxLength(45),
      ]),
      isActive: new FormControl("", []),
      componentId: new FormControl("", []),
      defaultName: new FormControl("", [Validators.maxLength(45)]),
      defaultTooltip: new FormControl("", [Validators.maxLength(350)]),
    });
  }

  componentModal(
    template: TemplateRef<any>,
    subComponentData: any,
    isEdit: boolean,
    index
  ) {
    this.isEdit = isEdit;
    this.currentIndex = index;
    if (this.isEdit) {
      this.currentIndex = index;
      const selectComponent = this.componentOptions.find(
        (component) => component.id == subComponentData.component_id
      );
      const componentId = selectComponent ? selectComponent.id : null;
      this.subComponentForm.patchValue({
        _id: subComponentData.id,
        subComponentName: subComponentData.sub_component_name,
        componentId: componentId,
        defaultName: subComponentData.default_name,
        defaultTooltip: subComponentData.default_tooltip_desc,
      });
    } else {
      this.subComponentForm.reset();
    }
    this.modalRef = this.modalService.show(template);
    this.adminService.addModal(this.modalRef);
  }

  closeModal() {
    this.modalService.hide();
  }

  addSubComponentName() {
    if (this.subComponentForm.valid) {
      const subComponentData = { ...this.subComponentForm.value };
      const requestData = {
        subComponentData,
        identifier: "create",
      };
      this.adminService.createSubComponent(requestData).subscribe({
        next: (response) => {
          this.subComponentForm.reset();
          this.getSubComponentData();
          this.openSnackBar(response.message, "success");

          this.closeModal();
        },
        error: (error) => {
          console.error(error);
          this.openSnackBar("Failed to create Sub Component", "error");
          this.closeModal();
        },
      });
    } else {
      this.openSnackBar("Please fill the name", "error");
    }
  }
  openSnackBar(message, status) {
    this._snackBar.open(message, "", {
      duration: 3000,
      panelClass: status === "success" ? "green-snackbar" : "red-snackbar",
      horizontalPosition: "right",
      verticalPosition: "top",
    });
  }

  // isActive(_id: any, event) {
  //   const data = {
  //     identifier: "delete",
  //     subComponentId: _id,
  //     isActive: event.checked ? 1 : 0,
  //   };

  //   this.adminService.isActiveSubComponent(data).subscribe({
  //     next: (response) => {
  //       this.openSnackBar(response.message, "success");
  //     },
  //     error: (error) => {
  //       this.openSnackBar(error.message, "error");
  //     },
  //   });
  // }

  editSubComponent(subComponentId: any) {
    const subComponentData = { ...this.subComponentForm.value };
    const requestData = {
      subComponentData,
      identifier: "update",
      subComponentId: subComponentId,
    };

    this.adminService.editSubComponent(requestData).subscribe({
      next: (response) => {
        this.modalService.hide();
        this.openSnackBar(response.message, "success");

        this.getSubComponentData();
        this.closeModal();
        this.subComponentForm.reset();
      },
      error: (error) => {
        this.openSnackBar(error.message, "error");
        this.closeModal();
      },
    });
  }
  searchSubComponent(event: any) {
    const value = event.target.value;
    let filterValue = value.trim().toLowerCase();
    this.dataSource.filter = filterValue;
  }
}
