import { Component, OnInit } from "@angular/core";
import { CompanyServices } from "../../company.service";
import * as XLSX from "xlsx";
import { ExcelService } from "../../../excel-service.service";

@Component({
  selector: "app-action-plan-reports",
  templateUrl: "./action-plan-reports.component.html",
  styleUrls: ["./action-plan-reports.component.scss"],
})
export class ActionPlanReportsComponent implements OnInit {
  reportType: String = "actionPlan";
  actionPlanReportData: any = [];
  actionPlanFeedbackData: any = [];
  implementationData: any = [];
  loading: Boolean = false;
  dropdownList: any = [];
  selectedItems = [];
  dropdownSettings = {};
  dropdownSettings2 = {};
  allPossibleFilters: any = [];
  finalDisplayFilters: any = [];
  constructor(
    private companyService: CompanyServices,
    private excelService: ExcelService
  ) {}

  ngOnInit() {
    // this.loadReport(this.reportType);
    this.getPossibleFilters();
    this.dropdownSettings = {
      singleSelection: false,
      text: "Select Filters",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableSearchFilter: true,
      classes: "fieldFilterDropdown",
      maxHeight: 160,
    };
    this.dropdownSettings2 = {
      singleSelection: false,
      text: "Select Filters",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableSearchFilter: true,
      classes: "fieldFilterDropdown",
      maxHeight: 160,
    };
  }

  getActionPlanReport(params: any = {}, downloadExcel = false) {
    this.loading = true;
    params["filters"] = this.finalDisplayFilters;
    this.companyService.getAp(params).subscribe((res: any) => {
      this.actionPlanReportData = res.data;
      this.implementationData = res.impl;
      this.loading = false;
      if (downloadExcel) {
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        const ws1 = XLSX.utils.json_to_sheet(this.actionPlanReportData);
        const ws2 = XLSX.utils.json_to_sheet(this.implementationData);
        XLSX.utils.book_append_sheet(wb, ws1, "Action Plans");
        XLSX.utils.book_append_sheet(wb, ws2, "Implementation Update");
        this.excelService.exportAsExcelFile(wb, `Action Plan Report.xlsx`);
      }
    });
  }

  downloadExcel() {
    switch (this.reportType) {
      case "actionPlan":
        this.getActionPlanReport({}, true);
        break;
      case "feedBack":
        this.getApFeedbackReports({}, true);
        break;
    }
  }

  getApFeedbackReports(params: any = {}, downloadExcel = false) {
    this.loading = true;
    params["filters"] = this.finalDisplayFilters;
    this.companyService.getApReports(params).subscribe((res: any) => {
      this.loading = false;

      if (res.status) {
        this.actionPlanFeedbackData = res.data;
        if (downloadExcel) {
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.data);
          const ws1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.data1);
          const ws2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.data2);
          const ws3: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.data3);
          const ws4: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.data4);
          XLSX.utils.book_append_sheet(
            wb,
            ws,
            "Interim Feedback - Team Members"
          );
          XLSX.utils.book_append_sheet(
            wb,
            ws1,
            "Score from Team Feedback Survey"
          );
          XLSX.utils.book_append_sheet(
            wb,
            ws2,
            "Interim Feedback - Supervisors"
          );
          XLSX.utils.book_append_sheet(wb, ws3, "Action Plan Only");
          XLSX.utils.book_append_sheet(wb, ws4, "Score from Supervisor Survey");
          this.excelService.exportAsExcelFile(wb, "Action plan Feedback.xlsx");
        }
      }
    });
  }

  loadReport(reportType: any) {
    switch (reportType) {
      case "actionPlan":
        this.getActionPlanReport();
        break;
      case "feedBack":
        this.getApFeedbackReports();
        break;
    }
  }

  onReportTypeChange(evt: any) {
    this.reportType = evt.target.value;
    this.loadReport(evt.target.value);
  }

  returnZero() {
    return 0;
  }

  getPossibleFilters() {
    this.loading = true;
    this.companyService.getAllPossibleFilters().subscribe((res: any) => {
      if (res.status) {
        this.loading = false;
        let data = res.data;
        data.forEach((filter: any) => {
          if (filter.options.length > 0) {
            this.dropdownList.push({
              id: filter.dbName,
              itemName: filter.filterName,
            });
            this.allPossibleFilters.push(filter);
          }
        });
      }
    });
  }

  onItemSelect(item: any) {
    let newObject: any = {};
    this.allPossibleFilters.forEach((filter: any) => {
      if (filter.dbName == item.id) {
        newObject = filter;
        newObject["selectedItems"] = [];
        newObject["newOptions"] = [];
        filter.options.map((option: any) => {
          newObject["newOptions"].push({
            id: option,
            itemName: option,
          });
        });
        this.finalDisplayFilters.push(newObject);
      }
    });
  }

  OnItemDeSelect(item: any) {
    this.finalDisplayFilters = this.finalDisplayFilters.filter(
      (filter: any) => {
        return filter.dbName != item.id;
      }
    );
  }

  onSelectAll(items: any) {
    this.selectedItems = items;
    let alreadyIncluded: any = [];
    let newObject: any = {};
    this.finalDisplayFilters.map((already: any) => {
      alreadyIncluded.push(already.dbName);
    });
    this.selectedItems.forEach((item: any) => {
      this.allPossibleFilters.forEach((filter: any) => {
        if (filter.dbName == item.id && !alreadyIncluded.includes(item.id)) {
          newObject = filter;
          newObject["selectedItems"] = [];
          newObject["newOptions"] = [];
          filter.options.map((option: any) => {
            newObject["newOptions"].push({
              id: option,
              itemName: option,
            });
          });
          this.finalDisplayFilters.push(newObject);
        }
      });
    });
  }

  onDeSelectAll(items: any) {
    this.selectedItems = items;
    this.finalDisplayFilters = [];
  }

  onFilterItemSelect(item: any) {}

  onFilterItemDeSelect(item: any) {}

  onFilterSelectAll(items: any, itemId: any) {
    this.finalDisplayFilters.map((filter: any, index: any) => {
      if (filter.dbName == itemId) {
        this.finalDisplayFilters[index]["selectedItems"] = items;
      }
    });
  }

  onFilterDeSelectAll(items: any, itemId: any) {
    this.finalDisplayFilters.map((filter: any, index: any) => {
      if (filter.dbName == itemId) {
        this.finalDisplayFilters[index]["selectedItems"] = items;
      }
    });
  }

  onReloadReport() {
    this.loadReport(this.reportType);
  }

  onOpen() {
    this.dropdownList = [...this.dropdownList];
  }
  getAttachments(value: any): string[] {
    if (typeof value === "string") {
      return value.split(",");
    } else {
      return [];
    }
  }
}
