import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { io, Socket } from "socket.io-client"; // Correct for v2.5
import { environment } from "../../../environments/environment";
import { AdminServices } from "../admin.service";
import * as XLSX from "xlsx";
import { constants } from "src/app/constants";

@Component({
  selector: "app-toxicity-analysis",
  templateUrl: "./toxicity-analysis.component.html",
  styleUrls: ["./toxicity-analysis.component.css"],
})
export class ToxicityAnalysisComponent implements OnInit {
  private socket: Socket;
  statusMessages: string[] = [];
  allAnalysisType: any = [];
  private imageRecognitionUrl = environment.imageRecognitionUrl;
  demoDataLink = `${constants.MEDIA_URL}/assests/sheets/uat/analysis/input/toxicity/demofile_toxicity2.xlsx`;
  uploadedFiles: File[] = [];
  allAnalysedFiles: any;
  filteredFiles: any;
  searchText = "";
  currentPage = 1;
  itemsPerPage = 10;
  totalPages = 0;
  paginatedFiles: any;
  paginationPages: number[];
  constructor(
    private toastr: ToastrService,
    private adminService: AdminServices
  ) {}

  ngOnInit(): void {
    this.socket = io(this.imageRecognitionUrl);
    this.getToxicityFiles();
    this.socket.on("connect", () => {
      this.statusMessages.push("Connected to Analysis server");
    });

    this.socket.on("toxicity_status", (data: { message: string }) => {
      this.statusMessages.unshift(data.message);
      if (data.message.toLowerCase() === "complete") {
        this.toastr.success("Analysis complete. Refreshing the page...");
        setTimeout(() => {
          window.location.reload(); // Refresh the page
        }, 1000); // Optional delay for user visibility
      } else if (data.message.toLowerCase() === "error") {
        this.toastr.error("Analysis cannot be complete. Retry again");
        setTimeout(() => {
          window.location.reload(); // Refresh the page
        }, 1000); // Optional delay for user visibility
      }
    });

    this.socket.on("disconnect", () => {
      this.statusMessages.push("Disconnected from analysis server");
    });
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    const files = event.dataTransfer?.files;
    if (files) {
      this.handleFiles(files);
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  handleFiles(files: FileList): void {
    for (const file of Array.from(files)) {
      if (file.size <= 15 * 1024 * 1024) {
        this.uploadedFiles.push(file);
      } else {
        alert(`File "${file.name}" exceeds the 15MB size limit.`);
      }
    }
  }

  onFileSelected(event: any): void {
    const files = event.target.files;
    if (files.length === 0) return;

    const file = files[0];
    const fileReader = new FileReader();

    fileReader.onload = () => {
      const data = new Uint8Array(fileReader.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: "array" });

      // Validate 'Manager Scores' tab
      const managerScoresSheet = workbook.Sheets["Manager Scores"];
      if (!managerScoresSheet) {
        this.toastr.error("Missing 'Manager Scores' tab in the file.");
        return;
      }

      const managerScoresData = XLSX.utils.sheet_to_json(managerScoresSheet, {
        header: 1,
      });
      const managerScoresHeaders: string[] = (
        managerScoresData[0] as string[]
      ).map((header) => header.trim().toLowerCase());

      // Required headers for 'Manager Scores'
      const requiredManagerScoresHeaders = [
        "manager name",
        "manager email",
        "overall",
        "saas score",
        "approachability",
        "conflict management",
        "fair treatment",
        "involvement in decisions",
        "listening",
        "respectful",
        "accountability",
        "openness to ideas",
      ].map((header) => header.toLowerCase());

      const missingManagerScoresHeaders = requiredManagerScoresHeaders.filter(
        (header) => !managerScoresHeaders.includes(header)
      );

      if (missingManagerScoresHeaders.length > 0) {
        this.toastr.error(
          `Missing headers in 'Manager Scores': ${missingManagerScoresHeaders.join(
            ", "
          )}`
        );
        return;
      }

      // Validate 'Comments' tab
      const commentsSheet = workbook.Sheets["Comments"];
      if (!commentsSheet) {
        this.toastr.error("Missing 'Comments' tab in the file.");
        return;
      }

      const commentsData = XLSX.utils.sheet_to_json(commentsSheet, {
        header: 1,
      });
      const commentsHeaders: string[] = (commentsData[0] as string[]).map(
        (header) => header.trim().toLowerCase()
      );

      // Required headers for 'Comments'
      const requiredCommentsHeaders = [
        "manager name",
        "manager email",
        "respondent name",
        "respondent email",
        "suggestions",
      ].map((header) => header.toLowerCase());

      const missingCommentsHeaders = requiredCommentsHeaders.filter(
        (header) => !commentsHeaders.includes(header)
      );

      if (missingCommentsHeaders.length > 0) {
        this.toastr.error(
          `Missing headers in 'Comments': ${missingCommentsHeaders.join(", ")}`
        );
        return;
      }

      // Validate rows in 'Manager Scores'
      const invalidManagerScoresRows: string[] = [];
      for (let i = 1; i < managerScoresData.length; i++) {
        const row = managerScoresData[i];
        requiredManagerScoresHeaders.forEach((header, index) => {
          const value = row[index];
          if (Number(value) < 0) {
            invalidManagerScoresRows.push(
              `Row ${
                i + 1
              }: Incorrect value for '${header}' in 'Manager Scores'.`
            );
          }
        });
      }

      if (invalidManagerScoresRows.length > 0) {
        this.toastr.error(
          `Validation failed for 'Manager Scores':\n${invalidManagerScoresRows.join(
            "\n"
          )}`
        );
        return;
      }

      // If all validations pass
      this.uploadedFiles.push(file); // Add the file to the list
      this.toastr.success("File validated successfully!");
    };

    fileReader.readAsArrayBuffer(file);
  }

  removeFile(index: number): void {
    this.uploadedFiles.splice(index, 1);
  }

  getAnalysisTypes() {
    this.adminService.getAnalysisTypes().subscribe((res: any) => {
      if (res.status) {
        this.allAnalysisType = res.info;
      } else {
        this.toastr.error(res.message ? res.message : "Cannot find data");
      }
    });
  }

  triggerAnalysis(): void {
    if (this.uploadedFiles.length === 0) {
      this.toastr.error("No files to upload.");
      return;
    }

    const formData: FormData = new FormData();
    const file = this.uploadedFiles[0];
    formData.append("file", file);
    formData.append("analysisTypeId", "3"); // Add the analysisTypeId

    this.adminService.analyseToxicity(formData).subscribe((response: any) => {
      if (response.status) {
        this.toastr.success(`Analysis started`);
      } else {
        this.toastr.error(`Analysis Cannot be started`);
      }
    });
  }

  getToxicityFiles() {
    const toxicityId = 3;
    this.adminService.getAnalysisFilesById(toxicityId).subscribe((res: any) => {
      if (res.status) {
        this.allAnalysedFiles = res.info;
        this.filterTable();
      } else {
        this.toastr.error(res.message ? res.message : "Something went wrong");
      }
    });
  }

  filterTable(): void {
    this.filteredFiles = this.allAnalysedFiles.filter((file) =>
      this.extractFileName(file.input_file)
        .toLowerCase()
        .includes(this.searchText.toLowerCase())
    );
    this.calculateTotalPages();
    this.updatePagination();
  }
  extractFileName(url: string): string {
    return url.split("/").pop() || "Unknown File";
  }

  updatePagination(): void {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.paginatedFiles = this.filteredFiles.slice(startIndex, endIndex);
  }

  // Calculate total pages
  calculateTotalPages(): void {
    this.totalPages = Math.ceil(this.filteredFiles.length / this.itemsPerPage);
    this.paginationPages = Array.from(
      { length: this.totalPages },
      (_, i) => i + 1
    );
  }

  goToPage(page: number): void {
    if (page < 1 || page > this.totalPages) return;
    this.currentPage = page;
    this.updatePagination();
  }

  downloadFile(fileUrl: string): void {
    const a = document.createElement("a");
    a.href = fileUrl;
    a.download = fileUrl.split("/").pop() || "download";
    a.target = "_blank";
    a.click();
  }
}
