<div class="container">
  <div class="row notFoundMain">
    <div class="col-md-8">
      <img src="../../assets/images/404.png" />
      <p class="firstP">Oops! Something is missing.</p>
      <p>
        This page was not found. You may have mistyped the address or the page
        may have been moved.
      </p>
      <!-- <a class="btn gmi-primary-btn" [routerLink]="[redirectUrl]">Take me home</a> -->
      <!-- <div class="error-template">
                <h1>
                    Oops!</h1>
                <h2>
                    404 Not Found</h2>
                <div class="error-details">
                    Sorry, an error has occured, Requested page not found!
                </div>
                <div class="error-actions">
                    <a [routerLink]="''" class="btn btn-primary btn-lg">
                        <span class="glyphicon glyphicon-home"></span>
                        Take Me Home </a>
                </div>
            </div> -->
    </div>
    <div class="col-md-4 hidden-sm hidden-xs">
      <img src="../../assets/images/gita-404.png" />
    </div>
  </div>
</div>
