import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormControlName,
  Validators,
} from '@angular/forms';
// import { Response } from "@angular/http/common";
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginServices } from '../../../login/login.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent implements OnInit {
  newPasswordForm: FormGroup;
  data: any;
  id: any;
  constructor(
    private ls: LoginServices,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.newPasswordForm = new FormGroup({
      newPassword: new FormControl(null, Validators.required),
      confirmPassword: new FormControl(null, Validators.required),
    });
  }

  newPassword() {
    this.activatedRoute.params.subscribe((params) => {
      this.id = params['id'];
    });
    var newPasswordData = {
      id: this.id,
      passwords: {
        newPassword: this.newPasswordForm.value.newPassword,
        confirmPassword: this.newPasswordForm.value.confirmPassword,
      },
    };

    this.ls
      .resetAdminPassword(newPasswordData)
      .subscribe((response: Response) => {
        this.data = response;
        if (this.data.status) {
          Swal.fire({
            title: 'Reset password',
            text: this.data.message,
            icon: 'success',
            timer: 2000,
          }).then((value) => {
            this.router.navigate(['/admin']);
          });
        } else {
          Swal.fire({
            title: 'Reset password',
            text: this.data.message,
            icon: 'error',
            timer: 2000,
          });
        }
      });
  }
}
