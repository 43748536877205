<div class="page-wrapper">
  <div class="util-section">
    <div class="btn-container">
      <button (click)="exportAsExcelFile(excelData, 'test')" class="download">
        Download
      </button>
      <label for="file" class="upload">Upload</label>
      <input
        type="file"
        id="file"
        (change)="sendExcel($event.target['files'])"
      />
    </div>

    <div class="search">
      <input
        type="text"
        (keyup)="applyFilter($event.target['value'])"
        class="form-control search-icon search-bar"
        placeholder="Enter your search..."
        (keyup)="applyFilter($event.target['value'])"
      />
      <div class="clearfix"></div>
    </div>
  </div>
  <table mat-table [dataSource]="dataSource" MatSort>
    <ng-container matColumnDef="Theme">
      <th mat-header-cell class="theme" *matHeaderCellDef>Theme</th>
      <td mat-cell class="theme" *matCellDef="let element">
        {{ element.theme }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Sub Theme">
      <th mat-header-cell class="theme" *matHeaderCellDef>Sub Theme</th>
      <td mat-cell class="theme" *matCellDef="let element">
        {{ element.subTheme }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Nudges">
      <th mat-header-cell class="nudge-content" *matHeaderCellDef>Nudge</th>
      <td mat-cell class="nudge-content" *matCellDef="let element">
        {{ element.content }}
      </td>
    </ng-container>

    <ng-container class="actions" matColumnDef="Actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let element">
        <!-- <mat-icon (click)="deleteNudge()">delete</mat-icon> -->
        <i (click)="deleteNudge()" class="fa fa-trash"></i>
        <mat-icon (click)="viewModals(element._id, editNudgeContent)"
          >edit</mat-icon
        >
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns; let i = index"
    ></tr>
  </table>

  <mat-paginator
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
    aria-label="Select page of periodic elements"
  >
  </mat-paginator>
</div>

<!--Modals-->

<!-- Edit Modal-->

<ng-template #editNudgeContent>
  <div class="modal-body">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <form [formGroup]="editNudge">
      <div class="wrapper">
        <div class="id">ID : {{ edit._id }}</div>
        <div class="theme_edit">Theme: {{ edit.theme }}</div>
        <div class="theme_edit">Sub Theme: {{ edit.subTheme }}</div>
        <div class="edit_nudge">
          <textarea
            class="nudge_content"
            formControlName="editNudgeContent"
            maxlength="200"
            >{{ edit.content }}</textarea
          >
        </div>
      </div>
      <div class="btn-container">
        <button class="edit_nudge_btn" (click)="editSingleNudge()">Edit</button>
      </div>
    </form>
  </div>
</ng-template>

<!-- Edit Modal ends Here -->

<!-- Delete Modal -->
<ng-template #deleteNudgeContent>
  <div class="modal-body">div.con</div>
</ng-template>

<!-- Delete Modal ends Here -->
