<a
  href="javascript:void(0)"
  [routerLink]="['/admin/admin-dashboard/mentor']"
  class="create-new clr-grey"
>
  <em> <i class="fa fa-arrow-left" aria-hidden="true"></i> </em>Dashboard</a
>
<div class="container">
  <!-- //<app-loader *ngIf="loading"></app-loader> -->
  <input
    type="text"
    (keyup)="applyFilter($event.target['value'])"
    class="form-control search-icon"
    placeholder="Enter your search..."
    (keyup)="applyFilter($event.target['value'])"
    style="width: 45% !important"
  />
  <p *ngIf="displayNoRecords">No Records Found!.</p>
  <mat-table #table [dataSource]="dataSource" matSort>
    <!-- Select all -->
    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef>
        <input
          id="checkbox"
          type="checkbox"
          [(ngModel)]="checkall"
          (change)="checkUncheckAll()"
        />
        <label for="checkbox"></label>
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span *ngIf="row.type == 'out'">
          <input
            id="checkbox{{ row._id }}"
            type="checkbox"
            value="{{ row._id }}"
            [(ngModel)]="row.checked"
            (change)="updateCheckall(row, $event.target['checked'])"
          />
          <label for="checkbox{{ row._id }}"></label>
        </span>
        <span
          (click)="removeMentorFromManager(row._id)"
          class="remove"
          *ngIf="row.type == 'in'"
          ><i class="fa fa-trash" aria-hidden="true"></i
        ></span>
      </mat-cell>
    </ng-container>
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Name</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
    </ng-container>

    <!-- Email Column -->
    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef mat-sort-header>email</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.email }}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <mat-paginator
    [length]="5"
    [pageSize]="5"
    [pageSizeOptions]="[1, 10, 25]"
    showFirstLastButtons
  ></mat-paginator>

  <button
    (click)="addSelectedManagers()"
    class="btn btn-primary"
    [disabled]="selectedManagers.length == 0 || selectedManagers.length != 1"
  >
    Assign Mentor
  </button>
</div>
