import { Component, OnInit } from '@angular/core';
// import { Stats } from "aws-sdk/clients/cloudsearchdomain";
import { AdminServices } from '../admin.service';

@Component({
  selector: 'app-nudges',
  templateUrl: './nudges.component.html',
  styleUrls: ['./nudges.component.css'],
})
export class NudgesComponent implements OnInit {
  navLinks: Array<any> = [
    {
      label: 'Nudge Content',
      link: '/admin/admin-dashboard/nudges/active',
    },
    {
      label: 'Nudge stats',
      link: '/admin/admin-dashboard/nudges/stats',
    },
  ];

  constructor() {}

  ngOnInit() {}
}
