import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CompanyServices } from "../company.service";
import { FormArray, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { SnackBarService } from "src/app/services/snack-bar.service";

@Component({
  selector: "app-sms-management",
  templateUrl: "./sms-management.component.html",
  styleUrls: ["./sms-management.component.css"],
})
export class SmsManagementComponent implements OnInit {
  displayedColumns: string[] = [
    "name",
    "scheduleFor",
    "sentStatus",
    "createdAt",
    "action",
  ];
  placeHolders = [];
  public smsTemplates: any[] = [];
  modalRef!: BsModalRef;
  scheduledSms = new MatTableDataSource([]);
  @ViewChild(MatSort)
  sort!: MatSort;
  templateForm: any;
  companySurveyId = "";
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  selectedTemplate: any = {};
  toTypesTemplate: string[];
  startAt = new Date(Date.now());
  constructor(
    private modalService: BsModalService,
    private companyService: CompanyServices,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit(): void {
    this.getSMSTemplateDetails();
    this.initForm();
    this.companySurveyId = this.route.snapshot.params["id"];
    this.getScheduleSms(this.companySurveyId);
  }
  initForm(): void {
    this.templateForm = this.fb.group({
      name: ["", Validators.required],
      template: [null, Validators.required],
      scheduleAt: [""],
      to: ["", Validators.required],
      placeholders: this.fb.array([]),
    });
  }
  get placeholders(): FormArray {
    return this.templateForm.get("placeholders") as FormArray;
  }
  // Method to add placeholders based on selected template
  updatePlaceholders(placeholdersArray: string[]): void {
    const control = this.templateForm.get("placeholders") as FormArray;
    control.clear();
    placeholdersArray.forEach((placeholder) => {
      control.push(
        this.fb.group({
          name: [placeholder, Validators.required],
          value: ["", Validators.required],
        })
      );
    });
  }
  openEmailTemplate(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: "medium-modal" })
    );
    this.companyService.addModal(this.modalRef);
  }
  getScheduleSms(id: string) {
    this.companyService.scheduleSms(id).subscribe((res: any) => {
      if (res.status) {
        this.snackBarService.openSnackBar(res.message, "success");
        this.scheduledSms = new MatTableDataSource(res.data);
        this.scheduledSms.sort = this.sort;
        this.scheduledSms.paginator = this.paginator;
      } else {
        this.snackBarService.openSnackBar(res.message, "fail");
      }
    });
  }
  changeTemplate(event: any) {
    this.selectedTemplate = this.smsTemplates.filter((value: any) => {
      return value.sms_communication_type_mapping_id == event.target.value;
    });

    if (this.selectedTemplate.length > 0) {
      this.selectedTemplate = this.selectedTemplate[0];
      this.toTypesTemplate = this.selectedTemplate.recipients;
      if (this.selectedTemplate.placeholders.length > 0) {
        this.updatePlaceholders(this.selectedTemplate.placeholders);
      }
    }
  }

  getSMSTemplateDetails() {
    this.companyService.smstemplates().subscribe((res) => {
      if (res.status) {
        this.smsTemplates = res.data;
      } else {
        this.snackBarService.openSnackBar(res.message, "fail");
      }
    });
  }
  deleteScheduledSMS(id: string) {
    this.companyService.deactivateSms(id).subscribe((res: any) => {
      if (res.status) {
        
        setTimeout(() => {
          this.getScheduleSms(this.companySurveyId);
        }, 2000);
        this.snackBarService.openSnackBar(res.message, "success");
      } else {
        this.snackBarService.openSnackBar(res.message, "fail");
      }
    });
  }
  onSubmit(): void {
    let formData = { ...this.templateForm.value };

    let data = {
      company_survey_group_id: this.companySurveyId,
      communication_type_id: this.selectedTemplate.communication_type_id,
      sms_template_id: this.selectedTemplate.sms_template_id,
      placeholders: formData.placeholders,
      name: formData.name,
      send_to: formData.to,
      short_name: this.selectedTemplate.short_name,
      startDate:
        formData && formData.scheduleAt ? formData.scheduleAt[0] : null,
      endDate:
        formData && formData.scheduleAt.length > 1
          ? formData.scheduleAt[1]
          : null,
      type: formData.scheduleAt.length > 0 ? "schedule" : "now",
    };
    this.companyService.createSMSSchedule(data).subscribe((res: any) => {
      if (res.status) {
        this.snackBarService.openSnackBar(res.message, "success");
        this.modalRef.hide();
        this.getScheduleSms(this.companySurveyId);
      } else {
        this.snackBarService.openSnackBar(res.message, "fail");
      }
    });
  }
}
