<app-loader *ngIf="loading"></app-loader>
<mat-form-field style="width: 50%" class="search-form-field" floatLabel="never">
  <input
    class="searchField"
    matInput
    [(ngModel)]="searchKey"
    placeholder="Search"
    autocomplete="off"
    (keyup)="applyFilter()"
  />
  <button
    mat-button
    matSuffix
    mat-icon-button
    aria-label="Clear"
    *ngIf="searchKey"
    (click)="onSearchClear()"
  >
    <!-- <mat-icon>close</mat-icon> -->
    <i class="fa fa-times"></i>
  </button>
</mat-form-field>

<mat-table #table [dataSource]="dataSource" matSort>
  <!-- Select all -->
  <ng-container matColumnDef="select">
    <mat-header-cell *matHeaderCellDef>
      <input
        class="checkbox"
        id="checkbox"
        type="checkbox"
        [(ngModel)]="checkall"
        (change)="checkUncheckAll()"
      />
      <label for="checkbox"></label>
    </mat-header-cell>
    <mat-cell *matCellDef="let row">
      <input
        class="checkbox"
        id="checkbox{{ row.id }}"
        type="checkbox"
        value="{{ row.id }}"
        [(ngModel)]="row.checked"
        (change)="updateCheckall(row, $any($event.target).checked)"
      />
      <label for="checkbox{{ row.id }}"></label>
    </mat-cell>
  </ng-container>
  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Name</mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element.name }}
      <span matTooltip="Total participants">
        ({{ element.participants.length }})</span
      >
    </mat-cell>
  </ng-container>

  <!-- Email Column -->
  <ng-container matColumnDef="email">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
    <mat-cell *matCellDef="let element">{{ element.email }}</mat-cell>
  </ng-container>

  <!-- Email Column
  <ng-container matColumnDef="moduleCompleted">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Module Completed</mat-header-cell>
    <mat-cell *matCellDef="let element">{{ element.modulesCompleted }}</mat-cell>
  </ng-container> -->

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>
<mat-card *ngIf="displayNoRecords" style="padding: 100px">
  <h5 style="text-align: center">
    We couldn't find data for
    <span style="color: red">"{{ dataSource.filter }}"</span>
  </h5>
</mat-card>
<mat-paginator
  [length]="5"
  [pageSize]="5"
  [pageSizeOptions]="[1, 5, 10, 25]"
  showFirstLastButtons
></mat-paginator>
