import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { SnackBarService } from "../../services/snack-bar.service";
import { WHATSAPP_TYPES } from "../../../app/constants";
import { CompanyServices } from "../company.service";

@Component({
  selector: "app-whatsapp-manangement",
  templateUrl: "./whatsapp-manangement.component.html",
  styleUrls: ["./whatsapp-manangement.component.css"],
})
export class WhatsappManangementComponent implements OnInit {
  toTypes: string[] = WHATSAPP_TYPES;
  toTypesTemplate: string[] = WHATSAPP_TYPES;
  modalRef!: BsModalRef;
  whatsappTemplate: string = "all";
  allWhatsappTemplate: any = [];
  startAt = new Date(Date.now());
  templateForm: any;
  selectedTemplate: any = {};
  companySurveyId = "";
  valueMapping: any = [];
  keyValuePairs: any;
  scheduledWhatsapp = new MatTableDataSource([]);
  displayedColumnsForWhatsapp: string[] = [
    "name",
    "scheduleFor",
    "sentStatus",
    "createdAt",
    "action",
  ];
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private modalService: BsModalService,
    private companyService: CompanyServices,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private snackBarService: SnackBarService
  ) {}

  openEmailTemplate(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: "medium-modal" })
    );
    this.companyService.addModal(this.modalRef);
  }

  ngOnInit() {
    this.getWhatsappTemplate(this.whatsappTemplate);
    this.initForm();
    this.companySurveyId = this.route.snapshot.params["id"];
    this.getScheduledWhatsapp(this.companySurveyId);
  }

  initForm(): void {
    this.templateForm = this.fb.group({
      name: ["", Validators.required],
      template: [null, Validators.required],
      scheduleAt: [""],
      to: ["", Validators.required],
    });
  }

  onSubmit(): void {
    let formData = { ...this.templateForm.value };
    let data = {
      companySurveyId: this.companySurveyId,
      content: this.selectedTemplate.content,
      templateId: this.selectedTemplate.templateId,
      whatsAppType: this.selectedTemplate.name,
      to: formData.to,
      name: formData.name,
      startDate:
        formData && formData.scheduleAt ? formData.scheduleAt[0] : null,
      endDate:
        formData && formData.scheduleAt.length > 1
          ? formData.scheduleAt[1]
          : null,
      type: formData.scheduleAt.length > 0 ? "schedule" : "now",
    };
    this.companyService.createScheduledWhatsapp(data).subscribe((res: any) => {
      if (res.status) {
        this.snackBarService.openSnackBar(res.message, "success");
        this.modalRef.hide();
        this.getScheduledWhatsapp(this.companySurveyId);
      } else {
        this.snackBarService.openSnackBar(res.message, "fail");
      }
    });
  }

  getScheduledWhatsapp(id: string) {
    this.companyService.getScheduledWhatsapp(id).subscribe((res: any) => {
      if (res.status) {
        this.snackBarService.openSnackBar(res.message, "success");
        this.scheduledWhatsapp = new MatTableDataSource(res.data);
        this.scheduledWhatsapp.sort = this.sort;
        this.scheduledWhatsapp.paginator = this.paginator;
      } else {
        this.snackBarService.openSnackBar(res.message, "fail");
      }
    });
  }

  changeTemplate(event: any) {
    this.selectedTemplate = this.allWhatsappTemplate.filter((value: any) => {
      return value.templateId == event.target.value;
    });
    if (this.selectedTemplate.length > 0) {
      this.selectedTemplate = this.selectedTemplate[0];
      this.toTypesTemplate = this.selectedTemplate.recipients;
      this.valueMapping = this.selectedTemplate.placeholder;
      this.keyValuePairs = this.valueMapping.map(
        (obj: any) => Object.entries(obj)[0]
      );
    }
  }

  deleteScheduledWhatsapp(id: string) {
    this.companyService.deleteScheduledWhatsapp(id).subscribe((res: any) => {
      if (res.status) {
        this.snackBarService.openSnackBar(res.message, "success");
        this.getScheduledWhatsapp(this.companySurveyId);
      } else {
        this.snackBarService.openSnackBar(res.message, "fail");
      }
    });
  }

  getWhatsappTemplate(data: any) {
    this.companyService
      .getCompanyWhatsappTemplate(data)
      .subscribe((res: any) => {
        if (res.status && res.data.length > 0) {
          this.snackBarService.openSnackBar(res.message, "success");
          this.allWhatsappTemplate = res.data;
        } else {
          this.snackBarService.openSnackBar(res.message, "fail");
        }
      });
  }
}
