<h2 mat-dialog-title>Launch round-two survey</h2>
<mat-dialog-content>
  <div>Select managers recognition year</div>
  <mat-form-field appearance="fill">
    <mat-label>Recognition year</mat-label>
    <mat-select
      [(value)]="data.gpmsRecognitionYear"
      (selectionChange)="isYearSelected = true"
    >
      <mat-option *ngFor="let year of years" [value]="year">
        {{year}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <br />
  <mat-form-field appearance="fill">
    <mat-label>Choose a survey end date</mat-label>
    <input matInput [matDatepicker]="picker" (dateChange)="addEvent($event)" />
    <mat-hint>MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <br />
  <div style="margin-top: 1em; margin-bottom: 1em">
    <div>Start Date: <span>{{data.startDate}}</span></div>
    <div *ngIf="data.endDate">end Date: <span>{{data.endDate}}</span></div>
  </div>
  <ul>
    <li *ngFor="let manager of data.managers">{{manager}}</li>
  </ul>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-raised-button mat-dialog-close>Cancel</button>
  <button
    mat-button
    mat-raised-button
    color="primary"
    [mat-dialog-close]="data"
    cdkFocusInitial
    [disabled]="!isYearSelected || !isDateSelected"
  >
    Survey launch
  </button>
</mat-dialog-actions>
