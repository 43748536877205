import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { Editor, NgxEditorComponent, Toolbar } from "ngx-editor";

@Component({
  selector: "app-email-editor",
  templateUrl: "./email-editor.component.html",
  styleUrls: ["./email-editor.component.css"],
})
export class EmailEditorComponent implements OnInit, OnChanges {
  editor: Editor;
  toolbar: Toolbar = [
    ["bold", "italic"],
    ["underline", "strike"],
    ["code", "blockquote"],
    ["ordered_list", "bullet_list"],
    [{ heading: ["h1", "h2", "h3", "h4", "h5", "h6"] }],
    ["link", "image"],
    ["text_color", "background_color"],
    ["align_left", "align_center", "align_right", "align_justify"],
  ];

  @Input() content: any;

  @Output() contentChange = new EventEmitter<string>();

  constructor(private cdr: ChangeDetectorRef) {
    this.editor = new Editor();
  }
  ngOnChanges(changes: SimpleChanges): void {}

  ngOnInit(): void {}
  onContentChange(content: any) {
    this.content = content;
    this.contentChange.emit(content);
  }
  ngOnDestroy(): void {
    if (this.editor) this.editor.destroy();
  }
}
