import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  RecaptchaModule,
  RECAPTCHA_SETTINGS,
  RecaptchaSettings,
} from "ng-recaptcha";
// import { UniversalScreenComponent } from "./universal-screen/universal-screen.component";
// import { LoaderMessageComponent } from "./loader-message/loader-message.component";
// import { UpdateNotifierComponent } from "./update-notifier/update-notifier.component";
import { GoogleRecaptchaComponent } from "./google-recaptcha/google-recaptcha.component";
import { constants } from "./constants";
// import { FeedbackPopupComponent } from "./feedback-popup/feedback-popup.component";
import { NgxEditorModule } from "ngx-editor";
import { EmailEditorComponent } from "./email-editor/email-editor/email-editor.component";
import { FormsModule } from "@angular/forms";
@NgModule({
  imports: [CommonModule, RecaptchaModule, NgxEditorModule, FormsModule],
  declarations: [
    // UniversalScreenCompo?
    GoogleRecaptchaComponent,
    EmailEditorComponent,
  ],
  exports: [
    // UniversalScreenComponent,
    // LoaderMessageComponent,
    // UpdateNotifierComponent,
    GoogleRecaptchaComponent,
    EmailEditorComponent,
    // FeedbackPopupComponent
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: constants.GOOGLE_CAPTCHA_KEY,
      } as RecaptchaSettings,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
