import { Component, OnInit, TemplateRef } from "@angular/core";
import { AdminServices } from "../admin.service";
import swal from "sweetalert2";
// import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

import { BsModalService } from "ngx-bootstrap/modal";
// import clonedeep from 'lodash.clonedeep';
import { cloneDeep } from "lodash";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
@Component({
  selector: "app-user-guide",
  templateUrl: "./user-guide.component.html",
  styleUrls: ["./user-guide.component.css"],
})
export class UserGuideComponent implements OnInit {
  public userGuideForm: FormGroup;
  dataSource = new MatTableDataSource();
  displayedColumns = ["name", "content", "module", "location", "actions"];
  isEdit: boolean = false;
  modalRef: BsModalRef;
  currentIndex: number;
  constructor(
    private adminService: AdminServices,
    private modalService: BsModalService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.getUserGuideData();
    this.userGuide();
  }

  // to search in table
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim().toLowerCase();
    this.dataSource.filter = filterValue;
  }

  userGuide() {
    this.userGuideForm = this.formBuilder.group({
      _id: new FormControl("", []),
      name: new FormControl("", [Validators.required]),
      content: new FormControl("", [Validators.required]),
      location: new FormControl("", [Validators.required]),
      module: new FormControl("", [Validators.required]),
      description: new FormControl(""),
    });
  }

  getUserGuideData() {
    this.adminService.getAllUserGuide({ isActive: true }).subscribe((res) => {
      if (res.status) {
        this.dataSource.data = res.data;
      }
    });
  }

  showUserGuideModal(
    template: TemplateRef<any>,
    userGuideData: any,
    isEdit: boolean,
    index
  ) {
    this.isEdit = isEdit;
    this.currentIndex = index;
    if (this.isEdit) {
      this.currentIndex = index;
      this.userGuideForm.patchValue({
        name: userGuideData.name,
        content: userGuideData.content,
        location: userGuideData.location,
        module: userGuideData.module,
        description: userGuideData.description,
        _id: userGuideData._id,
        isActive: userGuideData.isActive,
      });
    } else {
      this.userGuideForm.reset();
    }
    this.modalRef = this.modalService.show(template);
    this.adminService.addModal(this.modalRef);
  }

  deleteUserGuide(element, index) {
    swal
      .fire({
        title: "Are You Sure You Want To Delete This User Guide ?",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
      })
      .then((result) => {
        if (result.value) {
          element.isActive = false;
          this.adminService.updatedUserGuide(element).subscribe((res) => {
            if (res.status) {
              this.dataSource.data.splice(index, 1);
              this.dataSource.data = cloneDeep(this.dataSource.data);
            }
          });
        }
      });
    this.closeModal();
  }

  closeModal() {
    this.modalService.hide(1);
  }

  // CREATE USER GUIDE
  addNewData() {
    this.adminService
      .createUserGuide(this.userGuideForm.value)
      .subscribe((res) => {
        res && swal.fire(res.status ? "success" : "error", res.message);
        this.closeModal();
        res.status ? this.getUserGuideData() : "";
      });
  }

  //EDIT Data Function
  editExistingData() {
    this.adminService
      .updatedUserGuide(this.userGuideForm.value)
      .subscribe((res) => {
        if (res.status) {
          this.dataSource.data.map((data, index) => {
            if (index == this.currentIndex) {
              (data["name"] = this.userGuideForm.value.name),
                (data["content"] = this.userGuideForm.value.content),
                (data["location"] = this.userGuideForm.value.location),
                (data["module"] = this.userGuideForm.value.module);
            }
          });
          this.closeModal();
          swal.fire("success", res.message);
        } else {
          swal.fire("error", res.message);
        }
      });
  }
}
