import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { AdminServices } from "../../admin.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { constants } from "../../../../app/constants";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
@Component({
  selector: "app-survey-filler-mapping",
  templateUrl: "./survey-filler-mapping.component.html",
  styleUrls: ["./survey-filler-mapping.component.css"],
})
export class SurveyFillerMappingComponent implements OnInit {
  surveyFillerMapping: any = [];
  surveyPageForm: FormGroup;
  isUpdate: boolean = false;
  IMAGE_URL = constants.IMAGE_URL;
  surveyContentPagesData: any = {
    fillerTypes: [
      { type: "Add Filler", value: "filler" },
      { type: "Add Survey Question", value: "survey" },
    ],
    surveyContentPagesArray: [],
    currentFillerType: "",
    currentFillerData: {},
    surveyContentPagesGroupData: {},
  };
  modalRef: BsModalRef;

  constructor(
    private adminService: AdminServices,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.getSurveyFillerMapping();
    this.initializeForm();
    this.getSurveyContentPages();
  }

  getSurveyFillerMapping() {
    this.adminService.getSurveyFillerMapping().subscribe((res: any) => {
      if (res.status) {
        this.surveyFillerMapping = res.data;
      }
    });
  }

  /**
   * Retrieves survey content pages data from the admin service.
   * Stores the data in the component's surveyContentPagesData property.
   * This function is called in the ngOnInit lifecycle hook.
   */
  getSurveyContentPages() {
    // Call the admin service's getSurveyContentPages method
    this.adminService.getSurveyContentPages().subscribe((response) => {
      // If the response status is true, update the surveyContentPagesData property
      if (response.status) {
        // Assign the response data to the surveyContentPagesData property
        this.surveyContentPagesData.surveyContentPagesArray = response.data;
        // Iterate over each survey content in the surveyContentPagesData array
        this.surveyContentPagesData.surveyContentPagesArray.forEach(
          (surveyContent) => {
            // Create a property in the surveyContentPagesGroupData object
            // with the surveyContent's id as the key, and the surveyContent's surveySequenceData as the value
            this.surveyContentPagesData.surveyContentPagesGroupData[
              surveyContent.id
            ] = surveyContent;
          }
        );
      }
    });
  }

  createCustomPage(data: any) {
    const { name, is_active, id, status, surveySequenceData } = data
      ? data
      : this.surveyPageForm.value;

    const paramsData = {
      id,
      name,
      is_active: is_active == true ? 1 : 0,
      status: status == true ? 1 : 0,
      mode: this.isUpdate ? "update" : "create",
    };
    if (
      paramsData["mode"] == "create" &&
      paramsData["is_active"] &&
      this.checkIfAnyPageisActive()
    ) {
      this.openSnackBar("Only one page can be active at a time.", false);
      this.surveyPageForm.reset();
      return;
    }

    this.adminService
      .surveyFillerMappingOperation(paramsData)
      .subscribe((response) => {
        if (response.status) {
          switch (paramsData["mode"]) {
            case "update":
              this.updateInArray(
                { id, name, is_active, status, surveySequenceData },
                0
              );
              break;
            case "create":
              let newData = {
                name,
                is_active,
                status,
                id: response.data.newdId,
                surveySequenceData: [],
              };
              this.surveyFillerMapping.push(newData);
              break;
          }
          this.surveyPageForm.reset();
          this.isUpdate = false;
        }
        this.openSnackBar(response.message, response.status);
      });
  }

  initializeForm() {
    this.surveyPageForm = this.formBuilder.group({
      id: [""],
      name: [
        "",
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(20),
        ],
      ],
      is_active: [0, [Validators.required]],
      status: [0, [Validators.required]],
    });
  }
  deleteCustomPages(id: number) {
    this.adminService
      .surveyFillerMappingOperation({ id, mode: "delete" })
      .subscribe((response) => {
        this.updateInArray(null, id);
        this.openSnackBar(response.message, response.status);
      });
  }

  patchValues(element: any) {
    const { name, is_active, id, status } = element;
    this.surveyPageForm.patchValue({
      name,
      is_active,
      id,
      status,
    });
    this.isUpdate = true;
  }

  updateInArray(element: any, id?: number) {
    const matchId = element ? element.id : id;
    const index = this.surveyFillerMapping.findIndex((x) => x.id == matchId);
    if (index > -1) {
      element
        ? (this.surveyFillerMapping[index] = element)
        : (this.surveyFillerMapping[index]["is_active"] = 0);
    }
  }

  /**
   * Opens a snack bar with the given message and status.
   *
   * @param {any} message - The message to display in the snack bar.
   * @param {boolean} status - The status of the snack bar. If true, displays a green snack bar. Otherwise, displays a red snack bar.
   */
  openSnackBar(message: any, status: boolean) {
    // Opens a snack bar with the given message and status.
    // The duration of the snack bar is set to 2000 milliseconds.
    // The panelClass determines the color of the snack bar.
    // If the status is true, the snack bar is green. Otherwise, it is red.
    this._snackBar.open(message, "", {
      duration: 2000,
      panelClass: status ? ["green-snackbar"] : ["red-snackbar"],
    });
  }

  /**
   * Handles the change event of the slide toggle.
   * If the toggle is turned off, it calls the deleteCustomPages function.
   * If the toggle is turned on, it calls the createCustomPage function.
   *
   * @param {MatSlideToggleChange} $event - The event object for the slide toggle change event.
   * @param {any} element - The element object containing the id, name, and surveySequenceData properties.
   */

  activeStatusChange($event: MatSlideToggleChange, element, index: number) {
    const checked = $event.checked;
    const activatePage = (value) => {
      this.isUpdate = true;
      this.createCustomPage({
        id: element.id,
        name: element.name,
        is_active: value,
        status: element.status,
        surveySequenceData: element.surveySequenceData,
      });
    };

    const deactivatePage = () => {
      this.deleteCustomPages(element.id);
      this.surveyFillerMapping[index]["is_active"] = false;
    };

    if ($event.checked) {
      activatePage(checked);
    } else {
      deactivatePage();
    }
  }

  statusChange($event: MatSlideToggleChange, element, index: number) {
    const checked = $event.checked;
    const activatePage = (value) => {
      this.isUpdate = true;
      this.createCustomPage({
        id: element.id,
        name: element.name,
        status: value,
        is_active: element.is_active,
        surveySequenceData: element.surveySequenceData,
      });
    };
    const deactivatePage = () => {
      this.deleteCustomPages(element.id);
      this.surveyFillerMapping[index]["status"] = false;
    };

    if ($event.checked) {
      activatePage(checked);
    } else {
      deactivatePage();
    }
  }
  checkIfAnyPageisActive() {
    return this.surveyFillerMapping.some(
      (x) => x.is_active === 1 || x.is_active == true
    );
  }

  getImageUrl(url) {
    const newUrl = `${this.IMAGE_URL}${url}`;
    return newUrl;
  }

  /**
   * Drag and drop function for reordering survey sequence data.
   * This function takes in the event and index of the survey filler, and moves the survey sequence data
   * to the new position.
   *
   * @param {CdkDragDrop<[]>} event - The drag and drop event containing the previous and current index.
   * @param {number} index - The index of the survey filler in the surveyFillerMapping array.
   * @return {void}
   */
  dragAndDrop(event: CdkDragDrop<[]>, index: number) {
    // Move the survey sequence data to the new position
    moveItemInArray(
      this.surveyFillerMapping[index]["surveySequenceData"], // The array to move the item in
      event.previousIndex, // The index of the item before it was dropped
      event.currentIndex // The index of the item after it was dropped
    );
  }

  submitMapping(index: number) {
    const updatedData = this.surveyFillerMapping[index];

    const data = {
      surveySequenceData: updatedData.surveySequenceData
        .map((data, index) => {
          if (data.contentType !== "survey") {
            return {
              survey_page_id: Number(updatedData.id),
              position: Number(index + 1),
              survey_content_page_id: Number(data.survey_filler_id),
              is_active: data.is_active,
              status: data.status,
            };
          }
          return null;
        })
        .filter((item) => item !== null),
    };

    this.adminService.addSurveyFillerSequenceMapping(data).subscribe((res) => {
      if (res.status) {
        this.getSurveyFillerMapping();
      }
      this.openSnackBar(res.message, true);
    });
  }

  /**
   * Selects a filler based on the event target's value.
   * Updates the currentFillerData property of the surveyContentPagesData object.
   *
   * @param {Event} event - The event object containing the target element.
   * @return {void} This function does not return anything.
   */
  selectFiller(event: Event): void {
    // Get the value of the event target (the selected option's value)
    const fillerId = (event.target as HTMLInputElement).value;

    // Update the currentFillerData property of the surveyContentPagesData object
    // Using optional chaining to avoid errors if surveyContentPagesData is undefined
    this.surveyContentPagesData.currentFillerData =
      this.surveyContentPagesData.surveyContentPagesGroupData?.[fillerId];
  }

  /**
   * Adds the current filler to the survey filler mapping at the specified index.
   *
   * @param {number} index - The index of the survey filler mapping to add the current filler to.
   * @param {any} pageId - The ID of the survey page.
   * @param {string} type - The type of the filler ("filler" or "survey").
   * @return {void} This function does not return anything.
   */
  addCurrentFiller(index: number, pageId: any, type: string) {
    // Check if the survey filler mapping at the specified index exists
    if (!this.surveyFillerMapping?.[index]) {
      this.openSnackBar("Invalid survey page.", false);
      return;
    }

    switch (type) {
      case "filler":
        // Check if the current filler is already added to the survey filler mapping
        if (
          this.checkFillerIsExist(
            this.surveyContentPagesData.currentFillerData.id,
            index
          )
        ) {
          this.openSnackBar("Already added.", false);
          return;
        }

        // Extract relevant properties from the current filler data
        const { button_text, content_type, description, is_active, url, id } =
          this.surveyContentPagesData.currentFillerData;

        // Create the data object for the filler
        const dataForFiller = {
          buttonText: button_text,
          contentType: content_type,
          description: description,
          is_active: is_active ? is_active : 1,
          url: url,
          position:
            this.surveyFillerMapping[index].surveySequenceData.length - 1,
          survey_filler_id: id,
          survey_page_id: pageId,
        };

        // Add the filler to the survey filler mapping
        this.surveyFillerMapping?.[index].surveySequenceData.push(
          dataForFiller
        );

        // Display a success message
        this.openSnackBar(
          "Survey Filler added successfully. You can drag and drop it change position.",
          true
        );
        break;
      case "survey":
        // Create the data object for the survey
        const dataForSurvey = {
          url: "",
          position:
            this.surveyFillerMapping?.[index]?.surveySequenceData.length - 1,
          contentType: "survey",
          description: "On this Position Survey Question will be displayed.",
          survey_filler_id: 0,
          survey_page_id: 0,
        };

        // Add the survey to the survey filler mapping
        this.surveyFillerMapping?.[index]?.surveySequenceData.push(
          dataForSurvey
        );

        // Display a success message
        this.openSnackBar(
          "Survey Filler added successfully. You can drag and drop it change position.",
          true
        );
        break;
      default:
        // Display an error message if the filler type is invalid
        this.openSnackBar("Invalid filler type.", false);
        break;
    }
  }

  checkFillerIsExist(id: number, index: number) {
    const data = this.surveyFillerMapping?.[index]?.surveySequenceData;
    if (data) {
      return data.find((data) => data.survey_filler_id == id);
    }
    return false;
  }

  /**
   * Toggles the status of a filler.
   *
   * @param {MatSlideToggleChange} $event - The event object for the slide toggle change event.
   * @param {number} pageIndex - The index of the page in surveyFillerMapping.
   * @param {number} index - The index of the filler in surveySequenceData.
   * @return {void} This function does not return anything.
   */
  fillerStatusChange(
    $event: MatSlideToggleChange,
    pageIndex: number,
    index: number
  ) {
    // Check if surveyFillerMapping and its properties are defined
    if (
      this.surveyFillerMapping &&
      this.surveyFillerMapping[pageIndex] &&
      this.surveyFillerMapping[pageIndex]["surveySequenceData"]
    ) {
      // Toggle the is_active property of the filler
      this.surveyFillerMapping[pageIndex]["surveySequenceData"][index][
        "is_active"
      ] = !$event.checked ? 0 : 1;
    }
  }

  viewModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      class: "modal-lg",
    });
  }
  checkIfFillerDataEmpty() {
    const data = this.surveyContentPagesData.currentFillerData;
    if (data && Object.keys(data).length == 0) {
      return true;
    }
    return false;
  }

  changeFillerType(event) {
    this.surveyContentPagesData.currentFillerData = {};
  }
}
