<div class="container">
  <div class="row">
    <div class="main-card">
      <div class="filters" style="padding: 2%">
        <div class="row">
          <div class="col-sm-4">
            <div class="form-group">
              <label for="reportTypeSelect">Filters:</label>
              <angular2-multiselect
                [data]="dropdownList"
                [(ngModel)]="selectedItems"
                [settings]="dropdownSettings"
                (onSelect)="onItemSelect($event)"
                (onDeSelect)="OnItemDeSelect($event)"
                (onSelectAll)="onSelectAll($event)"
                (onDeSelectAll)="onDeSelectAll($event)"
              >
              </angular2-multiselect>
            </div>
          </div>
          <!-- <div class="col-sm-4">
                                <button class="btn btn-primary refreshData" (click)="onReloadReport()">
                                  Apply Filters
                                </button>
                              </div> -->
          <div class="col-sm-4"></div>
        </div>
        <div class="row">
          <div
            class="col-sm-3"
            *ngFor="let filters of finalDisplayFilters; let i = index"
          >
            <div class="form-group">
              <label for="reportTypeSelect">{{ filters.filterName }}:</label>
              <angular2-multiselect
                [data]="filters.newOptions"
                [(ngModel)]="filters.selectedItems"
                [settings]="dropdownSettings2"
                (onSelect)="onFilterItemSelect($event)"
                (onDeSelect)="onFilterItemDeSelect($event)"
                (onSelectAll)="onFilterSelectAll($event, filters.dbName)"
                (onDeSelectAll)="onFilterDeSelectAll($event, filters.dbName)"
              >
              </angular2-multiselect>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-card">
      <div class="main" style="margin-top: 0">
        <form class="main-form">
          <div class="main-pointer-div">
            <div class="main-upload-download-button">
              <div class="gen-change-type__excel">
                <label>
                  <i class="btn gmi-primary-btn text-center" for="excel-upload"
                    >click to upload</i
                  >
                  <input
                    type="file"
                    id="excel-upload"
                    accept=".xlsx"
                    multiple="false"
                    (change)="inputExcelOnClick($event)"
                  />
                </label>
              </div>
              <div class="gen-change-type__format">
                <label>
                  <i
                    class="btn gmi-primary-btn text-center"
                    (click)="getApReviewFormat()"
                    for="pdf-upload"
                    >Download Excel Format</i
                  >
                </label>
              </div>
            </div>
            <div class="gen-excel-tab" *ngIf="!isEmptyDrop && isExcelDrop">
              <div class="excel-tab-prev"></div>
              <mat-form-field floatLabel="never" appearance="standard">
                <mat-select
                  [(value)]="selectDefault"
                  class="garage-select-color"
                  #tSelectTabHeight
                  (click)="consoleHeight(tSelectTabHeight)"
                  placeholder="Select an Tab"
                  disableOptionCentering
                  disableRipple
                >
                  <div
                    [ngClass]="{ 'outer-tofix-perfect-scrollbar': isMaxSelect }"
                  >
                    <mat-option
                      *ngFor="let name of sheetNameForTab; let i = index"
                      [value]="name"
                      (click)="loadSheetOnTabClick(i)"
                    >
                      {{ name }}
                    </mat-option>
                  </div>
                </mat-select>
              </mat-form-field>
              <p class="excel-tab-page">
                {{ currentPage + 1 }} / {{ totalPage }}
              </p>
              <div class="excel-tab-next"></div>
            </div>
          </div>
          <div class="main-drop">
            <app-bounce-loader *ngIf="loading"></app-bounce-loader>
            <div
              class="dropzone"
              *ngIf="isEmptyDrop"
              appDragDrop
              (filesChangeEmiter)="dropExcelOnChance($event)"
              (filesInvalidEmiter)="dropExcelBlock($event)"
              [allowed_extensions]="[
                'xlsx',
                'xls',
                'xlsm',
                'xltx',
                'xltm',
                'xlsb',
                'xlam'
              ]"
            >
              <div class="text-wrapper">
                <div class="centered">DRAG & DROP</div>
                <div class="centered-sub">Excel format is suggested.</div>
              </div>
            </div>
            <div
              class="display-sheet-js"
              [hidden]="!(!isEmptyDrop && isExcelDrop)"
            >
              <div class="example-container">
                <table cdk-table [dataSource]="excelDataEncodeToJson">
                  <tr
                    cdk-header-row
                    *cdkHeaderRowDef="excelTransformNum; sticky: true"
                  ></tr>
                  <tr
                    cdk-row
                    *cdkRowDef="let row; columns: excelTransformNum"
                  ></tr>
                  <ng-container cdkColumnDef="order" sticky>
                    <th cdk-header-cell *cdkHeaderCellDef>{{ sheetMaxRow }}</th>
                    <td cdk-cell *cdkCellDef="let element; let idx = index">
                      {{ idx }}
                    </td>
                  </ng-container>
                  <ng-container
                    *ngFor="
                      let disCol of excelTransformNum | slice : 1;
                      let colIndex = index
                    "
                    cdkColumnDef="{{ disCol }}"
                  >
                    <th cdk-header-cell *cdkHeaderCellDef>{{ disCol }}</th>
                    <td cdk-cell *cdkCellDef="let element">
                      {{ element[disCol] }}
                    </td>
                  </ng-container>
                </table>
              </div>
            </div>
          </div>
          <div class="submit-btn">
            <button class="btn gmi-primary-btn" (click)="submitFeedback()">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
