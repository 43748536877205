import { Injectable, Injector } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Observable, pipe } from "rxjs";
import { tap } from "rxjs/operators";
import { LoaderService } from "../app/services/loader.service";
@Injectable({
  providedIn: "root",
})
export class LoaderInterceptorService implements HttpInterceptor {
  constructor(private loaderService: LoaderService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let doNotShowLoaderUrl = [
      "connectToBot",
      "getAllRawDataByCompanyName",
      "getHashtags",
      "addSurveyDetailsCompany",
      "searchMantra",
      "getPost",
      "updateMentorMessage",
      "storeChat",
      "getChat",
      "updateSurvey",
      "answer",
      "sendSurveyForm",
    ];
    let urlParts = req.url.split("/");
    let lastSegment = urlParts.pop() || urlParts.pop();
    if (!doNotShowLoaderUrl.includes(lastSegment)) {
      this.showLoader();
    }
    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.onEnd();
          }
        },
        (err: any) => {
          this.onEnd();
        }
      )
    );
  }
  private onEnd(): void {
    this.hideLoader();
  }
  private showLoader(): void {
    this.loaderService.show();
  }
  private hideLoader(): void {
    this.loaderService.hide();
  }
}
