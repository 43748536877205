import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { AdminServices } from "../../admin.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";

import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Observable } from "rxjs";
import { map } from "rxjs/internal/operators/map";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

export interface Pages {
  id: number;
  page_name: string;
  is_active: number;
}

@Component({
  selector: "app-page-manager",
  templateUrl: "./page-manager.component.html",
  styleUrls: ["./page-manager.component.css"],
})
export class PageManagerComponent implements OnInit {
  public pageForm: FormGroup;

  dataSource = new MatTableDataSource<Pages>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  pageArray: any[] = [];
  currentIndex: any;
  modalRef: BsModalRef;
  constructor(
    private _snackBar: MatSnackBar,
    private adminService: AdminServices,
    private formBuilder: FormBuilder,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.getPagesData();
    this.initPage();
  }
  displayedColumns = ["serialNo", "Pages", "actions"];
  isEdit: boolean = false;
  activeStatus: boolean;
  public subThemeForm: FormGroup;

  getPagesData() {
    this.adminService.getPagesData().subscribe((response: any) => {
      if (response.status) {
        this.dataSource.data = response.data;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      } else {
        console.error("Failed to fetch pages:", response.message);
      }
    });
  }

  initPage() {
    this.pageForm = this.formBuilder.group({
      _id: new FormControl("", []),
      pageName: new FormControl("", [
        Validators.required,
        Validators.maxLength(45),
      ]),
      isActive: new FormControl("", []),
    });
  }

  pageModal(template: TemplateRef<any>, pageData: any, isEdit: boolean, index) {
    this.isEdit = isEdit;
    this.currentIndex = index;
    if (this.isEdit) {
      this.currentIndex = index;
      this.pageForm.patchValue({
        _id: pageData.id,
        pageName: pageData.page_name,
      });
    } else {
      this.pageForm.reset();
    }
    this.modalRef = this.modalService.show(template);
    this.adminService.addModal(this.modalRef);
  }

  closeModal() {
    this.modalService.hide();
  }

  addPageName() {
    if (this.pageForm.valid) {
      const pageData = { ...this.pageForm.value };
      const requestData = {
        pageData,
        identifier: "create",
      };
      this.adminService.createPage(requestData).subscribe({
        next: (response) => {
          this.pageForm.reset();
          this.getPagesData();
          this.openSnackBar(response.message, "success");

          this.modalService.hide(1);
        },
        error: (error) => {
          console.error(error);
          this.openSnackBar("Failed to create page", "error");
        },
      });
    } else {
      this.openSnackBar("Please fill the name", "error");
    }
  }
  openSnackBar(message, status) {
    this._snackBar.open(message, "", {
      duration: 3000,
      panelClass: status === "success" ? "green-snackbar" : "red-snackbar",
      horizontalPosition: "right",
      verticalPosition: "top",
    });
  }

  // isActive(_id: any, event) {
  //   const data = {
  //     identifier: "delete",
  //     pageId: _id,
  //     isActive: event.checked ? 1 : 0,
  //   };

  //   this.adminService.isActivePage(data).subscribe({
  //     next: (response) => {
  //       this.openSnackBar(response.message, "success");
  //     },
  //     error: (error) => {
  //       this.openSnackBar(error.message, "error");
  //     },
  //   });
  // }     //Is Active commented

  editPage(pageId: any) {
    const pageName = this.pageForm.get("pageName").value.trim();

    if (!pageName) {
      this.openSnackBar("Page name cannot be empty", "error");
      return;
    }

    const pageData = { ...this.pageForm.value };
    const requestData = {
      pageData,
      identifier: "update",
      pageId: pageId,
    };

    this.adminService.editPage(requestData).subscribe({
      next: (response) => {
        this.modalService.hide();
        this.openSnackBar(response.message, "success");
        this.pageForm.reset();
        this.getPagesData();
      },
      error: (error) => {
        this.openSnackBar(error.message, "error");
      },
    });
  }
  searchPage(event: any) {
    const value = event.target.value;
    let filterValue = value.trim().toLowerCase();
    this.dataSource.filter = filterValue;
  }
}
