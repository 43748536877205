import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelCaseToTitleCase',
})
export class CamelCaseToTitleCasePipe implements PipeTransform {
  transform(value: string): string {
    // Split the camelCase string into an array of words
    const words = value.split(/(?=[A-Z])/);

    // Capitalize the first letter of each word and join them with a space
    return words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
}
