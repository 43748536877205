import { Router } from "@angular/router";
import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormGroup,
  FormControl,
  FormControlName,
  Validators,
  FormsModule,
} from "@angular/forms";
import { HttpResponse } from "@angular/common/http";
import Swal from "sweetalert2";
import { LoginServices } from "../../login/login.service";
import { GoogleRecaptchaComponent } from "../../google-recaptcha/google-recaptcha.component";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  data: any;
  data1: any;
  userId: any;
  roles!: Array<[]>;
  selfSurveyCreation: any;
  userTypeMantras: any;
  mantras: any;
  postDecode: any;
  received_token: any;
  currentDate: any;
  loading!: boolean;
  deviceInfo = null;
  eyeIcon = "fa-eye-slash";
  isVerifiedCaptcha: boolean = false;
  captchaResponse: String = "";
  @ViewChild(GoogleRecaptchaComponent) googleCaptcha: GoogleRecaptchaComponent;
  constructor(private ls: LoginServices, private router: Router) {}
  public showGoogleCaptcha: Boolean = true;
  ngOnInit() {
    // window.location.hostname == 'localhost' ||
    // window.location.hostname == 'dashboard-uat.greatmanagerinstitute.com'
    //   ? (this.showGoogleCaptcha = false)
    //   : (this.showGoogleCaptcha = true);
    this.loginForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, Validators.required),
      selectedRole: new FormControl("company", Validators.required),
    });
    this.checkAndRedirectToUrl();
  }

  checkAndRedirectToUrl() {
    let role = localStorage.getItem("role");
    console.log(role);
    if (role && role.toLowerCase() === "company") {
      this.router.navigate(["/company/company-dashboard"]);
    } else {
      localStorage.clear();
    }
  }

  togglePasswordView() {
    if (this.eyeIcon == "fa-eye") {
      this.eyeIcon = "fa-eye-slash";
      document.getElementById("passwordView")?.setAttribute("type", "password");
    } else {
      this.eyeIcon = "fa-eye";
      document.getElementById("passwordView")?.setAttribute("type", "text");
    }
  }

  loginSubmit() {
    this.loading = true;
    this.loginForm;
    this.loginForm.value.captchaResponse = this.captchaResponse;
    this.ls.login(this.loginForm.value).subscribe((response: Response) => {
      this.loading = false;
      this.data = response;
      if (this.data.status) {
        this.ls
          .getUniversalLogin({ email: this.loginForm.value.email })
          .subscribe((res) => {
            if (res.status) {
              if (res.universalLogin.roles.length > 1) {
                this.roles = res.universalLogin.roles;
                localStorage.setItem("roles", JSON.stringify(this.roles));
              }
            }
            localStorage.setItem("token", this.data.token);
            localStorage.setItem("role", "company");
            // this.router.navigate(['/manager/manager-dashboard']);
            this.router.navigate(["/company/company-dashboard"]);
          });

        Swal.fire({
          title: "login",
          text: this.data.message,
          icon: "success",
          timer: 2500,
          showConfirmButton: false,
        });
      } else {
        Swal.fire({
          title: "login",
          text: this.data.message,
          icon: "error",
          showConfirmButton: true,
        });
      }
      if (this.googleCaptcha != null && this.googleCaptcha != undefined) {
        this.googleCaptcha.resetCapcha();
      }
      this.isVerifiedCaptcha = false;
    });
  }

  onCaptchaSuccess(captchaResponse: any) {
    if (captchaResponse == null) {
      this.isVerifiedCaptcha = false;
    } else {
      this.isVerifiedCaptcha = true;
    }
    this.captchaResponse = captchaResponse;
  }

  onCaptchaError(captchaError: any) {
    this.isVerifiedCaptcha = false;
    this.captchaResponse = "";
  }
}
