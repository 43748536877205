import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { AdminServices } from "../admin.service";
import { Clipboard } from "@angular/cdk/clipboard";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { constants } from "../../../app/constants";
import swal from "sweetalert2";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
@Component({
  selector: "app-nudge-manager",
  templateUrl: "./nudge-manager.component.html",
  styleUrls: ["./nudge-manager.component.css"],
})
export class NudgeManagerComponent implements OnInit {
  constructor(
    private adminService: AdminServices,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private clipboard: Clipboard
  ) {}
  @ViewChild("fileInput", { static: false }) fileInput: ElementRef;
  public fileExt = "";
  public fileName = "";
  public nudgeCategoryTitle = "";
  public nudgeDataForm: FormGroup;
  isEdit: Boolean = false;
  isCustom: Boolean = false;
  public nudgeUrl = "assets/images/nudges/";
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  modalRef: BsModalRef;
  editNudgeData: any;
  categories: any[] = [];
  companyData: any[] = [];
  subthemes: any[] = [];
  displayedColumns: string[] = [
    "ID",
    "Title",
    "Description",
    "Subject",
    "Image",
    "Category",
    "Category Id",
    "Acted Count",
    "Status",
    "Edit",
    // "Delete",
  ];
  public nudges = [];

  hideEditModal() {
    this.isEdit = false;
    this.isCustom = false;
    this.modalRef.hide();
    this.nudgeDataForm.reset();
  }

  ngOnInit(): void {
    this.getNudges();
    this.nudgeDataForm = this.formBuilder.group({
      id: new FormControl(null),
      title: new FormControl("", [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(200),
      ]),
      description: new FormControl("", [Validators.required]),
      subject: new FormControl("", [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(100),
      ]),
      image: new FormControl("", [Validators.required]),
      nudge_category_id: new FormControl("", [Validators.required]),
      // sub_theme_id: new FormControl("", [Validators.required]),
      is_active: new FormControl(false),
    });
    // this.getCompanies();
  }

  copyText() {
    this.clipboard.copy("Today's personalized Great Manager recommendation");
  }
  openConfirmation() {
    swal
      .fire({
        title: "Are you sure you want to continue?",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: this.isEdit ? "Update Nudge" : "Yes",
        cancelButtonText: "Back to edit",
      })
      .then((result) => {
        if (result.value) {
          this.addNudgeData();
        }
      });
  }
  addNudgeData() {
    const formData = { ...this.nudgeDataForm.value };
    if (!formData.is_active) {
      formData.is_active = false;
    }

    if (!this.isEdit) {
      delete formData.id;
    } else {
      formData.image = formData.image.replace(constants.IMAGE_URL, "");
    }
    const nudgeServiceCall = this.isEdit
      ? this.adminService.updateNudge(formData)
      : this.adminService.addNudge(formData);

    nudgeServiceCall.subscribe((res: any) => {
      if (res.status) {
        swal.fire(
          "Success",
          this.isEdit ? "Updated Successfully" : "Added Successfully"
        );
        this.hideEditModal();
        this.getNudges();
      } else {
        swal.fire("Error", res.message ? res.message : res.error);
      }
    });
  }

  openEditNudgeModal(template: TemplateRef<any>, data) {
    this.editNudgeData = data;
    if (data.company_name != "Default") this.isCustom = true;
    this.isEdit = true;
    this.fileName = data.image.split("/").pop() || "";
    this.nudgeDataForm.patchValue({
      id: data.id,
      title: data.title,
      description: data.description,
      subject: data.subject,
      image: data.image,
      nudge_category_id: data.category_id,
      // sub_theme_id: data.sub_theme_id,

      is_active: data.status == "Active" ? true : false,
    });
    this.markFormGroupTouched(this.nudgeDataForm);
    const config = { class: "modal-lg" };
    this.modalRef = this.modalService.show(template, config);
    this.modalRef.onHidden.subscribe(() => {
      this.hideEditModal();
    });
  }
  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }
  fileChangeEvent(events): void {
    var reader = new FileReader();
    if (events.target.files[0].size > 2393911) {
      swal.fire("Error", "Image size should be less than 2MB");
      const fileInput: HTMLInputElement = document.getElementById(
        "fileSelect"
      ) as HTMLInputElement;
      fileInput.value = "";
      return;
    }
    reader.readAsDataURL(events.target.files[0]); // read file as data url
    reader.onload = (event: any) => {
      const img = new Image();
      img.src = event.target.result;

      img.onload = () => {
        const width = img.width;
        const height = img.height;

        if (width > 1920 || height > 1080) {
          swal.fire(
            "Error",
            "Image dimensions should not exceed 1920x1080 pixels"
          );
          const fileInput: HTMLInputElement = document.getElementById(
            "fileSelect"
          ) as HTMLInputElement;
          fileInput.value = "";
          return;
        }
      };
      let file = event.target.result;
      let data = {
        uploads: [
          {
            data: file,
            name: events.target.files[0].name,
            type: events.target.files[0].type,
            size: events.target.files[0].size,
          },
        ],
      };
      this.adminService.uploadNudgeImages(data).subscribe((res: any) => {
        if (res.status) {
          this.nudgeDataForm.patchValue({
            image: this.nudgeUrl + events.target.files[0].name,
          });
          this.fileName = events.target.files[0].name;
          let extArray = events.target.files[0].name.split(".");
          this.fileExt = extArray[extArray.length - 1];
        } else {
          swal.fire("Error", "Something Went Wrong!!");
        }
      });
    };
  }
  openFileViewer(template) {
    this.modalRef = this.modalService.show(template, { class: "modal-lg" });
  }
  deleteFile() {
    this.fileExt = "";
    this.nudgeDataForm.controls["image"].setValue(null);
  }
  openAddNudgeModal(template: TemplateRef<any>, type: string) {
    //  if (type === "custom") {
    //    this.isCustom = true;
    //    this.nudgeDataForm
    //      .get("company_id")
    //      .setValidators([Validators.required ]);
    //  } else {
    //    this.isCustom = false;
    //    this.nudgeDataForm.get("company_id").clearValidators();
    //  }
    //  this.nudgeDataForm.get("company_id").updateValueAndValidity();

    this.nudgeDataForm.patchValue({
      subject: "Today's personalized Great Manager recommendation",
    });
    const config = { class: "modal-lg" };
    this.modalRef = this.modalService.show(template, config);
    this.adminService.addModal(this.modalRef);
  }
  getNudges() {
    this.adminService.getNudges().subscribe((res: any) => {
      if (res.status) {
        this.dataSource.data = res.data.nudges;
        this.categories = res.data.categories;
        this.subthemes = res.data.subthemes;
        this.dataSource.paginator = this.paginator;
      }
    });
  }
}
