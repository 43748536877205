export var constants = {
  SJT_TIMING: 900, //This is in seconds 25 mins(1500) 15 Mins(900)
  REWORD_QUESTION_TIME: 15, //in Seconds
  VIDEO_WAIT: 10, //secs
  GOOGLE_CAPTCHA_KEY: "6Ldn-KoaAAAAAJ-XT6_kP3cSZmkWZYocvyZIqnWD",
  IMAGE_URL: "https://media.greatmanagerinstitute.com/",
  MASTER_QUESTIONS_ID: 1,
  MASTER_QUESTIONS_ID_POST: 90,
  MASTER_QUESTIONS_ID_NEW: 101,
  MASTER_QUESTIONS_ID_NEW_POST: 112,
  MASTER_QUESTIONS_ID_SENIOR_MANAGER: 100,
  MASTER_QUESTIONS_ID_SENIOR_MANAGER_POST: 113,
  DEFAULT_SIGN_CERTIFICATE: "Dalreen Patro",
};

const WHATSAPP_TYPES: string[] = [
  "All Managers",
  "All participants",
  "Not Respondent",
  "Only Registered Managers",
  "Not Registered Managers",
  "Not Registered Participant",
  "Registered Participant",
  "Only Respondent",
];

const countryCode = [
  { name: "Andorra", dial_code: "376", code: "AD" },
  { name: "United Arab Emirates", dial_code: "971", code: "AE" },
  { name: "Afghanistan", dial_code: "93", code: "AF" },
  { name: "Antigua and Barbuda", dial_code: "1268", code: "AG" },
  { name: "Anguilla", dial_code: "1 264", code: "AI" },
  { name: "Albania", dial_code: "355", code: "AL" },
  { name: "Armenia", dial_code: "374", code: "AM" },
  { name: "Netherlands Antilles", dial_code: "599", code: "AN" },
  { name: "Angola", dial_code: "244", code: "AO" },
  { name: "Antarctica", dial_code: "", code: "AQ" },
  { name: "Argentina", dial_code: "54", code: "AR" },
  { name: "AmericanSamoa", dial_code: "1 684", code: "AS" },
  { name: "Austria", dial_code: "43", code: "AT" },
  { name: "Australia", dial_code: "61", code: "AU" },
  { name: "Aruba", dial_code: "297", code: "AW" },
  { name: "land Islands", dial_code: "", code: "AX" },
  { name: "Azerbaijan", dial_code: "994", code: "AZ" },
  { name: "Bosnia and Herzegovina", dial_code: "387", code: "BA" },
  { name: "Barbados", dial_code: "1246", code: "BB" },
  { name: "Bangladesh", dial_code: "880", code: "BD" },
  { name: "Belgium", dial_code: "32", code: "BE" },
  { name: "Burkina Faso", dial_code: "226", code: "BF" },
  { name: "Bulgaria", dial_code: "359", code: "BG" },
  { name: "Bahrain", dial_code: "973", code: "BH" },
  { name: "Burundi", dial_code: "257", code: "BI" },
  { name: "Benin", dial_code: "229", code: "BJ" },
  { name: "Saint Barthélemy", dial_code: "590", code: "BL" },
  { name: "Bermuda", dial_code: "1441", code: "BM" },
  { name: "Brunei Darussalam", dial_code: "673", code: "BN" },
  { name: "Bolivia, Plurinational State of", dial_code: "591", code: "BO" },
  { name: "Brazil", dial_code: "55", code: "BR" },
  { name: "Bahamas", dial_code: "1 242", code: "BS" },
  { name: "Bhutan", dial_code: "975", code: "BT" },
  { name: "Botswana", dial_code: "267", code: "BW" },
  { name: "Belarus", dial_code: "375", code: "BY" },
  { name: "Belize", dial_code: "501", code: "BZ" },
  { name: "Canada", dial_code: "1", code: "CA" },
  { name: "Cocos (Keeling) Islands", dial_code: "61", code: "CC" },
  {
    name: "Congo, The Democratic Republic of the",
    dial_code: "243",
    code: "CD",
  },
  { name: "Central African Republic", dial_code: "236", code: "CF" },
  { name: "Congo", dial_code: "242", code: "CG" },
  { name: "Switzerland", dial_code: "41", code: "CH" },
  { name: "Cote d'Ivoire", dial_code: "225", code: "CI" },
  { name: "Cook Islands", dial_code: "682", code: "CK" },
  { name: "Chile", dial_code: "56", code: "CL" },
  { name: "Cameroon", dial_code: "237", code: "CM" },
  { name: "China", dial_code: "86", code: "CN" },
  { name: "Colombia", dial_code: "57", code: "CO" },
  { name: "Costa Rica", dial_code: "506", code: "CR" },
  { name: "Cuba", dial_code: "53", code: "CU" },
  { name: "Cape Verde", dial_code: "238", code: "CV" },
  { name: "Christmas Island", dial_code: "61", code: "CX" },
  { name: "Cyprus", dial_code: "537", code: "CY" },
  { name: "Czech Republic", dial_code: "420", code: "CZ" },
  { name: "Germany", dial_code: "49", code: "DE" },
  { name: "Djibouti", dial_code: "253", code: "DJ" },
  { name: "Denmark", dial_code: "45", code: "DK" },
  { name: "Dominica", dial_code: "1767", code: "DM" },
  { name: "Dominican Republic", dial_code: "1849", code: "DO" },
  { name: "Algeria", dial_code: "213", code: "DZ" },
  { name: "Ecuador", dial_code: "593", code: "EC" },
  { name: "Estonia", dial_code: "372", code: "EE" },
  { name: "Egypt", dial_code: "20", code: "EG" },
  { name: "Eritrea", dial_code: "291", code: "ER" },
  { name: "Spain", dial_code: "34", code: "ES" },
  { name: "Ethiopia", dial_code: "251", code: "ET" },
  { name: "Finland", dial_code: "358", code: "FI" },
  { name: "Fiji", dial_code: "679", code: "FJ" },
  { name: "Falkland Islands (Malvinas)", dial_code: "500", code: "FK" },
  { name: "Micronesia, Federated States of", dial_code: "691", code: "FM" },
  { name: "Faroe Islands", dial_code: "298", code: "FO" },
  { name: "France", dial_code: "33", code: "FR" },
  { name: "Gabon", dial_code: "241", code: "GA" },
  { name: "United Kingdom", dial_code: "44", code: "GB" },
  { name: "Grenada", dial_code: "1 473", code: "GD" },
  { name: "Georgia", dial_code: "995", code: "GE" },
  { name: "French Guiana", dial_code: "594", code: "GF" },
  { name: "Guernsey", dial_code: "44", code: "GG" },
  { name: "Ghana", dial_code: "233", code: "GH" },
  { name: "Gibraltar", dial_code: "350", code: "GI" },
  { name: "Greenland", dial_code: "299", code: "GL" },
  { name: "Gambia", dial_code: "220", code: "GM" },
  { name: "Guinea", dial_code: "224", code: "GN" },
  { name: "Guadeloupe", dial_code: "590", code: "GP" },
  { name: "Equatorial Guinea", dial_code: "240", code: "GQ" },
  { name: "Greece", dial_code: "30", code: "GR" },
  {
    name: "South Georgia and the South Sandwich Islands",
    dial_code: "500",
    code: "GS",
  },
  { name: "Guatemala", dial_code: "502", code: "GT" },
  { name: "Guam", dial_code: "1671", code: "GU" },
  { name: "Guinea-Bissau", dial_code: "245", code: "GW" },
  { name: "Guyana", dial_code: "595", code: "GY" },
  { name: "Hong Kong", dial_code: "852", code: "HK" },
  { name: "Honduras", dial_code: "504", code: "HN" },
  { name: "Croatia", dial_code: "385", code: "HR" },
  { name: "Haiti", dial_code: "509", code: "HT" },
  { name: "Hungary", dial_code: "36", code: "HU" },
  { name: "Indonesia", dial_code: "62", code: "ID" },
  { name: "Ireland", dial_code: "353", code: "IE" },
  { name: "Israel", dial_code: "972", code: "IL" },
  { name: "Isle of Man", dial_code: "44", code: "IM" },
  { name: "India", dial_code: "91", code: "IN" },
  { name: "British Indian Ocean Territory", dial_code: "246", code: "IO" },
  { name: "Iraq", dial_code: "964", code: "IQ" },
  { name: "Iran, Islamic Republic of", dial_code: "98", code: "IR" },
  { name: "Iceland", dial_code: "354", code: "IS" },
  { name: "Italy", dial_code: "39", code: "IT" },
  { name: "Jersey", dial_code: "44", code: "JE" },
  { name: "Jamaica", dial_code: "1 876", code: "JM" },
  { name: "Jordan", dial_code: "962", code: "JO" },
  { name: "Japan", dial_code: "81", code: "JP" },
  { name: "Kenya", dial_code: "254", code: "KE" },
  { name: "Kyrgyzstan", dial_code: "996", code: "KG" },
  { name: "Cambodia", dial_code: "855", code: "KH" },
  { name: "Kiribati", dial_code: "686", code: "KI" },
  { name: "Comoros", dial_code: "269", code: "KM" },
  { name: "Saint Kitts and Nevis", dial_code: "1869", code: "KN" },
  {
    name: "Korea, Democratic People's Republic of",
    dial_code: "850",
    code: "KP",
  },
  { name: "Korea, Republic of", dial_code: "82", code: "KR" },
  { name: "Kuwait", dial_code: "965", code: "KW" },
  { name: "Cayman Islands", dial_code: "345", code: "KY" },
  { name: "Kazakhstan", dial_code: "7 7", code: "KZ" },
  { name: "Lao People's Democratic Republic", dial_code: "856", code: "LA" },
  { name: "Lebanon", dial_code: "961", code: "LB" },
  { name: "Saint Lucia", dial_code: "1758", code: "LC" },
  { name: "Liechtenstein", dial_code: "423", code: "LI" },
  { name: "Sri Lanka", dial_code: "94", code: "LK" },
  { name: "Liberia", dial_code: "231", code: "LR" },
  { name: "Lesotho", dial_code: "266", code: "LS" },
  { name: "Lithuania", dial_code: "370", code: "LT" },
  { name: "Luxembourg", dial_code: "352", code: "LU" },
  { name: "Latvia", dial_code: "371", code: "LV" },
  { name: "Libyan Arab Jamahiriya", dial_code: "218", code: "LY" },
  { name: "Morocco", dial_code: "212", code: "MA" },
  { name: "Monaco", dial_code: "377", code: "MC" },
  { name: "Moldova, Republic of", dial_code: "373", code: "MD" },
  { name: "Montenegro", dial_code: "382", code: "ME" },
  { name: "Saint Martin", dial_code: "590", code: "MF" },
  { name: "Madagascar", dial_code: "261", code: "MG" },
  { name: "Marshall Islands", dial_code: "692", code: "MH" },
  {
    name: "Macedonia, The Former Yugoslav Republic of",
    dial_code: "389",
    code: "MK",
  },
  { name: "Mali", dial_code: "223", code: "ML" },
  { name: "Myanmar", dial_code: "95", code: "MM" },
  { name: "Mongolia", dial_code: "976", code: "MN" },
  { name: "Macao", dial_code: "853", code: "MO" },
  { name: "Northern Mariana Islands", dial_code: "1670", code: "MP" },
  { name: "Martinique", dial_code: "596", code: "MQ" },
  { name: "Mauritania", dial_code: "222", code: "MR" },
  { name: "Montserrat", dial_code: "1664", code: "MS" },
  { name: "Malta", dial_code: "356", code: "MT" },
  { name: "Mauritius", dial_code: "230", code: "MU" },
  { name: "Maldives", dial_code: "960", code: "MV" },
  { name: "Malawi", dial_code: "265", code: "MW" },
  { name: "Mexico", dial_code: "52", code: "MX" },
  { name: "Malaysia", dial_code: "60", code: "MY" },
  { name: "Mozambique", dial_code: "258", code: "MZ" },
  { name: "Namibia", dial_code: "264", code: "NA" },
  { name: "New Caledonia", dial_code: "687", code: "NC" },
  { name: "Niger", dial_code: "227", code: "NE" },
  { name: "Norfolk Island", dial_code: "672", code: "NF" },
  { name: "Nigeria", dial_code: "234", code: "NG" },
  { name: "Nicaragua", dial_code: "505", code: "NI" },
  { name: "Netherlands", dial_code: "31", code: "NL" },
  { name: "Norway", dial_code: "47", code: "NO" },
  { name: "Nepal", dial_code: "977", code: "NP" },
  { name: "Nauru", dial_code: "674", code: "NR" },
  { name: "Niue", dial_code: "683", code: "NU" },
  { name: "New Zealand", dial_code: "64", code: "NZ" },
  { name: "Oman", dial_code: "968", code: "OM" },
  { name: "Panama", dial_code: "507", code: "PA" },
  { name: "Peru", dial_code: "51", code: "PE" },
  { name: "French Polynesia", dial_code: "689", code: "PF" },
  { name: "Papua New Guinea", dial_code: "675", code: "PG" },
  { name: "Philippines", dial_code: "63", code: "PH" },
  { name: "Pakistan", dial_code: "92", code: "PK" },
  { name: "Poland", dial_code: "48", code: "PL" },
  { name: "Saint Pierre and Miquelon", dial_code: "508", code: "PM" },
  { name: "Pitcairn", dial_code: "872", code: "PN" },
  { name: "Puerto Rico", dial_code: "1939", code: "PR" },
  { name: "Palestinian Territory, Occupied", dial_code: "970", code: "PS" },
  { name: "Portugal", dial_code: "351", code: "PT" },
  { name: "Palau", dial_code: "680", code: "PW" },
  { name: "Paraguay", dial_code: "595", code: "PY" },
  { name: "Qatar", dial_code: "974", code: "QA" },
  { name: "Réunion", dial_code: "262", code: "RE" },
  { name: "Romania", dial_code: "40", code: "RO" },
  { name: "Serbia", dial_code: "381", code: "RS" },
  { name: "Russia", dial_code: "7", code: "RU" },
  { name: "Rwanda", dial_code: "250", code: "RW" },
  { name: "Saudi Arabia", dial_code: "966", code: "SA" },
  { name: "Solomon Islands", dial_code: "677", code: "SB" },
  { name: "Seychelles", dial_code: "248", code: "SC" },
  { name: "Sudan", dial_code: "249", code: "SD" },
  { name: "Sweden", dial_code: "46", code: "SE" },
  { name: "Singapore", dial_code: "65", code: "SG" },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    dial_code: "290",
    code: "SH",
  },
  { name: "Slovenia", dial_code: "386", code: "SI" },
  { name: "Svalbard and Jan Mayen", dial_code: "47", code: "SJ" },
  { name: "Slovakia", dial_code: "421", code: "SK" },
  { name: "Sierra Leone", dial_code: "232", code: "SL" },
  { name: "San Marino", dial_code: "378", code: "SM" },
  { name: "Senegal", dial_code: "221", code: "SN" },
  { name: "Somalia", dial_code: "252", code: "SO" },
  { name: "Suriname", dial_code: "597", code: "SR" },
  { name: "Sao Tome and Principe", dial_code: "239", code: "ST" },
  { name: "El Salvador", dial_code: "503", code: "SV" },
  { name: "Syrian Arab Republic", dial_code: "963", code: "SY" },
  { name: "Swaziland", dial_code: "268", code: "SZ" },
  { name: "Turks and Caicos Islands", dial_code: "1649", code: "TC" },
  { name: "Chad", dial_code: "235", code: "TD" },
  { name: "Togo", dial_code: "228", code: "TG" },
  { name: "Thailand", dial_code: "66", code: "TH" },
  { name: "Tajikistan", dial_code: "992", code: "TJ" },
  { name: "Tokelau", dial_code: "690", code: "TK" },
  { name: "Timor-Leste", dial_code: "670", code: "TL" },
  { name: "Turkmenistan", dial_code: "993", code: "TM" },
  { name: "Tunisia", dial_code: "216", code: "TN" },
  { name: "Tonga", dial_code: "676", code: "TO" },
  { name: "Turkey", dial_code: "90", code: "TR" },
  { name: "Trinidad and Tobago", dial_code: "1868", code: "TT" },
  { name: "Tuvalu", dial_code: "688", code: "TV" },
  { name: "Taiwan, Province of China", dial_code: "886", code: "TW" },
  { name: "Tanzania, United Republic of", dial_code: "255", code: "TZ" },
  { name: "Ukraine", dial_code: "380", code: "UA" },
  { name: "Uganda", dial_code: "256", code: "UG" },
  { name: "United States", dial_code: "1", code: "US" },
  { name: "Uruguay", dial_code: "598", code: "UY" },
  { name: "Uzbekistan", dial_code: "998", code: "UZ" },
  { name: "Holy See (Vatican City State)", dial_code: "379", code: "VA" },
  { name: "Saint Vincent and the Grenadines", dial_code: "1 784", code: "VC" },
  { name: "Venezuela, Bolivarian Republic of", dial_code: "58", code: "VE" },
  { name: "Virgin Islands, British", dial_code: "1284", code: "VG" },
  { name: "Virgin Islands, U.S.", dial_code: "1340", code: "VI" },
  { name: "Viet Nam", dial_code: "84", code: "VN" },
  { name: "Vanuatu", dial_code: "678", code: "VU" },
  { name: "Wallis and Futuna", dial_code: "681", code: "WF" },
  { name: "Samoa", dial_code: "685", code: "WS" },
  { name: "Yemen", dial_code: "967", code: "YE" },
  { name: "Mayotte", dial_code: "262", code: "YT" },
  { name: "South Africa", dial_code: "27", code: "ZA" },
  { name: "Zambia", dial_code: "260", code: "ZM" },
  { name: "Zimbabwe", dial_code: "263", code: "ZW" },
];
// export {countryCode};

export function validateEmail(email: any) {
  var re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
  // if(re.test(email) == true){
  //     return true;
  // }
  // else return false;
}
const companyTypes = [
  { name: "B2B(Hi-Touch)", type: "B2B(Hi-Touch)" },
  { name: "B2B-GPTW", type: "B2B-GPTW" },
  { name: "B2B(Hi-Tech)", type: "B2B(Hi-Tech)" },
  { name: "GPMS", type: "GPMS" },
  { name: "B2C", type: "B2C" },
];

const MANAGER_TYPE = ["D2C", "B2B", "ALL"];

const DEFAULT_RATING_RESPONSE = [
  {
    choice: "Almost always untrue",
  },
  {
    choice: "Often untrue",
  },
  {
    choice: "Sometimes true or untrue",
  },
  {
    choice: "Often true",
  },
  {
    choice: "Always always true",
  },
];

const RESEARCH_QUESTION_TYPE = [
  { value: "rating", label: "Rating Question" },
  { value: "select", label: "Select" },
  { value: "multi-select", label: "Multi Select" },
];

const LAUNCH_TYPE = [
  { value: "d2c", label: "D2C" },
  { value: "b2bgpms", label: "B2B GPMS" },
  { value: "b2bgmc", label: "B2B GMC" },
  { value: "all", label: "All" },
];

const SIDE_BAR_ITEMS = [
  {
    name: "Corporate Companies",
    icon: "fa fa-building",
    url: "/admin/admin-dashboard/companies",
  },
  {
    name: "Certification Moderation",
    // icon: "fa fa-ribbon",
    icon: "fa fa-certificate",
    url: "/admin/admin-dashboard/certification-moderation",
  },
  {
    name: "Manager MIS",
    icon: "fa fa-users",
    url: "/admin/admin-dashboard/manager-mis",
  },
  {
    name: "Nudge Management",
    icon: "fa fa-tasks",
    subItemOpen: false,
    subItems: [
      {
        name: "Nudge Library",
        icon: "fa fa-credit-card",
        url: "/admin/admin-dashboard/nudges",
      },
      {
        name: "Nudge Topic",
        icon: "fa fa-users",
        url: "/admin/admin-dashboard/nudge-category",
      },
      {
        name: "Nudge Subject",
        icon: "fa fa-bell",
        url: "/admin/admin-dashboard/nudge-subject",
      },
      {
        name: "Nudge Cron",
        icon: "fa fa-clock-o",
        url: "/admin/admin-dashboard/nudge-crons",
      },
      {
        name: "Nudge Email Names",
        icon: "fa fa-tag",
        url: "/admin/admin-dashboard/nudge-names",
      },
      {
        name: "AB Test",
        icon: "fa fa-bell",
        url: "/admin/admin-dashboard/nudge-ab",
      },
    ],
  },
  {
    name: "Survey Experience Management",
    icon: "fa fa-list-alt",
    url: "/admin/admin-dashboard/survey-experience",
  },
  {
    name: "Assign Coupon",
    icon: "fa fa-credit-card",
    url: "/admin/admin-dashboard/assign-coupon",
  },
  // {
  //   name: "Trigger",
  //   icon: "fa fa-bell",
  //   url: "/admin/admin-dashboard/trigger",
  // },
  // {
  //   name: "Bu Login",
  //   icon: "fa fa-user-circle",
  //   url: "/admin/admin-dashboard/bu-Login",
  // },
  {
    name: "Logs",
    icon: "fa fa-history",
    url: "/admin/admin-dashboard/logs",
  },
  {
    name: "Mentor Management",
    icon: "fa fa-comments-o",
    url: "/admin/admin-dashboard/mentor",
  },
  {
    name: "Round 2",
    icon: "fa fa-rocket",
    url: "/admin/admin-dashboard/round2",
  },
  // {
  //   name: "Reward Requests",
  //   icon: "fa fa-diamond",
  //   url: "/admin/admin-dashboard/reward-requests",
  // },
  // {
  //   name: "Landing Template",
  //   icon: "fa fa-desktop",
  //   url: "/admin/admin-dashboard/landing-template",
  // },
  // {
  //   name: "Company Benchmark",
  //   icon: "fa fa-bar-chart",
  //   url: "/admin/admin-dashboard/benchmark",
  // },
  // {
  //   name: "DMS",
  //   icon: "fa fa-database",
  //   url: "/admin/admin-dashboard/database-dashboard",
  // },
  {
    name: "Research Question",
    icon: "fa fa-line-chart",
    url: "/admin/admin-dashboard/research-question",
  },
  {
    name: "Survey Question",
    icon: "fa fa-tasks",
    subItemOpen: false,
    subItems: [
      {
        name: "Subthemes",
        icon: "fa fa-th-large",
        url: "/admin/admin-dashboard/survey-subthemes",
      },
      {
        name: "Questions",
        icon: "fa fa-list-alt",
        url: "/admin/admin-dashboard/survey-questions",
      },
      {
        name: "Master Questionnaire",
        icon: "fa fa-question-circle",
        url: "/admin/admin-dashboard/master-questionnaire",
      },
    ],
  },
  {
    name: "User Guide",
    icon: "fa fa-book",
    url: "/admin/admin-dashboard/user-guide",
  },
  {
    name: "Popovers",
    icon: "fa fa-eercast",
    url: "/admin/admin-dashboard/accomplishment-popover",
  },
  {
    name: "Leadership Factories",
    icon: "fa fa-sun",
    url: "/admin/admin-dashboard/leadership-dashboard",
  },
  {
    name: "App Pages",
    icon: "fa fa-solid fa-list",
    subItemOpen: false,
    subItems: [
      {
        name: "Pages",
        icon: "fa fa-th-large",
        url: "/admin/admin-dashboard/pages",
      },
      {
        name: "Components",
        icon: "fa fa-list-alt",
        url: "/admin/admin-dashboard/component",
      },
      {
        name: "Sub Components",
        icon: "fa fa-credit-card",
        url: "/admin/admin-dashboard/sub-component",
      },
    ],
  },
  {
    name: "Leadership Analytics",
    icon: "fa fa-bar-chart",
    url: "/admin/admin-dashboard/leadership-analytics",
  },
];

const INSTRUMENT_OPTIONS_ARRAY = [
  {
    name: "Old Instrument",
    value: constants.MASTER_QUESTIONS_ID,
  },
  {
    name: "New Instrument Mixed Manager Group",
    value: constants.MASTER_QUESTIONS_ID_NEW,
  },
  {
    name: "New Instrument Senior Manager Group",
    value: constants.MASTER_QUESTIONS_ID_SENIOR_MANAGER,
  },
  {
    name: "Old Instrument Post",
    value: constants.MASTER_QUESTIONS_ID_POST,
  },
  {
    name: "New Instrument Mixed Manager Group Post",
    value: constants.MASTER_QUESTIONS_ID_NEW_POST,
  },
  {
    name: "New Instrument Senior Manager Group Post",
    value: constants.MASTER_QUESTIONS_ID_SENIOR_MANAGER_POST,
  },
];

const RATING_OPTIONS = [
  {
    answer_id: 0,
    answer_text: "No Answer",
  },
  {
    answer_id: 1,
    answer_text: "Almost always untrue",
  },
  {
    answer_id: 2,
    answer_text: "Often untrue",
  },
  {
    answer_id: 3,
    answer_text: "Sometimes true or untrue",
  },
  {
    answer_id: 4,
    answer_text: "Often true",
  },
  {
    answer_id: 5,
    answer_text: "Almost always true",
  },
];

const QUESTION_RESPONDENT_MAPPING = [
  {
    respondent_type: 1,
    respondentTypeText: "Self",
  },
  {
    respondent_type: 2,
    respondentTypeText: "Team Member",
  },
  {
    respondent_type: 3,
    respondentTypeText: "Peer",
  },
  {
    respondent_type: 4,
    respondentTypeText: "Senior",
  },
  {
    respondent_type: 5,
    respondentTypeText: "Reporting Manager",
  },
];

const THEMES_ARRAY = [
  {
    name: "Connect",
    value: 1,
  },
  {
    name: "Develop",
    value: 2,
  },
  {
    name: "Inspire",
    value: 3,
  },
  {
    name: "Overriding",
    value: 4,
  },
  {
    name: "No Theme",
    value: 11,
  },
  {
    name: "HDFC Bank Theme",
    value: 18,
  },
];

const QUESTION_TYPES = {
  rating: 1,
  select: 2,
  openeneded: 4,
};

const QUESTION_TYPE_MAPPING = {
  1: "Rating",
  2: "Select",
  3: "Multi Select",
  4: "Open Ended",
  5: "Action Plan",
};

const MANAGER_STATUS = [
  "TFS-Not-Launched",
  "SAS-Not-Submitted",
  "Registered-Not-Paid",
  "People-Practices-Not-Submitted",
];

const CERTIFICATE_TEXT =
  "for successfully completing the Great People Manager Certification Program and demonstrating an exceptional ability to 'Connect, Develop, Inspire@' your teams.";

const USER_TYPE = {
  MANAGER: 1,
  RESPONDANTS: 2,
  ORGANIZATION: 3,
  EMPLOYEE_COMMENT_VISUALIZATION: 4,
};
const PAGE_TYPE = {
  KEY_INSIGHTS: 1,
  ORG_PROFILE: 2,
  MENU_OPTIONS: 3,
  THEME_COLOR: 4,
  VISUALIZATION: 5,
};
const PAGE_DETAILS = [
  {
    component_name: "key-insights",
    type: 1,
    demo_type: 1,
    page_type: 1,
    is_active: true,
  },
  {
    component_name: "org-profile",
    type: 1,
    demo_type: 1,
    page_type: 2,
    is_active: true,
  },
];
const GMC_CRITERIA_DEFAULT = {
  ACTION_PLAN_MIN: 3,
  IMPLEMENTATION_MIN: 2,
  LEARNING_AND_DEVELOPMENT_MIN: 3,
};
const GMC_CRITERIA = [
  {
    label: "Learning and Development",
    value: 0,
  },
  {
    label: "Action Plan",
    value: 0,
  },
  {
    label: "Implementation Updates",
    value: 0,
  },
];

const DEFAULT_DEMOGRAPHICS = [
  {
    company_demographics_name: "gender",
    company_demographics_options_name: ["Male", "Female", "Non Binary"],
    type: "both",
    show: true,
  },
  {
    company_demographics_name: "age",
    company_demographics_options_name: [
      "25 years or younger",
      "26 - 34 years",
      "35 - 44 years",
      "45 - 54 years",
      "55 years or above",
    ],
    type: "both",
    show: true,
  },
  {
    company_demographics_name: "tenureWithOrganisation",
    company_demographics_options_name: [
      "Upto 6 months",
      "More than 6 months upto 1 year",
      "More than 1 year upto 2 years",
      "More than 2 years upto 5 years",
      "More than 5 years upto 10 years",
      "More than 10 years upto 15 years",
      "More than 15 years upto 20 years",
      "Over 20 years",
    ],
    type: "both",
    show: true,
  },
  {
    company_demographics_name: "tenureWithTheManager",
    company_demographics_options_name: [
      "Upto 6 months",
      "More than 6 months upto 1 year",
      "More than 1 year upto 2 years",
      "More than 2 years upto 5 years",
      "More than 5 years upto 10 years",
      "Over 10 years",
    ],
    type: "participant",
    show: true,
  },
  {
    company_demographics_name: "tenureAsManager",
    company_demographics_options_name: [
      "Upto 6 months",
      "More than 6 months upto 1 year",
      "More than 6 months upto 2 years",
      "More than 2 years upto 5 years ",
      "More than 5 years upto 10 years",
      "More than 10 years upto 15 years",
      "Over 15 years",
    ],
    type: "manager",
    show: true,
  },
  {
    company_demographics_name: "layerOfReportee",
    company_demographics_options_name: [
      "None",
      "1 layer",
      "2 layers",
      "3 layers",
      "4 layers",
      "Greater than 4 layers",
    ],
    type: "manager",
    show: true,
  },
  {
    company_demographics_name: "departmentFunction",
    company_demographics_options_name: [
      "Marketing",
      "Sales",
      "Accounting and Finance",
      "HRM",
      "Technology",
      "Operations/Production",
      "R&D",
      "Quality Assurance",
      "Purchase",
      "Others (Please specify)",
    ],
    type: "both",
    show: true,
  },
  {
    company_demographics_name: "workExperience",
    company_demographics_options_name: [
      "Upto 1 year",
      "More than 1 year upto 2 years",
      "More than 2 years upto 5 years",
      "More than 5 years upto 10 years",
      "More than 10 years upto 15 years",
      "More than 15 years upto 20 years",
      "More than 20 years upto 25 years",
      "More than 25 years upto 30 years",
      "Over 30 years",
    ],
    type: "both",
    show: true,
  },
  {
    company_demographics_name: "levelOfSeniority",
    company_demographics_options_name: [
      "Individual contributor",
      "Front line manager",
      "Mid level manager",
      "Senior manager",
      "CXO/CEO/Owner",
    ],
    type: "both",
    show: true,
  },
  {
    company_demographics_name: "teamComposition",
    company_demographics_options_name: [
      "All working from office",
      "All working from home",
      "Hybrid working",
    ],
    type: "manager",
    show: true,
  },
  {
    company_demographics_name: "teamSize",
    company_demographics_options_name: [
      "Upto 2",
      "3-5",
      "6-10",
      "11-15",
      "16-20",
      "20-25",
      "25-30",
      "Above 30",
    ],
    type: "manager",
    show: true,
  },
  {
    company_demographics_name: "country",
    company_demographics_options_name: [],
    type: "both",
    show: false,
  },
  {
    company_demographics_name: "state",
    company_demographics_options_name: [],
    type: "both",
    show: false,
  },
  {
    company_demographics_name: "city",
    company_demographics_options_name: [],
    type: "both",
    show: false,
  },
];
enum leaderboardMailTypes {
  with_survey_id = "leaderboard_report_to_reporting_manager",
  without_survey_id = "leaderboard_report_to_individual_reporting_manager",
}

export {
  countryCode,
  companyTypes,
  DEFAULT_RATING_RESPONSE,
  RESEARCH_QUESTION_TYPE,
  LAUNCH_TYPE,
  SIDE_BAR_ITEMS,
  INSTRUMENT_OPTIONS_ARRAY,
  QUESTION_RESPONDENT_MAPPING,
  RATING_OPTIONS,
  THEMES_ARRAY,
  QUESTION_TYPES,
  MANAGER_STATUS,
  MANAGER_TYPE,
  CERTIFICATE_TEXT,
  WHATSAPP_TYPES,
  USER_TYPE,
  PAGE_DETAILS,
  PAGE_TYPE,
  GMC_CRITERIA,
  GMC_CRITERIA_DEFAULT,
  leaderboardMailTypes,
  QUESTION_TYPE_MAPPING,
  DEFAULT_DEMOGRAPHICS,
};
