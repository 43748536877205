<div class="login-main">
  <div class="container-fluid clearfix">
    <div class="row">
      <div class="tbl">

        <div class="tbl-cel-left"></div>

        <div class="tbl-cel-right">
          <form [formGroup]="newPasswordForm">
            <div class="col-md-6 login-right text-center">
              <div class="form-group">
                <img src="../../../assets/images/KEY.png">
                <h3>Forgot Password</h3>
                <p style="color:#9b9b9b">Create Password using uppercase and lowercase characters
                  <br> numbers and symbols like :;/?,</p>
              </div>

              <div class="form-group mt-30">
                <input type="password" class="form-control input-lg lock-icon" placeholder="Enter New Password" formControlName="newPassword">

              </div>

              <div class="form-group">
                <input type="password" class="form-control input-lg lock-icon" placeholder="Retype Password" formControlName="confirmPassword">
              </div>

              <div class="form-group mt-25">
                <button type="submit" class="btn btn-primary" (click)="newPassword()">CONFIRM PASSWORD</button>
              </div>

              <div class="form-group mt-25">
                <a routerLink="/admin">Cancel Request</a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>