<div class="container main">
  <div class="">
    <div class="row">
      <div class="col-md-5">
        <div class="form-group pb-10">
          <input
            class="form-control custom-form-control"
            required
            id="subtheme"
            name="subtheme"
            type="text"
            placeholder="Search Pages name..."
            (keyup)="searchPage($event)"
          />
        </div>
      </div>
      <div class="col-md-5 text-right">
        <button
          type="button"
          (click)="pageModal(addEditUserPage, {}, false, {})"
          class="btn btn-secondary btn-newuser-clr"
        >
          Add New
        </button>
      </div>
    </div>
    <div class="Page-table">
      <mat-table matSort [dataSource]="dataSource" class="mat-elevation-z4">
        <ng-container matColumnDef="serialNo">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Serial No</mat-header-cell
          >
          <mat-cell
            class="text-center"
            *matCellDef="let element; let i = index"
            >{{ i + 1 }}</mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="Pages">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Pages</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{ element.page_name }}</mat-cell>
        </ng-container>

        <!-- <ng-container matColumnDef="isActive">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            isActive
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-slide-toggle
              [checked]="element.is_active === 1"
              (change)="isActive(element.id, $event)"
            >
              {{ activeStatus }}
            </mat-slide-toggle>
          </mat-cell>
        </ng-container> -->

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
          <mat-cell *matCellDef="let element" class="action-buttons">
            <button
              mat-icon-button
              matTooltip="Click to Edit"
              color="primary"
              (click)="pageModal(addEditUserPage, element, true, element.id)"
              class="iconbutton"
            >
              <i class="fa fa-pencil"></i>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>

      <mat-paginator
        [pageSizeOptions]="[15, 30, 50]"
        showFirstLastButtons
      ></mat-paginator>

      <div
        class="text-center emptyContainer"
        *ngIf="dataSource.data.length === 0"
      >
        Not found any Pages.
      </div>
    </div>
  </div>
</div>

<ng-template #addEditUserPage>
  <button
    type="button "
    (click)="closeModal()"
    class="close"
    aria-label="Close "
  >
    &times;
  </button>
  <div class="modal-body">
    <form class="form" [formGroup]="pageForm">
      <label for="name">Page Name:<small class="text-danger">*</small> </label>
      <input
        formControlName="pageName"
        required
        type="text"
        placeholder="Enter name"
        maxlength="45"
      />
      <div
        *ngIf="
          pageForm.get('pageName').invalid && pageForm.get('pageName').touched
        "
      ></div>
      <br />
      <button
        class="p-2"
        type="button"
        [disabled]="pageForm.invalid"
        (click)="isEdit ? editPage(pageForm.get('_id').value) : addPageName()"
      >
        {{ isEdit ? "Update Page Name" : "Add Page Name" }}
      </button>
      <br />
    </form>
  </div>
</ng-template>
