<div class="reportMain">
  <div class="row">
    <div class="col-sm-4">
      <div class="form-group">
        <label for="reportTypeSelect">Report Type:</label>
        <select
          class="form-control"
          id="reportTypeSelect"
          (change)="onReportTypeChange($event)"
          [(ngModel)]="reportType"
        >
          <option value="managerReport">Manager Report</option>
          <option value="scoresheet">Manager Scoresheet</option>
          <option value="surveyResponse">Survey Response</option>
          <option value="masterReport">Master Report</option>
          <option value="leaderShipReport">Leadership Dashboard Report</option>
        </select>
      </div>
    </div>
    <div class="col-sm-4"></div>
    <div class="col-sm-4">
      <button
        class="btn gmi-primary-btn downloadExcel"
        (click)="downloadExcel()"
      >
        Download Excel
      </button>
    </div>
  </div>
  <!-- Description -->
  <div class="row">
    <div class="col-sm-12">
      <div class="form-group">
        <label for="reportTypeSelect">Description:</label>
        <p *ngIf="reportType == 'managerReport'">
          This is a comprehensive report which captures the stage wise progress
          of managers
        </p>
        <p *ngIf="reportType == 'scoresheet'">
          This report captures the scores for managers basis the latest survey.
        </p>
        <p *ngIf="reportType == 'surveyResponse'">
          This report captures the response rate for managers.
        </p>
      </div>
    </div>
  </div>
  <div class="filters">
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label for="reportTypeSelect">Filters:</label>
          <angular2-multiselect
            [data]="dropdownList"
            [(ngModel)]="selectedItems"
            [settings]="dropdownSettings"
            (onOpen)="onOpen()"
            (onSelect)="onItemSelect($event)"
            (onDeSelect)="OnItemDeSelect($event)"
            (onSelectAll)="onSelectAll($event)"
            (onDeSelectAll)="onDeSelectAll($event)"
          >
          </angular2-multiselect>
        </div>
      </div>
      <div class="col-sm-4">
        <button
          class="btn gmi-primary-btn refreshData"
          (click)="onReloadReport()"
        >
          Apply Filters
        </button>
      </div>
      <div class="col-sm-4"></div>
    </div>
    <div class="row">
      <div
        class="col-sm-3"
        *ngFor="let filters of finalDisplayFilters; let i = index"
      >
        <div class="form-group">
          <label for="reportTypeSelect">{{ filters.filterName }}:</label>
          <angular2-multiselect
            [data]="filters.newOptions"
            [(ngModel)]="filters.selectedItems"
            [settings]="dropdownSettings2"
            (onSelect)="onFilterItemSelect($event)"
            (onDeSelect)="onFilterItemDeSelect($event)"
            (onSelectAll)="onFilterSelectAll($event, filters.dbName)"
            (onDeSelectAll)="onFilterDeSelectAll($event, filters.dbName)"
          >
          </angular2-multiselect>
        </div>
      </div>
    </div>
  </div>
  <app-bounce-loader *ngIf="loading"></app-bounce-loader>
  <div class="reportSection" *ngIf="!loading">
    <div class="managerReportData" *ngIf="reportType == 'managerReport'">
      <!-- <div class="tabsSection">
        <ul class="nav nav-tabs">
          <li class="active">
            <a data-toggle="tab" href="#reportAfterSurvey">
              <span>Report after survey</span>
            </a>
          </li>
          <li>
            <a data-toggle="tab" href="#reportAfterActionPlanning">
              <span>Report after action planning</span>
            </a>
          </li>
          <li>
            <a data-toggle="tab" href="#reportDuringImp">
              <span>Report during implementation</span>
            </a>
          </li>
          <li>
            <a data-toggle="tab" href="#reportAfterFinalSurvey">
              <span>Report after final survey</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="tab-content">
        <div id="reportAfterSurvey" class="tab-pane fade active animated fadeIn">
          <div class="table-responsive" *ngIf="
              managerReport.reportAfterSurvey != undefined &&
              managerReport.reportAfterSurvey.length > 0
            ">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th *ngFor="
                      let item of managerReport.reportAfterSurvey[0]
                        | keyvalue: returnZero
                    ">
                    {{ item.key }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="
                    let data of managerReport.reportAfterSurvey;
                    let i = index
                  ">
                  <td *ngFor="let item of data | keyvalue: returnZero">
                    {{ item.value }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div id="reportAfterActionPlanning" class="tab-pane fade in animated fadeIn">
          <div class="table-responsive" *ngIf="
              managerReport.reportAfterAP != undefined &&
              managerReport.reportAfterAP.length > 0
            ">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th *ngFor="
                      let item of managerReport.reportAfterAP[0]
                        | keyvalue: returnZero
                    ">
                    {{ item.key }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of managerReport.reportAfterAP; let i = index">
                  <td *ngFor="let item of data | keyvalue: returnZero">
                    {{ item.value }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div id="reportDuringImp" class="tab-pane fade in animated fadeIn">
          <div class="table-responsive" *ngIf="
              managerReport.reportDuringImplementation != undefined &&
              managerReport.reportDuringImplementation.length > 0
            ">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th *ngFor="
                      let item of managerReport.reportDuringImplementation[0]
                        | keyvalue: returnZero
                    ">
                    {{ item.key }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="
                    let data of managerReport.reportDuringImplementation;
                    let i = index
                  ">
                  <td *ngFor="let item of data | keyvalue: returnZero">
                    {{ item.value }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div id="reportAfterFinalSurvey" class="tab-pane fade in animated fadeIn">
          <div class="table-responsive" *ngIf="
              managerReport.reportAfterFinalSurvey != undefined &&
              managerReport.reportAfterFinalSurvey.length > 0
            ">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th *ngFor="
                      let item of managerReport.reportAfterFinalSurvey[0]
                        | keyvalue: returnZero
                    ">
                    {{ item.key }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="
                    let data of managerReport.reportAfterFinalSurvey;
                    let i = index
                  ">
                  <td *ngFor="let item of data | keyvalue: returnZero">
                    {{ item.value }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div> -->
      <div class="noPreview">
        <div class="center">No Data Preview</div>
      </div>
    </div>
    <div class="scoreSheetData" *ngIf="reportType == 'scoresheet'">
      <!-- <div class="tabsSection">
        <ul class="nav nav-tabs">
          <li class="active">
            <a data-toggle="tab" href="#selfAssessment">
              <span>Self Assessment</span>
            </a>
          </li>
          <li>
            <a data-toggle="tab" href="#teamFeedback">
              <span>Team Feedback</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="tab-content">
        <div id="selfAssessment" class="tab-pane fade in active animated fadeIn">
          <div class="table-responsive" *ngIf="
              scoreSheetReport.selfAssessment != undefined &&
              scoreSheetReport.selfAssessment.length > 0
            ">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th *ngFor="
                      let item of scoreSheetReport.selfAssessment[0]
                        | keyvalue: returnZero
                    ">
                    {{ item.key }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="
                    let data of scoreSheetReport.selfAssessment;
                    let i = index
                  ">
                  <td *ngFor="let item of data | keyvalue: returnZero">
                    {{ item.value }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div id="teamFeedback" class="tab-pane fade animated fadeIn">
          <div class="table-responsive" *ngIf="
              scoreSheetReport.teamFeedback != undefined &&
              scoreSheetReport.teamFeedback.length > 0
            ">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th *ngFor="
                      let item of scoreSheetReport.teamFeedback[0]
                        | keyvalue: returnZero
                    ">
                    {{ item.key }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="
                    let data of scoreSheetReport.teamFeedback;
                    let i = index
                  ">
                  <td *ngFor="let item of data | keyvalue: returnZero">
                    {{ item.value }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div> -->
      <div class="noPreview">
        <div class="center">No Data Preview</div>
      </div>
    </div>
    <div class="surveyResponseData" *ngIf="reportType == 'surveyResponse'">
      <div
        class="table-responsive"
        *ngIf="
          surveyReponseReport != undefined && surveyReponseReport.length > 0
        "
      >
        <table class="table table-hover">
          <thead>
            <tr>
              <th
                *ngFor="
                  let item of surveyReponseReport[0] | keyvalue : returnZero
                "
              >
                {{ item.key }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of surveyReponseReport; let i = index">
              <td *ngFor="let item of data | keyvalue : returnZero">
                {{ item.value }}
              </td>
            </tr>
            <tr>
              <td>
                <h4 class="totalResponseRate">
                  Total Response Rate: {{ totalResponseRate }}%
                </h4>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="noResultFound"
        *ngIf="
          !loading &&
          (surveyReponseReport == undefined || surveyReponseReport.length == 0)
        "
      >
        <h3>No Data Found</h3>
      </div>
    </div>
    <div class="masterResponseData" *ngIf="reportType == 'masterReport'">
      <div class="noPreview">
        <div class="center">No Data Preview</div>
      </div>
    </div>
    <div class="surveyResponseData" *ngIf="reportType == 'leaderShipReport'">
      <div
        class="table-responsive"
        *ngIf="leadershipReport != undefined && leadershipReport.length > 0"
        id="leaderboardReport"
      >
        <table class="table table-hover freeze-table">
          <thead>
            <tr>
              <th colspan="3" class="header start"></th>
              <th
                class="header demographics"
                colspan="3"
                style="z-index: 0; background-color: transparent"
              >
                Demographics
              </th>
              <th
                class="header pre-survey"
                colspan="10"
                style="z-index: 0; background-color: transparent"
              >
                Pre Survey
              </th>
              <th class="header post-survey" colspan="10">Post Survey</th>
              <th class="header qualifyChecks" colspan="9">
                Qualifying Checks
              </th>
            </tr>
            <tr>
              <th>Serial No</th>
              <th
                *ngFor="let item of leadershipReport[0] | keyvalue : returnZero"
              >
                {{ item.key }}
              </th>
            </tr>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>Yes/No</th>
              <th>Not more than 10 point drop</th>
              <th>High Moderate Low</th>
              <th>Min {{ gmcCriteria.learningDevelopmentMin }} modules</th>
              <th>
                Min {{ gmcCriteria.implementationMin }} Implimentation posts
              </th>
              <th>60% or more on at least 1 plan</th>
              <th>Yes / No</th>
              <th>Enter Score</th>
              <th>Certified / Not Certified</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of leadershipReport; let i = index">
              <td>{{ i + 1 }}</td>
              <td *ngFor="let item of data | keyvalue : returnZero">
                {{ item.value }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="noResultFound"
        *ngIf="
          !loading &&
          (leadershipReport == undefined || leadershipReport.length == 0)
        "
      >
        <h3>No Data Found</h3>
      </div>
    </div>
  </div>
</div>
