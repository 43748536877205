// import { CompanyServices } from './company/company.service';
// import { ExcelService } from './excel-service.service';
import { AuthService } from "./auth.service";
// import { LoginServices } from './login/login.service';
// import { AdminServices } from './admin/admin.service';
// import { DataSvc } from "./manager/wijmo.services";
import { TokenInterceptor } from "./app.interceptor";
// import { LoginModule } from './login/login.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { DataTablesModule } from "angular-datatables";
import { MaterialModule } from "./material.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
// import { ChartModule , HIGHCHARTS_MODULES } from 'angular-highcharts';
// import { WindowRef } from "./manager/pricing/WindowRef";
import { AppComponent } from "./app.component";
// import { AppRoutingModule, routingModule } from './app-routing.module';
import { CommonModule } from "@angular/common";
import {
  RoleGuardService as RoleGuard,
  RoleGuardService,
} from "./role-guard.service";
import { CompanyRoleGuardService as CompanyRoleGuard } from "./company.rollguard.service";
// import { AuthGuardService } from './auth-guard.service';
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
// import { DatetimePopupModule } from 'ngx-bootstrap-datetime-popup';
// import { ScrollService } from './services/scroll.service';
// import { SurveyService } from './services/survey.service';
// import { PopoverModule } from "ngx-bootstrap";
// import { ScoreCalculationComponent } from './score-calculation/score-calculation.component';
// import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from "../environments/environment";
// import { TriggersService } from './triggers.service';
import { DragDropModule } from "@angular/cdk/drag-drop";
// import { ErrorDialogComponent } from './error-dialog/errordialog.component';
// import { FeedbackModalComponent } from './feedback-modal/feedback-modal.component';
// import { ErrorDialogService } from './error-dialog/errordialog.service';
import { LoaderInterceptorService } from "./loader.interceptor";
import { LoaderComponent } from "./loader/loader.component";
import { LoaderService } from "./services/loader.service";

// import { R2SelectionHrComponent } from './r2-selection-hr/r2-selection-hr.component';
// import { R2SurveyComponent } from './r2-survey/r2-survey.component';
import { AngularMultiSelectModule } from "angular2-multiselect-dropdown";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "./shared.module";
// import { HrAcceptancePageComponent } from './hr-acceptance-page/hr-acceptance-page.component';
// import { ModalService } from './manager/modal.service';
import { ModalModule, BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Ng2DeviceService } from "ng2-device-detector";
// import { NotSupportedComponent } from './not-supported/not-supported.component';
// import {
//   SocialLoginModule,
//   SocialAuthServiceConfig,
//   GoogleLoginProvider,
// } from 'angularx-social-login';
// import {
//   GoogleLoginProvider,
//   FacebookLoginProvider,
// } from 'angularx-social-login';
// import { FeedbackPageComponent } from './feedback-page/feedback-page.component';
import { MatChipsModule } from "@angular/material/chips";
import { DataService } from "./company/data.service";
// import { R2PolicyComponent } from './r2-policy/r2-policy.component';
import { PdfJsViewerModule } from "ng2-pdfjs-viewer";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { AuthGuardService } from "./auth-guard.service";
import { ServiceWorkerModule } from "@angular/service-worker";
import { TriggersService } from "./triggers.service";
// import { SortPipe } from './services/sort.service';
// import { RoundOffPipe } from './pipes/roundOff.pipe';
import { RouterModule, Routes } from "@angular/router";
const googleLoginOptions = {
  scope: "https://www.googleapis.com/auth/calendar",
};

// let config = new SocailAuthServiceConfig([
//   {
//     id: GoogleLoginProvider.PROVIDER_ID,
//     provider: new GoogleLoginProvider(
//       '1094121596528-osgleejhl1rvkovnobm91mtb7kjkf158.apps.googleusercontent.com',
//       googleLoginOptions
//     ),
//   },
// ]);

// export function provideConfig() {
//   return config;
// }

import {
  SocialAuthService,
  SocialLoginModule,
  SocialAuthServiceConfig,
  GoogleLoginProvider,
  FacebookLoginProvider,
} from "angularx-social-login";
import { LoginServices } from "./login/login.service";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { WindowRef } from "./company/manager-list/company-added-manager-list/WindowRef";
// import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const config: SocialAuthServiceConfig = {
  autoLogin: false,
  providers: [
    {
      id: GoogleLoginProvider.PROVIDER_ID,
      provider: new GoogleLoginProvider(
        "1094121596528-osgleejhl1rvkovnobm91mtb7kjkf158.apps.googleusercontent.com",
        googleLoginOptions
      ),
    },
  ],
};

export function provideConfig() {
  return config;
}

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "/company",
    // canActivate: [RoleGuardService],
  },
  {
    path: "admin",
    loadChildren: () =>
      import("./admin/admin.module").then((x) => x.AdminModule),
  },

  {
    path: "company",
    // canActivate: [RoleGuardService],
    loadChildren: () =>
      import("./company/company.module").then((x) => x.CompanyModule),
  },
  { path: "404", component: PageNotFoundComponent },
  /*
  {
    path: 'scoreCalc/e3efd54cdeb5eabd520c19f78fec9ee7',
    component: ScoreCalculationComponent,
  },
  { path: 'not-supported-browser', component: NotSupportedComponent },
  { path: 'r2-survey/:type/:id', component: R2SelectionHrComponent },
  { path: 'r2-survey/:id', component: R2SurveyComponent },

  { path: 'r2-policy', component: R2PolicyComponent },
  { path: 'acceptTermsAndCondition/:id', component: HrAcceptancePageComponent },
  // { path: "gpms21", component: NewLandingComponent },
  { path: 'sso-check/:token', component: UniversalScreenComponent },
  { path: 'feedback/:token/:formName', component: FeedbackPageComponent },
  */
  { path: "**", redirectTo: "/404" },
  { path: "**", redirectTo: "/404" },
];

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    /*ScoreCalculationComponent,
    ErrorDialogComponent,
    FeedbackModalComponent,
    R2SelectionHrComponent,
    R2SurveyComponent,
    HrAcceptancePageComponent,
    NotSupportedComponent,
    FeedbackPageComponent,
    R2PolicyComponent,
    SortPipe,*/
    //RoundOffPipe,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    // AppRoutingModule,
    // routingModule,
    HttpClientModule,
    // RouterModule,
    MaterialModule,
    DataTablesModule,
    DragDropModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatChipsModule,
    PdfJsViewerModule,
    PdfViewerModule,
    AngularMultiSelectModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    // SocialLoginModule,
    MatChipsModule,
    RouterModule.forRoot(routes),
    BrowserAnimationsModule,

    /*  
    PopoverModule.forRoot(),
    AngularFontAwesomeModule,
    
    AngularFontAwesomeModule,
    */
  ],
  providers: [
    // DataSvc,
    LoaderService,
    // ErrorDialogService,
    RoleGuard,
    CompanyRoleGuard,
    DataService,
    AuthGuardService,
    AuthService,
    LoginServices,
    // SurveyService,
    TriggersService,
    // ExcelService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true,
    },
    // ScrollService,
    // ModalService,
    BsModalService,
    BsModalRef,
    WindowRef,
    Ng2DeviceService,
    {
      provide: SocialAuthService,
      useFactory: provideConfig,
    },
  ],
  entryComponents: [
    // ErrorDialogComponent, FeedbackModalComponent
  ],

  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
