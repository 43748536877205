import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "sortby",
})
export class SortbyPipe implements PipeTransform {
  transform(items: any[], property: string, direction: string): any[] {
    if (!items || !property) {
      return items;
    }

    return items.sort((a, b) => {
      const comparison =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return direction === "desc" ? comparison * -1 : comparison;
    });
  }
}
