<app-loader *ngIf="loading"></app-loader>
<div *ngIf="!loading" class="round-2-container">
  <p class="text-center round-two-title">Round Two List</p>
  <section>
    <div class="row">
      <div class="survey-group-dropdown col-md-6">
        <label> Select Survey Groups : </label>
        <select
          class="survey-grp-select"
          [(ngModel)]="surveyGroup"
          (change)="selectSurveyGroup()"
          placeholder="---Select Survey Group ---"
        >
          <option *ngFor="let option of surveyGroupList" [ngValue]="option">
            {{ option.startDate }} {{ "- " + option.endDate }}
            {{ ", Survey Type: " + option.surveyType }}
          </option>
        </select>
      </div>
      <div class="survey-group-details col-md-6" *ngIf="surveyGroup">
        <div class="details-card">
          <div class="details-header">
            <p class="detail-name">
              <strong>Survey Type:</strong> {{ surveyGroup.surveyType }}
            </p>
            <p
              [ngClass]="{
                onGoing: surveyGroup.text === 'On going',
                expired: surveyGroup.text === 'Closed!'
              }"
            >
              {{ surveyGroup.text }}
            </p>
          </div>
          <div class="details-header">
            <p class="detail-name">
              <strong>Start Date: </strong>{{ surveyGroup.startDate }}
            </p>
            <p class="detail-name">
              <strong>End Date:</strong> {{ surveyGroup.endDate }}
            </p>
          </div>

          <p class="detail-name">
            Total Surveys: {{ surveyGroup.surveyLength }}
          </p>
          <div class="survey-card-button">
            <button
              class="pre-processed-button"
              *ngIf="showViewProcessBtn"
              (click)="getRTwoData(surveyGroup, processedData)"
            >
              View Processed Data
            </button>
            <button
              class="pre-processed-button"
              [disabled]="surveyGroup.text === 'On going'"
              (click)="getRTwoData(surveyGroup, processedData)"
            >
              Get Data
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section *ngIf="preProcessedData.length > 0 && !showProcessedStatus">
    <div class="manager-table-container">
      <p>Round Two Pre-Processed List</p>

      <table
        class="table-container"
        mat-table
        [dataSource]="preProcessedDataSource"
        matSort
      >
        <!-- <ng-container matColumnDef="selectAll">
          <th mat-header-cell *matHeaderCellDef>Select</th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox
              [(ngModel)]="element.status"
              (change)="onStatusChange(element, $event)"
            >
            </mat-checkbox>
          </td>
        </ng-container> -->

        <ng-container matColumnDef="mgrName">
          <th mat-header-cell *matHeaderCellDef>Manager Name</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="mgrEmail">
          <th mat-header-cell *matHeaderCellDef>Manager Email</th>
          <td mat-cell *matCellDef="let element">
            {{ element.email }}
          </td>
        </ng-container>

        <ng-container matColumnDef="tfsScore">
          <th mat-header-cell *matHeaderCellDef>
            TFS Score | Total Participants
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.overAllScore | roundoff : 2 }} |
            {{ element.participants }}
          </td>
        </ng-container>

        <ng-container matColumnDef="teamMemberScore">
          <th mat-header-cell *matHeaderCellDef>
            Team Member Score | Total Team Members
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.teamMemberScore | roundoff : 2 }} |
            {{ element.totalteamMembers }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="preProcessedColumn"></tr>
        <tr mat-row *matRowDef="let row; columns: preProcessedColumn"></tr>
      </table>

      <mat-paginator
        [pageSizeOptions]="[7, 14, 21, 28]"
        [pageSize]="7"
        [length]="7"
      >
      </mat-paginator>

      <div class="process-table-btn">
        <button class="process-btn" (click)="processData()">
          Process Data
        </button>
      </div>
      <!-- <mat-table >
  
        

        <ng-container matColumnDef="teamMemberScore">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Team Member Score</mat-header-cell
          >
          <mat-cell *matCellDef="let element">
            {{ element.teamMemberScore | roundoff : 3 }}</mat-cell
          >
        </ng-container>
        <mat-header-row *matHeaderRowDef="preProcessedColumn"></mat-header-row>
        <mat-row *matRowDef="let row; columns: preProcessedColumn"></mat-row>
      </mat-table> -->
    </div>
  </section>

  <section *ngIf="showProcessedStatus">
    <div>
      <div class="action-container">
        <div class="score-control">
          <p class="round-bar-text">Choose the scores</p>
          <mat-slider
            class="volume-bar"
            thumbLabel
            #volumeBar
            (change)="scoreControl(volumeBar)"
            min="1"
            max="5"
            step="0.1"
            [value]="scoreValue"
          ></mat-slider>
        </div>
        <div class="status-container">
          <ul class="gmi-stepper">
            <li
              (click)="openAddModal(sendEmail)"
              [ngClass]="{ active: showSurveyDashboard }"
            >
              Send Hr Mail
            </li>
            <div
              class="divider"
              [ngClass]="{ activeDivider: showSurveyDashboard }"
            ></div>

            <li [ngClass]="{ active: hrAccepted }">Hr Acceptance</li>
            <div
              class="divider"
              [ngClass]="{ activeDivider: hrAccepted }"
            ></div>

            <li
              (click)="openSurveyLaunch(surveyTemplate)"
              [ngClass]="{ active: surveyIsLaunched }"
            >
              R2 Survey launched
            </li>
          </ul>
        </div>
        <div class="reports-container">
          <button class="btn gmi-primary-btn" (click)="downloadManagerReport()">
            Manager List Report
          </button>
          <button class="btn gmi-primary-btn" (click)="downloadR2Report()">
            R2 Report
          </button>
          <button
            class="btn gmi-primary-btn"
            (click)="downloadR2FeedbackReport()"
          >
            R2 Feedback Report
          </button>
        </div>
      </div>
      <div class="manager-table-container">
        <table
          class="table-container"
          mat-table
          [dataSource]="processedDataSource"
          matSort
        >
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                (change)="toggleSelectAll()"
                [checked]="allSelected"
                [indeterminate]="selectedRows.length > 0 && !allSelected"
              ></mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let element">
              <mat-checkbox
                (change)="toggleRowSelection(element)"
                [checked]="isSelected(element)"
              ></mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="mgrName">
            <th mat-header-cell *matHeaderCellDef>Manager Name</th>
            <td mat-cell *matCellDef="let element">
              {{ element.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="mgrEmail">
            <th mat-header-cell *matHeaderCellDef>Manager Email</th>
            <td mat-cell *matCellDef="let element">
              {{ element.email }}
            </td>
          </ng-container>

          <ng-container matColumnDef="oldTfsScore">
            <th mat-header-cell *matHeaderCellDef>
              Old TFS Score | <br />Total Participants
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.overAllScore | roundoff : 2 }} |
              {{ element.participants }}
            </td>
          </ng-container>

          <ng-container matColumnDef="newTfsScore">
            <th mat-header-cell *matHeaderCellDef>
              New TFS Score | <br />Valid Participants
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.newtfsScore | roundoff : 2 }} |
              {{ element.managerRespondents }}
            </td>
          </ng-container>

          <ng-container matColumnDef="oldTeamMemberScore">
            <th mat-header-cell *matHeaderCellDef>
              Old TeamMember Score | <br />
              Team Members
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.teamMemberScore | roundoff : 2 }} |
              {{ element.totalteamMembers }}
            </td>
          </ng-container>

          <ng-container matColumnDef="newTeamMemberScore">
            <th mat-header-cell *matHeaderCellDef>
              New Team Member Score | <br />Valid Team Members
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.newteamMemberScore | roundoff : 2 }} |
              {{ element.totalTeamMembersFinal }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-paginator
          [length]="7"
          [pageSize]="7"
          [pageSizeOptions]="[7, 14, 28]"
        >
        </mat-paginator>

        <div class="process-table-btn">
          <button
            class="process-btn"
            *ngIf="hrAccepted == false"
            (click)="openAddModal(sendEmail)"
          >
            Confirm Data
          </button>
          <button
            class="process-btn"
            *ngIf="hrAccepted == true"
            [disabled]="hrAccepted == false"
            (click)="openSurveyLaunch(surveyTemplate)"
          >
            Launch Survey
          </button>
        </div>
      </div>
    </div>
  </section>

  <ng-template #showDefaultMessage>
    <!-- <p class="title">
      Manager is not found for <strong>{{ scoreValue }}</strong>
    </p> -->
  </ng-template>

  <ng-template #sendEmail>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Review and Send Email</h4>
      <button
        type="button "
        class="close pull-right"
        aria-label="Close "
        (click)="modalRef.hide()"
      >
        <span aria-hidden="true ">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="form-container">
          <form (ngSubmit)="sendMailHandler(hrForm)" #hrForm="ngForm">
            <div class="form-group">
              <label for="hrName">Hr Name</label>
              <input
                type="text"
                placeholder="Enter the Hr Name"
                required
                [(ngModel)]="emailModel.hrName"
                name="hrName"
                #hrName="ngModel"
              />
              <div [hidden]="hrName.valid || hrName.pristine" class="danger">
                Hr Name is required
              </div>
            </div>
            <div class="form-group">
              <label for="hrName">Hr Email</label>
              <input
                type="email"
                placeholder="Enter the Hr Email"
                required
                [(ngModel)]="emailModel.hrEmail"
                name="hrEmail"
                #hrEmail="ngModel"
                pattern="[^ @]*@[^ @]*"
              />
              <div [hidden]="hrEmail.valid || hrEmail.pristine" class="danger">
                Hr Email is required
              </div>
            </div>
            <div
              *ngIf="selectedRows.length > 0; else showDefaultMessage"
              class="managers-list-container"
            >
              <p class="manager-list-detail">Managers List detail</p>
              <div class="manager-list">
                <p class="detail" *ngFor="let manager of selectedRows">
                  <strong class="manager-name">{{ manager.name }}</strong>
                  <span>{{ manager.email }}</span>
                </p>
              </div>
            </div>
            <div class="button-container">
              <button
                type="submit"
                class="btn btn-success custom-btn"
                [disabled]="!hrForm.form.valid || selectedRows.length == 0"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #surveyTemplate>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Review and Launch Survey</h4>
      <button
        type="button "
        class="close pull-right"
        aria-label="Close "
        (click)="modalRef.hide()"
      >
        <span aria-hidden="true ">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="form-container">
          <div
            *ngIf="selectedManagers.length > 0; else showDefaultMessage"
            class="managers-list-container"
          >
            <mat-form-field *ngIf="!surveyIsLaunched">
              <input
                matInput
                [(ngModel)]="surveyEndDate"
                [min]="minDate"
                [matDatepicker]="picker"
                placeholder="Choose end date"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <div *ngIf="surveyEnded">
              <p class="title">Survey Is Ended, Extend Survey!</p>
              <mat-form-field>
                <input
                  matInput
                  [(ngModel)]="updateEndDate"
                  [min]="minDate"
                  [matDatepicker]="picker"
                  placeholder="Choose new end date"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="dateContainer">
              <p class="">Start Date : {{ minDate | date : "MMM d, y" }}</p>
              <p class="">End Date : {{ surveyEndDate | date : "MMM d, y" }}</p>
            </div>

            <p class="manager-list-detail">Managers List detail</p>
            <div class="manager-list">
              <p class="detail" *ngFor="let manager of selectedManagers">
                <strong class="manager-name">{{ manager.name }}</strong>
                <span>{{ manager.email }}</span>
              </p>
            </div>
          </div>
          <div class="button-container">
            <button
              type="submit"
              class="btn btn-success custom-btn"
              (click)="launchSurvey()"
            >
              {{ surveyEnded ? "Extend Survey" : "Launch Survey" }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #processedData>
    <div class="modal-header">
      <h4 style="font-size: 22px" class="modal-title pull-left">Round Two</h4>
      <button
        type="button "
        class="close pull-right"
        aria-label="Close "
        (click)="modalRef.hide()"
      >
        <span aria-hidden="true ">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="status-container" *ngIf="showSocketStatus">
        <p class="title">Total no of Round Two Data : {{ totalData }}</p>
        <progress class="custom-progress" value="{{ dataProcessed }}" max="100">
          {{ dataProcessed }}
        </progress>
      </div>
      <div class="message-container" *ngIf="!showSocketStatus">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>

        <p class="loading-text">Please wait we are preparing the Data</p>
      </div>
    </div>
  </ng-template>
</div>
