<div class="container">
  <div class="row">
    <div [ngClass]="getColumnClass()">
      <h3 style="display: flex; justify-content: center; margin-top: 20px">
        GMC Criteria Configuration
      </h3>
    </div>
    <div class="col-lg-2" *ngIf="batchOptions?.length">
      <button
        class="btn btn-gmi-primary gmi-primary-btn update"
        (click)="submit()"
      >
        Update
      </button>
    </div>
  </div>
  <div class="row" *ngIf="batchOptions.length; else noData">
    <div
      class="col-lg-6 pt-10"
      *ngFor="let batch of batchOptions; let i = index"
    >
      <mat-card class="batch-card">
        <mat-card-header
          style="margin-left: 0px !important; margin-right: 0px !important"
        >
          <div class="flexClass">
            <mat-card-title>{{ batch }}</mat-card-title>
            <p class="redText">* Enter minimum number of modules required</p>
          </div>
        </mat-card-header>
        <mat-card-content>
          <div class="field-container">
            <div
              *ngFor="let question of packageOptions[i]; let j = index"
              class="field"
            >
              <label>{{ question.label }} </label>
              <!-- <input
                type="number"
                [(ngModel)]="packageOptions[i][j].value"
                [min]="0"
                [max]="100"
              /> -->
              <input
                type="number"
                [(ngModel)]="packageOptions[i][j].value"
                [min]="0"
                [max]="100"
                (keydown)="preventNegativeInput($event)"
                (input)="enforceMaxValue($event)"
              />
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <ng-template #noData>
    <h3 style="display: flex; justify-content: center">
      No batches found for GMC Configuration
    </h3>
  </ng-template>
</div>
