import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  ChangeDetectorRef,
} from "@angular/core";

import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { CompanyServices } from "../../company.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { LoginServices } from "../../../login/login.service";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
// import * as jwt_decode from 'jwt-decode';
import { jwtDecode } from "jwt-decode";

import { BsModalService } from "ngx-bootstrap/modal";
import swal from "sweetalert2";
// import { WindowRef } from "../../../manager/pricing/WindowRef";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { Subject } from "rxjs";
import { map } from "rxjs";
@Component({
  selector: "app-individual-manager-list",
  templateUrl: "./individual-manager-list.component.html",
  styleUrls: ["./individual-manager-list.component.css"],
})
export class IndividualManagerListComponent implements OnInit {
  private ngUnsubscribe: Subject<any> = new Subject();
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  displayedColumns: string[] = [
    "name",
    "email",
    "profileLink",
    "status",
    "action",
  ];
  searchKey!: string;
  displayNoRecords = false;
  globalCopy: any;
  managers: any = [];
  isRegister: any = {};
  isSas: any = {};
  isPackage: any = {};
  isCertificate: any = {};
  text: any = {};
  isTeamFeedBack: any = {};
  isActionPlanning: any = {};
  data: any = [];
  data1: any;
  data9: any;
  modalRef!: BsModalRef;
  managerProfile: any;
  packagesFeature: any = [];
  currentSelected: any = "";
  flagStatus: boolean = false;
  loginId: any;
  seCompanyId!: string;
  subsPackage: any;
  package: any;
  packageId: any;
  price: any;
  loading!: boolean;
  sub_id: any;
  special: any = [];
  free: any = [];
  basic: any = [];
  premium: any = [];
  public serverUrl = environment.hostUrl;
  public commonUserApiUrl = "/v1/user";
  public companyApiUrl = "/v1/company";
  uploadedManagerList: any = [];

  constructor(
    private companyService: CompanyServices,
    public snackBar: MatSnackBar,
    private modalService: BsModalService,
    private loginService: LoginServices,
    // public winRef: WindowRef,
    private http: HttpClient,
    private cd: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    this.getIndividualManagersList();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  ngOnInit() {
    // this.getIndividualManagersList();
    let token: any = localStorage.getItem("token");
    let deodedToken = this.getDecodedAccessToken(token);
    //console.log(deodedToken);
    this.seCompanyId = deodedToken.companyId;
  }

  applyFilter() {
    this.dataSource.filter = this.searchKey.trim().toLowerCase();
    if (this.dataSource.filteredData.length == 0) {
      this.displayNoRecords = true;
    } else {
      this.displayNoRecords = false;
    }
  }
  getDecodedAccessToken(token: string): any {
    try {
      return jwtDecode(token);
    } catch (Error) {
      return null;
    }
  }
  onSearchClear() {
    this.searchKey = "";
    this.applyFilter();
  }
  copyToClipboard(id: any) {
    this.globalCopy = document.getElementById(`copy${id}`);
    this.globalCopy.select();
    //console.log(id, "ddd");
    document.execCommand("copy");
    this.snackBar.open("copied to clipboard", "", {
      duration: 3000,
    });
  }
  getIndividualManagersList() {
    this.companyService.managerData1.subscribe((response) => {
      this.managers = response;
      // console.log(this.managers , 'maagers')
      this.data = [];
      response.forEach((manager: any) => {
        if (
          manager.personalInfo &&
          manager.personalInfo.isRegisteredByCompanyToken
        ) {
          this.text[manager._id] = "Pending";
        } else {
          this.text[manager._id] = "Ask for data merge";
        }
        if (manager.personalInfo.isVerified) {
          this.isRegister[manager._id] = true;
        } else {
          this.isRegister[manager._id] = false;
        }
        if (manager.package_info && manager.package_info.features.length > 0) {
          this.isPackage[manager._id] = true;
        } else {
          this.isPackage[manager._id] = false;
        }
        if (manager.selfAssesments && manager.selfAssesments.length > 0) {
          this.isSas[manager._id] = true;
        } else {
          this.isSas[manager._id] = false;
        }
        if (
          manager.managerInfo &&
          manager.managerInfo.badge &&
          manager.managerInfo.badge.length > 0
        ) {
          this.isCertificate[manager._id] = true;
        } else {
          this.isCertificate[manager._id] = false;
        }
        if (manager.surveys && manager.surveys.length > 0) {
          this.isTeamFeedBack[manager._id] = true;
        } else {
          this.isTeamFeedBack[manager._id] = false;
        }
        if (manager.actionPlannings && manager.actionPlannings.length > 0) {
          this.isActionPlanning[manager._id] = true;
        } else {
          this.isActionPlanning[manager._id] = false;
        }
        if (manager.package_info && manager.package_info.features.length > 0) {
          if (new Date(manager.package_info.endDate) > new Date(Date.now())) {
            manager.packageStatus = "Active";
          } else {
            manager.packageStatus = "Expire";
          }
        }
        if (manager.personalInfo.isRegisteredByCompany == false) {
          let managerInfo = {
            name: manager.personalInfo.name,
            email: manager.personalInfo.email,
            profileLink: manager.personalInfo.userName,
            package: manager.packageStatus,
            id: manager._id,
          };
          this.data.push(managerInfo);
        }
      });
      let removedDuplicateManager = this.removeDuplicates(this.data, "email");
      //console.log(removedDuplicateManager,'removd')
      this.dataSource.data = removedDuplicateManager;
      //console.log(this.dataSource , 'e')
    });
  }
  removeDuplicates(myArr: any, prop: any) {
    return myArr.filter((obj: any, pos: any, arr: any) => {
      return arr.map((mapObj: any) => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }
  openModal1(template: TemplateRef<any>, id: any) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: "my-modal" })
    );
    this.companyService.addModal(this.modalRef);
    this.managers.forEach((manager: any) => {
      if (manager._id == id) {
        this.managerProfile = manager;
        this.loginService.features().subscribe((response: any) => {
          this.packagesFeature = response.docs;
          if (this.packagesFeature) {
            this.loginService.packageName(id).subscribe((response: any) => {
              this.currentSelected = response.docs;
              if (this.currentSelected) {
                if (this.packagesFeature.length > 0) {
                  this.flagStatus = false;
                  this.packagesFeature.forEach(
                    (packagesFeature: any, index: any) => {
                      // packagesFeature.flag = true;
                      if (
                        packagesFeature.name == this.currentSelected &&
                        this.flagStatus == false
                      ) {
                        packagesFeature.flag = true;
                        this.flagStatus = true;
                      } else if (this.flagStatus == true) {
                        packagesFeature.flag = false;
                      } else {
                        packagesFeature.flag = true;
                      }
                    }
                  );
                }
              }
            });
          }
        });
      }
    });
  }

  subscribe(packageName: any, amount: any) {
    this.loading = true;
    if (packageName == "Special") {
      this.sub_id = this.packagesFeature[3]._id;
      this.packagesFeature[0].features.forEach((res: any) => {
        this.special.push(res.key);
      });
      this.packagesFeature[1].features.forEach((res: any) => {
        this.special.push(res.key);
      });
      this.packagesFeature[2].features.forEach((res: any) => {
        this.special.push(res.key);
      });
      this.packagesFeature[3].features.forEach((res: any) => {
        this.special.push(res.key);
      });
      this.managerSubscription(
        amount,
        this.managerProfile.personalInfo.email,
        this.sub_id,
        this.managerProfile._id,
        this.special,
        packageName
      );
    } else if (packageName == "Free") {
      this.sub_id = this.packagesFeature[0]._id;
      this.packagesFeature[0].features.forEach((res: any) => {
        this.free.push(res.key);
      });
      this.loginService
        .addFeatures(this.free, this.managerProfile._id, packageName)
        .subscribe((response) => {
          //console.log(response);
        });
    } else if (packageName == "Basic") {
      this.sub_id = this.packagesFeature[1]._id;
      this.packagesFeature[0].features.forEach((res: any) => {
        this.basic.push(res.key);
      });
      this.packagesFeature[1].features.forEach((res: any) => {
        this.basic.push(res.key);
      });
      this.managerSubscription(
        amount,
        this.managerProfile.personalInfo.email,
        this.sub_id,
        this.managerProfile._id,
        this.basic,
        packageName
      );
    } else if (packageName == "Premium") {
      this.sub_id = this.packagesFeature[2]._id;
      this.packagesFeature[0].features.forEach((res: any) => {
        this.premium.push(res.key);
      });
      this.packagesFeature[1].features.forEach((res: any) => {
        this.premium.push(res.key);
      });
      this.packagesFeature[2].features.forEach((res: any) => {
        this.premium.push(res.key);
      });
      this.managerSubscription(
        amount,
        this.managerProfile.personalInfo.email,
        this.sub_id,
        this.managerProfile._id,
        this.premium,
        packageName
      );
      this.loading = false;
    }
  }

  managerSubscription(
    amount: any,
    email: any,
    sub_id: any,
    id: any,
    packageName: any,
    subPackage: any
  ) {
    //console.log(amount, "amoutn");
    this.loginId = id;
    this.subsPackage = subPackage;
    this.package = packageName;
    this.packageId = sub_id;
    this.price = amount * 100;
    let options = {
      key: "rzp_test_taFORmK6Ekzb4m",
      // key: 'rzp_live_v0FdLtDQ2apNzC',
      amount: this.price,
      name: subPackage,
      description: "You are one step away from your awesome journey",
      handler: this.paymentCapture.bind(this),

      prefill: {
        name: "Anonymous",
        email: email,
      },
      notes: {
        address: "Goa,India",
      },
      theme: {
        color: "#005071",
      },
    };
    // const rzp1 = new this.winRef.nativeWindow.Razorpay(options);
    // rzp1.open();
  }
  paymentCapture(response: any) {
    const amountInPaisa = this.price;
    const paymentId = response.razorpay_payment_id;
    this.capturePayment(
      amountInPaisa,
      paymentId,
      this.loginId,
      this.subsPackage
    );
  }

  capturePayment(amount: any, paymentId: any, id: any, packageName: any) {
    this.http
      .post(this.serverUrl + this.companyApiUrl + "/capture", {
        amount,
        paymentId,
        id,
      })
      .pipe(
        map((response5) => {
          return <any>response5;
        })
      )
      .subscribe((response1: Response) => {
        this.data1 = response1;
        if (this.data1.status) {
          this.loginService
            .addFeatures(this.package, id, packageName)
            .subscribe((response: any) => {
              this.data9 = response;
              if (this.data9.status) {
                this.data.forEach((manager: any) => {
                  if (manager.id == id) {
                    manager.package = "Active";
                  }
                });
                this.currentSelected = packageName;
                if (this.packagesFeature.length > 0) {
                  this.flagStatus = false;
                  this.packagesFeature.forEach(
                    (packagesFeature: any, index: any) => {
                      if (
                        packagesFeature.name == this.currentSelected &&
                        this.flagStatus == false
                      ) {
                        packagesFeature.flag = true;
                        this.flagStatus = true;
                      } else if (this.flagStatus == true) {
                        packagesFeature.flag = false;
                      } else {
                        packagesFeature.flag = true;
                      }
                    }
                  );
                }
                this.flagStatus = false;
                swal.fire({
                  title: "Subscription successfull",
                  text:
                    "You have subscribed to our" +
                    " " +
                    this.subsPackage +
                    " " +
                    "Packages",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 2500,
                });
                this.loading = false;
                this.cd.detectChanges();
              } else {
                swal.fire({
                  title: "Something went wrong",
                  text: "Error happen",
                  icon: "error",
                  showConfirmButton: false,
                  timer: 2500,
                });
              }
            });
        } else {
          swal.fire({
            title: "Something went wrong",
            text: "Error happen",
            icon: "error",
            showConfirmButton: false,
            timer: 2500,
          });
        }
      });
    this.modalRef.hide();
    // this.modalRef = null;
  }
  askForMergingData(id: any) {
    let data = {
      mgrId: id,
      seCompanyId: this.seCompanyId,
    };
    //console.log(data)
    this.loading = true;
    this.companyService.askForMerging(data).subscribe((response: any) => {
      this.loading = false;
      if (response.status) {
        swal.fire({
          title: "Merging Data",
          text: response.message,
          icon: "success",
          showConfirmButton: false,
          timer: 2500,
        });
        this.text[id] = "Pending";
        // this.editShow[this.email1] = false
        this.text[id] = "Pending";
        // this.editShow[this.email1] = false;
        this.loading = false;
      } else {
        swal.fire({
          title: "Merging Data",
          text: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    });
  }

  refreshComponent() {
    this.companyService.refreshComponent.subscribe((response: any) => {
      let managerInfo: any = {
        name: response.personalInfo.name,
        email: response.personalInfo.email,
        profileLink: response.personalInfo.userName,
        package: response.packageStatus,
        packageName: response.packageName,
        id: response._id,
        participants: response.participants,
      };
      this.managers.push(response);
      this.uploadedManagerList.push(managerInfo);
      this.dataSource.data = [...this.uploadedManagerList];
    });
  }

  ngOnDestroy() {
    // console.log("testing3");
    this.dataSource = new MatTableDataSource();
    this.ngUnsubscribe.next(this.dataSource);
    this.ngUnsubscribe.complete();
  }
}
