import { Component } from '@angular/core';
import { setTheme } from 'ngx-bootstrap/utils';
import { SwPush } from '@angular/service-worker';
// import { TriggersService } from './triggers.service';
import { SwUpdate } from '@angular/service-worker';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
const VAPID_PUBLIC =
  'BKZgqyWgaDx5dSqqZbRq4-YNZwvVNTe1bCuanMKLPZCBsa70QlmYoHwSdlYfba-lYnmQvn-ULCYGA5vT92M3dYo';

// import io from 'socket.io-client';

import { environment } from '../environments/environment';
import { TriggersService } from './triggers.service';
// import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'Great Manager Institute';
  navigator: any = window.navigator;
  window: any = window;
  promptEvent: any;
  status = 'ONLINE';
  private serverUrl = environment.apiURl;
  // private socket = io(this.serverUrl, { reconnection: true });
  isConnected = true;
  constructor(
    private swPush: SwPush,
    private triggerService: TriggersService,
    private swUpdate: SwUpdate,
    private router: Router
  ) {
    this.checkBrowserDetails();
    this.clearCacheStorage();
    window.addEventListener('beforeinstallprompt', (event) => {
      this.promptEvent = event;
    });

    // console.log(this.swPush.isEnabled);
    if (this.swPush.isEnabled) {
      // this.swUpdate.available.subscribe((event) => {
      //   // console.log(event);
      //   alert(
      //     "A newer version is now available. Refresh the page now to update the cache"
      //   );
      //   window.location.reload(true);
      // });
      // this.swUpdate.checkForUpdate();
      // // console.log(this.swUpdate.checkForUpdate());
    }
    // }else{
    //   alert("This browser does not support desktop notification");
    // }
    // setTheme('bs3');
    // router.events
    //   .filter((event) => event instanceof NavigationEnd)
    //   .subscribe((event: NavigationEnd) => {
    //     window.scroll(0, 0);
    //   });
  }

  // async clearCacheStorage(): Promise<Promise<boolean>[][]> {
  // async clearCacheStorage() {
  //   const cacheKeys = await caches.keys();
  //   return await Promise.all(
  //     cacheKeys.map((cacheKey) => {
  //       const ngswRegex = /^(ngsw).*/;
  //       if (ngswRegex.test(cacheKey)) {
  //         return caches
  //           .open(cacheKey)
  //           .then((cache) =>
  //             cache
  //               .keys()
  //               .then((requests) => requests.map((req) => cache.delete(req)))
  //           );
  //       }
  //     })
  //   );
  // }

  async clearCacheStorage() {
    const cacheKeys = await caches.keys();
    // // console.log(cacheKeys, "cache");
    return await Promise.all(
      cacheKeys.map((cacheKey) => {
        const ngswRegex = /^(ngsw).*/;
        if (ngswRegex.test(cacheKey)) {
          return caches
            .open(cacheKey)
            .then((cache) =>
              cache
                .keys()
                .then((requests) => requests.map((req) => cache.delete(req)))
            );
        } else {
          // Handle the scenario where the condition is not met
          return null; // Change this to an appropriate value based on your requirement
        }
      })
    );
  }

  checkBrowserDetails() {
    // console.log(this.navigator.userAgent);
    const isEdge = this.navigator.userAgent.indexOf('Edge/') > -1;
    // console.log(isEdge, "edge");
    if (isEdge) {
      this.router.navigate(['/not-supported-browser']);
    }
  }
}
