<div class="main-box p-5">
  <div class="p-3">
    <h2>Nudge Cron Management</h2>
    <p class="sub-title">
      Disclaimer: The Send Nudge emails command runs every 1 Hour. For Example,
      A mail scheduled at 09:07 will be sent at 09:00 and an email scheduled at
      10:17 will be sent at 10:00.
    </p>
  </div>

  <form [formGroup]="nudgeForm">
    <div class="row align-items-center">
      <div class="col-md-4">
        <div class="form-group pb-10">
          <label for="scheduleTime"
            >Schedule Time<span class="asterick">*</span></label
          >
          <input
            class="form-control custom-form-control"
            required
            id="schedule_time"
            name="schedule_time"
            type="time"
            formControlName="schedule_time"
            placeholder="Enter schedule time..."
          />
          <p
            class="error"
            *ngIf="
              nudgeForm.get('schedule_time').hasError('required') &&
              nudgeForm.get('schedule_time').touched
            "
          >
            Schedule Time is required.
          </p>
          <p
            class="error"
            *ngIf="
              nudgeForm.get('schedule_time').hasError('timeRange') &&
              (nudgeForm.get('schedule_time').touched || isUpdate)
            "
          >
            Time must be between 08:00 AM and 06:00 PM.
          </p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group pb-10">
          <label>Data Percentage<span class="asterick">*</span></label>
          <input
            class="form-control custom-form-control"
            required
            id="data_percentage"
            name="data_percentage"
            type="number"
            formControlName="data_percentage"
            min="0"
            max="100"
            step="0.01"
            placeholder="Enter data percentage..."
          />
          <p
            class="error"
            *ngIf="
              nudgeForm.get('data_percentage').hasError('required') &&
              nudgeForm.get('data_percentage').touched
            "
          >
            Data Percentage is required.
          </p>
          <p
            class="error"
            *ngIf="
              nudgeForm.get('data_percentage').hasError('min') &&
              nudgeForm.get('data_percentage').touched
            "
          >
            Value must be greater than or equal to 0.
          </p>
          <p
            class="error"
            *ngIf="
              nudgeForm.get('data_percentage').hasError('max') &&
              nudgeForm.get('data_percentage').touched
            "
          >
            Value must be less than or equal to 100.
          </p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="pb-10 mt-5">
          <label>Status<span class="asterick">*</span></label>
          <br />
          <mat-slide-toggle
            formControlName="is_active"
            [checked]="nudgeForm.value.is_active == 1 ? true : false"
            >{{
              nudgeForm.value.is_active == 1 ? "Active" : "Inactive"
            }}</mat-slide-toggle
          >
        </div>
      </div>
    </div>

    <div
      class="actions"
      style="display: flex; justify-content: center; gap: 20px"
    >
      <button
        type="button"
        (click)="createNudgeConfig(null)"
        style="margin-top: 25px; color: white"
        class="btn btn-secondary btn-newuser-clr"
        [disabled]="nudgeForm.invalid"
      >
        {{ isUpdate ? "Update" : "Create" }}
      </button>

      <button
        *ngIf="isUpdate"
        type="button"
        style="margin-top: 25px; color: white"
        class="btn btn-secondary btn-newuser-clr"
        (click)="nudgeForm.reset(); isUpdate = false"
      >
        Add New
      </button>
    </div>
  </form>

  <div
    *ngIf="this.dataSource.data.length > 0 && totalPercentage < 100"
    style="margin-top: 2rem; display: flex; justify-content: center"
  >
    <h4 class="highlight-message">
      If data percentage DOES NOT include 100% users, then a follow-up nudge
      email schedule will run after ONE HOUR to send emails to those remaining
      users.
    </h4>
  </div>

  <div class="main-box-table mat-elevation-z8">
    <mat-table [dataSource]="dataSource" class="w-100">
      <!-- Schedule Time Column -->
      <ng-container matColumnDef="schedule_time">
        <mat-header-cell *matHeaderCellDef> Schedule Time </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ formatScheduleTime(element.schedule_time) }}
        </mat-cell>
      </ng-container>

      <!-- Data Percentage Column -->
      <ng-container matColumnDef="data_percentage">
        <mat-header-cell *matHeaderCellDef> Data Percentage </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.data_percentage }}%
        </mat-cell>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="is_active">
        <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="p-3">
            <mat-slide-toggle
              (change)="statusChange($event, element)"
              [(ngModel)]="element.is_active"
              [checked]="element.is_active == 1 ? true : false"
            >
            </mat-slide-toggle>
          </div>

          <div
            class="chip"
            [ngStyle]="{
              'background-color':
                element.is_active == 1 ? '#D3FCD2' : '#FFAC82',
              color: element.is_active == 1 ? '#118D57' : '#B71D18'
            }"
          >
            {{ element.is_active == 1 ? "Active" : "Inactive" }}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell class="text-center" *matCellDef="let element">
          <i
            (click)="patchValues(element)"
            class="fa fa-pencil-square-o"
            aria-hidden="true"
          ></i>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>

    <mat-paginator [pageSizeOptions]="[5, 10, 20]"></mat-paginator>
  </div>
</div>
