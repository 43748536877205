<form>
  <div class="main">
    <h3 class="text-center">Schedule Leaderboard Report</h3>
    <div class="sub-main">
      <div class="items">
        <div class="datetime">
          <p class="header text-center">Select Schedule Time</p>
          <hr />
          <label>Select Time:</label>
          <input
            name="scheduleFor"
            [owlDateTimeTrigger]="dt10"
            [owlDateTime]="dt10"
            [(ngModel)]="scheduleFor"
            placeholder="Click to choose time"
            class="form-control"
          />
          <!-- [pickerMode]="'dialog'" -->
          <owl-date-time #dt10></owl-date-time>
        </div>
        <div class="comments">
          <p class="header text-center">Edit Comments</p>
          <hr />
          <div class="form-group">
            <label for="comment1" class="header">Comment for Criteria 1:</label>
            <div class="">
              <textarea
                name="commentOne"
                type="text"
                class="form-control"
                placeholder="Enter comment"
                [(ngModel)]="commentsObject.commentOne"
                rows="3"
              ></textarea>
            </div>
          </div>
          <div class="form-group">
            <label for="comment2" class="header">Comment for Criteria 2:</label>
            <div class="">
              <textarea
                type="text"
                class="form-control"
                placeholder="Enter comment"
                name="commentTwo"
                [(ngModel)]="commentsObject.commentTwo"
                rows="3"
              ></textarea>
            </div>
          </div>
          <div class="form-group">
            <label for="comment3" class="header">Comment for Criteria 3:</label>
            <div class="">
              <textarea
                type="text"
                name="commentThree"
                class="form-control"
                placeholder="Enter comment"
                [(ngModel)]="commentsObject.commentThree"
                rows="3"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="batches" *ngIf="companySurveyId">
          <p class="header text-center">Select Batches</p>
          <hr />
          <div *ngFor="let batch of batchOptions; let i = index">
            <div class="batches-name">
              <input
                type="checkbox"
                [value]="batch"
                [checked]="
                  selectedBatchOptions.length > 0 &&
                  selectedBatchOptions.includes(batch)
                "
                (change)="selectBatchName($event, i)"
              />
              <small>{{ batch }}</small>
            </div>
          </div>
        </div>
      </div>
      <div class="email-template">
        <p class="header">Email Operation</p>
        <div class="row">
          <div class="col-md-8">
            <!-- <angular-editor
              [(ngModel)]="emailData.content"
              [config]="editorConfig"
              [ngModelOptions]="{ standalone: true }"
            ></angular-editor> -->
            <!-- <div class="editor">
              <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
              </ngx-editor-menu>
              <ngx-editor
                [ngModelOptions]="{ standalone: true }"
                [editor]="editor"
                [placeholder]="'Enter text here...'"
                [(ngModel)]="emailData.content"
              >
              </ngx-editor>
            </div> -->

            <app-email-editor
              [(content)]="emailData.content"
              (contentChange)="onContentChange($event)"
            ></app-email-editor>

            <!-- <email-editor (loaded)="editorLoaded()"></email-editor> -->
          </div>
          <div class="col-md-4">
            <div class="form-container">
              <div class="placeholder-container">
                <h4><b>Choose Headers for Report Table:</b></h4>
                <div *ngFor="let header of reportTableHeaders; let i = index">
                  <div class="batches-name">
                    <input
                      type="checkbox"
                      [checked]="selectedReportTableHeaders.includes(header)"
                      [value]="header"
                      (change)="selectHeaders($event, i)"
                    />
                    <h5>{{ header }}</h5>
                  </div>
                </div>
              </div>
              <hr />
              <div class="input-group m-10">
                <label for="subject">Mail Subject:</label>
                <input
                  type="text"
                  name="subject"
                  name="subject"
                  id="subject"
                  [(ngModel)]="emailData.subject"
                />
              </div>
              <hr />
              <div class="check-spoc">
                <input
                  type="checkbox"
                  id="gmiSpoc"
                  [(ngModel)]="sendGmiSpoc"
                  [checked]="sendGmiSpoc"
                  (change)="sendGmiSpoc = !sendGmiSpoc"
                />
                <label for="gmiSpoc">Include GMI Spoc in CC</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="submit-button text-center m-10">
        <button
          type="button"
          class="btn btn-primary"
          [disabled]="
            scheduleFor == '' ||
            emailData.content == '' ||
            emailData.subject == '' ||
            selectedReportTableHeaders.length < 3 ||
            commentsObject.commentOne == '' ||
            commentsObject.commentTwo == '' ||
            commentsObject.commentThree == ''
          "
          (click)="addScheduleData()"
        >
          Add
        </button>
      </div>
      <hr />
      <div class="add-table">
        <table>
          <thead>
            <tr>
              <th
                class="text-center"
                style="font-weight: bold"
                *ngFor="let column of displayedColumns"
              >
                {{ column }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of scheduledData; let i = index">
              <td>{{ item.scheduleFor | date : "medium" }}</td>
              <td>
                <ul>
                  <li>'Comment One:'{{ item?.comments?.commentOne }}</li>
                  <li>'Comment Two:'{{ item?.comments?.commentTwo }}</li>
                  <li>'Comment Three:'{{ item?.comments?.commentThree }}</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li *ngFor="let batch of item.batches">{{ batch }}</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li *ngFor="let header of item.tableHeaders">
                    {{ header }}
                  </li>
                </ul>
              </td>
              <td>
                {{ item.includeGmiSpoc ? "Yes" : "No" }}
              </td>
              <td>
                <!-- <mat-icon matTooltip="Edit">edit</mat-icon> -->
                <!-- <mat-icon
                  matTooltip="Delete"
                  (click)="deleteScheduleData(index)"
                  >delete</mat-icon
                > -->
                <i (click)="deleteScheduleData(index)" class="fa fa-trash"></i>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="submit-button text-center m-10">
          <button
            type="button"
            class="btn btn-primary"
            [disabled]="scheduledData.length == 0"
            (click)="submitScheduledData()"
          >
            Submit
          </button>
        </div>
      </div>
      <hr />
      <div class="companyScheduleData">
        <p class="header">Scheduled Emails</p>
        <div *ngIf="scheduledEmails.data.length > 0; else noEmails">
          <mat-table [dataSource]="scheduledEmails" class="mat-elevation-z4">
            <ng-container matColumnDef="serialNo">
              <mat-header-cell *matHeaderCellDef> Sr. No </mat-header-cell>
              <mat-cell
                class="text-center"
                *matCellDef="let element; let i = index"
              >
                {{ i + 1 }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef> Email Name </mat-header-cell>
              <mat-cell
                class="text-center"
                *matCellDef="let element; let i = index"
              >
                {{ element.name }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="createdAt">
              <mat-header-cell *matHeaderCellDef> Created At </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.createdAt | date : "short" }}</mat-cell
              >
            </ng-container>

            <ng-container matColumnDef="scheduleFor">
              <mat-header-cell *matHeaderCellDef>
                Schedule Time and Date
              </mat-header-cell>
              <mat-cell class="text-center" *matCellDef="let element">
                {{ element.scheduleFor | date : "medium" }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="sentStatus">
              <mat-header-cell *matHeaderCellDef> Sent Status </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.sentStatus }}</mat-cell
              >
            </ng-container>

            <ng-container matColumnDef="cc">
              <mat-header-cell *matHeaderCellDef>
                Is GMI Spoc included in CC
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.cc ? "Yes" : "No" }}</mat-cell
              >
            </ng-container>

            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <mat-icon (click)="editScheduleFor(element, edit)"
                  >edit</mat-icon
                >
                <!-- <mat-icon
                  *ngIf="!element.sentStatus"
                  (click)="deleteEmail(element)"
                  >delete</mat-icon
                > -->
                <i
                  *ngIf="!element.sentStatus"
                  (click)="deleteEmail(element)"
                  class="fa fa-trash"
                ></i>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: tableColumns"></mat-row>
          </mat-table>
          <mat-paginator
            showFirstLastButtons
            [pageSizeOptions]="[10, 20, 30]"
          ></mat-paginator>
        </div>
        <ng-template #noEmails>
          <div class="text-center">
            <h2>No Scheduled emails found!</h2>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</form>
<ng-template #edit>
  <div class="modal-header">
    <h4 class="modal-title">Edit Schedule Dispatch</h4>
    <button type="button" class="close" (click)="closeModal()">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="confirmation-modal m-10">
      <label for="name" class="form-label">Dispatch Time:</label>
      <input
        name="Schedule"
        [owlDateTimeTrigger]="dt10"
        [owlDateTime]="dt10"
        [(ngModel)]="scheduledForDataObject.scheduleFor"
        placeholder="Click to
      choose time"
        class="form-control"
      />
      <!-- <owl-date-time [pickerMode]="'dialog'" #dt10></owl-date-time> -->
      <owl-date-time #dt10></owl-date-time>
      <div class="text-center m-10">
        <button type="button" class="btn btn-primary" (click)="onSubmit({})">
          Update
        </button>
      </div>
    </div>
  </div>
</ng-template>
