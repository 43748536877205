import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import moment from "moment";
import { ActivatedRoute } from "@angular/router";
import { AdminServices } from "../admin.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";

import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import io from "socket.io-client";
import { environment } from "../../../environments/environment";
import { ManagerInfo, SurveyGroupInfo } from "./round-two-company.interface";
import { ExcelService } from "src/app/excel-service.service";
@Component({
  selector: "app-round-two-company",
  templateUrl: "./round-two-company.component.html",
  styleUrls: ["./round-two-company.component.css"],
})
export class RoundTwoCompanyComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(
    private route: ActivatedRoute,
    private adminServices: AdminServices,
    private modalService: BsModalService,
    private readonly excelService: ExcelService
  ) {}
  surveyGroupId!: string;
  surveyGroupList = new MatTableDataSource<SurveyGroupInfo>();
  displayedColumns: string[] = [
    "surveyType",
    "surveyLength",
    "startDate",
    "endDate",
    "isDataProcessed",
    "actions",
  ];
  pageSize: number = 5;
  currentPage: number = 0;
  preProcessedData!: ManagerInfo[];
  selectedSurveyGroupId!: SurveyGroupInfo;

  // OLD VARIABLES

  private serverUrl = environment.apiURl;
  private socket = io(this.serverUrl, { reconnection: true });
  dataSource: MatTableDataSource<[]>;
  preProcessedColumn = ["mgrName", "mgrEmail", "tfsScore", "teamMemberScore"];
  preProcessedDataSource: MatTableDataSource<[]>;
  processedDataSource: MatTableDataSource<[]>;
  scoreValue = 4.4;
  seCompanyId: Number = 0;
  uuId: string;
  emailModel: any = { hrName: "", hrEMail: "" };
  modalRef: BsModalRef;
  loading = false;
  companyName: String;
  surveyEndDate: any;
  updateEndDate: any;
  showSurveyDashboard: Boolean = false;
  surveyIsLaunched: Boolean = false;
  displayNoRecords = false;
  surveyEnded = false;
  hrAccepted: any;
  searchKey: string;
  r2EmailId: string;
  selectedManagers: Array<[]> | any = [];
  allManagers: Array<[]> = [];

  surveyGroup: any;

  processedData: any = [];
  dataMsg: String;
  selectedRows: any = [];
  allSelected: boolean = false;
  minDate: any;
  totalData: number = 0;
  dataInProcess: number = 0;
  dataProcessed: number = 0;
  showSocketStatus: boolean = false;
  showProcessedStatus: boolean = false;
  showViewProcessBtn: Boolean = false;

  ngOnInit() {
    const companyId = this.route.snapshot.params["company-id"];
    this.getAllSurveyGroupFromCompanyId(companyId);
  }
  ngAfterViewInit() {
    this.surveyGroupList.paginator = this.paginator;
  }

  private getAllSurveyGroupFromCompanyId(companyId: number) {
    this.adminServices
      .getSurveyGroupFromCompanyId(companyId)
      .subscribe((res: any) => {
        if (res.status && res.data.length > 0) {
          this.surveyGroupList = res.data;
        }
      });
  }
  viewData(surveyGroupInfo: SurveyGroupInfo, content: any) {
    this.loading = true;
    this.selectedSurveyGroupId = surveyGroupInfo;
    const payloadData = {
      seSurveyIds: surveyGroupInfo.survey_ids,
    };
    this.adminServices.getPreProceessedData(payloadData).subscribe({
      next: (res: any) => {
        this.currentPage = 0;
        if (res.status && res.data.length) {
          this.preProcessedData = res.data;
          this.modalRef = this.modalService.show(content, {
            class: "modal-lg",
          });
        }
        if (res.status && res.data.length === 0) {
          Swal.fire({
            title: "Warning",
            text: "No managers were found",
            icon: "warning",
            showConfirmButton: true,
          });
        }
      },
      error: (err) => {
        Swal.fire({
          title: "Error",
          text: "Failed to fetch data",
          icon: "error",
          showConfirmButton: true,
        });
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  get paginatedData() {
    const startIndex = this.currentPage * this.pageSize;
    return this.preProcessedData.slice(startIndex, startIndex + this.pageSize);
  }

  nextPage() {
    if ((this.currentPage + 1) * this.pageSize < this.preProcessedData.length) {
      this.currentPage++;
    }
  }

  previousPage() {
    if (this.currentPage > 0) {
      this.currentPage--;
    }
  }

  downloadSheet() {
    this.loading = true;
    const managerInfo = this.preProcessedData.map((manager) => {
      return {
        name: manager.manager_name,
        email: manager.manager_email_id,
        overall_score: manager.overall_score,
        total_participants: manager.total_participants,
        team_member_score: manager.team_member_overall_score,
        total_team_members: manager.total_team_members,
      };
    });
    this.excelService.exportDataAsExcelFile(managerInfo, "Processed Data");
    this.loading = false;
  }

  processData() {
    const payloadData = {
      seSurveyIds: this.selectedSurveyGroupId.survey_ids,
      surveyGroupId: this.selectedSurveyGroupId.id,
      recognitionYear: 0,
    };
    this.adminServices.roundTwoAutomationV2(payloadData).subscribe({
      next: (res) => {
        if (res.status) {
          Swal.fire({
            title: "Success",
            text: res.message || "Data Processed Successfully",
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.modalRef.hide();
        }
      },
      error: (error) => {
        Swal.fire({
          title: "Oops...",
          text: error?.error?.message ?? "Something went wrong!",
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
        this.modalRef.hide();
      },
    });
  }

  // ********** OLD FUNCTIONS ******************
  getRTwoData(survey, template) {
    this.surveyGroupId = survey._id;
    //console.log("data",survey);
    let data = {
      surveyGroupId: survey._id,
    };

    if (survey.isDataProcessed) {
      this.modalRef = this.modalService.show(template, {
        keyboard: false,
      });
      this.adminServices.addModal(this.modalRef);
      this.getProcessedData(data);
    } else {
      this.getPreProcessedData(data);
    }
  }

  getProcessedData(data) {
    this.loading = true;
    this.adminServices.getQualifiedList(data).subscribe((res) => {
      if (res.status && res.data.processedData) {
        let data = res.data;
        const currentDate = new Date();
        this.surveyEnded =
          data.isHrEmail && data.isHrEmail.endDate < currentDate.toISOString();
        this.r2EmailId = data.isHrEmail && data.isHrEmail._id;
        this.processedDataSource = new MatTableDataSource(data.processedData);
        setTimeout(() => {
          this.processedDataSource.paginator = this.paginator;
        }, 0);

        this.processedData = data.processedData;
        this.showProcessedStatus = true;
        this.showSurveyDashboard = data.isHrEmail != undefined ? true : false;
        this.hrAccepted =
          data.isHrEmail != undefined ? data.isHrEmail.accepted : false;
        this.surveyIsLaunched =
          data.isHrEmail != undefined ? data.isHrEmail.surveyIsLaunched : false;
        this.surveyEndDate = data.isHrEmail
          ? new Date(data.isHrEmail.endDate)
          : new Date();
        this.minDate = data.isHrEmail
          ? new Date(data.isHrEmail.startDate)
          : new Date();
        this.selectedManagers = data.processedData;
        this.allSelected = false;
        this.toggleSelectAll();
        this.loading = false;
        this.modalRef.hide();
      }
    });
  }

  getPreProcessedData(data) {
    this.loading = true;
    this.adminServices.getPreProceessedData(data).subscribe((res) => {
      if (res.status && res.data) {
        //console.log("qualified data",res.data);
        let data = res.data;
        //this.dataMsg = res.data.processedData.length == 0 ? "Round Two Pre-Processed Data" : res.data.processedData.length > 0 ? "Round Two Processed Data" : "Data not found, please check if TFS scores are calculated!";
        this.preProcessedDataSource = new MatTableDataSource(
          data.preProcessedData
        );
        setTimeout(() => {
          this.preProcessedDataSource.paginator = this.paginator;
        }, 0);

        // console.log(
        //   "preProcessedDataSource",
        //   this.preProcessedDataSource.paginator
        // );
        // console.log("this.preProcessedDataSource", this.preProcessedDataSource);
        this.preProcessedData = data.preProcessedData;
        this.loading = false;
      }
    });
  }

  toggleSelectAll() {
    this.allSelected = !this.allSelected;
    this.selectedRows =
      this.allSelected === true ? [...this.processedDataSource.data] : [];
  }

  toggleRowSelection(row) {
    const index = this.selectedRows.indexOf(row);
    if (index > 0) {
      this.selectedRows.splice(index, 1);
    } else {
      this.selectedRows.push(row);
    }
  }

  isSelected(row) {
    return this.selectedRows.includes(row);
  }

  launchSurveys() {
    let data = {
      managers: this.selectedRows,
      surveyGroupId: this.surveyGroup._id,
    };
    this.getManagersData(data);
  }
  onStatusChange(row, event: any) {
    row.checked = event.checked;
    this.selectedRows = this.preProcessedDataSource.data.slice();
    //console.log("row",this.selectedData);
    //console.log("this.selectedData",this.selectedRows);
  }

  scoreControl(control) {
    this.scoreValue = control.value;
    let data = {
      companyId: this.seCompanyId,
      score: this.scoreValue,
    };
    this.getManagersData(data);
  }

  // getLists(data) {
  //   this.loading = true;
  //   this.adminServices.getAllRound2ListByCompany(data).subscribe((res) => {
  //     this.loading = false;
  //     if (res.status) {
  //       if (res.data.length > 0) {
  //         this.scoreValue = res.data[0].scoreFilter;
  //         this.showSurveyDashboard = true;
  //         this.surveyIsLaunched = res.data[0].surveyIsLaunched;
  //         let r2Array = res.data[0].managers.filter(
  //           (manager) => manager.checked == true
  //         );
  //         this.dataSource = new MatTableDataSource(r2Array);
  //       } else {
  //         let data = {
  //           companyId: this.seCompanyId,
  //           score: this.scoreValue,
  //         };

  //         this.getManagersData(data);
  //         this.showSurveyDashboard = false;
  //       }
  //     }
  //   });
  // }

  getManagersData(data) {
    let round2Data = [];
    this.loading = true;
    this.adminServices.getR2ManagerList(data).subscribe((res) => {
      this.loading = false;
      if (res.status) {
        //console.log("response", res);
        if (res.sendEmailToHr) {
          this.showSurveyDashboard = true;
        } else {
          this.showSurveyDashboard = false;
        }
        this.hrAccepted = res.hrAccepted;
        this.surveyIsLaunched = res.surveyLaunched;
        if (res.managers) {
          this.selectedManagers = res.managers.filter(
            (manager) => manager.checked == true
          );
          this.allManagers = res.managers;
        }

        res.data.forEach((manager) => {
          round2Data.push({
            name: manager.name,
            email: manager.email,
            teamSize: manager.totalTeam,
            appearedCount: manager.givenRespondent,
            teamMemberScore: manager.teamMemberScore,
          });
        });
        this.dataSource = new MatTableDataSource(round2Data);
      }
    });
  }

  applyFilter() {
    this.dataSource.filter = this.searchKey.trim().toLowerCase();
    if (this.dataSource.filteredData.length == 0) {
      this.displayNoRecords = true;
    } else {
      this.displayNoRecords = false;
    }
  }

  onSearchClear() {
    this.searchKey = "";
    this.applyFilter();
  }

  openAddModal(template) {
    //console.log("this.selectedRows",this.selectedRows);
    if (this.selectedRows.length == 0) {
      Swal.fire({
        title: "Hr Email Sent",
        text: "Manager list is empty",
        icon: "error",
        showConfirmButton: true,
        timer: 250000,
      });
      return false;
    }
    if (this.showSurveyDashboard) {
      Swal.fire({
        title: "Hr Email Sent",
        text: "Step is already completed",
        icon: "error",
        showConfirmButton: true,
        timer: 250000,
      });
      return false;
    }
    this.modalRef = this.modalService.show(template);
    this.adminServices.addModal(this.modalRef);
    return false;
  }
  openSurveyLaunch(template) {
    if (!this.hrAccepted) {
      Swal.fire({
        title: "Steps required",
        text: "Hr acceptance is required.",
        icon: "error",
        showConfirmButton: true,
        timer: 250000,
      });
      return false;
    }
    this.modalRef = this.modalService.show(template);
    this.adminServices.addModal(this.modalRef);
    return false;
  }

  sendMailHandler(formData) {
    //console.log("manager",manager);
    let data = {
      hrName: formData.value.hrName,
      hrEmail: formData.value.hrEmail,
      managers: this.selectedRows,
      seCompanyId: this.seCompanyId,
      companyName: this.companyName,
      surveyGroupId: this.surveyGroup._id,
      //scoreFilter: this.scoreValue,
    };
    this.loading = true;
    this.adminServices.sendEmailToHr(data).subscribe((res) => {
      this.loading = false;
      if (res.status) {
        Swal.fire({
          title: "Email Sent",
          text: res.message,
          icon: "success",
          showConfirmButton: false,
          timer: 2500,
        });
        //this.ngOnInit();
        let data = {
          companyId: this.seCompanyId,
          score: this.scoreValue,
        };
      }
      this.modalRef.hide();
      this.modalRef = null;
    });
  }

  launchSurvey() {
    if (this.surveyEnded) {
      this.updateSurvey();
    } else {
      let data = {
        startDate: new Date(Date.now()),
        endDate: moment(this.surveyEndDate).format("YYYY-MM-DD"),
        managers: this.selectedRows,
        companyName: this.companyName,
        companyId: this.seCompanyId,
        surveyGroupId: this.surveyGroupId,
      };

      this.loading = true;
      this.adminServices.launchR2Survey(data).subscribe((res) => {
        this.loading = false;
        if (res.status) {
          Swal.fire({
            title: "Survey Launch",
            text: res.message,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          let data = {
            companyId: this.seCompanyId,
            score: this.scoreValue,
          };
          this.getManagersData(data);
        }
        this.modalRef.hide();
        this.modalRef = null;
      });
    }
  }
  updateSurvey() {
    let data = {
      endDate: moment(this.updateEndDate).format("YYYY-MM-DD"),
      seCompanyId: this.seCompanyId,
      r2EmailId: this.r2EmailId,
    };
    this.loading = true;
    this.adminServices.updateR2Survey(data).subscribe((res) => {
      this.loading = false;
      if (res.status) {
        this.modalRef.hide();
        Swal.fire({
          title: "Survey Extended",
          text: res.message,
          icon: "success",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    });
  }
  downloadManagerReport() {
    let managerReports = [];
    this.allManagers.forEach((manager: any) => {
      managerReports.push({
        Name: manager.name,
        Email: manager.email,
        Selected: manager.checked ? "Yes" : "No",
      });
    });
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const ws1 = XLSX.utils.json_to_sheet(managerReports);
    XLSX.utils.book_append_sheet(wb, ws1, "Sheet1");
    XLSX.writeFile(wb, `Manager List.xlsx`);
  }
  downloadR2Report() {
    let data = {
      seCompanyId: this.seCompanyId,
      surveyGroupId: this.surveyGroup._id,
    };
    this.adminServices.getR2Report(data).subscribe((res) => {
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      const ws1 = XLSX.utils.json_to_sheet(res.data);
      XLSX.utils.book_append_sheet(wb, ws1, "Sheet1");
      XLSX.writeFile(wb, `R2 Report List.xlsx`);
    });
  }
  downloadR2FeedbackReport() {
    let data = {
      seCompanyId: this.seCompanyId,
      surveyGroupId: this.surveyGroup._id,
    };
    this.adminServices.getR2UserFeedbackReport(data).subscribe((res) => {
      this.loading = false;
      if (res.status) {
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        const ws1 = XLSX.utils.json_to_sheet(res.data);
        XLSX.utils.book_append_sheet(wb, ws1, "Sheet1");
        XLSX.writeFile(wb, `R2 FeedBack Data.xlsx`);
      }
    });
  }

  roundTwoAutomation() {
    let payloadData = {
      seSurveyIds: this.preProcessedData.map((item: any) => item.seSurveyId),
      surveyGroupId: this.surveyGroup._id,
      recognitionYear: 0,
    };
    this.adminServices.roundTwoAutomationV2(payloadData).subscribe({
      next: (res) => {
        if (!res.status) throw Error("Something went wrong");
        this.surveyGroup.isDataProcessed = true;
        this.showViewProcessBtn = true;
        this.preProcessedData = [];
        Swal.fire({
          title: "",
          text: "Data Processed Successfully",
          icon: "success",
          showConfirmButton: false,
          timer: 2500,
        }).then(() => true);
      },
      error: (error) => {
        Swal.fire({
          title: "Oops...",
          text: error?.error?.message ?? "Something went wrong!",
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        }).then(() => true);
      },
    });
  }
}
