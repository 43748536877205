<div class="container main">
  <div class="">
    <div class="row">
      <div class="col-md-5">
        <div class="form-group pb-10">
          <input
            class="form-control custom-form-control"
            required
            id="subtheme"
            name="subtheme"
            type="text"
            placeholder="Search subtheme name..."
            (keyup)="searchSubtheme($event)"
          />
        </div>
      </div>
      <div class="col-md-5 text-right">
        <button
          (click)="showSubthemeModal(addUpdateSubtheme, {}, false)"
          type="button"
          class="btn btn-secondary btn-newuser-clr"
        >
          Add New
        </button>
      </div>
    </div>
    <div class="subtheme-table">
      <mat-table
        [dataSource]="subthemesAsMatTableDataSource$ | async"
        matSort
        class="mat-elevation-z4"
      >
        <ng-container matColumnDef="serialNo">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Serial No</mat-header-cell
          >
          <mat-cell
            class="text-center"
            *matCellDef="let element; let i = index"
          >
            {{ i + 1 }}</mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="theme">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Theme</mat-header-cell
          >
          <mat-cell *matCellDef="let element"> {{ element.theme }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="subtheme">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Sub Theme</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{ element.subtheme }}</mat-cell>
        </ng-container>

        <!-- <ng-container matColumnDef="phrase">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Phrase</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{ element.phrase }}</mat-cell>
        </ng-container> -->

        <!-- Action Column -->
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <i
              class="fa fa-edit"
              (click)="showSubthemeModal(addUpdateSubtheme, element, true)"
              style="margin-right: 15px"
            ></i>
            <!-- <i
              (click)="deleteSubtheme(element.subthemeId)"
              class="fa fa-trash"
              style="margin-right: 15px"
            ></i> -->
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let col; columns: displayedColumns"></mat-row>
      </mat-table>

      <mat-paginator
        [pageSizeOptions]="[15, 30, 50]"
        showFirstLastButtons
      ></mat-paginator>
      <div
        class="text-center emptyContainer"
        *ngIf="dataSource.data.length === 0"
      >
        Not found any subthemes.
      </div>
    </div>
  </div>
</div>
<ng-template #addUpdateSubtheme>
  <div class="subtheme">
    <h4 class="text-center">
      <b>{{ isEdit ? "UPDATE" : "ADD" }} SUB THEME</b>
    </h4>
    <form [formGroup]="subThemeForm">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group pb-10">
            <label for="theme">Theme<span class="asterick">*</span></label>
            <select
              class="form-control custom-form-control"
              required
              id="theme"
              name="theme"
              formControlName="themeId"
            >
              <option value="" selected>Select</option>
              <option value="{{ theme.id }}" *ngFor="let theme of themeArray">
                {{ theme.theme_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group pb-10">
            <label for="subtheme"
              >Sub Theme<span class="asterick">*</span></label
            >
            <input
              class="form-control custom-form-control"
              required
              id="subtheme"
              name="subtheme"
              type="text"
              formControlName="subtheme"
              placeholder="Enter subtheme name..."
            />
          </div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-md-12">
          <div class="form-group pb-10">
            <label for="subtheme">Phrase<span class="asterick">*</span></label>
            <input
              class="form-control custom-form-control"
              id="phrase"
              name="phrase"
              type="text"
              formControlName="phrase"
              placeholder="Enter subtheme phrase..."
            />
          </div>
        </div>
      </div> -->
      <div class="text-center">
        <button
          type="button"
          style="margin-top: 25px; color: white"
          class="btn btn-secondary btn-newuser-clr"
          [disabled]="subThemeForm.invalid"
          (click)="isEdit ? setData('update') : setData('create')"
        >
          {{ isEdit ? "Update" : "Add" }} Subtheme
        </button>
      </div>
    </form>
  </div>
</ng-template>
