import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { AdminServices } from "../admin.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import * as XLSX from "xlsx";
import swal from "sweetalert2";
@Component({
  selector: "app-nudge-varying-names",
  templateUrl: "./nudge-varying-names.component.html",
  styleUrls: ["./nudge-varying-names.component.css"],
})
export class NudgeVaryingNamesComponent implements OnInit {
  nameForm: FormGroup;
  @ViewChild("nameFormSection") nameFormSection: ElementRef; // Reference to the form
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ["name", "created_at", "is_active", "actions"];
  dataSource = new MatTableDataSource<any>();
  isUpdate: boolean = false;
  fileError: string = "";
  fileData: any[] = [];
  errorNames: any[] = [];
  sampleExcelFile: string =
    "https://media.greatmanagerinstitute.com/sample/Sample+Nudge+Emails.xlsx";
  constructor(
    private formBuilder: FormBuilder,
    private adminService: AdminServices,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.getVaryingFromNames();
    this.initializeForm();
  }

  initializeForm() {
    this.nameForm = this.formBuilder.group({
      id: [""],
      name: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(20),
        ],
      ],
      is_active: [true, [Validators.required]],
    });
  }

  // Create/Update Varying Name
  createNameConfig(data: any) {
    const { name, is_active, id } = data ? data : this.nameForm.value;

    const paramsData = {
      id,
      name,
      is_active: is_active ? 1 : 0,
      mode: this.isUpdate ? "update" : "create",
    };

    this.adminService
      .nudgeVaryingFromNamesOperation(paramsData)
      .subscribe((response) => {
        if (response.status && this.isUpdate) {
          this.updateInArray(paramsData, 0);
          this.isUpdate = false;
        }
        this.getVaryingFromNames();
        this.nameForm.reset();
        this.openSnackBar(response.message, response.status);
      });
  }

  // Get all Varying From Names
  getVaryingFromNames() {
    this.adminService.getVaryingFromNames({}).subscribe((response) => {
      this.dataSource.data = response.data;
      this.dataSource.paginator = this.paginator;
    });
  }

  // Patch values for updating
  patchValues(element: any) {
    const { name, is_active, id } = element;
    this.nameForm.patchValue({
      name,
      is_active,
      id,
    });
    this.isUpdate = true;
    this.scrollToForm();
  }

  updateInArray(element: any, id?: number) {
    const matchId = element ? element.id : id;
    const index = this.dataSource.data.findIndex((x) => x.id == matchId);
    if (index > -1) {
      element
        ? (this.dataSource.data[index] = element)
        : (this.dataSource.data[index]["is_active"] = 0);
      this.dataSource._updateChangeSubscription();
    }
  }

  openSnackBar(message: any, status: boolean) {
    this._snackBar.open(message, "", {
      duration: 2000,
      panelClass: status ? ["green-snackbar"] : ["red-snackbar"],
    });
  }

  statusChange(checked, element) {
    element.is_active = checked.checked ? 1 : 0;
    this.isUpdate = true;
    this.createNameConfig(element);
  }

  deleteUserGuide(element) {
    swal
      .fire({
        title: "Are you sure want to delete this Name?",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
      })
      .then((result) => {
        if (result.value) {
          this.statusChange(false, element);
        }
      });
  }

  search(value) {
    let filterValue = value.trim().toLowerCase();
    this.dataSource.filter = filterValue;
  }
  onFileChange(event: any) {
    this.errorNames = [];
    this.fileError = "";
    const errorMessages = [];
    const file: File = event.target.files[0];
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: "binary" });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      let data: any[] = XLSX.utils.sheet_to_json(ws, { raw: true, header: 1 });
      const headers: any[] = data.shift().map((header: any) => header.trim());
      data = data.filter((data) => {
        return data.length > 0;
      });
      // Set to store unique names
      const uniqueNames = new Set<string>();

      const output: any[] = data.map((row: any) => {
        const obj: any = {};
        row.forEach((cell: any, i: number) => {
          obj[headers[i]] = cell;
        });
        const name = obj["name"]?.toString();
        if ((name && name.length > 20) || (name && name.length < 5)) {
          this.errorNames.push(name);
          errorMessages.push(
            `Name "${name}" must be between 5 and 20 characters.`
          );
          return null;
        }
        // Check if the name contains any numbers
        const hasNumber = /\d/.test(name);

        // Check if the name is valid in terms of length and no numbers
        if (hasNumber) {
          this.errorNames.push(name);
          errorMessages.push(
            `Name "${name}" contains numbers and it is not allowed.`
          );
          return null;
        }

        // Check for duplicate names
        if (name && uniqueNames.has(name)) {
          this.errorNames.push(name);
          errorMessages.push(`Duplicate name "${name}" is not allowed.`);
          return null;
        }
        // Add the name to the Set to track uniqueness
        uniqueNames.add(name);

        return obj;
      });

      this.fileError = errorMessages.join(" ");
      this.fileData = output?.filter(Boolean);

      if (this.fileData.length > 0) {
        this.bulkUploadNames(this.fileData);
      }

      event.target.value = null;
    };
    reader.readAsBinaryString(file);
  }

  bulkUploadNames(names: any[]) {
    this.adminService.bulkUploadVaryingNames(names).subscribe(
      (response) => {
        this.openSnackBar(
          this.errorNames.length > 0
            ? "Names uploaded sucessfully except Names with error."
            : response.message,
          response.status
        );
      },
      (error) => {
        this.openSnackBar("Upload failed", false);
      }
    );
  }

  scrollToForm() {
    // Scroll smoothly to the form section
    this.nameFormSection.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }
}
