<div class="reportMain">
  <div class="row">
    <div class="col-sm-4">
      <div class="form-group">
        <label for="reportTypeSelect">Report Type:</label>
        <select
          class="form-control"
          id="reportTypeSelect"
          (change)="onReportTypeChange($event)"
          [(ngModel)]="reportType"
        >
          <option value="actionPlan">Action Plan Report</option>
          <option value="feedBack">Action Plan Feedback Report</option>
        </select>
      </div>
    </div>
    <div class="col-sm-4"></div>
    <div class="col-sm-4">
      <button
        class="btn gmi-primary-btn downloadExcel"
        (click)="downloadExcel()"
      >
        Download Excel
      </button>
    </div>
  </div>
  <!-- Description -->
  <div class="row">
    <div class="col-sm-12">
      <div class="form-group">
        <label for="reportTypeSelect">Description:</label>
        <p *ngIf="reportType == 'actionPlan'">
          This report captures the action plans and implementation updates
          uploaded by managers on the GMI Portal
        </p>
        <p *ngIf="reportType == 'feedBack'">
          This report captures the feedback received by managers on the Action
          Plans and Implementation Updates
        </p>
      </div>
    </div>
  </div>
  <div class="filters">
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label for="reportTypeSelect">Filters:</label>
          <angular2-multiselect
            [data]="dropdownList"
            [(ngModel)]="selectedItems"
            [settings]="dropdownSettings"
            (onSelect)="onItemSelect($event)"
            (onDeSelect)="OnItemDeSelect($event)"
            (onSelectAll)="onSelectAll($event)"
            (onDeSelectAll)="onDeSelectAll($event)"
            (onOpen)="onOpen()"
          >
          </angular2-multiselect>
        </div>
      </div>
      <div class="col-sm-4">
        <button
          class="btn gmi-primary-btn refreshData"
          (click)="onReloadReport()"
        >
          Apply Filters
        </button>
      </div>
      <div class="col-sm-4"></div>
    </div>
    <div class="row">
      <div
        class="col-sm-3"
        *ngFor="let filters of finalDisplayFilters; let i = index"
      >
        <div class="form-group">
          <label for="reportTypeSelect">{{ filters.filterName }}:</label>
          <angular2-multiselect
            [data]="filters.newOptions"
            [(ngModel)]="filters.selectedItems"
            [settings]="dropdownSettings2"
            (onSelect)="onFilterItemSelect($event)"
            (onDeSelect)="onFilterItemDeSelect($event)"
            (onSelectAll)="onFilterSelectAll($event, filters.dbName)"
            (onDeSelectAll)="onFilterDeSelectAll($event, filters.dbName)"
          >
          </angular2-multiselect>
        </div>
      </div>
    </div>
  </div>
  <div class="report">
    <app-bounce-loader *ngIf="loading"></app-bounce-loader>
    <div class="actionPlanReport" *ngIf="reportType == 'actionPlan'">
      <div class="tabsSection">
        <ul class="nav nav-tabs">
          <li class="active">
            <a data-toggle="tab" href="#actionPlan">
              <span>Action Plan</span>
            </a>
          </li>
          <li>
            <a data-toggle="tab" href="#implementationSheet">
              <span>Implementation Sheet</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="tab-content">
        <div id="actionPlan" class="tab-pane fade active animated fadeIn">
          <div
            class="table-responsive"
            *ngIf="
              !loading &&
              actionPlanReportData != undefined &&
              actionPlanReportData.length > 0
            "
          >
            <table class="table table-hover freeze-table">
              <thead>
                <tr>
                  <th
                    *ngFor="
                      let item of actionPlanReportData[0]
                        | keyvalue : returnZero
                    "
                  >
                    {{ item.key }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of actionPlanReportData; let i = index">
                  <td *ngFor="let item of data | keyvalue : returnZero">
                    {{ item.value }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="noResultFound"
            *ngIf="!loading && actionPlanReportData.length == 0"
          >
            <h3>No Data Found</h3>
          </div>
        </div>
        <div id="implementationSheet" class="tab-pane fade in animated fadeIn">
          <div
            class="table-responsive"
            *ngIf="
              !loading &&
              implementationData != undefined &&
              implementationData.length > 0
            "
          >
            <table class="table table-hover freeze-implementation-table">
              <thead>
                <tr>
                  <th
                    *ngFor="
                      let item of implementationData[0] | keyvalue : returnZero
                    "
                  >
                    {{ item.key }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of implementationData; let i = index">
                  <td *ngFor="let item of data | keyvalue : returnZero">
                    <div *ngIf="item.key == 'Attachments' && item.value != ''">
                      <div
                        *ngFor="let attachment of getAttachments(item.value)"
                      >
                        <a target="_blank" href="{{ attachment }}"
                          >Attachment
                        </a>
                      </div>
                    </div>
                    <span
                      *ngIf="
                        item.key == 'Attachments' &&
                        (item.value == '' || item.value == null)
                      "
                      >NA</span
                    >
                    <span *ngIf="item.key != 'Attachments'">{{
                      item.value
                    }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="noResultFound"
            *ngIf="!loading && implementationData.length == 0"
          >
            <h3>No Data Found</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="feedbackReport" *ngIf="reportType == 'feedBack'">
      <div
        class="table-responsive"
        *ngIf="
          !loading &&
          actionPlanFeedbackData != undefined &&
          actionPlanFeedbackData.length > 0
        "
      >
        <table class="table table-hover freeze-feedback-table">
          <thead>
            <tr>
              <th
                *ngFor="
                  let item of actionPlanFeedbackData[0] | keyvalue : returnZero
                "
              >
                {{ item.key }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of actionPlanFeedbackData; let i = index">
              <td *ngFor="let item of data | keyvalue : returnZero">
                {{ item.value }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="noResultFound"
        *ngIf="!loading && actionPlanFeedbackData.length == 0"
      >
        <h3>No Data Found</h3>
      </div>
    </div>
  </div>
</div>
