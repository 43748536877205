import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { HttpClient } from '@angular/common/http';
// import { Subject } from 'rxjs/Subject';
// import 'rxjs/add/operator/map';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable()
export class LoginServices {
  public serverUrl = environment.apiURl;

  public commonUserApiUrl = '/api/v2/user';
  public commonCompanyApiUrl = '/v1/company';
  public commonManagerApiUrl = '/api/v2/manager';
  public universalRoles = new Subject<any>();
  public superAdminApiUrl = '/api/v2/superadmin';

  constructor(private http: HttpClient, private http1: HttpClient) {}

  signUp(userDetails: any) {
    return this.http
      .post(this.serverUrl + this.commonUserApiUrl + '/signUp', userDetails)
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }
  /*
  Description: This api is for sign up of new managers
  Month Of Creation: October 2019
  Author: Sukumar
  */
  managerSignup(userDetails: any) {
    return this.http
      .post(
        this.serverUrl + this.commonUserApiUrl + '/managerSignup',
        userDetails
      )
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }
  /*
  Description: This api is to check for existing email
  Month Of Creation: October 2019
  Author: Sukumar
  */
  checkEmailExistence(emailId: any) {
    return this.http
      .post(this.serverUrl + this.commonUserApiUrl + '/checkEmail', emailId)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  /*
  Description: This api is to check for already exisiting mobile number of manager while sign-up
  Month Of Creation: October 2019
  Author: Sukumar
  */
  checkMobileExistence(mobileNo: any) {
    return this.http
      .post(this.serverUrl + this.commonUserApiUrl + '/checkMobile', mobileNo)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  /*
  Description: This api is to check for already exisiting mobile number of employee while sign-up
  Month Of Creation: October 2019
  Author: Sukumar
  */
  checkEmployeeMobileExistence(mobileNo: any) {
    return this.http
      .post(
        this.serverUrl +
          this.commonUserApiUrl +
          '/checkEmployeeMobileExistence',
        mobileNo
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  /*
  Description: This api is to verify manager's mobile number
  Month Of Creation: October 2019
  Author: Sukumar
  */
  verifyOtp(otpDetails: any) {
    //for managers
    return this.http
      .post(this.serverUrl + this.commonUserApiUrl + '/verifyOtp', otpDetails)
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }
  /*
  Description: This api is to verify employee mobile number
  Month Of Creation: October 2019
  Author: Sukumar
  */
  employeeVerifyOtp(otpDetails: any) {
    //for employee
    return this.http
      .post(
        this.serverUrl + this.commonUserApiUrl + '/employeeVerifyOtp',
        otpDetails
      )
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }
  /*
 Description: This api is to resend otp for managers
 Month Of Creation: October 2019
 Author: Sukumar
 */
  resendOtp(data: any) {
    return this.http
      .post(this.serverUrl + this.commonUserApiUrl + '/resendOtp', data)
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }
  /*
  Description: This api is to send url for forgot password
  Month Of Creation: November 2019
  Author: Sukumar
  */
  forgotPassword(forgotPasswordDetails: any) {
    return this.http
      .post(
        this.serverUrl + this.commonUserApiUrl + '/forgotPassword',
        forgotPasswordDetails
      )
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }
  /*
  Description: This api is to reset password for managers
  Month Of Creation: October 2019
  Author: Sukumar
  */
  resetPassword(resetPasswordData: any) {
    return this.http
      .post(
        this.serverUrl + this.commonUserApiUrl + '/resetPassword',
        resetPasswordData
      )
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }
  /*
  Description: This api is to reset password for managers
  Month Of Creation: November 2019
  Author: Sukumar
  */
  resetEmployeePassword(resetPasswordData: any) {
    return this.http
      .post(
        this.serverUrl + this.commonUserApiUrl + '/resetEmployeePassword',
        resetPasswordData
      )
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }

  /*
  Description: This api is to reset password for managers
  Month Of Creation: January 2019
  Author: Sukumar
  */
  resetAdminPassword(resetPasswordData: any) {
    return this.http
      .post(
        this.serverUrl + this.commonUserApiUrl + '/resetAdminPassword',
        resetPasswordData
      )
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }
  /*
  Description: This api is to login for  manager
  Month Of Creation: October 2019
  Author: Sukumar
  */
  login(loginData: any) {
    return this.http
      .post(this.serverUrl + this.commonUserApiUrl + '/login', loginData)
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }

  tempLogin(loginData: any) {
    return this.http
      .post(this.serverUrl + this.commonUserApiUrl + '/tempLogin', loginData)
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }

  /*
  Description: This api is to login for  employee
  Month Of Creation: November 2019
  Author: Sukumar
  */
  employeeLogin(loginData: any) {
    return this.http
      .post(
        this.serverUrl + this.commonUserApiUrl + '/employeeLogin',
        loginData
      )
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }

  /*
  Description: This api is to login for admin
  Month Of Creation: January 2019
  Author: Kranti
  */
  superAdminLogin(loginData: any) {
    return this.http
      .post(
        this.serverUrl + this.commonUserApiUrl + '/superAdminLogin',
        loginData
      )
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }
  companyAdminLogin(loginData: any) {
    return this.http
      .post(
        this.serverUrl + this.commonCompanyApiUrl + '/companyAdminLogin',
        loginData
      )
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }
  /*
  Description: This api is to get list of participants of any manager
  Month Of Creation: January 2019
  Author: Sukumar
  */
  getUserDetails(managerId: any) {
    return this.http
      .get(
        this.serverUrl + this.commonUserApiUrl + '/getUserDetails/' + managerId
      )
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }
  /*
  Description: This api is to register new employee to give survey
  Month Of Creation: November 2019
  Author: Sukumar
  */
  urlSignUp(urlUserDetails: any) {
    return this.http
      .post(
        this.serverUrl + this.commonUserApiUrl + '/urlSignUp',
        urlUserDetails
      )
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }

  /*
  Description: This api is to check whether user is verified or not
  Month Of Creation: October 2019
  Author: Sukumar
  */
  checkOtp(data: any) {
    return this.http
      .post(this.serverUrl + this.commonUserApiUrl + '/checkOtp', data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  /*
  Description: This api is to get list of features provided by GMI
  Month Of Creation: November 2019
  Author: Sukumar
  */
  features() {
    return this.http
      .get(this.serverUrl + this.commonUserApiUrl + '/features')
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  /*
  Description: This api is to add subscribed features to the managers
  Month Of Creation: November 2019
  Author: Sukumar
  */
  addFeatures(sub: any, id: any, packageName: any) {
    return this.http
      .post(this.serverUrl + this.commonUserApiUrl + '/addFeatures', {
        sub,
        id: id,
        packageName,
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  /*
  Description: This api is to change view of the manager after verifying otp
  Month Of Creation: November 2019
  Author: Sukumar
  */
  changeView(loginId: any) {
    return this.http
      .post(this.serverUrl + this.commonUserApiUrl + '/changeView', {
        loginId: loginId,
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  /*
  Description: This api is to get subscribed package name
  Month Of Creation: December 2019
  Author: Sukumar
  */
  packageName(id: any) {
    return this.http
      .post(this.serverUrl + this.commonUserApiUrl + '/packageName', { id: id })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  /*
  Description: This api is capture payment id after paying money through razorpay
  Month Of Creation: October 2019
  Author: Sukumar
  */
  capture(amount: any, paymentId: any) {
    return this.http
      .post(this.serverUrl + this.commonUserApiUrl + '/capture', {
        amount,
        paymentId,
      })
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }

  sendEmployeeOtp(params: any) {
    return this.http
      .post(
        this.serverUrl + this.commonUserApiUrl + '/resendEmployeeOtp',
        params
      )
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }

  /*
  Description: This api is to resend otp for employee
  Month Of Creation: November 2019
  Author: Sukumar
  */
  resendEmployeeOtp(id: any) {
    return this.http
      .post(this.serverUrl + this.commonUserApiUrl + '/resendEmployeeOtp', {
        id: id,
      })
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }
  /*
  Description: This function is to verify and assign coupon to the employee
  Month Of Creation: January 2019
  Author: Sukumar
  */
  verifyCoupon(data: any) {
    return this.http
      .post(this.serverUrl + this.commonUserApiUrl + '/verifyCoupon', data)
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }

  /*
Description: This function is to verify and assign coupon to the employee
Month Of Creation: January 2019
Author: Sukumar
*/
  checkForAllCoupons(data: any) {
    return this.http
      .post(
        this.serverUrl + this.commonUserApiUrl + '/checkForAllCoupons',
        data
      )
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }
  /*
  Description: This api is to remove coupon
  Month Of Creation: January 2019
  Author: Sukumar
  */
  updateCoupon(data: any) {
    return this.http
      .post(this.serverUrl + this.commonUserApiUrl + '/updateCoupon', data)
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }
  /*
  Description: This api is to get manager mobile number to display on verify otp page
  Month Of Creation: January 2019
  Author: Sukumar
  */
  getManagerMobile(data: any) {
    return this.http
      .post(this.serverUrl + this.commonUserApiUrl + '/getManagerMobile', {
        data: data,
      })
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }
  /*
  Description: This api is to get employee mobile number to display on verify otp page
  Month Of Creation: January 2019
  Author: Sukumar
  */
  getEmployeeMobile(data: any) {
    return this.http
      .post(this.serverUrl + this.commonUserApiUrl + '/getEmployeeMobile', {
        data: data,
      })
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }

  /*
  Description: This api is to get details of a single manager
  Month Of Creation: January 2019
  Author: Sukumar
  */
  getManagerProfile(id: any) {
    return this.http
      .post(this.serverUrl + this.commonUserApiUrl + '/getManagerProfile', {
        id: id,
      })
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }

  getManagerUserDetails() {
    return this.http
      .get(this.serverUrl + this.commonManagerApiUrl + '/getManagerProfile', {})
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }

  isEmployeeVerified(id: any) {
    return this.http
      .post(this.serverUrl + this.commonUserApiUrl + '/isEmployeeVerified', {
        id: id,
      })
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }

  onScoreCalculation() {
    return this.http
      .post(this.serverUrl + this.commonUserApiUrl + '/onScoreCalculation', {})
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }

  sendOtp(data: any) {
    return this.http
      .post(this.serverUrl + this.commonUserApiUrl + '/sendOtp', data)
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }

  getIp() {
    return this.http1.get('https://freegeoip.app/json/').pipe(
      map((response) => {
        return <any>response;
      })
    );
  }

  getUniversalLogin(data: any) {
    return this.http
      .post(this.serverUrl + this.commonUserApiUrl + '/getUniversalLogin', data)
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }
  getUniversalRoles(data: any) {
    this.universalRoles.next(data);
  }
  oneTImeChangePassword(data: any) {
    return this.http
      .post(this.serverUrl + this.commonUserApiUrl + '/changePassword', data)
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }

  getR2Email(data: any) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + '/getR2Email', data)
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }
  updateEmailToHr(data: any) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + '/updateEmailToHr', data)
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }
  getR2Form(data: any) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + '/getR2Form', data)
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }
  r2SubmitForm(data: any) {
    return this.http
      .post(this.serverUrl + this.superAdminApiUrl + '/r2SubmitForm', data)
      .pipe(
        map((response) => {
          return <any>response;
        })
      );
  }

  comparePackages() {
    return this.http
      .get(this.serverUrl + this.commonUserApiUrl + '/comparePackages')
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
}
