import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { io, Socket } from "socket.io-client"; // Correct for v2.5
import { environment } from "../../../environments/environment";
import * as XLSX from "xlsx";
import { constants } from "src/app/constants";
import { AdminServices } from "../admin.service";

@Component({
  selector: "app-performance-analysis",
  templateUrl: "./performance-analysis.component.html",
  styleUrls: ["./performance-analysis.component.css"],
})
export class PerformanceAnalysisComponent implements OnInit {
  statusMessages: string[] = [];
  private socket: Socket;
  private imageRecognitionUrl = environment.imageRecognitionUrl;
  apiUrl = `${this.imageRecognitionUrl}`;
  allAnalysedFiles: any = [];
  filteredFiles = [];
  demoDataLink = `${constants.MEDIA_URL}/assests/sheets/analysis/input/performance/test.xlsx`;
  paginatedFiles = [];
  searchText = "";
  currentPage = 1;
  itemsPerPage = 10;
  totalPages = 0;
  paginationPages: number[] = [];
  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private adminService: AdminServices
  ) {}

  uploadedFiles: File[] = [];

  ngOnInit(): void {
    this.socket = io(this.imageRecognitionUrl);
    this.socket.on("connect", () => {
      this.statusMessages.push("Connected to Analysis server");
    });

    this.socket.on("status", (data: { message: string }) => {
      this.statusMessages.unshift(data.message);
      if (data.message.toLowerCase() === "complete") {
        this.toastr.success("Analysis complete. Refreshing the page...");
        setTimeout(() => {
          window.location.reload(); // Refresh the page
        }, 1000); // Optional delay for user visibility
      } else if (data.message.toLowerCase() === "error") {
        this.toastr.error("Analysis cannot be complete. Retry again");
        setTimeout(() => {
          window.location.reload(); // Refresh the page
        }, 1000); // Optional delay for user visibility
      }
    });

    this.socket.on("disconnect", () => {
      this.statusMessages.push("Disconnected from analysis server");
    });
    this.getAllAnalysedFiles();
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    const files = event.dataTransfer?.files;
    if (files) {
      this.handleFiles(files);
    }
  }

  onFileSelected(event: any): void {
    const files = event.target.files;
    if (files.length === 0) return;

    const file = files[0];
    const fileReader = new FileReader();

    fileReader.onload = () => {
      const data = new Uint8Array(fileReader.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      // Extract headers
      const headers: string[] = (jsonData[0] as string[]).map((header) =>
        header.trim().toLowerCase()
      ); // Normalize headers

      const requiredHeaders = [
        "manager name",
        "email id",
        "level of seniority",
        "overall",
      ].map((header) => header.toLowerCase()); // Normalize required headers

      // Check if either performance or retention is present
      const isPerformanceOrRetentionPresent =
        headers.includes("performance") || headers.includes("retention");

      if (!isPerformanceOrRetentionPresent) {
        this.toastr.error(
          "At least one of 'performance' or 'retention' must be present in the headers."
        );
        return;
      }

      // Check for other missing required headers
      const missingHeaders = requiredHeaders.filter(
        (header) => !headers.includes(header)
      );

      if (missingHeaders.length > 0) {
        this.toastr.error(`Missing headers: ${missingHeaders.join(", ")}`);
        return;
      }

      // Map columns to indices
      const levelOfSeniorityIndex = headers.indexOf("level of seniority");
      const performanceIndex = headers.indexOf("performance");
      const retentionIndex = headers.indexOf("retention");

      // Define allowed values
      const allowedSeniorities = [
        "middle management",
        "junior management",
        "senior management",
        "na",
      ];
      const allowedPerformanceRetention = ["high", "medium", "low", "na"];

      // Validate rows
      const invalidRows: string[] = [];
      for (let i = 1; i < jsonData.length; i++) {
        const row = jsonData[i];

        // Validate 'level of seniority' (check for empty values as well)
        const seniorityValue = (row[levelOfSeniorityIndex] || "")
          .toLowerCase()
          .trim();
        if (
          levelOfSeniorityIndex > -1 &&
          (!seniorityValue || !allowedSeniorities.includes(seniorityValue))
        ) {
          invalidRows.push(
            `Row ${i + 1}: Invalid or empty 'level of seniority' value: ${
              row[levelOfSeniorityIndex] || "Empty"
            }`
          );
        }

        // Validate 'performance' (check for empty values as well)
        const performanceValue = (row[performanceIndex] || "")
          .toLowerCase()
          .trim();
        if (
          performanceIndex > -1 &&
          (!performanceValue ||
            !allowedPerformanceRetention.includes(performanceValue))
        ) {
          invalidRows.push(
            `Row ${i + 1}: Invalid or empty 'performance' value: ${
              row[performanceIndex] || "Empty"
            }`
          );
        }

        // Validate 'retention' (check for empty values as well)
        const retentionValue = (row[retentionIndex] || "").toLowerCase().trim();
        if (
          retentionIndex > -1 &&
          (!retentionValue ||
            !allowedPerformanceRetention.includes(retentionValue))
        ) {
          invalidRows.push(
            `Row ${i + 1}: Invalid or empty 'retention' value: ${
              row[retentionIndex] || "Empty"
            }`
          );
        }
      }

      if (invalidRows.length > 0) {
        this.toastr.error(
          `Validation failed for the following rows:\n${invalidRows.join("\n")}`
        );
        return;
      }
      this.uploadedFiles.push(file); // Add the file to the list
    };

    fileReader.readAsArrayBuffer(file);
  }

  handleFiles(files: FileList): void {
    for (const file of Array.from(files)) {
      if (file.size <= 15 * 1024 * 1024) {
        this.uploadedFiles.push(file);
      } else {
        alert(`File "${file.name}" exceeds the 15MB size limit.`);
      }
    }
  }

  filterTable(): void {
    this.filteredFiles = this.allAnalysedFiles.filter((file) =>
      this.extractFileName(file.input_file)
        .toLowerCase()
        .includes(this.searchText.toLowerCase())
    );
    this.calculateTotalPages();
    this.updatePagination();
  }

  updatePagination(): void {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.paginatedFiles = this.filteredFiles.slice(startIndex, endIndex);
  }

  // Navigate to a specific page
  goToPage(page: number): void {
    if (page < 1 || page > this.totalPages) return;
    this.currentPage = page;
    this.updatePagination();
  }

  // Calculate total pages
  calculateTotalPages(): void {
    this.totalPages = Math.ceil(this.filteredFiles.length / this.itemsPerPage);
    this.paginationPages = Array.from(
      { length: this.totalPages },
      (_, i) => i + 1
    );
  }

  removeFile(index: number): void {
    this.uploadedFiles.splice(index, 1);
  }

  triggerAnalysis(): void {
    if (this.uploadedFiles.length === 0) {
      this.toastr.error("No files to upload.");
      return;
    }

    const formData: any = new FormData();
    const file = this.uploadedFiles[0];
    formData.append("file", file);

    this.adminService
      .analysePerformance(formData)
      .subscribe((response: any) => {
        if (response.status) {
          this.toastr.success(`Analysis started`);
        } else {
          this.toastr.error(`Analysis Cannot be started`);
        }
      });
  }

  getAllAnalysedFiles() {
    this.adminService.getAllAnalysedFiles().subscribe((res: any) => {
      if (res.status) {
        this.allAnalysedFiles = res.info;
        this.filterTable();
      } else {
        this.toastr.error(res.message ? res.message : "Something went wrong");
      }
    });
  }

  extractFileName(url: string): string {
    return url.split("/").pop() || "Unknown File";
  }

  // Function to handle file download
  downloadFile(fileUrl: string): void {
    const a = document.createElement("a");
    a.href = fileUrl;
    a.download = fileUrl.split("/").pop() || "download";
    a.target = "_blank";
    a.click();
  }
}
