import { Pipe, PipeTransform } from "@angular/core";
import { DecimalPipe } from "@angular/common";

@Pipe({
  name: "roundoff",
})
export class RoundOffPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}
  transform(value: number, precision: number = 2): string {
    return this.decimalPipe.transform(value, `1.${precision}-${precision}`);
  }
}
