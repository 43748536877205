<app-loader *ngIf="loading"></app-loader>
<section>
  <div>
    <h2>B2B Round two process</h2>
    <div *ngIf="surveyGroupList">
      <table mat-table [dataSource]="surveyGroupList" class="mat-elevation-z8">
        <ng-container matColumnDef="surveyType">
          <th mat-header-cell *matHeaderCellDef>Survey Type</th>
          <td mat-cell *matCellDef="let survey">{{ survey.survey_type }}</td>
        </ng-container>

        <ng-container matColumnDef="surveyLength">
          <th mat-header-cell *matHeaderCellDef>No of surveys</th>
          <td mat-cell *matCellDef="let survey">
            {{ survey.no_of_surveys }}
          </td>
        </ng-container>

        <ng-container matColumnDef="startDate">
          <th mat-header-cell *matHeaderCellDef>Start Date</th>
          <td mat-cell *matCellDef="let survey">
            {{ survey.start_date | date : "dd/MM/yyyy" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="endDate">
          <th mat-header-cell *matHeaderCellDef>End Date</th>
          <td mat-cell *matCellDef="let survey">
            {{ survey.end_date | date : "dd/MM/yyyy" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="isDataProcessed">
          <th mat-header-cell *matHeaderCellDef>isDataProcessed</th>
          <td mat-cell *matCellDef="let survey">
            {{ survey.is_data_processed === 0 ? "False" : "True" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let survey" class="action-cell">
            <button
              mat-raised-button
              color="accent"
              *ngIf="survey.is_data_processed"
            >
              view processed data
            </button>
            <button
              mat-raised-button
              color="primary"
              *ngIf="!survey.is_data_processed"
              (click)="viewData(survey, preprocessed)"
            >
              View data
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator
        [pageSizeOptions]="[5, 10, 25, 100]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </div>
</section>

<ng-template #preprocessed>
  <div class="modal-header">
    <h4 class="modal-title pull-left" id="my-modal-title">
      Pre processed data
    </h4>
    <button
      type="button"
      class="btn-close close pull-right"
      aria-label="Close"
      (click)="modalRef?.hide()"
    >
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="table-container" *ngIf="preProcessedData">
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Overall score</th>
            <th>Total members</th>
            <th>Team member score</th>
            <th>Total team members</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let element of paginatedData">
            <td>{{ element.manager_name }}</td>
            <td class="email-field">
              <div class="email-content">{{ element.manager_email_id }}</div>
            </td>
            <td>{{ element.overall_score | number : "1.2-2" }}</td>
            <td>{{ element.total_participants }}</td>
            <td>{{ element.team_member_overall_score | number : "1.2-2" }}</td>
            <td>{{ element.total_team_members }}</td>
          </tr>
        </tbody>
      </table>

      <div class="pagination">
        <button (click)="previousPage()" [disabled]="currentPage === 0">
          Previous
        </button>
        <span
          >Page {{ currentPage + 1 }} of
          {{
            (preProcessedData.length + pageSize - 1) / pageSize
              | number : "1.0-0"
          }}</span
        >
        <button
          (click)="nextPage()"
          [disabled]="(currentPage + 1) * pageSize >= preProcessedData.length"
        >
          Next
        </button>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="pagination-modal">
      <button (click)="downloadSheet()" mat-raised-button color="accent">
        Download data
      </button>
      <button (click)="processData()" mat-raised-button color="primary">
        Process data
      </button>
      <button mat-raised-button color="primary" (click)="modalRef?.hide()">
        Close
      </button>
    </div>
  </div>
</ng-template>
