import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
// import 'rxjs/add/observable/of';
// import * as jwt_decode from 'jwt-decode';
import { jwtDecode } from "jwt-decode";
import { CompanyServices } from "../company.service";
import swal from "sweetalert2";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import * as XLSX from "xlsx";
type AOA = any[][];
import { saveAs } from "file-saver";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { MatTableDataSource } from "@angular/material/table";
import { formatDate } from "@angular/common";
import { NgxDropdownConfig } from "ngx-select-dropdown";
@Component({
  selector: "app-company-info",
  templateUrl: "./company-info.component.html",
  styleUrls: ["./company-info.component.css"],
})
export class CompanyInfoComponent implements OnInit {
  @ViewChild("mentorAssign")
  mentorAssign!: any;
  public companyUserApiUrl = "/v1/company";
  CompanyInfo: any;
  loading: boolean = false;
  dataSource = new MatTableDataSource();
  companyId: any;
  managers: any;
  selectedFeatures: any = ["l&d", "sas", "ap"];
  appUrl = environment.hostUrl;
  data: any = [];
  mentorList: any = [];
  uploadedManagerList: any = [];
  scoreText = true;
  managersLength = 0;
  displayView = "basic";
  commentText = true;
  batchOptions: any = [];
  batchFilterArray: any = [];
  filters: any = {};
  displayedColumns = ["checkboxes", "mentorName", "mentorEmail"];

  mentorIds: Array<any> = [];
  isBatchNamePresent: Boolean = false;
  element: any;
  selectedManagers: any;
  constructor(
    private companyService: CompanyServices,
    private router: Router,
    private modalRef: BsModalRef,
    private modalService: BsModalService
  ) {}
  configAccess: NgxDropdownConfig = {
    displayKey: "name",
    search: true,
    height: "auto",
    placeholder: "Select batch",
    // customComparator: () => {},
    limitTo: this.batchOptions.length,
    moreText: "more",
    noResultsFound: "No batch found!",
    searchPlaceholder: "Search",
    searchOnKey: "name",

    customComparator: function (a: any, b: any): number {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    },
    clearOnSelection: false,
    inputDirection: "",
  };
  ngOnInit() {
    this.loading = true;
    this.decodeToken();
    this.getExistingManagers();
    this.getCompanyProfile();
    this.getData();
    this.getSelectedManagers();
  }

  decodeToken() {
    if (localStorage.getItem("token") !== null) {
      let token: any = localStorage.getItem("token");
      let tokenData: any = jwtDecode(token);
      this.companyId = tokenData.companyId;
      let selectedFeatures =
        typeof tokenData.selectedFeatures !== "undefined"
          ? tokenData.selectedFeatures
          : [];
      if (selectedFeatures.length > 0) {
        let tempSelected: any = [];
        selectedFeatures.forEach((feature: any) => {
          tempSelected.push(feature.type);
        });
        this.selectedFeatures = tempSelected;
      }
    }
  }

  downloadMentorReport() {
    this.companyService
      .getMentorReport({ seCompanyId: this.companyId })
      .subscribe((res: any) => {
        // this.loading = false;
        let excelData: any = [];

        res.data.forEach((data: any) => {
          let dt = {
            "Manager email": data.email,
            "Mentor selected status":
              data.managerMessageCount > 0 ? true : false,
            "Date of mentor selection":
              data.conversation.length > 0
                ? data.conversation[0].message.date
                : "NA",
            "Email of mentor selected":
              data.mentor.length > 0
                ? data.mentor.map((item: any) => `${item.email}`).join(", ")
                : "NA",
            "Number of messages sent": data.managerMessageCount,
            "Number of messages received": data.mentorMessageCount,
            "Last message sent timestamp":
              data.conversation.length > 0
                ? data.conversation
                    .map((chat: any) => {
                      let final;
                      if (chat.manager == chat.message.from)
                        final = chat.message.date + " " + chat.message.time;
                      return final;
                    })
                    .filter(Boolean)
                    .pop()
                : "NA",
            "Last message received timestamp":
              data.conversation.length > 0
                ? data.conversation
                    .map((chat: any) => {
                      let final;
                      if (chat.mentor == chat.message.from)
                        final = chat.message.date + " " + chat.message.time;
                      return final;
                    })
                    .filter(Boolean)
                    .pop()
                : "NA",
          };
          excelData.push(dt);
        });

        const Todaydate = formatDate(new Date(), "dd-MM", "en_US");
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(excelData);
        XLSX.utils.book_append_sheet(wb, ws, "All Managers");

        XLSX.writeFile(wb, `mentor_report.xlsx`);
      });
  }

  assignMentorCompany(mentorDetails: any) {
    const mentorIdIndex = this.mentorIds.findIndex(
      (id) => id === mentorDetails._id
    );
    if (mentorIdIndex !== -1) {
      this.mentorIds.splice(mentorIdIndex, 1);
    } else {
      this.mentorIds.push(mentorDetails._id);
    }
  }

  assignMentorsToCompany() {
    let data = {
      mentorIds: this.mentorIds,
      seCompanyId: this.companyId,
    };
    this.companyService.assignCompanyMentors(data).subscribe((res: any) => {
      if (res.status) {
        this.modalRef.hide();
      }
    });
  }

  getSelectedManagers() {
    this.companyService.selectedmanagersForPackages.subscribe((response) => {
      this.selectedManagers = response;
    });
  }
  getMentors() {
    this.modalRef = this.modalService.show(this.mentorAssign, {
      class: "modal-lg",
    });
    this.companyService.addModal(this.modalRef);
    this.companyService.getallMentors({}).subscribe((res: any) => {
      this.mentorList = res.data;
      this.dataSource.data = res.data;
      // this.dataSource.paginator = this.paginator;
    });
  }

  disableRap() {
    this.loading = true;
    this.companyService.disableRAP().subscribe((res: any) => {
      this.loading = false;
      if (res.status) {
        swal.fire({
          title: "Company Profile",
          text: res.message,
          icon: "success",
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        swal.fire({
          title: "Company Profile",
          text: res.message,
          icon: "error",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    });
  }

  closeMentors() {
    this.modalRef.hide();
  }

  getCompanyProfile() {
    this.loading = true;
    this.companyService
      .getCompanyProfile({ companyId: this.companyId })
      .subscribe((response: any) => {
        this.loading = false;
        if (response.status) {
          this.CompanyInfo = response.data;
          if (response?.packageData?.status) {
            this.CompanyInfo.packageData = response.packageData.data;
          }
          let companyAdmin = response.companyAdmin;
          if (
            companyAdmin.batchName !== undefined &&
            companyAdmin.batchName.length > 0
          ) {
            this.isBatchNamePresent = true;
          }
          let selectedFeatures =
            typeof response.data !== "undefined" &&
            typeof response.data.selectedFeatures !== "undefined"
              ? response.data.selectedFeatures
              : [];
          selectedFeatures.forEach((feature: any) => {
            this.selectedFeatures.push(feature.type);
          });
          if (
            this.selectedFeatures.includes("learning_dashboard") &&
            this.selectedFeatures.includes("l&d")
          ) {
            this.displayView = "learning";
          }
          // console.log(response);
          this.getExistingManagers();
          this.companyService.countOfAllPackages(this.CompanyInfo);
          if (typeof response.batchInCompany !== "undefined") {
            this.batchOptions = response.batchInCompany;
            this.companyService.batchOptions.next(response.batchInCompany);
          }
        } else {
          swal.fire({
            title: "Company Profile",
            text: response.message,
            icon: "error",
            showConfirmButton: false,
            timer: 2500,
          });
        }
      });
  }

  downloadManagerReport() {
    this.companyService.getRegisteredAndSasManager().subscribe((res: any) => {
      // console.log(res);
      if (res.status) {
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.data);

        XLSX.utils.book_append_sheet(wb, ws, "Sheet-1");

        XLSX.writeFile(wb, "Registration Report.xlsx");
      }
    });
  }

  changeBatchName(evt: any) {
    let value = evt.target.value;
    if (value !== "all") {
      this.filters["batchName"] = [value];
    } else {
      this.filters["batchName"] = null;
    }
    this.getExistingManagers({
      filter: "all",
      batchName: this.filters.batchName,
    });
  }

  changeExistingManagerFilters(evt: any) {
    let value = evt.target.value;
    let filter = "";
    let batchName = null;

    if (
      typeof this.filters.batchName !== "undefined" &&
      this.filters.batchName !== ""
    ) {
      batchName = this.filters.batchName;
    } else if (this.batchFilterArray.length > 0) {
      batchName = this.batchFilterArray;
    }
    filter = value;
    this.getExistingManagers({ filter, batchName });
  }

  getExistingManagers(filters = { filter: "all", batchName: null }) {
    this.loading = true;
    this.companyService
      .getExistingManagers(filters)
      .subscribe((response: any) => {
        this.loading = false;
        if (response.status) {
          // console.warn("testing", response);
          this.isBatchNamePresent = response.isBatchPresent;
          this.managers = response.data;
          this.managers.forEach((manager: any) => {
            if (manager.personalInfo.isRegisteredByCompany) {
              this.scoreText = manager.personalInfo.toggleScore;
              let managerInfo: any = {
                name: manager.personalInfo.name,
                email: manager.personalInfo.email,
                profileLink: manager.personalInfo.userName,
                package: manager.packageStatus,
                id: manager._id,
              };
              // console.log(manager.personalInfo.email, "email");
              this.uploadedManagerList.push(managerInfo);
            } else {
              let managerInfo = {
                name: manager.personalInfo.name,
                email: manager.personalInfo.email,
                profileLink: manager.personalInfo.userName,
                package: manager.packageStatus,
                id: manager._id,
              };
              this.data.push(managerInfo);
            }
          });
          this.managersLength =
            typeof response.data !== "undefined" ? response.data.length : 0;
          this.companyService.postCompanyAddedManagerList(this.managers);
          this.companyService.postIndividualAddedManagerList(this.managers);
        }
      });
    // console.log(this.scoreText);
  }
  getDecodedAccessToken(token: string): any {
    try {
      return jwtDecode(token);
    } catch (Error) {
      return null;
    }
  }
  mentorReport() {
    this.router.navigate(["company/company-dashboard/mentor-report"]);
  }
  downloadReport() {
    this.loading = true;
    this.companyService.getCompanyManagersReports().subscribe((res: any) => {
      this.loading = false;
      if (res.status) {
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.firstSheet);
        const ws1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.secondSheet);
        const ws2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.thirdSheet);
        const ws3: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.fourthSheet);
        XLSX.utils.book_append_sheet(wb, ws, "Report after survey");
        XLSX.utils.book_append_sheet(wb, ws1, "Report after action planning");
        XLSX.utils.book_append_sheet(wb, ws2, "Report during implementation");
        XLSX.utils.book_append_sheet(wb, ws3, "Report after final survey");
        XLSX.writeFile(wb, "ManagerReport.xlsx");
      }
    });
  }
  downloadScoreSheet() {
    this.loading = true;
    this.companyService.getCompanyScoreSheetReport().subscribe((res: any) => {
      this.loading = false;
      if (res.status) {
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        // console.log(res.firstSheet, "first sheet");
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.firstSheet);
        const ws1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.secondSheet);
        XLSX.utils.book_append_sheet(wb, ws, "SelfAssesment");
        XLSX.utils.book_append_sheet(wb, ws1, "Team Feedback ");
        XLSX.writeFile(wb, "ScoreSheet.xlsx");
      }
    });
  }

  getData() {
    this.companyService.refreshComponent.subscribe((response) => {
      ////console.log(response)
      // this.ngOnInit()
      this.managersLength = this.managersLength + 1;
    });
  }

  addBulkPlan() {
    this.router.navigate([
      "/company/company-dashboard/add-action-plan/not/add/bulk",
    ]);
  }

  redirectToLaunchSurveyPage() {
    this.router.navigate(["/company/company-dashboard/survey-list"]);
  }

  redirectToSurveyEmailMgmtPage() {
    this.router.navigate(["/company/company-dashboard/survey-email-mgmt"]);
  }

  redirectToSubareas() {
    this.router.navigate(["/company/company-dashboard/subareas"]);
  }

  redirectToUploadActionPlan() {
    this.router.navigate(["/company/company-dashboard/upload-action-plan"]);
  }
  downloadResponseRate() {
    // console.log("changes");
    this.loading = true;
    this.companyService.getResponseRate().subscribe((res: any) => {
      this.loading = false;
      // console.log(res.data);
      if (res.status) {
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.data);
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "Response Rate.xlsx");
      }
    });
  }

  landDStatus() {
    this.loading = true;
    this.companyService.getL_DStatus().subscribe((res: any) => {
      this.loading = false;
      // console.log(res);
      if (res.status) {
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.data);
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "Learning and Development.xlsx");
      }
    });
  }
  apReport() {
    this.loading = true;
    this.companyService.getAp().subscribe((res: any) => {
      this.loading = false;
      // console.log(res);
      if (res.status) {
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.data);
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "Action plan and Implementation.xlsx");
      }
    });
  }
  //
  toggleScores() {
    // scoreHide = unHide
    this.scoreText = !this.scoreText;
    this.loading = true;
    this.companyService
      .toggleScores({ toggleScore: this.scoreText })
      .subscribe((res: any) => {
        this.loading = false;
        // console.log(res);
      });
  }
  toggleComments() {
    this.commentText = !this.scoreText;
    this.loading = true;
    this.companyService
      .toggleComments({ toggleComments: this.commentText })
      .subscribe((res: any) => {
        this.loading = false;
        // console.log(res);
      });
  }
  addLandD() {
    this.loading = true;
    this.companyService.addCustomLandD().subscribe((res: any) => {
      this.loading = false;
      // console.log(res);
    });
  }
  assesmentScore() {
    this.loading = true;
    this.companyService.getAssessmentScores().subscribe((res: any) => {
      this.loading = false;
      if (res.status) {
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.data);
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "Assessment.xlsx");
      }
    });
  }
  getIuFeedbackReport() {
    this.loading = true;
    this.companyService.getIuReports().subscribe((res: any) => {
      this.loading = false;
      // console.log(res);
      if (res.status) {
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.data);
        const ws2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.data2);
        XLSX.utils.book_append_sheet(wb, ws, "Team");
        XLSX.utils.book_append_sheet(wb, ws2, "Supervisor");
        XLSX.writeFile(wb, "Implementation Update Feedback.xlsx");
      } else {
        swal.fire({
          title: "Report",
          text: "Something went wrong!",
          icon: "error",
          showConfirmButton: true,
        });
      }
    });
  }
  getApReports() {
    this.loading = true;
    this.companyService.getApReports().subscribe((res: any) => {
      this.loading = false;
      // console.log(res);
      if (res.status) {
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.data);
        const ws1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.data1);
        const ws2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.data2);
        const ws3: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.data3);
        const ws4: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.data4);
        XLSX.utils.book_append_sheet(wb, ws, "Interim Feedback - Team Members");
        XLSX.utils.book_append_sheet(
          wb,
          ws1,
          "Score from Team Feedback Survey"
        );
        XLSX.utils.book_append_sheet(wb, ws2, "Interim Feedback - Supervisors");
        XLSX.utils.book_append_sheet(wb, ws3, "Action Plan Only");
        XLSX.utils.book_append_sheet(wb, ws4, "Score from Supervisor Survey");
        XLSX.writeFile(wb, "Action plan Feedback.xlsx");
      }
    });
  }
  redirectToSupervisor() {
    this.router.navigate(["/company/company-dashboard/supervisor"]);
  }
  getManagerActionPlanReports() {
    this.loading = true;
    this.companyService.getManagerActionPlanReports().subscribe((res: any) => {
      this.loading = false;
      if (res.status) {
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.data);
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "Action plan Feedback.xlsx");
      } else {
        swal.fire({
          title: "Report",
          text: "Something went wrong!",
          icon: "error",
          showConfirmButton: true,
        });
      }
    });
  }
  selectBatchName(e: any) {
    this.batchFilterArray = e.value;
    if (this.batchFilterArray.length == 0) {
      this.getExistingManagers({ filter: "all", batchName: null });
    } else {
      this.getExistingManagers({
        filter: "all",
        batchName: this.batchFilterArray,
      });
    }
  }

  downloadManagerPis() {
    this.companyService.getManagerPIS().subscribe((blob: Blob) => {
      const filename = "employee-data.xlsx";
      saveAs(blob, filename);
    });
  }

  downloadManagerStatusReport() {
    this.companyService.downloadManagerStatusReport().subscribe((res: any) => {
      if (res.status) {
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.data);
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "Manager Status Report.xlsx");
      } else {
        swal.fire({
          title: "Report",
          text: "Something went wrong!",
          icon: "error",
          showConfirmButton: true,
        });
      }
    });
  }
  pageChanged(event: any): void {
    // Your logic for handling page changes
    console.log("Page changed:", event);
  }
  test() {
    console.log("Testing");
  }
}
