<div class="company-main">
  <div class="container">
    <div class="row">
      <app-company-header></app-company-header>
      <!-- //<app-loader *ngIf="loading"></app-loader> -->
      <div class="col-md-12 recentlyadded">
        <!-- Basic View //This is Old Section (Classic View)-->
        <div
          class="example-container"
          style="padding: 15px; background-color: #fff"
          *ngIf="displayView == 'basic'"
        >
          <div class="recentlyadded-title">
            <h4 class="managerText red-header-text">Managers</h4>
          </div>
          <mat-tab-group>
            <mat-tab label="Your Managers ({{ managersLength }}) ">
              <div class="col-sm-6" style="margin-top: 10px">
                <div class="row">
                  <div class="col-sm-6" *ngIf="!isBatchNamePresent">
                    <div class="form-group">
                      <label for="batch">Batch</label>
                      <ngx-select-dropdown
                        id="batch"
                        style="margin: 20px"
                        (change)="selectBatchName($event)"
                        [multiple]="true"
                        [config]="configAccess"
                        name="batchFilters"
                        [options]="batchOptions"
                      ></ngx-select-dropdown>
                      <!-- </label> -->
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="filters">Filters: </label>
                      <select
                        class="form-control"
                        id="filters"
                        (change)="changeExistingManagerFilters($event)"
                      >
                        <option value="all">All Managers</option>
                        <option value="registered">Registered</option>
                        <option value="notRegistered">Not Registered</option>
                        <!-- <option value="completeAllLearningModule">
                      Completed All Modules
                    </option>
                    <option value="notCompleteAllLearningModule">
                      Incomplete Modules
                    </option> -->
                      </select>
                      <!-- </label> -->
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-sm-6"
                style="margin-top: 30px"
                *ngIf="!isBatchNamePresent"
              >
                <div class="dropdown">
                  <button
                    class="btn gmi-primary-btn dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                  >
                    Actions <span class="caret"></span>
                  </button>
                  <ul class="dropdown-menu">
                    <li class="dropdown-header">Reports</li>
                    <li>
                      <a (click)="downloadManagerPis()">Manager PIS</a>
                    </li>
                    <li>
                      <a (click)="downloadManagerStatusReport()"
                        >Manager Status</a
                      >
                    </li>
                    <li>
                      <a (click)="downloadManagerReport()">Registration</a>
                    </li>
                    <li>
                      <a (click)="getIuFeedbackReport()"
                        >Implementation Update Feedback</a
                      >
                    </li>
                    <li class="dropdown-header">Other action</li>
                    <li>
                      <a>
                        <app-group-packages-assign></app-group-packages-assign>
                      </a>
                    </li>
                    <li>
                      <a>
                        <app-assign-batches></app-assign-batches>
                      </a>
                    </li>
                    <li>
                      <a (click)="redirectToSubareas()">
                        Add Subareas and Goals
                      </a>
                    </li>
                    <li>
                      <a>
                        <app-action-plan-forms></app-action-plan-forms>
                      </a>
                    </li>
                    <li>
                      <a
                        (click)="addBulkPlan()"
                        *ngIf="selectedFeatures.includes('ap')"
                        >Add Recommended Action Plan(s)
                        <span
                          ><i
                            class="fa fa-info-circle info"
                            aria-hidden="true"
                            matTooltip="Add company recomended action plan(s) for multiple/all managers"
                            matTooltipClass="custom-tooltip"
                          ></i></span
                      ></a>
                    </li>
                    <li>
                      <a (click)="redirectToSupervisor()">
                        Manage Supervisor
                      </a>
                    </li>
                    <!-- <li>
                      <a>
                        <app-view-batches></app-view-batches>
                      </a>
                    </li> -->
                    <li>
                      <a>
                        <app-company-send-email></app-company-send-email>
                      </a>
                    </li>
                    <li>
                      <a (click)="redirectToUploadActionPlan()">
                        Upload Action Plan
                      </a>
                    </li>
                    <li>
                      <a (click)="getMentors()"> Assign Mentor </a>
                    </li>
                    <li>
                      <a (click)="downloadMentorReport()">
                        Download Mentor Report
                      </a>
                    </li>
                    <li>
                      <a>
                        <app-assign-nudges></app-assign-nudges>
                      </a>
                    </li>
                      <li>
                        <a>
                          <app-reset-sas></app-reset-sas>
                        </a>
                      </li>
                      <li>
                        <a>
                          <app-reset-ap-ld></app-reset-ap-ld>
                        </a>
                      </li>
                    <li>
                      <a
                        [routerLink]="
                          selectedManagers?.length > 0
                            ? '/company/company-dashboard/leaderboard-report'
                            : null
                        "
                        [state]="{ managers: this.selectedManagers }"
                        [ngClass]="{
                          disabled: this.selectedManagers?.length <= 0
                        }"
                        [attr.aria-disabled]="
                          selectedManagers?.length === 0 ? 'true' : null
                        "
                      >
                        Schedule Leadership Mails
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-sm-12">
                <app-company-added-manager-list></app-company-added-manager-list>
              </div>
            </mat-tab>
            <mat-tab
              label="Managers registered Individually from your company ({{
                data.length
              }})"
            >
              <app-individual-manager-list></app-individual-manager-list>
            </mat-tab>
          </mat-tab-group>
        </div>
        <!-- Learning View //This is Seprate view for Learning Program-->
        <div
          class="learningSection"
          style="padding: 15px; background-color: #fff"
          *ngIf="displayView == 'learning'"
        >
          <div class="recentlyadded-title">
            <h4 style="color: #005071">Manager Details</h4>
          </div>
          <div class="col-sm-6" style="margin-top: 10px">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label>Batch:</label>
                  <select
                    class="form-control"
                    id="batchFilters"
                    (change)="changeBatchName($event)"
                  >
                    <option value="all">All Batch</option>
                    <option *ngFor="let list of batchOptions" [value]="list">
                      {{ list }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>Filters:</label>
                  <select
                    class="form-control"
                    id="learningFilters"
                    (change)="changeExistingManagerFilters($event)"
                  >
                    <option value="all">All Managers</option>
                    <option value="registered">Registered</option>
                    <option value="notRegistered">Not Registered</option>
                    <option value="completeAllLearningModule">
                      Completed All Modules
                    </option>
                    <option value="notCompleteAllLearningModule">
                      Incomplete Modules
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6" style="margin-top: 30px">
            <div class="dropdown" style="float: right; margin-top: 22px">
              <button
                class="btn btn-primary dropdown-toggle"
                type="button"
                data-toggle="dropdown"
              >
                Actions <span class="caret"></span>
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a>
                    <app-company-send-email></app-company-send-email>
                  </a>
                </li>
                <li>
                  <a>
                    <app-group-packages-assign></app-group-packages-assign>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-sm-12">
            <app-learning-manager-list></app-learning-manager-list>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12" style="margin-bottom: 20px">
        <app-pages-setting></app-pages-setting>
      </div>
    </div>
  </div>
</div>

<ng-template #mentorAssign>
  <div class="mentor-wrapper">
    <div class="mat-elevation-z4">
      <mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="checkboxes">
          <mat-header-cell *matHeaderCellDef
            ><input (change)="assignMentorCompany(element)" type="checkbox"
          /></mat-header-cell>
          <mat-cell *matCellDef="let element">
            <input type="checkbox" (change)="assignMentorCompany(element)" />
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="mentorName">
          <mat-header-cell *matHeaderCellDef>Mentor Name</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.name }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="mentorEmail">
          <mat-header-cell *matHeaderCellDef>Mentor Email</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.email }}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let col; columns: displayedColumns"></mat-row>
      </mat-table>

      <mat-paginator
        [pageSizeOptions]="[45, 60, 75]"
        (page)="pageChanged($event)"
      ></mat-paginator>
    </div>
    <div class="" style="margin-top: 10px; text-align: center">
      <button class="cancel" (click)="closeMentors()">Cancel</button>
      <button
        class="submit"
        [disabled]="mentorIds.length == 0"
        (click)="assignMentorsToCompany()"
      >
        Assign Mentors
      </button>
    </div>
  </div>
</ng-template>
