import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-batch',
  templateUrl: './add-batch.component.html',
  styleUrls: ['./add-batch.component.css']
})
export class AddBatchComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    
  }

  sendToAddBatchPage(){
    console.log('manager')
  }
  
  viewBatchPage(){
    console.log('view batch')
  }
}
