import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { AdminServices } from "../admin.service";
import * as XLSX from "xlsx";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: "app-digital-profile",
  templateUrl: "./digital-profile.component.html",
  styleUrls: ["./digital-profile.component.css"],
})
export class DigitalProfileComponent implements OnInit {
  allAchievementDetails: any;
  modalForm: FormGroup;
  CUSTOM_ACHIEVEMENT_ID = 3;
  customAchievementManagers: any = [];
  dataSourceCustom = new MatTableDataSource<any>([]); // I
  selectedFile: File | null = null;
  fileError: string = "";
  @ViewChild("modalTemplate") modalTemplate: any;
  @ViewChild("customModal") customModal: any;
  constructor(
    private adminService: AdminServices,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private fb: FormBuilder
  ) {
    this.modalForm = this.fb.group({
      achievementTitle: ["", Validators.required],
      achievementDescription: ["", Validators.required],
      baseImage: [
        "",
        [
          Validators.required,
          Validators.pattern(
            "https?://(?:[-\\w.]|(?:%[\\da-fA-F]{2}))+(/[-\\w@:%_+.~#?,&//=]*)?"
          ),
        ],
      ],
      message: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.getAllAchievement();
  }

  getAllAchievement() {
    this.adminService.getAllAchievement().subscribe((res: any) => {
      if (res.status) {
        this.allAchievementDetails = res.data;
      } else {
        this.toastr.error(res.message ? res.message : "Something went wrong ");
      }
    });
  }

  displayedColumns: string[] = [
    "achievementTitle",
    "achievementDescription",
    "message",
    "baseImage",
    "isActive",
    "achievementType",
    "actions",
  ];

  displayedColumnsCustom: string[] = [
    "name",
    "email_id",
    "achievement_title",
    "achievement_date",
    "achievement_image_url",
    "trackable_url",
  ];

  editRow(row: any) {
    row.isEditing = true;
  }

  saveRow(row: any) {
    row.isEditing = false;

    // Map the row object to the desired keys
    const finalData = {
      message: row.message,
      is_active: !!row.isActive,
      base_image: row.baseImage,
      achievement_id: row.achievementId,
      achievement_type: row.achievementTypeId,
      achievement_title: row.achievementTitle,
      achievement_description: row.achievementDescription,
    };

    // Send the mapped object to the service
    this.adminService.updateAchievement(finalData).subscribe(
      (res: any) => {
        // Success handling
        if (res.status) {
          this.toastr.success(
            res.message || "Achievement updated successfully!"
          );
        } else {
          this.toastr.error(res.error || "Cannot update achievement");
        }
      },
      (error) => {
        // Error handling
        const errorMessage =
          error.error.error || "An unexpected error occurred";
        this.toastr.error(errorMessage);
      }
    );
  }

  cancelEdit(row: any) {
    row.isEditing = false;
    // Reload original values if necessary
  }

  onToggleActive(row: any) {
    row.isActive = !row.isActive;
  }

  openModal() {
    this.dialog.open(this.modalTemplate, {
      width: "500px",
    });
  }

  onFileChange(event: any) {
    const file = event.target.files[0];
    const allowedExtensions = /(\.xlsx|\.xls|\.csv)$/i;

    if (file && allowedExtensions.exec(file.name)) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const headers = this.getHeaders(sheet);

        // Check if required headers are present
        if (
          !headers.includes("manager_email") ||
          !headers.includes("manager_id")
        ) {
          this.fileError =
            'File must contain "manager_email" and "manager_id" headers.';
          this.toastr.error(this.fileError);
          this.selectedFile = null;
          return;
        }

        // File is valid
        this.fileError = null;
        this.selectedFile = file;
      };

      reader.readAsArrayBuffer(file);
    } else {
      this.fileError =
        "Invalid file type. Please upload a .xlsx, .xls, or .csv file.";
      this.toastr.error(this.fileError);
      this.selectedFile = null;
    }
  }

  getHeaders(sheet: XLSX.WorkSheet): string[] {
    const headers: string[] = [];
    const range = XLSX.utils.decode_range(sheet["!ref"]!);

    for (let c = range.s.c; c <= range.e.c; c++) {
      const cell = sheet[XLSX.utils.encode_cell({ r: range.s.r, c })];
      if (cell && cell.v) {
        headers.push(cell.v.toString().toLowerCase());
      }
    }

    return headers;
  }

  onModalSubmit() {
    if (this.modalForm.valid && !this.fileError) {
      const formData = new FormData();
      formData.append(
        "achievementTitle",
        this.modalForm.value.achievementTitle
      );
      formData.append(
        "achievementDescription",
        this.modalForm.value.achievementDescription
      );
      formData.append("baseImage", this.modalForm.value.baseImage);
      formData.append("message", this.modalForm.value.message);

      if (this.selectedFile) {
        formData.append("file", this.selectedFile);
      }

      this.adminService.addCustomAchievement(formData).subscribe((res: any) => {
        if (res.status) {
          this.toastr.success(
            res.message ? res.message : "Added Custom Achievement"
          );
        } else {
          this.toastr.error(
            res.message ? res.message : "Cannot Add Custom Achievement"
          );
        }
      });

      // Add the new achievement to the table data source
      const newAchievement = {
        ...this.modalForm.value,
        baseImage: this.modalForm.value.baseImage,
        message: this.modalForm.value.message,
        isActive: true, // Default value
      };

      this.allAchievementDetails.push(newAchievement);
      this.dialog.closeAll(); // Close the modal
    }
  }

  viewAchievement(element: any): void {
    const data = {
      achievementId: element.achievementId,
    };

    this.adminService
      .getCustomAchievementDetails(data)
      .subscribe((res: any) => {
        if (res.status) {
          console.log("API Response:", res); // Debugging
          this.customAchievementManagers = res.data;
          this.dataSourceCustom.data = this.customAchievementManagers; // Update MatTableDataSource
        } else {
          console.error("Error: No data found");
          this.customAchievementManagers = []; // Reset if no data
          this.dataSourceCustom.data = []; // Reset dataSource
        }

        this.dialog.open(this.customModal, {
          data: this.customAchievementManagers, // Pass the data to modal
        });
      });
  }
}
