import { saveAs } from "file-saver";
import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import swal from "sweetalert2";
import { CompanyServices } from "../company.service";
// import * as jwt_decode from "jwt-decode";
import { jwtDecode } from "jwt-decode";
import * as XLSX from "xlsx";
import { ExcelService } from "../../excel-service.service";
type AOA = any[][];
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { SelectionModel } from "@angular/cdk/collections";
import { constants } from "../../constants";
@Component({
  selector: "app-survey-list",
  templateUrl: "./survey-list.component.html",
  styleUrls: ["./survey-list.component.css"],
})
export class SurveyListComponent implements OnInit {
  constructor(
    private companyService: CompanyServices,
    private excelService: ExcelService,
    private router: Router,
    private modalService: BsModalService
  ) {}
  benchmarks: any = new MatTableDataSource([]);
  selection = new SelectionModel<BenchmarkModel>(true, []);
  modalRef: BsModalRef;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("viewReport") viewReport: any;

  displayedColumns: string[] = ["select", "benchmarkName", "createdAt"];
  surveyLists: any = [];
  surveyToggle: any = {};
  rtfMailToggle: any = {};
  surveyToggleText: any = {};
  surveyStatus: any = {};
  decodedData: any = {};
  surveyOrderDetails: any = {};
  updateSurveyName: string;
  updateSurveyNameId: string;
  currentSurveyId: string;
  q1: number = 0;
  q2: number = 0;
  q3: number = 0;
  q4: number = 0;
  seCompanyId: any;
  access: any = {
    launchButtonAccess: false,
    gpmsReportsAccess: false,
    generateOrgReportAccess: false,
  };
  isRealTimeFeedback: boolean = false;
  gpmsOrgReportLink: string = "";
  companyName: string = "";

  ngOnInit() {
    this.checkAccess();
    this.getSurvey();
    this.getAllBenchmark();
  }
  // SOC Table
  applyFilter(filterValue: string) {
    this.benchmarks.filter = filterValue.trim().toLowerCase();
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.benchmarks.data.length;
    return numSelected === numRows;
  }
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.benchmarks.data.forEach((row) => this.selection.select(row));
  }

  checkboxLabel(row?: BenchmarkModel): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
      row.id + 1
    }`;
  }

  getUrl(link: string): string {
    return `${constants.IMAGE_URL}${link}`;
  }

  getOrgReport(companySurveyId) {
    this.companyService
      .getOrgReport({ companySurveyGroupId: companySurveyId })
      .subscribe(
        (response) => {
          const swalType = response.status ? "success" : "error";
          swal.fire(
            response.status ? "Success" : "Error",
            response.message,
            swalType
          );
        },
        (error) => {
          // Handle error response here
          // For example, show a toast message
          swal.fire("Error", "Failed to fetch org report", "error");
        }
      );
  }
  // EOC
  // SOC : Benchmark operations

  // updateCompanyNudge(order,type,value){
  //   let params={
  //     surveyOrder:order,
  //     surveyType:type,
  //     companyNudge:value
  //   }

  //   this.companyService.sendCompanyNudge(params).subscribe(res=>{
  //     console.log(res);
  //   })

  // }
  getAllBenchmark() {
    this.companyService.getAllBenchmarks().subscribe((res) => {
      let benchmarks: BenchmarkModel[] = [];
      if (res.data.list) {
        res.data.list.forEach((benchmark) => {
          benchmarks.push({
            id: benchmark.id,
            benchmarkName: benchmark.benchmark_name,
            createdAt: benchmark.created_at,
          });
        });
        this.benchmarks = new MatTableDataSource(benchmarks);
        this.benchmarks.sort = this.sort;
        this.benchmarks.paginator = this.paginator;
      }
    });
  }

  openMappingPopup(surveyId, template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    this.companyService.addModal(this.modalRef);
    this.currentSurveyId = surveyId;
  }

  mapBenchmark() {
    if (this.selection.selected.length > 3) {
      swal.fire({
        title: "Warning",
        text: "You can only map up to 3 benchmarks",
        icon: "warning",
        showConfirmButton: true,
      });
      // return false;
    } else if (this.selection.selected.length == 0) {
      swal.fire({
        title: "Warning",
        text: "Please select at least one benchmark",
        icon: "warning",
        showConfirmButton: true,
      });
      // return false;
    }

    let data = {
      surveyId: this.currentSurveyId,
      benchmarks: this.selection.selected,
      seCompanyId: this.seCompanyId,
    };

    this.companyService.mapSurveyGrpWithBenchmark(data).subscribe((res) => {
      this.getSurvey();
      this.modalRef.hide();
      this.modalRef = null;

      if (res.status) {
        window.open(res.orgReportLink, "_blank");
        window.open(res.summReportLink, "_blank");
        swal.fire({
          title: "Survey list",
          text: "Mapping successful!",
          icon: "success",
          showConfirmButton: true,
        });
        // return true;
      }
      swal.fire({
        title: "Survey list",
        text: res.message,
        icon: "error",
        showConfirmButton: true,
      });
    });
  }

  // EOC
  getDecodedAccessToken(token: string): any {
    try {
      // return jwt_decode(token);
      return jwtDecode(token);
    } catch (Error) {
      return null;
    }
  }

  checkAccess() {
    let decodeToken = this.getDecodedAccessToken(localStorage.getItem("token"));
    this.decodedData = decodeToken;
    // console.warn("decodeToken",decodeToken)
    if (decodeToken) {
      this.seCompanyId = decodeToken.companyId;
      this.access.reportsAccess =
        decodeToken.access.filter((acc) => acc.value == "survey").length > 0;
      if (!this.access.reportsAccess) {
        this.router.navigate(["/404"]);
      }
      this.access.launchButtonAccess =
        decodeToken.access.filter((acc) => acc.value == "launch_survey")
          .length > 0;
      this.access.gpmsReportsAccess =
        decodeToken.access.filter((acc) => acc.value == "gpms_report").length >
        0;
      this.access.generateOrgReportAccess =
        decodeToken.access.filter((acc) => acc.value == "org_report").length >
        0;
    }
  }

  redirectToPage(type, surveyId) {
    switch (type) {
      case "edit":
        this.router.navigate([
          "/company/company-dashboard/survey/edit",
          surveyId,
        ]);
        break;
      case "email":
        this.router.navigate([
          "/company/company-dashboard/survey-email-mgmt/survey/",
          surveyId,
        ]);
        break;
      case "whatsapp":
        this.router.navigate([
          "/company/company-dashboard/survey-whatsapp-mgmt/survey/",
          surveyId,
        ]);
        break;
      case "sms":
        this.router.navigate([
          "/company/company-dashboard/survey-sms-mgmt/survey/",
          surveyId,
        ]);
        break;
      case "score":
        this.surveyToggle[surveyId] = !this.surveyToggle[surveyId];
        this.surveyToggleText[surveyId] = this.surveyToggle[surveyId]
          ? "Hide Score"
          : "Show Score";
        let data = {
          surveyId: surveyId,
          toggleState: this.surveyToggle[surveyId],
        };

        this.companyService
          .toggleScoresByCompanySurveyId(data)
          .subscribe((res: any) => {
            if (res.status) {
              this.getSurvey();
              swal.fire({
                title: "Show/Hide Score",
                text: res.message,
                icon: "success",
                showConfirmButton: true,
                timer: 250000,
              });
              // return true;
            } else {
              swal.fire({
                title: "Show/Hide Score",
                text: res.message,
                icon: "error",
                showConfirmButton: true,
                timer: 250000,
              });
            }
          });
        break;
      case "leaderboard":
        this.router.navigate([
          "/company/company-dashboard/survey/leaderboard-report",
          surveyId,
        ]);
        break;
      default:
        break;
    }
  }
  openUpdateSurveyNameModal(id, name, template) {
    this.updateSurveyNameId = id;
    this.updateSurveyName = name;
    this.modalRef = this.modalService.show(template);
    this.companyService.addModal(this.modalRef);
  }

  surveyNameUpdate() {
    if (
      this.updateSurveyName != undefined &&
      this.updateSurveyName.trim().length == 0
    ) {
      swal.fire({
        title: "Please enter survey name",
        text: "Survey name cannot be empty",
        icon: "error",
        showConfirmButton: true,
      });
      return;
    }
    let data = {
      surveyName: this.updateSurveyName,
      companySurveyId: this.updateSurveyNameId,
    };
    this.companyService.updateSurveyName(data).subscribe((res) => {
      if (res.status) {
        this.modalRef.hide();
        this.modalRef = null;
        this.getSurvey();
      } else {
        swal.fire({
          title: "Cannot Update survey Name",
          text: "Something went wrong",
          icon: "error",
          showConfirmButton: true,
        });
      }
    });
  }

  getSurvey() {
    this.companyService.getCompanyWiseSurvey().subscribe((res: any) => {
      if (res.status) {
        this.surveyLists = res.data;
        this.surveyLists.forEach((survey: any) => {
          this.surveyToggle[survey._id] = survey.toggleScore;
          this.surveyToggleText[survey._id] = this.surveyToggle[survey._id]
            ? "Hide Score"
            : "Show Score";
          if (survey.surveyType == "RealTimeFeedback") {
            this.isRealTimeFeedback = true;
          }
          this.rtfMailToggle[survey._id] = survey?.rtf_manager_mail_status
            ? true
            : false;
        });

        return true;
      } else {
        swal.fire({
          title: "Survey list",
          text: "Something went wrong!",
          icon: "error",
          showConfirmButton: true,
        });
        return false;
      }
    });
  }

  report(surveyId, reportType) {
    let data = {
      type: reportType,
      surveyId,
    };
    let sheetName = "Response Rate";
    switch (reportType) {
      case "pis":
        sheetName = "PIS";
        break;
      case "surveyScoreSheet":
        sheetName = "Survey Score Sheet";
        break;
      case "gpms":
        sheetName = "GPMS Manager Report";
        break;
      case "orgReport":
        sheetName = "Org Report";
        break;
      case "surveyComments":
        sheetName = "x Report";
        break;
    }
    if (reportType == "pis") {
      this.companyService.getCompanyReportBlob(data).subscribe((blob: Blob) => {
        const filename = "Survey-PIS-Data.xlsx";
        saveAs(blob, filename);
      });
    } else {
      this.companyService.getCompanyReport(data).subscribe((res) => {
        if (res.status) {
          if (reportType == "pis" && res.data.isAlldata) {
            const workbook = XLSX.utils.book_new();
            const managerSheet = XLSX.utils.json_to_sheet(
              res.data.managerSheetData
            );
            const employeeSheet = XLSX.utils.json_to_sheet(
              res.data.employeeSheetData
            );
            XLSX.utils.book_append_sheet(
              workbook,
              employeeSheet,
              "Respondents Data"
            );
            XLSX.utils.book_append_sheet(
              workbook,
              managerSheet,
              "Participants Data"
            );
            this.excelService.exportAsExcelFile(workbook, `${sheetName}`);
          } else if (reportType == "orgReport") {
            const dataForExcel = res.data.dataToSend
              ? res.data.dataToSend
              : res.data;
            const workBook = XLSX.utils.book_new();
            const workSheet = XLSX.utils.json_to_sheet(dataForExcel);
            if (dataForExcel.length > 0 && dataForExcel[0].Dimension) {
              const merges = [];
              let currentTheme = "";
              let mergeStartRow = 1;
              let mergeEndRow = 0;
              for (let i = 0; i < dataForExcel.length; i++) {
                let rowIndex = i;
                let row = dataForExcel[i];
                const theme = row["Dimension"];
                const subTheme = row["SubDimension"];
                if (subTheme === "NA") {
                  continue;
                }
                if (theme !== currentTheme) {
                  merges.push({
                    s: { r: mergeStartRow, c: 0 },
                    e: { r: mergeEndRow, c: 0 },
                  });

                  mergeStartRow = rowIndex + 1;
                  currentTheme = theme;
                }
                mergeEndRow = rowIndex + 1;
              }
              merges.push({
                s: { r: mergeStartRow, c: 0 },
                e: { r: mergeEndRow, c: 0 },
              });
              // workSheet["!merges"] = merges;
              XLSX.utils.book_append_sheet(workBook, workSheet, "data");
              const merging = [];
              let currentDemographic = "";
              let mergeStartForDemo = 1;
              let mergeEndForDemo = 1;
              for (
                let i = 0;
                i < res.data.respondentsByDemographic.length;
                i++
              ) {
                let rowIndex = i;
                let row = res.data.respondentsByDemographic[i];
                const demographic = row["demographicName"];
                if (demographic != currentDemographic) {
                  merging.push({
                    s: { r: mergeStartForDemo, c: 1 },
                    e: { r: mergeEndForDemo, c: 1 },
                  });

                  mergeStartForDemo = rowIndex + 1;
                  currentDemographic = demographic;
                }
                mergeEndForDemo = rowIndex + 1;
              }
              merging.push({
                s: { r: mergeStartForDemo, c: 1 },
                e: { r: mergeEndForDemo, c: 1 },
              });
              merging.push({
                s: { r: 1, c: 0 },
                e: { r: res.data.respondentsByDemographic.length, c: 0 },
              });
              const respondentDetails = XLSX.utils.json_to_sheet(
                res.data.respondentsByDemographic
              );
              respondentDetails["!merges"] = merging;
              XLSX.utils.book_append_sheet(
                workBook,
                respondentDetails,
                "Respondent Numbers"
              );
              const respondentsByType = XLSX.utils.json_to_sheet(
                res.data.respondentsByType
              );
              XLSX.utils.book_append_sheet(
                workBook,
                respondentsByType,
                "Respondent Numbers By Type"
              );
            } else {
              XLSX.utils.book_append_sheet(workBook, workSheet, "Org Report");
            }
            this.excelService.exportAsExcelFile(
              workBook,
              `${sheetName}-${res.companyName}`
            );
          } else {
            const workBook = XLSX.utils.book_new();
            const workSheet = XLSX.utils.json_to_sheet(res.data);
            XLSX.utils.book_append_sheet(workBook, workSheet, "data");
            this.excelService.exportAsExcelFile(
              workBook,
              `${sheetName}-${res.companyName}`
            );
          }
        } else {
          swal.fire({
            title: "Report",
            text: res.message,
            icon: "error",
            showConfirmButton: true,
            timer: 250000,
          });
        }
      });
    }
  }

  masterReport(surveyId) {
    let data = {
      type: "reportType",
      surveyId,
    };
    let sheetName = "Master Report";
    this.companyService.masterReport(data).subscribe((res) => {
      if (res.status) {
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.responseRate);
        const ws1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
          res.respondentDetails
        );
        XLSX.utils.book_append_sheet(wb, ws, "Survey Response Report");
        XLSX.utils.book_append_sheet(wb, ws1, "Respondents Details");

        this.excelService.exportAsExcelFile(
          wb,
          `${sheetName}-${res.companyName}.xlsx`
        );
      } else {
        swal.fire({
          title: "Report",
          text: res.message,
          icon: "error",
          showConfirmButton: true,
          timer: 250000,
        });
      }
    });
  }

  orgReport(link, type?) {
    window.open(link, "_blank");
  }

  getTwoByTwoData(surveyId, template) {
    // this.modalService.show(template);
    let data = {
      surveyId: surveyId,
    };
    this.companyService.addModal(this.modalRef);
    this.companyService.getTwoByTwo(data).subscribe((res: any) => {
      if (res.status) {
        this.modalRef = this.modalService.show(template);
        this.companyService.addModal(this.modalRef);
        this.q1 = res.data.q1;
        this.q2 = res.data.q2;
        this.q3 = res.data.q3;
        this.q4 = res.data.q4;
      }
    });
  }

  openAssignNudge(surveyOrder, surveyType, survey, template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    this.companyService.addModal(this.modalRef);
    this.surveyOrderDetails = {
      surveyOrder: surveyOrder,
      surveyType: surveyType,
      seCompanyId: this.seCompanyId,
    };
  }

  auditReport(surveyId: string) {
    const data = {
      type: "auditReport",
      surveyId,
    };
    let sheetName = "Audit Report";
    this.companyService.getAuditReport(data).subscribe((res) => {
      if (res.status && res.data.surveyResponse.length > 0) {
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
          res.data.surveyResponse
        );
        const ws1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
          res.data.managerSurveyComments
        );
        XLSX.utils.book_append_sheet(wb, ws, "Audit Fail Sheet");
        XLSX.utils.book_append_sheet(wb, ws1, "Comments Data");
        this.excelService.exportAsExcelFile(wb, `${sheetName}.xlsx`);
      } else {
        swal.fire({
          title: "Report",
          text: res.message || "No Audit cases found.",
          icon: "error",
          showConfirmButton: true,
          timer: 250000,
        });
      }
    });
  }
  generateOrgReport(survey: any, reportTemplate: any) {
    if (survey.orgReport && typeof survey.orgReport == "string") {
      this.gpmsOrgReportLink = survey.orgReport;
      this.companyName = survey.companyName;
      this.modalRef = this.modalService.show(reportTemplate);
      this.companyService.addModal(this.modalRef);
    } else {
      const surveyGroupId = survey._id.toString();
      // TODO: CHANGE BENCHMARK ID TO 59 FOR PRD
      const benchmarkId = 28;
      const gpmsReportData = {
        surveyGroupId: surveyGroupId, // Survey group ID
        benchmarkId: [
          {
            id: benchmarkId,
          },
        ],
        seCompanyId: this.seCompanyId,
      };
      this.companyService.generateGpmsOrgReport(gpmsReportData).subscribe({
        next: (res: any) => {
          if (res.status) {
            this.gpmsOrgReportLink = res.data.orgReportLink;
            this.modalRef = this.modalService.show(this.viewReport);
            this.companyService.addModal(this.modalRef);
          }
        },
        error: (err) => {
          console.log(err);
          swal.fire({
            title: err.error.message,
            text: "Something went wrong while generating org report",
            icon: "error",
            showConfirmButton: true,
          });
        },
      });
    }
  }

  getSurveyResponseRateV2(
    companySurveyGroupId: string,
    seCompanySurveyGroupId: number
  ) {
    const data = {
      seCompanySurveyGroupId,
      seCompanyId: this.seCompanyId,
      companySurveyGroupId,
    };

    this.companyService.getSurveyResponseRateV2(data).subscribe(
      (response) => {
        swal.fire(
          response.status ? "Success" : "Error",
          response.message,
          response.status ? "success" : "error"
        );
      },
      (error) => {
        swal.fire(
          "Error",
          "Failed to fetch survey response rate report.",
          "error"
        );
      }
    );
  }
  getMasterSurveyResponseRateV2(
    companySurveyGroupId: string,
    seCompanySurveyGroupId: number
  ) {
    const data = {
      seCompanySurveyGroupId,
      seCompanyId: this.seCompanyId,
      companySurveyGroupId,
    };

    this.companyService.getMasterSurveyResponseRateV2(data).subscribe(
      (response) => {
        swal.fire(
          response.status ? "Success" : "Error",
          response.message,
          response.status ? "success" : "error"
        );
      },
      (error) => {
        swal.fire(
          "Error",
          "Failed to fetch master survey response rate report.",
          "error"
        );
      }
    );
  }
  getSurveyScoreSheetV2(
    companySurveyGroupId: string,
    seCompanySurveyGroupId: number
  ) {
    const data = {
      seCompanySurveyGroupId,
      seCompanyId: this.seCompanyId,
      companySurveyGroupId,
    };

    this.companyService.getSurveyScoreSheetV2(data).subscribe(
      (response) => {
        swal.fire(
          response.status ? "Success" : "Error",
          response.message,
          response.status ? "success" : "error"
        );
      },
      (error) => {
        swal.fire("Error", "Failed to fetch Org report.", "error");
      }
    );
  }

  getOrgReportNew(
    companySurveyGroupId: string,
    seCompanySurveyGroupId: number
  ) {
    const data = {
      seCompanySurveyGroupId,
      seCompanyId: this.seCompanyId,
      companySurveyGroupId,
    };

    this.companyService.getOrgReportNew(data).subscribe(
      (response) => {
        swal.fire(
          response.status ? "Success" : "Error",
          response.message,
          response.status ? "success" : "error"
        );
      },
      (error) => {
        swal.fire(
          "Error",
          "Failed to fetch survey score sheet report.",
          "error"
        );
      }
    );
  }

  isGreaterThanSurveyEndDate(reportDate: any, surveyEndDate: any) {
    const date1 = new Date(reportDate);
    const parts = surveyEndDate.split(" ");
    const date2 = new Date(`${parts[1]} ${parts[0]}, ${parts[2]}`);
    return date1 > date2;
  }
  changeRTFMailStatus(surveyId: any) {
    swal
      .fire({
        title: "RTF",
        text: "Show/Hide RTF scores Mail",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.companyService
            .changeRTFMailStatus({ companySurveyGroupId: surveyId })
            .subscribe({
              next: (res: any) => {
                this.rtfMailToggle[surveyId] = res?.data?.latestStatus;
                swal.fire({
                  title: "RTF",
                  text: res.message,
                  icon: "success",
                  showConfirmButton: true,
                  timer: 250000,
                });
              },
              error: (err) => {
                swal.fire({
                  title: err.error.message,
                  text: "Something went wrong while changing RTF Mail status",
                  icon: "error",
                  showConfirmButton: true,
                });
              },
            });
        } else {
          this.rtfMailToggle[surveyId] = !this.rtfMailToggle[surveyId];
        }
      });
  }
  isRTFMailEnabled(survey: any): boolean {
    const startDate = new Date(survey.startDate);
    const rtfDaysAfterMail = survey.rtf_days_after_mail;
    const currentDate = new Date();

    const targetDate = new Date(
      startDate.getTime() + rtfDaysAfterMail * 24 * 60 * 60 * 1000
    );

    return currentDate >= targetDate;
  }
}

export interface BenchmarkModel {
  id: string;
  benchmarkName: string;
  createdAt: string;
}
