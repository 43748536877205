import {
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { convertDate } from "../../../config/utils";
import {
  DEFAULT_RATING_RESPONSE,
  LAUNCH_TYPE,
  RESEARCH_QUESTION_TYPE,
} from "../../constants";
import { AdminServices } from "../admin.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import * as XLSX from "xlsx";
import moment from "moment";

@Component({
  selector: "app-research-question",
  templateUrl: "./research-question.component.html",
  styleUrls: ["./research-question.component.css"],
})
export class ResearchQuestionComponent implements OnInit {
  questionForm: FormGroup;
  isRating: boolean = false;
  isSelect: boolean = false;
  isMultiSelect: boolean = false;
  isRatingSelected: number = 1;
  researchQuestionType = RESEARCH_QUESTION_TYPE;
  launchType = LAUNCH_TYPE;
  modalRef?: BsModalRef;
  singleQuestion: any = [];
  config = {
    backdrop: true,
  };
  activeQuestion: any[] = [];
  isEditQuestion: boolean = false;
  lengthofEditArray: number = 1;
  isActiveChecked: boolean = false;
  reportEndDate = "";
  displayedColumns: string[] = [
    "Question Text",
    "Launch Type",
    "Question Type",
    "isActive",
    "Start Date",
    "End Date",
    "Edit",
    "Delete",
    "Download",
  ];
  allQuestions: any[] = [];
  isEditRating: boolean = false;
  isEditSelect: boolean = false;
  @ViewChild("dateTemplate") dateTemplate;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource = new MatTableDataSource();
  pageSizeOption = [5, 10, 15];
  minDate = new Date();
  defaultRatingResponse = DEFAULT_RATING_RESPONSE;
  isDefaultRatingResponse = false;
  endDateForm: FormGroup;
  selectedQstId = "";
  isQstActive: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private adminService: AdminServices,
    private modalService: BsModalService,
    private _snackBar: MatSnackBar,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.questionForm = this.formBuilder.group({
      questionText: [
        "",
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(150),
        ],
      ],
      questionType: ["", Validators.required],
      launchType: ["", Validators.required],
      selectAnswer: this.formBuilder.array([]),
      startDate: ["", Validators.required],
      endDate: ["", Validators.required],
    });
    this.getResearchQuestionAll();
    this.endDateForm = this.formBuilder.group({
      startDate: ["", Validators.required],
      endDate: ["", Validators.required],
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  submitForm() {
    let data = { ...this.questionForm.value };
    data.startDate = convertDate(data.startDate, "ISO");
    data.endDate = convertDate(data.endDate, "ISO");
    switch (data.questionType) {
      case "rating":
        data.answers = [...data.selectAnswer];
        break;
      case "select":
        data.answers = [...data.selectAnswer];
        break;
      default:
        data.answers = [];
        break;
    }
    data.answers.length > 0 && !this.isEditQuestion
      ? this.addResearchQuestion(data)
      : this.editResearchQuestion();
  }

  downloadReport(qstId) {
    let data = {
      _id: qstId,
    };
    this.adminService.downloadResearchQstReport(data).subscribe((res: any) => {
      if (res.status) {
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        const ws1 = XLSX.utils.json_to_sheet(res.data);
        XLSX.utils.book_append_sheet(wb, ws1, "Report");
        XLSX.writeFile(wb, `Research Data.xlsx`);
      } else {
        this.openSnackBar(res.msg, "error");
      }
    });
  }

  openSnackBar(message, status) {
    this._snackBar.open(message, "", {
      duration: 2000,
      panelClass: status == "success" ? ["green-snackbar"] : ["red-snackbar"],
      horizontalPosition: "right",
      verticalPosition: "top",
    });
  }

  addResearchQuestion(data) {
    this.adminService.addReasearchQuestion(data).subscribe((res: any) => {
      if (res.status) {
        this.modalRef.hide();
        this.questionForm.reset();
        this.openSnackBar(res.message, "success");
        this.getResearchQuestionAll();
      } else if (!res.status && res.data.length > 0) {
        this.activeQuestion = [...res.data];
        this.activeQuestion[0].isActive
          ? (this.isActiveChecked = true)
          : (this.isActiveChecked = false);
      } else {
        this.openSnackBar(res.message, "error");
      }
    });
  }

  checkisActive(endDate) {
    if (!endDate || typeof endDate !== "string") return false;
    let todaysDate = new Date(Date.now());
    let newEndDate = new Date(endDate);
    return todaysDate > newEndDate ? true : false;
  }

  getResearchQuestion(data) {
    this.adminService.getResearchQuestion(data).subscribe((res: any) => {
      if (res.status && res.data.length > 0 && !this.isEditQuestion) {
        this.allQuestions = [...res.data];
        // this.allQuestions.map((question) => {
        //   !this.checkisActive(question.endDate)
        //     ? question.isActive == true
        //     : (question.isActive = false);
        // });
        this.dataSource.data = res.data;
        this.dataSource.paginator = this.paginator;
        this.changeDetectorRef.detectChanges();
      } else if (res.status && res.data.length > 0 && this.isEditQuestion) {
        this.singleQuestion = [...res.data];

        if (this.singleQuestion && this.singleQuestion.length > 0) {
          this.singleQuestion[0].questionType == "rating"
            ? ((this.isEditRating = true), (this.isEditSelect = false))
            : ((this.isEditSelect = true), (this.isEditRating = false));

          this.questionForm.patchValue({
            questionText: this.singleQuestion[0].questionText,
            questionType: this.singleQuestion[0].questionType,
            launchType: this.singleQuestion[0].launchType,
            startDate: new Date(
              moment(this.singleQuestion[0].startDate).format("YYYY-MM-DD")
            ),
            endDate: new Date(
              moment(this.singleQuestion[0].endDate).format("YYYY-MM-DD")
            ),
          });

          // if (this.isEditRating) {
          //   this.clearFormArray(this.ratingAnswerArray());
          //   this.clearFormArray(this.selectAnswerArray());
          //   this.singleQuestion[0].answers.map((answer, index) => {
          //     const group = this.ratingAnswers();
          //     Object.keys(group.controls).forEach((key) => {
          //       group.patchValue({
          //         [key]: answer[key],
          //       });
          //     });
          //     this.ratingAnswerArray().push(group);
          //   });
          // }

          if (this.isEditSelect || this.isEditRating) {
            this.clearFormArray(this.selectAnswerArray());
            // this.clearFormArray(this.ratingAnswerArray());
            this.singleQuestion[0].answers.map((answer, index) => {
              const group = this.selectAnswers();
              Object.keys(group.controls).forEach((key) => {
                group.patchValue({
                  [key]: answer[key],
                });
              });
              this.selectAnswerArray().push(group);
            });
          }
          this.changeDetectorRef.detectChanges();
        }
      } else {
        this.openSnackBar(res.message, "error");
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  getResearchQuestionAll() {
    let data = {
      key: "",
      value: "",
    };
    this.getResearchQuestion(data);
  }

  editResearchQuestion() {
    let data = { ...this.questionForm.value };
    data.startDate = convertDate(data.startDate, "ISO");
    data.endDate = convertDate(data.endDate, "ISO");
    switch (data.questionType) {
      case "rating":
        data.answers = [...data.selectAnswer];
        break;
      case "select":
        data.answers = [...data.selectAnswer];
        break;
      default:
        data.answers = [];
        break;
    }
    data["questionId"] =
      this.singleQuestion.length > 0 ? this.singleQuestion[0]._id : "";
    this.modalRef.hide();
    this.updateResearchQuestion(data);
  }

  changeDefaultRating(checked) {
    if (!checked) {
      this.defaultRatingResponse.map((answer, index) => {
        const group = this.selectAnswers();
        Object.keys(group.controls).forEach((key) => {
          group.patchValue({
            [key]: answer[key],
          });
        });
        this.selectAnswerArray().push(group);
      });
    } else {
      this.clearFormArray(this.selectAnswerArray());
    }
  }

  addQuestion(template) {
    this.questionForm.reset();
    this.isSelect = false;
    this.isRating = false;
    this.isEditSelect = false;
    this.isEditRating = false;
    this.isEditQuestion = false;
    this.activeQuestion = [];
    this.openModal(template);
  }

  getResearchQuestionById(id, template) {
    let data = {
      key: "_id",
      value: id,
    };
    this.isEditQuestion = true;
    this.getResearchQuestion(data);
    this.openModal(template);
  }

  changeActiveQuestion(_id: any, event, template) {
    this.selectedQstId = _id;
    this.endDateForm.reset();
    event.checked ? (this.isQstActive = true) : (this.isQstActive = false);
    let data = {
      questionId: _id,
      isActive: event.checked,
    };
    event.checked && this.isQstActive
      ? this.openModal(template)
      : this.updateResearchQuestion(data);
  }

  updateQuestionEndDate() {
    let data = { ...this.endDateForm.value };
    data.questionId = this.selectedQstId;
    data.isActive = this.isQstActive;
    this.updateResearchQuestion(data);
    // this.modalRef.hide();
  }

  deleteResearchQuestion(id: string) {
    let data = {
      key: "_id",
      value: id,
    };
    this.adminService.deleteResearchQuestion(data).subscribe((res: any) => {
      let message = "";
      res.status ? (message = "success") : (message = "error");
      this.openSnackBar(res.message, message);
      this.getResearchQuestionAll();
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
    this.adminService.addModal(this.modalRef);
  }

  openReportModal(template, questionId) {
    this.modalRef = this.modalService.show(template);
    this.adminService.addModal(this.modalRef);
    this.selectedQstId = questionId;
  }

  onActiveChange(event) {
    let data = {};
    if (this.activeQuestion.length > 0) {
      data["questionId"] = this.activeQuestion[0]._id;
      data["isActive"] = event.checked;
      data["showModal"] = true;
      this.isActiveChecked = event.checked;
      this.updateResearchQuestion(data);
    }
  }

  updateResearchQuestion(data) {
    this.adminService.updateResearchQuestion(data).subscribe((res: any) => {
      let message = res.status ? "success" : "error";
      if (res.status) {
        this.activeQuestion = [];
        data.showModal ? null : this.modalRef.hide();
      } else {
        this.activeQuestion = [...res.data];
        this.activeQuestion.length > 0 && this.activeQuestion[0].isActive
          ? (this.isActiveChecked = true)
          : (this.isActiveChecked = false);
      }
      this.openSnackBar(res.message, message);
      this.getResearchQuestionAll();
    });
  }

  selectAnswers(): FormGroup {
    return this.formBuilder.group({
      choice: [
        "",
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50),
        ],
      ],
    });
  }

  // ratingAnswers(): FormGroup {
  //   return this.formBuilder.group({
  //     ratingStart: ["", [Validators.required]],
  //     ratingEnd: ["", [Validators.required]],
  //   });
  // }

  // ratingAnswerArray(): FormArray {
  //   return this.questionForm.get("ratingAnswer") as FormArray;
  // }

  addToSelect() {
    this.selectAnswerArray().push(this.selectAnswers());
  }

  // addToRating() {
  //   this.ratingAnswerArray().length < 1
  //     ? this.ratingAnswerArray().push(this.ratingAnswers())
  //     : null;
  // }

  deleteSelection(i: number) {
    this.selectAnswerArray().removeAt(i);
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  };

  selectAnswerArray(): FormArray {
    return this.questionForm.get("selectAnswer") as FormArray;
  }

  checkquestionType(event) {
    switch (event.value) {
      case "rating":
        this.isRating = true;
        this.isSelect = false;
        this.isEditSelect = false;
        // this.addToRating();
        this.clearFormArray(this.selectAnswerArray());
        break;
      case "select":
        this.isSelect = true;
        this.isRating = false;
        this.isEditRating = false;
        // this.clearFormArray(this.ratingAnswerArray());
        break;
      case "multi-select":
        this.isMultiSelect = true;
        break;
    }
  }
}
