import { CompanyServices } from "./company.service";
import { AgGridModule } from "ag-grid-angular";
import { DataTablesModule } from "angular-datatables";
import { CdkTableModule } from "@angular/cdk/table";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "../material.module";
import { CompanyComponent } from "./company.component";
import { CompanyInfoComponent } from "./company-info/company-info.component";
import { LoginComponent } from "./login/login.component";
import { ForgotPasswordComponent } from "./login/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./login/reset-password/reset-password.component";
// import { CompanyDashboardComponent } from './company-dashboard/company-dashboard.component';
import { RoleGuardService as RoleGuard } from "../role-guard.service";
import { ModalModule } from "ngx-bootstrap/modal";
import { PaginationModule } from "ngx-bootstrap/pagination";
// import { AuthGuardService } from '../auth-guard.service';
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TabsModule } from "ngx-bootstrap/tabs";
import { XlDataMappingComponent } from "./xl-data-mapping/xl-data-mapping.component";
import { XlDataUpdateNsaveComponent } from "./xl-data-update-nsave/xl-data-update-nsave.component";
// import { DataService } from "./data.service";
import { DataService } from "../../app/data.service";
import { ApproveDataComponent } from "./approve-data/approve-data.component";
import { LoaderComponent } from "./loader/loader.component";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { CompanyAddedManagerListComponent } from "./manager-list/company-added-manager-list/company-added-manager-list.component";
import { IndividualManagerListComponent } from "./manager-list/individual-manager-list/individual-manager-list.component";
import { GroupPackagesAssignComponent } from "./packages-assign/group-packages-assign/group-packages-assign.component";
import { CompanyHeaderComponent } from "./company-header/company-header.component";
import { CompanySendEmailComponent } from "./company-send-email/company-send-email.component";
import { FilterPipe } from "./filter.pipe";
import { CompanyActionBankComponent } from "./company-action-bank/company-action-bank.component";
import { NgxEditorModule } from "ngx-editor";
import { AddBatchComponent } from "./add-batch/add-batch.component";
import { ViewBatchesComponent } from "./view-batches/view-batches.component";
import { BatchesDashboardComponent } from "./batches-dashboard/batches-dashboard.component";
import { UpdateParticipantComponent } from "./update-participant/update-participant.component";
import { LaunchSurveyComponent } from "./launch-survey/launch-survey.component";
// import { ReportsComponent } from './reports/reports.component';
// import { LDReportsComponent } from './reports/l-d-reports/l-d-reports.component';
// import { GmcLearningService } from './../gmcLearning.service';
import { NgApexchartsModule } from "ng-apexcharts";
import { ActionPlanReportsComponent } from "./reports/action-plan-reports/action-plan-reports.component";
import { SurveyReportsComponent } from "./reports/survey-reports/survey-reports.component";
import { AngularMultiSelectModule } from "angular2-multiselect-dropdown";
import { BounceLoaderComponent } from "./loader/bounce-loader/bounce-loader.component";
import { SharedModule } from "../shared.module";
import { LearningManagerListComponent } from "./manager-list/learning-manager-list/learning-manager-list.component";
import { SummaryReportsComponent } from "./reports/summary-reports/summary-reports.component";
import { AccordionModule } from "ngx-bootstrap/accordion";
// import { SurveyEmailMgmtComponent } from './survey-email-mgmt/survey-email-mgmt.component';
// import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TimepickerModule } from "ngx-bootstrap/timepicker";
// import { DatetimePopupModule } from 'ngx-bootstrap-datetime-popup';
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
// import { ManagerListGpmsComponent } from './manager-list/manager-list-gpms/manager-list-gpms.component';

// import { SupervisorComponent } from './supervisor/supervisor.component';
import { CKEditorModule, CKEditorComponent } from "@ckeditor/ckeditor5-angular";

// import { SurveyListComponent } from './survey-list/survey-list.component';

import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
// import { ActionPlanFeedbackComponent } from './action-plan-feedback/action-plan-feedback.component';
// import { DragDropDirective } from './directives/drag-drop.directive';
// import { ActionSubthemeComponent } from './action-subtheme/action-subtheme.component';
import { SelectDropDownModule } from "ngx-select-dropdown";
// import { AssignBatchesComponent } from './assign-batches/assign-batches.component';
// import { ActionPlanFormsComponent } from './action-plan-forms/action-plan-forms.component';
// import { RequestPortalComponent } from './request-portal/request-portal.component';
// import { WindowRef } from './manager-list/company-added-manager-list/WindowRef';
// import { AssignNudgesComponent } from './assign-nudges/assign-nudges.component';
import { MatSortModule } from "@angular/material/sort";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTableModule } from "@angular/material/table";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { SurveyListComponent } from "./survey-list/survey-list.component";
import { SurveyEmailMgmtComponent } from "./survey-email-mgmt/survey-email-mgmt.component";
import { WhatsappManangementComponent } from "./whatsapp-manangement/whatsapp-manangement.component";
import { SupervisorComponent } from "./supervisor/supervisor.component";
import { ActionPlanFeedbackComponent } from "./action-plan-feedback/action-plan-feedback.component";
import { ActionSubthemeComponent } from "./action-subtheme/action-subtheme.component";
import { RequestPortalComponent } from "./request-portal/request-portal.component";
import { PreUploadActionPlanComponent } from "./pre-upload-action-plan/pre-upload-action-plan.component";
import { LeaderboardReportComponent } from "./leaderboard-report/leaderboard-report.component";
import { ReportsComponent } from "./reports/reports.component";
import { LDReportsComponent } from "./reports/l-d-reports/l-d-reports.component";
import { ActionPlanFormsComponent } from "./action-plan-forms/action-plan-forms.component";
import { ManagerListGpmsComponent } from "./manager-list/manager-list-gpms/manager-list-gpms.component";
import { DragDropDirective } from "./directives/drag-drop.directive";
import { AssignBatchesComponent } from "./assign-batches/assign-batches.component";
import { JoinObjectValuesPipe } from "./join-object-values.pipe";
import { CamelCaseToTitleCasePipe } from "./camel-case-to-title-case.pipe";
import { FirstPartPipe } from "./first-part.pipe";
import { CompanyDashboardComponent } from "./company-dashboard/company-dashboard.component";
import { WindowRef } from "./manager-list/company-added-manager-list/WindowRef";
import { ExcelService } from "../excel-service.service";
import { GmcLearningService } from "../gmcLearning.service";
import { PagesSettingComponent } from "./pages-setting/pages-setting.component";
import { MatListModule } from "@angular/material/list";
import { DatePipe } from "@angular/common";
import { LeadershipDashboardComponent } from "./leadership-dashboard/leadership-dashboard/leadership-dashboard.component";
import { NudgesConfigurationComponent } from "./nudges-configuration/nudges-configuration.component";
import { GmcComponent } from "./gmc/gmc.component";
import { AssignNudgesComponent } from "./assign-nudges/assign-nudges.component";
import { ColorPickerModule } from "ngx-color-picker";
import { SmsManagementComponent } from "./sms-management/sms-management.component";
import { TextDemographicNameFormatPipe } from "../pipes/text-demographic-name-format.pipe";
import { ResetSasComponent } from './reset-sas/reset-sas.component';
import { EmailLogsComponent } from "./email-logs/email-logs.component";
import { ResetApLdComponent } from './reset-ap-ld/reset-ap-ld.component';
// import { JoinObjectValuesPipe } from './join-object-values.pipe';
// import { CamelCaseToTitleCasePipe } from './camel-case-to-title-case.pipe';
// import { PreUploadActionPlanComponent } from './pre-upload-action-plan/pre-upload-action-plan.component';
// import { AdminServices } from '../admin/admin.service';
// import { WhatsappManangementComponent } from './whatsapp-manangement/whatsapp-manangement.component';
// import { FirstPartPipe } from './first-part.pipe';
// import { LeaderboardReportComponent } from './leaderboard-report/leaderboard-report.component';
// import { LeadershipDashboardComponent } from './leadership-dashboard/leadership-dashboard.component';
const routes: Routes = [
  {
    path: "",
    component: LoginComponent,
  },
  { path: ":companyName/:managerid/:token", component: ApproveDataComponent },
  { path: "forgot-password", component: ForgotPasswordComponent },
  { path: "reset-password/:id", component: ResetPasswordComponent },
  // {
  //   path: 'verify-otp/:id',
  //   component: EnterotpComponent
  // },
  // {
  //   path: 'survey',
  //   component: PostregisterquestionsComponent
  // },
  {
    path: "company-dashboard",
    component: CompanyDashboardComponent,
    canActivate: [RoleGuard],
    children: [
      {
        path: "",
        component: CompanyInfoComponent,
      },
      {
        path: "add-action-plan/:id/add/:type",
        component: CompanyActionBankComponent,
      },
      {
        path: "survey-list",
        component: SurveyListComponent,
      },
      {
        path: "xl-dataMapping",
        component: XlDataMappingComponent,
      },
      {
        path: "xl-dataUpdateNSave",
        component: XlDataUpdateNsaveComponent,
      },
      {
        path: "view-batches",
        component: BatchesDashboardComponent,
      },
      {
        path: "launch-survey",
        component: LaunchSurveyComponent,
      },
      {
        path: "survey/edit/:id",
        component: LaunchSurveyComponent,
      },
      {
        path: "reports",
        component: ReportsComponent,
      },
      {
        path: "email-logs",
        component: EmailLogsComponent,
      },
      {
        path: "survey-email-mgmt",
        component: SurveyEmailMgmtComponent,
      },
      {
        path: "survey-email-mgmt/survey/:id",
        component: SurveyEmailMgmtComponent,
      },
      {
        path: "survey-whatsapp-mgmt/survey/:id",
        component: WhatsappManangementComponent,
      },
      {
        path: "survey-sms-mgmt/survey/:id",
        component: SmsManagementComponent,
      },
      {
        path: "supervisor",
        component: SupervisorComponent,
      },
      {
        path: "action-plan-feedback",
        component: ActionPlanFeedbackComponent,
      },
      {
        path: "subareas",
        component: ActionSubthemeComponent,
      },
      {
        path: "request-portal",
        component: RequestPortalComponent,
      },
      {
        path: "upload-action-plan",
        component: PreUploadActionPlanComponent,
      },
      {
        path: "survey/leaderboard-report/:id",
        component: LeaderboardReportComponent,
      },
      {
        path: "leaderboard-report",
        component: LeaderboardReportComponent,
      },
      {
        path: "leadership-dashboard",
        component: LeadershipDashboardComponent,
      },
      {
        path: "nudges-configuration",
        component: NudgesConfigurationComponent,
      },
      {
        path: "gmc-criteria",
        component: GmcComponent,
      },
      { path: "assign-nudges", component: AssignNudgesComponent },
    ],
  },
];

@NgModule({
  declarations: [
    CompanyComponent,
    CompanyInfoComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    XlDataMappingComponent,
    XlDataUpdateNsaveComponent,
    ApproveDataComponent,
    LoaderComponent,
    CompanyAddedManagerListComponent,
    IndividualManagerListComponent,
    GroupPackagesAssignComponent,
    CompanyHeaderComponent,
    CompanySendEmailComponent,
    FilterPipe,
    CompanyActionBankComponent,
    AddBatchComponent,
    ViewBatchesComponent,
    BatchesDashboardComponent,
    UpdateParticipantComponent,
    LaunchSurveyComponent,
    ReportsComponent,
    LDReportsComponent,
    ActionPlanReportsComponent,
    SurveyReportsComponent,
    BounceLoaderComponent,
    LearningManagerListComponent,
    SummaryReportsComponent,
    SurveyEmailMgmtComponent,
    ManagerListGpmsComponent,
    SupervisorComponent,
    SurveyListComponent,
    ActionPlanFeedbackComponent,
    DragDropDirective,
    ActionSubthemeComponent,
    AssignBatchesComponent,
    ActionPlanFormsComponent,
    RequestPortalComponent,
    JoinObjectValuesPipe,
    CamelCaseToTitleCasePipe,
    PreUploadActionPlanComponent,
    WhatsappManangementComponent,
    FirstPartPipe,
    LeaderboardReportComponent,
    CompanyDashboardComponent,
    PagesSettingComponent,
    LeadershipDashboardComponent,
    NudgesConfigurationComponent,
    GmcComponent,
    AssignNudgesComponent,
    SmsManagementComponent,
    TextDemographicNameFormatPipe,
    ResetSasComponent,
    EmailLogsComponent,
    ResetApLdComponent,
    /* 
    LeadershipDashboardComponent,
    AssignNudgesComponent,
    CompanyDashboardComponent,
    */
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    MaterialModule,
    DataTablesModule,
    CdkTableModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    PaginationModule.forRoot(),
    NgxEditorModule,
    NgApexchartsModule,
    AngularMultiSelectModule,
    CKEditorModule,
    AccordionModule.forRoot(),
    MatTableModule,
    MatTooltipModule,
    MatSortModule,
    MatSlideToggleModule,
    SelectDropDownModule,
    AccordionModule,
    BsDatepickerModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    TimepickerModule.forRoot(),
    TabsModule.forRoot(),
    AngularEditorModule,
    /*
    DatepickerModule,
    DatetimePopupModule.forRoot(),
    DatepickerModule.forRoot(),
    */

    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
    // RouterModule.forChild(routes),
    MatSortModule,
    MatTableModule,
    CdkTableModule,
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    PaginationModule.forRoot(),
    NgxEditorModule,
    NgApexchartsModule,
    // AngularMultiSelectModule,
    CKEditorModule,
    AccordionModule.forRoot(),
    // OwlDateTimeModule
    // OwlNativeDateTimeModule,
    TabsModule,
    AccordionModule,
    MatListModule,
    ColorPickerModule,
    AgGridModule,
  ],
  providers: [
    DataService,
    ExcelService,
    GmcLearningService,
    CompanyServices,
    WindowRef,
    DatePipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [ColorPickerModule],
})
export class CompanyModule {}
