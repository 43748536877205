<div class="container-fluid" style="padding-top: 3%">
  <app-loader *ngIf="loading"></app-loader>
  <div
    class="col-md-12 form-card"
    style="
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    "
  >
    <div class="row">
      <div
        class="col-md-12 col-xs-6 col-md-6 subadmin-title"
        style="padding: 0px !important"
      >
        <div class="col-md-7" style="padding-top: 2%; padding-bottom: 2%">
          <input
            type="text"
            (keyup)="applyFilter($event.target['value'])"
            class="form-control search-icon"
            placeholder="Enter your search..."
            (keyup)="applyFilter($event.target['value'])"
          />
          <div class="clearfix"></div>
        </div>
        <div class="col-md-5">
          <button
            type="button"
            style="margin-right: -21%; float: right; margin-top: 6%"
            class="btn btn-secondary btn-newuser-clr"
            [routerLink]="['/admin/admin-dashboard/corporate-companies/add']"
          >
            Add New
          </button>
        </div>
      </div>
      <div class="users-table">
        <div class="subadmin-table">
          <div class="example-container container mar-tp tab-size">
            <mat-table [dataSource]="dataSource" matSort>
              <!-- Company name Column -->
              <ng-container matColumnDef="companyName">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >Company name</mat-header-cell
                >
                <mat-cell *matCellDef="let element">
                  {{ element.companyName }}</mat-cell
                >
              </ng-container>

              <!-- Website Column -->
              <ng-container matColumnDef="websiteUrl">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >Website</mat-header-cell
                >
                <mat-cell *matCellDef="let element">{{
                  element.websiteUrl
                }}</mat-cell>
              </ng-container>
              <!-- Color Column -->
              <ng-container matColumnDef="createdAt">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >Subscription Date</mat-header-cell
                >
                <mat-cell *matCellDef="let element">
                  {{ element.creationDate }}</mat-cell
                >
              </ng-container>
              <!-- Action Column -->

              <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <i
                    class="fa fa-edit"
                    style="margin-right: 15px"
                    (click)="
                      openEditModal(editTemplate, element.surveyEngineCompanyId)
                    "
                  ></i>
                  <i
                    class="fa fa-plus"
                    style="margin-right: 15px"
                    (click)="
                      openCustomLD(addCustomLD, element.surveyEngineCompanyId)
                    "
                  ></i>
                  <i
                    class="fa fa-user-plus"
                    (click)="
                      openAddModal(
                        addTemplate,
                        element.companyName,
                        element.surveyEngineCompanyId
                      )
                    "
                    style="margin-right: 15px"
                  ></i>
                  <!-- class="fa fa-hand-holding-usd" -->
                  <i
                    class="fa fa-money"
                    style="font-size: 16px; margin-right: 15px"
                    (click)="
                      openUpdatePackageModal(
                        updatePackage,
                        element.surveyEngineCompanyId
                      )
                    "
                  ></i>
                  <i
                    (click)="openDatePickerModal(datePickerModal, element)"
                    class="fa fa-calendar"
                    aria-hidden="true"
                    style="font-size: 16px; margin-right: 15px"
                  ></i>
                  <i
                    matTooltip="Download Raw Data"
                    matTooltipPosition="above"
                    (click)="getCompanySurveys(element, rawData)"
                    class="fa fa-bar-chart"
                    style="font-size: 16px; margin-right: 15px"
                  ></i>
                  <button class="btn primary-btn" (click)="goToRound2(element)">
                    Round 2
                  </button>

                  <ng-template #addTemplate>
                    <div class="modal-content">
                      <div class="modal-header">
                        <h4
                          style="font-size: 22px"
                          class="modal-title pull-left"
                        >
                          Add User
                        </h4>
                        <button
                          type="button "
                          class="close pull-right"
                          aria-label="Close "
                          (click)="modalRef.hide()"
                        >
                          <span aria-hidden="true ">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <div class="row">
                          <div class="col-md-12" style="padding: 0">
                            <div class="form-group col-md-6">
                              <h3>Add new user</h3>
                              <form (ngSubmit)="onSubmit(f)" #f="ngForm">
                                <label for="name"
                                  >User Name<span class="asterick"
                                    >*</span
                                  ></label
                                >
                                <input
                                  type="text"
                                  name="name"
                                  [(ngModel)]="addName"
                                  class="form-control custom-form-control"
                                  placeholder="Enter user name"
                                  required
                                  #addUser="ngModel"
                                  style="margin-bottom: 3%"
                                />
                                <p
                                  class="warning"
                                  *ngIf="!addUser.valid && addUser.touched"
                                >
                                  Please Enter user name
                                </p>
                                <label for="email"
                                  >Email<span class="asterick">*</span></label
                                >
                                <input
                                  type="text"
                                  name="email"
                                  (change)="validate()"
                                  [(ngModel)]="addEmail"
                                  class="form-control custom-form-control"
                                  placeholder="Enter Email id"
                                  required
                                  #addAdminEmail="ngModel"
                                  style="margin-bottom: 3%"
                                />
                                <p
                                  class="warning"
                                  *ngIf="
                                    !addAdminEmail.valid &&
                                    addAdminEmail.touched
                                  "
                                >
                                  Please Enter email
                                </p>
                                <p class="warning">{{ emailStatus }}</p>
                                <label for="mobile"
                                  >Mobile Number<span class="asterick"
                                    >*</span
                                  ></label
                                >
                                <input
                                  type="number"
                                  name="mobile"
                                  [(ngModel)]="addMobile"
                                  class="form-control custom-form-control"
                                  placeholder="Enter mobile number"
                                  maxlength="10"
                                  size="10"
                                  required
                                  #addCompanyAdminEmail="ngModel"
                                  style="margin-bottom: 3%"
                                />
                                <p
                                  class="warning"
                                  *ngIf="
                                    !addCompanyAdminEmail.valid &&
                                    addCompanyAdminEmail.touched
                                  "
                                >
                                  Please Enter mobile number
                                </p>
                                <label for="gender"
                                  >Gender<span class="asterick">*</span></label
                                >
                                <select
                                  class="form-control custom-form-control"
                                  [(ngModel)]="addGender"
                                  name="gender"
                                  (change)="
                                    onSelectgender($event.target['value'])
                                  "
                                  ngModel
                                  required
                                  #ngGender="ngModel"
                                  style="margin-bottom: 3%"
                                >
                                  <option value="" selected>select</option>
                                  <option
                                    *ngFor="let gender of gender"
                                    [value]="gender.value"
                                  >
                                    {{ gender.value }}
                                  </option>
                                </select>
                                <p
                                  class="warning"
                                  *ngIf="!ngGender.valid && ngGender.touched"
                                >
                                  Please select gender
                                </p>
                                <label for="company"
                                  >Company Name<span class="asterick"
                                    >*</span
                                  ></label
                                >
                                <input
                                  type="text"
                                  name="companyName"
                                  class="form-control custom-form-control"
                                  [(ngModel)]="addCompanyName"
                                  placeholder="Enter Company name"
                                  required
                                  #adminCompanyName="ngModel"
                                  disabled
                                />
                                <p
                                  class="warning"
                                  *ngIf="
                                    !adminCompanyName.valid &&
                                    adminCompanyName.touched
                                  "
                                >
                                  Please Enter company name
                                </p>

                                <label for=""
                                  >Select Access
                                  <span class="asterick">*</span></label
                                >
                                <ngx-select-dropdown
                                  style="margin: 20px"
                                  (change)="selectUserAccess($event)"
                                  [multiple]="true"
                                  [config]="configAccess"
                                  [options]="accessArray"
                                >
                                </ngx-select-dropdown>

                                <label for="" class="batchHeader"
                                  >Select Batches
                                </label>
                                <ngx-select-dropdown
                                  style="margin: 20px"
                                  (change)="selectBatches($event)"
                                  [multiple]="true"
                                  [config]="configBatch"
                                  [options]="batchArray"
                                >
                                </ngx-select-dropdown>

                                <!-- <label for="batchName">Batch Name </label>
                                <input
                                  type="text"
                                  name="batchName"
                                  class="form-control custom-form-control"
                                  [(ngModel)]="batchName"
                                  placeholder="Enter Batch Name"
                                /> -->
                                <!-- <angular2-multiselect
                                  [data]="batchArray"
                                  [(ngModel)]="selectedBatches"
                                  [settings]="batchDropdownSettings"
                                  (onSelect)="onItemSelect($event)"
                                  (onDeSelect)="OnItemDeSelect($event)"
                                  (onSelectAll)="onSelectAll($event)"
                                  (onDeSelectAll)="onDeSelectAll($event)"
                                ></angular2-multiselect> -->
                                <!-- <label for="companyName">Company Id<span class="asterick">*</span></label>
                                                            <input type="text" name="companyId" [value]="addCompanyId"
                                                                [(ngModel)]="addCompanyId" class="form-control custom-form-control"
                                                                required placeholder="Enter Company id" #adminCompanyId
                                                                disabled>
                                                            <p class="warning" *ngIf="!adminCompanyId.valid && adminCompanyId.touched">
                                                                Please Enter company id
                                                            </p> -->
                                <button
                                  style="margin-top: 5%"
                                  type="submit"
                                  class="btn btn-primary"
                                  (click)="addAdmin()"
                                  (click)="modalRef.hide()"
                                  [disabled]="
                                    !f.valid ||
                                    !isEmailValid ||
                                    userAccess.length == 0
                                  "
                                >
                                  Add User
                                </button>
                              </form>
                            </div>
                            <div class="col-md-6">
                              <h3 style="text-align: center">User list</h3>
                              <table
                                class="table table1"
                                style="margin-top: 9% !important"
                              >
                                <tr>
                                  <th>Email</th>
                                  <th>Status</th>
                                  <th>Edit</th>
                                </tr>
                                <tr *ngFor="let data of data">
                                  <td style="width: 80%; word-break: break-all">
                                    {{ data.email }}
                                  </td>
                                  <td>
                                    <input
                                      class="tgl tgl-ios"
                                      id="{{ data.email }}"
                                      (click)="
                                        changeStatus(data.email, data.status)
                                      "
                                      name="status"
                                      type="checkbox"
                                      [checked]="data.status"
                                    />
                                    <label
                                      class="tgl-btn"
                                      for="{{ data.email }}"
                                    ></label>
                                  </td>
                                  <td>
                                    <i
                                      class="fa fa-edit"
                                      (click)="
                                        openEditCompanyAdminModal(
                                          editCompanyAdminTemplate,
                                          data
                                        )
                                      "
                                    >
                                    </i>
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template #editTemplate>
                    <div class="modal-header">
                      <h4 style="font-size: 22px" class="modal-title pull-left">
                        Edit company details
                      </h4>
                      <button
                        type="button "
                        class="close pull-right"
                        aria-label="Close "
                        (click)="hideEditModal()"
                      >
                        <span aria-hidden="true ">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-md-12" style="padding: 0">
                          <form (ngSubmit)="onSubmitEdit(f1)" #f1="ngForm">
                            <div class="form-group col-md-6" style="padding: 0">
                              <div class="form-group col-md-12 pb-10">
                                <label for="email"
                                  >Company Name<span class="asterick"
                                    >*</span
                                  ></label
                                >
                                <input
                                  placeholder="Enter company name"
                                  name="company"
                                  class="form-control custom-form-control"
                                  [ngModel]="companyName"
                                  [disabled]="true"
                                  #company="ngModel"
                                />
                                <p
                                  class="warning"
                                  *ngIf="!company.valid && company.touched"
                                >
                                  Please Enter company name
                                </p>
                              </div>
                              <div
                                *ngIf="industryType"
                                class="form-group col-md-12 pb-10"
                              >
                                <label for="industry_type"
                                  >Industry Type<span class="asterick"
                                    >*</span
                                  ></label
                                >
                                <select
                                  class="form-control custom-form-control"
                                  ngModel
                                  required
                                  #ngIndustryType="ngModel"
                                  [(ngModel)]="industryType"
                                  name="industryType"
                                  (change)="
                                    onSelectIndustryType($event.target['value'])
                                  "
                                >
                                  <option
                                    *ngFor="
                                      let industryType of industryTypeArray
                                    "
                                    [value]="industryType.name"
                                    selected
                                  >
                                    {{ industryType.name }}
                                  </option>
                                </select>
                                <p
                                  class="warning"
                                  *ngIf="
                                    !ngIndustryType.valid &&
                                    ngIndustryType.touched
                                  "
                                >
                                  Please Select Your industry type
                                </p>
                              </div>
                              <div
                                *ngIf="industrySubType"
                                class="form-group col-md-12 pb-10"
                              >
                                <label for="title">Industry Sub Type</label>
                                <input
                                  type="text"
                                  name="industrySubType"
                                  class="form-control custom-form-control"
                                  placeholder="Enter Sub Industry type"
                                  [(ngModel)]="industrySubType"
                                  #ngIndustrySubType="ngModel"
                                />
                                <p
                                  class="warning"
                                  *ngIf="
                                    !ngIndustrySubType.valid &&
                                    ngIndustrySubType.touched
                                  "
                                >
                                  Please Enter Your industry sub type
                                </p>
                              </div>
                              <div
                                *ngIf="sector"
                                class="form-group col-md-12 pb-10"
                              >
                                <label for="email"
                                  >Sector<span class="asterick">*</span></label
                                >
                                <select
                                  class="form-control custom-form-control"
                                  ngModel
                                  required
                                  #selectedSectorModel="ngModel"
                                  [(ngModel)]="sector"
                                  name="sector"
                                  (change)="
                                    filterData(
                                      'sectors',
                                      $event.target['value']
                                    )
                                  "
                                >
                                  <option
                                    *ngFor="let industryType of uniqueSectors"
                                    [value]="industryType.sectors"
                                    selected
                                  >
                                    {{ industryType.sectors }}
                                  </option>
                                </select>
                                <p
                                  class="warning"
                                  *ngIf="
                                    !selectedSectorModel.valid &&
                                    selectedSectorModel.touched
                                  "
                                >
                                  Please Select Your sector type
                                </p>
                              </div>
                              <div
                                *ngIf="industryGroup"
                                class="form-group col-md-12 pb-10"
                              >
                                <label for="email"
                                  >Industry Group<span class="asterick"
                                    >*</span
                                  ></label
                                >
                                <select
                                  class="form-control custom-form-control"
                                  ngModel
                                  required
                                  #selectedIndustryGroupModel="ngModel"
                                  [(ngModel)]="industryGroup"
                                  name="industryGroup"
                                  (change)="
                                    filterData(
                                      'industryGroups',
                                      $event.target['value']
                                    )
                                  "
                                >
                                  <option
                                    *ngFor="let industryType of industryGroups"
                                    [value]="industryType.industry_groups"
                                    selected
                                  >
                                    {{ industryType.industry_groups }}
                                  </option>
                                </select>
                                <p
                                  class="warning"
                                  *ngIf="
                                    !selectedIndustryGroupModel.valid &&
                                    selectedIndustryGroupModel.touched
                                  "
                                >
                                  Please Select Your sector type
                                </p>
                              </div>
                              <div
                                *ngIf="industry"
                                class="form-group col-md-12 pb-10"
                              >
                                <label for="email"
                                  >Industry<span class="asterick"
                                    >*</span
                                  ></label
                                >
                                <select
                                  class="form-control custom-form-control"
                                  ngModel
                                  required
                                  #selectedIndustryModel="ngModel"
                                  [(ngModel)]="industry"
                                  name="industry"
                                  (change)="
                                    filterData(
                                      'industries',
                                      $event.target['value']
                                    )
                                  "
                                >
                                  <option
                                    *ngFor="let industryType of industries"
                                    [value]="industryType.industries"
                                    selected
                                  >
                                    {{ industryType.industries }}
                                  </option>
                                </select>
                                <p
                                  class="warning"
                                  *ngIf="
                                    !selectedIndustryModel.valid &&
                                    selectedIndustryModel.touched
                                  "
                                >
                                  Please Select Your Industry type
                                </p>
                              </div>
                              <div
                                *ngIf="subIndustry"
                                class="form-group col-md-12 pb-10"
                              >
                                <label for="email"
                                  >Sub Industry<span class="asterick"
                                    >*</span
                                  ></label
                                >
                                <select
                                  class="form-control custom-form-control"
                                  ngModel
                                  required
                                  #selectedSubIndustryModel="ngModel"
                                  [(ngModel)]="subIndustry"
                                  name="subIndustry"
                                >
                                  <option
                                    *ngFor="let industryType of subIndustries"
                                    [value]="industryType.sub_industries"
                                    selected
                                  >
                                    {{ industryType.sub_industries }}
                                  </option>
                                </select>
                                <p
                                  class="warning"
                                  *ngIf="
                                    !selectedSubIndustryModel.valid &&
                                    selectedSubIndustryModel.touched
                                  "
                                >
                                  Please Select Your Industry type
                                </p>
                              </div>
                              <div class="form-group col-md-12 pb-10">
                                <label for="address">Address</label>
                                <input
                                  type="text"
                                  class="form-control custom-form-control mt-10"
                                  placeholder="Address line 1"
                                  [(ngModel)]="line1"
                                  name="line1"
                                />
                                <input
                                  type="text"
                                  class="form-control custom-form-control country-size mt-10"
                                  placeholder="Country"
                                  [(ngModel)]="country"
                                  name="country"
                                />
                                <input
                                  type="text"
                                  class="form-control custom-form-control city-size mt-10"
                                  placeholder="City"
                                  [(ngModel)]="city"
                                  name="city"
                                />
                                <input
                                  type="number"
                                  class="form-control custom-form-control pincode-size mt-10"
                                  placeholder="Pincode"
                                  [(ngModel)]="pinCode"
                                  name="pincode"
                                />
                              </div>
                              <div
                                class="form-group col-md-12 pb-10 typeSelect"
                              >
                                <label for="setting"
                                  >Select Launch Setting:</label
                                >
                                <div
                                  class="responseRateEmail flex flex-col justify-center"
                                >
                                  <p>
                                    <input
                                      name="sendResponseRateEmail"
                                      checked="sendResponseRateEmail"
                                      type="checkbox"
                                      [(ngModel)]="sendResponseRateEmail"
                                    />
                                    Send Response Rate Email
                                  </p>
                                  <p>
                                    <input
                                      name="sendSasEmail"
                                      checked="sendSasEmail"
                                      type="checkbox"
                                      [(ngModel)]="sendSasEmail"
                                    />
                                    Send SAS Email
                                  </p>
                                  <p>
                                    <input
                                      name="showZoho"
                                      checked="showZoho"
                                      type="checkbox"
                                      [(ngModel)]="showZoho"
                                    />
                                    Show zoho chat bot
                                  </p>
                                </div>
                              </div>
                              <!-- <div class="criteria">
                                <h4>GMC Criteria Setting:</h4>
                                <div class="form-group col-md-12 pb-10">
                                  <label for="title">Minimum Action Plan</label>
                                  <input
                                    type="number"
                                    name="actionPlanMin"
                                    max="10"
                                    class="form-control custom-form-control"
                                    placeholder="Enter Minimum Action Plan"
                                    [(ngModel)]="gmcCriteria.actionPlanMin"
                                  />
                                </div>
                                <div class="form-group col-md-12 pb-10">
                                  <label for="title"
                                    >Minimum Implementation Updates</label
                                  >
                                  <input
                                    type="number"
                                    name="implementationMin"
                                    class="form-control custom-form-control"
                                    placeholder="Enter Minimum Implementation Updates"
                                    [(ngModel)]="gmcCriteria.implementationMin"
                                  />
                                </div>
                                <div class="form-group col-md-12 pb-10">
                                  <label for="title"
                                    >Minimum Learning and Development</label
                                  >
                                  <input
                                    type="number"
                                    name="learningDevelopmentMin"
                                    class="form-control custom-form-control"
                                    placeholder="Enter Minimum Learning and Development Plan"
                                    [(ngModel)]="
                                      gmcCriteria.learningDevelopmentMin
                                    "
                                  />
                                </div>
                              </div> -->
                            </div>

                            <div
                              class="col-md-6"
                              style="padding-top: 10%; padding: 0"
                            >
                              <div class="form-group col-md-12 pb-10 web">
                                <label for="title">Company Icon</label>
                                <br />
                                <a
                                  class="btn btn-primary add-icon"
                                  (click)="iconInput.click()"
                                  >Click Here to Add Icon</a
                                >
                                <input
                                  type="file"
                                  class="btn-primary"
                                  id="icon"
                                  (change)="showIcon($event)"
                                  accept="image/x-png,image/gif,image/jpeg"
                                  #iconInput
                                  name="icon"
                                />
                                <p class="warning" *ngIf="!isIconValid">
                                  {{ iconSizeStatus }}
                                </p>
                                <img
                                  class="mt-10"
                                  *ngIf="imageSrc || iconAddFromDB"
                                  [src]="imageSrc ? imageSrc : iconAddFromDB"
                                  alt="Your Icon"
                                  width="auto"
                                  height="50px"
                                />
                                <!-- <p class="warning" *ngIf="!website.valid && website.touched">
                                  Please Enter your website url
                                </p> -->
                                <!-- <p class="warning">{{ websiteUrlStatus }}</p> -->
                              </div>

                              <div class="form-group col-md-12 pb-10 web">
                                <label for="title"
                                  >Website<span class="asterick">*</span></label
                                >
                                <input
                                  type="text"
                                  class="form-control custom-form-control mt-10"
                                  name="website"
                                  class="form-control custom-form-control"
                                  placeholder="Enter Website URL"
                                  (change)="validate()"
                                  [(ngModel)]="websiteUrl"
                                  required
                                  #website="ngModel"
                                />
                                <p
                                  class="warning"
                                  *ngIf="!website.valid && website.touched"
                                >
                                  Please Enter your website url
                                </p>
                                <p class="warning">{{ websiteUrlStatus }}</p>
                              </div>
                              <div class="form-group col-md-12 pb-10 web">
                                <label for="title">Access Url</label>
                                <input
                                  type="text"
                                  class="form-control custom-form-control mt-10"
                                  name="accessurl"
                                  class="form-control custom-form-control"
                                  placeholder="Enter Access URL"
                                  [(ngModel)]="accessUrl"
                                  #accessurl="ngModel"
                                />
                                <!-- <p class="warning" *ngIf="!accessurl.valid && accessurl.touched">
                                  Please Enter your access url
                                </p> -->
                              </div>
                              <!-- <div *ngIf="keyPersonArray.length" class="personDetails">
                                <div >
                                  <div>
                                    <h6 *ngFor="let person of keyPersonArray">
                                      {{person.email}}
                                    </h6>
                                  </div>
                                  <div>
                                    <button>Edit</button>
                                  </div>
                                </div>
                              </div> -->
                              <div class="form-group col-md-12 pb-10">
                                <div *ngFor="let keyPerson of keyPersonArray">
                                  <div *ngIf="keyPersonArray.length > 0">
                                    <h5 class="rounded email">
                                      Contact Person: {{ keyPerson.email }}
                                      <i
                                        class="fa fa-edit"
                                        (click)="patchKeyPerson(keyPerson)"
                                      ></i>
                                      <span
                                        (click)="removePerson(keyPerson.email)"
                                        class="closeBtn ml-2"
                                      >
                                        X</span
                                      >
                                    </h5>
                                  </div>
                                </div>
                                <form
                                  (ngSubmit)="addAnotherKeyPerson()"
                                  #f="ngForm"
                                >
                                  <label for="email"
                                    >Contact Person Name<span class="asterick"
                                      >*</span
                                    ></label
                                  >
                                  <input
                                    type="text"
                                    name="kpName"
                                    class="form-control custom-form-control mt-10"
                                    placeholder="Person name"
                                    [(ngModel)]="kpName"
                                    required
                                  />
                                  <input
                                    type="email"
                                    class="form-control custom-form-control email-size mt-10"
                                    placeholder="Enter email"
                                    name="kpEmail"
                                    [(ngModel)]="kpEmail"
                                    required
                                  />
                                  <input
                                    type="number"
                                    class="form-control custom-form-control cno-size mt-10"
                                    placeholder="Contact No."
                                    name="kpPhone"
                                    [(ngModel)]="kpPhone"
                                    required
                                  />

                                  <div class="mt-10">
                                    <label for="kpUserType"
                                      >Select User Type</label
                                    >
                                    <select
                                      [ngModelOptions]="{ standalone: true }"
                                      [(ngModel)]="kpUserType"
                                      class="form-control"
                                      id="kpUserType"
                                      required
                                    >
                                      <option value="" selected disabled>
                                        Select User Type
                                      </option>
                                      <option value="companySpoc">
                                        Company Spoc
                                      </option>
                                      <option value="gmiSpoc">GMI Spoc</option>
                                      <option value="gmiHead">GMI Head</option>
                                    </select>
                                  </div>
                                </form>
                              </div>
                              <button
                                class="btn btn-info pull-right"
                                (click)="addAnotherKeyPerson()"
                                [disabled]="f.invalid"
                              >
                                Add
                              </button>
                              <p class="warning" *ngIf="errorText.length > 0">
                                {{ errorText }}
                              </p>
                              <!-- <div class="form-group col-md-12 pb-10">
                                <label>GPMS Company <span class="asterick">*</span></label>
                                <div class="form-group col-md-12 pb-10">

                                  <label for="gpms" class="gpmsLabel">Yes</label>
                                  <input type="radio" name="gpms" id="gpms" [checked]="isCompanyGPMS" value="Yes"
                                    (click)="radioValue($event)">
                                  <label for="gpms" class="gpmsLabel">No</label>
                                  <input type="radio" name="gpms" id="gpms" value="No" (click)="radioValue($event)">


                                </div>
                                <div class="form-group col-md-12 pb-10" *ngIf="gpmsValue=='Yes' || isCompanyGPMS">
                                  <label for="email">HR Details</label>
                                  <input type="text" name="hrName" class="form-control custom-form-control mt-10"
                                    placeholder="HR Name" [(ngModel)]="hrName" />
                                  <input type="email" class="form-control custom-form-control  mt-10"
                                    placeholder="HR Email" name="hrEmail" [(ngModel)]="hrEmail" />

                                </div>
                              </div> -->
                              <div class="form-group col-md-12 pb-10">
                                <label for="title"
                                  >Select Survey template</label
                                >
                                <select
                                  class="form-control custom-form-control"
                                  [(ngModel)]="selectedSurveyTemplate"
                                  (change)="resetGroupAndStatementsModal()"
                                  [ngModelOptions]="{ standalone: true }"
                                >
                                  class="form-control">
                                  <option selected disabled>Select</option>
                                  <option
                                    [selected]="
                                      template.id === selectedSurveyTemplate
                                    "
                                    *ngFor="let template of surveyTemplate"
                                    value="{{ template.id }}"
                                  >
                                    {{ template.id }}
                                    -
                                    {{ template.questionnaireName }}
                                  </option>
                                </select>
                              </div>

                              <div
                                class="form-group col-md-12 pb-10"
                                *ngIf="
                                  companyGroupsFromDB?.length > 0 &&
                                  templateFromDb?.toString() ==
                                    selectedSurveyTemplate?.toString()
                                "
                              >
                                <h4>Already assinged Groups</h4>
                                <div class="items">
                                  <div
                                    class="name"
                                    *ngFor="let group of companyGroupsFromDB"
                                  >
                                    {{ group }}
                                  </div>
                                </div>
                              </div>
                              <div
                                class="form-group col-md-12 pb-10"
                                *ngIf="selectedSurveyTemplate && featureExists"
                              >
                                <label for="surveyGroup"
                                  >Assign survey statements to new groups
                                </label>
                                <mat-slide-toggle
                                  id="surveyGroup"
                                  style="margin-left: 10px"
                                  [checked]="showGroupsAndStatements"
                                  (change)="surveyGroupStatus($event)"
                                ></mat-slide-toggle>
                              </div>
                              <div
                                *ngIf="showGroupsAndStatements"
                                class="form-group col-md-12 pb-10"
                              >
                                <div
                                  class="AddMoreBtn"
                                  style="
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                  "
                                >
                                  <button
                                    class="btn btn-danger add-icon"
                                    (click)="
                                      showGroupAndAssignMentPopup(popupGroup)
                                    "
                                  >
                                    Create New Groups and Order
                                  </button>
                                  <svg
                                    width="30"
                                    height="30"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style="margin-left: 10px"
                                    *ngIf="groupsSaved"
                                  >
                                    <path
                                      class="text-success"
                                      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1.41 15.41L6.29 12.3a1 1 0 0 1 0-1.4 1 1 0 0 1 1.42 0L11 14.17l8.3-8.29a1 1 0 0 1 1.41 1.42l-9 9a1 1 0 0 1-1.42 0z"
                                    />
                                  </svg>
                                </div>
                                <div
                                  class="AddMoreBtn"
                                  *ngIf="groupsSaved"
                                  style="
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                  "
                                >
                                  <button
                                    class="btn btn-danger add-icon"
                                    (click)="
                                      showGroupAndAssignMentPopup(
                                        assignStatements
                                      )
                                    "
                                  >
                                    Assign Statements to Groups
                                  </button>
                                  <svg
                                    width="30"
                                    height="30"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style="margin-left: 10px"
                                    *ngIf="allDataPresentForGroupsAndStatements"
                                  >
                                    <path
                                      class="text-success"
                                      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1.41 15.41L6.29 12.3a1 1 0 0 1 0-1.4 1 1 0 0 1 1.42 0L11 14.17l8.3-8.29a1 1 0 0 1 1.41 1.42l-9 9a1 1 0 0 1-1.42 0z"
                                    />
                                  </svg>
                                </div>
                              </div>

                              <div class="form-group col-md-12">
                                <label>Select Company type </label>
                                <select
                                  class="form-control custom-form-control"
                                  (change)="onSelectCompanyType($event)"
                                  [(ngModel)]="selectedCompanyType"
                                  [ngModelOptions]="{ standalone: true }"
                                >
                                  <!-- <option value="" selected hidden>select</option> -->

                                  <option
                                    *ngFor="let companyType of companyTypes"
                                    [value]="companyType.type"
                                  >
                                    {{ companyType.name }}
                                  </option>
                                </select>
                                <div
                                  class="form-group col-md-12 pb-10"
                                  *ngIf="gpmsValue"
                                >
                                  <label for="hr-details" class="mt-10"
                                    >HR Details<span class="asterick"
                                      >*</span
                                    ></label
                                  >
                                  <div id="hr-details">
                                    <input
                                      type="text"
                                      name="hrName"
                                      class="form-control custom-form-control"
                                      placeholder="HR Name"
                                      [(ngModel)]="hrName"
                                      required
                                      #onHRName="ngModel"
                                    />
                                    <p
                                      class="warning"
                                      *ngIf="
                                        !onHRName.valid && onHRName.touched
                                      "
                                    >
                                      Please Enter HR Name
                                    </p>
                                    <input
                                      type="email"
                                      class="form-control custom-form-control mt-10"
                                      placeholder="HR Email"
                                      name="hrEmail"
                                      (change)="validateEmailMain()"
                                      [(ngModel)]="hrEmail"
                                      required
                                      #onHREmail="ngModel"
                                    />
                                    <p
                                      class="warning"
                                      *ngIf="
                                        !onHREmail.valid && onHREmail.touched
                                      "
                                    >
                                      Please Enter HR Email
                                    </p>
                                    <p class="warning">{{ gpmsEmailStatus }}</p>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group col-md-12 pb-10">
                                <button
                                  type="submit"
                                  class="btn btn-primary addmantra-btn"
                                  (click)="onEditCompany()"
                                  [disabled]="
                                    !f1.valid ||
                                    !isIconValid ||
                                    (!isGPMSMailValid && gpmsValue) ||
                                    (showGroupsAndStatements &&
                                      !allDataPresentForGroupsAndStatements)
                                  "
                                  *ngIf="imageSrc"
                                >
                                  Update
                                </button>
                                <button
                                  type="submit"
                                  class="btn btn-primary addmantra-btn"
                                  (click)="onEditCompany()"
                                  [disabled]="
                                    !f1.valid ||
                                    (showGroupsAndStatements &&
                                      !allDataPresentForGroupsAndStatements)
                                  "
                                  *ngIf="!imageSrc"
                                >
                                  Update
                                </button>
                              </div>
                            </div>
                            <!-- <div class="form-group col-md-12 pb-10">
                              <label for="icon">Company Icon</label>
                              <input type="file" class="btn-primary" id="icon" (change)="showIcon($event)"
                                accept="image/x-png,image/gif,image/jpeg" name="icon">
                              <img class="mt-10" *ngIf="companySpecific.companyLogo!=='undefined'"
                                [src]="companySpecific.companyLogo" alt="Your Icon" width="50" height="50">
                            </div> -->
                          </form>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template #updatePackage>
                    <div class="modal-header">
                      <h4 style="font-size: 22px" class="modal-title pull-left">
                        Update company package
                      </h4>

                      <button
                        type="button "
                        class="close pull-right"
                        aria-label="Close "
                        (click)="modalRef.hide()"
                      >
                        <span aria-hidden="true ">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="row">
                        <div
                          class="col-md-12"
                          style="padding: 0"
                          style="text-align: center"
                        >
                          <form (ngSubmit)="onSubmit(f)" #f="ngForm">
                            <label for="email">Invoice no.</label>
                            <p style="text-align: center; padding-bottom: 2%">
                              <input
                                type="text"
                                name="invoice"
                                required
                                style="margin: 0 auto; width: 50%"
                                class="form-control custom-form-control mt-10"
                                placeholder="Eg. #1234XYZ"
                                [(ngModel)]="invoice"
                              />
                            </p>

                            <div class="col-md-3" style="text-align: center">
                              <button class="btn btn-primary" disabled>
                                Available: {{ remainingBasic }}
                              </button>
                              <br />
                              <br />
                              <p
                                style="
                                  font-size: 16px;
                                  font-weight: 700;
                                  text-align: center;
                                "
                              >
                                Basic package
                              </p>
                              <div class="input-group">
                                <span class="input-group-btn">
                                  <button
                                    type="button"
                                    class="btn btn-danger btn-number"
                                    (click)="subBasic()"
                                    data-type="minus"
                                    data-field="quant[2]"
                                  >
                                    <span
                                      class="glyphicon glyphicon-minus"
                                    ></span>
                                  </button>
                                </span>
                                <input
                                  type="number"
                                  class="form-control input-number"
                                  name="basic"
                                  [(ngModel)]="basic"
                                  style="text-align: center"
                                />
                                <span class="input-group-btn">
                                  <button
                                    type="button"
                                    class="btn btn-success btn-number"
                                    (click)="addBasic()"
                                    data-type="plus"
                                    data-field="quant[2]"
                                  >
                                    <span
                                      class="glyphicon glyphicon-plus"
                                    ></span>
                                  </button>
                                </span>
                              </div>
                            </div>
                            <div class="col-md-3" style="text-align: center">
                              <button class="btn btn-primary" disabled>
                                Available: {{ remainingPremium }}
                              </button>
                              <br />
                              <br />
                              <p style="font-size: 16px; font-weight: 700">
                                Premium package
                              </p>
                              <div class="input-group">
                                <span class="input-group-btn">
                                  <button
                                    type="button"
                                    class="btn btn-danger btn-number"
                                    (click)="subPremium()"
                                    data-type="minus"
                                    data-field="quant[2]"
                                  >
                                    <span
                                      class="glyphicon glyphicon-minus"
                                    ></span>
                                  </button>
                                </span>
                                <input
                                  type="number"
                                  name="quant[2]"
                                  class="form-control input-number"
                                  name="premium"
                                  [(ngModel)]="premium"
                                  style="text-align: center"
                                />
                                <span class="input-group-btn">
                                  <button
                                    type="button"
                                    class="btn btn-success btn-number"
                                    (click)="addPremium()"
                                    data-type="plus"
                                    data-field="quant[2]"
                                  >
                                    <span
                                      class="glyphicon glyphicon-plus"
                                    ></span>
                                  </button>
                                </span>
                              </div>
                            </div>
                            <div class="col-md-3" style="text-align: center">
                              <button class="btn btn-primary" disabled>
                                Available: {{ remainingSpecial }}
                              </button>
                              <br />
                              <br />
                              <p style="font-size: 16px; font-weight: 700">
                                Special package
                              </p>
                              <div class="input-group">
                                <span class="input-group-btn">
                                  <button
                                    type="button"
                                    class="btn btn-danger btn-number"
                                    (click)="subSpecial()"
                                    data-type="minus"
                                    data-field="quant[2]"
                                  >
                                    <span
                                      class="glyphicon glyphicon-minus"
                                    ></span>
                                  </button>
                                </span>
                                <input
                                  type="number"
                                  name="quant[2]"
                                  class="form-control input-number"
                                  name="special"
                                  [(ngModel)]="special"
                                  style="text-align: center"
                                />
                                <span class="input-group-btn">
                                  <button
                                    type="button"
                                    class="btn btn-success btn-number"
                                    (click)="addSpecial()"
                                    data-type="plus"
                                    data-field="quant[2]"
                                  >
                                    <span
                                      class="glyphicon glyphicon-plus"
                                    ></span>
                                  </button>
                                </span>
                              </div>
                            </div>
                            <div class="col-md-3" style="text-align: center">
                              <button class="btn btn-primary" disabled>
                                Available: {{ remainingCustom }}
                              </button>
                              <br />
                              <br />
                              <p
                                style="
                                  font-size: 16px;
                                  font-weight: 700;
                                  text-align: center;
                                "
                              >
                                Custom package
                              </p>
                              <div class="input-group">
                                <span class="input-group-btn">
                                  <button
                                    type="button"
                                    class="btn btn-danger btn-number"
                                    (click)="subCustom()"
                                    data-type="minus"
                                    data-field="quant[2]"
                                  >
                                    <span
                                      class="glyphicon glyphicon-minus"
                                    ></span>
                                  </button>
                                </span>
                                <input
                                  type="number"
                                  class="form-control input-number"
                                  name="custom"
                                  [(ngModel)]="custom"
                                  style="text-align: center"
                                />
                                <span class="input-group-btn">
                                  <button
                                    type="button"
                                    class="btn btn-success btn-number"
                                    (click)="addCustom()"
                                    data-type="plus"
                                    data-field="quant[2]"
                                  >
                                    <span
                                      class="glyphicon glyphicon-plus"
                                    ></span>
                                  </button>
                                </span>
                              </div>
                            </div>
                            <div
                              class="form-group col-md-12 pb-10"
                              style="text-align: center; padding-top: 4%"
                            >
                              <button
                                type="submit"
                                class="btn btn-primary addmantra-btn"
                                (click)="modalRef.hide()"
                                [disabled]="!f.valid"
                                (click)="
                                  onUpdatePackage(element.surveyEngineCompanyId)
                                "
                              >
                                Update
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template #editCompanyAdminTemplate>
                    <div class="modal-content">
                      <div class="modal-header">
                        <h4
                          style="font-size: 22px"
                          class="modal-title pull-left"
                        >
                          Edit User
                        </h4>
                        <button
                          type="button "
                          class="close pull-right"
                          aria-label="Close "
                          (click)="modalRef.hide()"
                        >
                          <span aria-hidden="true ">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <div class="row">
                          <div class="col-md-12" style="padding: 0">
                            <div class="form-group col-md-6">
                              <h3>Edit Admin Details</h3>
                              <form (ngSubmit)="onSubmit(f)" #f="ngForm">
                                <label for="name"
                                  >User Name<span class="asterick"
                                    >*</span
                                  ></label
                                >
                                <input
                                  type="text"
                                  name="name"
                                  [(ngModel)]="editName"
                                  class="form-control custom-form-control"
                                  placeholder="Enter user name"
                                  required
                                  disabled
                                  style="margin-bottom: 3%"
                                />
                                <label for="email"
                                  >Email<span class="asterick">*</span></label
                                >
                                <input
                                  type="text"
                                  name="email"
                                  [(ngModel)]="editEmail"
                                  class="form-control custom-form-control"
                                  disabled
                                  style="margin-bottom: 3%"
                                />

                                <p class="warning">{{ emailStatus }}</p>
                                <label for="mobile"
                                  >Mobile Number<span class="asterick"
                                    >*</span
                                  ></label
                                >
                                <input
                                  type="number"
                                  name="mobile"
                                  [(ngModel)]="editMobile"
                                  class="form-control custom-form-control"
                                  placeholder="Enter mobile number"
                                  maxlength="10"
                                  size="10"
                                  required
                                  #addCompanyAdminEmail="ngModel"
                                  style="margin-bottom: 3%"
                                />
                                <p
                                  class="warning"
                                  *ngIf="
                                    !addCompanyAdminEmail.valid &&
                                    addCompanyAdminEmail.touched
                                  "
                                >
                                  Please Enter mobile number
                                </p>
                                <label for="gender"
                                  >Gender<span class="asterick">*</span></label
                                >
                                <select
                                  class="form-control custom-form-control"
                                  [(ngModel)]="editGender"
                                  name="gender"
                                  (change)="
                                    onSelectgender($event.target['value'])
                                  "
                                  ngModel
                                  required
                                  #ngGender="ngModel"
                                  style="margin-bottom: 3%"
                                >
                                  <option value="" selected>select</option>
                                  <option
                                    *ngFor="let gender of gender"
                                    [value]="gender.value"
                                  >
                                    {{ gender.value }}
                                  </option>
                                </select>
                                <p
                                  class="warning"
                                  *ngIf="!ngGender.valid && ngGender.touched"
                                >
                                  Please select gender
                                </p>

                                <label for="company"
                                  >Company Name<span class="asterick"
                                    >*</span
                                  ></label
                                >
                                <input
                                  type="text"
                                  name="companyName"
                                  class="form-control custom-form-control"
                                  [(ngModel)]="editCompanyName"
                                  placeholder="Enter Company name"
                                  required
                                  #adminCompanyName="ngModel"
                                  disabled
                                />
                                <p
                                  class="warning"
                                  *ngIf="
                                    !adminCompanyName.valid &&
                                    adminCompanyName.touched
                                  "
                                >
                                  Please Enter company name
                                </p>

                                <label for="editAccess"
                                  >Add or remove access
                                  <span class="asterick">*</span></label
                                >
                                <ngx-select-dropdown
                                  style="margin: 20px"
                                  (change)="selectEditUserAccess($event)"
                                  [multiple]="true"
                                  [config]="configAccess"
                                  [(ngModel)]="selectedAccess"
                                  name="editAccess"
                                  [options]="optionsArray"
                                ></ngx-select-dropdown>

                                <!-- <label for="batchName">Batch Name </label>
                                <input
                                  type="text"
                                  name="batchName"
                                  class="form-control custom-form-control"
                                  [(ngModel)]="editBatchName"
                                  placeholder="Enter Batch Name"
                                /> -->
                                <label for="batch" class="batchHeader"
                                  >Add or Remove Batches
                                </label>
                                <ngx-select-dropdown
                                  style="margin: 20px"
                                  (change)="selectEditBatch($event)"
                                  [multiple]="true"
                                  [config]="configBatch"
                                  [(ngModel)]="editBatchName"
                                  [options]="batchArray"
                                  name="batch"
                                >
                                </ngx-select-dropdown>

                                <button
                                  style="margin-top: 5%"
                                  type="submit"
                                  class="btn btn-primary"
                                  (click)="editCompanyAdmin()"
                                  (click)="modalRef.hide()"
                                  [disabled]="
                                    !f.valid || selectedAccess.length == 0
                                  "
                                >
                                  Edit User
                                </button>
                                <button
                                  style="margin-top: 5%; margin-left: 5%"
                                  type="submit"
                                  class="btn btn-primary"
                                  (click)="sendEmailCompanyLogin()"
                                  (click)="modalRef.hide()"
                                  [disabled]="
                                    !f.valid || selectedAccess.length == 0
                                  "
                                >
                                  Send Email
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </mat-cell>
              </ng-container>
              <!-- uploadCertificate -->
              <ng-container matColumnDef="uploadCertificate">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >Upload Certificate</mat-header-cell
                >
                <mat-cell *matCellDef="let element">
                  <button
                    class="btn primary-btn"
                    (click)="uploadCertificateFn(element)"
                  >
                    Upload Certificate
                  </button>
                </mat-cell>
              </ng-container>
              <!-- generateCertificate -->
              <ng-container matColumnDef="generateCertificate">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >Generate Certificate</mat-header-cell
                >
                <mat-cell *matCellDef="let element">
                  <button
                    class="btn primary-btn"
                    (click)="generateCertitifcate(element)"
                  >
                    Generate Certificate
                  </button>
                </mat-cell>
              </ng-container>
              <mat-header-row
                *matHeaderRowDef="displayedColumns"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedColumns"
              ></mat-row>
            </mat-table>
            <mat-paginator
              [length]="7"
              [pageSize]="7"
              [pageSizeOptions]="[7, 14, 28]"
            >
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #addCustomLD>
  <div class="modal-header">
    <h4 style="font-size: 22px" class="modal-title pull-left">
      Add Custom L and D
    </h4>
    <button
      type="button "
      class="close pull-right"
      aria-label="Close "
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <label for="">Select module</label>
      <ngx-select-dropdown
        style="margin: 20px"
        (change)="selectModule($event)"
        [multiple]="true"
        [config]="config"
        [options]="moduleNames"
      ></ngx-select-dropdown>
      <div style="text-align: center">
        <button
          style="margin-top: 20px"
          class="btn btn-primary"
          [disabled]="selectedModules.length == 0"
          (click)="addCustomLd()"
        >
          Add
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #datePickerModal>
  <div class="modal-header">
    <h4 style="font-size: 22px" class="modal-title pull-left">
      Add revoke date
    </h4>
    <button
      type="button "
      class="close pull-right"
      aria-label="Close "
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <p *ngIf="revokeDate" class="currentRevokeData">
        Revoke Date : {{ revokeDate }}
      </p>
      <mat-form-field>
        <input
          matInput
          [(ngModel)]="revokeDate"
          [matDatepicker]="picker"
          placeholder="Choose a date"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <button class="btn primary-btn" (click)="updateRevokeDate()">
        Submit
      </button>
    </div>
  </div>
</ng-template>

<ng-template #rawData>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Report Download</h4>
    <button
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row" *ngIf="showSocketData">
      <p>All Surveys</p>
      <p class="totalSurveys">
        Total Number of surveys: {{ totalNumberOfSurveys }}
      </p>
      <p class="fetchingRawData">Fetching Raw Data: {{ leftNumberOfSurvey }}</p>
    </div>
    <div *ngIf="surveydataSource.length === 0">
      <h1 style="font-size: 22px">No surveys found</h1>
    </div>
    <div *ngIf="surveydataSource.length > 0">
      <table
        class="table table1 table-striped"
        style="
          margin-top: 0;
          font-size: 12px;
          font-family: Montserrat-Regular, sans-serif;
        "
      >
        <thead>
          <tr>
            <th scope="col">Startdate</th>
            <th scope="col">Enddate</th>
            <th scope="col">Status</th>
            <th scope="col">Type</th>
            <th scope="col">Text</th>
            <th scope="col">No. of Surveys</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let survey of surveydataSource | paginate : configTable">
            <td>{{ survey.startDate }}</td>
            <td>
              {{ survey.endDate }}
            </td>
            <td>{{ survey.status }}</td>
            <td>{{ survey.surveyType }}</td>
            <td>{{ survey.text }}</td>
            <td>{{ survey.surveyLength }}</td>
            <td>
              <ul class="list-group list-group-horizontal">
                <li class="list-group-item flex-fill">
                  <button
                    class="btn btn-secondary btn-block"
                    style="font-size: 12px"
                    (click)="getRawDataBySurveyId(survey)"
                  >
                    Raw Data
                  </button>
                </li>
                <li class="list-group-item flex-fill">
                  <button
                    class="btn btn-secondary btn-block"
                    style="font-size: 12px"
                    (click)="getReliabilityReport(survey)"
                  >
                    Reliability Report
                  </button>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination-control">
        <pagination-controls
          (pageChange)="pageChanged($event)"
          [autoHide]="autoHide"
          [responsive]="responsive"
          class="my-pagination"
        ></pagination-controls>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #popupGroup>
  <table class="table">
    <thead>
      <tr>
        <th>Group Name</th>
        <th>Group Order</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let group of groups; let i = index">
        <td>
          <input
            type="text"
            placeholder="New Group Name"
            [(ngModel)]="group.groupName"
            class="form-control"
          />
        </td>
        <td>
          <input
            type="number"
            placeholder="New Group Order"
            [(ngModel)]="group.groupOrder"
            class="form-control"
          />
        </td>
        <td>
          <button
            type="button"
            class="btn btn-danger btn-sm"
            (click)="removeGroup(i)"
          >
            Remove
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="buttons">
    <button
      type="button"
      class="btn btn-primary add-icon"
      (click)="addGroup('', 1)"
    >
      Add More+
    </button>

    <button
      type="button"
      class="btn btn-primary add-icon"
      [disabled]="isSaveButtonDisabled() || groups.length == 0"
      (click)="saveGroupsInDb()"
    >
      Save All
    </button>
  </div>
</ng-template>
<ng-template #assignStatements>
  <!-- <div class="form-group">
    <label for="groupName">Select Group Name</label>
    <select class="form-control" id="groupName" [(ngModel)]="selectedGroupName">
      <option *ngFor="let item of groups" [value]="item.groupName">{{ item.groupName }}</option>
    </select>
  </div>


  <div class="form-group">
    <label for="statements">Select Statements</label>
    <select class="form-control" id="statements" multiple (click)="toggleItem($event.target['value'],selectedGroupName)">
      <option *ngFor="let question of questionsFromDB" [value]="question.questionId">{{ question.questionText }}</option>
    </select>
  </div>
  
  <div *ngIf="selectedItems.length > 0">
    <h4>Selected Statements:</h4>
    <ul>
      <li *ngFor="let selectedItem of selectedItems">
        Group: {{ selectedItem.groupName }}, Question: {{ getQuestionTextById(selectedItem.question.questionId) }}
        <button (click)="toggleItem(selectedItem.questionId, selectedItem.groupName)">Remove</button>
      </li>
    </ul>
  </div>

  <div *ngIf="selectedItems.length > 0" class="save">
    <button class="btn btn-primary add-icon" (click)="saveAll()">Save All</button>
  </div> -->

  <div class="modal-body">
    <div class="form-group">
      <label for="groupName"><h4>Select Group Name</h4></label>
      <select
        class="form-control"
        id="groupName"
        [(ngModel)]="selectedGroupName"
      >
        <option *ngFor="let item of groups" [value]="item.groupName">
          {{ item.groupName }}
        </option>
      </select>
    </div>
    <div class="form-group" *ngIf="questionsCount - selectedItems.length != 0">
      <label for="statements"> <h4>Click on Statement to select</h4></label>
      <div class="table-container">
        <table class="table">
          <thead>
            <tr>
              <th>Question</th>
              <th>Theme</th>
              <th>Sub-Theme</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let question of availableQuestions"
              (click)="toggleItem(question.questionId, selectedGroupName)"
            >
              <td>{{ question.questionText }}</td>
              <td>{{ question.themeName }}</td>
              <td>{{ question.subTheme }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div *ngIf="selectedItems.length > 0" class="mt-4">
      <h4>Selected Statements:</h4>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Group Name</th>
            <th style="font-size: 16px">Statement/Subtheme Name</th>
            <th style="font-size: 16px">Question Text</th>
            <th style="width: 1%; white-space: nowrap">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let selectedItem of selectedItems">
            <td>{{ selectedItem.groupName }}</td>
            <td>
              {{ getQuestionSubthemeById(selectedItem.question.questionId) }}
            </td>
            <td>{{ getQuestionTextById(selectedItem.question.questionId) }}</td>
            <td>
              <button
                class="btn btn-danger btn-sm"
                (click)="
                  toggleItem(
                    selectedItem.question.questionId,
                    selectedItem.groupName
                  )
                "
              >
                Remove
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="modal-footer">
    <button
      *ngIf="selectedItems.length > 0"
      type="button"
      class="btn btn-primary add-icon"
      (click)="saveAll()"
    >
      Save All
    </button>
  </div>
</ng-template>
