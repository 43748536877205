import { Component, OnInit } from '@angular/core';
import { AdminServices } from '../admin.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
})
export class AdminComponent implements OnInit {
  constructor(private adminService: AdminServices, private router: Router) {}

  ngOnInit() {
    if (this.adminService.roleGuard()) {
    } else {
      this.router.navigate(['/admin']);
    }
  }
}
