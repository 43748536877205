<section>
  <h3>Choose survey date interval:</h3>
  <div class="select-section">
    <mat-form-field appearance="fill">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
        <input
          matStartDate
          formControlName="startDate"
          placeholder="Start date"
        />
        <input matEndDate formControlName="endDate" placeholder="End date" />
      </mat-date-range-input>
      <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>

      <mat-error
        *ngIf="range.controls.startDate.hasError('matStartDateInvalid')"
        >Invalid start date</mat-error
      >
      <mat-error *ngIf="range.controls.endDate.hasError('matEndDateInvalid')"
        >Invalid end date</mat-error
      >
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Choose an option</mat-label>
      <mat-select
        [(ngModel)]="selectedDataType"
        (selectionChange)="selectType($event)"
      >
        <mat-option value="preProcessedData">Pre-processed data</mat-option>
        <mat-option value="processedData">Processed data</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field
      appearance="fill"
      *ngIf="selectedDataType == 'processedData'"
    >
      <mat-label>Recognition year</mat-label>
      <mat-select (selectionChange)="selectYear($event.value)">
        <mat-option *ngFor="let year of years" [value]="year">
          {{ year }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button
      mat-raised-button
      color="warn"
      [disabled]="
        range.controls.startDate.value == null ||
        range.controls.endDate.value == null
      "
      (click)="getManagers()"
    >
      Get managers
    </button>
    <button
      mat-raised-button
      color="primary"
      *ngIf="selection.selected.length > 0"
    >
      {{
        selectedDataType == "preProcessedData"
          ? "Process data of selected managers"
          : "Launch survey of selected managers"
      }}
    </button>
    <button
      mat-raised-button
      color="primary"
      *ngIf="
        selection.selected.length > 0 && selectedDataType == 'preProcessedData'
      "
      (click)="openDialog('viewSurvey')"
    >
      New process data
    </button>
  </div>
  <br />
  <div class="sub-header-section">
    <h2>
      `
      <span class="text-data">
        {{
          selectedDataType == "preProcessedData"
            ? "Pre-Processed Data"
            : "Processed Data"
        }}
      </span>
      is selected.
    </h2>
  </div>
</section>

<section *ngIf="dataSource && dataSource.data.length > 0; else noDataTemplate">
  <table mat-table [dataSource]="dataSource">
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? toggleAllRows() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [disabled]="isChecked"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
          [disabled]="isChecked"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <!-- email Column -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>Email</th>
      <td mat-cell *matCellDef="let element">{{ element.email }}</td>
    </ng-container>

    <!-- Master questionnair Column -->
    <ng-container matColumnDef="masterQuestionnaireId">
      <th mat-header-cell *matHeaderCellDef>MasterQuestionnaireId</th>
      <td mat-cell *matCellDef="let element">
        {{ element.masterQuestionnaireId }}
      </td>
    </ng-container>

    <!-- overAllScore Column -->
    <ng-container matColumnDef="overAllScore">
      <th mat-header-cell *matHeaderCellDef>Overall Score</th>
      <td mat-cell *matCellDef="let element">
        {{ element.overAllScore | number : "1.0-0" }}
      </td>
    </ng-container>

    <!-- teamMemberScore Column -->
    <ng-container matColumnDef="teamMemberScore">
      <th mat-header-cell *matHeaderCellDef>TM Score</th>
      <td mat-cell *matCellDef="let element">
        {{ element.teamMemberScore | number : "1.0-0" }}
      </td>
    </ng-container>

    <!-- totalTeamMembers Column -->
    <ng-container matColumnDef="totalTeamMembers">
      <th mat-header-cell *matHeaderCellDef>
        {{
          selectedDataType == "preProcessedData" ? "Total TM" : "Final Score"
        }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.totalTeamMembers }}</td>
    </ng-container>

    <!-- totalParticipants Column -->
    <ng-container matColumnDef="totalParticipants">
      <th mat-header-cell *matHeaderCellDef>Total Participants</th>
      <td mat-cell *matCellDef="let element">
        {{ element.totalParticipants }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="selection.toggle(row)"
    ></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" aria-label="Items per page">
  </mat-paginator>
</section>
<ng-template #noDataTemplate>
  <p class="warningText">No data found in selected range</p>
</ng-template>
