<div class="page-wrapper">
    <div class="col-md-12 text-right mb-5">
        <div style="display: flex;justify-content: end; margin-bottom: 12px; gap: 12px;"><button
                class="btn btn-secondary btn-newuser-clr" (click)="openAddNudgeModal(addNudge,'default')">Add New
                Topic</button></div>
    </div>
    <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="ID">
            <th mat-header-cell *matHeaderCellDef>ID</th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <ng-container matColumnDef="Title">
            <th mat-header-cell *matHeaderCellDef>Title</th>
            <td mat-cell *matCellDef="let element"> {{ element.title }}
            </td>
        </ng-container>

        <ng-container matColumnDef="Sub Theme">
            <th mat-header-cell *matHeaderCellDef>Sub Theme</th>
            <td mat-cell *matCellDef="let element"> {{ element.subtheme }}
            </td>
        </ng-container>
        <ng-container matColumnDef="Code">
            <th mat-header-cell *matHeaderCellDef>Code</th>
            <td mat-cell *matCellDef="let element"> {{ element.code}}
            </td>
        </ng-container>
        <ng-container matColumnDef="Custom">
            <th mat-header-cell *matHeaderCellDef>Custom</th>
            <td mat-cell *matCellDef="let element"> {{ element.is_custom}}
            </td>
        </ng-container>
        <ng-container matColumnDef="Edit">
            <th mat-header-cell *matHeaderCellDef>Edit</th>
            <td mat-cell *matCellDef="let element"><i (click)="openEditNudgeModal(addNudge,element)"
                    matTooltip="Edit Topic" matTooltipPosition="above" class="fa fa-edit"
                    style="cursor: pointer; margin-left: 15px"></i></td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;let i = index"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator>
</div>

<ng-template #addNudge>
    <div class="modal-header">
        <h4 style="font-size: 22px" class="modal-title pull-left">
            {{ isEdit ? 'Edit Topic' : 'Add Topic' }}
        </h4>
        <button type="button " class="close pull-right" aria-label="Close " (click)="hideEditModal()">
            <span aria-hidden="true ">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form class="form" [formGroup]="categoryDataForm">
            <div class="parent">
                <div class="child"><label for="title">Title:<small class="text-danger">*</small> </label>
                    <input formControlName="title" required type="text" placeholder="Enter title" />


                </div>





                <div class="child">
                    <div class="child">
                        <label for="is_active">Custom:
                        </label>
                        <label class="switch">
                            <input formControlName="is_custom" type="checkbox">
                            <span class="slider round"></span>
                        </label>
                    </div>
                    <div class="child">
                        <label for="sub_theme_id">Choose Topic Subtheme:<small class="text-danger">*</small> </label>
                        <select class="form-control custom-form-control" formControlName="sub_theme_id" required>

                            <option *ngFor="let subtheme of subthemes" [value]="subtheme.subthemeId">
                                {{ subtheme.subtheme }}
                            </option>
                        </select>
                    </div>

                </div>


            </div>
            <button class=" btn btn-secondary btn-newuser-clr" type="button" (click)="openConfirmation()"
                [disabled]="categoryDataForm.invalid">
                {{isEdit ? 'Update':'Submit'}}
            </button>
            <br />
            <p class="text-danger text-center m-2" *ngIf="categoryDataForm.touched && categoryDataForm.invalid">
                Please Fill All Mandatory Fields
            </p>
        </form>
    </div>
</ng-template>