<div class="login-main">
  <div class="container-fluid">
    <!-- //<app-loader *ngIf="loading"></app-loader> -->
    <div class="row">
      <div class="tbl">
        <div class="tbl-cel-left login-left"></div>
        <div class="tbl-cel-right">
          <div class="col-md-6 login-right text-center">
            <form
              [formGroup]="loginForm"
              (ngSubmit)="loginSubmit()"
              autocomplete="off"
            >
              <div class="form-group">
                <img
                  src="../../../assets/images/Logo.png"
                  style="margin: 0 auto"
                  class="gmiMobileLogo"
                  width="30%"
                  height="50%"
                />
                <img src="../../../assets/images/locked.png" class="lockIcon" />
                <h3>Sign In</h3>
              </div>
              <div class="form-group mt-30">
                <input
                  type="email"
                  class="form-control form-control-lg envelope-icon"
                  placeholder="Email Address"
                  formControlName="email"
                  autocomplete="off"
                />
              </div>
              <div class="form-group mt-30">
                <input
                  type="hidden"
                  class="form-control form-control-lg envelope-icon"
                  placeholder="Email Address"
                  formControlName="selectedRole"
                  value="company"
                  autocomplete="off"
                />
              </div>
              <div class="form-group" style="display: table">
                <input
                  id="passwordView"
                  type="password"
                  class="form-control form-control-lg lock-icon"
                  placeholder="Password"
                  formControlName="password"
                  autocomplete="off"
                />
                <span
                  class="input-group-addon"
                  style="
                    border-top-right-radius: 25px;
                    border-bottom-right-radius: 25px;
                    cursor: pointer;
                    background-color: #d7dce3;
                  "
                >
                  <i
                    class="fa {{ eyeIcon }}"
                    aria-hidden="true"
                    (click)="togglePasswordView()"
                    style="color: #b8b8b8; font-size: 18px"
                  ></i>
                </span>
              </div>
              <div *ngIf="showGoogleCaptcha">
                <app-google-recaptcha
                  (onSuccess)="onCaptchaSuccess($event)"
                  (onError)="onCaptchaError($event)"
                ></app-google-recaptcha>
              </div>
              <div class="form-group mt-25">
                <button
                  type="submit"
                  class="gmi-oval-btn"
                  [disabled]="
                    !loginForm.valid ||
                    (!isVerifiedCaptcha && showGoogleCaptcha)
                  "
                >
                  SIGN IN
                </button>
              </div>
              <div class="form-group mt-30">
                <a
                  class="red-header-text"
                  [routerLink]="'/company/forgot-password'"
                  >Forgot Password</a
                >
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
