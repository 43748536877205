export var config = {
  title: "Dashboard",
  razorpay_key: "rzp_live_v0FdLtDQ2apNzC",
  appUrl: "https://dashboard.greatmanagerinstitute.com",
  apiUrl: "https://api-read.greatmanagerinstitute.com",
  portalUrl: "https://app.greatmanagerinstitute.com",
  apiReadUrl: "https://api-read.greatmanagerinstitute.com",
  emailTemplateID: "template_MwNzUhDm",
  emailUserId: "user_kS9VfjmUbIZ9Cf7bN01lY",
  lfiUrlUat: "https://lfi-api-uat.greatmanagerinstitute.com/api",
  lfiTokenUat:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJHTUktQURNSU4iLCJuYW1lIjoiTEZJLVRPS0VOIiwiaWF0IjoxNTE2MjM5MDIyfQ.HXbx06xh6SJW_kudR8-AQX68y4eE_sAA-Dz0OE7BBwc",
  lfiUrl: "https://lfi-api.greatmanagerinstitute.com/api",
  lfiToken:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJHTUktQURNSU4iLCJuYW1lIjoiTEZJLVRPS0VOIiwiaWF0IjoxNTE2MjM5MDIyfQ.HXbx06xh6SJW_kudR8-AQX68y4eE_sAA-Dz0OE7BBwc",
  imageRecognitionUrl: "https://ml.greatmanagerinstitute.com",
};
