import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from './auth.service';
// import * as decode from 'jwt-decode';
// import * as jwt_decode from 'jwt-decode';
// import * as jwt_decode from 'jwt-decode';
import { jwtDecode } from 'jwt-decode';

// import { ManagerServices } from "./manager/manager.service";
import Swal from 'sweetalert2';

@Injectable()
export class RoleGuardService implements CanActivate {
  currentUrl: any;
  nowUrl: any;
  wantedUrl: any;
  packageInfo: any;
  currentDate: any;
  menuItems = [
    {
      name: 'packages',
      routerLink: '/manager-dashboard',
      rolesRequired: 'one_year_access',
    },
    {
      name: 'profile',
      routerLink: '/profile',
      rolesRequired: 'profile',
    },
    {
      name: 'manager-mantras',
      routerLink: '/manager-mantras',
      rolesRequired: 'manager_mantras',
    },
    {
      name: 'my-surveys',
      routerLink: '/my-surveys',
      rolesRequired: 'certification_survey',
    },
    {
      name: 'create-survey',
      routerLink: '/create-survey',
      rolesRequired: 'certification_survey',
    },
    {
      name: 'preview-survey',
      routerLink: '/preview-survey/:id',
      rolesRequired: 'certification_survey',
    },
    {
      name: 'action-plans-dashboard',
      routerLink: '/action-plans-dashboard',
      rolesRequired: 'ap',
    },
    {
      name: 'create-action-plan',
      routerLink: '/create-action-plan',
      rolesRequired: 'ap',
    },
    {
      name: 'view-plan',
      routerLink: '/view-plan/:id',
      rolesRequired: 'ap',
    },
    {
      name: 'learning-and-development',
      routerLink: '/learning-and-development',
      rolesRequired: 'l&d',
    },
    {
      name: 'leaderboard',
      routerLink: '/leaderboard',
      rolesRequired: 'l&d',
    },
    {
      name: 'learning-dashboard',
      routerLink: '/learning-dashboard',
      rolesRequired: 'learning_dashboard',
    },
    {
      name: 'marshall-bot',
      routerLink: '/marshall-bot',
      rolesRequired: 'manager_mantras',
    },
    {
      name: 'mentor-chat',
      routerLink: '/mentor-chat',
      rolesRequired: 'mentor_support',
    },
  ];

  constructor(
    public auth: AuthService,
    public router: Router // private managerService: ManagerServices
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    // this will be passed from the route config
    // on the data property

    const token = localStorage.getItem('token');
    // const r2Id = localStorage.getItem("r2Id")
    let url: string = state.url;
    this.wantedUrl = url.split('/')[1];
    if (!token) {
      if (this.wantedUrl === 'company' || this.wantedUrl === 'admin') {
        this.router.navigate(['/']);
        return false;
      } else {
        this.router.navigate(['/404', this.wantedUrl]);
        return false;
      }
    }
    if (token) {
      const tokenPayload: any = jwtDecode(token);
      let url: string = state.url;
      switch (this.wantedUrl) {
        case 'admin':
          return this.getAuthorizeToUrl('admin', tokenPayload.role);
        case 'company':
          return this.getAuthorizeToUrl('company', tokenPayload.role);
        default:
          this.router.navigate(['/404']);
          break;
      }
    }
    return false;
  }

  getAuthorizeToUrl(url: any, role: any) {
    return role.toLowerCase() === url.toLowerCase();
  }

  managerAuthorization(url: any, tokenPayload: any) {
    if (
      url.split('/')[1] == 'manager' ||
      url.split('/')[2] == 'manager-dashboard'
    ) {
      if (
        (url.split('/')[1] == 'manager' &&
          url.split('/')[2] == 'selfassesmentsurvey') ||
        url.split('/')[2] == 'profile-creation' ||
        url.split('/')[2] == 'pricing' ||
        url.split('/')[2] == 'manager-dashboard' ||
        url.split('/')[2] == 'mentor-chat'
      ) {
      } else {
        this.currentDate = new Date();
        // this.managerService.getManagerProfile().subscribe((response: any) => {
        //   this.packageInfo = JSON.stringify(response.data.package_info);
        //   var firstDate = new Date(this.currentDate),
        //     secondDate = new Date(response.data.package_info.endDate),
        //     currentDateInSeconds = firstDate.getTime(),
        //     endDateInSeconds = secondDate.getTime();
        //   if (currentDateInSeconds >= endDateInSeconds) {
        //     Swal({
        //       title: "Package expire",
        //       text: "Your package has been expired",
        //       type: "error",
        //       timer: 2500,
        //       showConfirmButton: false,
        //     });
        //     this.router.navigate(["/manager/login"]);
        //     localStorage.clear();
        //   }
        // });
      }

      if (url.split('/')[3] != undefined) {
        // this is to check access to that route.
        let menuItem: any = {};
        for (var i = 0; i < this.menuItems.length; i++) {
          if (this.menuItems[i].name == url.split('/')[3]) {
            menuItem = this.menuItems[i];
          }
        }

        if (
          Object.keys(menuItem).length === 0 &&
          menuItem.constructor === Object
        ) {
          // unknown URL
          return true;
        } else {
          let haveAccess = tokenPayload.packageFeatures.includes(
            menuItem.rolesRequired
          );
          if (!haveAccess) {
            this.router.navigate(['/manager/manager-dashboard/accessDenied']);
            return false;
          }
          return true;
        }
      }
      //this is for dashboard.
      return true;
    } else {
      return false;
    }
  }
}
