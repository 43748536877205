// import { NgModule } from '@angular/core';
// import { CommonModule } from '@angular/common';
// import { AdminComponent } from './admin/admin.component';

// @NgModule({
//   declarations: [AdminComponent],
//   imports: [CommonModule],
// })
// export class AdminModule {}
import { ClipboardModule } from "@angular/cdk/clipboard";
import { AdminServices } from "./admin.service";
import { LoginServices } from "../login/login.service";
import { ForgotPasswordComponent } from "./login/forgot-password/forgot-password.component";
import { LoginComponent } from "./login/login.component";
import { AdminComponent } from "./admin/admin.component";
import { DataTablesModule } from "angular-datatables";
import { DataSource } from "@angular/cdk/table";
import { NgxEditorModule } from "ngx-editor";
import { NgxPaginationModule } from "ngx-pagination";
import { ColorPickerModule } from "ngx-color-picker";
import { NoSpacesDirective } from "../directives/no-spaces.directive"; // Import your directive
// import {
//
//   MatTabsModule,
//   MatIconModule,
//   MatNativeDateModule,
// } from '@angular/material';
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule, MatTableDataSource } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatIconModule } from "@angular/material/icon";
import { CdkTableModule } from "@angular/cdk/table";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
import { CommonModule, DatePipe } from "@angular/common";
import { MaterialModule } from "../material.module";
import { TagInputModule } from "ngx-chips";
// import { RlTagInputModule } from 'angular2-tag-input';
import { SurveyEngineService } from "../services/surveyEngine.service";
import { CompanyInputComponent } from "./components/company-input.component";
import { AdminDashboardComponent } from "./admin-dashboard/admin-dashboard.component";
import { ResetPasswordComponent } from "./login/reset-password/reset-password.component";
import { RoleGuardService as RoleGuard } from "../role-guard.service";
import { CorporateCompaniesComponent } from "./corporate-companies/corporate-companies.component";
import { AddCompanyComponent } from "./corporate-companies/add-company/add-company.component";
import { FilterPipe } from "../pipes/filter.pipe";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { AuthGuardService } from "../auth-guard.service";
import { CertificationModerationComponent } from "./certification-moderation/certification-moderation.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { BsModalService } from "ngx-bootstrap/modal";
import { ModalModule } from "ngx-bootstrap/modal";
import { ManagerMisComponent } from "./manager-mis/manager-mis.component";
import { LoaderComponent } from "./loader/loader.component";
import { AssignCouponComponent } from "./assign-coupon/assign-coupon.component";
// import { TriggerComponent } from "./trigger/trigger.component";
// import { CreateMessageComponent } from "./trigger/create-message/create-message.component";
// import { ScheduleMessageComponent } from "./trigger/schedule-message/schedule-message.component";
// import { MessageManagementComponent } from "./trigger/message-management/message-management.component";
// import { BuLoginComponent } from "./bu-login/bu-login.component";
import { LocalCompanyInputComponent } from "./components/local-company-input";
// import { AddBuLoginManagersComponent } from "./add-bu-login-managers/add-bu-login-managers.component";
import { SelectDropDownModule } from "ngx-select-dropdown";
import { LogsComponent } from "./logs/logs.component";
import { CreateCouponComponent } from "./create-coupon/create-coupon.component";
import { MentorComponent } from "./mentor/mentor.component";
import { CreateMentorComponent } from "./create-mentor/create-mentor.component";
import { AssignMentorComponent } from "./assign-mentor/assign-mentor.component";
import { RoundTwoCompanyComponent } from "./round-two-company/round-two-company.component";
import { R2SurveyComponent } from "./r2-survey/r2-survey.component";
import { SharedModule } from "../shared.module";
// import { LandingTemplateComponent } from "./landing-template/landing-template.component";
// import { AddTemplateComponent } from "./landing-template/add-template/add-template.component";
// import { PreviewTemplateComponent } from "./landing-template/preview-template/preview-template.component";
// import { DatabaseDashboardComponent } from "./database-dashboard/database-dashboard.component";
// import { CodemirrorModule } from '@ctrl/ngx-codemirror';

// import { CodemirrorModule } from '@ctrl/ngx-codemirror';
// import { CodeMirrorModule } from '@ctrl/ngx-codemirror';
// import { BenchmarkComponent } from "./benchmark/benchmark.component";
// import { CreateBenchmarkComponent } from "./benchmark/create-benchmark/create-benchmark.component";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { MatCheckboxModule } from "@angular/material/checkbox";
// import { RewardRequestsComponent } from "./reward-requests/reward-requests.component";
import { AngularMultiSelectModule } from "angular2-multiselect-dropdown";
import { SafeHtmlPipe } from "../../app/commonPipe";
import { NudgesComponent } from "./nudges/nudges.component";

import { NudgesStatsComponent } from "./nudges/nudges-stats/nudges-stats.component";
import { NudgesActiveComponent } from "./nudges/nudges-active/nudges-active.component";

import { UserGuideComponent } from "./user-guide/user-guide.component";
// import { HotspotComponent } from './admin-dashboard/hotspot/hotspot.component';
import { ResearchQuestionComponent } from "./research-question/research-question.component";
import { AccomplishmentPopoverComponent } from "./accomplishment-popover/accomplishment-popover.component";
import { SurveyQuestionsComponent } from "./survey-question-management/survey-questions/survey-questions.component";
import { SubthemeComponent } from "./survey-question-management/subtheme/subtheme.component";
import { MasterQuestionnaireComponent } from "./survey-question-management/master-questionnaire/master-questionnaire.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { CertificateGenerationComponent } from "./certificate-generation/certificate-generation.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { SortbyPipe } from "../pipes/sortby.pipe";
import { RoundOffPipe } from "../pipes/roundOff.pipe";
import { DecimalPipe } from "@angular/common";
import { SchduleSendCertificatesComponent } from "./certificate-generation/schdule-send-certificates/schdule-send-certificates.component";
import { LeadershipFactoriesComponent } from "./leadership-factories/leadership-factories.component";
import { MatNativeDateModule } from "@angular/material/core";
import { R2ExtensionComponent } from "./r2-survey/r2-extension/r2-extension.component";

import { NudgeManagerComponent } from "./nudge-manager/nudge-manager.component";
import { PageManagerComponent } from "./pages/page-manager/page-manager.component";
import { ComponentComponent } from "./pages/component/component.component";
import { SubComponentComponent } from "./pages/sub-component/sub-component.component";
import { CertificateUploadComponent } from "./certificate-upload/certificate-upload.component";
import { LeadershipAnalyticsComponent } from "./admin-dashboard/leadership-analytics/leadership-analytics.component";
import { NudgeCategoryComponent } from "./nudge-category/nudge-category.component";
import { SurveyExperienceManagementComponent } from "./survey-experience-management/survey-experience-management.component";
import { SurveyDialogComponent } from "./survey-experience-management/survey-dialog/survey-dialog.component";
import { SurveyFillersComponent } from "./survey-experience-management/survey-fillers/survey-fillers.component";
import { SurveyFillerMappingComponent } from "./survey-experience-management/survey-filler-mapping/survey-filler-mapping.component";
import { RoundTwoReportsComponent } from "./r2-survey/round-two-reports/round-two-reports.component";
import { RoundTwoDataProcessComponent } from "./r2-survey/round-two-data-process/round-two-data-process.component";
import { SurveyLaunchDialog } from "./r2-survey/round-two-data-process/confirm-survey-dialog/survey-dialog.component";
import { NudgeSubjectComponent } from "./nudge-subject/nudge-subject.component";
import { NudgeCronsComponent } from "./nudge-crons/nudge-crons.component";
import { NudgeVaryingNamesComponent } from "./nudge-varying-names/nudge-varying-names.component";
import { NoNumbersDirective } from "../directives/noNumbers.directive";
import { NudgeAbComponent } from "./nudge-ab/nudge-ab.component";
// import { CodeMirrorModule } from 'ngx-codemirror';
const routes: Routes = [
  {
    path: "",
    component: LoginComponent,
  },
  { path: "forgot-password", component: ForgotPasswordComponent },
  { path: "reset-password/:id", component: ResetPasswordComponent },
  {
    path: "admin-dashboard",
    component: AdminDashboardComponent,
    canActivate: [RoleGuard],
    children: [
      {
        path: "companies",
        component: CorporateCompaniesComponent,
      },
      {
        path: "user-guide",
        component: UserGuideComponent,
      },
      {
        path: "corporate-companies/add",
        component: AddCompanyComponent,
      },
      {
        path: "certification-moderation",
        component: CertificationModerationComponent,
      },
      {
        path: "manager-mis",
        component: ManagerMisComponent,
      },
      {
        path: "assign-coupon",
        component: AssignCouponComponent,
      },
      // {
      //   path: "trigger",
      //   component: TriggerComponent,
      // },
      // {
      //   path: "trigger/create-message",
      //   component: CreateMessageComponent,
      // },
      // {
      //   path: "trigger/schedule-message",
      //   component: ScheduleMessageComponent,
      // },
      // {
      //   path: "trigger/message-management",
      //   component: MessageManagementComponent,
      // },
      // {
      //   path: "bu-Login",
      //   component: BuLoginComponent,
      // },
      {
        path: "logs",
        component: LogsComponent,
      },
      {
        path: "nudges",

        component: NudgeManagerComponent,
      },
      {
        path: "nudge-category",
        component: NudgeCategoryComponent,
      },
      {
        path: "nudge-ab",
        component: NudgeAbComponent,
      },
      {
        path: "nudge-subject",
        component: NudgeSubjectComponent,
      },
      {
        path: "nudge-crons",
        component: NudgeCronsComponent,
      },
      {
        path: "nudge-names",
        component: NudgeVaryingNamesComponent,
      },
      // {
      //   path: "nudges",
      //   component: NudgesComponent,
      //   children: [
      //     {
      //       path: "stats",
      //       component: NudgesStatsComponent,
      //     },
      //     {
      //       path: "active",
      //       component: NudgesActiveComponent,
      //     },
      //   ],
      // },
      // {
      //   path: "bu-Login/add-bulogin-manager/:buUserId",
      //   component: AddBuLoginManagersComponent,
      // },
      {
        path: "assign-coupon/create-coupon",
        component: CreateCouponComponent,
      },
      {
        path: "mentor",
        component: MentorComponent,
      },
      {
        path: "mentor/create-mentor",
        component: CreateMentorComponent,
      },
      {
        path: "mentor/assign-mentor/:managerId",
        component: AssignMentorComponent,
      },
      {
        path: "round2-company/:id/:name/:uuId",
        component: RoundTwoCompanyComponent,
      },
      {
        path: "round2",
        component: R2SurveyComponent,
      },
      // {
      //   path: "landing-template",
      //   component: LandingTemplateComponent,
      // },
      // {
      //   path: "landing-template/add",
      //   component: AddTemplateComponent,
      // },
      // {
      //   path: "landing-template/:_id",
      //   component: AddTemplateComponent,
      // },
      // {
      //   path: "database-dashboard",
      //   component: DatabaseDashboardComponent,
      // },
      // {
      //   path: "benchmark",
      //   component: BenchmarkComponent,
      // },
      // {
      //   path: "benchmark/create-benchmark",
      //   component: CreateBenchmarkComponent,
      // },
      // {
      //   path: "reward-requests",
      //   component: RewardRequestsComponent,
      // },
      {
        path: "research-question",
        component: ResearchQuestionComponent,
      },
      {
        path: "accomplishment-popover",
        component: AccomplishmentPopoverComponent,
      },
      {
        path: "survey-questions",
        component: SurveyQuestionsComponent,
      },
      {
        path: "survey-subthemes",
        component: SubthemeComponent,
      },
      {
        path: "master-questionnaire",
        component: MasterQuestionnaireComponent,
      },
      {
        path: "certificate-generation/:id/:name",
        component: CertificateGenerationComponent,
      },
      {
        path: "certificate-upload/:id/:name",
        component: CertificateUploadComponent,
      },
      {
        path: "leadership-dashboard",
        component: LeadershipFactoriesComponent,
      },
      {
        path: "pages",
        component: PageManagerComponent,
      },
      {
        path: "component",
        component: ComponentComponent,
      },
      {
        path: "sub-component",
        component: SubComponentComponent,
      },
      {
        path: "leadership-analytics",
        component: LeadershipAnalyticsComponent,
      },
      {
        path: "survey-experience",
        component: SurveyExperienceManagementComponent,
      },
    ],
  },
];

@NgModule({
  declarations: [
    AdminComponent,
    LoginComponent,
    NudgesComponent,
    NudgesStatsComponent,
    NudgesActiveComponent,
    AdminDashboardComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    CorporateCompaniesComponent,
    AddCompanyComponent,
    CompanyInputComponent,
    FilterPipe,
    CertificationModerationComponent,
    ManagerMisComponent,
    LocalCompanyInputComponent,
    LoaderComponent,
    AssignCouponComponent,
    // TriggerComponent,
    // CreateMessageComponent,
    // ScheduleMessageComponent,
    // MessageManagementComponent,
    // BuLoginComponent,
    // AddBuLoginManagersComponent,
    LogsComponent,
    MentorComponent,
    CreateMentorComponent,
    AssignMentorComponent,
    CreateCouponComponent,
    RoundTwoCompanyComponent,
    R2SurveyComponent,
    // AddTemplateComponent,
    // LandingTemplateComponent,
    // PreviewTemplateComponent,
    // DatabaseDashboardComponent,
    // BenchmarkComponent,
    // CreateBenchmarkComponent,
    // RewardRequestsComponent,
    SafeHtmlPipe,
    UserGuideComponent,
    // HotspotComponent,
    ResearchQuestionComponent,
    AccomplishmentPopoverComponent,
    SurveyQuestionsComponent,
    SubthemeComponent,
    PageManagerComponent,
    MasterQuestionnaireComponent,
    CertificateGenerationComponent,
    SortbyPipe,
    RoundOffPipe,
    SchduleSendCertificatesComponent,
    LeadershipFactoriesComponent,
    R2ExtensionComponent,
    NudgeManagerComponent,
    ComponentComponent,
    SubComponentComponent,
    CertificateUploadComponent,
    LeadershipAnalyticsComponent,
    NudgeCategoryComponent,
    SurveyExperienceManagementComponent,
    SurveyDialogComponent,
    SurveyFillersComponent,
    SurveyFillerMappingComponent,
    RoundTwoReportsComponent,
    RoundTwoDataProcessComponent,
    SurveyLaunchDialog,
    NudgeSubjectComponent,
    NoSpacesDirective,
    NoNumbersDirective,
    NudgeCronsComponent,
    NudgeAbComponent,
    NudgeVaryingNamesComponent,
  ],
  imports: [
    CommonModule,
    NgxEditorModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    MaterialModule,
    DataTablesModule,
    MatTabsModule,
    CdkTableModule,
    MatIconModule,
    MatSortModule,
    TagInputModule,
    MatTableModule,
    // RlTagInputModule,
    BsDatepickerModule,
    MatPaginatorModule,
    ModalModule.forRoot(),
    SelectDropDownModule,
    // CodemirrorModule,
    // CodeMirrorModule,
    MatNativeDateModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatCheckboxModule,
    NgxPaginationModule,
    AngularMultiSelectModule,
    DragDropModule,
    ImageCropperModule,
    AngularEditorModule,
    ColorPickerModule,
    NgbModule,
    ClipboardModule,
  ],
  exports: [ColorPickerModule],
  providers: [
    SurveyEngineService,
    BsModalService,
    AdminServices,
    DecimalPipe,
    DatePipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AdminModule {}
