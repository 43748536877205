<div class="page-wrapper">
  <div class="col-md-12 text-right mb-5">
    <div
      style="
        display: flex;
        justify-content: end;
        margin-bottom: 12px;
        gap: 12px;
      "
    >
      <button
        class="btn btn-secondary btn-newuser-clr"
        (click)="openAddNudgeModal(addNudge, 'default')"
      >
        Add New Nudge
      </button>
    </div>
  </div>
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="ID">
      <th mat-header-cell *matHeaderCellDef>ID</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>

    <ng-container matColumnDef="Title">
      <th mat-header-cell *matHeaderCellDef>Title</th>
      <td mat-cell *matCellDef="let element">
        {{
          element.title.length > 20
            ? (element.title | slice : 0 : 20) + "..."
            : element.title
        }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Description">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let element">
        {{
          element.description.length > 20
            ? (element.description | slice : 0 : 20) + "..."
            : element.description
        }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Subject">
      <th mat-header-cell *matHeaderCellDef>Email Subject</th>
      <td mat-cell *matCellDef="let element">
        {{
          element.subject.length > 20
            ? (element.subject | slice : 0 : 20) + "..."
            : element.subject
        }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Image">
      <th mat-header-cell *matHeaderCellDef>Image</th>
      <td mat-cell *matCellDef="let element">
        <img style="width: 50px" [src]="element.image" alt="" />
      </td>
    </ng-container>

    <ng-container matColumnDef="Category">
      <th mat-header-cell *matHeaderCellDef>Category</th>
      <td mat-cell *matCellDef="let element">{{ element.category }}</td>
    </ng-container>

    <ng-container matColumnDef="Category Id">
      <th mat-header-cell *matHeaderCellDef>Category Id</th>
      <td mat-cell *matCellDef="let element">{{ element.category_id }}</td>
    </ng-container>

    <ng-container matColumnDef="Acted Count">
      <th mat-header-cell *matHeaderCellDef>Acted Count</th>
      <td mat-cell *matCellDef="let element">{{ element.acted_count }}</td>
    </ng-container>

    <ng-container matColumnDef="Status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">{{ element.status }}</td>
    </ng-container>
    <ng-container matColumnDef="Edit">
      <th mat-header-cell *matHeaderCellDef>Edit</th>
      <td mat-cell *matCellDef="let element">
        <i
          (click)="openEditNudgeModal(addNudge, element)"
          matTooltip="Edit Nudge"
          matTooltipPosition="above"
          class="fa fa-edit"
          style="cursor: pointer; margin-left: 15px"
        ></i>
      </td>
    </ng-container>
    <!-- <ng-container matColumnDef="Delete">
            <th mat-header-cell *matHeaderCellDef>Delete</th>
            <td mat-cell *matCellDef="let element"><i matTooltip="Delete Nudge" matTooltipPosition="above"
                    class="fa fa-solid fa-trash" style="cursor: pointer; margin-left: 15px"></i>
            </td>
        </ng-container> -->

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns; let i = index"
    ></tr>
  </table>

  <mat-paginator
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
    aria-label="Select page of periodic elements"
  >
  </mat-paginator>
</div>

<ng-template #addNudge>
  <div class="modal-header">
    <h4 style="font-size: 22px" class="modal-title pull-left">
      {{ isEdit ? "Edit Nudge" : "Add Nudge" }}
    </h4>
    <button
      type="button "
      class="close pull-right"
      aria-label="Close "
      (click)="hideEditModal()"
    >
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="form" [formGroup]="nudgeDataForm">
      <div class="parent">
        <div class="child">
          <label for="title">Title:<small class="text-danger">*</small> </label>
          <input
            formControlName="title"
            required
            type="text"
            placeholder="Enter title"
          />
        </div>
        <div
          class="child"
          *ngIf="nudgeDataForm.controls['title'].errors?.['maxlength']"
        >
          <small class="text-danger">
            Title cannot exceed 200 characters.
          </small>
        </div>
        <div class="child">
          <label for="description"
            >Description:<small class="text-danger">*</small>
          </label>
          <textarea
            style="width: 85%"
            formControlName="description"
            required
            type="text"
            placeholder="Enter description"
          ></textarea>
        </div>
        <div class="child">
          <label for="subject"
            >Subject:<small class="text-danger">*</small>
          </label>
          <input
            formControlName="subject"
            required
            type="text"
            placeholder="Enter subject"
          />
        </div>
        <div
          class="child"
          *ngIf="nudgeDataForm.controls['subject'].errors?.['maxlength']"
        >
          <small class="text-danger">
            Subject cannot exceed 100 characters.
          </small>
        </div>
        <h3 *ngIf="isEdit">
          Default Subject : (Today's personalized Great Manager recommendation)
          <i
            (click)="copyText()"
            matTooltip="Click to copy subject"
            matTooltipPosition="above"
            style="cursor: pointer"
            class="fa fa-solid fa-copy"
          ></i>
        </h3>

        <div class="grandchild">
          <div class="child">
            <label for="characterUrl">
              Nudge Image:<small class="text-danger">*</small>
            </label>
            <input
              id="fileSelect"
              style="width: 400px !important"
              type="file"
              name="myImage"
              accept="image/x-png,image/gif,image/jpeg"
              (change)="fileChangeEvent($event)"
            />
            <input *ngIf="isEdit" type="text" [value]="fileName" readonly />
            <label for="nudge_category_id"
              >Nudge Topics:<small class="text-danger">*</small>
            </label>
            <select
              class="form-control custom-form-control"
              formControlName="nudge_category_id"
              required
            >
              <option *ngFor="let category of categories" [value]="category.id">
                {{ category.title }}
              </option>
            </select>
          </div>
          <h3 style="margin: 0 !important">
            Please upload the image with 1920X1080 dimention
          </h3>
        </div>

        <div class="child">
          <div class="child">
            <label for="is_active">Nudge Status: </label>
            <label class="switch">
              <input formControlName="is_active" type="checkbox" />
              <span class="slider round"></span>
            </label>
          </div>
          <div class="child">
            <label for="sub_theme_id"
              >Choose Nudge Subtheme:<small class="text-danger">*</small>
            </label>
            <select
              class="form-control custom-form-control"
              formControlName="sub_theme_id"
              required
            >
              <option
                *ngFor="let subtheme of subthemes"
                [value]="subtheme.subthemeId"
              >
                {{ subtheme.subtheme }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <button
        class="btn btn-secondary btn-newuser-clr"
        type="button"
        (click)="openConfirmation()"
        [disabled]="nudgeDataForm.invalid"
      >
        {{ isEdit ? "Update" : "Submit" }}
      </button>
      <br />
      <p
        class="text-danger text-center m-2"
        *ngIf="nudgeDataForm.touched && nudgeDataForm.invalid"
      >
        Please Fill All Mandatory Fields
      </p>
    </form>
  </div>
</ng-template>
