import { Component, OnInit, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "app-google-recaptcha",
  templateUrl: "./google-recaptcha.component.html",
  styleUrls: ["./google-recaptcha.component.css"],
})
export class GoogleRecaptchaComponent implements OnInit {
  isVerifiedCaptcha: boolean = false;
  captchaResponse: String = "";
  @Output() onSuccess = new EventEmitter<string>();
  @Output() onError = new EventEmitter<string>();
  constructor() {}

  ngOnInit() {}

  resolved(captchaResponse: string) {
    this.onSuccess.emit(captchaResponse);    
  }

  errored() {
    this.onError.emit("Error Occured");    
  }

  resetCapcha() {
    grecaptcha.reset();
    this.isVerifiedCaptcha = false;
  }
}
