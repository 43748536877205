<div class="page-wrapper">
  <nav mat-tab-nav-bar>
    <div
      mat-tab-link
      *ngFor="let link of navLinks"
      [routerLink]="link.link"
      routerLinkActive
      #rla="routerLinkActive"
      [active]="rla.isActive"
    >
      {{ link.label }}
    </div>
  </nav>
  <router-outlet></router-outlet>
</div>
