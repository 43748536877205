import { AdminServices } from "../admin.service";
import { Router } from "@angular/router";
import { DataSource } from "@angular/cdk/table";
import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
// import 'rxjs/add/observable/of';
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import { environment } from "../../../environments/environment";
// import io from "socket.io-client";
import io from "socket.io-client";
import { formatDate } from "@angular/common";
import { companyTypes, validateEmail, constants } from "../../constants";
import { FormGroup } from "@angular/forms";
import { switchMap } from "rxjs-compat/operators/switchMap";
import { NgxDropdownConfig } from "ngx-select-dropdown";

@Component({
  selector: "app-corporate-companies",
  templateUrl: "./corporate-companies.component.html",
  styleUrls: ["./corporate-companies.component.css"],
})
export class CorporateCompaniesComponent implements OnInit {
  selectedSurveyTemplate: number;
  surveyTemplate: any;
  featureExists: boolean = false;
  industryTypeArray = [
    { name: "BFSI" },
    { name: "Hospitality" },
    { name: "Infrastructure & Real Estate" },
    { name: "Electronics" },
    { name: "Consulting" },
    { name: "Internet & Software" },
    { name: "Automotive" },
    { name: "Healthcare & Pharma" },
    { name: "Education" },
    { name: "Retail" },
    { name: "Media & Entertainment" },
    { name: "Manufacturing" },
    { name: "Social" },
    { name: "Logistics" },
    { name: "FMCG" },
    { name: "Legal" },
    { name: "Others" },
  ];
  industrySubTypeArray = [];
  packageArray = [
    { name: "Free" },
    { name: "Basic" },
    { name: "Premium" },
    { name: "Special" },
  ];
  managerArray = [{ name: "manager1" }, { name: "manager2" }];
  employeeArray = [{ name: "employee1" }, { name: "employee2" }];
  data: any;
  data1: any;
  private serverUrl = environment.apiURl;
  // private  socket = io.connect();

  private socket = io(this.serverUrl, { reconnection: true });
  revokeDate: any;
  parentMessage: any;
  companyData: any;
  addCompanyName: any;
  batchName: string;
  addCompanyId: any;
  modalRef: BsModalRef;
  addName: any;
  addEmail: any;
  isEmailValid: any;
  isGPMSMailValid: any;
  addGender: any = "";
  checkedItem = {};
  addMobile: any;
  industryType: string = "";
  sector: string = "";
  industryGroup: string = "";
  industry: string = "";
  subIndustry: string = "";
  uniqueSectors = [];
  uniqueIndustryGroups = [];
  uniqueIndustries = [];
  uniqueSubIndustries = [];
  industryGroups = [];
  industries = [];
  subIndustries = [];
  companyNameFromChild: string = "";
  companyId;
  newCompanyNameFromChild: string = "";
  emailStatus: any;
  gpmsEmailStatus: any;
  companyName: any;
  mobile: any;
  editName: any;
  editCompanyId: any;
  editEmail: any;
  editCompanyName: any;
  editMobile: any;
  editGender: any;
  editBatchName: any;
  id: any;
  p: number = 1;
  industrySubType: any = "";
  line1: any;
  line2: any;
  pinCode: any;
  city: any;
  state: any;
  websiteUrl: any;
  accessUrl: any;
  kpName: any;
  kpEmail: any;
  kpPhone: any;
  kpUserType: any;
  errorText = "";
  sendResponseRateEmail: any;
  sendSasEmail: any;
  kpName2: any;
  kpEmail2: any;
  kpPhone2: any;
  loading: boolean;
  websiteUrlStatus: any;
  currentCompanyId: any;
  package: any;
  textInput: boolean;
  invoice: any;
  confipageTable: any;
  surveyEngineCompanyId;
  country: any;
  premium: number = 0;
  special: number = 0;
  basic: number = 0;
  custom: number = 0;
  remainingPremium: number = 0;
  remainingSpecial: number = 0;
  remainingBasic: number = 0;
  remainingCustom: number = 0;
  surveydataSource = [];
  showSocketData = false;
  isWebsiteValid: boolean = false;
  displayedColumns = [
    "companyName",
    "websiteUrl",
    "createdAt",
    "actions",
    "uploadCertificate",
    "generateCertificate",
  ];
  gender = [{ value: "Male" }, { value: "Female" }];
  dataSource = new MatTableDataSource();
  moduleNames = [];
  selectedModules = [];
  allCompanySurveys = [];
  totalNumberOfSurveys: number = 0;
  leftNumberOfSurvey: number = 0;
  DEFAULT_COMPANYTYPE: string = "B2B(Hi-Touch)";
  userAccess: Array<[]> = [];
  selectedAccess: Array<[]> = [];
  editUserAccess: Array<[]> = [];
  companySpecific: any;
  imageSrc: any;
  fileName: any;
  iconSize: number;
  isIconValid: boolean;
  iconSizeStatus: string;
  iconAddFromDB: string;
  isCompanyGPMS: boolean;
  gpmsValue: boolean = false;
  hrName: any;
  hrEmail: any;
  companyType: string;
  selectedCompanyType: string = this.DEFAULT_COMPANYTYPE;
  companyTypes: any = companyTypes;
  batchArray: Array<[]> = [];
  batchSelected: Array<[]> = [];
  companyGroupsFromDB: Array<[]> = [];

  accessArray = [
    { name: "Survey Page", value: "survey" },
    { name: "Launch Survey (Survey Page)", value: "launch_survey" },
    { name: "GPMS Report (Survey Page)", value: "gpms_report" },
    { name: "Reports Page", value: "report" },
    { name: "L&D Reports (Reports Page)", value: "l_d_report" },
    { name: "Summaries Report (Reports Page)", value: "summaries_report" },
    { name: "Survey Reports (Reports Page)", value: "survey_report" },
    { name: "Action Plan Reports (Reports Page)", value: "ap_report" },
    { name: "PIS Upload (Dashboard Page)", value: "pis" },
    { name: "Action Plan Review (GMIFIT)", value: "ap_review" },
    { name: "Generate Org Report for GPMS(Survey Page)", value: "org_report" },
    { name: "Leadership Analytics Dashboard", value: "leadership_dashboard" },
    { name: "GMC Criteria", value: "gmc" },
    { name: "Nudges", value: "nudges" },
  ];
  editBatchArray = [];
  optionsArray = [];
  config: NgxDropdownConfig = {
    displayKey: "moduleName",
    search: true,
    height: "auto",
    placeholder: "Select modules",
    // customComparator: () => {},
    limitTo: this.moduleNames.length,
    moreText: "more",
    noResultsFound: "No results found!",
    searchPlaceholder: "Search",
    searchOnKey: "moduleName",
    customComparator: function (a: any, b: any): number {
      if (a?.name < b?.name) {
        return -1;
      }
      if (a?.name > b?.name) {
        return 1;
      }
      return 0;
      // throw new Error('Function not implemented.');
    },
    clearOnSelection: false,
    inputDirection: "",
  };
  configAccess: NgxDropdownConfig = {
    displayKey: "name",
    search: true,
    height: "auto",
    placeholder: "Select Access",
    // customComparator: () => {},
    limitTo: this.accessArray.length,
    moreText: "more",
    noResultsFound: "No results found!",
    searchPlaceholder: "Search",
    searchOnKey: "name",
    customComparator: function (a: any, b: any): number {
      if (a?.name < b?.name) {
        return -1;
      }
      if (a?.name > b?.name) {
        return 1;
      }
      return 0;
      // throw new Error('Function not implemented.');
    },
    clearOnSelection: false,
    inputDirection: "",
  };
  configTable = {
    itemsPerPage: 4,
    currentPage: 1,
    totalItems: this.surveydataSource ? this.surveydataSource.length : 0,
  };

  configBatch: NgxDropdownConfig = {
    displayKey: "batchName",
    search: true,
    height: "auto",
    placeholder: "Select Batch",
    // customComparator: () => {},
    limitTo: this.batchArray.length,
    moreText: "more",
    noResultsFound: "No results found!",
    searchPlaceholder: "Search",
    searchOnKey: "batchName",
    customComparator: function (a: any, b: any): number {
      if (a?.name < b?.name) {
        return -1;
      }
      if (a?.name > b?.name) {
        return 1;
      }
      return 0;
      // throw new Error('Function not implemented.');
    },
    clearOnSelection: false,
    inputDirection: "",
  };
  keyPersonArray = [];
  public autoHide: boolean = true;
  public responsive: boolean = true;
  showZoho: boolean = true;

  showGroupsAndStatements: boolean = false;
  form: FormGroup;
  groups: { groupName: string; groupOrder: number }[] = [
    { groupName: "", groupOrder: 1 },
  ];
  questionsFromDB = [];
  questionsCount: number;
  groupsSaved: boolean = false;
  selectedGroupName: string = "";
  allDataPresentForGroupsAndStatements = false;
  selectedItems: any = [];
  templateFromDb: number;

  gmcCriteria: any = {
    actionPlanMin: "",
    implementationMin: "",
    learningDevelopmentMin: "",
  };
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private router: Router,
    private adminService: AdminServices,
    private modalService: BsModalService,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.loading = true;
    this.getMasterQuestionair();
    this.socket.on("TOTAL_SURVEYS_FETCH", (data) => {
      this.totalNumberOfSurveys = data;
    });
    this.socket.on("SURVEY_RAW_DATA_FETCHING", (data) => {
      this.leftNumberOfSurvey = data;
    });
    this.adminService.getCompanies().subscribe((res) => {
      this.companyData = res.data;
      this.companyData.forEach((companies) => {
        let d = new Date(companies.createdAt);
        let year = d.getFullYear();
        let month = d.getMonth();
        let day = d.getDate();
        let dateFormat = "";
        dateFormat = `${day}/${month + 1}/${year}`;
        companies.creationDate = dateFormat;
      });
      this.dataSource.data = this.companyData;
      this.loading = false;
    });
    this.getIndustries();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  getHeight() {
    return { height: window.innerHeight + "px" };
  }
  goToRound2(company) {
    this.router.navigate([
      "/admin/admin-dashboard/round2-company/" +
        company.surveyEngineCompanyId +
        "/" +
        company.companyName +
        "/" +
        company._id,
    ]);
  }

  generateCertitifcate(companyDetails) {
    this.router.navigate([
      "/admin/admin-dashboard/certificate-generation/" +
        companyDetails.surveyEngineCompanyId +
        "/" +
        companyDetails.companyName,
    ]);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  // to search in table
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  // to get company name from other component
  getCompanyOutputValFromChild(selected: string) {
    if (selected) {
      this.companyNameFromChild = selected;
      this.newCompanyNameFromChild = "";
    }
  }

  // to check either company already exist or not
  gettIsNewCompanyFromParent(isNew: boolean) {
    if (isNew) {
      this.newCompanyNameFromChild = this.companyNameFromChild;
    }
  }

  // to add new company
  openAddModal(template: TemplateRef<any>, addCompanyName, addCompanyId) {
    const config = { class: "modal-lg" };
    this.addCompanyName = addCompanyName;
    this.addCompanyId = addCompanyId;
    this.getAdminList(addCompanyId);
    this.modalRef = this.modalService.show(template, config);
    this.adminService.addModal(this.modalRef);
  }
  //to edit company admin
  openEditCompanyAdminModal(template: TemplateRef<any>, data) {
    console.warn("data", data);
    this.modalRef.hide();
    const config = { class: "modal-lg" };
    this.id = data._id;
    this.editCompanyName = data.company;
    this.editName = data.name;
    this.editEmail = data.email;
    this.editMobile = data.mobile;
    this.editGender = data.gender;
    this.editBatchName = data.batchName;
    this.editBatchArray = this.batchArray.filter((batch) => {
      return !data.batchName?.some((newBatch) => {
        return batch == newBatch;
      });
    });
    this.selectedAccess = data.access;
    this.optionsArray = this.accessArray.filter((o1) => {
      return !data.access.some((o2) => {
        return o1.name == o2.name;
      });
    });
    this.modalRef = this.modalService.show(template, config);
    this.adminService.addModal(this.modalRef);
  }
  //to send emails with company login credentials
  sendEmailCompanyLogin() {
    this.loading = true;
    let editedAdminDetails = {
      _id: this.id,
      gender: this.editGender,
      mobile: this.editMobile,
      accessArray: this.selectedAccess,
      batchName: this.editBatchName,
      sendMailAgain: true,
    };
    this.adminService
      .editCompanyAdmin(editedAdminDetails)
      .subscribe((response: any) => {
        if (response.status) {
          this.loading = false;
          Swal.fire({
            title: "Admin edit",
            text: "Admin edited successfully and mail sent",
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
        } else {
          this.loading = false;
          Swal.fire({
            title: "Admin edit",
            text: "Admin not edited",
            icon: "error",
            showConfirmButton: false,
            timer: 2500,
          });
        }
      });
    this.getAdminList(this.addCompanyId);
  }

  //All groups code
  surveyGroupStatus(event) {
    this.showGroupsAndStatements = event.checked;
  }

  addGroup(groupName: string, groupOrder: number) {
    const newGroup = {
      groupName: groupName,
      groupOrder: this.groups.length + groupOrder,
    };
    this.groups.push(newGroup);
  }

  removeGroup(index: number) {
    this.groups.splice(index, 1);
  }
  isSaveButtonDisabled(): boolean {
    return this.groups.some((group) => !group.groupName || !group.groupOrder);
  }
  showGroupAndAssignMentPopup(template: TemplateRef<any>) {
    const config = { class: "modal-lg custom-modal" };
    this.modalRef = this.modalService.show(template, config);
    this.adminService.addModal(this.modalRef);
  }

  saveGroupsInDb() {
    this.adminService
      .getDataToAssignStatements({
        selectedSurveyTemplate: this.selectedSurveyTemplate,
      })
      .subscribe((response: any) => {
        if (response.status) {
          this.questionsFromDB = response.data;
          this.questionsCount = this.questionsFromDB.length;
          this.groupsSaved = true;
          this.selectedGroupName = this.groups[0].groupName;
        }
      });

    this.modalService._hideModal(2);
  }

  saveAll() {
    this.allDataPresentForGroupsAndStatements = true;
    this.modalService._hideModal(2);
  }

  get availableQuestions() {
    return this.questionsFromDB.filter(
      (question) =>
        !this.selectedItems.some(
          (selectedItem: any) =>
            selectedItem.question.questionId === question.questionId
        )
    );
  }

  getQuestionTextById(questionId: number): string {
    const question = this.questionsFromDB.find(
      (q) => q.questionId == questionId
    );
    return question ? question.questionText : "";
  }

  getQuestionSubthemeById(questionId: number): string {
    const question = this.questionsFromDB.find(
      (q) => q.questionId == questionId
    );
    return question ? question.subTheme : "";
  }

  toggleItem(questionId: number, groupName: string) {
    const question = this.questionsFromDB.find(
      (q) => q.questionId == questionId
    );

    const selectedItemIndex = this.selectedItems.findIndex(
      (item: any) =>
        item.question.questionId == questionId && item.groupName == groupName
    );
    if (selectedItemIndex != -1) {
      this.selectedItems.splice(selectedItemIndex, 1);
    } else {
      this.selectedItems.push({ question, groupName });
    }
  }

  // to add more package
  openUpdatePackageModal(template: TemplateRef<any>, addCompanyId) {
    this.remainingBasic = 0;
    this.remainingPremium = 0;
    this.remainingSpecial = 0;
    this.remainingCustom = 0;
    const config = { class: "modal-lg" };
    this.addCompanyId = addCompanyId;
    this.adminService
      .getCompanyData(addCompanyId)
      .subscribe((response: any) => {
        response.data = response.data;
        if (response?.packageDataFromSE) {
          console.log(response?.packageDataFromSE);
          let packages = response?.packageDataFromSE;
          this.remainingBasic = packages?.basic || 0;
          this.remainingSpecial = packages?.special || 0;
          this.remainingPremium = packages?.premium || 0;
          this.remainingCustom = packages?.custom || 0;
        } else {
          response.data?.package?.forEach((packages) => {
            for (let key in packages) {
              switch (key) {
                case "basic":
                  this.remainingBasic = packages[key] + this.remainingBasic;
                  break;
                case "special":
                  this.remainingSpecial = packages[key] + this.remainingSpecial;
                  break;
                case "premium":
                  this.remainingPremium = packages[key] + this.remainingPremium;
                  break;
                case "custom":
                  this.remainingCustom = packages[key] + this.remainingCustom;
                  break;
                default:
                  break;
              }
            }
          });
        }
      });
    this.modalRef = this.modalService.show(template);
    this.adminService.addModal(this.modalRef);
  }

  // to edit company data
  openEditModal(template: TemplateRef<any>, editCompanyId) {
    this.selectedGroupName = "";
    this.groups = [{ groupName: "", groupOrder: 1 }];
    this.questionsFromDB = [];
    this.questionsCount = 0;
    this.groupsSaved = false;
    this.selectedItems = [];
    this.allDataPresentForGroupsAndStatements = false;
    this.showGroupsAndStatements = false;
    this.showZoho = true;

    const config = { class: "modal-lg" };
    this.keyPersonArray = [];
    this.adminService
      .getCompanyData(editCompanyId)
      .pipe(
        switchMap((response: any) => {
          //If "profile_overview_v2" feature present into database then only we show the option to add custom groups
          this.featureExists =
            response.data.selectedFeatures.filter(
              (feature) => feature.type == "profile_overview_v2"
            ).length > 0
              ? true
              : false;
          this.companyGroupsFromDB = response.groups;
          this.companyName = response.data.companyName;
          this.selectedSurveyTemplate =
            response.data.surveyMasterQuestionnaireId;
          this.templateFromDb = response.data.surveyMasterQuestionnaireId;
          this.industryType = response.data.industryType;
          this.industrySubType = response.data.industrySubType;
          this.sector = response.data.sector
            ? response.data.sector
            : this.sector;
          if (this.sector) {
            this.filterData("sectors", this.sector);
          }
          this.industryGroup = response.data.industryGroup
            ? response.data.industryGroup
            : this.industryGroup;
          if (this.industryGroup) {
            this.filterData("industryGroups", this.industryGroup);
          }
          this.industry = response.data.industry
            ? response.data.industry
            : this.industry;
          if (this.industry) {
            this.filterData("industries", this.industry);
          }
          this.subIndustry = response.data.subIndustry
            ? response.data.subIndustry
            : this.subIndustry;
          this.sendResponseRateEmail =
            response.data.companySettings.sendResponseRateEmail;
          this.sendSasEmail = response.data.companySettings.sendSasEmail;
          this.websiteUrl = response.data.websiteUrl;
          this.accessUrl = response.data.accessUrl;
          this.package = response.data.package;
          this.surveyEngineCompanyId = response.data.surveyEngineCompanyId;
          this.companySpecific = response.data.companySpecific;
          this.iconAddFromDB =
            response.data.companySpecific &&
            response.data.companySpecific.companyLogo
              ? constants.IMAGE_URL + response.data.companySpecific.companyLogo
              : "";

          this.hrEmail = response.data.contactDetails
            ? response.data.contactDetails.hrEmail
            : "";
          if (this.hrEmail != "" && response.data.companyType)
            this.isGPMSMailValid = true;

          this.hrName = response.data.contactDetails
            ? response.data.contactDetails.hrName
            : "";

          this.isCompanyGPMS = response.data.contactDetails ? true : false;
          let keyperson = response.data.keyPerson;
          keyperson.forEach((element) => {
            this.keyPersonArray.push(element);
          });
          this.line1 = response.data.address && response.data.address.line1;
          this.line2 = response.data.address && response.data.address.line2;
          this.pinCode = response.data.address && response.data.address.pinCode;
          this.city = response.data.address && response.data.address.city;
          this.state = response.data.address && response.data.address.state;
          this.country = response.data.address && response.data.address.country;
          this.isIconValid = false;
          this.selectedCompanyType = response.data.companyType
            ? response.data.companyType
            : this.DEFAULT_COMPANYTYPE;
          this.gpmsValue =
            response.data.companyType && response.data.companyType == "GPMS"
              ? true
              : false;

          this.gmcCriteria.actionPlanMin =
            response.data.companySettings.gmcCriteria &&
            response.data.companySettings.gmcCriteria.actionPlanMin
              ? response.data.companySettings.gmcCriteria.actionPlanMin
              : "";
          this.gmcCriteria.implementationMin =
            response.data.companySettings.gmcCriteria &&
            response.data.companySettings.gmcCriteria.implementationMin
              ? response.data.companySettings.gmcCriteria.implementationMin
              : "";
          this.gmcCriteria.learningDevelopmentMin =
            response.data.companySettings.gmcCriteria &&
            response.data.companySettings.gmcCriteria.learningDevelopmentMin
              ? response.data.companySettings.gmcCriteria.learningDevelopmentMin
              : "";
          this.modalRef = this.modalService.show(template, config);
          this.adminService.addModal(this.modalRef);
          const seCompanyId = response.data.surveyEngineCompanyId;
          return this.adminService.getCompanyComponentMapping(seCompanyId);
        })
      )
      .subscribe(
        (response: any) => {
          if (response && response.status !== "null") {
            const [company] = response.data;
            if (company?.id) {
              this.showZoho = company.status == 1 ? true : false;
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getMasterQuestionair() {
    this.adminService.getMasterQuestionnaires().subscribe({
      next: (res: any) => {
        if (res.status) {
          this.surveyTemplate = res.data;
        }
      },
    });
  }
  // to get admin list
  getAdminList(addCompanyId) {
    this.adminService.getCompanyAdmin(addCompanyId).subscribe((response) => {
      this.data = response.data;
      this.batchArray = response.batchData;
      this.batchArray = this.batchArray.filter((item) => item !== null);
      console.log(this.batchArray);
    });
  }

  // to add new admin to the company
  addAdmin() {
    this.loading = true;
    var adminDetails = {
      name: this.addName,
      email: this.addEmail,
      role: "company",
      status: true,
      gender: this.addGender,
      company: this.addCompanyName,
      mobile: this.addMobile,
      seCompanyId: this.addCompanyId,
      accessArray: this.userAccess,
      batchName: this.batchSelected,
    };
    this.adminService
      .addCompanyAdmin(adminDetails)
      .subscribe((response: any) => {
        if (response.status) {
          this.loading = false;
          this.batchSelected = [];
          Swal.fire({
            title: "Admin add",
            text: "Admin added successfully",
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.ngOnInit();
        } else {
          this.loading = false;
          Swal.fire({
            title: "Admin add",
            text: "Admin not added",
            icon: "error",
            showConfirmButton: false,
            timer: 2500,
          });
        }
      });
    this.getAdminList(this.addCompanyId);
  }
  editCompanyAdmin() {
    this.loading = true;
    let editedAdminDetails = {
      _id: this.id,
      gender: this.editGender,
      mobile: this.editMobile,
      accessArray: this.selectedAccess,
      batchName: this.editBatchName,
    };
    this.adminService
      .editCompanyAdmin(editedAdminDetails)
      .subscribe((response: any) => {
        if (response.status) {
          this.loading = false;
          this.editBatchName = [];
          Swal.fire({
            title: "Admin edit",
            text: "Admin edited successfully",
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
        } else {
          this.loading = false;
          Swal.fire({
            title: "Admin edit",
            text: "Admin not edited",
            icon: "error",
            showConfirmButton: false,
            timer: 2500,
          });
        }
      });
    this.getAdminList(this.addCompanyId);
  }

  // to edit selected company
  onEditCompany() {
    this.loading = true;
    if (this.keyPersonArray.length < 2) {
      if (this.kpName != "" && this.kpEmail != "" && this.kpPhone != "") {
        this.keyPersonArray.push({
          name: this.kpName,
          email: this.kpEmail,
          mobile: this.kpPhone,
        });
      }
    }
    (this.kpName = ""), (this.kpEmail = ""), (this.kpPhone = "");

    if (this.imageSrc) {
      let iconObject = {
        fileString: this.imageSrc,
        fileName: this.fileName,
        companyName: this.companyName,
      };
      if (this.iconSize < 524288) {
        this.adminService.uploadIcon(iconObject).subscribe((response) => {
          if (response.status) {
            this.imageSrc = "";
            let iconAddress = response.imageUrl;
            iconAddress = iconAddress.replace(
              "https://s3.ap-south-1.amazonaws.com/s3.gmi/",
              ""
            );
            this.sendEditCompanyData(iconAddress);
          }
        });
      } else {
        Swal.fire({
          title: "Image Size",
          text: "File size should not be greater than 500kb",
          icon: "error",
          showConfirmButton: true,
          timer: 20000,
        });
        this.imageSrc = "";
        this.isIconValid = false;
      }
    } else {
      let iconAddress = this.iconAddFromDB;
      iconAddress =
        iconAddress && iconAddress.length > 0
          ? iconAddress.replace(constants.IMAGE_URL, "")
          : null;
      this.sendEditCompanyData(iconAddress);
    }
  }

  resetGroupAndStatementsModal() {
    this.selectedGroupName = "";
    this.groups = [{ groupName: "", groupOrder: 1 }];
    this.questionsFromDB = [];
    this.questionsCount = 0;
    this.groupsSaved = false;
    this.selectedItems = [];
    this.allDataPresentForGroupsAndStatements = false;
    this.showGroupsAndStatements = false;
  }

  sendEditCompanyData(iconAddress) {
    Swal.fire({
      title: "Are you sure?",
      text: "Old access url will be discarded",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        const {
          selectedSector,
          selectedIndustryGroup,
          selectedIndustry,
          selectedSubIndustry,
        } = this.getSectorIndustriesId();

        let companyDetails = {
          sector: this.sector,
          sectorId: selectedSector.sector_id,
          industryGroupsId: selectedIndustryGroup.industry_groups_id,
          industryId: selectedIndustry.industries_id,
          subIndustriesId: selectedSubIndustry.sub_industries_id,
          industryGroup: this.industryGroup,
          industry: this.industry,
          subIndustry: this.subIndustry,
          industryType: this.industryType,
          industrySubType: this.industrySubType,
          websiteUrl: this.websiteUrl,
          accessUrl: this.accessUrl,
          package: this.package,
          line1: this.line1,
          line2: this.line2,
          pinCode: this.pinCode,
          city: this.city,
          state: this.state,
          country: this.country,
          surveyEngineCompanyId: this.surveyEngineCompanyId,
          surveyMasterQuestionnaireId: this.selectedSurveyTemplate,
          companySpecific: {
            companyLogo: iconAddress,
          },
          companySettings: {
            sendResponseRateEmail: this.sendResponseRateEmail,
            sendSasEmail: this.sendSasEmail,
            gmcCriteria: {
              ...(this.gmcCriteria.actionPlanMin != "" && {
                actionPlanMin: this.gmcCriteria.actionPlanMin,
              }),
              ...(this.gmcCriteria.implementationMin != "" && {
                implementationMin: this.gmcCriteria.implementationMin,
              }),
              ...(this.gmcCriteria.learningDevelopmentMin != "" && {
                learningDevelopmentMin: this.gmcCriteria.learningDevelopmentMin,
              }),
            },
          },
          contactDetails:
            this.isCompanyGPMS || this.gpmsValue
              ? { hrName: this.hrName, hrEmail: this.hrEmail }
              : null,
          companyType: this.companyType,
          keyPerson: this.keyPersonArray,
          allDataPresentForGroupsAndStatements:
            this.allDataPresentForGroupsAndStatements,
          dataForGroupsAndStatements: {
            groups: this.groups,
            requiredData: this.selectedItems,
          },
        };
        companyDetails["isZohoScript"] = this.showZoho;
        this.adminService
          .updateCompanyData(companyDetails)
          .subscribe((response: any) => {
            this.loading = false;
            Swal.fire({
              title: "Company update",
              text: response.status
                ? "Company details updated"
                : "Company details not updated",
              icon: response.status ? "success" : "error",
              showConfirmButton: false,
              timer: 2500,
            });
            this.modalRef.hide();
          });
      }
    });
  }

  getSectorIndustriesId() {
    const selectedSector = this.uniqueSectors.find(
      (industryType) => industryType.sectors == this.sector
    );
    const selectedIndustryGroup = this.industryGroups.find(
      (industryType) => industryType.industry_groups == this.industryGroup
    );
    const selectedIndustry = this.industries.find(
      (industryType) => industryType.industries == this.industry
    );
    const selectedSubIndustry = this.subIndustries.find(
      (industryType) => industryType.sub_industries == this.subIndustry
    );
    return {
      selectedSector,
      selectedIndustryGroup,
      selectedIndustry,
      selectedSubIndustry,
    };
  }

  getIndustries() {
    this.adminService.getIndustries().subscribe((res) => {
      if (res.status) {
        res.data.forEach((item) => {
          // Check if the sector is not already in the uniqueSectors array
          if (
            !this.uniqueSectors.find(
              (uniqueItem) => uniqueItem.sector_id === item.sector_id
            )
          ) {
            this.uniqueSectors.push({
              sector_id: item.sector_id,
              sectors: item.sectors,
            });
          }
          // Check if the industry group is not already in the uniqueIndustryGroups array
          if (
            !this.uniqueIndustryGroups.find(
              (uniqueItem) =>
                uniqueItem.industry_groups_id === item?.industry_groups_id
            )
          ) {
            this.uniqueIndustryGroups.push({
              industry_groups_id: item.industry_groups_id,
              industry_groups: item.industry_groups,
              industry_groups_foreign_id: item.industry_groups_foreign_id,
            });
          }

          // Check if the industry is not already in the uniqueIndustries array
          if (
            !this.uniqueIndustries.find(
              (uniqueItem) => uniqueItem.industries_id === item?.industries_id
            )
          ) {
            this.uniqueIndustries.push({
              industries_id: item.industries_id,
              industries: item.industries,
              industries_foreign_id: item.industries_foreign_id,
            });
          }

          // Check if the sub-industry is not already in the uniqueSubIndustries array
          if (
            !this.uniqueSubIndustries.find(
              (uniqueItem) =>
                uniqueItem.sub_industries_id === item.sub_industries_id
            )
          ) {
            this.uniqueSubIndustries.push({
              sub_industries_id: item.sub_industries_id,
              sub_industries: item.sub_industries,
              sub_industries_foreign_id: item.sub_industries_foreign_id,
            });
          }
        });
      }
    });
  }
  // to select gender
  onSelectgender(val) {
    this.addGender = val;
  }

  // to select industry type
  onSelectIndustryType(event) {
    this.industryType = event;
  }

  // to select industry sub type
  onSelectIndustrySubType(event) {
    this.industrySubType = event;
  }

  //to change status
  changeStatus(email, status) {
    // console.log(email);
    this.adminService
      .changeStatus(email, !status)
      .subscribe((response: any) => {
        if (response.status) {
          this.ngOnInit();
        } else {
        }
      });
  }

  // to reset form
  onSubmit(formData) {
    formData.reset();
  }
  validate() {
    //Validating Email..
    if (validateEmail(this.addEmail)) {
      this.isEmailValid = true;
      this.emailStatus = "";
      this.adminService.checkEmail(this.addEmail).subscribe((response: any) => {
        this.isEmailValid = true;
        this.emailStatus = "";
        if (response.status) {
          // console.log("Valid");
        } else {
          this.isEmailValid = false;
          this.emailStatus = response.message;
        }
      });
    } else {
      this.isEmailValid = false;
      this.emailStatus = "InValid Email ID";
    }
  }

  hideEditModal() {
    this.imageSrc = "";
    this.iconAddFromDB = "";
    this.iconSizeStatus = "";
    this.modalRef.hide();
  }

  // validate email

  addPremium() {
    //to increase number of premium package
    this.premium = this.premium + 1;
  }
  subPremium() {
    //to decrease number of premium package
    if (this.premium <= 0) {
      this.premium = 0;
    } else {
      this.premium = this.premium - 1;
    }
  }
  addSpecial() {
    //to increase number of special package
    this.special = this.special + 1;
  }
  subSpecial() {
    //to decrease number of special package
    if (this.special <= 0) {
      this.special = 0;
    } else {
      this.special = this.special - 1;
    }
  }
  addBasic() {
    //to increase number of basic package
    this.basic = this.basic + 1;
  }
  subBasic() {
    //to decrease number of basic package
    if (this.basic <= 0) {
      this.basic = 0;
    } else {
      this.basic = this.basic - 1;
    }
  }

  addCustom() {
    //to increase number of custom package
    this.custom = this.custom + 1;
  }
  subCustom() {
    //to decrease number of custom package
    if (this.custom <= 0) {
      this.custom = 0;
    } else {
      this.custom = this.custom - 1;
    }
  }

  //for updating package
  onUpdatePackage(id) {
    this.adminService
      .updateCompanyPackage(
        id,
        this.basic,
        this.special,
        this.premium,
        this.custom,
        this.invoice
      )
      .subscribe((response: any) => {
        console.log(response);
        if (response.status) {
          Swal.fire({
            title: "Package updated",
            text: "Package updated successfully",
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.basic = this.premium = this.special = this.custom = 0;
        } else {
          Swal.fire({
            title: "Package not updated",
            text: "Package not added",
            icon: "error",
            showConfirmButton: false,
            timer: 2500,
          });
        }
      });
  }
  selectModule(modules) {
    this.selectedModules = modules.value;
  }
  openCustomLD(template: TemplateRef<any>, companyId) {
    const config = { class: "modal-lg" };
    this.currentCompanyId = companyId;
    let data = {
      surveyEngineCompanyId: this.currentCompanyId,
    };
    this.adminService.getLdList(data).subscribe((res) => {
      // console.log(res);
      if (res.status) {
        this.moduleNames = res.data;
      }
    });
    this.modalRef = this.modalService.show(template, config);
    this.adminService.addModal(this.modalRef);
  }
  addCustomLd() {
    this.loading = true;
    let data = {
      surveyEngineCompanyId: this.currentCompanyId,
      customLd: this.selectedModules,
    };
    this.adminService.addCustomLd(data).subscribe((res) => {
      this.loading = false;
      this.selectedModules = [];
      this.ngOnInit();
      if (res.status) {
        Swal.fire({
          title: "Company",
          text: "l and D Assigned",
          icon: "success",
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          title: "Company update",
          text: "Something went wrong",
          icon: "error",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    });
    this.modalRef.hide();
    this.modalRef = null;
  }
  openDatePickerModal(template: TemplateRef<any>, companyData) {
    this.modalRef = this.modalService.show(template);
    this.adminService.addModal(this.modalRef);
    this.currentCompanyId = companyData.surveyEngineCompanyId;
    this.revokeDate = companyData.revokeDate;
  }
  updateRevokeDate() {
    let data = {
      surveyEngineCompanyId: this.currentCompanyId,
      revokeDate: this.revokeDate,
    };
    this.loading = true;
    this.adminService.revokeDate(data).subscribe((res) => {
      this.loading = false;
      if (res.status) {
        this.ngOnInit();
        this.modalRef.hide();
        this.modalRef = null;
      }
    });
  }

  onSubmitEdit(f1) {
    // console.log(f1);
  }

  getCompanySurveys(data, template) {
    this.loading = true;
    this.modalRef = this.modalService.show(template);
    this.adminService.addModal(this.modalRef);
    this.adminService.getIndividualCompanySurvey(data._id).subscribe((res) => {
      this.configTable.currentPage = 1;
      this.surveydataSource = res.data;
      this.allCompanySurveys = res.data;
      this.loading = false;
    });
  }

  pageChanged(event) {
    this.configTable.currentPage = event;
  }

  getRawDataBySurveyId(data) {
    this.showSocketData = true;
    let dataObj = {
      companySurveyGroupId: data._id,
      requestType: "excel",
    };
    const companyName = data.companyName ? data.companyName : "Raw-Data";
    this.adminService.getAllRawDataBySurveyGroup(dataObj).subscribe((res) => {
      if (res.data.length > 0) {
        this.loading = false;
        const Todaydate = formatDate(new Date(), "dd-MM", "en_US");
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(res.data);
        XLSX.utils.book_append_sheet(wb, ws, "All Managers");
        // const ws2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.auditCases);
        // XLSX.utils.book_append_sheet(wb, ws2, "Audit Cases");
        XLSX.writeFile(wb, `${companyName}_${Todaydate}.xlsx`);
        this.modalRef.hide();
        this.showSocketData = false;
        this.modalRef = null;
      } else if (res.status == true && res.data.length === 0) {
        this.showSocketData = false;
        Swal.fire({
          title: "Not Found",
          text: "No raw data found",
          icon: "error",
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        this.showSocketData = false;
        Swal.fire({
          title: "Not Found",
          text: "Company Does Not Have Any Managers",
          icon: "error",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    });
  }

  getReliabilityReport(data) {
    this.showSocketData = true;
    const dataObj = {
      surveyGroupId: data._id,
    };
    const companyName = data.companyName
      ? data.companyName
      : "Reliability-Data";
    this.adminService.getReliabilityReport(dataObj).subscribe(
      (res) => {
        if (res.data) {
          this.loading = false;
          const finalData = res.data;
          console.log("finalData", finalData);
          const Todaydate = formatDate(new Date(), "dd-MM", "en_US");
          const wb: XLSX.WorkBook = XLSX.utils.book_new();

          // Adding the first sheet
          const ws1 = XLSX.utils.json_to_sheet(finalData.managerWiseScore);
          XLSX.utils.book_append_sheet(wb, ws1, "Manager Wise Score");

          // Adding the second sheet
          const ws2 = XLSX.utils.json_to_sheet(finalData.reliabilityScore);
          XLSX.utils.book_append_sheet(wb, ws2, "Reliability Score");

          // Adding the third sheet
          const ws3 = XLSX.utils.json_to_sheet(finalData.reliabilityMapping);
          XLSX.utils.book_append_sheet(wb, ws3, "Reliability Mapping");

          // Adding the fourth sheet
          const ws4 = XLSX.utils.json_to_sheet(finalData.reliabilityCount);
          XLSX.utils.book_append_sheet(wb, ws4, "Reliability Count");

          // Adding the fifth sheet
          const ws5 = XLSX.utils.json_to_sheet(finalData.scoreCount);
          XLSX.utils.book_append_sheet(wb, ws5, "Score Count");

          XLSX.writeFile(wb, `${companyName}_${Todaydate}_Reliability.xlsx`);
        } else if (res.status == true && res.data.length === 0) {
          Swal.fire({
            title: "Not Found",
            text: "No reliability data found",
            icon: "error",
            showConfirmButton: false,
            timer: 2500,
          });
        } else {
          Swal.fire({
            title: "Not Found",
            text: "Company does not have any reliability data",
            icon: "error",
            showConfirmButton: false,
            timer: 2500,
          });
        }
      },
      (error) => {
        this.showSocketData = false;
        console.error("Error fetching reliability report:", error);
      }
    );
  }

  // selectUserAccess
  selectUserAccess(access) {
    this.userAccess = access.value;
  }
  selectEditUserAccess(access) {
    this.selectedAccess = access.value;
    this.optionsArray = this.accessArray.filter((o1) => {
      return !access.value.some((o2) => {
        return o1.name == o2.name;
      });
    });
  }
  filterData(type: string, name: string) {
    switch (type) {
      case "sectors":
        let sector = this.uniqueSectors.find(
          (industryType) => industryType.sectors == name
        );
        this.industryGroups = this.uniqueIndustryGroups.filter(
          (item) => item.industry_groups_foreign_id == sector.sector_id
        );
        break;
      case "industryGroups":
        let industryGroups = this.uniqueIndustryGroups.find(
          (industryType) => industryType.industry_groups == name
        );

        this.industries = this.uniqueIndustries.filter(
          (item) =>
            item.industries_foreign_id == industryGroups?.industry_groups_id
        );
        break;
      case "industries":
        let industries = this.uniqueIndustries.find(
          (industryType) => industryType?.industries == name
        );
        this.subIndustries = this.uniqueSubIndustries.filter(
          (item) => item.sub_industries_foreign_id == industries?.industries_id
        );
        break;
    }
  }
  //to view icon
  showIcon(event: any) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      this.iconSize = event.target.files[0].size;
      if (this.iconSize > 524288) {
        this.isIconValid = false;
        this.iconSizeStatus = "Icon size should be less than 512kb.";
      } else {
        this.isIconValid = true;
        this.iconSizeStatus = "";
      }
      const reader = new FileReader();
      reader.onload = (e) => (this.imageSrc = reader.result);
      this.fileName = event.target.files[0].name;

      reader.readAsDataURL(file);
    }
  }
  //to select if a company is gpms or not
  // radioValue(event: any) {
  //   this.gpmsValue = event.target.value;
  // }
  onSelectCompanyType(event: any) {
    this.companyType = event.target.value;
    if (event.target.value === "GPMS") {
      this.gpmsValue = true;
      if (this.hrEmail) {
        this.isGPMSMailValid = true;
      }
    } else {
      this.gpmsValue = false;
    }
  }
  //to validate email id while creating company admin account of a gpms company
  validateEmailMain() {
    //Validating Email..
    if (validateEmail(this.hrEmail)) {
      this.isGPMSMailValid = true;
      this.gpmsEmailStatus = "";
      this.adminService.checkEmail(this.hrEmail).subscribe((response: any) => {
        this.isGPMSMailValid = true;
        this.gpmsEmailStatus = "";
        if (response.status) {
          // console.log("Valid");
        } else {
          this.isGPMSMailValid = false;
          this.gpmsEmailStatus = response.message;
        }
      });
    } else {
      this.isGPMSMailValid = false;
      this.gpmsEmailStatus = "InValid Email ID";
    }
  }
  selectBatches(batch) {
    // console.log("batch",batch)
    this.batchSelected = batch.value;
    // console.log(this.batchSelected)
  }

  selectEditBatch(access) {
    // console.warn(access)
    this.editBatchName = access.value;
    this.editBatchArray = this.batchArray.filter((batch) => {
      return !access.value.some((newBatch) => {
        return batch == newBatch;
      });
    });
  }
  checkKeyPersonCount() {
    if (this.keyPersonArray.length == 2) {
      this.errorText = "Cannot add more than 2 key persons";
    }
  }
  addAnotherKeyPerson() {
    // if (this.keyPersonArray.length == 5) {
    // this.errorText = "Cannot add more than 2 key persons";
    // } else {
    this.keyPersonArray.push({
      name: this.kpName,
      email: this.kpEmail,
      mobile: this.kpPhone,
      userType: this.kpUserType,
    });
    this.kpName = "";
    this.kpEmail = "";
    this.kpPhone = "";
    this.kpUserType = "";
    this.errorText = "";
    // }

    // if (this.keyPersonArray.length == 5) {
    //   this.errorText = "Cannot add more than 5 key persons";
    // } else {
    // this.keyPersonArray.push({
    //   name: this.kpName,
    //   email: this.kpEmail,
    //   mobile: this.kpPhone,
    //   userType: this.kpUserType,
    // });
    // this.kpName = "";
    // this.kpUserType = "";
    // this.kpEmail = "";
    // this.kpPhone = "";
    // this.errorText = "";
    // // }
  }

  removePerson(email) {
    this.keyPersonArray = this.keyPersonArray.filter((id) => id.email != email);
    this.kpName = "";
    this.kpEmail = "";
    this.kpPhone = "";
    this.kpUserType = "";
    this.errorText = "";
  }
  patchKeyPerson(data) {
    this.keyPersonArray = this.keyPersonArray.filter(
      (id) => id.email != data.email
    );
    this.kpName = data.name;
    this.kpEmail = data.email;
    this.kpPhone = data.mobile;
    this.kpUserType = data.userType;
    this.errorText = "";
  }

  getCompanyComponentMapping(companyId: number) {
    // console.log(companyId);
  }

  uploadCertificateFn(companyDetails) {
    this.router.navigate([
      "/admin/admin-dashboard/certificate-upload/" +
        companyDetails.surveyEngineCompanyId +
        "/" +
        companyDetails.companyName,
    ]);
  }
}
