<div class="main-box p-5">
  <form [formGroup]="dialogForm">
    <div class="row align-items-center">
      <div class="col-md-4">
        <div class="form-group pb-10">
          <label for="questionType"
            >Question Type ID<span class="asterick">*</span></label
          >
          <select
            class="form-control custom-form-control"
            required
            id="question_type_id"
            name="question_type_id"
            formControlName="question_type_id"
          >
            <option value="" selected>Select</option>
            <option value="1">Rating Question</option>
            <option value="2">Select Question</option>
            <option value="4">Open Ended Question</option>
            <option value="3">Multi Select Question</option>
            <option value="5">Action Plan Question</option>
          </select>
          <p
            class="error"
            *ngIf="
              dialogForm?.get('question_type_id').hasError('required') &&
              dialogForm.get('question_type_id').touched
            "
          >
            Question Type ID is required.
          </p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group pb-10">
          <label>Button Text<span class="asterick">*</span></label>
          <input
            class="form-control custom-form-control"
            required
            id="button_text"
            name="button_text"
            type="text"
            formControlName="button_text"
            appNoSpaces
            placeholder="Enter button_text name..."
          />
          <p
            class="error"
            *ngIf="
              dialogForm.get('button_text').hasError('required') &&
              dialogForm.get('button_text').touched
            "
          >
            Button Text is required.
          </p>
          <p
            class="error"
            *ngIf="
              dialogForm.get('button_text').hasError('minlength') &&
              dialogForm.get('button_text').touched
            "
          >
            Message must be at least 2 characters long.
          </p>
          <p
            class="error"
            *ngIf="
              dialogForm.get('button_text').hasError('maxlength') &&
              dialogForm.get('button_text').touched
            "
          >
            Button Text cannot be more than 20 characters long.
          </p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="form-group pb-10">
          <label>Header<span class="asterick">*</span></label>
          <input
            class="form-control custom-form-control"
            required
            id="header"
            name="header"
            type="text"
            formControlName="header"
            appNoSpaces
            placeholder="Enter header name..."
          />
          <p
            class="error"
            *ngIf="
              dialogForm.get('header').hasError('required') &&
              dialogForm.get('header').touched
            "
          >
            Header is required.
          </p>
          <p
            class="error"
            *ngIf="
              dialogForm.get('header').hasError('minlength') &&
              dialogForm.get('header').touched
            "
          >
            Header must be at least 5 characters long.
          </p>
          <p
            class="error"
            *ngIf="
              dialogForm.get('header').hasError('maxlength') &&
              dialogForm.get('header').touched
            "
          >
            Header cannot be more than 50 characters long.
          </p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group pb-10">
          <label>Message<span class="asterick">*</span></label>
          <textarea
            class="form-control custom-form-control"
            required
            id="message"
            name="message"
            type="text"
            formControlName="message"
            rows="5"
            appNoSpaces
            placeholder="Enter message name..."
          ></textarea>

          <p
            class="error"
            *ngIf="
              dialogForm.get('message').hasError('required') &&
              dialogForm.get('message').touched
            "
          >
            Message is required.
          </p>
          <p
            class="error"
            *ngIf="
              dialogForm.get('message').hasError('minlength') &&
              dialogForm.get('message').touched
            "
          >
            Message must be at least 50 characters long.
          </p>
          <p
            class="error"
            *ngIf="
              dialogForm.get('message').hasError('maxlength') &&
              dialogForm.get('message').touched
            "
          >
            Message cannot be more than 250 characters long.
          </p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="pb-10 mt-5">
          <label>Status<span class="asterick">*</span></label>
          <br />
          <mat-slide-toggle
            formControlName="status"
            [checked]="dialogForm.value.status == 1 ? true : false"
            >{{
              dialogForm.value.status == 1 ? "Active" : "Inactive"
            }}</mat-slide-toggle
          >
        </div>
      </div>

      <!-- <div class="col-md-4">
        <div class="form-group pb-10">
          <label>Image</label>
          <input
            class="form-control custom-form-control"
            id="image"
            name="image"
            type="text"
            formControlName="image"
            placeholder="Add image URL here"
          />
          <p class="error" *ngIf="dialogForm.get('image').hasError('pattern')">
            Please enter a valid URL
          </p>
        </div>
      </div> -->
    </div>

    <div class="" style="display: flex; justify-content: center; gap: 20px">
      <div>
        <button
          type="button"
          (click)="createSurvey(null)"
          style="margin-top: 25px; color: white"
          class="btn btn-secondary btn-newuser-clr"
          [disabled]="dialogForm.invalid"
        >
          {{ isUpdate ? "Update" : "Create" }}
        </button>
      </div>
      <div>
        <button
          *ngIf="isUpdate"
          type="button"
          style="margin-top: 25px; color: white"
          class="btn btn-secondary btn-newuser-clr"
          (click)="dialogForm.reset(); isUpdate = false"
        >
          Add New
        </button>
      </div>
    </div>
  </form>

  <div class="main-box-table mat-elevation-z8">
    <mat-table [dataSource]="dataSource" class="w-100">
      <!-- Question Type ID Column -->
      <ng-container matColumnDef="question_type_id">
        <mat-header-cell *matHeaderCellDef> Question Type </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ QUESTION_TYPE_MAPPING[element.question_type_id] }}
        </mat-cell>
      </ng-container>

      <!-- Header Column -->
      <ng-container matColumnDef="header">
        <mat-header-cell *matHeaderCellDef> Header </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.header }}
        </mat-cell>
      </ng-container>

      <!-- Message Column -->
      <ng-container matColumnDef="message">
        <mat-header-cell *matHeaderCellDef> Message </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.message }}
        </mat-cell>
      </ng-container>

      <!-- Image Column -->
      <!-- <ng-container matColumnDef="image">
        <mat-header-cell *matHeaderCellDef> Image </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.image }}
        </mat-cell>
      </ng-container> -->

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="p-5">
            <div class="p-3">
              <mat-slide-toggle
                (change)="statusChange($event, element)"
                [(ngModel)]="element.status"
                [checked]="element.status == 1 ? true : false"
              >
              </mat-slide-toggle>
            </div>

            <div
              class="chip"
              [ngStyle]="{
                'background-color': element.status == 1 ? '#D3FCD2' : '#FFAC82',
                color: element.status == 1 ? '#118D57' : '#B71D18'
              }"
            >
              {{ element.status == 1 ? "Active" : "Inactive" }}
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <!-- Button Text Column -->
      <ng-container matColumnDef="button_text">
        <mat-header-cell *matHeaderCellDef> Button Text </mat-header-cell>
        <mat-cell class="text-center" *matCellDef="let element">
          {{ element.button_text }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell class="text-center" *matCellDef="let element">
          <!-- <i
            (click)="deleteSurvey(element.id)"
            class="fa fa-trash-o"
            style="padding-right: 10px; color: #ff5630"
            aria-hidden="true"
          ></i> -->

          <i
            (click)="patchValues(element)"
            class="fa fa-pencil-square-o"
            aria-hidden="true"
          ></i>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>

    <mat-paginator [pageSizeOptions]="[05, 10, 20]"></mat-paginator>
    <div
      class="text-center emptyContainer"
      *ngIf="dataSource.data.length === 0"
    >
      <h2>Not found any survey dialogs.</h2>
    </div>
  </div>
</div>
