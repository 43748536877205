<div class="container">
  <div class="row">
    <h3>Upload Certificate for {{ companyName }}.</h3>
  </div>
  <div class="row d-flex">
    <div class="textInput w-40">
      <label for="certificateIssueDate" class="form-label"
        >Certificate Issue Date</label
      >
      <mat-form-field appearance="fill">
        <input
          matInput
          [matDatepicker]="startDatePicker"
          placeholder="Choose Certificate Start Date"
          [(ngModel)]="issueDate"
          (dateChange)="onDateChange($event)"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="startDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
      </mat-form-field>

      <div class="mt-10" *ngIf="endDate">
        Certificate End date will be <strong>{{ endDate }}</strong>
      </div>
    </div>
    <div class="textInput w-40 fileClass">
      <button
        class="btn btn-secondary btn-newuser-clr"
        (click)="fileInput.click()"
      >
        Upload File
      </button>

      <span>
        <i class="fa fa-info-circle"></i>
        File should be pdf and name should be manager's email Id.</span
      >
      <input
        style="display: none"
        id="fileSelect"
        type="file"
        accept=".pdf"
        name="fileUpload"
        #fileInput
        multiple
        (change)="getFileDetails($event)"
      />

      <p class="error-message" *ngIf="errorMessage">{{ errorMessage }}</p>
    </div>
    <div class="sendEmailClass">
      <mat-slide-toggle [(ngModel)]="sendEmail">Send Mail</mat-slide-toggle>
    </div>
  </div>

  <div *ngIf="myFiles.length">
    <br />
    <h2 class="text-center">Manager's File Details</h2>
    <table class="table">
      <thead>
        <tr>
          <th>Sr No</th>
          <th>File Name</th>
          <th>View</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let file of myFiles; let i = index">
          <td>{{ i + 1 }}</td>
          <td>{{ file.fileName }}</td>
          <td>
            <i
              class="fa fa-eye cursor"
              (click)="openPreviewModal(file.file, previewCertificate)"
              aria-hidden="true"
            ></i>
            <ng-template #previewCertificate>
              <div style="padding: 10px">
                <iframe
                  [src]="safeFileUrl"
                  width="100%"
                  height="600px"
                ></iframe>
              </div>
            </ng-template>
          </td>
          <td>
            <i class="fa fa-trash cursor" (click)="deleteFile(i)"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="email-template" *ngIf="sendEmail">
    <h2 class="text-center">Email Operation</h2>
    <div class="row">
      <div class="col-md-7">
        <app-email-editor [(content)]="emailData.content"></app-email-editor>
      </div>
      <div class="col-md-5">
        <div class="form-container">
          <div class="placeholder-container">
            <h4><b>Placeholders:</b></h4>
            <ul>
              <li>%MANAGER_NAME%</li>
              <li>%COMPANY_NAME%</li>
              <!-- <li>%CERTIFICATE_LINK%</li> -->
              <li>%PACKAGE_EXPIRY_DATE%</li>
              <li>%CERTIFICATE_EXPIRY_DATE%</li>
            </ul>
          </div>
          <!-- <div class="input-group">
            <label for="from">From:</label>
            <input
              type="text"
              name="from"
              id="from"
              disabled
              [(ngModel)]="emailData.from"
            />
          </div> -->
          <div class="input-group">
            <label for="subject">Mail Subject:</label>
            <input
              type="text"
              name="subject"
              id="subject"
              [(ngModel)]="emailData.subject"
            />
          </div>
          <div class="input-group">
            <label for="from">CC:</label>
            <input type="text" name="cc" id="cc" [(ngModel)]="emailData.cc" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="button-container">
    <button
      *ngIf="myFiles.length"
      style="margin-top: 5%"
      type="submit"
      class="btn btn-secondary btn-newuser-clr"
      (click)="uploadCertificateFiles()"
      [disabled]="
        !myFiles.length ||
        !issueDate ||
        (sendEmail && (!emailData || emailData?.content?.length == 0))
      "
    >
      Upload Certificate
    </button>
  </div>
</div>
