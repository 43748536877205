import { Component, OnInit, ViewChild } from '@angular/core';
// import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CompanyServices } from '../../company.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-learning-manager-list',
  templateUrl: './learning-manager-list.component.html',
  styleUrls: ['./learning-manager-list.component.css'],
})
export class LearningManagerListComponent implements OnInit {
  private ngUnsubscribe: Subject<any> = new Subject();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  dataSource = new MatTableDataSource();
  uploadedManagerList: any = [];
  managers: any = [];
  displayedColumns: string[] = [
    'select',
    'name',
    'email',
    'batch',
    'registerStatus',
  ];
  checkall: any;
  displayNoRecords = false;
  searchKey!: string;
  loading: boolean = false;

  constructor(private companyService: CompanyServices) {}

  ngOnInit() {
    // this.getCompanyAddedManagerList();
    // this.refreshComponent();
  }

  ngAfterViewInit() {
    this.getCompanyAddedManagerList();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  applyFilter() {
    this.dataSource.filter = this.searchKey.trim().toLowerCase();
    if (this.dataSource.filteredData.length == 0) {
      this.displayNoRecords = true;
    } else {
      this.displayNoRecords = false;
    }
  }

  getCompanyAddedManagerList() {
    this.companyService.managerData.subscribe((response: any) => {
      // console.log('hit', response);
      this.loading = true;
      if (response) {
        this.managers = response;
        this.uploadedManagerList = [];
        let modulesCompltedPresent = false;
        response.forEach((manager: any) => {
          if (manager.personalInfo.isRegisteredByCompany) {
            let managerInfo: any = {
              name: manager.personalInfo.name,
              email: manager.personalInfo.email,
              profileLink: manager.personalInfo.userName,
              batchName:
                typeof manager.personalInfo.batchName !== 'undefined' &&
                manager.personalInfo.batchName != ''
                  ? manager.personalInfo.batchName
                  : 'NA',
              package: manager.packageStatus,
              packageName: manager?.package_info?.packageName,
              id: manager._id,
              registerd: manager.personalInfo.isVerified
                ? 'Registered'
                : 'Not Registered',
              participants: manager.participants,
              modulesCompleted:
                typeof manager.modulesCompleted !== 'undefined'
                  ? manager.modulesCompleted
                  : 'NA',
            };
            // console.log('managerInfo', managerInfo);
            if (typeof manager.modulesCompleted !== 'undefined') {
              modulesCompltedPresent = true;
            }
            if (
              modulesCompltedPresent &&
              !this.displayedColumns.includes('moduleCompleted')
            ) {
              this.displayedColumns.push('moduleCompleted');
            } else {
              this.displayedColumns = this.displayedColumns.filter((value) => {
                return value !== 'moduleCompleted';
              });
            }
            this.uploadedManagerList.push(managerInfo);
          }
        });
        this.loading = false;
        this.dataSource.data = [...this.uploadedManagerList];
        let that = this;
        // console.log('dataSource', this.dataSource);
      }
      // setTimeout(function () { that.dataSource.data = [...that.uploadedManagerList]; }, 2000);
    });
    ////console.log(this.dataSource  , 'data12')
  }

  refreshComponent() {
    this.companyService.refreshComponent.subscribe((response: any) => {
      let managerInfo = {
        name: response.personalInfo.name,
        email: response.personalInfo.email,
        profileLink: response.personalInfo.userName,
        package: response.packageStatus,
        packageName: response.packageName,
        id: response._id,
        participants: response.participants,
        modulesCompleted:
          typeof response.modulesCompleted !== 'undefined'
            ? response.modulesCompleted
            : 'NA',
      };
      this.managers.push(response);
      this.uploadedManagerList.push(managerInfo);
      this.dataSource.data = [...this.uploadedManagerList];
    });
  }

  collectSelectedManagers(data: any) {
    this.companyService.collectSelectedManagers(data);
  }

  checkUncheckAll() {
    if (this.checkall) {
      this.checkall = true;
    } else {
      this.checkall = false;
    }
    this.uploadedManagerList.forEach((selectedManager: any) => {
      selectedManager.checked = this.checkall;
      this.onChange(selectedManager, selectedManager.checked);
    });
  }

  count = 0;
  selectedManagers: any = [];
  updateCheckall(participant: any, isChecked: boolean) {
    ////console.log(isChecked);
    ////console.log(participant)
    var userTotal = this.uploadedManagerList.length;
    var count = 0;
    if (isChecked) {
      this.count++;
      if (!this.selectedManagers.includes(participant)) {
        this.selectedManagers.push(participant);
      }
    } else {
      this.count--;
      let index = this.selectedManagers.indexOf(participant);
      this.selectedManagers.splice(index, 1);
    }
    this.collectSelectedManagers(this.selectedManagers);
    if (userTotal == this.count) {
      this.checkall = true;
    } else {
      this.checkall = false;
    }
  }

  onChange(selectedManger: any, isChecked: boolean) {
    if (isChecked) {
      this.count++;
      if (!this.selectedManagers.includes(selectedManger)) {
        this.selectedManagers.push(selectedManger);
      }
    } else {
      this.count--;
      let index = this.selectedManagers.indexOf(selectedManger);
      this.selectedManagers.splice(index, 1);
    }
    this.collectSelectedManagers(this.selectedManagers);
  }

  onSearchClear() {
    this.searchKey = '';
    this.applyFilter();
  }

  ngOnDestroy() {
    this.dataSource = new MatTableDataSource();
    // this.ngUnsubscribe.next();
    this.ngUnsubscribe.next(this.dataSource);
    this.ngUnsubscribe.complete();
  }
}
