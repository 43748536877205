import { Directive, HostListener } from "@angular/core";

@Directive({
  selector: "[appNoNumbers]",
})
export class NoNumbersDirective {
  constructor() {}

  @HostListener("keydown", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    // Allow control keys (backspace, tab, etc.)
    if (
      event.key === "Backspace" ||
      event.key === "Tab" ||
      event.key === "ArrowLeft" ||
      event.key === "ArrowRight" ||
      event.key === "Delete" ||
      event.ctrlKey ||
      event.metaKey
    ) {
      return; // Allow default behavior
    }

    // Prevent numbers (0-9)
    const key = event.key;
    const isNumber = key >= "0" && key <= "9";

    if (isNumber) {
      event.preventDefault(); // Prevent input
    }
  }
}
