import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

export interface stats {
  likes: number;
  mark_done_average: number;
  nudge_page_view: number;
  email_open: number;
  email_click: number;
}
@Component({
  selector: 'app-nudges-stats',
  templateUrl: './nudges-stats.component.html',
  styleUrls: ['./nudges-stats.component.css'],
})
export class NudgesStatsComponent implements OnInit {
  dataSource = new MatTableDataSource<stats>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = [
    'Nudges like',
    'Mark done average',
    'Nudge page view',
    'email open rate',
    'email click through rate',
  ];

  constructor() {
    this.dataSource.data = [
      {
        likes: 10,
        mark_done_average: 43.3,
        nudge_page_view: 67,
        email_open: 3,
        email_click: 20,
      },
      {
        likes: 10,
        mark_done_average: 43.3,
        nudge_page_view: 67,
        email_open: 3,
        email_click: 20,
      },
      {
        likes: 10,
        mark_done_average: 43.3,
        nudge_page_view: 67,
        email_open: 3,
        email_click: 20,
      },
      {
        likes: 10,
        mark_done_average: 43.3,
        nudge_page_view: 67,
        email_open: 3,
        email_click: 20,
      },
      {
        likes: 10,
        mark_done_average: 43.3,
        nudge_page_view: 67,
        email_open: 3,
        email_click: 20,
      },
      {
        likes: 10,
        mark_done_average: 43.3,
        nudge_page_view: 67,
        email_open: 3,
        email_click: 20,
      },
      {
        likes: 10,
        mark_done_average: 43.3,
        nudge_page_view: 67,
        email_open: 3,
        email_click: 20,
      },
      {
        likes: 10,
        mark_done_average: 43.3,
        nudge_page_view: 67,
        email_open: 3,
        email_click: 20,
      },
      {
        likes: 10,
        mark_done_average: 43.3,
        nudge_page_view: 67,
        email_open: 3,
        email_click: 20,
      },
      {
        likes: 10,
        mark_done_average: 43.3,
        nudge_page_view: 67,
        email_open: 3,
        email_click: 20,
      },
    ];
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }
}
