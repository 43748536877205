<div class="main-body" *ngIf="!loading">
    <div style="display: flex;justify-content: end; margin-bottom: 12px; gap: 12px;">
        <input style="padding: 10px;" type="text" [(ngModel)]="searchQuery" (ngModelChange)="filterNudgeSubject($event)" placeholder="Search..."
            class="tw-mb-4 tw-p-2 tw-border tw-rounded" />
    </div>

    <ngb-accordion #acc="ngbAccordion" [closeOthers]="true">
        <ngb-panel class="panel-header" *ngFor="let nudge of filteredNudgeData" id="toggle-{{ nudge.nudge_id }}">
            <ng-template ngbPanelTitle>
                <span style="margin-right: 20px;">
                    {{
                    nudge.title.length > 75
                    ? (nudge.title | slice : 0 : 75) + "..."
                    : nudge.title
                    }}
                </span>
                <button class="btn btn-secondary btn-newuser-clr"
                    (click)="openAddSubjectModal(addNudgeSubject,nudge.nudge_id)">Add New Subject</button>
            </ng-template>
            <ng-template ngbPanelContent>
                <div class="accordian-body">
                    <p>Subject</p>
                    <p>Status</p>
                    <p>Action</p>
                </div>
                <div *ngIf="nudge.subjects.length > 0; else noSubject">
                        <div *ngFor="let subject of nudge.subjects" class="accordian-body">
                            <div>
                                <div>
                                    {{ subject.subject }}
                                </div>
                                <div>{{ subject.is_active ? 'Active' : 'In Active' }}</div>
                            </div>
                            <i (click)="openEditSubjectModal(addNudgeSubject,subject, nudge.nudge_id)" matTooltip="Edit Nudge Subject"
                                matTooltipPosition="above" class="fa fa-edit" style="cursor: pointer; margin-right: 15px"></i>
                        </div>
                </div>
            
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>
<ng-template #noSubject>
    <div>No subjects available</div>
</ng-template>
<ng-template #addNudgeSubject>
    <div class="modal-header">
        <h4 style="font-size: 22px" class="modal-title pull-left">
            {{ isEdit ? 'Edit Nudge Subject' : 'Add Nudge Subject' }}
        </h4>
        <button type="button " class="close pull-right" aria-label="Close " (click)="hideEditModal()">
            <span aria-hidden="true ">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form class="form" [formGroup]="subjectDataForm">
            <div class="parent">
                <div class="child"><label for="subject">Subject:<small class="text-danger">*</small> </label>
                    <input formControlName="subject" required type="text" placeholder="Enter subject" />
                </div>
                <div class="child">
                    <div class="child">
                        <label for="is_active">Status:
                        </label>
                        <label class="switch">
                            <input formControlName="is_active" type="checkbox">
                            <span class="slider round"></span>
                        </label>
                    </div>
                </div>
            </div>
            <button class=" btn btn-secondary btn-newuser-clr" type="button" (click)="openConfirmation()"
                [disabled]="subjectDataForm.invalid">
                {{isEdit ? 'Update':'Submit'}}
            </button>
            <br />
            <p class="text-danger text-center m-2" *ngIf="subjectDataForm.touched && subjectDataForm.invalid">
                Please Fill All Mandatory Fields
            </p>
        </form>
    </div>
</ng-template>