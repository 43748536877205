<div class="section">
  <div class="container">
    <div class="row">
      <div class="col">
        <mat-form-field appearance="fill">
          <mat-label>From date</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            [value]="fromDate"
            (dateInput)="onChangeStartDate($event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="fill">
          <mat-label>To date</mat-label>
          <input
            matInput
            [matDatepicker]="picker1"
            [value]="toDate"
            (dateInput)="onChangeEndDate($event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker1"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col">
        <form>
          <mat-form-field appearance="fill">
            <mat-label>Search Anything in Table</mat-label>
            <input matInput [(ngModel)]="search" name="search" />
          </mat-form-field>
        </form>
      </div>
      <div class="col">
        <button mat-raised-button color="warn" (click)="getEmail()">
          Filter
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button mat-raised-button color="warn" (click)="exportExcel()">
          Download Excel
        </button>
      </div>
      <div class="col">
        <button
          mat-raised-button
          color="warn"
          (click)="openEmailModal(viewMailLogs)"
        >
          Resend mail
        </button>
      </div>
      <div class="col">
        <mat-slide-toggle
          class="activeUserSlide"
          (change)="activeUserToggle($event)"
          >Slide to change the status</mat-slide-toggle
        >
      </div>
    </div>
  </div>
</div>
<br />
<mat-divider></mat-divider>
<br />
<div class="section">
  <div class="container">
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource">
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- To Column -->
        <ng-container matColumnDef="to">
          <th mat-header-cell *matHeaderCellDef>To</th>
          <td mat-cell *matCellDef="let element">{{ element.to }}</td>
        </ng-container>

        <!-- Subject Column -->
        <ng-container matColumnDef="subject">
          <th mat-header-cell *matHeaderCellDef>Subject</th>
          <td mat-cell *matCellDef="let element">{{ element.subject }}</td>
        </ng-container>

        <!-- Date Column -->
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdAt | date }}
          </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">{{ element.status }}</td>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>Action</th>
          <td mat-cell *matCellDef="let element">
            <span
              (click)="openEmailTemplate(viewEmailContent, element.content)"
              class="material-icons"
              ><i class="fa fa-eye"></i
            ></span>
            <!-- >visibility</span -->
            >
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator
        [length]="10"
        [pageSize]="10"
        (page)="pageChange($event)"
        showFirstLastButtons
        aria-label="Select page of periodic elements"
      >
      </mat-paginator>
    </div>
  </div>
</div>

<!-- Modal -->
<ng-template #viewMailLogs>
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-heading">
      <h2>Selected Emails are ({{ selectedMailsLength }})</h2>
    </div>
    <div class="container-fluid" style="padding: 20px">
      <div class="example-container mat-elevation-z3">
        <mat-table [dataSource]="selectedEmails">
          <!-- To Email Column -->
          <ng-container matColumnDef="to">
            <mat-header-cell *matHeaderCellDef matsort> To </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.to }} </mat-cell>
          </ng-container>

          <!-- subject Column -->
          <ng-container matColumnDef="subject">
            <mat-header-cell *matHeaderCellDef matsort>
              Subject
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.subject }}
            </mat-cell>
          </ng-container>

          <!-- status Type -->
          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef> status </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.status }}
            </mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedNewColumns"
          ></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedNewColumns"></mat-row>
        </mat-table>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        (click)="confirmToSend()"
        [disabled]="!selectedEmails.length"
        class="btn btn-primary"
        data-dismiss="modal"
        aria-hidden="true"
      >
        Send Mails
      </button>
    </div>
  </div>
</ng-template>

<!-- Email modal  -->
<ng-template #viewEmailContent>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Email Description</h4>
    <button
      type="button"
      class="btn-close close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div [innerHTML]="emailContent | safeHtml"></div>
  </div>
</ng-template>
