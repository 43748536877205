<div>
  <mat-table [dataSource]="scheduledEmails" class="mat-elevation-z4">
    <ng-container matColumnDef="serialNo">
      <mat-header-cell *matHeaderCellDef> Sr. No </mat-header-cell>
      <mat-cell class="text-center" *matCellDef="let element; let i = index">
        {{ i + 1 }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef> Email Name </mat-header-cell>
      <mat-cell class="text-center" *matCellDef="let element; let i = index">
        {{ element.name }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <mat-header-cell *matHeaderCellDef> Created At </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.createdAt | date : "short" }}</mat-cell
      >
    </ng-container>

    <ng-container matColumnDef="scheduleFor">
      <mat-header-cell *matHeaderCellDef>
        Schedule Time and Date
      </mat-header-cell>
      <mat-cell class="text-center" *matCellDef="let element">
        {{ element.scheduleFor | date : "medium" }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="sentStatus">
      <mat-header-cell *matHeaderCellDef> Sent Status </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.sentStatus }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-icon (click)="editScheduleFor(element, edit)">edit</mat-icon>
        <!-- <mat-icon *ngIf="!element.sentStatus" (click)="deleteEmail(element)"
          >delete</mat-icon
        > -->
        <i
          *ngIf="!element.sentStatus"
          (click)="deleteEmail(element)"
          class="fa fa-trash"
        ></i>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <mat-paginator
    showFirstLastButtons
    [pageSizeOptions]="[10, 20, 30]"
  ></mat-paginator>
</div>

<ng-template #edit>
  <div class="modal-header">
    <h4 class="modal-title">Edit Schedule Dispatch</h4>
    <button type="button" class="close" (click)="closeModal()">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="confirmation-modal m-10">
      <label for="name" class="form-label">Dispatch Time:</label>
      <input
        [owlDateTimeTrigger]="dt10"
        [owlDateTime]="dt10"
        [(ngModel)]="scheduledForDataObject.scheduleFor"
        placeholder="Click to choose time"
        class="form-control"
      />
      <!-- <owl-date-time [pickerMode]="'dialog'" #dt10></owl-date-time> -->
      <owl-date-time #dt10></owl-date-time>
      <div class="text-center m-10">
        <button type="button" class="btn btn-primary" (click)="onSubmit({})">
          Update
        </button>
      </div>
    </div>
  </div>
</ng-template>
